const Prev = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, ...props.customStyles }}
    >
      <img src="/images/arrow_left.svg" />
    </div>
  );
};
const Next = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, ...props.customStyles }}
    >
      <img src="/images/arrow_right_sec.svg" />
    </div>
  );
};

export { Prev, Next };
