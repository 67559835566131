import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  InputAdornment,
  Modal,
  Skeleton,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { Box, padding } from "@mui/system";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import OnlineConsultationIcon from "../../../../shared/icons/OnlineConsultationIcon";
import { fetchPatientTimeLine } from "../../../../redux/reducers/prescription.slice";
import { useEffect, useRef, useState } from "react";
import moment from "moment";

import PdfReport from "../utils/PdfReview";
import DiagnosticsIcon from "../../../../shared/icons/DiagnosticsIcon";
import DischargeSummaryIcon from "../../../../shared/icons/DischargeSummaryIcon";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import DateRange from "../utils/DateRange";
import NoDocumentsIcon from "../../../../shared/icons/NodocumentSAvailable";
import NoPatientTimelineIcon from "../../../../shared/icons/NoPatientTimelineIcon";
import PDFModal from "../../../../shared/Pdf-Modal/Pdf-Modal";
import {
  PATIENT_TIMELINE,
  SVAAS_DOCUMENTS,
} from "../../../../shared/constants";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import CalenderIcon from "../../../../shared/icons/CalenderIcon";
import CustomizedDialogs from "../../../../shared/Dialog/Dialog";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import Calendar from "@mui/icons-material/Event";
import SyncIcon from '@mui/icons-material/Sync';

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "14px",
  boxShadow: 24,
  p: 2,
};

export function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    account_id: params.get("account_id"),
  };
}

export default function PatientTimeLine({ isMobileScreen }: any) {
  const scrollContainerRef = useRef<any>(null);
  const [groupData, setGroupData] = useState<any>([]);
  const [displayUrl, setDisplayUrl] = useState("");
  const [displayType, setDisplayType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [dateValue, setDateValue] = useState<any>();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [TimelineData, setTimeLineData] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [type, setType] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const { prescription } = useSelector((state: any) => state.prescription);

  const [selectedDateFilterValue, setSelectedDateFilterValue] = useState<any>([
    dayjs(),
    dayjs(),
  ]);

  const { account_id } = getQueryParams();

  const theme = useTheme();

  const { selectedAppointment } = useSelector(
    (state: any) => state.appointments
  );
  const {
    loaders: { fetchingPatientTimeline },
  } = useSelector((state: any) => state.prescription);

  const NoSelectInput = styled('input')({
    '::selection': {
      background: 'transparent',
    },
  });

  useEffect(() => {
    setLoading(true);
    getPatientTimeLineData();
  }, []);


  const getPatientTimeLineData = async (
    startDate?: string,
    endDate?: string
  ) => {
    const response = await dispatch(
      fetchPatientTimeLine({
        params: {
          patient_id: selectedAppointment?.patient?.patient_id,
          ...(startDate && { start_date: startDate }),
          ...(endDate && { end_date: endDate }),
          ...(isMobileScreen && {is_doctor_app : true}),
          ...(isMobileScreen && lastEvaluatedKey !== null && Object.keys(lastEvaluatedKey)?.length && {last_evaluatedKey : lastEvaluatedKey })
        },
      })
    );
    const data = response?.payload?.data?.data?.documents;
    if (data && data.length) {
      setLastEvaluatedKey(response?.payload?.data?.data?.last_evaluatedKey);
      setTimeLineData((prevTimeLineData: Array<any>) => [...prevTimeLineData, ...data]);
      setGroupData((prevGroupData: any) => organizeDataByDate(data, { ...prevGroupData }));
      setLoading(false);
    } else {
      setLoading(false)
      setGroupData([]);
    }
  };
  const organizeDataByDate = (data: any, existingData: any = {}) => {
    const groupedData = data.reduce((acc: any, item: any) => {
      const date = new Date(item.date);
      const yearMonth = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}`;
  
      if (!acc[yearMonth]) {
        acc[yearMonth] = [];
      }
  
      acc[yearMonth].push(item);
  
      return acc;
    }, {});
  
    // Merge the new grouped data with the existing data
    for (const [key, value] of Object.entries(groupedData)) {
      if (existingData[key]) {
        existingData[key] = [...existingData[key], ...value as any];
      } else {
        existingData[key] = value;
      }
    }
  
    return existingData;
  };

  const checkPhone = () => {
    const u = navigator.userAgent;
    return !!u.match(/Android/i);
  }

  const handlePrescrition = (
    url: string,
    content_type: string,
    type: string
  ) => {
    const androidStatus = checkPhone();
    if(isMobileScreen){
      if(androidStatus && account_id !== null){
      const urlWithKey = `${url}====content_type=${content_type}&android=true`;
      window.open(urlWithKey);
      return;
    }
    else{
      window.open(url);
    }
  }

    else if(!isMobileScreen){
    setDisplayUrl(url);
    setDisplayType(content_type);
    setModalOpen(true);
    setType(type);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleDateRangeCalendar = () => {
    setShowCalendar(true);
  };

  const handleApply = (dateValue: any) => {
    const startDate = dateValue
      ? moment(dateValue[0]?.$d).format("YYYY-MM-DD")
      : undefined;
    const endDate = dateValue
      ? moment(dateValue[1]?.$d).format("YYYY-MM-DD")
      : undefined;
    setSearchTerm("");
    getPatientTimeLineData(startDate, endDate);
    setLoading(true)
    setShowCalendar(false);
    setOpenDialog(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      getPatientTimeLineData();
    }
    setSearchTerm(event.target.value);
    const results =
      TimelineData &&
      TimelineData?.filter((prescription: any) => {
        const highlights = prescription?.highlights || [];
        const documentNames = prescription?.documents?.map((doc: any) =>
          doc?.document_name?.toLowerCase()
        );
        return (
          highlights?.some((highlight: any) =>
            highlight?.value?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          ) ||
          documentNames?.some((documentName: any) =>
            documentName?.includes(searchTerm.toLowerCase())
          )
        );
      });

    setGroupData(organizeDataByDate(results));
  };

  const HandleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const HandleDialogSave = () => {
    return true;
  };

  const PaperPropsStyle = () => {
    if (isMobileScreen) {
      return {
        margin: 0,
      };
    } else {
      return {};
    }
  };

  const DialogLayoutStyle = () => {
    if (isMobileScreen) {
      return {
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
        "& .MuiDialogContent-root": {
          padding: "0px",
        },
      };
    } else {
      return {};
    }
  };

  const DialogFooterStyles = () => {
    return {
      display: "none",
    };
  };

  const handleDateChange = (value: any) => {
    setSelectedDateFilterValue(value);
  };

  const shortcutsItems = [
    {
      label: "Today",
      getValue: () => {
        const today = dayjs();
        return [today, today];
      },
    },
    {
      label: "Yesterday",
      getValue: () => {
        const today = dayjs();
        const yesterday = today.subtract(1, "day");
        return [yesterday, yesterday];
      },
    },
    {
      label: "This Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "Past Month",
      getValue: () => {
        const today = dayjs();
        const pastMonth = today.subtract(1, "month");
        return [pastMonth.startOf("month"), pastMonth.endOf("month")];
      },
    },
    {
      label: "Past 3 Months",
      getValue: () => {
        const today = dayjs();
        const past3Months = today.subtract(3, "month");
        return [past3Months, today];
      },
    },
  ];

  const handleScroll = () => {
    if (!scrollContainerRef.current) {
        return;
    }
    const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 1 && isMobileScreen) {
        getPatientTimeLineData();
    }
};

useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer && isMobileScreen && lastEvaluatedKey !== null) {
        scrollContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
        if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', handleScroll);
        }
    };
}, [lastEvaluatedKey]);


  return (
    <Box className="patient-time-line" ref={scrollContainerRef} sx={{overflowY:"scroll", height:"calc(100vh - 200px)"}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#FFFFFF",
          height:isMobileScreen ? "28px" : "104px",
          borderRadius: "8px",
          padding: "10px",
          gap: "20px",
        }}
      >
        <TextField
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="/images/icon_search.svg" alt="search_icon" />
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          sx={{
            width: "100%",
            minWidth: isMobileScreen ? "null" : "300px",
            "& .MuiOutlinedInput-input": {
              height: isMobileScreen ? "12px" : "24px",
            },
          }}
        />
        {isMobileScreen && <CalenderIcon onClick={HandleDialog} />}
        {!isMobileScreen && (
          <Box
            onClick={(e) => {
              handleDateRangeCalendar();
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SingleInputDateRangeField
                label="From-To"
                focused={showCalendar}
                value={dateValue}
                format="DD-MM-YYYY"
                sx={{ width: "294px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Calendar color={"action"} />
                    </InputAdornment>
                  ),
                  inputComponent:NoSelectInput,
                }}
              />
            </LocalizationProvider>
          </Box>
        )}
        {/* {showCalendar && (
          <DateRange
            setShowCalendar={setShowCalendar}
            setDateValue={setDateValue}
            onClick={() => handleApply(dateValue)}
          />
        )} */}
        <Modal
          open={showCalendar}
          onClose={() => {
            setShowCalendar(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateRangePicker"]}>
                  <DateRangePicker
                    localeText={{
                      start: "From",
                      end: "To",
                    }}
                    value={selectedDateFilterValue}
                    onChange={(newValue) => {
                      if (!newValue[0] || !newValue[1]) {
                        return;
                      }
                      setSelectedDateFilterValue(newValue);
                      // getDateValue(newValue);
                    }}
                    slotProps={{
                      textField: {
                        InputProps: {
                          startAdornment: (
                            <Box
                              width={{
                                md: 30,
                                marginRight: 4,
                              }}
                              component="img"
                              src={"/images/calenderIcon.svg"}
                              sx={{}}
                            />
                          ),
                        },
                        onKeyDown: (e) => {
                          e.preventDefault();
                        },
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>

            <Box display={"flex"} flexDirection={"column"} gap={1} mt={2}>
              {shortcutsItems.map((elem) => {
                return (
                  <Box
                    sx={{
                      backgroundColor:
                        selectedDateFilterValue &&
                        selectedDateFilterValue[0]?.format("DD/MM/YYYY") ===
                          elem.getValue()[0].format("DD/MM/YYYY") &&
                        selectedDateFilterValue &&
                        selectedDateFilterValue[1]?.format("DD/MM/YYYY") ===
                          elem.getValue()[1].format("DD/MM/YYYY")
                          ? "#3755aa33"
                          : "#FFFFFF",
                      border: `1px solid ${"#C0C0C0"}`,
                      borderRadius: "10px",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      ":hover": {
                        backgroundColor: "#EEEEEE",
                      },
                    }}
                    onClick={() => {
                      setSelectedDateFilterValue(elem.getValue());
                    }}
                  >
                    <Typography>{elem.label}</Typography>
                  </Box>
                );
              })}
              <Box display={"flex"} gap={1}>
                <Box
                  sx={{
                    flex: 1,
                    border: `1px solid ${"#C0C0C0"}`,
                    borderRadius: "10px",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: "#EEEEEE",
                    },
                  }}
                  onClick={() => {
                    setDateValue(undefined);
                    setShowCalendar(false);
                    setSelectedDateFilterValue([dayjs(), dayjs()]);
                    handleApply(undefined);
                  }}
                >
                  <Typography>{"Reset"}</Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: selectedDateFilterValue
                      ? theme.palette.primary.main
                      : "#C0C0C0",
                    borderRadius: "10px",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!selectedDateFilterValue) {
                      return;
                    }
                    setDateValue(selectedDateFilterValue);
                    setShowCalendar(false);
                    setSelectedDateFilterValue([dayjs(), dayjs()]);
                    handleApply(selectedDateFilterValue);
                  }}
                >
                  <Typography color={"#FFFFFF"}>{"Apply"}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
      {loading && (
        <Box>
          <Skeleton animation="wave" height={40}></Skeleton>
          <Skeleton animation="wave" height={40}></Skeleton>
          <Skeleton animation="wave" height={40}></Skeleton>
        </Box>
      )}
      {!loading &&
      groupData &&
      Object.keys(groupData)?.length > 0 ? (
        <Box>
          {Object.keys(groupData)?.map((date: any) =>
            isMobileScreen ? (
              <Box>
                <Accordion expanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      background: "rgba(242, 243, 255, 1)",
                      marginTop: "10px",
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "18.75px",
                    }}
                  >
                    {moment(date)?.format("MMM YY")}
                  </AccordionSummary>
                  {Object.keys(groupData[date])?.map((type: any) => {
                    const timeLineDate = groupData[date][type]?.date;
                    const data: any = groupData[date][type]?.highlights;
                    const documentData: any = groupData[date][type]?.documents;
                    const types: any = groupData[date][type]?.type;
                    const appointment: any = documentData?.find(
                      (doc: any) => doc
                    );
                    return (
                      <AccordionDetails>
                        <TimeLineView
                          timeLineDate={timeLineDate}
                          data={data}
                          documentData={documentData}
                          types={types}
                          appointment={appointment}
                          handlePrescrition={handlePrescrition}
                          isMobileScreen={isMobileScreen}
                        />
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              </Box>
            ) : (
              <Box sx={{ background: "#fff" }}>
                <DateView date={date} />
                {Object.keys(groupData[date])?.map((type: any) => {
                  const timeLineDate = groupData[date][type]?.date;
                  const data: any = groupData[date][type]?.highlights;
                  const documentData: any = groupData[date][type]?.documents;
                  const types: any = groupData[date][type]?.type;
                  const appointment: any = documentData?.find((doc: any) => doc);
                  return (
                    <TimeLineView
                      timeLineDate={timeLineDate}
                      data={data}
                      documentData={documentData}
                      types={types}
                      appointment={appointment}
                      handlePrescrition={handlePrescrition}
                    />
                  );
                })}
              </Box>
            )
          )}
          {/* {lastEvaluatedKey &&<Box sx={{textAlign:"center"}}>
           <Button onClick={()=>{
             getPatientTimeLineData()
           }}
           sx={{display:"flex", justifyContent:"center", alignItems:"center", gap:"5px", fontSize:"18px", width:"100%", textTransform: "none !important"
           }}>
            <SyncIcon />
            View more</Button>
           </Box>} */}
        </Box>
      ) : !fetchingPatientTimeline &&
        groupData &&
        Object.keys(groupData)?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          marginTop="20px"
          borderRadius="8px"
          sx={{
            background: "#ffffff",
            height:isMobileScreen ? "calc(100vh - 160px)" : "calc(100vh - 250px)",
            boxShadow: isMobileScreen
              ? "0px 4px 24px 0px rgba(0, 0, 0, 0.08)"
              : null,
            margin: "10px 0px 0px 0px",
          }}
        >
          <Box component="img" src="/images/icon_no_patient_time.svg" />
          <Typography sx={{fontSize: "14px", fontWeight: "400", lineHeight:"18px", color:"rgba(111, 111, 111, 1)"}}>Patient Timeline not available</Typography>
        </Box>
      ) : (
        ""
      )}
      {modalOpen && !checkPhone() && (
        <Box>
          <PDFModal
            From={type}
            pdfUrl={displayUrl}
            displayType={displayType}
            handleClose={handleClose}
            modalOpen={modalOpen}
          />
        </Box>
      )}
      <CustomizedDialogs
        isMobileScreen={isMobileScreen}
        DialogOpen={openDialog}
        onHandleDialog={HandleDialog}
        title="Select Document Date"
        onHandleSave={HandleDialogSave}
        PaperPropsStyle={PaperPropsStyle()}
        Layoutstyles={DialogLayoutStyle()}
        footerStyles={DialogFooterStyles()}
      >
        <Box>
          <Box
            sx={{
              borderTop: "1px solid #E5E5E5",
              borderBottom: "1px solid #E5E5E5",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangeCalendar
                calendars={1}
                onChange={handleDateChange}
                sx={{
                  "& .MuiDateRangeCalendar-container": {
                    width: "100%",
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", gap: "10px", margin: "16px" }}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{ background: "#494E9D", color: "white" }}
              onClick={() => handleApply(selectedDateFilterValue)}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </CustomizedDialogs>
    </Box>
  );
}
const TimeLineView = ({
  timeLineDate,
  data,
  documentData,
  types,
  appointment,
  handlePrescrition,
  isMobileScreen
}: any) => {
  return (
    <Box padding="10px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(3, max-content)"
        gap="20px"
        alignItems="center"
      >
        <Typography
          fontSize="14px"
          fontWeight="400"
          lineHeight="21.98px"
          letterSpacing="0.1px"
          color="rgba(0, 0, 0, 0.6)"
          width="45px"
        >
          {moment(timeLineDate).format("DD MMM")}
        </Typography>
        {types === "diagnostics" ? (
          <DiagnosticsIcon />
        ) : types === "discharge_summary" ? (
          <DischargeSummaryIcon />
        ) : appointment?.appointment_type === "online" ? (
          <OnlineConsultationIcon />
        ) : (
          <Box
            sx={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F2F3FF",
            }}
          >
            <Box
              component="img"
              src="/images/clinicIcon2.svg"
              sx={{ width: "18px", height: "18px" }}
            />
          </Box>
        )}
        <Typography
          fontSize="16px"
          fontWeight="700"
          textTransform="capitalize"
          lineHeight="25.12px"
          letterSpacing="0.1px"
        >
          {types?.split("_").join(" ")}
        </Typography>
      </Box>
      <Box display="flex" gap="35px" marginLeft="80px">
        <Box
          className="tracker-line"
          width="1px"
          sx={{ background: "#BDBDBD" }}
        ></Box>
        <Box className="prescription-details">
          {data &&
            data.map((item: any) => (
              <Box>
                <Typography fontSize="14px" fontWeight="700" color="#494E9D">
                  {item?.key}
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight="400"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  {item?.value}
                </Typography>
              </Box>
            ))}
          <Box display="flex" gap="20px" flexWrap="wrap">
            {documentData &&
              documentData.map((item: any) =>
                item?.content_type ? (
                  <Box
                    onClick={() =>
                      handlePrescrition(
                        item?.display_url,
                        item?.content_type,
                        SVAAS_DOCUMENTS
                      )
                    }
                    className="main-Box"
                    width="190px"
                    height="155px"
                    border="1px solid #E8E8E8"
                    borderRadius="8px"
                    marginTop="10px"
                    overflow="hidden"
                    sx={{
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <Box className="pdf-view" height="100px">
                      {item?.content_type === "application/pdf" ? (
                        <PdfReport
                          // className="pdf-container"
                          pdfLink={item?.display_url}
                          isMobileScreen={isMobileScreen}
                        />
                      ) : (
                        <object
                          data={item?.display_url}
                          type={item?.content_type}
                          width="100%"
                          height="100%"
                        />
                      )}
                    </Box>
                    <Box
                      className="pdf-desc"
                      padding="10px"
                      borderTop="1px solid #E8E8E8"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          title={item?.document_name}
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          maxWidth="330px"
                          fontSize="16px"
                          fontWeight="600"
                          lineHeight="24px"
                          color="#494E9D"
                          sx={{ cursor: "pointer" }}
                        >
                          {item?.document_name}
                        </Typography>
                        <Typography
                          fontSize="11px"
                          fontWeight="400"
                          lineHeight="16.5px"
                          color="#8B8B8B"
                          width="70px"
                        >
                          {moment(item?.date).format("DD MMM’YY")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  item?.file_type && (
                    <Box
                      className="file-type-box"
                      width="190px"
                      onClick={() =>
                        handlePrescrition(
                          item?.display_url,
                          item?.content_type,
                          SVAAS_DOCUMENTS
                        )
                      }
                      height="155px"
                      border="1px solid #E8E8E8"
                      borderRadius="8px"
                      marginTop="10px"
                      overflow="hidden"
                      sx={{
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    >
                      <Box className="pdf-view" height="100px">
                        <PdfReport pdfLink={item?.display_url} isMobileScreen={isMobileScreen}/>
                      </Box>
                      <Box
                        className="pdf-desc"
                        padding="10px"
                        borderTop="1px solid #E8E8E8"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography
                            title={item?.document_name}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            maxWidth="330px"
                            fontSize="16px"
                            fontWeight="600"
                            lineHeight="24px"
                            color="#494E9D"
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.document_name}
                          </Typography>
                          <Typography
                            fontSize="11px"
                            fontWeight="400"
                            lineHeight="16.5px"
                            color="#8B8B8B"
                            width="70px"
                          >
                            {moment(item?.date).format("DD MMM’YY")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )
                )
              )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const DateView = ({ date }: any) => {
  return (
    <Box padding="10px" height="30px" sx={{ background: "#F2F3FF" }}>
      <Typography fontSize="16px" fontWeight="700" lineHeight="18.75px">
        {moment(date).format("MMM YY")}
      </Typography>
    </Box>
  );
};
