import axios, { AxiosResponse } from "axios";
import { ADVICE, ADVICE_NOTES, COMPLAINTS, DIABETES_ASSESSMENT, DIAGNOSIS, EXAMINATION, FOLLOW_UP, FOOT_EXAM, GENERAL_PHYSICAL_EXAMINATION, INVESTIGATIONS, INVESTIGATION_MONITORING, KNEE_EXAMINATION, MEDICATION, OBG_HISTORY, OBG_NOTES, PATIENT_HISTORY, PHYSICAL_FINDING, REFERRAL, SYSTEMIC_EXAMINATION, VITALS } from "../../../../shared/constants";
import { isPatientHistoryEmpty } from "../tabcomponents/RX/patienthistory/utils/shared";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// dayjs.extend(utc); this is throwing an unexpected error

export const isUndefined = (value: boolean | undefined) => typeof value === "undefined"


export const convertUTCTimeToISTTime = (utcTimeString:string) => {
    // const utcTime = dayjs?.utc(`2024-07-12 ${utcTimeString}`);
    // const istTime = utcTime.add(5, 'hour').add(30, 'minute');
    // const istTimeString = istTime.format('HH:mm:ss');
    // return istTimeString;

    return utcTimeString;
}


export const compareBooleanWithBitwiseAnd = (varsArr: Array<boolean | undefined>): boolean | undefined => {
    let result: any;
    if (varsArr.length !== 0) {
        varsArr?.forEach((item: undefined | boolean) => {
            if (!isUndefined(item)) {
                if (isUndefined(result)) {
                    result = item
                } else {
                    result = result && item
                }
            }
        })
    }
    return result;
}


export function getQtyPerDoseOptions(value: string) {
    const combns = ["Tablet", "Capsule", "Drop", "ML"];

    let optionArr: string[] = [];

    const numVal = Number(value);

    optionArr = combns.map((t) => `${numVal} ${t}`);

    return optionArr;

}

export function isSectionEmpty(viewType: string, tabData: Array<any>) {
    let isSecEmpty, secData;
    const isTableEmpty = (table: any) => {
        return table?.rows?.every((row: any) => row?.value?.length === 0)
    }

    const isEditorSectionEmpty = (eS: any) => {
        if(eS?.rows[0]?.value){
            if(eS.rows[0]?.value?.length === 0) return true
            else if(eS?.rows[0]?.value[0]){
                return Object.keys(eS?.rows[0]?.value[0])?.every(
                    (key) => eS?.rows[0]?.value[0][key] === ""
                )
            }
        }
        else{
            if(eS?.rows){
                return eS?.rows?.every(
                    (key:any) => key?.value === ""
                )
            }
        }
        
        return null
    }

    const isEditorSectionEmpty2 = (eS: any) => {
        if(eS?.rows){
            return eS?.rows?.every(
                (key:any) => !key?.value
            )
        }
        
        return null
    }
    const isEditorSectionEmptyForOBG = (eS: any) => {
        if(eS?.rows){
            return eS?.rows?.every(
                (key:any) => key?.value?.every((v:any)=>!v?.value)
            )
        }
        
        return null
    }


    switch (viewType) {
        case VITALS:
            secData = tabData.filter(sec => sec.view_type === VITALS);
            if (secData) {
                isSecEmpty = isTableEmpty(secData[0])
            }
            break;
        case COMPLAINTS:
            secData = tabData.filter(sec => sec.view_type === COMPLAINTS);
            if (secData) {
                isSecEmpty = isTableEmpty(secData[0]);
            }
            break;
        case PATIENT_HISTORY:
            secData = tabData.filter(sec => sec.view_type === PATIENT_HISTORY);
            if (secData) {
                isSecEmpty = isPatientHistoryEmpty(secData[0])
            }
            break;
        case DIAGNOSIS:
            secData = tabData.filter(sec => sec.view_type === DIAGNOSIS);
            if (secData) {
                isSecEmpty = isTableEmpty(secData[0])
            }
            break;
        case EXAMINATION:
            secData = tabData.filter(sec => sec.view_type === EXAMINATION);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty(secData[0])
            }
            break;
        case DIABETES_ASSESSMENT:
            secData = tabData.filter(sec => sec.view_type === DIABETES_ASSESSMENT);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty(secData[0])
            }
            break;
        case GENERAL_PHYSICAL_EXAMINATION:
            secData = tabData.filter(sec => sec.view_type === GENERAL_PHYSICAL_EXAMINATION);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty2(secData[0])
            }
            break;
        case SYSTEMIC_EXAMINATION:
            secData = tabData.filter(sec => sec.view_type === SYSTEMIC_EXAMINATION);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty2(secData[0])
            }
            break;
        case FOOT_EXAM:
            secData = tabData.filter(sec => sec.view_type === FOOT_EXAM);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty2(secData[0])
            }
            break;
        case INVESTIGATION_MONITORING:
            secData = tabData.filter(sec => sec.view_type === INVESTIGATION_MONITORING);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty(secData[0])
            }
            break;
        case KNEE_EXAMINATION:
            secData = tabData.filter(sec => sec.view_type === KNEE_EXAMINATION);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty(secData[0])
            }
            break;
        case PHYSICAL_FINDING:
            secData = tabData.filter(sec => sec.view_type === PHYSICAL_FINDING);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty(secData[0])
            }
            break;
        case MEDICATION:
            secData = tabData.filter(sec => sec.view_type === MEDICATION);
            if (secData) {
                isSecEmpty = isTableEmpty(secData[0])
            }
            break;
        case INVESTIGATIONS:
            secData = tabData.filter(sec => sec.view_type === INVESTIGATIONS);
            if (secData) {
                isSecEmpty = isTableEmpty(secData[0])
            }
            break;
        case ADVICE:
            secData = tabData.filter(sec => sec.view_type === ADVICE);
            if (secData) {
                isSecEmpty = isTableEmpty(secData[0])
            }
            break;
        case REFERRAL:
            secData = tabData.filter(sec => sec.view_type === REFERRAL);
            if (secData) {
                isSecEmpty = isTableEmpty(secData[0])
            }
            break;
        case FOLLOW_UP:
            secData = tabData.filter(sec => sec.view_type === FOLLOW_UP);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty(secData[0])
            }
            break;
        case ADVICE_NOTES:
            secData = tabData.filter(sec => sec.view_type === ADVICE_NOTES);
            if (secData) {
                isSecEmpty = isEditorSectionEmpty(secData[0])
            }
            break;
        case OBG_HISTORY:
            secData = tabData.filter(sec => sec.view_type === OBG_HISTORY);
            if (secData) {
                isSecEmpty = isEditorSectionEmptyForOBG(secData[0])
            }
            break;
        case OBG_NOTES:
            secData = tabData.filter(sec => sec.view_type === OBG_NOTES);
            if (secData) {
                isSecEmpty = isEditorSectionEmptyForOBG(secData[0])
            }
            break;
        default:
            return isSecEmpty
    }

    return isSecEmpty
}

export const isRxEmpty = (tabData: any) => {
    const enabledViewTabData = tabData?.filter((section: any) => !section?.disableView)
    let patientHistoryEmpty, areTablesEmpty, editorSectionEmpty, rxEmpty: undefined | boolean;
    if (tabData && enabledViewTabData) {
        const patientHistoryData = enabledViewTabData?.filter(
            (section: any) => section?.view_type === PATIENT_HISTORY
        );

        if (patientHistoryData.length !== 0) {
            patientHistoryEmpty =
                patientHistoryData[0] && isPatientHistoryEmpty(patientHistoryData[0]);
        }

        const editorSections = enabledViewTabData?.filter(
            (section: any) =>
                section?.view_type === ADVICE_NOTES ||
                section?.view_type === FOLLOW_UP ||
                section?.view_type === EXAMINATION
        );

        if (editorSections.length !== 0) {
            editorSectionEmpty = !editorSections
                ?.map((eS: any) =>
                    Object.keys(eS?.rows[0]?.value[0]).every(
                        (key) => eS?.rows[0]?.value[0][key] === ""
                    )
                )
                .includes(false);
        }

        const tableData = enabledViewTabData?.filter(
            (section: any) =>
                section?.view_type !== ADVICE_NOTES &&
                section?.view_type !== FOLLOW_UP &&
                section?.view_type !== PATIENT_HISTORY &&
                section?.view_type !== EXAMINATION
        );

        if (tableData?.length !== 0) {
            areTablesEmpty = !tableData?.map((tS: any) => tS.rows.every((row: any) => row?.value?.length === 0))
                .includes(false);
        }

        rxEmpty = compareBooleanWithBitwiseAnd([editorSectionEmpty, areTablesEmpty, patientHistoryEmpty])

        return rxEmpty;
    }
};

export const handleDisablePreview = (tabData: any) => {
    const enabledViewTabData = tabData?.filter((section: any) => !section?.disableView);
    let editorSectionEmpty, areTablesEmpty,areOBGEmpty , disablePreview: undefined | boolean;
    if (tabData && enabledViewTabData) {
        const editorSections = enabledViewTabData?.filter(
            (section: any) =>
                section?.view_type === ADVICE_NOTES ||
                section?.view_type === EXAMINATION ||
                section?.view_type === FOLLOW_UP
        );
        if (editorSections.length !== 0) {
            editorSectionEmpty = !editorSections
                .map((eS: any) =>
                    Object.keys(eS.rows[0]?.value[0]).every(
                        (key) => eS.rows[0]?.value[0][key] === ""
                    )
                )
                .includes(false);
        }

        const OBGSections = enabledViewTabData?.filter(
            (section: any) =>
                section?.view_type === OBG_HISTORY ||
                section?.view_type === OBG_NOTES 
        );
        if(OBGSections.length !== 0){
            areOBGEmpty = !OBGSections
                .map((eS: any) =>
                    eS?.rows?.every(
                        (key:any) => key?.value?.every((v:any)=>!v?.value)
                    )
                ).includes(false);
        }

        const tableData = enabledViewTabData?.filter(
            (section: any) =>
                section?.view_type !== ADVICE_NOTES &&
                section?.view_type !== FOLLOW_UP &&
                section?.view_type !== PATIENT_HISTORY &&
                section?.view_type !== EXAMINATION &&
                section?.view_type !== VITALS &&
                section?.view_type !== OBG_HISTORY &&
                section?.view_type !== OBG_NOTES 
        );

        if (tableData?.length !== 0) {
            areTablesEmpty = !tableData
                ?.map((tS: any) => tS.rows.every((row: any) => (row?.value?.length === 0 || !row?.value)))
                ?.includes(false);
        }

        if (editorSections?.length === 0 && tableData?.length === 0 && OBGSections?.length === 0) {
            // preview strictly disabled for other sections (patient history & vitals) : featured
            return true;
        }

        disablePreview = compareBooleanWithBitwiseAnd([editorSectionEmpty, areTablesEmpty, areOBGEmpty])

        return disablePreview;
    }
}

export const initDataUpdateAPI = async (rxData: any, signedURL: any) => {
    if (Array.isArray(rxData)){
        try {
            const response: AxiosResponse<any, any> = await axios.put(
                signedURL?.url,
                { sections: rxData}
            );
            return response;
        } catch (error) {
            return error;
        }
    }
};

export const evaluateParams = (params: any) => {
    if(params === "true"){
        return true;
    }
    else{
        return false;
    }
}