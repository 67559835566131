import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { TabComponent, allyProps } from "../RightPanel";
import { SyntheticEvent, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { fetchSignedURL } from "../../../../redux/reducers/prescription.slice";
import axios from "axios";
import { APIClient } from "../../../../shared/Utils/api-client";
import { LoadingButton } from "@mui/lab";
import {
  fetchDisplayUrlData,
  getTemplateUrl,
} from "../../../../shared/Utils/utils";

interface TemplateProps {
  templateName: string;
  handleCloseButton: (event: any) => void;
  handleSaveTemplate?: (event: any) => void;
  data?: any;
  handleSelectedTemplateData?: (event: any) => void;
}
const TemplateCard = ({
  templateName,
  handleCloseButton,
  data,
  handleSelectedTemplateData,
}: TemplateProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = useState(0);
  const [options, setOptions] = useState<string[]>([]);
  const [value, setValue] = useState<string>();
  const [buttonDisable, setButtonDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>();
  const [templateId, setTemplateId] = useState<string>();
  const [templateData, setTemplateData] = useState([]);

  const MainTemplateCardStyle = {
    background: "white",
        height: "138px",
        minWidth:!isMobileScreen ? "520px" : "0px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",
        boxShadow:"0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
        position:isMobileScreen? "relative": "none",
        top:"-82px",
        zIndex:isMobileScreen? "1": "none"
  }


  const { selectedAppointment, selectedTemplate } = useSelector(
    (state: any) => state.appointments
  );

  useEffect(() => {
    if (templateName === "search-template") {
      getTemplates();
    }
  }, []);

  const getTemplates = async () => {
    const response = await APIClient.get("prescription/get-template", {
      params: {
        section_id: data?.view_type,
        template_id: selectedTemplate?.template_id,
      },
    });
    if (response?.data?.statusCode === 200) {
      setTemplateData(response?.data?.data);
      const optionsArr = response?.data?.data.map((templateName: any) => {
        const fileName = {
          label: templateName?.filename,
        };
        return fileName;
      });
      setOptions(optionsArr);
    }
  };
  const handleChange = async (_event: SyntheticEvent, newValue: number) => {
    setTabActive(newValue);
    setButtonDisable(true);
    if (newValue === 1) {
      getTemplates();
    }
  };

  const handleTextField = (event: any) => {
    if (event.target.value.length > 0 && data?.rows[0]?.value.length > 0) {
      setValue(event.target.value);
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };

  const handleSaveUpdateTemplate = async (templateType: string) => {
    const templateParam = {
      selectedAppointment: selectedAppointment,
      templateId: templateId,
      data: data,
      templateType: templateType,
      selectedTemplate: selectedTemplate,
    };
    setLoading(true);
    const response: any = await getTemplateUrl(templateParam);
    if (response?.data?.statusCode === 200) {
      const displayUrl = await axios.put(response?.data?.data?.url, {
        sections: data,
      });
      if (displayUrl?.status === 200 && templateType === "mini_template") {
        APIClient.post("prescription/save-template", {
          id: response?.data?.data?.id,
          filename: value,
        })
          .then((res) => {
            if (res?.data?.statusCode === 200) {
              setLoading(false);
              handleCloseButton("icon_save.svg");
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      }
      if (
        displayUrl?.status === 200 &&
        templateType === "update_mini_template"
      ) {
        setLoading(false);
        handleCloseButton("icon_save.svg");
      }
    } else {
      setLoading(false);
    }
  };

  const handleExistingTemplate = (event: any) => {
    if (event.target.outerText && data?.rows[0]?.value.length > 0) {
      const data: any = templateData.filter(
        (template: any) => template?.filename === event.target.outerText
      );
      if (data?.length > 0) {
        setTemplateId(data[0]?.id);
        setButtonDisable(false);
      }
    } else {
      setButtonDisable(true);
    }
  };

  const handleSearchTemplate = async (options: any) => {
    const data = templateData
      .filter(
        (templateName: any) =>
          templateName?.filename === options.target.outerText
      )
      .map((url: any) => {
        return url?.display_url;
      });
    const displayUrl = await fetchDisplayUrlData(data[0]);
    if (handleSelectedTemplateData) {
      handleSelectedTemplateData(displayUrl?.sections);
      handleCloseButton("icon_add_page.svg");
    }
  };

  return (
    <Box
      className="main-template-card"
      sx={MainTemplateCardStyle}
    >
      {templateName === "save-template" ? (
        <Box className="save&update-Template">
          <Box
            className="header-box"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid rgba(0, 0, 0, 0.12)"
            padding="0px 16px"
          >
            <Box className="tabs box">
              <Tabs value={tabActive} onChange={handleChange}>
                <Tab label="NEW TEMPLATE" {...allyProps(0)} sx={{fontSize:!isMobileScreen ? "14px" :"12px"}} />
                <Tab label="UPDATE EXISTING TEMPLATE" sx={{fontSize:!isMobileScreen ? "14px" :"12px"}} {...allyProps(1)} />
              </Tabs>
            </Box>
            <Box className="close">
              <IconButton
                onClick={() => handleCloseButton("icon_save.svg")}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <TabComponent tabActive={tabActive} index={0} height={!isMobileScreen ? "calc(100vh - 160px)" : "100%"}>
            <Box
              display="flex"
              alignItems="center"
              gap="16px"
              padding="0px 16px"
            >
              <TextField
                label="Template Name"
                sx={{ minWidth: !isMobileScreen ? "386px" : "none", maxWidth: "100%", width:isMobileScreen ? "450px" : "100%" }}
                onChange={(event) => handleTextField(event)}
                inputProps={{ maxLength: 40 }}
              />
              <LoadingButton
                disabled={buttonDisable}
                onClick={() => handleSaveUpdateTemplate("mini_template")}
                variant="contained"
                loading={loading}
                loadingPosition="center"
                sx={{ textTransform: "none !important" }}
              >
                Save
              </LoadingButton>
            </Box>
          </TabComponent>
          <TabComponent tabActive={tabActive} index={1} height={!isMobileScreen ? "calc(100vh - 160px)" : "100%"}>
            <Box
              display="flex"
              alignItems="center"
              gap="16px"
              padding="0px 16px"
            >
              <Autocomplete
                options={options}
                onChange={(event) => handleExistingTemplate(event)}
                sx={{
                  width: "100%",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Existing template" />
                )}
              />
              <LoadingButton
                disabled={buttonDisable}
                onClick={() => handleSaveUpdateTemplate("update_mini_template")}
                variant="contained"
                loading={loading}
                loadingPosition="center"
                sx={{ textTransform: "none !important" }}
              >
                Update
              </LoadingButton>
            </Box>
          </TabComponent>
        </Box>
      ) : (
        <Box className="search-existing-template">
          <Box
            className="header"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="10px 16px 0px 24px"
          >
            <Typography fontSize={!isMobileScreen ?"16px" : "14px"} fontWeight="600">
              Search for {data?.header} Templates
            </Typography>
            <Box className="close">
              <IconButton
                onClick={() => handleCloseButton("icon_add_page.svg")}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Autocomplete
            options={options}
            onChange={(event) => handleSearchTemplate(event)}
            sx={{
              width:!isMobileScreen ? "100%" : "none",
              minWidth:!isMobileScreen ?  "386px" : "none",
              padding: "11px 24px 24px 24px",
              height: "40px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src="/images/icon_search.svg" alt="search_icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search for templates"
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default TemplateCard;
