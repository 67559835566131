import { Autocomplete, Button, Divider, Grid, InputAdornment, Skeleton, Snackbar, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, styled, useTheme,useMediaQuery } from '@mui/material'
import { Box, Stack } from '@mui/system'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchPastVitalsData, setEditRxSecMobile, setToggleShouldShowPastVitals } from '../../../../../redux/reducers/prescription.slice';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BMITable from './TableComponents/BMITable';
import BMIChart from './ChartComponents/BMIChart';
import BPChart from './ChartComponents/BPChart';
import BPTable from './TableComponents/BPTable';
import RRChart from './ChartComponents/RRChart';
import RRTable from './TableComponents/RRTable';
import HRChart from './ChartComponents/HRChart';
import HRTable from './TableComponents/HRTable';
import SPO2Table from './TableComponents/SPO2Table';
import SPO2Chart from './ChartComponents/SPO2Chart';
import BTTable from './TableComponents/BTTable';
import BTChart from './ChartComponents/BTChart';
import VitalDataEmpty from './VitalDataEmpty';
import SearchIcon from '@mui/icons-material/Search';

const tabs = [
    {
        label:'BMI',
        title:'Body Mass Index',
        value:'bmi',
        logo:'/images/vitals-bmi.svg',
        id: `vertical-tab-bmi`,
        'aria-controls': `vertical-tabpanel-bmi`,
    },
    {
        label:'Body Temperature',
        title:'Body Temperature',
        value:'bodytemp',
        logo:'/images/vitals-bodytemp.svg',
        id: `vertical-tab-bodytemp`,
        'aria-controls': `vertical-tabpanel-bodytemp`,
    },
    {
        label:'Blood Pressure',
        title:'Blood Pressure',
        value:'bp',
        logo:'/images/vitals-bp.svg',
        id: `vertical-tab-bp`,
        'aria-controls': `vertical-tabpanel-bp`,
    },
    {
        label:'Oxygen Saturation',
        title:'Oxygen Saturation',
        value:'spo2',
        logo:'/images/vitals-spo2.svg',
        id: `vertical-tab-spo2`,
        'aria-controls': `vertical-tabpanel-spo2`,
    },
    {
        label:'Respiratory Rate',
        title:'Respiratory Rate',
        value:'rr',
        logo:'/images/vitals-rr.svg',
        id: `vertical-tab-rr`,
        'aria-controls': `vertical-tabpanel-rr`,
    },
    {
        label:'Heart Rate',
        title:'Heart Rate',
        value:'hr',
        logo:'/images/vitals-hr.svg',
        id: `vertical-tab-hr`,
        'aria-controls': `vertical-tabpanel-hr`,
    }
]

const initErrorState = {
    errorField:'',
    errorMsg:'',
    showError:false
}

const PastVitalsWrapper = ({selectedAppointment}) => {
  
    //hooks
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));


    //states
    const {
        pastVitalsData : {data,error,pastVitalsLoader}
    } = useSelector((state) => state.prescription);
    const [tabValue,setTabValue] = useState(0);
    const [selectedView, setSelectedView] = useState('chart');
    const [numberOfReadings, setNumberOfReadings] = useState(7);

    const [errorState,setErrorState] = useReducer((state,action)=>{
        return {
            ...state,
            ...action
        }
    },initErrorState)

    //effects
    useEffect(()=>{
        if(selectedAppointment?.account_id && selectedAppointment?.patient_id){
            handleSearchNumberOfReadings();
        }
    },[selectedAppointment?.account_id,selectedAppointment?.patient_id,numberOfReadings])

    useEffect(()=>{
        console.log("error",error);
        if(error && error!==null){
            setErrorState({
                errorField:'fetchingData',
                errorMsg:'error',
                showError:true
            })
        }
    },[error])

    
    // useEffect(()=>{
    //     handleSearchNumberOfReadings();
    // },[numberOfReadings])

    //functions

    const renderResults = () => {
        if(!data?.VITALS?.length){
            return <VitalDataEmpty/>
        }
        switch(tabValue){
            case 0:
                if(!data?.VITALS?.find(elem=>elem.name==="bmi")){
                    return <VitalDataEmpty/>
                }
                return selectedView==='chart' ? <BMIChart/> : <BMITable/>
            case 1:
                if(!data?.VITALS?.find(elem=>elem.name==="temperature")){
                    return <VitalDataEmpty/>
                }
                return selectedView==='chart' ? <BTChart/> : <BTTable/>
            case 2:
                if(!data?.VITALS?.find(elem=>elem.name==="bp")){
                    return <VitalDataEmpty/>
                }
                return selectedView==='chart' ? <BPChart/> : <BPTable/>
            case 3:
                if(!data?.VITALS?.find(elem=>elem.name==="spo2")){
                    return <VitalDataEmpty/>
                }
                return selectedView==='chart' ? <SPO2Chart/> : <SPO2Table/>
            case 4:
                if(!data?.VITALS?.find(elem=>elem.name==="rr")){
                    return <VitalDataEmpty/>
                }
                return selectedView==='chart' ? <RRChart/> : <RRTable/>
            case 5:
                if(!data?.VITALS?.find(elem=>elem.name==="prhr")){
                    return <VitalDataEmpty/>
                }
                return selectedView==='chart' ? <HRChart/> : <HRTable/>
            default:
                return selectedView==='chart' ? <VitalDataEmpty/> : <VitalDataEmpty/>
        }
    }

    const fetchPastClinicDataFunc = (account_id,patient_id,limit)=>{
        try {
            dispatch(fetchPastVitalsData({accountId:account_id,patientId:patient_id,limit}))
        } catch (error) {
            console.log(error)
        }
    }

    const handleSearchNumberOfReadings = () => {
        if(selectedAppointment?.account_id && selectedAppointment?.patient_id && numberOfReadings){
            fetchPastClinicDataFunc(selectedAppointment?.account_id,selectedAppointment?.patient_id,numberOfReadings);
        }
    }

    const handleChangeNumberOfReadings = (e) => {
        let val = e.target.value;
        const onlyNums = val.replace(
            /[^0-9]/g,
            ""
        );
        setNumberOfReadings(onlyNums);
    }

    const handleChangeSelectedView = (e,newAlignment) => {
        if(newAlignment){
            hideChartTooltip();
            setSelectedView(newAlignment)
        }
    }

    const handleChangeTabValue = (e,newValue) => {
        hideChartTooltip();
        setTabValue(newValue)
    }

   

    const handleBackToPrescription = () => {
        hideChartTooltip();
        dispatch(setToggleShouldShowPastVitals(false));
        dispatch(setEditRxSecMobile(""));
    }

    const StyledToggleButton = styled(ToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: theme.palette.primary.main, // Change this to your desired color
          color: theme.palette.white.main
        },
    })

    return (
        <Box width={'100%'} height={isMobileScreen ? '100%' : '100%'} sx={{backgroundColor:theme.palette.white.main}}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                ContentProps={{ sx: { background: "red", marginTop: "40px" } }}
                open={errorState.showError}
                onClose={() => setErrorState({showError:false})}
                autoHideDuration={6000}
                message={errorState.errorMsg}
            />
            {pastVitalsLoader ? (
                <Box padding={2}>
                    <Skeleton animation={'wave'}/>
                    <Skeleton animation={'wave'}/>
                    <Skeleton animation={'wave'}/>
                </Box>
            ) : (
                isMobileScreen ? (
                    <Box sx={{height:"100vh", overflowY:'auto',display:"flex", flexDirection:"column",gap:'20px',padding:"0px 10px"}}>
                        <Box sx={{display:"flex", justifyContent:"space-between", alignItems:'center'}}>
                            <Button sx={{display:'flex', justifyContent:'start',py:2}} startIcon={<ChevronLeftIcon color={'#000000'}/>} onClick={handleBackToPrescription}>
                                <Typography variant='subtitle2' color={'#000000'} textAlign={'left'} fontWeight={500} textTransform={'none'}>Back to prescription</Typography>
                            </Button>
                            
                        </Box>

                        <Box>
                            <Box display={'flex'} alignItems={'center'} gap={1} marginBottom={'10px'}>
                                <Box
                                    component={'img'}
                                    src={tabs[tabValue].logo}
                                />
                                <Typography fontWeight={500}>{tabs[tabValue].title}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{display:"flex",justifyContent:'space-between', alignItems:"center", gap:"10px",marginTop:isMobileScreen ? '20px':'0px'}}>
                            <Autocomplete
                                disablePortal
                                id="number-of-readings-dropdown"
                                disableClearable
                                options={[
                                    {label:'3 readings', value:3},
                                    {label:'5 readings', value:5},
                                    {label:'7 readings', value:7},
                                ]}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Number of readings" value={params.inputProps.value || ''} />}
                                value={numberOfReadings ? {label:numberOfReadings+' readings'} : null}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setNumberOfReadings(newValue.value);
                                    }
                                }}
                                sx={{
                                    width:'100%'
                                }}
                            />

                            <ToggleButtonGroup
                                value={selectedView}
                                exclusive
                                onChange={handleChangeSelectedView}
                            >
                                <Tooltip title={'Chart'}>
                                    <StyledToggleButton value="chart">
                                        <SignalCellularAltIcon/>
                                    </StyledToggleButton>
                                </Tooltip>
                                <Tooltip  title={'Table'}>
                                    <StyledToggleButton value="table">
                                        <FormatListBulletedIcon/>
                                    </StyledToggleButton>
                                </Tooltip>
                            </ToggleButtonGroup>
                        </Box>

                        <Box>
                            <Tabs
                                variant="scrollable"
                                value={tabValue}
                                onChange={handleChangeTabValue}
                                aria-label="Vertical tabs example"
                            >
                                {tabs.map((elem,index)=>{
                                    return (
                                        <Tab
                                            label={<Typography variant='subtitle2' fontWeight={400}>{elem.label}</Typography>}
                                            icon={<Box component={'img'} src={elem.logo}/>}
                                            iconPosition="start"
                                            id={elem.id}
                                            aria-controls={elem['aria-controls']}
                                            sx={{
                                                py:0,
                                                minHeight:'50px',
                                                justifyContent:'start',
                                                "&.MuiTab-root.Mui-selected": {
                                                    backgroundColor: theme.palette.white.mobileLight
                                                }
                                            }}
                                        />
                                    )
                                })}

                            </Tabs>
                        </Box>

                        <Box sx={{
                            height:"66%",
                            marginTop:isMobileScreen ? '20px':'0px'
                        }}>
                            <Box sx={{overflow:'auto',padding:1,flex:1,height:'100%'}}>
                                {error ? <VitalDataEmpty/>  : renderResults()}
                            </Box>
                        </Box>
                    </Box>

                ) : (
                    <Grid container height={'100%'} xs={12} sx={{flexGrow:1}}>
                        {/* left panel */}
                        <Grid item xs={3.5} height={'100%'} sx={{borderRight:`1px solid ${theme.palette.white.mobileLight}`}}>
                            <Stack height={'100%'}>
                                <Button sx={{display:'flex', justifyContent:'start',py:2}} startIcon={<ChevronLeftIcon color={'#000000'}/>} onClick={handleBackToPrescription}>
                                    <Typography variant='subtitle2' color={'#000000'} textAlign={'left'} fontWeight={500} textTransform={'none'}>Back to prescription</Typography>
                                </Button>
                                <Divider/>
                                <Box display={'flex'} justifyContent={'start'} alignItems={'center'} py={2} px={2}>
                                    <Typography variant='subtitle1' fontWeight={500}>Past vital data</Typography>
                                </Box>
                                <Divider/>
                                <Box height={'100%'} sx={{overflowY:'auto'}}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={tabValue}
                                        onChange={handleChangeTabValue}
                                        aria-label="Vertical tabs example"
                                    >
                                        {tabs.map((elem,index)=>{
                                            return (
                                                <Tab
                                                    label={<Typography variant='subtitle2' fontWeight={400}>{elem.label}</Typography>}
                                                    icon={<Box component={'img'} src={elem.logo}/>}
                                                    iconPosition="start"
                                                    id={elem.id}
                                                    aria-controls={elem['aria-controls']}
                                                    sx={{
                                                        py:0,
                                                        minHeight:'50px',
                                                        justifyContent:'start',
                                                        "&.MuiTab-root.Mui-selected": {
                                                            backgroundColor: theme.palette.white.mobileLight
                                                        }
                                                    }}
                                                />
                                            )
                                        })}

                                    </Tabs>
                                </Box>
                            </Stack>
                        </Grid>


                        {/* right panel */}
                        <Grid item xs={8.5} height={'100%'} padding={3}>

                            <Box display={'flex'} height={'100%'} flexDirection={'column'}>
                                {/* header */}
                                <Box display={'flex'} justifyContent={'space-between'}>
                                    <Box display={'flex'} alignItems={'center'} gap={1}>
                                        <Box
                                            component={'img'}
                                            src={tabs[tabValue].logo}
                                        />
                                        <Typography fontWeight={500}>{tabs[tabValue].title}</Typography>
                                    </Box>

                                    <Box display={'flex'} gap={2}>
                                        <Autocomplete
                                            disablePortal
                                            id="number-of-readings-dropdown"
                                            disableClearable
                                            options={[
                                                {label:'3 readings', value:3},
                                                {label:'5 readings', value:5},
                                                {label:'7 readings', value:7},
                                            ]}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <TextField {...params} label="Number of readings" value={params.inputProps.value || ''} />}
                                            value={numberOfReadings ? {label:numberOfReadings+' readings'} : null}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setNumberOfReadings(newValue.value);
                                                }
                                            }}
                                            sx={{
                                                width:'200px'
                                            }}
                                        />

                                        <ToggleButtonGroup
                                            value={selectedView}
                                            exclusive
                                            onChange={handleChangeSelectedView}
                                        >
                                            <Tooltip title={'Chart'}>
                                                <StyledToggleButton value="chart">
                                                    <SignalCellularAltIcon/>
                                                </StyledToggleButton>
                                            </Tooltip>
                                            <Tooltip  title={'Table'}>
                                                <StyledToggleButton value="table">
                                                    <FormatListBulletedIcon/>
                                                </StyledToggleButton>
                                            </Tooltip>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Box>

                                <Box marginTop={3} sx={{overflow:'auto',padding:1,flex:1 ,boxShadow: `0px 0px 12px ${theme.palette.white.mobileLight}`}}>
                                    {error ? <VitalDataEmpty/>  : renderResults()}
                                </Box>
                            </Box>

                            
                        </Grid>
                    </Grid>
                )
                
            )}
            
        </Box>
    )
}

export const hideChartTooltip = ()=>{
    if(document.getElementById('chartjs-tooltip')){
        document.getElementById('chartjs-tooltip').style.opacity = 0;
    }
}

export default PastVitalsWrapper