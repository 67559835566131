/* eslint-disable prefer-const */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
  Tooltip,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
} from "@mui/material";
import Card from "../../../utils/Card";
import CardHeader, { renderHeaderButtons } from "../../../utils/CardHeader";
import { SyntheticEvent, useEffect, useReducer, useState } from "react";

import { CARD_SHADOW } from "../../../utils/Constants";
import { styled } from "@mui/system";
import Slider from "react-slick";
import {
  ALLERGIES,
  BREAST,
  FAMILY_HISTORY,
  MEAL_PREFERENCE,
  MEDICAL_HISTORY,
  MEDICAL_HISTORY_DIABETES,
  OBG_HISTORY,
  OBG_NOTES,
  OTHER_FINDINGS,
  PATIENT_HISTORY,
  PER_ABDOMEN,
  PER_SPECULUM,
  PER_VAGINUM,
  PHYSICAL_ACTIVITY,
  PHYSICAL_EXAMINATION,
  SOCIAL_HISTORY,
  SURGICAL_HISTORY,
  TRIMESTER_NOTES,
} from "../../../../../../shared/constants";
import { Next, Prev } from "../patienthistory/utils/arrows";
import { isPatientHistoryEmpty } from "../patienthistory/utils/shared";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditRxSecMobile,
  setPrevPrescriptionDetails,
  setToggleRepopulateTable,
} from "../../../../../../redux/reducers/prescription.slice";
import { TabComponent, allyProps } from "../../../RightPanel";
import Gravida from "../obstetrichistory/Gravida";
import Abortion from "../obstetrichistory/Abortion";
import Ectopic from "../obstetrichistory/Ectopic";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import TrimesterNotes from "./TrimesterNotes";
import PerAbdomen from "./PerAbdomen";
import Breast from "./Breast";
import PerVaginum from "./PerVaginum";
import PerSpectrum from "./PerSpectrum";
import OtherFindings from "./OtherFindings";
import PhysicalExamination from "./PhysicalExamination";
import TemplateCard from "../../../utils/TemplateCard";

const obgNotesProps = {
  ["Physical Examination"]: true,
  ["Trimester notes"]: false,
  ["Per Abdomen"]: false,
  ["Breast"]: false,
  ["Per Vaginum"]: false,
  ["Per Speculum"]: false,
  ["Other Findings"]: false,
};

const EditorModules = {
    toolbar: [
        ["bold", "italic", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
        matchVisual: false,
    },
};

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "16px",
    paddingLeft: 0,
    border: "none",
  },
});

const StyledChip = styled(Chip)({
  color: "#494E9D",
  backgroundColor: "#F2F3FF",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "18px",
  borderRadius: "100px",
  letterSpacing: "0.16px",
  marginRight: "13px",
  cursor: "pointer",
  userSelect: "none",
});

const EditButton = styled(Button)({
  width: 93,
  height: 32,
  border: "1px solid #494E9D",
  borderRadius: 4,
  color: "#494E9D",
  textTransform: "none",
});

const EmptyState: any = {
  PHYSICAL_EXAMINATION: "No known significant physical examination",
  TRIMESTER_NOTES: "No known significant trimester notes",
  PER_ABDOMEN: "No known significant per abdomen",
  BREAST: "No known breast",
  PER_VAGINUM: "No known significant per vaginum",
  PER_SPECULUM: "No known significant per speculum",
  ['Other Findings']: "No known significant other findings",
};

const initState = {
    physicalExamination:''
}

export default function OBGNotes(props: any) {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [showHistory, setShowHistory] = useState(obgNotesProps);
  const [toEdit, setEdit] = useState<boolean>();
  const [tabActive, setTabActive] = useState<number>(0);

  const sectionData = props.sectionData[0];
  const setTabData = props.setTabData;

  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isFocused: boolean = props.isFocused || false;
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>(false);
  const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
    useState<boolean>(false);

  const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
  const searchExistingTemplateMobile =
    props?.sectionData?.[0]?.searchExistingTemplateMobile;

  const {
    prescription: { prevPrescriptionDetails, rePopulateTable },
  } = useSelector((state: any) => state.prescription);

  const prevPatientHistory = prevPrescriptionDetails?.find(
    (section: any) => section.view_type === OBG_NOTES
  );

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
      ...state,
      ...action
    }
  },initState)

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };

  useEffect(() => {
    if (sectionData) {

        let data = sectionData?.rows?.find((elem:any)=>elem.view_type === PHYSICAL_EXAMINATION)?.value;
        setState({
            physicalExamination:data?.find((elem:any)=>elem?.name === 'comment')?.value,
        })

      if (isSectionEmpty && prevPatientHistory && rePopulateTable) {
        if (!isPatientHistoryEmpty(prevPatientHistory)) {
          setTabData((prev: any) => {
            return prev.map((section: any) => {
              if (section.view_type === OBG_NOTES) {
                return { ...prevPatientHistory };
              }
              return { ...section };
            });
          });
          dispatch(setToggleRepopulateTable(false));
        }
      } else {
        dispatch(setToggleRepopulateTable(false));
        setData(sectionData);
      }

      rePopulateTable && dispatch(setToggleRepopulateTable(false));

      if (isSectionEmpty && !isFocused) {
        setShowHistory(() => {
          return {
            ["Physical Examination"]: true,
            ["Trimester notes"]: false,
            ["Per Abdomen"]: false,
            ["Breast"]: false,
            ["Per Vaginum"]: false,
            ["Per Speculum"]: false,
            ["Other Findings"]: false,
          };
        });
      }
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (!isFocused) {
      setEdit(false);
    }
  }, [isFocused]);

  useEffect(() => {
    if (
      isSectionEmpty &&
      !data?.rows?.some((row: any) => row.disable_value === true) &&
      !rePopulateTable
    ) {
      setEdit(true);
    }
  }, [isSectionEmpty]);

  useEffect(() => {
    if (
      data &&
      isPatientHistoryEmpty(data) &&
      !data?.rows?.some((row: any) => row.disable_value === true) &&
      !rePopulateTable
    ) {
      setEdit(true);
    }
  }, [toEdit]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(OBG_NOTES));
    }
  }, [isAccExpanded]);

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_NOTES) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                return { ...row, value: item?.rows[index]?.value };
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const sliderSetting = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: (
      <Prev
        customStyles={{
          zIndex: "20",
          width: 24,
          height: 24,
          position: "absolute",
          left: "101%",
        }}
      />
    ),
    nextArrow: (
      <Next
        customStyles={{
          width: 24,
          height: 24,
          position: "absolute",
          right: "-55px",
        }}
      />
    ),
  };

  const sliderMobileSetting = {
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: (
      <Prev
        customStyles={{
          zIndex: "20",
          width: 20,
          height: 20,
          position: "absolute",
          left: "88%",
          top: "-10px",
        }}
      />
    ),
    nextArrow: (
      <Next
        customStyles={{
          width: 20,
          height: 20,
          position: "absolute",
          right: "-12px",
          top: "-10px",
        }}
      />
    ),
  };


  const isFieldDisabled = () => {
    const disabledFields = data?.rows?.filter(
      (row: any) => row.disable_value === true
    );

    if (disabledFields?.length === 0) return ShowEditableContent();

    const viewTypes = disabledFields?.map((row: any) => row.view_type);

    return (
      <Box
        sx={{
          color: "#959595",
          fontWeight: 400,
          lineHeight: "132%",
        }}
        component="span"
      >
        {viewTypes?.map((view: any) => EmptyState[view]).join(", ")}
      </Box>
    );
  };

  const getValueContainerWidth = () => {
    const tableRow: any = document.querySelector(
      "#preview-medical-histories tr"
    );
    const labelCell: any = tableRow?.querySelector(`td`);
    const width: any = tableRow?.clientWidth - labelCell?.clientWidth;

    return width;
  };

  const validateInput = (value:any,type:string)=>{
    switch(type){
        case 'ageOfMenopause':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'marriedSince':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'marriageComments':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'ectopicComments':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'gravida':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'para':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'live':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'abortion':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
  }

  const ShowEditableContent = () => {
    const PhysicalExaminationRef = (
      <PhysicalExamination
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === PHYSICAL_EXAMINATION
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    const TrimesterNotesRef = (
      <TrimesterNotes
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === TRIMESTER_NOTES
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    const PerAbdomenRef = (
      <PerAbdomen
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === PER_ABDOMEN
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    const BreastRef = (
      <Breast
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === BREAST
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    const PerVaginumRef = (
      <PerVaginum
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === PER_VAGINUM
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    const PerSpectrumRef = (
      <PerSpectrum
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === PER_SPECULUM
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    const OtherFindingsRef = (
      <OtherFindings
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === OTHER_FINDINGS
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    
    if (!isMobileScreen) {
      return (
        <>

          {/* <Box display={'flex'} flexDirection={'column'} gap={2} mb={2}>
            
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <span style={{ fontWeight: "600", color: "#000" }}>
                    Physical Examination
                </span>
                <Box display={'flex'} gap={1}>
                    {renderHeaderButtons()}
                </Box>
            </Box>

            
            <ReactQuill
                id="rx-examination-findings"
                theme="snow"
                placeholder="Enter Comments"
                modules={EditorModules}
                value={state.physicalExamination}
                onChange={(content, _delta, _source, editor) => {
                    let newComment = content;
                    if (editor.getLength() <= 1) {
                        newComment = "";
                    }
                    if (editor.getLength() > 1500) {
                        newComment = newComment.substring(0, 1500);
                    }
                    setState({
                        physicalExamination:newComment
                    })
                    // setTabData((prev: any) => {
                //     return prev.map((section: any) => {
                //     if (section.view_type === MENSTRUAL_HISTORY) {
                //         return {
                //         ...section,
                //         rows: section.rows.map((row: any, index: number) => {
                //             if (index === 0) {
                //             return {
                //                 ...row,
                //                 value: row.value.map((val: any, index: number) => {
                //                 if (index === 0) {
                //                     return {
                //                     ...val,
                //                     comments: newComment ? newComment : "",
                //                     };
                //                 }
                //                 return val;
                //                 }),
                //             };
                //             }
                //             return row;
                //         }),
                //         };
                //     }
                //     return section;
                //     });
                // });
                }}
                onBlur={(e:any)=>{
                    updateHistory(e,state.physicalExamination,'comment');
                }}
                style={{ color: "#000000" }}
            />  

          </Box> */}


          {showHistory["Physical Examination"] && PhysicalExaminationRef}
          {showHistory["Trimester notes"] && TrimesterNotesRef}
          {showHistory["Per Abdomen"] && PerAbdomenRef}
          {showHistory["Breast"] && BreastRef}
          {showHistory["Per Vaginum"] && PerVaginumRef}
          {showHistory["Per Speculum"] && PerSpectrumRef}
          {showHistory["Other Findings"] && OtherFindingsRef}

          <>
            {Object.values(showHistory).includes(false) && (
              <>
                <Divider sx={{ mx: 0, mb: 2 }} variant="middle" />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      marginRight: "16px",
                      color: "#000000",
                      opacity: "87%",
                    }}
                  >
                    Add
                  </span>
                  <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                    {Object.entries(showHistory).map((arr) => {
                      const [key, value] = arr;
                      if (!value) {
                        return (
                          <Box
                            sx={{ mr: 2, cursor: "pointer" }}
                            onClick={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              const ariaLabel = target.ariaLabel as string;
                              setShowHistory({
                                ...showHistory,
                                [ariaLabel]: true,
                              });
                            }}
                            aria-label={key}
                            key={key}
                          >
                            <StyledChip
                              sx={{ pointerEvents: "none" }}
                              avatar={
                                <Avatar alt="" src="/images/icon_add.svg" />
                              }
                              label={key}
                            />
                          </Box>
                        );
                      }
                    })}
                  </Box>
                  
                </Box>
              </>
            )}
          </>
        </>
      );
    }
    return (
      <>
        <TabComponent tabActive={tabActive} index={0}>
          {PhysicalExaminationRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={1}>
          {TrimesterNotesRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={2}>
          {PerAbdomenRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={3}>
          {BreastRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={4}>
          {PerVaginumRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={5}>
          {PerSpectrumRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={6}>
          {OtherFindingsRef}
        </TabComponent>
      </>
    );
  };

  const updateHistory = (event?:SyntheticEvent, value?: string | null, forKey?:string)=>{
    
    const target =
      (event?.target as HTMLInputElement) ||
      (event?.target as HTMLElement);

    const ariaLabel = target?.ariaLabel as string;
    const inputName = target?.name as string;

    const key = forKey || inputName;

    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_NOTES) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PHYSICAL_EXAMINATION) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === key) {
                        return {
                          ...val,
                          value: value,
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
    return;
  }

  const renderComplaints = (view_type: string, value: any) => {
    const EmptyValueLabel = () => {
      return (
        <StyledTableCell
          sx={{
            display: "flex",
            flexDirection: "column",
            borderBottom: isMobileScreen ? "none" : "1px solid #eee",
            padding: isMobileScreen ? "8px 8px 8px 0px" : "16px",
            width: "100%",
          }}
          key={view_type}

        >
          <Box
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "132%",
              marginBottom: "5px",
            }}
            component="span"
          >
            {EmptyState[view_type]}
          </Box>
        </StyledTableCell>
      );
    };
    console.log("valuee",value?.some((elem:any)=>elem.value!==''))
    if (value?.some((elem:any)=>elem.value!=='')) {
      return value?.map((item: any) => {
        let { name, value } = item;
        let label;
        let icons:any[] = [];

        switch (view_type) {
          case PHYSICAL_EXAMINATION:
            label = `${value}`;
            break;
          case TRIMESTER_NOTES:
            label = `${value}`;
            break;
          case PER_ABDOMEN:
            label = `${value}`;
            break;
          case BREAST:
            label = `${value}`;
            break;
          case PER_VAGINUM:
            label = `${value}`;
            break;
          case PER_SPECULUM:
            label = `${value}`;
            break;
          case OTHER_FINDINGS:
            label = `${value}`;
            break;
        }

        const content = (
          <>
            <Box
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "132%",
                marginBottom: "5px",
              }}
              component="span"
            >
              {name}
            </Box>
            {label && (
              <Box
                sx={{
                  color: "#878787",
                  fontWeight: 400,
                  lineHeight: "132%",
                }}
                component="span"
              >
                {label}
              </Box>
            )}
          </>
        );

        return (
          <>
            <StyledTableCell
              className="histories"
              sx={{
                display: "flex",
                flexDirection: "column",
                borderBottom: isMobileScreen ? "none" : "1px solid #eee",
                padding: isMobileScreen ? "8px 8px 8px 0px" : "16px",
                maxWidth:  !isMobileScreen ? "200px" : undefined
              }}
              key={name}
            >
              {Array.isArray(icons) && icons.length !== 0 ? (
                // <Box
                //   className="SociakHistory"
                //   sx={{ display: "flex", alignItems: "center" }}
                // >
                //   <span style={{ marginRight: "9px" }}>
                //     <img
                //       src={`/images/${
                //         icons.filter((iName: any) => iName === name)[0]
                //       }.svg`}
                //     />
                //   </span>
                //   <Box
                //     className="SociakHistory"
                //     sx={{
                //       display: "flex",
                //       flexDirection: "column",
                //       justifyContent: isMobileScreen
                //         ? "space-between"
                //         : "center",
                //     }}
                //   >
                //     {content}
                //   </Box>
                // </Box>
                <></>
              ) : (
                content
              )}
            </StyledTableCell>
          </>
        );
      });
    }

    return <EmptyValueLabel />
  };

  const RenderReadOnly = () => {
    return (
      <Box>
        {data?.rows?.map((item: any) => {
          return (
            <Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "rgba(73, 78, 157, 1)",
                  }}
                >
                  {item?.header}
                </Typography>
              </Box>
              {item?.value?.length > 3 ? (
                <Box className="slider-container">
                  <Slider {...sliderMobileSetting}>
                    {renderComplaints(item?.view_type, item?.value)}
                  </Slider>
                </Box>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {renderComplaints(item?.view_type, item?.value)}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  const handleButtonClick = async (imageName: string) => {
    if (imageName === "icon_save.svg") {
      if (isMobileScreen) {
        // handleButtonClickMiniTMobile(imageName); // review-code
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === OBG_NOTES) {
              return {
                ...section,
                openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                searchExistingTemplateMobile: false,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenSaveTemplate(!openSaveTemplate);
      setOpenUpdateExistingTemplate(false);
    } else if (imageName === "icon_add_page.svg") {
      if (isMobileScreen) {
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === OBG_NOTES) {
              return {
                ...section,
                openSaveTemplateMobile: false,
                searchExistingTemplateMobile:
                  !section.searchExistingTemplateMobile,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenUpdateExistingTemplate(!serachExistingTemplate);
      setOpenSaveTemplate(false);
    }
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position:'relative'
          }}
          title="OBG Notes"
          isReadOnly={props?.isReadOnly}
        >
          <CardHeader
            headerImg="icon_obg_notes.svg"
            headerTitle="OBG Notes"
          >
              <>
                {renderHeaderButtons(
                  ["icon_save.svg", "icon_add_page.svg"],
                  handleButtonClick
                )}
              </>
          </CardHeader>
          {ShowEditableContent()}
          {openSaveTemplate && (
            <Box position="absolute" top="68px" right="79px" zIndex="1">
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {serachExistingTemplate && (
            <Box position="absolute" top="68px" right="39px" zIndex="1">
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
      {!loading && isMobileScreen && editSection && (
        <>
          <Divider sx={{ mx: 0 }} variant="middle" />
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                background: "transparent",
              }}
            >
              <Tabs
                value={tabActive}
                onChange={(_e, newValue) => {
                  setTabActive(newValue);
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                {Object.entries(showHistory).map((arr, index) => {
                  const [key] = arr;
                  return <Tab label={key} {...allyProps(index)} />;
                })}
              </Tabs>
            </Box>
            {ShowEditableContent()}
          </Box>
          {openSaveTemplateMobile && (
            <Box sx={SaveTemplateStyle}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {searchExistingTemplateMobile && (
            <Box sx={AddMiniTemplateStyle}>
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}
