const LeftArrowIcon = ({ height = "20", width = "20", color = "#23BCB5" }) => (
  <>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4902 9.216H6.72945L10.5569 5.38855C10.8628 5.08267 10.8628 4.5807 10.5569 4.27482C10.251 3.96894 9.7569 3.96894 9.45102 4.27482L4.28239 9.44345C3.97651 9.74933 3.97651 10.2434 4.28239 10.5493L9.45102 15.718C9.7569 16.0238 10.251 16.0238 10.5569 15.718C10.8628 15.4121 10.8628 14.918 10.5569 14.6121L6.72945 10.7846H15.4902C15.9216 10.7846 16.2745 10.4317 16.2745 10.0003C16.2745 9.56894 15.9216 9.216 15.4902 9.216Z"
        fill="#494E9D"
      />
    </svg>
  </>
);

export default LeftArrowIcon;
