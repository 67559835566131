/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */

// import { persistor } from "../../redux/store";
import { BASE_API_URL } from "../constants";
import { CustomEvents, sendInternalEvent } from "./utilities";
export const _BASE_URL = BASE_API_URL;

const targetWindow = window.parent; // Change this to the target window or iframe
const message = {
  type: "unAuth",
  content: "Unauthorized User",
};

export async function APIClient(
  endpoint: string,
  { body, ...customConfig } = {} as any,
  signal: any,
  useCustomBaseURL?: boolean
) {
  const headers: any = { "Content-Type": "application/json" };
  const token = localStorage.getItem("emrToken");

  let cAccountKey = "";
  if (localStorage.getItem("uAccounts") && localStorage.getItem("cAccount")) {
    let accountObj = JSON.parse(
      JSON.parse(localStorage.getItem("uAccounts") || "").account_ids
    );
    for (const [key, value] of Object.entries(accountObj)) {
      if (value === localStorage.getItem("cAccount")) {
        cAccountKey = key;
      }
    }
  }

  if (token) {
    let cAccountString = cAccountKey ? "-liveaccountid-" + cAccountKey : "";
    headers["Authorization"] = `Bearer ${localStorage.getItem(
      "emrToken"
    )}${cAccountString}`;
  }

  if (localStorage.getItem("account_id")) {
    headers["account_id"] = localStorage.getItem("account_id");
  }

  // if (localStorage.getItem("auth-type")) {
  //   headers["auth-type"] = localStorage.getItem("auth-type");
  // }
  // if (localStorage.getItem("application_id")) {
  //   headers["application_id"] = localStorage.getItem("application_id");
  // }

  // let application_slug;
  // if (localStorage.getItem("application_slug")) {
  //   application_slug = localStorage.getItem("application_slug");
  // }

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    signal: signal,
  };
  if (body) {
    config.body = JSON.stringify(body);
  }
  
  let data;
  const BASE_URL = useCustomBaseURL ? "" : _BASE_URL;

  sendInternalEvent(CustomEvents.REQUEST_SENT, { endpoint });
  const response = await window.fetch(BASE_URL + endpoint, config);
  if (response.ok) {
    sendInternalEvent(CustomEvents.REQUEST_SUCCESS, { endpoint });
    if (response.status === 204) {
      return {
        status: response.status,
        headers: response.headers,
        url: response.url,
      };
    }
    data = await response.json();
    // Return a result object similar to Axios
    return {
      status: response.status,
      data,
      headers: response.headers,
      url: response.url,
    };
  } else if (response.status === 401) {
    targetWindow.postMessage(message, window.location.origin);
    sendInternalEvent(CustomEvents.REQUEST_FAIL, { endpoint });
    return Promise.reject("Forbidden");
  } else if (response.status === 403) {
    targetWindow.postMessage(message, window.location.origin);
    data = await response.json();
    sendInternalEvent(CustomEvents.REQUEST_FAIL, { message: data?.error });
    return Promise.reject(data?.error || "Forbidden");
  } else if (response.status === 400 || response.status === 404) {
    data = await response.json();
    let message = data?.error;
    if (typeof data?.error === "boolean") {
      message = data?.message;
    }
    if (data?.errorDetails) {
      message = data?.errorDetails
        .map((e: any) => `${e.message || e.error}`)
        .join(", ");
    }
    sendInternalEvent(CustomEvents.REQUEST_FAIL, { message });
    return Promise.reject(message);
  }
  sendInternalEvent(CustomEvents.REQUEST_FAIL, {
    message: response.statusText || "Something went wrong",
  });
  return Promise.reject(response.statusText);
}

APIClient.get = function (
  endpoint: string,
  customConfig: any = {},
  signal = null,
  useCustomBaseURL = false
) {
  let params: any = [];

  if (customConfig.params) {
    for (let p in customConfig.params) {
      let key = encodeURIComponent(p);
      let value = customConfig.params[p];

      if (p === "last_evaluatedKey") {
        // If the key is last_evaluatedKey, handle its encoding differently
        value = encodeURIComponent(JSON.stringify(value));
      } else if (typeof value === "object") {
        // If the value is an object (excluding last_evaluatedKey), stringify it without URL encoding
        value = JSON.stringify(value);
      } else {
        value = encodeURIComponent(value);
      }

      params.push(`${key}=${value}`);
    }
  }

  if (params.length > 0) {
    endpoint += "?" + params.join("&");
  }

  return APIClient(
    endpoint,
    {
      ...customConfig,
      method: "GET",
    },
    signal,
    useCustomBaseURL
  );
};

APIClient.post = function (
  endpoint: string,
  body: any,
  customConfig = {},
  signal = null
) {
  return APIClient(endpoint, { ...customConfig, body, method: "POST" }, signal);
};

APIClient.put = function (
  endpoint: string,
  body: any,
  customConfig = {},
  signal = null
) {
  return APIClient(endpoint, { ...customConfig, body, method: "PUT" }, signal);
};

APIClient.patch = function (
  endpoint: string,
  body: any,
  customConfig = {},
  signal = null
) {
  return APIClient(
    endpoint,
    { ...customConfig, body, method: "PATCH" },
    signal
  );
};

APIClient.delete = function (
  endpoint: string,
  customConfig = {},
  signal = null
) {
  return APIClient(endpoint, { ...customConfig, method: "DELETE" }, signal);
};
