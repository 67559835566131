import { Box, IconButton, Tooltip, Typography } from "@mui/material";
// import CopyIcon from "../../../../shared/icons/CopyIcon";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setCopyData } from "../../../../redux/reducers/prescription.slice";

interface PrescriptionData {
  header: string;
}
interface PrescriptionDataProps {
  data: PrescriptionData;
  onClick?: (event: any) => void;
  appointment_status: any;
  activeTab: number;
}
function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    showTimeline: params.get("timeline"),
    showDocument: params.get("document"),
  };
}
const PrescriptionHeader: React.FC<PrescriptionDataProps> = ({
  data,
  appointment_status,
  activeTab,
}: any) => {
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();
  const { showTimeline, showDocument } = getQueryParams();
  const handleCopy = (data: any) => {
    dispatch(setCopyData(data));
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom="8px"
      >
        <Typography fontSize="16px" fontWeight="700" lineHeight="18.75px">
          {data?.header}
        </Typography>

        {appointment_status !== "Done" && data?.rows[0]?.value?.length > 0 && (
          <Box display="flex" alignItems="center">
            {showMessage && (
              <Typography
                fontSize="10px"
                fontWeight="500"
                color="#ffff"
                sx={{
                  background: "#646665",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                Copied to {data?.header} section
              </Typography>
            )}
            <Tooltip title={`Add to ${data?.header?.toLowerCase()}`}>
              <IconButton sx={{ padding: "0px" }}>
                {(!showTimeline || !showDocument) && activeTab === 0 && (
                  <AddBoxOutlinedIcon onClick={() => handleCopy(data)} />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </>
  );
};
export default PrescriptionHeader;
