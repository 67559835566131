const PdfIcon = () => {
  return (
    <>
      <svg
        width="27"
        height="26"
        viewBox="0 0 27 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3794_65051)">
          <rect
            x="0.953125"
            width="25.9048"
            height="25.9048"
            rx="3.2381"
            fill="white"
          />
          <path
            d="M15.7254 6.8811H10.2612C9.58998 6.8811 9.04688 7.42421 9.04688 8.09539V17.8097C9.04688 18.4809 9.58998 19.024 10.2612 19.024H17.5469C18.2181 19.024 18.7612 18.4809 18.7612 17.8097V9.91682L15.7254 6.8811ZM12.0826 11.7382C12.417 11.7382 12.6897 12.011 12.6897 12.3454C12.6897 12.6798 12.417 12.9525 12.0826 12.9525C11.7482 12.9525 11.4754 12.6798 11.4754 12.3454C11.4754 12.011 11.7482 11.7382 12.0826 11.7382ZM10.8683 15.9882L12.6897 13.5597L13.904 14.774L15.1183 12.9525L16.9397 15.9882H10.8683Z"
            fill="#FF5F5F"
          />
        </g>
        <defs>
          <clipPath id="clip0_3794_65051">
            <rect
              width="25.9048"
              height="25.9048"
              fill="white"
              transform="translate(0.953125)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export default PdfIcon;
