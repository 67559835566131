import Card from "../../utils/Card";
import CardHeader from "../../utils/CardHeader";
import {
  Box,
  IconButton,
  Modal,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DateCalendar,
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { CARD_SHADOW } from "../../utils/Constants";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import { FOLLOW_UP } from "../../../../../shared/constants";
import { useDispatch, useSelector } from "react-redux";
import { setEditRxSecMobile } from "../../../../../redux/reducers/prescription.slice";
import DOMPurify from "dompurify";
import Button from "../../../../../shared/Button/Button";
import CloseIcon from "@mui/icons-material/Close";

interface FollowUpProps {
  date: string;
  comment: string;
  number_of_days: string;
}

const EditorModules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    ["bold", "italic", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

export default function FollowUp(props: any) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [showFollowUpCalender, setShowFollowUpCalender] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const isFocused: boolean = props.isFocused || false;
  const sectionData = props?.sectionData?.[0]?.rows[0];
  const data = sectionData
    ? (sectionData.value[0] as FollowUpProps)
    : { date: "", comment: "", number_of_days: "" };
  const setTabData = props?.setTabData;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  useEffect(() => {
    if (sectionData && sectionData.value[0]) {
      setLoading(false);
    }
  }, [sectionData, instantRxData]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(FOLLOW_UP));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === FOLLOW_UP
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === FOLLOW_UP) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                   const newValue = item?.rows[0]?.value.map((val : any)=>{
                     const {date, number_of_days, comment} = val
                     const newValue=[
                      {
                        date: "",
                        number_of_days: "",
                        comment: comment,
                      }
                     ]
                     return newValue
                   });
                  return { ...row, value: newValue[0] };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const RenderReadOnly = () => {
    const examinationData: string = data.comment;
    const tempDiv = document.createElement("div");
    tempDiv.classList.add(isMobileScreen ? "free-text-mobile" : "free-text");
    const removeBreaks = /<p><br><\/p>/g;
    tempDiv.innerHTML = examinationData?.replace(removeBreaks, "");

    return (
      tempDiv.innerHTML?.length > 0 && (
        <Box>
          <Typography
            sx={{
              color: "#6F6F6F",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "160%",
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(tempDiv),
            }}
          ></Typography>
        </Box>
      )
    );
  };

  const handleDateChange = () => {
    setShowFollowUpCalender(true);
  };

  const handleCalenderDateChange = (date: any) => {
    setSelectedDate(date);
    const NoOfDays = Math.floor(dayjs(date).diff(dayjs(), "day", true)) + 1;
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === FOLLOW_UP) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.map((followUp: any, index: number) => {
                    if (index === 0) {
                      return {
                        ...followUp,
                        date: date,
                        number_of_days: NoOfDays,
                      };
                    }
                    return followUp;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
          }}
          title="Follow Up"
        >
          <CardHeader headerImg="icon_follow_up.svg" headerTitle="Follow Up">
            <></>
          </CardHeader>
          {!loading && (
            <Box sx={{ display: "flex", minHeight: "168px" }}>
              <Box
                sx={{
                  display: "flex",
                  mr: 2,
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="dark"
                      slotProps={{
                        textField: {
                          error: false,
                          disabled: true,
                        },
                      }}
                      format="DD/MM/YYYY"
                      label="Select a Date"
                      minDate={dayjs().add(1, "day")}
                      value={data.date ? dayjs(data.date) : null}
                      onChange={(value: any) => {
                        const NoOfDays =
                          Math.floor(dayjs(value).diff(dayjs(), "day", true)) +
                          1;
                        setTabData((prev: any) => {
                          return prev.map((section: any) => {
                            if (section.view_type === FOLLOW_UP) {
                              return {
                                ...section,
                                rows: section.rows.map(
                                  (row: any, index: number) => {
                                    if (index === 0) {
                                      return {
                                        ...row,
                                        value: row.value.map(
                                          (followUp: any, index: number) => {
                                            if (index === 0) {
                                              return {
                                                ...followUp,
                                                date: value,
                                                number_of_days: NoOfDays,
                                              };
                                            }
                                            return followUp;
                                          }
                                        ),
                                      };
                                    }
                                    return row;
                                  }
                                ),
                              };
                            }
                            return section;
                          });
                        });
                      }}
                    />
                  </LocalizationProvider>
                </Box>

                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    letterSpacing: "0.15px",
                    textAlign: "center",
                  }}
                >
                  OR
                </Box>
                <TextField
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  onChange={(event: any) => {
                    // Ensure that negative values are not accepted
                    const NoOfDays = event.target.value.replace(/[^0-9]/g, "");
                    if (NoOfDays < 0 || NoOfDays > 1000) {
                      return;
                    }
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === FOLLOW_UP) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: row.value.map(
                                      (followUp: any, index: number) => {
                                        if (index === 0) {
                                          return {
                                            ...followUp,
                                            date:
                                              +NoOfDays >= 1
                                                ? dayjs().add(NoOfDays, "day")
                                                : "",
                                            number_of_days:
                                              +NoOfDays >= 1
                                                ? +NoOfDays
                                                : NoOfDays,
                                          };
                                        }
                                        return followUp;
                                      }
                                    ),
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                  }}
                  autoComplete="off"
                  type="text"
                  value={data.number_of_days}
                  label="Number of Days"
                  fullWidth
                  variant="outlined"
                />
              </Box>

              <ReactQuill
                id="rx-follow-up"
                theme="snow"
                modules={EditorModules}
                value={data.comment}
                placeholder="Enter some advice for the patient"
                onChange={(content, delta, source, editor) => {
                  let newComment = content;
                  if (editor.getLength() <= 1) {
                    newComment = "";
                  }
                  if (editor.getLength() > 400) {
                    newComment = newComment.substring(0, 400);
                  }
                  setTabData((prev: any) => {
                    return prev.map((section: any) => {
                      if (section.view_type === FOLLOW_UP) {
                        return {
                          ...section,
                          rows: section.rows.map((row: any, index: number) => {
                            if (index === 0) {
                              return {
                                ...row,
                                value: row.value.map(
                                  (val: any, index: number) => {
                                    if (index === 0) {
                                      return {
                                        ...val,
                                        comment: newComment,
                                      };
                                    }
                                    return val;
                                  }
                                ),
                              };
                            }
                            return row;
                          }),
                        };
                      }
                      return section;
                    });
                  });
                }}
                style={{ width: "100%", height: "100%", color: "#000000" }}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    className="dark"
                    slotProps={{
                      textField: {
                        error: false,
                        // disabled: false, prev attr value : changed by tabish
                        disabled: true,
                        InputProps: {
                          endAdornment: (
                            <Box
                              component="img"
                              src={"/images/icon_mobile_calender.svg"}
                              sx={{ width: "24px", height: "24px" }}
                              onClick={handleDateChange}
                            />
                          ),
                        },
                      },
                    }}
                    format="DD/MM/YYYY"
                    label="Select a Date"
                    minDate={dayjs().add(1, "day")}
                    value={data.date ? dayjs(data.date) : null}
                    onChange={(value: any) => {
                      const NoOfDays =
                        Math.floor(dayjs(value).diff(dayjs(), "day", true)) + 1;
                      setTabData((prev: any) => {
                        return prev.map((section: any) => {
                          if (section.view_type === FOLLOW_UP) {
                            return {
                              ...section,
                              rows: section.rows.map(
                                (row: any, index: number) => {
                                  if (index === 0) {
                                    return {
                                      ...row,
                                      value: row.value.map(
                                        (followUp: any, index: number) => {
                                          if (index === 0) {
                                            return {
                                              ...followUp,
                                              date: value,
                                              number_of_days: NoOfDays,
                                            };
                                          }
                                          return followUp;
                                        }
                                      ),
                                    };
                                  }
                                  return row;
                                }
                              ),
                            };
                          }
                          return section;
                        });
                      });
                    }}
                  />
                </LocalizationProvider>
              </Box>

              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  letterSpacing: "0.15px",
                  textAlign: "center",
                  margin: "0 5px",
                }}
              >
                OR
              </Box>
              <TextField
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                onChange={(event: any) => {
                  // Ensure that negative values are not accepted
                  const NoOfDays = event.target.value.replace(/[^0-9]/g, "");
                  if (NoOfDays < 0 || NoOfDays > 1000) {
                    return;
                  }

                  setTabData((prev: any) => {
                    return prev.map((section: any) => {
                      if (section.view_type === FOLLOW_UP) {
                        return {
                          ...section,
                          rows: section.rows.map((row: any, index: number) => {
                            if (index === 0) {
                              return {
                                ...row,
                                value: row.value.map(
                                  (followUp: any, index: number) => {
                                    if (index === 0) {
                                      return {
                                        ...followUp,
                                        date:
                                          +NoOfDays >= 1
                                            ? dayjs().add(NoOfDays, "day")
                                            : "",
                                        number_of_days:
                                          +NoOfDays >= 1 ? +NoOfDays : NoOfDays,
                                      };
                                    }
                                    return followUp;
                                  }
                                ),
                              };
                            }
                            return row;
                          }),
                        };
                      }
                      return section;
                    });
                  });
                }}
                autoComplete="off"
                type="text"
                value={data.number_of_days}
                label="Number of Days"
                fullWidth
                variant="outlined"
              />
            </Box>

            <ReactQuill
              id="rx-follow-up"
              theme="snow"
              modules={EditorModules}
              value={data.comment}
              placeholder="Enter some advice for the patient"
              onChange={(content, delta, source, editor) => {
                let newComment = content;
                if (editor.getLength() <= 1) {
                  newComment = "";
                }
                if (editor.getLength() > 400) {
                  newComment = newComment.substring(0, 400);
                }
                setTabData((prev: any) => {
                  return prev.map((section: any) => {
                    if (section.view_type === FOLLOW_UP) {
                      return {
                        ...section,
                        rows: section.rows.map((row: any, index: number) => {
                          if (index === 0) {
                            return {
                              ...row,
                              value: row.value.map(
                                (val: any, index: number) => {
                                  if (index === 0) {
                                    return {
                                      ...val,
                                      comment: newComment,
                                    };
                                  }
                                  return val;
                                }
                              ),
                            };
                          }
                          return row;
                        }),
                      };
                    }
                    return section;
                  });
                });
              }}
              style={{ width: "100%", height: "100%", color: "#000000" }}
            />
            <Modal
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
              open={showFollowUpCalender}
              onClose={() => {
                setShowFollowUpCalender(false);
              }}
            >
              <Box
                className="filter-main-container"
                sx={{
                  background: "#ffff",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid #D7D7D7",
                    height: "54px",
                  }}
                >
                  <Typography
                    className="filter-heading"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "700",
                      color: "rgba(73, 78, 157, 1)",
                      marginLeft: "10px",
                    }}
                  >
                    Select Document Date
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setSelectedDate(null);
                      setShowFollowUpCalender(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box
                  className="filter-calender"
                  sx={{
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      value={selectedDate}
                      onChange={(date: any) => {
                        setSelectedDate(date);
                      }}
                      minDate={dayjs().add(1, "day")}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    paddingBottom: "16px",
                  }}
                >
                  <Button
                    onClick={() => setSelectedDate(null)}
                    variant="outlined"
                    text="Clear"
                    styles={{
                      width: "40%",
                    }}
                  />
                  <Button
                    onClick={() => {
                      handleCalenderDateChange(selectedDate);
                      setShowFollowUpCalender(false);
                    }}
                    variant="contained"
                    text="Apply"
                    styles={{
                      width: "40%",
                    }}
                  />
                </Box>
              </Box>
            </Modal>
          </Box>
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
    </>
  );
}
