/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Checkbox, Divider, FormControlLabel, Radio, RadioGroup, Skeleton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { SyntheticEvent, useEffect, useReducer, useState } from 'react'
import Card from '../../../utils/Card'
import { CARD_SHADOW } from '../../../utils/Constants'
import CardHeader from '../../../utils/CardHeader'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { MARITAL_HISTORY, MENSTRUAL_HISTORY, OBG_HISTORY } from '../../../../../../shared/constants'
import { DateField, DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


interface MenstrualHistoryProps {
    sex: string;
    delivery: string;
    birthWeight: string;
    age: string;
    comments: string;
    advice: string;
  }
  
  const EditorModules = {
    toolbar: [
      ["bold", "italic", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  
  const initState = {
    lmp:'',
    ageOfMenarche:'',
    daysOfBleeding:'',
    frequencyOfCycle:'',
    ageOfMenopause:'',
    addEDD:false,
    edd:'',
    eddScan:'',
    comments:'',
}

const MenstrualHistory = (props:any) => {

    const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isFocused: boolean = props.isFocused || false;
    const sectionData = props?.sectionData?.[0];

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
        ...state,
        ...action
    }
},initState)

  const { setTabData } = props;
//   const {
//     prescription: { instantRxData },
//   } = useSelector((state: any) => state.prescription);

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };

  useEffect(() => {
    if (sectionData) {
        const data = sectionData?.value;
        setState({
            lmp:data?.find((elem:any)=>elem?.name === 'LMP')?.value ? dayjs(data?.find((elem:any)=>elem?.name === 'LMP')?.value,'DD/MM/YYYY') : '',
            ageOfMenarche:data?.find((elem:any)=>elem?.name === 'Age of menarche')?.value,
            daysOfBleeding:data?.find((elem:any)=>elem?.name === 'Days of bleeding')?.value,
            frequencyOfCycle:data?.find((elem:any)=>elem?.name === 'Frequency of cycle')?.value,
            ageOfMenopause:data?.find((elem:any)=>elem?.name === 'Age of menopause')?.value,
            addEDD:(data?.find((elem:any)=>elem?.name === 'EDD')?.value || data?.find((elem:any)=>elem?.name === 'EDD Scan')?.value),
            edd:dayjs(data?.find((elem:any)=>elem?.name === 'EDD')?.value,'DD/MM/YYYY'),
            eddScan:dayjs(data?.find((elem:any)=>elem?.name === 'EDD Scan')?.value,'DD/MM/YYYY'),
            comments:data?.find((elem:any)=>elem?.name === 'comment')?.value,
        })
        
      setLoading(false);
    }
  }, [sectionData]);


  const renderHeaderButtons = ()=>{

    const buttons = [
        {
            name:'Delete',
            icon:'icon_delete_rx.svg',
            onClick:()=>{
                setState({initState})
                resetTabData();
                props?.setShowHistory({
                    ...props?.showHistory,
                    ["Menstrual History"]: false,
                })
            }
        },
    ]

    

    return (
        <>
            {buttons.map((elem,index)=>{
                return (
                    <button
                        key={elem.name}
                        style={{
                            outline: "none",
                            background: "none",
                            backgroundColor:theme.palette.primary.light,
                            padding: 8,
                            // marginRight: index !== imgs.length - 1 ? 16 : 0,
                            border: 0,
                            borderRadius:'50%',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                    >
                        <Tooltip
                            title={elem.name}
                        >
                            <img
                                onClick={elem.onClick}
                                src={`/images/${elem.icon}`}
                                alt={elem.icon}
                            />
                        </Tooltip>
                    </button>
                )
            })}
            
        </>
    )
    
  }

  const resetTabData = ()=>{
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === MENSTRUAL_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      return {...val,value:''};
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
  }

  const validateInput = (value:any,type:string)=>{
    switch(type){
        case 'ageOfMenarche':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'daysOfBleeding':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'frequencyOfCycle':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'ageOfMenopause':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'gravida':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'para':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'live':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'abortion':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
  }

  const updateHistory = (event?:SyntheticEvent, value?: string | null, forKey?:string)=>{
    
    const target =
      (event?.target as HTMLInputElement) ||
      (event?.target as HTMLElement);

    const ariaLabel = target?.ariaLabel as string;
    const inputName = target?.name as string;

    const key = forKey || inputName;

    let data;

    setTabData((prev: any) => {
        data = prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === MENSTRUAL_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === key) {
                        return {
                          ...val,
                          value: value,
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });

        console.log("Dataaa",data);
        return data;
      });
    return;
  }



  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Menstrual History"
        >
          <CardHeader
            headerImg=""
            headerTitle="Menstrual History"
          >
            <Box display={'flex'} gap={1}>
                {renderHeaderButtons()}
            </Box>
            
          </CardHeader>

          <Box display={'flex'} flexDirection={'column'} gap={2} mb={2}>
            
            <span style={{ fontWeight: "600", color: "#000" }}>
                Menstrual history
            </span>

            <Box display={'flex'} gap={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="LMP"
                        format="DD/MM/YYYY"
                        value={state.lmp}
                        onChange={(newValue:any) => {
                            setState({lmp:newValue})
                            const date = dayjs(newValue)?.format('DD/MM/YYYY');
                            updateHistory(undefined,date!='Invalid Date' ? date : '','LMP')
                        }}
                        slotProps={{
                          textField: {
                              error: false,
                              onKeyDown:(e:any)=>{
                                  e.preventDefault();
                              },
                              placeholder: "Select a date",
                              inputProps: {
                                  placeholder: "Select a date"
                              }
                          },
                        }}
                        
                        
                    />
                </LocalizationProvider>
                <TextField
                    name={`Age of menarche`}
                    label={'Age of menarche'}
                    value={state.ageOfMenarche}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'ageOfMenarche')){
                            setState({
                                ageOfMenarche:e.target.value
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                    
                />
                <TextField
                    name={`Days of bleeding`}
                    label={'Days of bleeding'}
                    value={state.daysOfBleeding}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'daysOfBleeding')){
                            setState({
                                daysOfBleeding:e.target.value
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                    
                />
                <TextField
                    name={`Frequency of cycle`}
                    label={'Frequency of cycle'}
                    value={state.frequencyOfCycle}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'frequencyOfCycle')){
                            setState({
                                frequencyOfCycle:e.target.value
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                    
                />
                <TextField
                    name={`Age of menopause`}
                    label={'Age of menopause'}
                    value={state.ageOfMenopause}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'ageOfMenopause')){
                            setState({
                                ageOfMenopause:e.target.value
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                />
            </Box>
            <Box display={'flex'} justifyContent={'start'}>
                <FormControlLabel control={    
                    <Checkbox
                        checked={state.addEDD} 
                        onClick={(e:any)=>{
                            setState({addEDD:!state.addEDD});
                        }} 
                
                    />} label={"Add EDD"} 
                />
            </Box>

            {state.addEDD && (
                <Box display={'flex'} justifyContent={'start'} gap={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="EDD"
                            value={state.edd}
                            format="DD/MM/YYYY"
                            onChange={(newValue:any) => {
                                setState({edd:newValue})
                                const date = dayjs(newValue)?.format('DD/MM/YYYY');
                                updateHistory(undefined,date!='Invalid Date' ? date : '','EDD')
                            }}
                            slotProps={{
                                textField: {
                                    error: false,
                                    onKeyDown:(e:any)=>{
                                        e.preventDefault();
                                    }
                                },
                            }}
                            
                        />
                        
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="EDD Scan"
                            value={state.eddScan}
                            format="DD/MM/YYYY"
                            onChange={(newValue:any) => {
                                setState({eddScan:newValue})
                                const date = dayjs(newValue)?.format('DD/MM/YYYY');
                                updateHistory(undefined,date!='Invalid Date' ? date : '','EDD Scan')
                            }}
                            slotProps={{
                                textField: {
                                    error: false,
                                    onKeyDown:(e:any)=>{
                                        e.preventDefault();
                                    }
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            )}

            <ReactQuill
                id="rx-examination-findings"
                theme="snow"
                placeholder="Enter Comments"
                modules={EditorModules}
                value={state.comments}
                onChange={(content, _delta, _source, editor) => {
                    let newComment = content;
                    if (editor.getLength() <= 1) {
                        newComment = "";
                    }
                    if (editor.getLength() > 1500) {
                        newComment = newComment.substring(0, 1500);
                    }
                    setState({
                        comments:newComment
                    })
                    // setTabData((prev: any) => {
                //     return prev.map((section: any) => {
                //     if (section.view_type === MENSTRUAL_HISTORY) {
                //         return {
                //         ...section,
                //         rows: section.rows.map((row: any, index: number) => {
                //             if (index === 0) {
                //             return {
                //                 ...row,
                //                 value: row.value.map((val: any, index: number) => {
                //                 if (index === 0) {
                //                     return {
                //                     ...val,
                //                     comments: newComment ? newComment : "",
                //                     };
                //                 }
                //                 return val;
                //                 }),
                //             };
                //             }
                //             return row;
                //         }),
                //         };
                //     }
                //     return section;
                //     });
                // });
                }}
                onBlur={(e:any)=>{
                    updateHistory(e,state.comments,'comment');
                }}
                style={{ color: "#000000" }}
            />  

          </Box>
          

          
        </Card>
      )}
      {!loading && isMobileScreen && (
        <>
            <Box display={'flex'} flexDirection={'column'} flexWrap={'wrap'} gap={2} mt={2}>
                <Box display={'flex'} gap={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="LMP"
                            format="DD/MM/YYYY"
                            value={state.lmp}
                            onChange={(newValue:any) => {
                                setState({lmp:newValue})
                                const date = dayjs(newValue)?.format('DD/MM/YYYY');
                                updateHistory(undefined,date!='Invalid Date' ? date : '','LMP')
                            }}
                            sx={{flex:1}}
                            slotProps={{
                            textField: {
                                error: false,
                                onKeyDown:(e:any)=>{
                                    e.preventDefault();
                                },
                                placeholder: "Select a date",
                                inputProps: {
                                    placeholder: "Select a date",
                                    
                                },
                            },
                            }}
                            
                            
                        />
                    </LocalizationProvider>
                    <TextField
                        name={`Age of menarche`}
                        label={'Age of menarche'}
                        value={state.ageOfMenarche}
                        onChange={(e)=>{
                            if(validateInput(e.target.value,'ageOfMenarche')){
                                setState({
                                    ageOfMenarche:e.target.value
                                })
                            }
                        }}
                        onBlur={(event: any) => {
                            updateHistory(event, event.target.value);
                        }}
                        sx={{flex:1}}
                        
                    />
                </Box>

                <Box display={'flex'} gap={2}>
                    <TextField
                        name={`Days of bleeding`}
                        label={'Days of bleeding'}
                        value={state.daysOfBleeding}
                        onChange={(e)=>{
                            if(validateInput(e.target.value,'daysOfBleeding')){
                                setState({
                                    daysOfBleeding:e.target.value
                                })
                            }
                        }}
                        onBlur={(event: any) => {
                            updateHistory(event, event.target.value);
                        }}
                        sx={{flex:1}}
                    />
                    <TextField
                        name={`Frequency of cycle`}
                        label={'Frequency of cycle'}
                        value={state.frequencyOfCycle}
                        onChange={(e)=>{
                            if(validateInput(e.target.value,'frequencyOfCycle')){
                                setState({
                                    frequencyOfCycle:e.target.value
                                })
                            }
                        }}
                        onBlur={(event: any) => {
                            updateHistory(event, event.target.value);
                        }}
                        sx={{flex:1}}
                    />
                </Box>

                <Box display={'flex'} gap={2} flex={1}>
                    <TextField
                        name={`Age of menopause`}
                        label={'Age of menopause'}
                        value={state.ageOfMenopause}
                        onChange={(e)=>{
                            if(validateInput(e.target.value,'ageOfMenopause')){
                                setState({
                                    ageOfMenopause:e.target.value
                                })
                            }
                        }}
                        onBlur={(event: any) => {
                            updateHistory(event, event.target.value);
                        }}
                        sx={{flex:1}}
                    />
                    <Box sx={{flex:1}}></Box>
                </Box>
                
                
            </Box>
            <Box display={'flex'} justifyContent={'start'} my={2}>
                <FormControlLabel control={    
                    <Checkbox
                        checked={state.addEDD} 
                        onClick={(e:any)=>{
                            setState({addEDD:!state.addEDD});
                        }} 
                
                    />} label={"Add EDD"} 
                />
            </Box>

            {state.addEDD && (
                <Box display={'flex'} justifyContent={'start'} gap={2} mb={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="EDD"
                            value={state.edd}
                            format="DD/MM/YYYY"
                            onChange={(newValue:any) => {
                                setState({edd:newValue})
                                const date = dayjs(newValue)?.format('DD/MM/YYYY');
                                updateHistory(undefined,date!='Invalid Date' ? date : '','EDD')
                            }}
                            slotProps={{
                                textField: {
                                    error: false,
                                    onKeyDown:(e:any)=>{
                                        e.preventDefault();
                                    }
                                },
                            }}
                            
                        />
                        
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="EDD Scan"
                            value={state.eddScan}
                            format="DD/MM/YYYY"
                            onChange={(newValue:any) => {
                                setState({eddScan:newValue})
                                const date = dayjs(newValue)?.format('DD/MM/YYYY');
                                updateHistory(undefined,date!='Invalid Date' ? date : '','EDD Scan')
                            }}
                            slotProps={{
                                textField: {
                                    error: false,
                                    onKeyDown:(e:any)=>{
                                        e.preventDefault();
                                    }
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            )}

            <ReactQuill
                id="rx-examination-findings"
                theme="snow"
                placeholder="Enter Comments"
                modules={EditorModules}
                value={state.comments}
                onChange={(content, _delta, _source, editor) => {
                    let newComment = content;
                    if (editor.getLength() <= 1) {
                        newComment = "";
                    }
                    if (editor.getLength() > 1500) {
                        newComment = newComment.substring(0, 1500);
                    }
                    setState({
                        comments:newComment
                    })
                    // setTabData((prev: any) => {
                //     return prev.map((section: any) => {
                //     if (section.view_type === MENSTRUAL_HISTORY) {
                //         return {
                //         ...section,
                //         rows: section.rows.map((row: any, index: number) => {
                //             if (index === 0) {
                //             return {
                //                 ...row,
                //                 value: row.value.map((val: any, index: number) => {
                //                 if (index === 0) {
                //                     return {
                //                     ...val,
                //                     comments: newComment ? newComment : "",
                //                     };
                //                 }
                //                 return val;
                //                 }),
                //             };
                //             }
                //             return row;
                //         }),
                //         };
                //     }
                //     return section;
                //     });
                // });
                }}
                onBlur={(e:any)=>{
                    updateHistory(e,state.comments,'comment');
                }}
                style={{ color: "#000000" }}
            />  
        </>
      )}
    </>
  )
}

export default MenstrualHistory;