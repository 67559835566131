import { Button as MuiButton } from "@mui/material";

interface ButtonProps {
  children?: React.ReactNode;
  text?: string;
  styles?: object;
  variant?: "text" | "outlined" | "contained";
  onClick?: (event: any) => void;
  startIcon?: React.ReactNode;
}
const Button = ({
  text,
  styles,
  variant,
  onClick,
  children,
  startIcon,
}: ButtonProps) => {
  return (
    <>
      <MuiButton
        onClick={onClick}
        variant={variant}
        sx={{ textTransform: "none !important", ...styles }}
        startIcon={startIcon}
      >
        {text}
        {children}
      </MuiButton>
    </>
  );
};
export default Button;
