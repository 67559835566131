import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import PdfReport from "../../pages/components/prescription/utils/PdfReview";
import { INVOICE_DOCUMENTS, SVAAS_DOCUMENTS } from "../constants";

type Props = {
  handleClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  modalOpen?: any;
  pdfUrl: string;
  displayType?: string;
  From?: string;
  pdfBlobProp?: Blob;
};
export default function PDFModal({
  modalOpen,
  handleClose,
  pdfUrl,
  displayType,
  From,
  pdfBlobProp
}: Props) {
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  useEffect(() => {
    if (From === SVAAS_DOCUMENTS) {
      const fetchPDF = async () => {
        const response: any = await fetch(pdfUrl);
        const data: any = await response.blob();
        setPdfBlob(data);
      };

      fetchPDF();
    }
    else if(From === INVOICE_DOCUMENTS){
      setPdfBlob(pdfBlobProp || null);
    }
  }, [modalOpen]);

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
        }}
      >
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
        <Box width="80%" height="80%" overflow="hidden" textAlign={"center"}>
          {displayType && displayType.split("/")[0] === "image" ? (
            <img
              src={pdfUrl}
              alt="Your Image Alt Text"
              height={"100%"}
              // width={"100%"}
            />
          ) : (
            <>
              
              {From === "PATIENT_DOCUMENTS" || From === SVAAS_DOCUMENTS || From === INVOICE_DOCUMENTS ? (
                <>
                  {" "}
                  <iframe
                    src={
                      From === SVAAS_DOCUMENTS || From === INVOICE_DOCUMENTS
                        ? pdfBlob
                          ? URL.createObjectURL(pdfBlob)
                          : ""
                        : pdfUrl
                    }
                    width="80%"
                    height="80%"
                  ></iframe>
                </>
              ) : (
                <embed
                  src={pdfUrl}
                  className={"embed"}
                  width="100%"
                  height="100%"
                  style={{
                    overflow: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                ></embed>
              )}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
