/* eslint-disable no-var */
/* eslint-disable prefer-const */
import {   useEffect, useReducer, useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  useTheme,
  useMediaQuery,
 
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginLayout from "../../../shared/layouts/LoginLayout";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { fetchProfile, setSelectedDoctor, setToken, setUser, toggleHelper } from "../../../redux/reducers/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import FormattedTextField from "../../../shared/Utils/indianMobileFormatInput";
import MobileLoginSplashScreen from "./MobileLoginSplashScreen";
import { ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC } from "../../../shared/routes/route.constants";


const initalState = {
  username:"",
  password: "",
  loading: false,
  notify: "No",
  verifyCode: "",
  showPassword: false,
  showResetNewPassword: false,
  showConfirmResetNewPassword: false,
  newPassword: "",
  confirmPassword: "",
  user: null,
  showChallengeNewPasswordView: false,
  settings: null,
  code: "",
  challengeName: null,
  otpValidation: false,
  show: false,
  alert: {
    type: "",
    title: "",
    text: "",
  },
  version: localStorage.getItem("appVersion"),
  rememberme: false,
  isError:0,
  showHelp:false,
  resetPasswordError:'',
};

const LoginPage = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [state, setState] = useReducer((state, action) => {
    return {
      ...state,
      ...action,
    };
  }, initalState);

  const [loading,setLoading] = useState(false);

  const [showSplashScreen,setShowSplashScreen] = useState(true);

  const {showHelper,profile} = useSelector((state)=>state.auth)

  const [showResetPassword,setShowResetPassword] = useState(false);

  useEffect(()=>{
    setTimeout(()=>{
      setShowSplashScreen(false);
    },3000)
    dispatch(setSelectedDoctor(""))
  },[])

  useEffect(()=>{
    //check local storage for token to prevent infinite looping 
    if(profile && localStorage.getItem('emrToken')){
      if(profile.role.includes('admin') || profile.role.includes('superadmin')){
        window.location.href = `${ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC}`;
      }else{
        window.location.href = `${"/appointments"}`;
      }
    }
  },[profile])
  
  const formatIndianMobileNumber = (num) => {
    // Remove all non-digit characters
    const digits = num.replace(/\D/g, '');
    // Format the number
    return digits.replace(/(\d{5})(\d{1,5})?/, '$1 $2').trim();
};

  function changeUsername(e) {
    e.preventDefault();
    setState({ 
      username: formatIndianMobileNumber(e.target.value),
      alert: {
        type: state.alert.type=="User Not Found" ? "" : state.alert.type,
        title: state.alert.title=="User Not Found" ? "" : state.alert.title,
        text: state.alert.text=="User Not Found" ? "" : state.alert.text,
      },
    });
  }

  function changePassword(e) {
    e.preventDefault();
    if(state.alert.text==='User is disabled.'){
      setState({
        password: e.target.value || "",
        alert: {
          type: "",
          title: "",
          text: "",
        },
      })
    }
    else{
      setState({
        password: e.target.value || "",
        alert: {
          type: state.alert.type=="Incorrect username or password." ? "" : state.alert.type,
          title: state.alert.title=="Incorrect username or password." ? "" : state.alert.title,
          text: state.alert.text=="Incorrect username or password." ? "" : state.alert.text,
        },
      });
    }
    
  }
  function changeResetNewPassword(e) {
    e.preventDefault();
    setState({
      newPassword: e.target.value || "",
      alert: {
        type: state.alert.type=="Incorrect Password" ? "" : state.alert.type,
        title: state.alert.title=="Incorrect Password" ? "" : state.alert.title,
        text: state.alert.text=="Incorrect Password" ? "" : state.alert.text,
      },
    });
  }
  function changeResetConfirmPassword(e) {
    e.preventDefault();
    setState({
      confirmPassword: e.target.value || "",
      alert: {
        type: state.alert.type=="Incorrect Password" ? "" : state.alert.type,
        title: state.alert.title=="Incorrect Password" ? "" : state.alert.title,
        text: state.alert.text=="Incorrect Password" ? "" : state.alert.text,
      },
    });
  }

  function changeRouteToForgotPassword() {
    navigate("/forgotpassword");
  }

  function handleClickShowPassword() {
    setState({ showPassword: !state.showPassword });
  }
  function handleMouseDownPassword() {
    setState({ showPassword: !state.showPassword });
  }
  function handleClickShowNewPassword() {
    setState({ showResetNewPassword: !state.showResetNewPassword });
  }
  function handleMouseDownNewPassword() {
    setState({ showResetNewPassword: !state.showResetNewPassword });
  }
  function handleClickShowConfirmPassword() {
    setState({ showConfirmResetNewPassword: !state.showConfirmResetNewPassword });
  }
  function handleMouseDownConfirmPassword() {
    setState({ showConfirmResetNewPassword: !state.showConfirmResetNewPassword });
  }

  const handleSigninSubmit = async () => {
    setState({ loading: true });
    setState({
      alert: {
        type: "",
        title: "",
        text: "",
      },
    });
    
    let user = "+91" + state?.username.replace(/\s/g, "");
    try {
      setLoading(true);
      var data = await Auth.signIn(user, state.password);
      
      // eslint-disable-next-line no-constant-condition
      if(data && data.challengeName === "NEW_PASSWORD_REQUIRED"){
        setState({
          challengeName:data.challengeName,
          showChallengeNewPasswordView:true,
          user:data,
          loading:false,
        })
        setShowResetPassword(true);
        setLoading(false);
        return;
      }else{
        localStorage.setItem("auth-type", "cognito");
        await dispatch(setToken(data.signInUserSession.idToken.jwtToken));
        const exp = data.signInUserSession?.idToken?.payload?.exp;
        const iat = data.signInUserSession?.idToken?.payload?.iat;
        localStorage.setItem('cognito-validity', exp);
        localStorage.setItem('cognito-timestamp', iat);
        localStorage.setItem('cognito-refresh-times', '0');


        var authk = data.signInUserSession.idToken.jwtToken;
        localStorage.setItem("emrToken", authk);
        if (data.signInUserSession.idToken.payload["custom:dblist"]) {
          let dbListArr =
            data.signInUserSession.idToken.payload["custom:dblist"].split(",");
          localStorage.setItem("s3AccountId", dbListArr[0]);
        }
        if (data.attributes["custom:misc"]) {
          let uAccounts = JSON.parse(data.attributes["custom:misc"]);
          localStorage.setItem("uAccounts", JSON.stringify(uAccounts));
        }
        dispatch(setUser(data));
        await dispatch(fetchProfile());

        // if(state.rememberme){
        //   localStorage.setItem('userDetails',JSON.stringify(data));
        //   Object.keys(localStorage).forEach(function(key){
        //       localStorage.setItem(key,localStorage.getItem(key))
        //   });
        // }

        localStorage.setItem('userDetails',JSON.stringify({attributes : data?.attributes}));
        Object.keys(localStorage).forEach(function(key){
            localStorage.setItem(key,localStorage.getItem(key))
        });
      
        return
      }
      

      
    } catch (error) {
      setLoading(false)
      setState({isError:state.isError+1});
      if(state.isError===3){
        dispatch(toggleHelper())
      }
     if (error.code === 'UserNotFoundException') {
      setState({
        alert: {
          type: "error",
          title: "",
          text: "User Not Found",
        },
      });
      // setMessageError("User Not Found")
      return
      }
      else{
        
        setState({
          alert: {
            type: "error",
            title: "",
            text: error.message,
          }})
          return
      }
      
    }
  };

  const handleResetPassword = async () => {
    setState({ loading: true });
    setState({
      alert: {
        type: "",
        title: "",
        text: "",
      },
    });

    try {
      setLoading(true);
      let data = await Auth.completeNewPassword(state.user, state.newPassword);
      if (data) {
        setLoading(false);
        setState({
          showChallengeNewPasswordView:false,
          username:'',
          password:'',
        })
        setShowResetPassword(false);
      }
    } catch (error) {
      setLoading(false);
      // setState({resetPasswordError:error})
      console.log(error);

    }
  }



  const toggleRememberMe = () => {
    setState({ rememberme: !state.rememberme });
  };
  const toggleShowHelp = () => {
    setState({ showHelp: !state.showHelp });
  };

  return (
    <>
      {isMobileScreen && showSplashScreen ? (
        <MobileLoginSplashScreen/>
      ) : (
        <LoginLayout setState={()=>toggleShowHelp()}>
          {!showResetPassword ? (
            <Box width={'100%'} height={{xs:'auto',md:'100%'}}>
              <Box
                component="img"
                width={{ xs: 180, md: 180 }}
                marginBottom={1}
                alt="svaas logo"
                src={"/images/mobiledashboardlogo.svg"}
              />
              <Typography
                variant="subtitle1"
                fontSize={{xs:15,md:20}}
                gutterBottom
                sx={{ marginBottom: 3 }}
              >
                <Typography
                  variant="subtitle1"
                  fontSize={{xs:15,md:20}}
                  sx={{ fontWeight: "bold", display: "inline" }}
                >
                  Welcome! &nbsp;
                </Typography>
                
                <Typography
                  variant="subtitle1"
                  fontSize={{xs:15,md:20}}
                  sx={{ fontWeight: "light", display: "inline" }}
                >
                  Login to manage your practice seamlessly.
                </Typography>
              </Typography>
              <Stack spacing={2}>
                <FormattedTextField
                  label="Mobile Number"
                  // type="number"
                  variant="outlined"
                  value={state.username}
                  onChange={changeUsername}
                  color={
                    state.alert.text==="User Not Found" ? "error" : "primary"
                  }
                  focused={state.alert.text==="User Not Found"}
                />
                {
                  state.alert.text && state.alert.text==="User Not Found" &&
                  <Box style={{marginTop:'4px'}} boxSizing={'border-box'} display={'flex'} gap={0.5} alignItems={'center'}>
                    <ErrorOutlineOutlinedIcon color="error" fontSize="8px"/>
                    <Typography color="error" variant="caption" style={{marginTop:"0px"}}>
                      {state.alert.text}
                    </Typography>{" "}
                  
                  </Box>
                }
                <TextField
                  label="Password"
                  variant="outlined"
                  type={state.showPassword ? "text" : "password"}
                  onChange={changePassword}
                  value={state.password || ""}
                  color={
                    state.alert.text && state.alert.text!=="User Not Found" ? "error" : undefined
                  }
                  focused={state.isError>0}
                  InputProps={{
                    endAdornment:
                      (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {state.showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>  
                      ), 
                      
                  }}
                  InputLabelProps={{ shrink: true }}  
                />
                {
                  state.alert.text && state.alert.text!=="User Not Found" &&
                  <Box style={{marginTop:'4px'}} boxSizing={'border-box'} display={'flex'} gap={0.5} alignItems={'center'}>
                    <ErrorOutlineOutlinedIcon color="error" fontSize="8px"/>
                    <Typography color="error" variant="caption" style={{marginTop:"0px"}}>
                      {state.alert.text}
                    </Typography>{" "}
                  
                  </Box>
                }
                {showHelper && <Box display={'flex'} gap={0} flexDirection={'column'} justifyContent={'start'} border={`1px solid ${theme.palette.primary.main}`} sx={{padding:"20px",borderRadius:'10px' ,backgroundColor:theme.palette.white.main}}>
                  <Typography variant="subtitle2" fontSize={17}>Need help</Typography>
                  <Typography variant="subtitle2" color={theme.palette.white.dark}>Please reach to support Team</Typography>
                  <Box display={'flex'} gap={4} mt={1}>
                    <Box display={'flex'} gap={1}>
                      <Box
                        component="img"
                        width={{xs:18,md:25}}
                        src="/images/phoneicon.svg"
                      />
                      <Typography  sx={{ typography: { sm: 'subtitle1', xs: 'caption' } }} color={theme.palette.primary.main}>9807990112</Typography>
                    </Box>
                    <Box display={'flex'} gap={1}>
                      <Box
                        component="img"
                        width={{xs:18,md:25}}
                        src="/images/mailicon.svg"
                      />
                        <Typography sx={{ typography: { sm: 'subtitle1', xs: 'caption' } }} color={theme.palette.primary.main}>emrsupport@drreddy.com</Typography>
                      </Box>
                  </Box>
                </Box>}
                <Stack direction="row" display="flex" justifyContent="space-between">
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={state.rememberme}
                          onClick={toggleRememberMe}
                        />
                      }
                      label={<Typography variant="subtitle1">Remember me</Typography>}
                    />
                  </FormControl>

                  <Button
                    variant="text"
                    onClick={changeRouteToForgotPassword}
                  >
                    
                    <Typography fontWeight={500} color={theme.palette.blue.highlight} textTransform={'none'} variant="subtitle1">Forgot Password?</Typography>
                  </Button>
                </Stack>
              </Stack>

              {/* <Button
                variant="text"
                sx={{width:'fit-content',my:1}}
                onClick={()=>{navigate("/loginotp");}}
              >
                <Typography textTransform={'none'}>Login with OTP instead of password </Typography>
              </Button> */}

              <LoadingButton
                size="large"
                variant="contained"
                loading={loading}
                disabled={(state.username.length === 11 && state.password ? false : true) || state.alert.text!==''}
                sx={{ marginBottom: 3, marginTop: 1,width:'100%' }}
                onClick={() => {
                  handleSigninSubmit();
                }}
              >
                  SIGN IN
              </LoadingButton>

              <Typography fontSize={12} color={theme.palette.white.dark}>
                By continuing, you agree that we create an account for you (unless
                already created), and accept our{" "}
                <Typography
                  fontSize={12}
                  color={theme.palette.blue.lightHighlight}
                  sx={{
                    textDecoration: "underline",
                    display: "inline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://svaaswellness.com/terms-conditions/")
                  }}
                >
                  Terms and Conditions
                </Typography>{" "}
                and{" "}
                <Typography
                  fontSize={12}
                  color={theme.palette.blue.lightHighlight}
                  sx={{
                    textDecoration: "underline",
                    display: "inline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://svaaswellness.com/privacy-policy/")
                  }}
                >
                  Privacy Policy
                </Typography>
                .
              </Typography>
            </Box>
          ) : (
            <Box width={'100%'} height={{xs:'auto',md:'100%'}}>
              <Typography
                  variant="h2"
                  fontSize={2}
                  gutterBottom
                  sx={{ marginBottom: 1 }}
              >
                  <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", display: "inline" }}
                  >
                      Reset Password
                  </Typography>
              
              </Typography>
              <Typography
                  variant="subtitle1"
                  sx={{marginBottom: 6}}
              >
                  Set a new password
              </Typography>

              <Stack spacing={2}>
                <TextField
                  label="New Password"
                  variant="outlined"
                  type={state.showResetNewPassword ? "text" : "password"}
                  onChange={changeResetNewPassword}
                  value={state.newPassword || ""}
                  color={
                    state.alert.text && state.alert.text!=="User Not Found" ? "error" : undefined
                  }
                  focused={state.isError>0}
                  InputProps={{
                    endAdornment:
                      (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownNewPassword}
                          >
                            {state.showResetNewPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>  
                      ), 
                      
                  }}
                  InputLabelProps={{ shrink: true }}  
                />
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  type={state.showConfirmResetNewPassword ? "text" : "password"}
                  onChange={changeResetConfirmPassword}
                  value={state.confirmPassword || ""}
                  color={
                    state.alert.text && state.alert.text!=="User Not Found" ? "error" : undefined
                  }
                  focused={state.isError>0}
                  InputProps={{
                    endAdornment:
                      (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          >
                            {state.showConfirmResetNewPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>  
                      ), 
                      
                  }}
                  InputLabelProps={{ shrink: true }}  
                />

                {state.resetPasswordError && <Typography variant="subtitle2" color={'red'}>{state.resetPasswordError}</Typography>}

              </Stack>

              <LoadingButton
                size="large"
                variant="contained"
                loading={loading}
                disabled={(state.newPassword && state.confirmPassword && state.newPassword === state.confirmPassword ? false : true) || state.alert.text!==''}
                sx={{ marginBottom: 3, marginTop: 1,width:'100%' }}
                onClick={() => {
                  handleResetPassword();
                }}
              >
                  SET
              </LoadingButton>

              <Typography fontSize={12} color={theme.palette.white.dark}>
                By continuing, you agree that we create an account for you (unless
                already created), and accept our{" "}
                <Typography
                  fontSize={12}
                  color={theme.palette.blue.lightHighlight}
                  sx={{
                    textDecoration: "underline",
                    display: "inline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://svaaswellness.com/terms-conditions/")
                  }}
                >
                  Terms and Conditions
                </Typography>{" "}
                and{" "}
                <Typography
                  fontSize={12}
                  color={theme.palette.blue.lightHighlight}
                  sx={{
                    textDecoration: "underline",
                    display: "inline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://svaaswellness.com/privacy-policy/")
                  }}
                >
                  Privacy Policy
                </Typography>
                .
              </Typography>
            </Box>
          )}
          
        </LoginLayout>
      )}
      

      
    </>
  );
};
      

export default LoginPage;
