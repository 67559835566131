import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


import { APIClient } from "../../shared/Utils/api-client";
import { API_ROUTE_CLINIC_DETAILS_ID, API_ROUTE_CREATE_CLINIC, API_ROUTE_FETCH_CLINICS, API_ROUTE_FETCH_DOCTOR_LIST, API_ROUTE_FETCH_USERS_LIST } from "../../shared/routes/route.constants";
// import { AlphaClient } from "../../shared/Utils/api-client";

const config = {
  name: "admin",
};
export const fetchAllClinics: any = createAsyncThunk(
  `${config.name}/fetchAllClinics`,
  async (data:any) => {
    const params = data?.params
    return await APIClient.get(API_ROUTE_FETCH_CLINICS(),{ params });
  }
);
export const fetchAllUsers: any = createAsyncThunk(
  `${config.name}/fetchAllUsers`,
  async (data:any) => {
    const params = data?.params
    return await APIClient.get(API_ROUTE_FETCH_USERS_LIST(data?.id,data?.isActive),{ params });
  }
);
export const fetchDoctorList: any = createAsyncThunk(
  `${config.name}/fetchDoctorList`,
  async (data:any) => {
    const params = data?.params
    return await APIClient.get(API_ROUTE_FETCH_DOCTOR_LIST(data?.role, data?.account_id),{ params });
  }
);
export const fetchClinicDetails: any = createAsyncThunk(
  `${config.name}/fetchClinicDetails`,
  async (data:any) => {
    const params = data?.params
    return await APIClient.get(API_ROUTE_CLINIC_DETAILS_ID(data?.account_id),{ params });
  }
);
export const createClinic: any = createAsyncThunk(
  `${config.name}/createClinic`,
  async (data:any) => {
    return await APIClient.post(API_ROUTE_CREATE_CLINIC(),data);
  }
);




//CRUD

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    clinicList: [] as any,
    loader: false as any,
    usersloader: false as any,
    error:'' as any,
    usersList: [] as any,
    doctorList: [] as any,  
    clinicDetails:{} as any,
},
  reducers: {
    resetUsersList: (state)=>{
        state.usersList = [];
    },
    resetClinicDetails: (state)=>{
        state.clinicDetails = {};
    }
  },
  extraReducers(builder: any) {
    builder
      .addCase(createClinic.pending, (state: any) => {
        state.loader = true;
      })
      .addCase(createClinic.fulfilled, (state: any,action:any) => {
        state.loader = false;
      })
      .addCase(createClinic.rejected, (state: any, action: any) => {
        state.loader = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchAllClinics.pending, (state: any) => {
        state.loader = true;
      })
      .addCase(fetchAllClinics.fulfilled, (state: any,action:any) => {
        state.loader = false;
        state.clinicList = action.payload?.data?.rows;
      })
      .addCase(fetchAllClinics.rejected, (state: any, action: any) => {
        state.loader = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchAllUsers.pending, (state: any) => {
        state.usersloader = true;
        state.loader = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state: any,action:any) => {
        state.usersloader = false;
        state.loader = false;
        state.usersList = action.payload?.data?.rows;
      })
      .addCase(fetchAllUsers.rejected, (state: any, action: any) => {
        state.usersloader = false;
        state.loader = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchDoctorList.pending, (state: any) => {
        state.loader = true;
      })
      .addCase(fetchDoctorList.fulfilled, (state: any,action:any) => {
        state.loader = false;
        state.doctorList = action.payload?.data?.rows;
      })
      .addCase(fetchDoctorList.rejected, (state: any, action: any) => {
        state.loader = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchClinicDetails.pending, (state: any) => {
        state.loader = true;
      })
      .addCase(fetchClinicDetails.fulfilled, (state: any,action:any) => {
        state.loader = false;
        state.clinicDetails = action.payload?.data;
      })
      .addCase(fetchClinicDetails.rejected, (state: any, action: any) => {
        state.loader = false;
        state.error = action.payload || action.error.message;
      })
      

  },
});

export const {
    resetUsersList,
    resetClinicDetails,
} = adminSlice.actions;
export default adminSlice.reducer;
