import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card from "../../utils/Card";
import CardHeader, { renderHeaderButtons } from "../../utils/CardHeader";
import {
  HTMLAttributes,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDebounce } from "../../../../../shared/Utils/useDebounce";
import {
  fetchSuggestions,
  seperateUrlAndParams,
} from "../../../../../shared/Utils/utils";
import { CARD_SHADOW } from "../../utils/Constants";
import { styled } from "@mui/system";
import { DIAGNOSIS } from "../../../../../shared/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setCopyData,
  setEditRxSecMobile,
} from "../../../../../redux/reducers/prescription.slice";
import DoctorSuggestions from "../../utils/DoctorSuggestions";
import TemplateCard from "../../utils/TemplateCard";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import { CustomTextarea } from "./patienthistory/utils/shared";
import CloseIcon from "@mui/icons-material/Close";

interface DiagnosisProps {
  id: string;
  name: string;
  state: string;
  comment: string;
}

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "8px",
    paddingLeft: 0,
    border: "none",
  },
});

const CustomDropDownPaper = styled(Paper)({
  minWidth: 900,
});

export default function Diagnosis(props: any) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);

  const [data, setData] = useState<DiagnosisProps[]>([]);
  const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>();
  const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
    useState<boolean>();

  const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
  const searchExistingTemplateMobile =
    props?.sectionData?.[0]?.searchExistingTemplateMobile;

  const [searchValue, setSearchValue] = useState("");
  const [valUpdateSearch, setValUpdateSearch] = useState("");
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {} as DiagnosisProps,
  });

  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);

  const sectionData = props?.sectionData?.[0]?.rows[0];
  const setTabData = props?.setTabData;
  const isFocused: boolean = props.isFocused || false;
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const {
    copyData,
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const MiniTemplateStyle = {
    position: !isMobileScreen ? "absolute" : "none",
    top: "68px",
    right: "79px",
    zIndex: 1,
    height: "0px",
  };

  const AddMiniTemplateStyle = {
    position: !isMobileScreen ? "absolute" : "none",
    top: "68px",
    right: "39px",
    zIndex: 1,
    height: "0px",
  };

  const stateMaster = sectionData?.master?.state?.values || [];

  const autoref = useRef<HTMLDivElement>(null);

  const apiurldata = sectionData?.search?.api;

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(DIAGNOSIS));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === DIAGNOSIS
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  const updateSearchOptions = (value: any) => {
    if (apiurldata && value) {
      const { url, query_params } = seperateUrlAndParams(apiurldata);
      fetchSuggestions(
        url,
        {
          ...query_params,
          name: value,
        },
        true // use custom base url
      ).then((response) => {
        const responseArr = response?.data?.data;
        const optionsArr = responseArr?.map((option: any) => option.name);
        if (!optionsArr.some((opt: any) => opt === value)) {
          setOptions([value, ...optionsArr]);
          setOptionsData([
            {
              id: `custom${Date.now()}`,
              name: value,
            },
            ...responseArr,
          ]);
          return;
        }
        setOptions(optionsArr);
        setOptionsData(responseArr);
      });
    }
  };
  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length !== 0 && searchValue.trim().length >= 3) {
      updateSearchOptions(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (valUpdateSearch.length !== 0) {
      updateSearchOptions(altDebouncedSearch);
    }
  }, [altDebouncedSearch]);

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === DIAGNOSIS) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  useEffect(() => {
    if (copyData?.view_type === DIAGNOSIS) {
      handleSelectedTemplateData(copyData);
      dispatch(setCopyData([] as any));
    }
  }, [copyData]);

  const assignAddDiagnosisMobile = (value: string) => {
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;
    if (!value) return;
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        id,
        name,
        state: "",
        comment: "",
      },
    });
  };

  const doctorSuggestionProps = {
    sKey: "diagnosis",
    sHeading: "Suggested diagnosis",
    headerImg: isMobileScreen
      ? "/images/icon_mobile_resp_suggestion.svg"
      : "/images/icon_dummy.svg",
    handleAdd: (event: any) => {
      const currentTarget = event?.currentTarget;
      const suggestion = currentTarget?.attributes["data-key"]?.nodeValue;
      const clickAction = currentTarget?.attributes["data-action"]?.nodeValue;

      if (!data.some((history: any) => history.name === suggestion)) {
        switch (clickAction) {
          case "ADD":
            if (!isMobileScreen) {
              addDiagnosis(null as any, suggestion);
            } else {
              assignAddDiagnosisMobile(suggestion);
            }
            break;
          case "UPDATE":
            updateDiagnosis(event as any, suggestion);
            break;
          default:
            return;
        }
      }
      if (autoref.current) {
        autoref.current.blur();
      }
    },
  };

  const handleDialogState = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
      // content :
    });
  };

  const dialogProps = {
    DialogOpen: dialog.open ? dialog.open : false,
    onHandleDialog: handleDialogState,
    headerStyles: { display: "none" },
    PaperPropsStyle: {
      margin: 0,
      width: "100%",
    },
    Layoutstyles: {
      "& .MuiDialog-container": {
        alignItems: "flex-end",
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
      },
    },
    footerStyles: {
      display: "none",
    },
    CloseButtonStyles:{
      display: "none",
    },
    onHandleSave: () => {
      // not required, custom save option is there
      return;
    },
  };

  const addDiagnosis = (event: SyntheticEvent, value: string | null) => {
    if (!value) {
      return;
    }
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;

    const newDiagnosis: DiagnosisProps = {
      id,
      name,
      state: "",
      comment: "",
    };
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === DIAGNOSIS) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: [...row.value, newDiagnosis],
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
    setSearchValue("");
  };

  const updateDiagnosis = (event: SyntheticEvent, value: string | null) => {
    let newValue: any, newId: any;

    const target =
      (event.currentTarget as HTMLInputElement) ||
      (event.currentTarget as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName).split("-");

    const key = nameArr[nameArr.length - 1];

    const currId = nameArr.reduce((acc, val) => {
      if (val === key) {
        return acc;
      }
      return acc + "-" + val;
    });

    if (key === "name") {
      const optionObj = optionsData.find(
        (optObj: any) => optObj.name === value
      );
      const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
      const name = optionObj?.name ? optionObj.name : value;
      newValue = name;
      newId = id;
    } else {
      (newId = currId), (newValue = value);
    }
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === DIAGNOSIS) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.map((diagnosis: any) => {
                    if (diagnosis?.id?.toString() === currId) {
                      return {
                        ...diagnosis,
                        id: newId,
                        [key]: newValue,
                      };
                    }
                    return diagnosis;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
  };

  const removeDiagnosis = (event: SyntheticEvent<Element, Event>) => {
    const elem = event.currentTarget as HTMLElement;
    const { id } = elem;
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === DIAGNOSIS) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (val: any) => val?.id?.toString() !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
  };

  const handleButtonClick = async (imageName: string) => {
    if (imageName === "icon_save.svg") {
      if (isMobileScreen) {
        // handleButtonClickMiniTMobile(imageName); // review-code
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === DIAGNOSIS) {
              return {
                ...section,
                openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                searchExistingTemplateMobile: false,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenSaveTemplate(!openSaveTemplate);
      setOpenUpdateExistingTemplate(false);
    } else if (imageName === "icon_add_page.svg") {
      if (isMobileScreen) {
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === DIAGNOSIS) {
              return {
                ...section,
                openSaveTemplateMobile: false,
                searchExistingTemplateMobile:
                  !section.searchExistingTemplateMobile,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenUpdateExistingTemplate(!serachExistingTemplate);
      setOpenSaveTemplate(false);
    }
  };

  const DiagnosisCardMobile = (Diagnosis: DiagnosisProps, index: number) => {
    const { id, name, state, comment } = Diagnosis;
    return (
      <Card
        styles={{
          borderRadius: "8px",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
          padding: 0,
        }}
        key={index + id}
      >
        <Box id={id}>
          {/* Card Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <Typography
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              color="#494E9D"
            >
              {name}
            </Typography>
            <IconButton>
              <Box
                id={id}
                aria-label="delete-diagnosis"
                onClick={removeDiagnosis}
                component="img"
                src="/images/DeleteIcon.svg"
              ></Box>
            </IconButton>
          </Box>
          <Divider />
          {/* Card Contents */}
          <Box padding="12px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  component="img"
                  marginRight="4px"
                  src="/images/info-icon.svg"
                ></Box>
                <Box>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="100%"
                    marginBottom="8px"
                    color="rgba(0, 0, 0, 0.87)"
                  >
                    {state}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Box
                component="img"
                marginRight="4px"
                src="/images/CommentIcon.svg"
              ></Box>
              <Box>
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.87)"
                >
                  Comments
                </Typography>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="160%"
                  color="#6F6F6F"
                >
                  {comment}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    );
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        {data.map((datum, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
                fontFamily: "Roboto, sans-serif",
              }}
              key={index}
            >
              {datum.name}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Diagnosis"
        >
          <CardHeader headerImg="icon_diagnosis.svg" headerTitle="Diagnosis">
            {renderHeaderButtons(
              ["icon_save.svg", "icon_add_page.svg"],
              handleButtonClick
            )}
          </CardHeader>
          <TableContainer sx={{ mb: 2, boxShadow: "none" }} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="all-diagnosis">
              <TableBody>
                {data.map((diagnosis, index) => {
                  const { id, name, state, comment } = diagnosis;
                  return (
                    <TableRow key={`complaint-${index}-${id}`}>
                      <StyledTableCell align="left">
                        <Autocomplete
                          id={`${id}-name`}
                          aria-label={`${id}-name`}
                          filterOptions={(options, { inputValue }) => {
                            return options.filter((option) => true);
                          }}
                          options={valUpdateSearch.length === 0 ? [] : options}
                          disableClearable
                          value={name}
                          clearOnBlur
                          clearOnEscape
                          noOptionsText={
                            <DoctorSuggestions
                              clickSource={`${id}-name`}
                              clickAction="UPDATE"
                              {...doctorSuggestionProps}
                            />
                          }
                          popupIcon={false}
                          clearIcon={false}
                          onChange={updateDiagnosis}
                          PaperComponent={({ children }) => (
                            <CustomDropDownPaper>
                              {children}
                            </CustomDropDownPaper>
                          )}
                          renderInput={(params) => {
                            return (
                              <TextField
                                inputRef={autoref}
                                autoComplete="off"
                                multiline
                                label="Diagnosis"
                                onChange={(event: any) => {
                                  if (event.target.value === "") {
                                    setOptions([]);
                                  }
                                  setValUpdateSearch(event.target.value);
                                }}
                                {...params}
                                fullWidth
                                variant="outlined"
                              />
                            );
                          }}
                          renderOption={(
                            props: HTMLAttributes<HTMLLIElement>,
                            option: string,
                            state
                          ) => {
                            // if (
                            //   !data.some(
                            //     (complaint: any) => complaint.name === option
                            //   )
                            // ) {
                              return (
                                <Box
                                  component="li"
                                  aria-label={`${id}-name`}
                                  sx={{
                                    backgroundColor: state.selected
                                      ? "#F2F3FF !important"
                                      : "",
                                    "&:hover, &:focus": {
                                      backgroundColor: "#F2F3FF !important",
                                    },
                                  }}
                                  {...props}
                                >
                                  {!optionsData
                                    .find((opt: any) => opt.name === option)
                                    ?.id?.toString()
                                    .includes("custom")
                                    ? option
                                    : `Add "${option}"`}
                                </Box>
                              );
                            // }
                          }}
                          sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" } }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          aria-label={`${id}-comment`}
                          name={`${id}-comment`}
                          autoComplete="off"
                          title={comment}
                          value={comment}
                          onChange={(event) => {
                            let value = event.target.value;
                            if (value.length > 250) {
                              value = value.substring(0, 250);
                            }
                            setData((prev: any) => {
                              return prev.map((elem: any) => {
                                if (elem.id.toString() === id.toString()) {
                                  return {
                                    ...elem,
                                    comment: value,
                                  };
                                }
                                return elem;
                              });
                            });
                          }}
                          onBlur={(event: any) => {
                            updateDiagnosis(event as any, event.target.value);
                          }}
                          label="Comments"
                          fullWidth
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ paddingLeft: "16px !important" }}
                        align="center"
                      >
                        <FormControl fullWidth>
                          <RadioGroup row>
                            {stateMaster.map((stateOption: any) => {
                              return (
                                <FormControlLabel
                                  key={stateOption}
                                  title={stateOption}
                                  value={stateOption}
                                  id={`${id}-state`}
                                  aria-label={`${id}-state`}
                                  name={`${id}-state`}
                                  checked={
                                    state === ""
                                      ? stateOption === "Provisional"
                                      : state === stateOption
                                  }
                                  onClick={(event: any) => {
                                    const { innerText, value } = event.target;
                                    if (
                                      (value || innerText) &&
                                      state !== (value || innerText)
                                    )
                                      updateDiagnosis(
                                        event as any,
                                        innerText || value
                                      );
                                  }}
                                  control={<Radio />}
                                  label={stateOption}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ width: "32px", padding: "0 !important" }}
                        align="right"
                      >
                        <Tooltip title={"Delete"}>
                          <button
                            id={id}
                            onClick={removeDiagnosis}
                            style={{
                              outline: "none",
                              border: "none",
                              background: "none",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              style={{ pointerEvents: "none" }}
                              src="/images/minus-in-circle.svg"
                              alt="remove-button"
                            />
                          </button>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Autocomplete
            id="search-for-daignosis"
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            value={""}
            clearOnBlur
            clearOnEscape
            inputValue={searchValue}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} data={data} />
            }
            onChange={addDiagnosis}
            popupIcon={false}
            clearIcon={false}
            renderInput={(params) => {
              return (
                <TextField
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for diagnosis"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((diagnosis: any) => diagnosis.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplate && (
            <Box position="absolute" top="68px" right="79px" zIndex="1">
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {serachExistingTemplate && (
            <Box position="absolute" top="68px" right="39px" zIndex="1">
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Autocomplete
            id="search-for-daignosis"
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            value={""}
            clearOnBlur
            clearOnEscape
            inputValue={searchValue}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} data={data} />
            }
            onChange={(event, value) => {
              assignAddDiagnosisMobile(value);
            }}
            popupIcon={false}
            clearIcon={false}
            renderInput={(params) => {
              return (
                <TextField
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for diagnosis"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((diagnosis: any) => diagnosis.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplateMobile && (
            <Box className="templated" sx={{ height: "0px" }}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {searchExistingTemplateMobile && (
            <Box className="templated" sx={AddMiniTemplateStyle}>
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
          <Box marginTop="16px">
            {data.length !== 0 &&
              data.map((diagnosis, index) => {
                return (
                  <Box
                    component="div"
                    id={diagnosis.id}
                    onClick={(_event: any) => {
                      if (_event.target.ariaLabel === "delete-diagnosis") {
                        return;
                      }
                      setDialog({
                        ...dialog,
                        open: true,
                        mode: "UPDATE",
                        content: diagnosis,
                      });
                    }}
                    key={index}
                  >
                    {DiagnosisCardMobile(diagnosis, index)}
                  </Box>
                );
              })}
          </Box>
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
      {isMobileScreen && (
        <CustomizedDialogs {...(dialogProps as any)}>
          <Box margin="12px">
            <Box className="header" display="flex">
              <Box
                component="img"
                sx={{ marginRight: 1.5 }}
                src="/images/icon_diagnosis.svg"
              />
              <Box className="text-content" sx={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <Box>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  Diagnosis
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="100%"
                  color="#494E9D"
                >
                  {dialog.content.name}
                </Typography>
                </Box>
                <Box onClick={handleDialogState}>
                  <CloseIcon sx={{ color: theme.palette.grey[500] }} />
                </Box>
              </Box>
            </Box>
            <Divider sx={{ margin: "16px 0" }} />
            <Box className="content" marginBottom="24px">
              <Box marginBottom="24px" width="100%" gap="8px">
                <FormControl sx={{ marginBottom: "4px" }} fullWidth>
                  <RadioGroup row>
                    {stateMaster.map((stateOption: any) => {
                      const { id, state } = dialog.content;
                      return (
                        <FormControlLabel
                          key={stateOption}
                          title={stateOption}
                          value={stateOption}
                          id={`${id}-state`}
                          aria-label={`${id}-state`}
                          name={`${id}-state`}
                          checked={
                            state === ""
                              ? stateOption === "Provisional"
                              : state === stateOption
                          }
                          onClick={(event: any) => {
                            const { innerText, value } = event.target;
                            if (
                              (value || innerText) &&
                              state !== (value || innerText)
                            ) {
                              setDialog({
                                ...dialog,
                                content: {
                                  ...dialog.content,
                                  state: innerText || value,
                                },
                              });
                            }
                          }}
                          control={<Radio />}
                          label={stateOption}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                <CustomTextarea
                  placeholder="Comments"
                  value={dialog.content.comment}
                  maxRows={4}
                  onChange={(event: any) => {
                    let newValue = event.target.value;
                    console.log({ newValue });
                    if (newValue.length > 250) {
                      newValue = newValue.substring(0, 250);
                    }
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        comment: newValue,
                      },
                    });
                  }}
                  minRows={2}
                />
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none !important",
                  borderRadius: "6px",
                }}
                fullWidth
                onClick={() => {
                  if (dialog.mode === "ADD" && dialog.content.id) {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === DIAGNOSIS) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: [
                                      ...row.value,
                                      {
                                        ...dialog.content,
                                        state:
                                          dialog.content.state.length !== 0
                                            ? dialog.content.state
                                            : "Provisional",
                                      },
                                    ],
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setSearchValue("");
                  }
                  if (dialog.mode === "UPDATE" && dialog.content.id) {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === DIAGNOSIS) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: row.value.map((val: any) => {
                                      if (val.id === dialog.content.id) {
                                        return {
                                          ...dialog.content,
                                        };
                                      }
                                      return val;
                                    }),
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                  }
                }}
              >
                {dialog.mode === "ADD" ? `Add` : `Update`} Diagnosis
              </Button>
            </Box>
          </Box>
        </CustomizedDialogs>
      )}
    </>
  );
}
