interface InstantRxProps {
  type?: string;
}
const InstantRxIcons = ({ type }: InstantRxProps) => {
  return (
    <>
      {type === "create-instant" ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.5625 3V18.4359C4.5625 18.9882 5.01022 19.4359 5.5625 19.4359H15.8702C16.4225 19.4359 16.8702 18.9882 16.8702 18.4359V3C16.8702 2.44772 16.4225 2 15.8702 2H5.5625C5.01022 2 4.5625 2.44771 4.5625 3Z"
            stroke="#494E9D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.64062 6.10352L13.7945 6.10352"
            stroke="#494E9D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.64062 9.17969L13.7945 9.17969"
            stroke="#494E9D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.64062 12.2578L13.7945 12.2578"
            stroke="#494E9D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.64062 15.334L13.7945 15.334"
            stroke="#494E9D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5641 4.56445H3C2.44772 4.56445 2 5.01217 2 5.56445V21.0004C2 21.5526 2.44772 22.0004 3 22.0004H13.3077C13.86 22.0004 14.3077 21.5526 14.3077 21.0004V19.4362"
            stroke="#494E9D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.9219 14.8209V6.10291C18.9219 5.59009 19.2296 4.56445 20.4603 4.56445C21.6911 4.56445 21.9988 5.59009 21.9988 6.10291V14.8209M18.9219 14.8209H21.9988M18.9219 14.8209L20.4603 17.385L21.9988 14.8209"
            stroke="#494E9D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3346 7.22401V21.0837C19.3346 21.6359 18.8869 22.0837 18.3346 22.0837H5.66797C5.11568 22.0837 4.66797 21.6359 4.66797 21.0837V2.91699C4.66797 2.36471 5.11569 1.91699 5.66797 1.91699H14.0965M19.3346 7.22401L14.0965 1.91699M19.3346 7.22401H15.0965C14.5443 7.22401 14.0965 6.7763 14.0965 6.22401V1.91699"
            stroke="#494E9D"
            stroke-linecap="round"
          />
          <path
            d="M12 9.70801V14.2913"
            stroke="#494E9D"
            stroke-linecap="round"
          />
          <path
            d="M9.70703 12H14.2904"
            stroke="#494E9D"
            stroke-linecap="round"
          />
        </svg>
      )}
    </>
  );
};

export default InstantRxIcons;
