/* eslint-disable @typescript-eslint/no-unused-vars */
import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, Skeleton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { SyntheticEvent, useEffect, useReducer, useState } from 'react'
import Card from '../../../utils/Card'
import { CARD_SHADOW } from '../../../utils/Constants'
import CardHeader from '../../../utils/CardHeader'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { OBG_HISTORY, PREGNANCY_HISTORY } from '../../../../../../shared/constants'
import CloseIcon from '@mui/icons-material/Close';
import { getDurationOptions } from '../../../../../../shared/Utils/utilities'

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "14px",
    boxShadow: 24,
    p: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "14px",
};

interface PregnancyHistoryProps {
    sex: string;
    delivery: string;
    birthWeight: string;
    age: string;
    comments: string;
    advice: string;
  }
  
  const EditorModules = {
    toolbar: [
      ["bold", "italic", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const initModalState = {
    open:false,
    text:'',
    onAccept:()=>{return},
    onCancel:()=>{return}
  }
  
  const initState = {
    obstetricFormula:{
        gpla:'',
        lcb:'',
    },
    noAbortionHistory:false,
    abortion:[
        {
            when:'',
            abortionType:'',
            comments:'',
        }
    ],
    noEctopicHistory:false,
    ectopic:[
        {
            when:'',
            comments:'',
        }
    ],
    coitalHistory:'',
    otherDetails:'',
    abortionTypeOptions:['Spontaneous','MTP','Missed'],
  }

  const initAbortion = {
    when:'',
    abortionType:'',
    comments:'',
  }

  const initEctopic = {
    when:'',
    comments:'',
  }

const PregnancyHistory = (props:any) => {

    const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isFocused: boolean = props.isFocused || false;
  const sectionData = props?.sectionData?.[0];

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
        ...state,
        ...action
    }
},initState)

    const [modalState,setModalState] = useReducer((state:any,action:any)=>{
        return {
            ...state,
            ...action
        }
    },initModalState)

    const [complaintDurationOptions, setComplaintDurationOptions] = useState<
    string[]
  >([]);

  const { setTabData } = props;
//   const {
//     prescription: { instantRxData },
//   } = useSelector((state: any) => state.prescription);

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };


  useEffect(()=>{
    console.log("state",state)
  },[state])

  useEffect(() => {
    if (sectionData) {
        const data = sectionData?.value;
        const gpla = data?.find((elem:any)=>elem?.name === 'GPLA')
        const lcb = data?.find((elem:any)=>elem?.name === 'LCB')
        const abortion = data?.find((elem:any)=>elem?.name === 'Abortion type')
        const ectopic = data?.find((elem:any)=>elem?.name === 'Ectopic')
        const coitalHistory = data?.find((elem:any)=>elem?.name === 'coital_history_comment')
        const otherDetails = data?.find((elem:any)=>elem?.name === 'other_details_comment')
        
        setState({
            obstetricFormula:{
                gpla:gpla?gpla?.value:'',
                lcb:lcb?lcb?.value:'',
            },
            noAbortionHistory:Array.isArray(abortion?.value) ? !abortion?.value?.length : true,
            abortion:Array.isArray(abortion?.value) ? abortion?.value : [],
            noEctopicHistory: Array.isArray(ectopic?.value) ? !ectopic?.value?.length : true,
            ectopic:Array.isArray(ectopic?.value) ? ectopic?.value : [],
            coitalHistory:coitalHistory ? coitalHistory?.value : '',
            otherDetails:otherDetails ? otherDetails?.value : '',
        })

        setLoading(false);
    }
  }, [sectionData]);


  const renderHeaderButtons = ()=>{

    const buttons = [
        {
            name:'Delete',
            icon:'icon_delete_rx.svg',
            onClick:()=>{
                setState({initState})
                resetTabData();
                props?.setShowHistory({
                    ...props?.showHistory,
                    ["Pregnancy History"]: false,
                })
            }
        },
    ]

    

    return (
        <>
            {buttons.map((elem,index)=>{
                return (
                    <button
                        key={elem.name}
                        style={{
                            outline: "none",
                            background: "none",
                            backgroundColor:theme.palette.primary.light,
                            padding: 8,
                            // marginRight: index !== imgs.length - 1 ? 16 : 0,
                            border: 0,
                            borderRadius:'50%',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                    >
                        <Tooltip
                            title={elem.name}
                        >
                            <img
                                onClick={elem.onClick}
                                src={`/images/${elem.icon}`}
                                alt={elem.icon}
                            />
                        </Tooltip>
                    </button>
                )
            })}
            
        </>
    )
    
  }
  const renderHeaderButtons2 = (type:string)=>{

    const buttons = [
        {
            name:'Add',
            icon:'icon_add_rx.svg',
            onClick:()=>{
                if(type==='abortion' && !state.noAbortionHistory){

                    setState({
                        abortion:[...state.abortion,initAbortion]
                    })
                }else if(type === 'ectopic' && !state.noEctopicHistory){
                    setState({
                        ectopic:[...state.ectopic,initEctopic]
                    })
                }
            }
        },
        {
            name:'Copy',
            icon:'icon_copy_rx.svg',
            onClick:()=>{
                if(type==='abortion' && !state.noAbortionHistory && state.abortion?.length > 0){
                    handleCopyAbortionHistory([...state.abortion,state.abortion[state.abortion?.length-1]])
                    setState({
                        abortion:[...state.abortion,state.abortion[state.abortion?.length-1]]
                    })
                }else if(type === 'ectopic' && !state.noEctopicHistory && state.ectopic?.length > 0){
                    handleCopyEctopicHistory([...state.ectopic,state.ectopic[state.ectopic?.length-1]])
                    setState({
                        ectopic:[...state.ectopic,state.ectopic[state.ectopic?.length-1]]
                    })
                }
            }
        },
        {
            name:'Delete',
            icon:'icon_delete_rx.svg',
            onClick:()=>{
                if(type==='abortion' && !state.noAbortionHistory && state.abortion?.length > 0){
                    setModalState({
                        open:true,
                        text:'One you click delete the “Abortion details” will be removed  do you still want to proceed?',
                        onAccept:handleDeleteAbortionHistory,
                        onCancel:()=>{
                            setModalState({
                                open:false
                            })
                        }
                    })
                }else if(type === 'ectopic' && !state.noEctopicHistory && state.ectopic?.length > 0){
                    setModalState({
                        open:true,
                        text:'One you click delete the “Ectopic details” will be removed  do you still want to proceed?',
                        onAccept:handleDeleteEctopicHistory,
                        onCancel:()=>{
                            setModalState({
                                open:false
                            })
                        }
                    })
                }
            },
            hide:(type==='abortion' && state.noAbortionHistory) ? true : (
                    (type==='ectopic' && state.noEctopicHistory) ? true : false
                )
            
        },
    ]

    

    return (
        <>
            {buttons.map((elem,index)=>{
                return (
                    <button
                        key={elem.name}
                        style={{
                            outline: "none",
                            background: "none",
                            backgroundColor:theme.palette.primary.light,
                            padding: 8,
                            // marginRight: index !== imgs.length - 1 ? 16 : 0,
                            border: 0,
                            borderRadius:'50%',
                            display:elem?.hide ? "none" : 'flex',
                            justifyContent:'center',
                            alignItems:'center',
                        }}
                    >
                        <Tooltip
                            title={elem.name}
                        >
                            <img
                                onClick={elem.onClick}
                                src={`/images/${elem.icon}`}
                                alt={elem.icon}
                            />
                        </Tooltip>
                    </button>
                )
            })}
            
        </>
    )
    
  }

  const validateInput = (value:any,type:string)=>{
    switch(type){
        case 'GPLA':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'lcb':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'when':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'comments':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
  }

  const updateHistory = (event?:SyntheticEvent, value?: any | null, forKey?:string)=>{
    
    const target =
      (event?.target as HTMLInputElement) ||
      (event?.target as HTMLElement);

    const ariaLabel = target?.ariaLabel as string;
    const inputName = target?.name as string;

    let key = forKey || inputName;

    let keyArr:any
    if(forKey === 'abortion' || forKey === 'ectopic'){
        key = inputName
        keyArr = key?.split('_');
    }

    let data;

    setTabData((prev: any) => {
        data = prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PREGNANCY_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === (keyArr?.length ? keyArr[0] : key)) {
                        let valArr = [];
                        if((forKey === 'abortion' && state?.abortion?.length) || 
                            (forKey === 'ectopic' && state?.ectopic?.length)
                        ){
                            valArr = state[forKey]
                        }
                        return {
                          ...val,
                          value: !keyArr?.length ? value : valArr?.map((elem:any,i:any)=>{
                            if(i == +keyArr[1]){
                                return {
                                    ...elem,
                                    [keyArr[2]]:value
                                }
                            }
                            return elem
                          }),
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
        console.log("data",data)
        return data;
      });
    return;
  }

  const resetTabData = ()=>{
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PREGNANCY_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      return {...val,value:''};
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
  }

  const toggleNoAbortionHistory = () => {

    if(!state.noAbortionHistory){
        setState({
            abortion:[],
            noAbortionHistory:!state.noAbortionHistory
        })
        setTabData((prev: any) => {
            return prev.map((section: any) => {
              if (section.view_type === OBG_HISTORY) {
                return {
                  ...section,
                  rows: section.rows.map((row: any) => {
                    if (row.view_type === PREGNANCY_HISTORY) {
                      return {
                        ...row,
                        value: row?.value?.map((val: any) => {
                          if (val?.name === 'Abortion type') {
                            
                            return {
                              ...val,
                              value: []
                            };
                          }
                          return val;
                        }),
                      };
                    }
                    return row;
                  }),
                };
              }
              return section;
            });
          });
    }else{
        setState({
            noAbortionHistory:!state.noAbortionHistory
        })
    }
    
  }
  const toggleNoEctopicHistory = () => {

    if(!state.noEctopicHistory){
        setState({
            ectopic:[],
            noEctopicHistory:!state.noEctopicHistory
        })
        setTabData((prev: any) => {
            return prev.map((section: any) => {
              if (section.view_type === OBG_HISTORY) {
                return {
                  ...section,
                  rows: section.rows.map((row: any) => {
                    if (row.view_type === PREGNANCY_HISTORY) {
                      return {
                        ...row,
                        value: row?.value?.map((val: any) => {
                          if (val?.name === 'Ectopic') {
                            
                            return {
                              ...val,
                              value: []
                            };
                          }
                          return val;
                        }),
                      };
                    }
                    return row;
                  }),
                };
              }
              return section;
            });
          });
    }else{
        setState({
            noEctopicHistory:!state.noEctopicHistory
        })
    }
    
  }

  const handleDeleteAbortionHistory = () => {
    setState({
        abortion:[]
    })
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PREGNANCY_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === 'Abortion type') {
                        
                        return {
                          ...val,
                          value: []
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
      setModalState({
        open:false
      })
  }

  const handleDeleteEctopicHistory = () => {
    setState({
        ectopic:[]
    })
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PREGNANCY_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === 'Ectopic') {
                        
                        return {
                          ...val,
                          value: []
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
    setModalState({
        open:false
    })
  }

  const handleCopyAbortionHistory = (value:any) => {
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PREGNANCY_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === 'Abortion type') {
                        
                        return {
                          ...val,
                          value: value
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
  }
  const handleCopyEctopicHistory = (value:any) => {
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PREGNANCY_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === 'Ectopic') {
                        
                        return {
                          ...val,
                          value: value
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
  }

  const updateComplaintDurationOptions = (
    event: SyntheticEvent<Element, Event>
  ) => {
    const elem = event.target as HTMLInputElement;
    const { value } = elem;

    if (value == "") {
      setComplaintDurationOptions([]);
      return;
    }

    if (!/^[1-9][0-9]*$/.test(value) || value === "") {
      const newOptionArr = complaintDurationOptions.filter((option) =>
        option.includes(value)
      );

      if (newOptionArr) {
        setComplaintDurationOptions(newOptionArr);
        return;
      }

      setComplaintDurationOptions([]);
      return;
    }

    const optionArr = getDurationOptions(value);

    if (optionArr) {
      setComplaintDurationOptions(optionArr);
    }
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Pregnancy History"
        >
          <CardHeader
            headerImg=""
            headerTitle="Pregnancy History"
          >
            <Box display={'flex'} gap={1}>
                {renderHeaderButtons()}
            </Box>
            
          </CardHeader>

          <Box display={'flex'} flexDirection={'column'} gap={4}>
            <Box display={'flex'} alignItems={'center'} sx={{width:'100%'}} gap={2}>
               
               <Typography sx={{width:200}}>Obstetric Formula</Typography>

                <TextField
                    label={'GPLA'}
                    name='GPLA'
                    placeholder={'Enter text'}
                    // InputLabelProps={{ shrink: true }}
                    value={state?.obstetricFormula?.gpla}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'GPLA')){
                            setState({
                                obstetricFormula:{
                                    ...state.obstetricFormula,
                                    gpla:e.target.value
                                }
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                    sx={{width:'200px'}}
                />
                <TextField
                    label={'LCB'}
                    name='LCB'
                    value={state?.obstetricFormula?.lcb}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'lcb')){
                            setState({
                                obstetricFormula:{
                                    ...state.obstetricFormula,
                                    lcb:e.target.value
                                }
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                    sx={{width:'200px'}}
                />

               
            </Box>

            <Divider/>

            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{width:'100%'}} gap={2}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                    <Typography>Abortion</Typography>
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                        <FormControlLabel control={    
                            <Checkbox
                                checked={state.noAbortionHistory} 
                                onClick={(e:any)=>{
                                    toggleNoAbortionHistory()
                                    
                                }} 
                        
                            />} label={"No Abortion History"} 
                        />
                        {renderHeaderButtons2("abortion")}
                    </Box>
                </Box>

                {!state.noAbortionHistory && (
                    <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'start'} sx={{width:'100%'}}>
                        {state.abortion?.map((elem:any,index:any)=>{
                            return (
                                <Box display={'flex'} gap={2} alignItems={'center'} sx={{width:'100%'}}>
                                    <Autocomplete
                                        // disableClearable
                                        id={`Abortion type_${index}_when`}
                                        // aria-label={`${id}-since`}
                                        // noOptionsText={""}
                                        
                                        title={elem?.when}
                                        options={complaintDurationOptions}
                                        filterOptions={(options, { inputValue }) => {
                                        return options.filter((option) => true);
                                        }}
                                        // open={
                                        //   complaintDurationOptions.length === 0
                                        //     ? false
                                        //     : true
                                        // }
                                        // onChange={(e:any)=>{
                                        //     if(validateInput(e.target.value,'when')){
                                        //         setState({
                                        //             abortion: state.abortion.map((el:any,i:any)=>{
                                        //                 if(i===index){
                                        //                     return {
                                        //                         ...el,
                                        //                         when:e.target.value
                                        //                     }
                                        //                 }
                                        //                 return el;
                                        //             })
                                        //         })
                                        //     }
                                        // }}
                                        onBlur={(event: any) => {
                                            updateHistory(event, event.target.value,"abortion");
                                        }}
                                        value={elem?.when}
                                        clearIcon={false}
                                        renderOption={(props, option, state) => {
                                            return (
                                                <Box
                                                // aria-label={`${id}-since`}
                                                component="li"
                                                sx={{
                                                    backgroundColor: state.selected
                                                    ? "#F2F3FF !important"
                                                    : "",
                                                    "&:hover, &:focus": {
                                                    backgroundColor: "#F2F3FF !important",
                                                    },
                                                }}
                                                // id={`${id}-since-${option}`}
                                                {...props}
                                                >
                                                {option}
                                                </Box>
                                            );
                                        }}
                                        renderInput={(params) => {
                                        return (
                                            <TextField
                                                name={`Abortion type_${index}_when`}
                                                onChange={updateComplaintDurationOptions}
                                                {...params}
                                                value={elem?.when}
                                                label="When"
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, style: { minWidth: '200px' } }} 
                                            />
                                        );
                                        }}
                                        sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" },
                                        }}
                                    />
                                    {/* <TextField
                                        label={'When'}
                                        name={`Abortion type_${index}_when`}
                                        value={elem?.when}
                                        onChange={(e)=>{
                                            if(validateInput(e.target.value,'when')){
                                                setState({
                                                    abortion: state.abortion.map((el:any,i:any)=>{
                                                        if(i===index){
                                                            return {
                                                                ...el,
                                                                when:e.target.value
                                                            }
                                                        }
                                                        return el;
                                                    })
                                                })
                                            }
                                        }}
                                        onBlur={(event: any) => {
                                            updateHistory(event, event.target.value,"abortion");
                                        }}
                                        sx={{width:'200px'}}
                                    /> */}


                                    <FormControl sx={{width:'200px'}}>
                                        <InputLabel id="demo-simple-select-label">Abortion type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={elem?.abortionType}
                                            label="Abortion type"
                                            name={`Abortion type_${index}_abortionType`}
                                            onChange={(e)=>{
                                                setState({
                                                    abortion: state.abortion.map((el:any,i:any)=>{
                                                        if(i===index){
                                                            return {
                                                                ...el,
                                                                abortionType:e.target.value
                                                            }
                                                        }
                                                        return el;
                                                    })
                                                })
                                            }}
                                            onBlur={(event: any) => {
                                                updateHistory(event, event.target.value,"abortion");
                                            }}
                                        >
                                            {state.abortionTypeOptions?.map((op:any)=>{
                                                return (
                                                    <MenuItem value={op}>{op}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>

                                    

                                    <TextField
                                        label={'Enter Comments'}
                                        name={`Abortion type_${index}_comments`}
                                        value={elem?.comments}
                                        onChange={(e)=>{
                                            if(validateInput(e.target.value,'comments')){
                                                setState({
                                                    abortion: state.abortion.map((el:any,i:any)=>{
                                                        if(i===index){
                                                            return {
                                                                ...el,
                                                                comments:e.target.value
                                                            }
                                                        }
                                                        return el;
                                                    })
                                                })
                                            }
                                        }}
                                        onBlur={(event: any) => {
                                            updateHistory(event, event.target.value,"abortion");
                                        }}
                                        sx={{flex:1}}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                )}
            </Box>

            <Divider/>


            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{width:'100%'}} gap={2}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                    <Typography>Ectopic</Typography>
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                        <FormControlLabel control={    
                            <Checkbox
                                checked={state.noEctopicHistory} 
                                onClick={(e:any)=>{
                                    toggleNoEctopicHistory()
                                }} 
                        
                            />} label={"No Ectopic History"} 
                        />
                        {renderHeaderButtons2("ectopic")}
                    </Box>
                </Box>

                {!state.noEctopicHistory && (
                    <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'start'} sx={{width:'100%'}}>
                        {state.ectopic?.map((elem:any,index:any)=>{
                            return (
                                <Box display={'flex'} gap={2} alignItems={'center'} sx={{width:'100%'}}>

                                    <Autocomplete
                                        // disableClearable
                                        id={`Ectopic_${index}_when`}
                                        // aria-label={`${id}-since`}
                                        // noOptionsText={""}
                                        
                                        title={elem?.when}
                                        options={complaintDurationOptions}
                                        filterOptions={(options, { inputValue }) => {
                                        return options.filter((option) => true);
                                        }}
                                        // open={
                                        //   complaintDurationOptions.length === 0
                                        //     ? false
                                        //     : true
                                        // }
                                        // onChange={(e:any)=>{
                                        //     if(validateInput(e.target.value,'when')){
                                        //         setState({
                                        //             abortion: state.abortion.map((el:any,i:any)=>{
                                        //                 if(i===index){
                                        //                     return {
                                        //                         ...el,
                                        //                         when:e.target.value
                                        //                     }
                                        //                 }
                                        //                 return el;
                                        //             })
                                        //         })
                                        //     }
                                        // }}
                                        onBlur={(event: any) => {
                                            updateHistory(event, event.target.value,"ectopic");
                                        }}
                                        value={elem?.when}
                                        clearIcon={false}
                                        renderOption={(props, option, state) => {
                                            return (
                                                <Box
                                                // aria-label={`${id}-since`}
                                                component="li"
                                                sx={{
                                                    backgroundColor: state.selected
                                                    ? "#F2F3FF !important"
                                                    : "",
                                                    "&:hover, &:focus": {
                                                    backgroundColor: "#F2F3FF !important",
                                                    },
                                                }}
                                                // id={`${id}-since-${option}`}
                                                {...props}
                                                >
                                                {option}
                                                </Box>
                                            );
                                        }}
                                        renderInput={(params) => {
                                        return (
                                            <TextField
                                                name={`Ectopic_${index}_when`}
                                                onChange={updateComplaintDurationOptions}
                                                {...params}
                                                value={elem?.when}
                                                label="When"
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, style: { minWidth: '200px' } }} 
                                            />
                                        );
                                        }}
                                        sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" },
                                        }}
                                    />


                                    {/* <TextField
                                        label={'When'}
                                        name={`Ectopic_${index}_when`}
                                        value={elem?.when}
                                        onChange={(e)=>{
                                            if(validateInput(e.target.value,'when')){
                                                setState({
                                                    ectopic: state.ectopic.map((el:any,i:any)=>{
                                                        if(i===index){
                                                            return {
                                                                ...el,
                                                                when:e.target.value
                                                            }
                                                        }
                                                        return el;
                                                    })
                                                })
                                            }
                                        }}
                                        onBlur={(event: any) => {
                                            updateHistory(event, event.target.value, "ectopic");
                                        }}
                                        sx={{width:'200px'}}
                                    /> */}
                                    

                                    <TextField
                                        label={'Enter Comments'}
                                        name={`Ectopic_${index}_comments`}
                                        value={elem?.comments}
                                        onChange={(e)=>{
                                            if(validateInput(e.target.value,'comments')){
                                                setState({
                                                    ectopic: state.ectopic.map((el:any,i:any)=>{
                                                        if(i===index){
                                                            return {
                                                                ...el,
                                                                comments:e.target.value
                                                            }
                                                        }
                                                        return el;
                                                    })
                                                })
                                            }
                                        }}
                                        onBlur={(event: any) => {
                                            updateHistory(event, event.target.value, "ectopic");
                                        }}
                                        sx={{flex:1}}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                )}
            </Box>


            <Divider/>

            <Box display={'flex'} flexDirection={'column'} alignItems={'start'} sx={{width:'100%'}} gap={2}>

                <Typography>Coital History</Typography>
                <ReactQuill
                    id="rx-examination-findings"
                    theme="snow"
                    placeholder="Enter Comments"
                    modules={EditorModules}
                    value={state.coitalHistory}
                    onChange={(content, _delta, _source, editor) => {
                        let newComment = content;
                        if (editor?.getLength() <= 1) {
                            newComment = "";
                        }
                        if (editor?.getLength() > 1500) {
                            newComment = newComment?.substring(0, 1500);
                        }
                        setState({
                            coitalHistory:newComment
                        })
                        // setTabData((prev: any) => {
                    //     return prev.map((section: any) => {
                    //     if (section.view_type === MENSTRUAL_HISTORY) {
                    //         return {
                    //         ...section,
                    //         rows: section.rows.map((row: any, index: number) => {
                    //             if (index === 0) {
                    //             return {
                    //                 ...row,
                    //                 value: row.value.map((val: any, index: number) => {
                    //                 if (index === 0) {
                    //                     return {
                    //                     ...val,
                    //                     comments: newComment ? newComment : "",
                    //                     };
                    //                 }
                    //                 return val;
                    //                 }),
                    //             };
                    //             }
                    //             return row;
                    //         }),
                    //         };
                    //     }
                    //     return section;
                    //     });
                    // });
                    }}
                    onBlur={(e:any)=>{
                        updateHistory(e,state.coitalHistory,'coital_history_comment');
                    }}
                    style={{ color: "#000000",width:'100%' }}
                />
            </Box>
            <Divider/>

            <Box display={'flex'} flexDirection={'column'} alignItems={'start'} sx={{width:'100%'}} gap={2}>

                <Typography>Other details</Typography>
                <ReactQuill
                    id="rx-examination-findings"
                    theme="snow"
                    placeholder="Enter Comments"
                    modules={EditorModules}
                    value={state.otherDetails}
                    onChange={(content, _delta, _source, editor) => {
                        let newComment = content;
                        if (editor?.getLength() <= 1) {
                            newComment = "";
                        }
                        if (editor?.getLength() > 1500) {
                            newComment = newComment?.substring(0, 1500);
                        }
                        setState({
                            otherDetails:newComment
                        })
                        // setTabData((prev: any) => {
                    //     return prev.map((section: any) => {
                    //     if (section.view_type === MENSTRUAL_HISTORY) {
                    //         return {
                    //         ...section,
                    //         rows: section.rows.map((row: any, index: number) => {
                    //             if (index === 0) {
                    //             return {
                    //                 ...row,
                    //                 value: row.value.map((val: any, index: number) => {
                    //                 if (index === 0) {
                    //                     return {
                    //                     ...val,
                    //                     comments: newComment ? newComment : "",
                    //                     };
                    //                 }
                    //                 return val;
                    //                 }),
                    //             };
                    //             }
                    //             return row;
                    //         }),
                    //         };
                    //     }
                    //     return section;
                    //     });
                    // });
                    }}
                    onBlur={(e:any)=>{
                        updateHistory(e,state.otherDetails,'other_details_comment');
                    }}
                    style={{ color: "#000000",width:'100%' }}
                />
            </Box>

            
          </Box>
          

          
        </Card>
      )}
      {!loading && isMobileScreen && (
        <>
          <Box display={'flex'} flexDirection={'column'} gap={4}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'start'} sx={{width:'100%'}} gap={2}>
               
               <Typography fontWeight={'bold'} my={1}>Obstetric Formula</Typography>


                <Box display={'flex'} gap={2}>
                  <TextField
                      label={'GPLA'}
                      name='GPLA'
                      placeholder={'Enter text'}
                    //   InputLabelProps={{ shrink: true }}
                      value={state?.obstetricFormula?.gpla}
                      onChange={(e)=>{
                          if(validateInput(e.target.value,'GPLA')){
                              setState({
                                  obstetricFormula:{
                                      ...state.obstetricFormula,
                                      gpla:e.target.value
                                  }
                              })
                          }
                      }}
                      onBlur={(event: any) => {
                          updateHistory(event, event.target.value);
                      }}
                      sx={{flex:1}}
                  />
                  <TextField
                      label={'LCB'}
                      name='LCB'
                      value={state?.obstetricFormula?.lcb}
                      onChange={(e)=>{
                          if(validateInput(e.target.value,'lcb')){
                              setState({
                                  obstetricFormula:{
                                      ...state.obstetricFormula,
                                      lcb:e.target.value
                                  }
                              })
                          }
                      }}
                      onBlur={(event: any) => {
                          updateHistory(event, event.target.value);
                      }}
                      sx={{flex:1}}
                  />
                </Box>
                

               
            </Box>

            <Divider/>

            <Box display={'flex'} flexDirection={'column'} alignItems={'start'} sx={{width:'100%'}} gap={2}>

                <Typography fontWeight={'bold'}>Abortion</Typography>

                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                    
                    <Box display={'flex'} alignItems={'center'} justifyContent={"space-between"} gap={2} flex={1}>
                        <FormControlLabel control={    
                            <Checkbox
                                checked={state.noAbortionHistory} 
                                onClick={(e:any)=>{
                                    toggleNoAbortionHistory()
                                    
                                }} 
                        
                            />} label={"No Abortion History"} 
                        />
                        <Box display={'flex'} gap={2}>
                          {renderHeaderButtons2("abortion")}
                        </Box>
                    </Box>
                </Box>

                {!state.noAbortionHistory && (
                    <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'start'} sx={{width:'100%'}}>
                        {state.abortion?.map((elem:any,index:any)=>{
                            return (
                                <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'center'} sx={{width:'100%'}}>
                                    {index!==0 && <Divider sx={{width:'100%'}}/>}
                                    
                                    <Box display={'flex'} flex={1} gap={2} width={'100%'}>

                                    <Box sx={{flex:1}}>
                                        <Autocomplete
                                            // disableClearable
                                            id={`Abortion type_${index}_when`}
                                            // aria-label={`${id}-since`}
                                            // noOptionsText={""}
                                            
                                            title={elem?.when}
                                            options={complaintDurationOptions}
                                            filterOptions={(options, { inputValue }) => {
                                            return options.filter((option) => true);
                                            }}
                                            // open={
                                            //   complaintDurationOptions.length === 0
                                            //     ? false
                                            //     : true
                                            // }
                                            // onChange={(e:any)=>{
                                            //     if(validateInput(e.target.value,'when')){
                                            //         setState({
                                            //             abortion: state.abortion.map((el:any,i:any)=>{
                                            //                 if(i===index){
                                            //                     return {
                                            //                         ...el,
                                            //                         when:e.target.value
                                            //                     }
                                            //                 }
                                            //                 return el;
                                            //             })
                                            //         })
                                            //     }
                                            // }}
                                            onBlur={(event: any) => {
                                                updateHistory(event, event.target.value,"abortion");
                                            }}
                                            value={elem?.when}
                                            clearIcon={false}
                                            renderOption={(props, option, state) => {
                                                return (
                                                    <Box
                                                    // aria-label={`${id}-since`}
                                                    component="li"
                                                    sx={{
                                                        backgroundColor: state.selected
                                                        ? "#F2F3FF !important"
                                                        : "",
                                                        "&:hover, &:focus": {
                                                        backgroundColor: "#F2F3FF !important",
                                                        },
                                                    }}
                                                    // id={`${id}-since-${option}`}
                                                    {...props}
                                                    >
                                                    {option}
                                                    </Box>
                                                );
                                            }}
                                            renderInput={(params) => {
                                            return (
                                                <TextField
                                                    name={`Abortion type_${index}_when`}
                                                    onChange={updateComplaintDurationOptions}
                                                    {...params}
                                                    value={elem?.when}
                                                    label="When"
                                                    fullWidth
                                                    variant="outlined"
                                                    // InputProps={{ ...params.InputProps, style: { width:'50%' } }} 
                                                />
                                            );
                                            }}
                                            sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" },
                                            }}
                                        />
                                    </Box>
                                    
                                      {/* <TextField
                                          label={'When'}
                                          name={`Abortion type_${index}_when`}
                                          value={elem?.when}
                                          onChange={(e)=>{
                                              if(validateInput(e.target.value,'when')){
                                                  setState({
                                                      abortion: state.abortion.map((el:any,i:any)=>{
                                                          if(i===index){
                                                              return {
                                                                  ...el,
                                                                  when:e.target.value
                                                              }
                                                          }
                                                          return el;
                                                      })
                                                  })
                                              }
                                          }}
                                          onBlur={(event: any) => {
                                              updateHistory(event, event.target.value,"abortion");
                                          }}
                                          sx={{flex:1}}
                                      /> */}


                                      <FormControl sx={{flex:1}}>
                                          <InputLabel id="demo-simple-select-label">Abortion type</InputLabel>
                                          <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={elem?.abortionType}
                                              label="Abortion type"
                                              name={`Abortion type_${index}_abortionType`}
                                              onChange={(e)=>{
                                                  setState({
                                                      abortion: state.abortion.map((el:any,i:any)=>{
                                                          if(i===index){
                                                              return {
                                                                  ...el,
                                                                  abortionType:e.target.value
                                                              }
                                                          }
                                                          return el;
                                                      })
                                                  })
                                              }}
                                              onBlur={(event: any) => {
                                                  updateHistory(event, event.target.value,"abortion");
                                              }}
                                          >
                                              {state.abortionTypeOptions?.map((op:any)=>{
                                                  return (
                                                      <MenuItem value={op}>{op}</MenuItem>
                                                  )
                                              })}
                                          </Select>
                                      </FormControl>
                                    </Box>

                                    <TextField
                                        label={'Comment'}
                                        name={`Abortion type_${index}_comments`}
                                        value={elem?.comments}
                                        onChange={(e)=>{
                                            if(validateInput(e.target.value,'comments')){
                                                setState({
                                                    abortion: state.abortion.map((el:any,i:any)=>{
                                                        if(i===index){
                                                            return {
                                                                ...el,
                                                                comments:e.target.value
                                                            }
                                                        }
                                                        return el;
                                                    })
                                                })
                                            }
                                        }}
                                        onBlur={(event: any) => {
                                            updateHistory(event, event.target.value,"abortion");
                                        }}
                                        sx={{flex:1,width:'100%'}}
                                    />
                                    
                                </Box>
                            )
                        })}
                    </Box>
                )}
            </Box>

            <Divider/>


            <Box display={'flex'} flexDirection={'column'} alignItems={'start'} sx={{width:'100%'}} gap={2}>
                
                <Typography fontWeight={'bold'}>Ectopic</Typography>
                
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                    
                  <Box display={'flex'} alignItems={'center'} justifyContent={"space-between"} gap={2} flex={1}>
                        <FormControlLabel control={    
                            <Checkbox
                                checked={state.noEctopicHistory} 
                                onClick={(e:any)=>{
                                    toggleNoEctopicHistory()
                                }} 
                        
                            />} label={"No Ectopic History"} 
                        />
                        <Box display={'flex'} gap={2}>
                          {renderHeaderButtons2("ectopic")}
                        </Box>
                    </Box>
                </Box>

                {!state.noEctopicHistory && (
                    <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'start'} sx={{width:'100%'}}>
                        {state.ectopic?.map((elem:any,index:any)=>{
                            return (
                              <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'start'} sx={{width:'100%'}}>
                                    {index!==0 && <Divider sx={{width:'100%'}}/>}
                                    <Box sx={{width:'50%'}}>
                                        <Autocomplete
                                            // disableClearable
                                            id={`Ectopic_${index}_when`}
                                            // aria-label={`${id}-since`}
                                            // noOptionsText={""}
                                            
                                            title={elem?.when}
                                            options={complaintDurationOptions}
                                            filterOptions={(options, { inputValue }) => {
                                            return options.filter((option) => true);
                                            }}
                                            // open={
                                            //   complaintDurationOptions.length === 0
                                            //     ? false
                                            //     : true
                                            // }
                                            // onChange={(e:any)=>{
                                            //     if(validateInput(e.target.value,'when')){
                                            //         setState({
                                            //             abortion: state.abortion.map((el:any,i:any)=>{
                                            //                 if(i===index){
                                            //                     return {
                                            //                         ...el,
                                            //                         when:e.target.value
                                            //                     }
                                            //                 }
                                            //                 return el;
                                            //             })
                                            //         })
                                            //     }
                                            // }}
                                            onBlur={(event: any) => {
                                                updateHistory(event, event.target.value,"ectopic");
                                            }}
                                            value={elem?.when}
                                            clearIcon={false}
                                            renderOption={(props, option, state) => {
                                                return (
                                                    <Box
                                                    // aria-label={`${id}-since`}
                                                    component="li"
                                                    sx={{
                                                        backgroundColor: state.selected
                                                        ? "#F2F3FF !important"
                                                        : "",
                                                        "&:hover, &:focus": {
                                                        backgroundColor: "#F2F3FF !important",
                                                        },
                                                    }}
                                                    // id={`${id}-since-${option}`}
                                                    {...props}
                                                    >
                                                    {option}
                                                    </Box>
                                                );
                                            }}
                                            renderInput={(params) => {
                                            return (
                                                <TextField
                                                    name={`Ectopic_${index}_when`}
                                                    onChange={updateComplaintDurationOptions}
                                                    {...params}
                                                    value={elem?.when}
                                                    label="When"
                                                    fullWidth
                                                    variant="outlined"
                                                    // InputProps={{ ...params.InputProps, style: { width: '50%' } }} 
                                                />
                                            );
                                            }}
                                            sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" },
                                            }}
                                        />
                                    </Box>
                                    
                                    {/* <TextField
                                        label={'When'}
                                        name={`Ectopic_${index}_when`}
                                        value={elem?.when}
                                        onChange={(e)=>{
                                            if(validateInput(e.target.value,'when')){
                                                setState({
                                                    ectopic: state.ectopic.map((el:any,i:any)=>{
                                                        if(i===index){
                                                            return {
                                                                ...el,
                                                                when:e.target.value
                                                            }
                                                        }
                                                        return el;
                                                    })
                                                })
                                            }
                                        }}
                                        onBlur={(event: any) => {
                                            updateHistory(event, event.target.value, "ectopic");
                                        }}
                                        sx={{width:'50%'}}
                                    /> */}
                                    

                                    <TextField
                                        label={'Comment'}
                                        name={`Ectopic_${index}_comments`}
                                        value={elem?.comments}
                                        onChange={(e)=>{
                                            if(validateInput(e.target.value,'comments')){
                                                setState({
                                                    ectopic: state.ectopic.map((el:any,i:any)=>{
                                                        if(i===index){
                                                            return {
                                                                ...el,
                                                                comments:e.target.value
                                                            }
                                                        }
                                                        return el;
                                                    })
                                                })
                                            }
                                        }}
                                        onBlur={(event: any) => {
                                            updateHistory(event, event.target.value, "ectopic");
                                        }}
                                        sx={{width:'100%'}}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                )}
            </Box>


            <Divider/>

            <Box display={'flex'} flexDirection={'column'} alignItems={'start'} sx={{width:'100%'}} gap={2}>

                <Typography fontWeight={'bold'}>Coital History</Typography>
                <ReactQuill
                    id="rx-examination-findings"
                    theme="snow"
                    placeholder="Enter Comments"
                    modules={EditorModules}
                    value={state.coitalHistory}
                    onChange={(content, _delta, _source, editor) => {
                        let newComment = content;
                        if (editor?.getLength() <= 1) {
                            newComment = "";
                        }
                        if (editor?.getLength() > 1500) {
                            newComment = newComment?.substring(0, 1500);
                        }
                        setState({
                            coitalHistory:newComment
                        })
                        // setTabData((prev: any) => {
                    //     return prev.map((section: any) => {
                    //     if (section.view_type === MENSTRUAL_HISTORY) {
                    //         return {
                    //         ...section,
                    //         rows: section.rows.map((row: any, index: number) => {
                    //             if (index === 0) {
                    //             return {
                    //                 ...row,
                    //                 value: row.value.map((val: any, index: number) => {
                    //                 if (index === 0) {
                    //                     return {
                    //                     ...val,
                    //                     comments: newComment ? newComment : "",
                    //                     };
                    //                 }
                    //                 return val;
                    //                 }),
                    //             };
                    //             }
                    //             return row;
                    //         }),
                    //         };
                    //     }
                    //     return section;
                    //     });
                    // });
                    }}
                    onBlur={(e:any)=>{
                        updateHistory(e,state.coitalHistory,'coital_history_comment');
                    }}
                    style={{ color: "#000000",width:'100%' }}
                />
            </Box>

            <Divider/>

            <Box display={'flex'} flexDirection={'column'} alignItems={'start'} sx={{width:'100%'}} gap={2}>

                <Typography fontWeight={'bold'}>Other details</Typography>
                <ReactQuill
                    id="rx-examination-findings"
                    theme="snow"
                    placeholder="Enter Comments"
                    modules={EditorModules}
                    value={state.otherDetails}
                    onChange={(content, _delta, _source, editor) => {
                        let newComment = content;
                        if (editor?.getLength() <= 1) {
                            newComment = "";
                        }
                        if (editor?.getLength() > 1500) {
                            newComment = newComment?.substring(0, 1500);
                        }
                        setState({
                            otherDetails:newComment
                        })
                        // setTabData((prev: any) => {
                    //     return prev.map((section: any) => {
                    //     if (section.view_type === MENSTRUAL_HISTORY) {
                    //         return {
                    //         ...section,
                    //         rows: section.rows.map((row: any, index: number) => {
                    //             if (index === 0) {
                    //             return {
                    //                 ...row,
                    //                 value: row.value.map((val: any, index: number) => {
                    //                 if (index === 0) {
                    //                     return {
                    //                     ...val,
                    //                     comments: newComment ? newComment : "",
                    //                     };
                    //                 }
                    //                 return val;
                    //                 }),
                    //             };
                    //             }
                    //             return row;
                    //         }),
                    //         };
                    //     }
                    //     return section;
                    //     });
                    // });
                    }}
                    onBlur={(e:any)=>{
                        updateHistory(e,state.otherDetails,'other_details_comment');
                    }}
                    style={{ color: "#000000",width:'100%' }}
                />
            </Box>
          </Box>
        </>
      )}
      <Modal
        open={modalState.open}
        onClose={() => {
          setModalState({
            open:false
          })
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <Box sx={isMobileScreen ? style2 : style}>
                <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <Box display={'flex'} gap={2}>
                        <Box
                            component={'img'}
                            src='/images/slot_edit_icon.svg'
                        />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <b>
                                Are you sure you want to delete?
                            </b>
                        </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{cursor:'pointer'}} onClick={()=>{modalState?.onCancel()}}>
                        <CloseIcon/>
                    </Box>
                </Box>

                <Typography sx={{mt:2}}>
                    {modalState.text}
                </Typography>

                <FormControl
                  fullWidth
                  sx={{
                    marginTop: "20px",
                    display: `flex`,
                    flexDirection:'row',
                    justifyContent:'flex-end',
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={modalState.onCancel}
                  >
                     <Typography textTransform={'none'}>Cancel</Typography>
                  </Button>
                  <Button
                    variant="contained"
                    onClick={modalState.onAccept}
                  >
                    <Typography textTransform={'none'}>Yes, delete!</Typography>
                  </Button>
                </FormControl>
              </Box>
      </Modal>
    </>
  )
}

export default PregnancyHistory