import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { API_ROUTE_ACCOUNT_DETAILS, API_ROUTE_FETCH_DOCTORS, API_ROUTE_PROFILE } from "../../shared/routes/route.constants";
import { APIClient } from "../../shared/Utils/api-client";
// import { AlphaClient } from "../../shared/Utils/api-client";


const config = {
  name: "auth",
};
export const fetchProfile: any = createAsyncThunk(
  `${config.name}/fetchProfile`,
  async () => {
    return await APIClient.get(API_ROUTE_PROFILE);
  }
);

export const fetchAccountDetails: any = createAsyncThunk(
  `${config.name}/fetchAccountDetails`,
  async (data:any) => {
    return await APIClient.get(API_ROUTE_ACCOUNT_DETAILS(data?.doctor_id,data?.account_id));
  }
);

export const fetchDoctorsList: any = createAsyncThunk(
  `${config.name}/fetchDoctorsList`,
  async (id:string)=>{
    return await APIClient.get(API_ROUTE_FETCH_DOCTORS(id))
  }
)





//CRUD

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loggingIn: false,
    userDetails:null as any,
    profile:null as any,
    accountInfo:null as any,
    showHelper:false as boolean,
    token: null as any,
    clinicName: [],
    doctorsList:[],
    selectedDoctor:''
  },
  reducers: {
    setSelectedDoctor: (state,action)=>{
      state.selectedDoctor = action.payload
    },
    toggleHelper: (state)=>{
      state.showHelper = !state.showHelper
    },
    logout: (state) => {
      state.loggingIn = false;
      state.userDetails = null;
      state.profile = null;
      state.accountInfo = null;
      state.token = null
    },
   setUser:(state,palyload)=>{
    state.userDetails = palyload?.payload
   },
   setToken: (state, action) => {
    state.token = action.payload;
    // localStorage.setItem("emrToken", action.payload);
  },
   setClinicName: (state, action) => {
    state.clinicName = action.payload;
   },
   resetAccountInfo :(state)=>{
     state.accountInfo = [];
   }
  },
  extraReducers(builder:any) {
    builder
      .addCase(fetchDoctorsList.pending, (state:any, action:any)=>{
        state.doctorsList = [];
      })
      .addCase(fetchDoctorsList.rejected, (state:any, action:any)=>{
        state.doctorsList = [];
      })
      .addCase(fetchDoctorsList.fulfilled, (state:any, action:any)=>{
        state.doctorsList = action.payload?.data?.doctor_id;
      })
      .addCase(fetchProfile.fulfilled, (state:any, action:any) => {
        state.profile = action.payload?.data;
      })
      .addCase(fetchProfile.rejected, (state:any, action:any) => {
        state.profile = null;
      })
      .addCase(fetchProfile.pending, (state:any, action:any) => {
        state.profile = null;
      })

      .addCase(fetchAccountDetails.fulfilled, (state:any, action:any) => {
        state.accountInfo = action.payload?.data;
      })
      .addCase(fetchAccountDetails.rejected, (state:any, action:any) => {
        state.accountInfo = null;
      })
      .addCase(fetchAccountDetails.pending, (state:any, action:any) => {
        state.accountInfo = null;
      })

      
  },
});

export const {
  logout,
  setUser,
  setClinicName,
  toggleHelper,
  setToken,
  setSelectedDoctor,
  resetAccountInfo
} = authSlice.actions;

export default authSlice.reducer;
