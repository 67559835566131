import { Box } from "@mui/system";

const CalenderIcon = ({onClick}: any) => {
  return (
    <Box onClick={onClick}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="3.5"
          fill="white"
          stroke="#C4C4C4"
        />
        <path
          d="M28.2852 11.2852H27.2852V9.28516H25.2852V11.2852H15.2852V9.28516H13.2852V11.2852H12.2852C11.1852 11.2852 10.2852 12.1852 10.2852 13.2852V29.2852C10.2852 30.3852 11.1852 31.2852 12.2852 31.2852H28.2852C29.3852 31.2852 30.2852 30.3852 30.2852 29.2852V13.2852C30.2852 12.1852 29.3852 11.2852 28.2852 11.2852ZM28.2852 29.2852H12.2852V16.2852H28.2852V29.2852Z"
          fill="#494E9D"
        />
      </svg>
    </Box>
  );
};

export default CalenderIcon;
