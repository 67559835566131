const TabletIcon = ({width}: any) => {
  return (
    <>
      <svg
        width={width}
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.03019"
          y="0.129537"
          width="29.5412"
          height="11.7102"
          rx="5.85508"
          transform="matrix(0.77683 -0.62971 0.596752 0.802426 0.144792 20.2153)"
          stroke="#494F9D"
          stroke-width="1.5"
        />
        <line
          y1="-0.75"
          x2="13.2102"
          y2="-0.75"
          transform="matrix(0.596752 0.802426 -0.77683 0.62971 11.4141 10.2803)"
          stroke="#494F9D"
          stroke-width="1.5"
        />
        <circle cx="23.4277" cy="24.2578" r="4.07227" stroke="#494F9D" />
        <path
          d="M22.0508 24.2603H24.7941"
          stroke="#494F9D"
          stroke-width="0.775421"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};
export default TabletIcon;
