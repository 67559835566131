import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card from "../../utils/Card";
import CardHeader, { renderHeaderButtons } from "../../utils/CardHeader";
import {
  HTMLAttributes,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { getDurationOptions } from "../../../../../shared/Utils/utilities";
import {
  fetchSuggestions,
  seperateUrlAndParams,
} from "../../../../../shared/Utils/utils";
import { useDebounce } from "../../../../../shared/Utils/useDebounce";
import {  styled } from "@mui/system";
import { CARD_SHADOW } from "../../utils/Constants";
import TemplateCard from "../../utils/TemplateCard";
import { useDispatch, useSelector } from "react-redux";
import { COMPLAINTS } from "../../../../../shared/constants";
import {
  setCopyData,
  setEditRxSecMobile,
  setInstantRxData,
} from "../../../../../redux/reducers/prescription.slice";
import DoctorSuggestions from "../../utils/DoctorSuggestions";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import { CustomTextarea } from "./patienthistory/utils/shared";
import CloseIcon from "@mui/icons-material/Close";

interface ComplaintProps {
  id: string;
  name: string;
  since: string;
  severity: string;
  comment: string;
}

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "8px",
    paddingLeft: 0,
    border: "none",
  },
});

// const StyledOption = styled(Box)({
//   "& li.Mui-focused": {
//     cursor: "pointer",
//   },
//   "& li:active": {
//     backgroundColor: "#F2F3FF",
//   },
// });

const CustomDropDownPaper = styled(Paper)({
  minWidth: 900,
});

export default function ChiefComplaints(props: any) {
  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [complaintDurationOptions, setComplaintDurationOptions] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<ComplaintProps[]>([]);
  const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>();
  const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
    useState<boolean>();

  const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
  const searchExistingTemplateMobile =
    props?.sectionData?.[0]?.searchExistingTemplateMobile;
  // const handleButtonClickMiniTMobile =
  //   props.sectionData[0].handleButtonClickMiniTMobile;

  const [searchValue, setSearchValue] = useState("");
  const [valUpdateSearch, setValUpdateSearch] = useState("");
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {} as ComplaintProps,
  });
  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);

  const isFocused: boolean = props.isFocused || false;

  const sectionData = props?.sectionData?.[0]?.rows[0];
  const { setTabData } = props;
  const severityMaster = sectionData?.master?.severity?.values || [];

  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;
  const {
    copyData,
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const autoref = useRef<HTMLDivElement>(null);

  const MiniTemplateStyle = {
    position: !isMobileScreen ? "absolute" : "none",
    top: "68px",
    right: "79px",
    zIndex: 1,
    height: "0px",
  };

  const AddMiniTemplateStyle = {
    position: !isMobileScreen ? "absolute" : "none",
    top: "68px",
    right: "39px",
    zIndex: 1,
    height: "0px",
  };

  const apiurldata = sectionData?.search?.api;

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(COMPLAINTS));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (copyData?.view_type === COMPLAINTS) {
      handleSelectedTemplateData(copyData);
      dispatch(setCopyData([] as any));
    }
  }, [copyData]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === COMPLAINTS
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  const assignAddComplaintMobile = (value: string) => {
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;
    if (!value) return;
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        id,
        name,
        since: "",
        severity: "",
        comment: "",
      },
    });
  };

  const doctorSuggestionProps = {
    sKey: "complaints",
    sHeading: "Suggested complaints",
    headerImg: isMobileScreen
      ? "/images/icon_mobile_resp_suggestion.svg"
      : "/images/icon_dummy.svg",
    handleAdd: (event: any) => {
      const currentTarget = event?.currentTarget;
      const suggestion = currentTarget?.attributes["data-key"]?.nodeValue;
      const clickAction = currentTarget?.attributes["data-action"]?.nodeValue;

      if (!data.some((complaint: any) => complaint.name === suggestion)) {
        switch (clickAction) {
          case "ADD":
            if (!isMobileScreen) {
              addComplaint(null as any, suggestion);
            } else {
              assignAddComplaintMobile(suggestion);
            }
            break;
          case "UPDATE":
            updateComplaint(event as any, suggestion);
            break;
          default:
            return;
        }
      }
      if (autoref.current) {
        autoref.current.blur();
      }
    },
  };

  const addComplaint = async (
    event: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;
    if (!value) {
      return;
    }

    const newComplaint: ComplaintProps = {
      id,
      name,
      since: "",
      severity: "",
      comment: "",
    };
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === COMPLAINTS) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: [...row.value, newComplaint],
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
    setComplaintDurationOptions([]);
    setSearchValue("");
  };

  const updateComplaint = (event: SyntheticEvent, value: string | null) => {
    let newValue: any, newId: any;

    const target =
      (event.currentTarget as HTMLInputElement) ||
      (event.currentTarget as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName).split("-");

    const key = nameArr[nameArr.length - 1];

    const currId = nameArr.reduce((acc, val) => {
      if (val === key) {
        return acc;
      }
      return acc + "-" + val;
    });

    if (key === "name") {
      const optionObj = optionsData.find(
        (optObj: any) => optObj.name === value
      );
      const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
      const name = optionObj?.name ? optionObj.name : value;
      newValue = name;
      newId = id;
    } else {
      (newId = currId), (newValue = value);
    }
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === COMPLAINTS) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.map((complaint: any) => {
                    if (complaint?.id?.toString() === currId) {
                      return {
                        ...complaint,
                        id: newId,
                        [key]: newValue,
                      };
                    }
                    return complaint;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });

    setOptions([]);
    setComplaintDurationOptions([]);
  };

  const removeComplaint = (event: SyntheticEvent<Element, Event>) => {
    const elem = event.target as HTMLElement;
    const { id } = elem;
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === COMPLAINTS) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (complaint: any) => complaint?.id?.toString() !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
  };

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === COMPLAINTS) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
    dispatch(setInstantRxData([] as any));
  };

  const updateComplaintDurationOptions = (
    event: SyntheticEvent<Element, Event>
  ) => {
    const elem = event.target as HTMLInputElement;
    const { value } = elem;

    if (value == "") {
      setComplaintDurationOptions([]);
      return;
    }

    if (!/^[1-9][0-9]*$/.test(value) || value === "") {
      const newOptionArr = complaintDurationOptions.filter((option) =>
        option.includes(value)
      );

      if (newOptionArr) {
        setComplaintDurationOptions(newOptionArr);
        return;
      }

      setComplaintDurationOptions([]);
      return;
    }

    const optionArr = getDurationOptions(value);

    if (optionArr) {
      setComplaintDurationOptions(optionArr);
    }
  };

  const updateSearchOptions = (value: any) => {
    const trimmedValue = value.trim();
    if (apiurldata && trimmedValue) {
      const { url, query_params } = seperateUrlAndParams(apiurldata);
      fetchSuggestions(
        url,
        {
          ...query_params,
          name: value,
        },
        true // use custom base url
      ).then((response) => {
        const responseArr = response?.data?.data;
        const optionsArr = responseArr?.map((option: any) => option.name);
        if (!optionsArr.some((opt: any) => opt === trimmedValue)) {
          setOptions([trimmedValue, ...optionsArr]);
          setOptionsData([
            {
              id: `custom${Date.now()}`,
              name: trimmedValue,
            },
            ...responseArr,
          ]);
          return;
        }
        setOptions(optionsArr);
        setOptionsData(responseArr);
      });
    }
  };

  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length !== 0 && searchValue.trim().length >= 3) {
      updateSearchOptions(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (valUpdateSearch.length !== 0) {
      updateSearchOptions(altDebouncedSearch);
    }
  }, [altDebouncedSearch]);

  const handleButtonClick = async (imageName: string) => {
    if (imageName === "icon_save.svg") {
      if (isMobileScreen) {
        // handleButtonClickMiniTMobile(imageName); // review-code
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === COMPLAINTS) {
              return {
                ...section,
                openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                searchExistingTemplateMobile: false,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenSaveTemplate(!openSaveTemplate);
      setOpenUpdateExistingTemplate(false);
    } else if (imageName === "icon_add_page.svg") {
      if (isMobileScreen) {
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === COMPLAINTS) {
              return {
                ...section,
                openSaveTemplateMobile: false,
                searchExistingTemplateMobile:
                  !section.searchExistingTemplateMobile,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenUpdateExistingTemplate(!serachExistingTemplate);
      setOpenSaveTemplate(false);
    }
  };

  const handleDialogState = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
    });
  };

  const dialogProps = {
    DialogOpen: dialog.open ? dialog.open : false,
    onHandleDialog: handleDialogState,
    headerStyles: { display: "none" },
    PaperPropsStyle: {
      margin: 0,
      width: "100%",
    },
    Layoutstyles: {
      "& .MuiDialog-container": {
        alignItems: "flex-end",
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
      },
    },
    footerStyles: {
      display: "none",
    },
    CloseButtonStyles:{
      display: "none",
    },
    onHandleSave: () => {
      // not required, custom save option is there
      return;
    },
  };

  const ComplaintCardMobile = (Complaint: ComplaintProps, index: number) => {
    const { id, name, since, severity, comment } = Complaint;
    return (
      <Card
        styles={{
          boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.08)`,
          padding: 0,
          borderRadius: "8px",
        }}
        key={index + id}
      >
        <Box id={id}>
          {/* Card Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <Typography
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              color="#494E9D"
            >
              {name}
            </Typography>
            <IconButton>
              <Box
                id={id}
                aria-label="delete-complaint"
                onClick={removeComplaint}
                component="img"
                src="/images/DeleteIcon.svg"
              ></Box>
            </IconButton>
          </Box>
          <Divider />
          {/* Card Contents */}
          <Box padding="12px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginRight: "25%",
                }}
              >
                <Box
                  component="img"
                  marginRight="4px"
                  src="/images/ClockIcon.svg"
                ></Box>
                <Box>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="100%"
                    marginBottom="8px"
                    color="rgba(0, 0, 0, 0.87)"
                  >
                    Since When
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="100%"
                    color="#6F6F6F"
                  >
                    {since}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Box
                  component="img"
                  marginRight="4px"
                  src="/images/FlagIcon.svg"
                ></Box>
                <Box>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="100%"
                    marginBottom="8px"
                    color="rgba(0, 0, 0, 0.87)"
                  >
                    Severity
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="100%"
                    color="#6F6F6F"
                  >
                    {severity}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Box
                component="img"
                marginRight="4px"
                src="/images/CommentIcon.svg"
              ></Box>
              <Box>
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.87)"
                >
                  Comments
                </Typography>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="160%"
                  color="#6F6F6F"
                >
                  {comment}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    );
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        {data.map((datum, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
                fontFamily: "Roboto, sans-serif",
              }}
              key={index}
            >
              {datum.name}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Chief Complaints"
        >
          <CardHeader
            headerImg="icon_chief_complaints.svg"
            headerTitle="Chief Complaints"
          >
            {renderHeaderButtons(
              ["icon_save.svg", "icon_add_page.svg"],
              handleButtonClick
            )}
          </CardHeader>
          {data.length !== 0 && (
            <TableContainer sx={{ mb: 2, boxShadow: "none" }} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="all-compaints">
                <TableBody>
                  {data.map((complaint, index) => {
                    const { id, name, comment } = complaint;
                    return (
                      <TableRow key={`complaint-${index}-${name}`}>
                        <StyledTableCell align="left">
                          <Autocomplete
                            id={`${id}-name`}
                            aria-label={`${id}-name`}
                            options={
                              valUpdateSearch.length === 0 ? [] : options
                            }
                            disableClearable
                            value={name}
                            clearOnBlur
                            clearOnEscape
                            popupIcon={false}
                            clearIcon={false}
                            filterOptions={(options, { inputValue }) => {
                              return options.filter((option) => true);
                            }}
                            onChange={updateComplaint}
                            PaperComponent={({ children }) => (
                              <CustomDropDownPaper>
                                {children}
                              </CustomDropDownPaper>
                            )}
                            noOptionsText={
                              <DoctorSuggestions
                                clickSource={`${id}-name`}
                                clickAction="UPDATE"
                                {...doctorSuggestionProps}
                              />
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  multiline
                                  inputRef={autoref}
                                  onChange={(event: any) => {
                                    if (event.target.value === "") {
                                      setOptions([]);
                                    }
                                    setValUpdateSearch(event.target.value);
                                  }}
                                  label="Symptom"
                                  fullWidth
                                  variant="outlined"
                                />
                              );
                            }}
                            renderOption={(
                              props: HTMLAttributes<HTMLLIElement>,
                              option: string,
                              state
                            ) => {
                              // if (
                              //   data.some(
                              //     (complaint: any) => complaint.name === option
                              //   )
                              // ) {
                                return (
                                  <Box
                                    component="li"
                                    aria-label={`${id}-name`}
                                    sx={{
                                      backgroundColor: state.selected
                                        ? "#F2F3FF !important"
                                        : "",
                                      "&:hover, &:focus": {
                                        backgroundColor: "#F2F3FF !important",
                                      },
                                    }}
                                    {...props}
                                  >
                                    {!optionsData
                                      .find((opt: any) => opt.name === option)
                                      ?.id?.toString()
                                      .includes("custom")
                                      ? option
                                      : `Add "${option}"`}
                                  </Box>
                                );
                              // }
                            }}
                            sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" } }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Autocomplete
                            // disableClearable
                            id={`${id}-since`}
                            aria-label={`${id}-since`}
                            // noOptionsText={""}
                            title={complaint.since}
                            options={complaintDurationOptions}
                            filterOptions={(options, { inputValue }) => {
                              return options.filter((option) => true);
                            }}
                            // open={
                            //   complaintDurationOptions.length === 0
                            //     ? false
                            //     : true
                            // }
                            onChange={updateComplaint}
                            value={complaint.since}
                            clearIcon={false}
                            renderOption={(props, option, state) => {
                              return (
                                <Box
                                  aria-label={`${id}-since`}
                                  component="li"
                                  sx={{
                                    backgroundColor: state.selected
                                      ? "#F2F3FF !important"
                                      : "",
                                    "&:hover, &:focus": {
                                      backgroundColor: "#F2F3FF !important",
                                    },
                                  }}
                                  id={`${id}-since-${option}`}
                                  {...props}
                                >
                                  {option}
                                </Box>
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  name={`${id}-since`}
                                  onChange={updateComplaintDurationOptions}
                                  {...params}
                                  value={complaint.since}
                                  label="Since When"
                                  placeholder="Eg: 1 day"
                                  fullWidth
                                  variant="outlined"
                                  InputProps={{ ...params.InputProps, style: { minWidth: '120px' } }} 
                                />
                              );
                            }}
                            sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" },
                             }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Autocomplete
                            // disableClearable
                            options={severityMaster}
                            id={`${id}-severity`}
                            aria-label={`${id}-severity`}
                            value={complaint.severity}
                            title={complaint.severity}
                            onChange={updateComplaint}
                            filterOptions={(options, { inputValue }) => {
                              return options.filter((option) => true);
                            }}
                            noOptionsText=""
                            clearIcon={false}
                            renderOption={(props, option, state) => {
                              return (
                                <Box
                                  sx={{
                                    backgroundColor: state.selected
                                      ? "#F2F3FF !important"
                                      : "",
                                    "&:hover, &:focus": {
                                      backgroundColor: "#F2F3FF !important",
                                    },
                                  }}
                                  aria-label={`${id}-severity`}
                                  component="li"
                                  id={`${id}-status-${option}`}
                                  {...props}
                                >
                                  {option}
                                </Box>
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  name={`${id}-severity`}
                                  {...params}
                                  label="Severity"
                                  fullWidth
                                  variant="outlined"
                                  InputProps={{ ...params.InputProps, style: { minWidth: '100px' } }} 
                                />
                              );
                            }}
                            sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" },
                              "& .MuiInputBase-root":{
                                    padding: "9px !important",
                              }}}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TextField
                            autoComplete="off"
                            value={comment}
                            title={comment}
                            label="Comments"
                            fullWidth
                            id={`${id}-comment`}
                            aria-label={`${id}-comment`}
                            name={`${id}-comment`}
                            variant="outlined"
                            onChange={(event: any) => {
                              let value = event.target.value;
                              if (value.length > 250) {
                                value = value.substring(0, 250);
                              }
                              setData((prev: any) => {
                                return prev.map((elem: any) => {
                                  if (elem.id.toString() === id.toString()) {
                                    return {
                                      ...elem,
                                      comment: value,
                                    };
                                  }
                                  return elem;
                                });
                              });
                            }}
                            onBlur={(event: any) => {
                              updateComplaint(event as any, event.target.value);
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: "32px", padding: "0 !important" }}
                          align="right"
                        >
                          <Tooltip title={"Delete"}>
                            <button
                              id={id}
                              onClick={removeComplaint}
                              style={{
                                outline: "none",
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                style={{ pointerEvents: "none" }}
                                src="/images/minus-in-circle.svg"
                                alt="remove-button"
                              />
                            </button>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Autocomplete
            id="search-for-symptoms"
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            // loading={true}
            value={""}
            clearOnBlur
            clearOnEscape
            popupIcon={false}
            clearIcon={false}
            inputValue={searchValue}
            onChange={addComplaint}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} data={data}/>
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    value: null,
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for complaint"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((complaint: any) => complaint.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplate && (
            <Box sx={MiniTemplateStyle}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {serachExistingTemplate && (
            <Box position="absolute" top="68px" right="39px" zIndex="1">
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Autocomplete
            id="search-for-symptoms"
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            value={""}
            clearOnBlur
            clearOnEscape
            popupIcon={false}
            clearIcon={false}
            inputValue={searchValue}
            onChange={(_event, value) => {
              assignAddComplaintMobile(value);
            }}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} data={data} />
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    value: null,
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for symptoms"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((complaint: any) => complaint.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplateMobile && (
            <Box sx={MiniTemplateStyle}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {searchExistingTemplateMobile && (
            <Box sx={AddMiniTemplateStyle}>
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
          <Box marginTop="16px">
            {data.length !== 0 &&
              data.map((complaint, index) => {
                return (
                  <Box
                    component="div"
                    id={complaint.id}
                    onClick={(_event: any) => {
                      if (_event.target.ariaLabel === "delete-complaint") {
                        return;
                      }
                      setDialog({
                        ...dialog,
                        open: true,
                        mode: "UPDATE",
                        content: complaint,
                      });
                    }}
                    key={index}
                  >
                    {ComplaintCardMobile(complaint, index)}
                  </Box>
                );
              })}
          </Box>
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
      {isMobileScreen && (
        <CustomizedDialogs {...(dialogProps as any)}>
          <Box margin="12px">
            <Box className="header" display="flex">
              <Box
                component="img"
                sx={{ marginRight: 1.5 }}
                src="/images/icon_chief_complaints.svg"
              />
              <Box className="text-content" sx={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <Box>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  Symptoms
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="100%"
                  color="#494E9D"
                >
                  {dialog.content.name}
                </Typography>
                </Box>
                <Box onClick={handleDialogState}>
                  <CloseIcon sx={{ color: theme.palette.grey[500] }} />
                </Box>
              </Box>
            </Box>
            <Divider sx={{ margin: "16px 0" }} />
            <Box className="content" marginBottom="24px">
              <Box display="flex" marginBottom="16px" width="100%" gap="8px">
                <Autocomplete
                  // disableClearable
                  noOptionsText={""}
                  title="since"
                  options={complaintDurationOptions}
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => true);
                  }}
                  onChange={(event, value) => {
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        since: value,
                      },
                    });
                  }}
                  value={dialog.content.since}
                  clearIcon={false}
                  sx={{ width: "100%" }}
                  renderOption={(props, option, state) => {
                    return (
                      <Box
                        aria-label={`since`}
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        id={`-since-${option}`}
                        {...props}
                      >
                        {option}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        onChange={updateComplaintDurationOptions}
                        {...params}
                        label="Since When"
                        placeholder="Eg: 1 day"
                        fullWidth
                        variant="outlined"
                      />
                    );
                  }}
                />
                <Autocomplete
                  // disableClearable
                  options={severityMaster}
                  value={dialog.content.severity}
                  title="severity"
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => true);
                  }}
                  onChange={(event, value) => {
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        severity: value,
                      },
                    });
                  }}
                  noOptionsText=""
                  clearIcon={false}
                  sx={{ width: "100%" }}
                  renderOption={(props, option, state) => {
                    return (
                      <Box
                        sx={{
                          width: "175px",
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        aria-label={`-severity`}
                        component="li"
                        id={`-status-${option}`}
                        {...props}
                      >
                        {option}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Severity"
                        fullWidth
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Box>
              <CustomTextarea
                placeholder="Comments"
                value={dialog.content.comment}
                maxRows={4}
                onChange={(event: any) => {
                  let newValue = event.target.value;
                  console.log({ newValue });
                  if (newValue.length > 250) {
                    newValue = newValue.substring(0, 250);
                  }
                  setDialog({
                    ...dialog,
                    content: {
                      ...dialog.content,
                      comment: newValue,
                    },
                  });
                }}
                minRows={2}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none !important",
                  borderRadius: "6px",
                }}
                fullWidth
                onClick={() => {
                  if (dialog.mode === "ADD" && dialog.content.id) {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === COMPLAINTS) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: [...row.value, dialog.content],
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setSearchValue("");
                    setComplaintDurationOptions([]);
                  }
                  if (dialog.mode === "UPDATE" && dialog.content.id) {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === COMPLAINTS) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: row.value.map((val: any) => {
                                      if (val.id === dialog.content.id) {
                                        return {
                                          ...dialog.content,
                                        };
                                      }
                                      return val;
                                    }),
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setComplaintDurationOptions([]);
                  }
                }}
              >
                {dialog.mode === "ADD" ? `Add` : `Update`} Chief Complaints
              </Button>
            </Box>
          </Box>
        </CustomizedDialogs>
      )}
    </>
  );
}
