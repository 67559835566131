import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";
import { GradientBox } from "./GradientBox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@emotion/react";
import { SearchInput } from "./SearchInput";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  API_ROUTE_FETCH_CITY,
  API_ROUTE_FETCH_COUNTRIES,
  API_ROUTE_FETCH_STATE,
  API_ROUTE_PATIENT,
  ROUTE_APPOINTMENTS,
} from "../../../shared/routes/route.constants";
import ModalComponent from "./Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import { APIClient } from "../../../shared/Utils/api-client";
import dayjs from "dayjs";
import { isValidEmail } from "../../../shared/Utils/utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const initState = {
  fullName: "",
  phoneNumber: "",
  gender: "male",
  dob: "",
  age: "",
  guardianName: "",
  guardianContactNo: "",
  email: "",
  referringDoctor: "",
  address: "",
  pincode: "",
  city: "",
  state: "",
  country: "India",
  countryOptions: [],
  stateOptions: [],
  cityOptions: [],
};

const NewPatient = () => {
  const theme = useTheme();
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorField, setErrorField] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [modalData, setModalData] = useState({
    isSuccess: true,
    headerText: "",
    subText: "",
    actionButtonText: "",
    actionButtonOnClick: () => {
      return;
    },
  });
  const [openModal, setOpenModal] = useState(false);

  const [state, setState] = useReducer((state, actions) => {
    return {
      ...state,
      ...actions,
    };
  }, initState);

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const { selectedDoctor,userDetails } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    fetchCountryOptions().then((res) => {
      setState({ country: "India" });
    });
  }, []);
  useEffect(() => {
    fetchStateOptions();
  }, [state.country]);
  useEffect(() => {
    fetchCityOptions();
  }, [state.state]);

  const handleGoBack = ()=>{
    navigate(`${ROUTE_APPOINTMENTS}`);
    return;
  }

  const handleNewPatientSubmit = async () => {
    if (state?.fullName === "" || state?.fullName?.trim()?.length===0) {
      setErrorMsg("Enter Patient Name");
      setErrorField("patient name");
      // setShowError(true)
      return;
    }
    if (state?.phoneNumber === "") {
      setErrorMsg("Enter Patient Mobile Number");
      setErrorField("phone number");
      // setShowError(true)
      return;
    }
    if (state?.phoneNumber != "" && state?.phoneNumber.length !== 10) {
      setErrorMsg("Enter Valid Phone Number");
      setErrorField("phone number");
      // setShowError(true)
      return;
    }
    if (state?.gender === "") {
      setErrorMsg("Choose Patient Gender");
      setErrorField("gender");
      // setShowError(true)
      return;
    }

    if (state.dob === null && state?.age?.no_of_years === undefined) {
      setErrorMsg("Enter Patient Age or Date of Birth");
      setErrorField("dob");
      // setShowError(true)
      return;
    }
    if (state.dob === "" && state?.age?.no_of_years === "") {
      setErrorMsg("Enter Patient Age or Date of Birth");
      setErrorField("dob");
      // setShowError(true)
      return;
    }

    if (state?.dob) {
      const calculatedAge =
        new Date().getFullYear() - new Date(state?.dob).getFullYear();

      if (calculatedAge > 120) {
        setErrorMsg("Patient age couldn't be more than 120");
        setErrorField("age");
        // setShowError(true)
        return;
      }
      if (calculatedAge < 0) {
        setErrorMsg("Patient age cannot be negative");
        setErrorField("age");
        // setShowError(true)
        setState({ dob: null });
        return;
      }
    }

    if (
      state?.age?.no_of_years < 18 &&
      state?.guardianName === "" &&
      state?.age?.no_of_years !== ""
    ) {
      setErrorMsg("Guardian Name Required");
      setErrorField("guardian name");
      // setShowError(true)
      return;
    }
    if (
      state?.age?.no_of_years < 18 &&
      state?.guardianContactNo === "" &&
      state?.age?.no_of_years !== ""
    ) {
      setErrorMsg("Guardian Contact Number Required");
      setErrorField("guardian contact");
      //   setShowError(true)
      return;
    }

    if (
      state?.guardianContactNo != "" &&
      state?.guardianContactNo.length !== 10
    ) {
      setErrorMsg("Enter Valid Guardian Contact Number");
      setErrorField("guardian contact");
      // setShowError(true)
      return;
    }
    if (state?.email != "" && !isValidEmail(state?.email)) {
      setErrorMsg("Enter Valid Email Address");
      setErrorField("email");
      // setShowError(true)
      return;
    }

    if (state?.pincode != "" && state?.pincode?.length !== 6) {
      setErrorMsg("Enter Valid Pincode");
      setErrorField("pincode");
      // setShowError(true)
      return;
    } else {
      setErrorField("");
      await createNewPatient()
        .then((res) => {
          setModalData({
            isSuccess: true,
            headerText: "Patient Successfully Registered",
            subText: `UID: ${res?.patient_hid} created for patient ‘${res?.name}’`,
            actionButtonText: "",
            actionButtonOnClick: () => {
              return;
            },
          });
          setOpenModal(true);
          setLoading(false);
        })
        .catch((err) => {
          setModalData({
            isSuccess: false,
            headerText: err,
            subText: ``,
            actionButtonText: "Try Again",
            actionButtonOnClick: () => {
              setOpenModal(false);
              handleNewPatientSubmit();
            },
          });
          setOpenModal(true);
          setLoading(false);
        });
    }
  };

  const createNewPatient = async () => {
    setLoading(true);

    let results = await APIClient.post(API_ROUTE_PATIENT(), {
      name: state.fullName,
      phone: state.phoneNumber,
      referred_doctor_name: state.referringDoctor,
      guardian_phone: state.guardianContactNo,
      guardian_name: state.guardianName,
      gender: state.gender,
      email: state.email,
      is_svaas_patient: true,
      doctor_name: "",
      doctor_id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
      date_of_birth: state.dob
        ? moment(new Date(state.dob)).format('YYYY-MM-DD')
        : null,
      country_code: "+91",
      alternate_phone: state.phoneNumber,
      age: state.age,
      address: {
        city: state.city,
        country: state.country,
        line1: state.address,
        line2: "",
        state: state.state,
        zipcode: state.pincode,
      },
    });
    return new Promise((res) => res(results.data));
  };

  const toggleShowAdditionalfields = () => {
    setShowAdditionalFields((prev) => !prev);
  };

  const fetchCountryOptions = async () => {
    let results = await APIClient.get(
      API_ROUTE_FETCH_COUNTRIES(localStorage.getItem("s3AccountId"), "country")
    );

    if (results?.data?.length > 0) {
      setState({ countryOptions: results?.data });
    } else {
      setState({ countryOptions: [] });
    }
  };
  const fetchStateOptions = async () => {
    let results = await APIClient.get(
      API_ROUTE_FETCH_STATE(
        localStorage.getItem("s3AccountId"),
        "state",
        state.country
      )
    );

    if (results?.data?.length > 0) {
      setState({ stateOptions: results?.data });
    } else {
      setState({ stateOptions: [] });
    }
  };
  const fetchCityOptions = async () => {
    let results = await APIClient.get(
      API_ROUTE_FETCH_CITY(
        localStorage.getItem("s3AccountId"),
        "city",
        state.country,
        state.state
      )
    );

    if (results?.data?.length > 0) {
      setState({ cityOptions: results?.data });
    } else {
      setState({ cityOptions: [] });
    }
  };

  const shouldShowGuardianInputs = ()=>{
    if(state.age.no_of_years!=="" && state.age.no_of_years<18){
      return true;
    }else if(state.dob!=="" && dayjs().diff(state.dob,'year')<18 ){
      return true;
    }
    return false;
  }
  
  return (
    <>
      {isMobileScreen ? (
        <>
          <Box>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              ContentProps={{ sx: { background: "red", marginTop: "40px" } }}
              open={showError}
              onClose={() => setShowError(false)}
              autoHideDuration={6000}
              message={errorMsg}
            />
            <Box
              display={"flex"}
              gap={1}
              alignItems={"center"}
              paddingX={1}
              paddingTop={2}
            >
              <IconButton onClick={handleGoBack}>
                <KeyboardBackspaceIcon color="primary" />
              </IconButton>
              <Typography variant="subtitle1" fontWeight={"bold"}>
                New Patient
              </Typography>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              padding={2}
              mb={8}
            >
              <TextField
                label={"Full Name"}
                type={"text"}
                required={true}
                value={state.fullName}
                onChange={(e) => {
                  const regex = /^[A-Za-z ]*$/;
                  if (regex.test(e.target.value)) {
                    setState({ fullName: e.target.value });
                    if (errorField === "patient name") {
                      setErrorField("");
                    }
                  } else {
                    return;
                  }
                }}
                helperText={
                  errorField === "patient name" && errorMsg
                }
                error={errorField === "patient name"}
              />
              <TextField
                label={"Phone Number"}
                autoComplete="off"
                // type={"number"}
                inputProps={{
                  min: "10", // Minimum value
                  max: "10", // Maximum value
                }}
                required={true}
                value={state.phoneNumber}
                onChange={(e) => {
                  const onlyNums = e.target.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                  if (onlyNums.length > 10) {
                    return;
                  } else {
                    setState({ phoneNumber: onlyNums });
                    if (errorField === "phone number") {
                      setErrorField("");
                    }
                  }
                }}
                helperText={
                  errorField === "phone number" && errorMsg
                }
                error={errorField === "phone number"}
              />

              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={state.gender}
                  onChange={(e) => {
                    setState({ gender: e.target.value });
                  }}
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
              </FormControl>
              <Box
                display={"flex"}
                alignItems={"start"}
                gap={1}
                boxSizing={"border-box"}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="Date of Birth"
                    maxDate={dayjs()}
                    value={state.dob ? state.dob : null}
                    onChange={(value) => {
                      if (
                        errorField === "age" ||
                        errorField === "dob"
                      ) {
                        setErrorField("");
                      }
                      if (value) {
                        // If a valid date is selected, calculate the age
                        const today = new Date();
                        const birthDate = new Date(value);

                        let years =
                          today.getFullYear() -
                          birthDate.getFullYear();
                        let months =
                          today.getMonth() - birthDate.getMonth();

                        if (months < 0) {
                          years--;
                          months += 12;
                        }

                        if (!isNaN(years) && years <= 120) {
                          setState({
                            dob: value,
                            age: {
                              no_of_months: months, // Optionally calculate months
                              no_of_years: years,
                            },
                          });
                        } else {
                          setState({
                            dob: "",
                            age: {
                              no_of_months: "",
                              no_of_years: "", // Default value for invalid/over-limit age
                            },
                          });
                        }
                      } else {
                        // setErrorMsg("Patient age couldn't be more than 120")
                        // setShowError(true)
                        setState({
                          dob: "",
                          age: {
                            no_of_months: "",
                            no_of_years: "",
                          },
                        });
                      }
                    }}
                    slotProps={{
                      textField: {
                        InputProps: {
                          endAdornment: (
                            <Box
                              width={{
                                xs: 40,
                                marginRight: 4,
                              }}
                              component="img"
                              src={"/images/calenderIcon.svg"}
                            />
                          ),
                        },
                        required: true,
                        helperText:
                          errorField === "dob" && errorMsg,
                        error: errorField === "dob",
                      },
                    }}
                  />
                </LocalizationProvider>

                <Typography alignSelf={"center"} variant="subtitle2" color={theme.palette.white.dark}>OR</Typography>

                <FormControl sx={{ width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-age">
                    Age <span>*</span>
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-age"
                    label={"Age"}
                    autoComplete="off"
                    value={
                      typeof state.age === "object"
                        ? state?.age?.no_of_years
                        : state.age
                    }
                    required={true}
                    helperText={errorField === "age" && errorMsg}
                    error={errorField === "age"}
                    onChange={(e) => {
                      const onlyNums = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      if (onlyNums > 120 || onlyNums < 0) {
                        return;
                      } else {
                        setState({
                          age: { no_of_years: onlyNums || "" },
                        });
                        if (
                          errorField === "age" ||
                          errorField === "dob"
                        ) {
                          setErrorField("");
                        }
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        years
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              {shouldShowGuardianInputs() && (
                  <>
                    {/* <SearchInput label={'Guardian Name'} isSearchable={false}  value={state.guardianName} setValue={(val)=>{setState({guardianName:val})}}/> */}
                    {/* <SearchInput label={'Guardian Contact No.'} isSearchable={false}  value={state.guardianContactNo} setValue={(val)=>{setState({guardianContactNo:val})}}/> */}

                    <TextField
                      label={"Guardian Name"}
                      autoComplete="off"
                      required={state.age?.no_of_years < 18}
                      value={state.guardianName}
                      onChange={(e) => {
                        const regex = /^[A-Za-z ]*$/;
                        if (regex.test(e.target.value)) {
                          setState({
                            guardianName: e.target.value,
                          });
                          if (errorField === "guardian name") {
                            setErrorField("");
                          }
                        } else {
                          return;
                        }
                      }}
                      helperText={
                        errorField === "guardian name" && errorMsg
                      }
                      error={errorField === "guardian name"}
                    />
                    <TextField
                      label={"Guardian Contact No."}
                      autoComplete="off"
                      type="text"
                      required={state.age?.no_of_years < 18}
                      value={state.guardianContactNo}
                      onChange={(e) => {
                        const onlyNums = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        if (onlyNums.length > 10) {
                          return;
                        } else {
                          setState({ guardianContactNo: onlyNums });
                          if (errorField === "guardian contact") {
                            setErrorField("");
                          }
                        }
                      }}
                      helperText={
                        errorField === "guardian contact" &&
                        errorMsg
                      }
                      error={errorField === "guardian contact"}
                    />
                  </>
                )}
              <SearchInput
                label={"Email Address"}
                autoComplete="off"
                isSearchable={false}
                value={state.email}
                setValue={(val) => {
                  setState({ email: val });
                  if (errorField === "email") {
                    setErrorField("");
                  }
                }}
                helperText={errorField === "email" && errorMsg}
                error={errorField === "email"}
              />
              <SearchInput
                label={"Referring Doctor"}
                autoComplete="off"
                isSearchable={false}
                value={state.referringDoctor}
                setValue={(val) => {
                  const regex = /^[A-Za-z ]*$/;
                  if (regex.test(val)) {
                    setState({ referringDoctor: val });
                  } else {
                    return;
                  }
                }}
              />

              <TextField
                id="outlined-multiline-static"
                label="Address"
                autoComplete={"off"}
                multiline
                rows={2}
                sx={{ width: "100%" }}
                value={state.address}
                onChange={(e) => {
                  setState({ address: e.target.value });
                }}
              />
              <Box display={"flex"} gap={1}>
                <TextField
                  label="Pincode"
                  autoComplete={"off"}
                  sx={{ width: "100%" }}
                  value={state.pincode}
                  onChange={(e) => {
                    const onlyNums = e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );
                    if (onlyNums.length > 6) {
                      return;
                    }
                    if (onlyNums || e.target.value === "") {
                      setState({ pincode: e.target.value });
                      if (errorField === "pincode") {
                        setErrorField("");
                      }
                    }
                  }}
                  helperText={
                    errorField === "pincode" && errorMsg
                  }
                  error={errorField === "pincode"}
                />

                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    label="Country"
                    value={state.country}
                    onChange={(e) => {
                      setState({ country: e.target.value });
                    }}
                  >
                    {state.countryOptions?.map((country) => {
                      return <MenuItem value={country}>{country}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box display={"flex"} gap={1}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    label="State"
                    value={state.state}
                    onChange={(e) => {
                      setState({ state: e.target.value });
                    }}
                    disabled={state.country === ""}
                  >
                    {state.stateOptions?.map((state) => {
                      return <MenuItem value={state}>{state}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    label="City"
                    value={state.city}
                    onChange={(e) => {
                      setState({ city: e.target.value });
                    }}
                    disabled={state.state === ""}
                  >
                    {state.cityOptions?.map((city) => {
                      return <MenuItem value={city}>{city}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box
              position={"fixed"}
              bottom={0}
              paddingX={2}
              width={"100vw"}
              height={60}
              zIndex={100}
              backgroundColor={theme.palette.white.mobileLight}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={2}
              sx={{ boxSizing: "border-box" }}
            >
              <Button
                variant="outlined"
                onClick={handleGoBack}
                sx={{ flex: 1 }}
              >
                Cancel
              </Button>
              {/* <Button variant="contained" onClick={handleNewPatientSubmit}>Continue</Button> */}
              <LoadingButton
                variant="contained"
                loading={loading}
                sx={{
                  border: `1px solid  ${theme.palette.primary.main}`,
                  flex: 1,
                }}
                onClick={handleNewPatientSubmit}
                disabled={state.fullName=='' || state.phoneNumber=="" || (state.age.no_of_years!=="" ? false : state.dob=="") || (shouldShowGuardianInputs() && (state.guardianName==="" || state.guardianContactNo===""))}
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
          <ModalComponent
            open={openModal}
            setOpen={setOpenModal}
            isSuccess={modalData.isSuccess}
            headerText={modalData.headerText}
            subText={modalData.subText}
            actionButtonText={modalData.actionButtonText}
            actionButtonOnClick={modalData.actionButtonOnClick}
          />
        </>
      ) : (
        <Box height={"100%"} width={"100%"} zIndex={100} position={"absolute"}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            ContentProps={{ sx: { background: "red", marginTop: "40px" } }}
            open={showError}
            onClose={() => setShowError(false)}
            autoHideDuration={6000}
            message={errorMsg}
          />
          <GradientBox>
            <Grid container height="100%" spacing={2}>
              <Grid
                height="100%"
                item
                xs={3.3}
                sx={{
                  boxShadow: "1px 1px 2px 2px rgba(0,0,0,0.2)",
                  backgroundColor: "#ffffff",
                }}
              >
                <Box padding={2}>
                  <Typography>Add New Patient</Typography>
                </Box>
              </Grid>

              <Grid
                height="100%"
                item
                xs={8.7}
                backgroundColor={theme.palette.primary.background}
              >
                <Box
                  spacing={2}
                  height={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                >
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <IconButton onClick={handleGoBack}>
                      <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                      New Patient
                    </Typography>
                  </Box>

                  <Box
                    width={"92%"}
                    flex={1}
                    backgroundColor={theme.palette.white.main}
                    borderRadius={"6px"}
                    padding={2}
                  >
                    <Typography fontWeight={"bold"} mb={2} variant="subtitle2">
                      Patient Details
                    </Typography>
                    <Grid
                      container
                      sx={{ overflowY: "auto" }}
                      height={`calc(100vh - 300px)`}
                      spacing={2}
                      mt={1}
                    >
                      <Grid item xs={6}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={2}
                          mb={1}
                          height={420}
                        >
                          {/* <SearchInput label={'Full Name'} isSearchable={false} value={state.fullName} setValue={(val)=>{setState({fullName:val})}}/> */}
                          <TextField
                            label={"Full Name"}
                            type={"text"}
                            required={true}
                            value={state.fullName}
                            onChange={(e) => {
                              const regex = /^[A-Za-z ]*$/;
                              if (regex.test(e.target.value)) {
                                setState({ fullName: e.target.value });
                                if (errorField === "patient name") {
                                  setErrorField("");
                                }
                              } else {
                                return;
                              }
                            }}
                            helperText={
                              errorField === "patient name" && errorMsg
                            }
                            error={errorField === "patient name"}
                          />

                          {/* <SearchInput label={'Phone Number'} isSearchable={false}  value={state.phoneNumber} setValue={(val)=>{setState({phoneNumber:val})}}/> */}
                          <TextField
                            label={"Phone Number"}
                            autoComplete="off"
                            // type={"number"}
                            inputProps={{
                              min: "10", // Minimum value
                              max: "10", // Maximum value
                            }}
                            required={true}
                            value={state.phoneNumber}
                            onChange={(e) => {
                              const onlyNums = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              if (onlyNums.length > 10) {
                                return;
                              } else {
                                setState({ phoneNumber: onlyNums });
                                if (errorField === "phone number") {
                                  setErrorField("");
                                }
                              }
                            }}
                            helperText={
                              errorField === "phone number" && errorMsg
                            }
                            error={errorField === "phone number"}
                          />

                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={state.gender}
                              onChange={(e) => {
                                setState({ gender: e.target.value });
                              }}
                            >
                              <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label="Male"
                              />
                              <FormControlLabel
                                value="female"
                                control={<Radio />}
                                label="Female"
                              />
                              <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label="Others"
                              />
                            </RadioGroup>
                          </FormControl>
                          <Box
                            display={"flex"}
                            alignItems={"start"}
                            gap={1}
                            boxSizing={"border-box"}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                format="DD/MM/YYYY"
                                label="Date of Birth"
                                maxDate={dayjs()}
                                value={state.dob ? state.dob : null}
                                onChange={(value) => {
                                  if (
                                    errorField === "age" ||
                                    errorField === "dob"
                                  ) {
                                    setErrorField("");
                                  }
                                  if (value) {
                                    // If a valid date is selected, calculate the age
                                    const today = new Date();
                                    const birthDate = new Date(value);

                                    let years =
                                      today.getFullYear() -
                                      birthDate.getFullYear();
                                    let months =
                                      today.getMonth() - birthDate.getMonth();

                                    if (months < 0) {
                                      years--;
                                      months += 12;
                                    }

                                    if (!isNaN(years) && years <= 120) {
                                      setState({
                                        dob: value,
                                        age: {
                                          no_of_months: months, // Optionally calculate months
                                          no_of_years: years,
                                        },
                                      });
                                    } else {
                                      setState({
                                        dob: "",
                                        age: {
                                          no_of_months: "",
                                          no_of_years: "", // Default value for invalid/over-limit age
                                        },
                                      });
                                    }
                                  } else {
                                    // setErrorMsg("Patient age couldn't be more than 120")
                                    // setShowError(true)
                                    setState({
                                      dob: "",
                                      age: {
                                        no_of_months: "",
                                        no_of_years: "",
                                      },
                                    });
                                  }
                                }}
                                slotProps={{
                                  textField: {
                                    required: true,
                                    helperText:
                                      errorField === "dob" && errorMsg,
                                    error: errorField === "dob",
                                  },
                                }}
                              />
                            </LocalizationProvider>

                            <Typography alignSelf={"center"}>OR</Typography>
                            <FormControl
                              sx={{ width: "25ch", flex: 1 }}
                              variant="outlined"
                            >
                              <InputLabel htmlFor="outlined-adornment-age">
                                Age <span>*</span>
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-age"
                                label={"Age"}
                                autoComplete="off"
                                value={
                                  typeof state.age === "object"
                                    ? state?.age?.no_of_years
                                    : state.age
                                }
                                required={true}
                                helperText={errorField === "age" && errorMsg}
                                error={errorField === "age"}
                                onChange={(e) => {
                                  const onlyNums = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  if (onlyNums > 120 || onlyNums < 0) {
                                    return;
                                  } else {
                                    setState({
                                      age: { no_of_years: onlyNums || "" },
                                    });
                                    if (
                                      errorField === "age" ||
                                      errorField === "dob"
                                    ) {
                                      setErrorField("");
                                    }
                                  }
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    years
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </Box>

                          {shouldShowGuardianInputs() && (
                              <>
                                {/* <SearchInput label={'Guardian Name'} isSearchable={false}  value={state.guardianName} setValue={(val)=>{setState({guardianName:val})}}/> */}
                                {/* <SearchInput label={'Guardian Contact No.'} isSearchable={false}  value={state.guardianContactNo} setValue={(val)=>{setState({guardianContactNo:val})}}/> */}

                                <TextField
                                  label={"Guardian Name"}
                                  autoComplete="off"
                                  required={state.age?.no_of_years < 18}
                                  value={state.guardianName}
                                  onChange={(e) => {
                                    const regex = /^[A-Za-z ]*$/;
                                    if (regex.test(e.target.value)) {
                                      setState({
                                        guardianName: e.target.value,
                                      });
                                      if (errorField === "guardian name") {
                                        setErrorField("");
                                      }
                                    } else {
                                      return;
                                    }
                                  }}
                                  helperText={
                                    errorField === "guardian name" && errorMsg
                                  }
                                  error={errorField === "guardian name"}
                                />
                                <TextField
                                  label={"Guardian Contact No."}
                                  autoComplete="off"
                                  type="text"
                                  required={state.age?.no_of_years < 18}
                                  value={state.guardianContactNo}
                                  onChange={(e) => {
                                    const onlyNums = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    if (onlyNums.length > 10) {
                                      return;
                                    } else {
                                      setState({ guardianContactNo: onlyNums });
                                      if (errorField === "guardian contact") {
                                        setErrorField("");
                                      }
                                    }
                                  }}
                                  helperText={
                                    errorField === "guardian contact" &&
                                    errorMsg
                                  }
                                  error={errorField === "guardian contact"}
                                />
                              </>
                            )}
                          <SearchInput
                            label={"Email Address"}
                            autoComplete="off"
                            isSearchable={false}
                            value={state.email}
                            setValue={(val) => {
                              setState({ email: val });
                              if (errorField === "email") {
                                setErrorField("");
                              }
                            }}
                            helperText={errorField === "email" && errorMsg}
                            error={errorField === "email"}
                          />
                          <SearchInput
                            label={"Referring Doctor"}
                            autoComplete="off"
                            isSearchable={false}
                            value={state.referringDoctor}
                            setValue={(val) => {
                              const regex = /^[A-Za-z ]*$/;
                              if (regex.test(val)) {
                                setState({ referringDoctor: val });
                              } else {
                                return;
                              }
                            }}
                          />
                          <Typography
                            my={2}
                            variant="caption"
                            sx={{ cursor: "pointer" }}
                            color={theme.palette.primary.main}
                            textTransform={"uppercase"}
                            onClick={toggleShowAdditionalfields}
                          >
                            {`${!showAdditionalFields ? "show" : "hide"}`}{" "}
                            additional fields
                          </Typography>
                        </Box>
                      </Grid>
                      {showAdditionalFields && (
                        <Grid item xs={6}>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                          >
                            <TextField
                              id="outlined-multiline-static"
                              label="Address"
                              autoComplete={"off"}
                              multiline
                              rows={2}
                              sx={{ width: "100%" }}
                              value={state.address}
                              onChange={(e) => {
                                setState({ address: e.target.value });
                              }}
                            />
                            <Box display={"flex"} gap={1}>
                              <TextField
                                label="Pincode"
                                autoComplete={"off"}
                                sx={{ width: "100%" }}
                                value={state.pincode}
                                onChange={(e) => {
                                  const onlyNums = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  if (onlyNums.length > 6) {
                                    return;
                                  }
                                  if (onlyNums || e.target.value === "") {
                                    setState({ pincode: e.target.value });
                                    if (errorField === "pincode") {
                                      setErrorField("");
                                    }
                                  }
                                }}
                                helperText={
                                  errorField === "pincode" && errorMsg
                                }
                                error={errorField === "pincode"}
                              />

                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Country
                                </InputLabel>
                                <Select
                                  label="Country"
                                  value={state.country}
                                  onChange={(e) => {
                                    setState({ country: e.target.value });
                                  }}
                                >
                                  {state.countryOptions?.map((country) => {
                                    return (
                                      <MenuItem value={country}>
                                        {country}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box display={"flex"} gap={1}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  State
                                </InputLabel>
                                <Select
                                  label="State"
                                  value={state.state}
                                  onChange={(e) => {
                                    setState({ state: e.target.value });
                                  }}
                                  disabled={state.country === ""}
                                >
                                  {state.stateOptions?.map((state) => {
                                    return (
                                      <MenuItem value={state}>{state}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  City
                                </InputLabel>
                                <Select
                                  label="City"
                                  value={state.city}
                                  onChange={(e) => {
                                    setState({ city: e.target.value });
                                  }}
                                  disabled={state.state === ""}
                                >
                                  {state.cityOptions?.map((city) => {
                                    return (
                                      <MenuItem value={city}>{city}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Box>

                  <Box
                    width={"92%"}
                    height={60}
                    backgroundColor={theme.palette.white.medium}
                    borderRadius={"6px"}
                    marginBottom={8}
                    padding={1}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Button variant="outlined" onClick={handleGoBack}>
                      Cancel
                    </Button>
                    {/* <Button variant="contained" onClick={handleNewPatientSubmit}>Continue</Button> */}
                    <LoadingButton
                      variant="contained"
                      loading={loading}
                      sx={{
                        border: `1px solid  ${theme.palette.primary.main}`,
                      }}
                      onClick={handleNewPatientSubmit}
                      disabled={state.fullName=='' || state.phoneNumber=="" || (state.age!=="" ? false : state.dob=="") || (shouldShowGuardianInputs() && (state.guardianName==="" || state.guardianContactNo===""))}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </GradientBox>
          <ModalComponent
            open={openModal}
            setOpen={setOpenModal}
            isSuccess={modalData.isSuccess}
            headerText={modalData.headerText}
            subText={modalData.subText}
            actionButtonText={modalData.actionButtonText}
            actionButtonOnClick={modalData.actionButtonOnClick}
          />
        </Box>
      )}
    </>
  );
};

export default NewPatient;
