export const ROUTE_PAGE_NOT_FOUND = `/404`;
export const ROUTE_LANDING = `/`;
export const ROUTE_LOGIN = `/login`;
export const ROUTE_LOGINOTP = `/loginotp`;
export const ROUTE_FORGOTPASSWORD = `/forgotpassword`;

export const ROUTE_APPOINTMENTS = `/appointments`;
export const ROUTE_NEW_PATIENT = `/appointments/newpatient`;
export const ROUTE_NEW_APPOINTMENT = `/appointments/newappointment`;

export const ROUTE_GET_PRESCRIPTION = `/display/prescription`;

//admin
export const ROUTE_SUPER_ADMIN_INIT = `/superadmin`;
export const ROUTE_SUPER_ADMIN_SETUP = `/superadmin/setup`;
export const ROUTE_ADMIN = `/admin`;
export const ROUTE_KPI = `/kpi`;
export const ROUTE_ADMIN_USER_MANAGEMENT = `/admin/usermanagement`;
export const ROUTE_ADMIN_CLINIC_MANAGEMENT_INIT = `/admin/clinicmanagement/start`;
export const ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC = `/admin/clinicmanagement/newclinic`;
export const ROUTE_ADMIN_CLINIC_MANAGEMENT_EDIT_CLINIC = `/admin/clinicmanagement/editclinic`;
export const ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC = `/admin/clinicmanagement/allclinic`;
export const ROUTE_ADMIN_CLINIC_MANAGEMENT_CLINIC_DETAILS = `/admin/clinicmanagement/clinicdetails`;
export const ROUTE_ADMIN_SLOT_MANAGEMENT = `/admin/slotmanagement`;


//for user forms
export const ROUTE_ADMIN_SINGLE_DOCTOR_VIEW =(clinic_id:string,doctor_id:string)=> `/admin/user/single/view/${clinic_id}/${doctor_id}`;
export const ROUTE_ADMIN_SINGLE_DOCTOR_EDIT =(clinic_id:string,doctor_id:string)=> `/admin/user/single/edit/${clinic_id}/${doctor_id}`;

export const ROUTE_MY_PATIENTS = `/my-patients`
export const ROUTE_ARTICLES = `/articles`
export const ROUTE_ARTICLES_DETAILS = `articles/details`

//for rx template

export const ROUTE_ADMIN_RX_TEMPLATES = `/rxadmin`;
export const ROUTE_ADMIN_LIST_RX_TEMPLATES = `/rxadmin/prescrption/rxtemplates`;
export const ROUTE_ADMIN_NEW_RX_TEMPLATE_FORM = `/rxadmin/prescrption/rxtemplate/new`;
export const ROUTE_ADMIN_EDIT_RX_TEMPLATE_FORM = (template_id:any)=>`/rxadmin/prescrption/rxtemplate/${template_id}`;
export const ROUTE_ADMIN_EDIT_RX_TEMPLATE_SPECIALITY = (template_id:any)=>`/rxadmin/prescrption/rxtemplate/speciality/${template_id}`;
export const ROUTE_ADMIN_EDIT_RX_TEMPLATE_DOCTOR = (template_id:any)=>`/rxadmin/prescrption/rxtemplate/doctor/${template_id}`;

//for clinical matrix
export const ROUTE_ADMIN_CLINICAL_MATRIX = `/kpi/clinicalmatrix`;
export const API_ROUTE_GET_CLINICAL_MATRIX = ()=>`api/common/kpi_dashboard`;
export const ROUTE_PRESCRIPTION = (appointment_id: any) =>
  `prescription/${appointment_id}`;

//invoice
export const ROUTE_ALL_INVOICES = `invoice/allinvoice`
export const ROUTE_CREATE_INVOICE = `invoice/createinvoice`

// API ROUTES
export const API_ROUTE_FETCH_APPOINTMENT_BY_ID = (appt_id: any) =>
  `appointment/appointment/${appt_id}`;
export const API_ROUTE_APPOINTMENTS = (doctor_id: any) =>
  `appointment/appointments/byrange/${doctor_id}`;
  export const API_ROUTE_APPOINTMENTS_COUNT = (doctor_id: any) =>
  `appointment/appointments/countbyrange/${doctor_id}`;
export const API_ROUTE_APPOINTMENT_UPDATE = (user_id: any) =>
  `appointment/appointment/${user_id}`;
export const API_ROUTE_APPOINTMENT_REASONS = (type: any) =>
  `api/common/get_reasons/${type}`;
export const API_ROUTE_APPOINTMENT_TIMESLOTS = (doctor_id: any, date: any,account_id:any) =>
  `appointment/offlineappointment/getslots/${doctor_id}?date=${date}&slot_type=offline&account_id=${account_id}`;
export const API_ROUTE_CREATE_APPOINTMENT = () =>
  `appointment/offlineappointment/create`;
export const API_ROUTE_RESCHEDULE_APPOINTMENT = (appointment_id: any) =>
  `appointment/appointment/${appointment_id}/reschedule`;
export const API_ROUTE_PRESCRIPTION_DOCTOR_TEMPLATES = () =>
  `prescription/doctor-templates`;
export const API_ROUTE_PRESCRIPTIONS_DETAILS = () =>
  `prescription/get-prescription-details`;
export const API_ROUTE_PRESCRIPTION_VIEW_URL = () => `prescription/view-url`;
export const API_ROUTE_FETCH_DOCTOR_SUGGESTIONS = `prescription/get-doctor-suggestions`;
export const API_ROUTE_PRESCRIPTION_PATIENT_DOCUMENTS = () =>
  `prescription/get-documents`;
export const API_ROUTE_SAVE_PRESCRIPTION = () =>
  `prescription/save-prescription`;
export const API_ROUTE_GET_PATIENT_TIMELINE = () => `prescription/get-timeline`;
export const API_ROUTE_PRESCRIPTION_SAVE_DOCUMENTS = () =>
  `prescription/save-document`;
export const API_ROUTE_PRESCRIPTION_DELETE_DOCUMENT = (id: any) =>
  `prescription/delete-document/${id}`;
export const API_ROUTE_PRESCRIPTION_RECOMMENDATION = () =>
  `prescription/recommendations`;

//Patients
export const API_ROUTE_SEARCH_PATIENT = (searchterm: any, searchby: any) =>
  `patient/patients/search?term=${searchterm}&get_data_by=${searchby}`;
export const API_ROUTE_PATIENT = () => `patient/patient`;
export const API_ROUTE_FETCH_COUNTRIES = (account_id: any, filter_type: any) =>
  `onlinepatient/get-tp-master-data?account_id=svaas&filter_type=${filter_type}`;
export const API_ROUTE_FETCH_STATE = (
  account_id: any,
  filter_type: any,
  country_name: any
) =>
  `onlinepatient/get-tp-master-data?account_id=svaas&filter_type=${filter_type}&country_name=${country_name}`;
export const API_ROUTE_FETCH_CITY = (
  account_id: any,
  filter_type: any,
  country_name: any,
  state_name: any
) =>
  `onlinepatient/get-tp-master-data?account_id=svaas&filter_type=${filter_type}&country_name=${country_name}&state_name=${state_name}`;

export const API_ROUTE_PROFILE = `user/user/profile`;
export const API_ROUTE_ACCOUNT_DETAILS = (doctor_id: any, account_id: any) =>
  `appointment/onlineappointment/get-account-info?account_id=${account_id}&video_settings=true&doctor_id=${doctor_id}`;

export const API_ROUTE_TELE_CALL = `api/svaas/outbound/tele_call`;

export const API_ROUTE_FETCH_DOCTORS = (user_id:string)=>`user/user/projections/${user_id}?projection=doctor_id,doctor_name`

export const API_ROUTE_FETCH_CLINICS = ()=>`admin/admin/account/all`

export const API_ROUTE_CREATE_CLINIC = ()=>`admin/admin/account`
export const API_ROUTE_EDIT_CLINIC = (clinic_id:any)=>`admin/admin/account/${clinic_id}`

export const API_ROUTE_FETCH_USERS_LIST = (account_id:any,is_active:any = true)=>`user/users?account_id=${account_id}&is_active=${is_active}`

export const API_ROUTE_FETCH_DOCTOR_LIST = (role:any,account_id:any)=>`user/users?role=${role}&account_id=${account_id}`

export const API_ROUTE_CLINIC_DETAILS_ID = (account_id: any) =>
  `appointment/onlineappointment/get-account-info?account_id=${account_id}`;

export const API_ROUTE_ADD_USER = () =>
  `user/user`;
export const API_ROUTE_UPDATE_USER = (user_id:any,account_id:any) =>
  `user/user/${user_id}?account_id=${account_id}`;
export const API_ROUTE_USER_DETAILS = (cognito_user_sub:any,account_id:any) =>
  `user/onlinedoctor/details?account_id=${account_id}&cognito_user_sub=${cognito_user_sub}`;

export const API_ROUTE_DOCTOR_DETAILS = (account_id:string,cognito_user_sub:string) =>
  `user/onlinedoctor/details?account_id=${account_id}&cognito_user_sub=${cognito_user_sub}`;

export const API_ROUTE_UPDATE_ONLINEDOC = () =>
  `user/onlinedoctor`;

export const API_ROUTE_DELETE_CLINIC = (account_id:string) =>
  `admin/admin/account/${account_id}`;

export const API_ROUTE_DELETE_USER = (user_id:string,account_id:string) =>
  `user/user/${user_id}?account_id=${account_id}`;


export const API_ROUTE_GET_SIGNED_URL = (type:string,content_type:string) =>
  `prescription/upload-url?type=${type}&content_type=${content_type}`;

export const API_ROUTE_SEARCH_CLINIC = (limit:string,account_name:string) =>
  `admin/admin/account/all?limit=${limit}&account_name=${account_name}`;

export const API_ROUTE_MY_PATIENT = () => `patient/patients`

export const API_ROUTE_ADMIN_RESET_PASSWORD = () => `user/user/admin/set_user_password`

export const API_ROUTE_ADMIN_VALIDATE_SLOTS = () => `user/user/validate`


export const API_ROUTE_GET_VITALS_DATA = (patientId:any,accountId:any,limit:any) => `prescription/get-vitals?patientId=${patientId}&accountId=${accountId}&viewType=vitals&limit=${limit}`

//invoice

export const API_ROUTE_GET_ALL_INVOICE = (patientId:any) => `onlinepatient/invoice/patient/${patientId}`

export const API_ROUTE_CREATE_INVOICE = () => `onlinepatient/invoice/generate`


//prescription open api

export const API_ROUTE_OPEN_PRESCRIPTION_DETAILS = (patientId:any) => `onlinepatient/invoice/patient/${patientId}`

