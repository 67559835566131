import {Box, Typography, useTheme,useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const legends = [
    {
        label:'Bradycardia',
        boarderColor:'#D1D3EA',
        backgroundColor:'#d1d3ea33'
    },
    {
        label:'Normal',
        boarderColor:'#34B3A8',
        backgroundColor:'#34b3a833'
    },
    {
        label:'Tachycardia',
        boarderColor:'#fb585a',
        backgroundColor:'#fb585a33'
    },
]

const HRChart = () => {

    
      
    // const data = {
    //     labels: ['12/6/23', '15/6/23', '21/7/23', '28/7/23', '5/8/23', '5/8/23', '27/10/23'],
    //     datasets: [
    //       {
    //         label: 'Heart Rate (BPM)',
    //         data:[
    //             {x:'12/6/23',y:100,hr:100,hrLabel:'low'},
    //             {x:'15/6/23',y:70,hr:70,hrLabel:'high'},
    //             {x:'21/7/23',y:78,hr:78,hrLabel:'normal'},
    //             {x:'28/7/23',y:60,hr:60,hrLabel:'normal'},
    //             {x:'5/8/23',y:120,hr:120,hrLabel:'high'},
    //             {x:'5/8/23',y:110,hr:110,hrLabel:'low'},
    //             {x:'27/10/23',y:98,hr:98,hrLabel:'normal'},
    //         ],
    //         borderColor: '#AA91F8',
    //         backgroundColor: '#AA91F8',
    //       },
    //     ],
    // };
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [data,setData] = useState({
        labels: [],
        datasets: [
          {
            label: 'Heart Rate (BPM)',
            data:[
                // {x:'12/6/23',y:100,hr:100,hrLabel:'low'},
            ],
            borderColor: '#AA91F8',
            backgroundColor: '#AA91F8',
          },
        ],
    })

    const [minValue, setMinValue] = useState(25-10);
    const [maxValue, setMaxValue] = useState(600+50);

    const {
        pastVitalsData : {data:rawData,error,pastVitalsLoader}
    } = useSelector((state) => state.prescription);

    useEffect(()=>{
        if(rawData?.VITALS?.length){
            let rawDataArray = rawData?.VITALS?.find(elem=>elem.name=='prhr');

            setMinValue(rawDataArray?.min < minValue ? rawDataArray?.min : minValue)
            setMaxValue(rawDataArray?.max > maxValue ? rawDataArray?.max : maxValue)
            
            if(rawDataArray?.values){
                let newData = data;
                rawDataArray?.values?.forEach(row=>{
                    let value = row?.value
                    if(value){
                        newData.labels.push(dayjs(row?.date,'YYYY-MM-DD').format('DD/MM/YYYY'));
                        newData.datasets[0].data.push({
                            x:dayjs(row?.date,'YYYY-MM-DD').format('DD/MM/YYYY'),
                            y:value,
                            hr:value,
                            hrLabel:row?.type
                        })
                    }
                })
                setData({...newData});
            }
        }
    },[rawData])

    const canvasBackgroundColor = {
        id:'canvasBackgroundColor',
        beforeDraw(chart,args,pluginOptions){
            const {ctx, chartArea: {top,bottom,left,right,width}, scales:{x,y}} = chart;

            ctx.save();

            colorBg(25,60,'#d1d3ea26');
            colorBg(60,100,'#34b3a826');
            colorBg(100,600,'#fb585a26');

            function colorBg(bracketLow,bracketHigh,color){
                ctx.fillStyle = color
                ctx.fillRect(left,y.getPixelForValue(bracketHigh), width, y.getPixelForValue(bracketLow)-y.getPixelForValue(bracketHigh))
                ctx.restore();
            }
        },
    }

    const options = {
        
        responsive: true,
        maintainAspectRatio: isMobileScreen ? false : true,
        plugins: {
            legend: {
                position: 'top',
                display:false
            },
            title: {
                display: false,
                text: 'HR Chart',
            },
            tooltip: {
                // Disable the on-canvas tooltip
                enabled: false,

                external: function(context) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = '<thead>';

                        titleLines.forEach(function(title) {
                            innerHtml += '<tr><th>' + title + '</th></tr>';
                        });
                        innerHtml += '</thead><tbody>';

                        bodyLines.forEach(function(body, i) {
                            const colors = tooltipModel.labelColors[i];
                            let style = 'background:' + colors.backgroundColor;
                            style += '; border-color:' + colors.borderColor;
                            style += '; border-width: 2px';
                            const span = '<span style="' + style + '">' + body + '</span>';
                            innerHtml += '<tr><td>' + span + '</td></tr>';
                        });
                        innerHtml += '</tbody>';

                        let tableRoot = tooltipEl.querySelector('table');
                        tableRoot.style.padding = '8px'
                        tableRoot.style.backgroundColor = '#ffffff'
                        tableRoot.style.boxShadow = '1px 1px 4px #666666'


                        // tableRoot.innerHTML = innerHtml;

                        let hrLabelDotColor;
                        let hrLabelBackgroundColor;
                        switch(context?.tooltip?.dataPoints[0]?.raw?.hrLabel){
                            case 'normal':
                                hrLabelDotColor = '#34B3A8';
                                hrLabelBackgroundColor = '#34b3a833';
                                break;
                            case 'bradycardia':
                                hrLabelDotColor = '#D1D3EA';
                                hrLabelBackgroundColor = '#d1d3ea33';
                                break;
                            case 'tachycardia':
                                hrLabelDotColor = '#FF9A6C';
                                hrLabelBackgroundColor = '#ff9a6c33';
                                break;
                            default:
                                hrLabelDotColor = '#34B3A8';
                                hrLabelBackgroundColor = '#34b3a833';
                                break;
                        }

                        tableRoot.innerHTML = `
                            <thead>
                                <tr>
                                    <th>
                                        <div style="display:flex;justify-content:center; align-items:center ; background-color:${hrLabelBackgroundColor}; border-radius:36px; padding: 4px 32px;">
                                            <div style="margin-right:5px; width:6px; height:6px; border-radius:50%; background-color:${hrLabelDotColor};"></div>
                                            <div style="font-size:14px;">${context?.tooltip?.dataPoints[0]?.raw?.hrLabel?.charAt(0)?.toUpperCase() + context?.tooltip?.dataPoints[0]?.raw?.hrLabel?.slice(1)}</div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="display:flex; flex-direction:column; align-items:start;">
                                        <p style="color:#313131;font-size:10px; padding:0px; margin:0px;">Time</p>
                                        <p style="font-size:14px; padding:0px; margin:0px;font-weight:bold;">${context?.tooltip?.dataPoints[0]?.raw?.x}</p>
                                    </td>
                                <tr>
                                <tr>
                                    <td style="display:flex; flex-direction:column; align-items:start;">
                                        <p style="color:#313131;font-size:10px; padding:0px; margin:0px;">Heart Rate (BPM)</p>
                                        <p style="font-size:14px; padding:0px; margin:0px;font-weight:bold;">${context?.tooltip?.dataPoints[0]?.raw?.hr}</p>
                                    </td>
                                <tr>
                            </tbody>
                        `;
                    }

                    const position = context.chart.canvas.getBoundingClientRect();
                    const tooltipX = position.left + window.pageXOffset + tooltipModel.caretX;
                    const chartWidth = context.chart.canvas.clientWidth;
                    const tooltipWidth = tooltipEl.clientWidth;

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    // If the tooltip is off the right side of the chart, position it to the left of the cursor
                    if (tooltipX + tooltipWidth > chartWidth) {
                        tooltipEl.style.left = (tooltipX - tooltipWidth) + 'px';
                    } else {
                        tooltipEl.style.left = tooltipX + 'px';
                    }
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            }
          
        },
        scales:{
            y:{
                title:{
                    display:true, 
                    text:"Heart Rate (BPM)",
                    font:{
                        weight:'bold',
                        size: 14
                    },
                },
                min:minValue,
                max:maxValue,
                
                stacked:true,
                
                ticks: {
                    callback: function(val, index) {
                      return val < 0 ? this.getLabelForValue(val)?.replace('-','') : this.getLabelForValue(val);
                    },
                    stepSize: 20,
                },
                
            },
            x:{
                title:{
                    display:true, 
                    text:'Date',
                    font:{
                        weight:'bold',
                        size: 14
                    },
                },
                grid: {
                    display: false
                },
                stacked:true
            }
            
        },
        barThickness: 13
        
    };

    return (
        <Box height={'100%'}  display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box height={isMobileScreen?'300px' : '100%'} width={'100%'} display={'flex'}>
                <Bar
                    options={options}
                    data={data}
                    plugins={[canvasBackgroundColor]}
                />
            </Box>
            <Box display={'flex'} gap={2} mt={2}>
                {legends?.map(elem=>{
                    return (
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                            <Box width={'13px'} height={'13px'} borderRadius={'50%'} border={`1px solid ${elem.boarderColor}`} backgroundColor={elem.backgroundColor}/>
                            <Typography variant='caption'>{elem.label}</Typography>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default HRChart