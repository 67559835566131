import authSlice from "./auth.slice";
import appointmentSlice from "./appointment.slice";
import prescriptionSlice from "./prescription.slice";
import adminSlice from "./admin.slice";

let reducers;

// eslint-disable-next-line
export default reducers = {
  auth: authSlice,
  appointments: appointmentSlice,
  prescription: prescriptionSlice,
  admin:adminSlice,
};
