import { Box, Button } from "@mui/material";

 interface ReverseIconProps {
    fillColor?: string;
  }
const ReverseIcon: React.FC<ReverseIconProps> = ({ fillColor }) => {
 return (
    <Box sx={{cursor:"pointer"}}>
      <svg
        width="10"
        height="18"
        viewBox="0 0 10 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.57457 1.128C10.1031 1.69073 10.1015 2.56782 9.57104 3.12868L4.01775 9L9.57268 14.8859C10.1019 15.4466 10.1014 16.3229 9.57159 16.8831C8.99791 17.4896 8.03246 17.4896 7.45878 16.8831L0.909853 9.95912C0.400877 9.42099 0.400876 8.57901 0.909852 8.04088L7.45183 1.12425C8.02865 0.514404 8.99991 0.516117 9.57457 1.128Z"
          fill={fillColor}
        />
      </svg>
    </Box>
  );
};
export default ReverseIcon;
