import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Snackbar,
  Stack,
  StepContent,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, Stepper, Step, StepLabel } from "@material-ui/core";
import React, { useEffect, useReducer, useState } from "react";
import { GradientBox } from "./GradientBox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useTheme } from "@emotion/react";
import { SearchInput } from "./SearchInput";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import UAParser from "ua-parser-js";
import { useDispatch, useSelector } from "react-redux";
import {
  API_ROUTE_SEARCH_PATIENT,
  API_ROUTE_APPOINTMENT_TIMESLOTS,
  API_ROUTE_CREATE_APPOINTMENT,
  API_ROUTE_RESCHEDULE_APPOINTMENT,
  API_ROUTE_FETCH_COUNTRIES,
  API_ROUTE_FETCH_STATE,
  API_ROUTE_FETCH_CITY,
  ROUTE_APPOINTMENTS,
} from "../../../shared/routes/route.constants";
import dayjs from "dayjs";
import ModalComponent from "./Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import { APIClient } from "../../../shared/Utils/api-client";
import moment from "moment";
import "../../../assets/styles/mui-button-styles.css";
import { isValidEmail } from "../../../shared/Utils/utils";
import OverwriteModalComponent from "./OverwriteModal";
import { result } from "lodash";
import { useNavigate } from "react-router-dom";
import { resetReschedule } from "../../../redux/reducers/appointment.slice";

const steps = [
  {
    label: "PATIENT DETAILS",
  },
  {
    label: "APPOINTMENT DETAILS",
  },
];

const initState = {
  fullName: "",
  phoneNumber: "",
  gender: "male",
  dob: "",
  age: {
    no_of_days: "",
    no_of_months: "",
    no_of_years: "",
  },
  patientUHID: "",
  clinicName: "",
  clinicAddress: "",
  selectedDate: new Date(),
  selectedSlot: "",
  slotType: "",
  guardianName: "",
  guardianContactNo: "",
  email: "",
  referringDoctor: "",
  address: "",
  pincode: "",
  city: "",
  state: "",
  country: "India",
  countryOptions: [],
  stateOptions: [],
  cityOptions: [],
  shouldShowGuardianInputs:false,
};


const NewAppointment = () => { 
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isReschedule,rescheduleData} = useSelector((state)=>state.appointments)
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorField, setErrorField] = useState("");
  const { userDetails, accountInfo, profile,selectedDoctor } = useSelector(
    (state) => state.auth
  );
  const [userIpAddress, setUserIdAddress] = useState("");
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [state, setState] = useReducer((state, action) => {
    return {
      ...state,
      ...action,
    };
  }, initState);

  const [parentAppointmentId,setParentAppointmentId] = useState('');

  useEffect(() => {
    getIPAddress().then((ip) => setUserIdAddress(ip));
  }, []);
  async function getIPAddress() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  }
  const [loading, setLoading] = useState(false);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  const [modalData, setModalData] = useState({
    isSuccess: true,
    headerText: "",
    subText: "",
    actionButtonText: "",
    actionButtonOnClick: () => {
      return;
    },
  });
  const [openModal, setOpenModal] = useState(false);
  const [openOverModal, setOverModal] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [slots, setSlots] = useState({
    morning: [],
    afternoon: [],
    evening: [],
  });

  const currentDate = new Date();

  const [days, setDays] = useState([
    new Date(currentDate),
    new Date(currentDate.setDate(currentDate.getDate() + 1)),
    new Date(currentDate.setDate(currentDate.getDate() + 1)),
    new Date(currentDate.setDate(currentDate.getDate() + 1)),
    new Date(currentDate.setDate(currentDate.getDate() + 1)),
    new Date(currentDate.setDate(currentDate.getDate() + 1)),
    new Date(currentDate.setDate(currentDate.getDate() + 1)),
  ]);

  const currentDateMobile = new Date();

  const [daysMobile, setDaysMobile] = useState([
    new Date(currentDateMobile),
    new Date(currentDateMobile.setDate(currentDateMobile.getDate() + 1)),
    new Date(currentDateMobile.setDate(currentDateMobile.getDate() + 1)),
  ]);

  const useStylesStepper = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-completed": { color: theme.palette.green.main },
    },
  }));

  const stepperStyles = useStylesStepper();

  useEffect(() => {

    if(localStorage.getItem('isFollowUp')){
      setParentAppointmentId(localStorage.getItem('isFollowUp'));
      localStorage.removeItem('isFollowUp');
    }

    if (isReschedule || rescheduleData?.name) {
      setState({
        fullName: rescheduleData.name,
        phoneNumber: rescheduleData.phone,
        gender:
          rescheduleData.gender.toLowerCase() === "male"
            ? "male"
            : rescheduleData.gender.toLowerCase() === "female"
            ? "female"
            : "other",
        dob: "",
        age:
          typeof state.age === "object"
            ? {
                no_of_days: "",
                no_of_months: "",
                no_of_years: rescheduleData?.age?.no_of_years,
              }
            : rescheduleData.age,
        patientUHID: rescheduleData?.patient_hid,
        clinicName: "",
        clinicAddress: "",
        selectedDate: new Date(),
        selectedSlot: "",
        guardianName: rescheduleData?.guardian_name
          ? rescheduleData?.guardian_name
          : "",
        guardianContactNo: rescheduleData?.guardian_phone
          ? rescheduleData?.guardian_phone
          : "",
        email: rescheduleData?.email,
        slotType: "",
        city: rescheduleData?.address?.city,
        country: rescheduleData?.address?.country,
        address: rescheduleData?.address?.line1,
        state: rescheduleData?.address?.state,
        pincode: rescheduleData?.address?.zipcode,
        referringDoctor: rescheduleData?.referred_doctor_name,
      });
      setActiveStep(1);
    } else {
      setState({
        selectedDate: new Date(),
      });
    }

    setState({
      clinicName: accountInfo?.account_name || profile?.name,
      clinicAddress: `${accountInfo?.address?.line ? accountInfo?.address?.line+" ":""}${accountInfo?.address?.city ? accountInfo?.address?.city+" ":""}${accountInfo?.address?.state ? accountInfo?.address?.state+" ":""}${accountInfo?.address?.country ? accountInfo?.address?.country+" ":""}${accountInfo?.address?.zipcode ? accountInfo?.address?.zipcode:""}` || profile?.display_address
    });

    shouldShowGuardianInputs();

    return () => {
      setActiveStep(0);
      dispatch(resetReschedule())
    };
  }, []);

  useEffect(() => {

    if (selectedOption?.value) {
      
      setState({
        fullName: selectedOption?.value?.name || '',
        phoneNumber: selectedOption?.value?.phone || '',
        gender: selectedOption?.value?.gender?.toLowerCase(),
        dob: selectedOption?.value?.date_of_birth
          ? dayjs(new Date(selectedOption?.value?.date_of_birth))
          : "",
        age: {
          no_of_days: selectedOption?.value?.age?.no_of_days,
          no_of_months: selectedOption?.value?.age?.no_of_months,
          no_of_years: selectedOption?.value?.age?.no_of_years,
        },
        patientUHID: selectedOption?.value?.patient_hid || '',
        selectedDate: new Date(),
        selectedSlot: "",
        guardianName: selectedOption?.value?.guardian_name || '',
        guardianContactNo: selectedOption?.value?.guardian_phone || '',
        email: selectedOption?.value?.email || '',
        city: selectedOption?.value?.address?.city || '',
        country: selectedOption?.value?.address?.country || '',
        address: selectedOption?.value?.address?.line1 || '',
        state: selectedOption?.value?.address?.state || '',
        pincode: selectedOption?.value?.address?.zipcode || '',
        referringDoctor: selectedOption?.value?.referred_doctor_name || '',
      });
      if (Object.keys(selectedOption?.value?.address)?.length !== 0) {
        setShowAdditionalFields(true);
      }
    }
    shouldShowGuardianInputs();
  }, [selectedOption]);

  useEffect(() => {
    fetchTimeSlots();
  }, [state?.selectedDate]);

  useEffect(() => {
    fetchCountryOptions().then((res) => {
      setState({ country: "India" });
    });
  }, []);

  useEffect(() => {
    if (state.country) {
      fetchStateOptions();
    }
  }, [state.country]);

  useEffect(() => {
    if (state.state) {
      fetchCityOptions();
    }
  }, [state.state]);

  useEffect(()=>{
    shouldShowGuardianInputs();
  },[state.age.no_of_years,state.dob])

  const submitNewAppointment = async () => {
    let details = new UAParser().getResult();

    setLoading(true);

    if(!isReschedule){
        let requestBody = {
            ...(parentAppointmentId!=='' ? {parent_appointment_id:parentAppointmentId} : {}),
            "account_id":accountInfo?.account_id,
            "appointment_date": moment(state.selectedDate)?.format('YYYY-MM-DD'),
            "remarks": "",
            "institution": "",
            "appointment_slot": state.selectedSlot,
            "slot_type": "offline",
            "rescheduled_appointment_date": "",
            "rescheduled_appointment_slot": "",
            "appointment_type": "offline",
            "service_name": "",
            "appointment_status": "",
            "invoice_amount": "",
            "patient": {
                "name": state.fullName,
                age:state.age.no_of_years!=="" ? state.age: {no_of_days:'',no_of_months:'',no_of_years:new Date().getFullYear() - new Date(state?.dob).getFullYear()},
                "email": state.email,
                address:{
                    city:state.city,
                    country:state.country,
                    line1:state.address,
                    line2:'',
                    state:state.state,
                    zipcode:state.pincode
                },
                "date_of_birth": state.dob ? moment(new Date(state.dob)).format('YYYY-MM-DD') : null,
                "country_code": "+91",
                "gender": state.gender,
                "phone": state.phoneNumber,
                referred_doctor_name:state.referringDoctor,
                guardian_phone:state.guardianContactNo,
                guardian_name:state.guardianName,
            },
            "doctor": {
              "doctor_name": "",
              "doctor_id": "",
              "department": "",
              "account_id": accountInfo?.account_id
            },
            "patient_hid": state.patientUHID,
            "doctor_name": "",
            "department": "",
            "referred_doctor_name": "",
            "referred_doctor_id": "",
            "pay_later": false,
            "is_svaas_appt": true,
            doctor_id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
            "source": 'non-svaas',
            ip_address: userIpAddress,
            browser_info: {
              browser: details.browser,
              OS: details.os,
              device: details.device,
              cpu: details.cpu,
              engine: details.engine,
            },
          };
          
          if (state?.age?.no_of_years < 18) {
            requestBody.patient.guardian_phone = state.guardianContactNo;
            requestBody.patient.guardian_name = state.guardianName;
          }
          
          let results = await APIClient.post(API_ROUTE_CREATE_APPOINTMENT(), requestBody);
          return new Promise((res, rej) => {
            res(results?.data);
          });
          
    }else{
        let results = await APIClient.post(API_ROUTE_RESCHEDULE_APPOINTMENT(rescheduleData.id),
        {
            "remarks": "",
            "rescheduled_appointment_date": moment(state.selectedDate)?.format('YYYY-MM-DD'),
            "rescheduled_appointment_slot": state.selectedSlot,
            "appointment_type": "offline",
            "source": 'non-svaas',
            ip_address: userIpAddress,
            is_svaas_appt: true,
            browser_info: {
            browser: details.browser,
            OS: details.os,
            device: details.device,
            cpu: details.cpu,
            engine: details.engine,
            },
        })
        return new Promise((res,rej)=>res(results?.data));
    }
  };

  const fetchCountryOptions = async () => {
    let results = await APIClient.get(
      API_ROUTE_FETCH_COUNTRIES(localStorage.getItem("s3AccountId"), "country")
    );

    if (results?.data?.length > 0) {
      setState({ countryOptions: results?.data });
    } else {
      setState({ countryOptions: [] });
    }
  };
  const fetchStateOptions = async () => {
    let results = await APIClient.get(
      API_ROUTE_FETCH_STATE(
        localStorage.getItem("s3AccountId"),
        "state",
        state.country
      )
    );

    if (results?.data?.length > 0) {
      setState({ stateOptions: results?.data });
    } else {
      setState({ stateOptions: [] });
    }
  };
  const fetchCityOptions = async () => {
    let results = await APIClient.get(
      API_ROUTE_FETCH_CITY(
        localStorage.getItem("s3AccountId"),
        "city",
        state.country,
        state.state
      )
    );

    if (results?.data?.length > 0) {
      setState({ cityOptions: results?.data });
    } else {
      setState({ cityOptions: [] });
    }
  };

  const toggleShowAdditionalfields = () => {
    setShowAdditionalFields((prev) => !prev);
  };

  const fetchTimeSlots = async () => {

    setSlotsLoading(true);
    await APIClient.get(
      API_ROUTE_APPOINTMENT_TIMESLOTS(
        selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
        moment(state.selectedDate).format('YYYY-MM-DD'),
        accountInfo?.account_id
      )
    ).then(results=>{
      setSlotsLoading(false);
      setSlots(results?.data?.data);
    }).catch(err=>{
      setSlotsLoading(false);
      setErrorMsg(err || 'Unable to get slots')
      setShowError(true);
    })
    
  };

  const handlePatientSearch = async (searchterm, searchby) => {
    let results = await APIClient.get(
      API_ROUTE_SEARCH_PATIENT(searchterm, searchby)
    );
    return results?.data?.rows;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    fetchTimeSlots();
  };

  const handleBack = () => {
    if (rescheduleData && rescheduleData?.address) {
      if (Object.keys(rescheduleData?.address).length !== 0) {
        setShowAdditionalFields(true);
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGoBack=()=>{
    navigate(ROUTE_APPOINTMENTS)
  }

  // const handleReset = () => {
  //     setActiveStep(0);
  // };

  const handleNavigateToSlotPicker = () => {
    if (state?.fullName === "" || state?.fullName?.trim()?.length===0) {
      setErrorMsg("Enter Patient Name");
      setErrorField("patient name");
      // setShowError(true)
      return;
    }
    if (state?.phoneNumber === "") {
      setErrorMsg("Enter Patient Mobile Number");
      setErrorField("phone number");
      // setShowError(true)
      return;
    }
    if (state?.phoneNumber != "" && state?.phoneNumber?.length !== 10) {
      setErrorMsg("Enter Valid Phone Number");
      setErrorField("phone number");
      // setShowError(true)
      return;
    }
    if (state?.gender === "") {
      setErrorMsg("Choose Patient Gender");
      setErrorField("gender");
      // setShowError(true)
      return;
    }

    if (state?.dob === null && state?.age?.no_of_years === undefined) {
      setErrorMsg("Enter Patient Date of Birth or Age1");
      setErrorField("dob");
      // setShowError(true)
      return;
    }
    if (state.dob) {
        const calculatedAge =
        new Date().getFullYear() - new Date(state?.dob).getFullYear();

        if (calculatedAge > 120) {
            setErrorMsg("Patient age couldn't be more than 120");
            //  setShowError(true)
            setErrorField("dob");
            return;
        }
        if (calculatedAge < 0) {
            setErrorMsg("Invalid date of birth");
            //  setShowError(true)
            setErrorField("dob");
            return;
        }
    }
    if (state?.dob === "" && state?.age?.no_of_years === "") {
      setErrorMsg("Enter Patient Date of Birth or Age2");
      setErrorField("dob");
      // setShowError(true)
      return;
    }
    
    if (state?.dob) {
      const calculatedAge =
        new Date().getFullYear() - new Date(state?.dob).getFullYear();

      if (calculatedAge > 120) {
        setErrorMsg("Patient age couldn't be more than 120");
        //  setShowError(true)
        setErrorField("age");
        return;
      }
      if (calculatedAge < 0) {
        setErrorMsg("Patient age couldn't be more in future");
        // setShowError(true)
        setErrorField("age");
        setState({ dob: null });
        return;
      }
    }

    if (
      state?.age?.no_of_years < 18 &&
      state?.guardianName === "" &&
      state?.age?.no_of_years !== ""
    ) {
      setErrorMsg("Guardian Name Required");
      setErrorField("guardian name");
      // setShowError(true)
      return;
    }
    if (
      state?.age?.no_of_years < 18 &&
      state?.guardianContactNo === "" &&
      state?.age?.no_of_years !== ""
    ) {
      setErrorMsg("Guardian Contact Number Required");
      setErrorField("guardian contact");
      //   setShowError(true)
      return;
    }

    if (
      state?.guardianContactNo != "" &&
      state?.guardianContactNo?.length !== 10
    ) {
      setErrorMsg("Enter Valid Guardian Contact Number");
      setErrorField("guardian contact");
      // setShowError(true)
      return;
    }
    if (state?.email && state?.email != "" && !isValidEmail(state?.email)) {
      setErrorMsg("Enter Valid Email Address");
      setErrorField("email");
      // setShowError(true)
      return;
    }

    if (
      state?.pincode &&
      state?.pincode != "" &&
      state?.pincode?.length !== 6
    ) {
      setErrorMsg("Enter Valid Pincode");
      setErrorField("pincode");
      // setShowError(true)
      return;
    }

    // Define the initial state
    const initState = state;

    // Define the selectedOption data
    const selectedOptionData = {
      ...selectedOption?.value,
      address: selectedOption?.value?.address?.line1,
      city: selectedOption?.value?.address?.city, // Corrected key mapping for 'city'
      pincode: selectedOption?.value?.address?.zipcode,
      country: selectedOption?.value?.address?.country,
      state: selectedOption?.value?.address.state,
    };

    const keyMapping = {
      // Specify key mappings here
      referringDoctor: "referred_doctor_name",
    };
    // Initialize an object to store the changes
    const changes = {};

    // Deep comparison function to handle nested objects
  function deepEqual(a, b) {
      if (a === b) return true;

      if(a === null && b === '') return true;
      if(b==='' && a===null) return true;

      if (typeof a !== "object" || typeof b !== "object") return false;

      const keysA = Object.keys(a);
      const keysB = Object.keys(b);

      if (keysA.length !== keysB.length) return false;

      for (const key of keysA) {
        if (!keysB.includes(key)) return false;

        if (!deepEqual(a[key], b[key])) return false;
      }

      return true;
    }

    // Compare values based on the keys in your state object
    for (const stateKey in initState) {
      if (Object.hasOwnProperty.call(initState, stateKey)) {
        // Check if the key exists in the selectedOption data or if there is a mapping
        const selectedOptionKey = keyMapping[stateKey] || stateKey;
        if (selectedOptionData[selectedOptionKey] !== undefined) {
          // Compare the values for the same key using deepEqual
          if (
            !deepEqual(
              initState[stateKey],
              selectedOptionData[selectedOptionKey]
            )
          ) {
            // The key has changed
            changes[stateKey] = selectedOptionData[selectedOptionKey];
          }
        }
      }
    }

    const filteredData = Object.fromEntries(
      Object.entries(changes).filter(
        ([key, value]) => key !== "dob" && value !== undefined && value !== ""
      )
    );
    if (Object.keys(filteredData).length > 0) {
      setOverModal(true);
    } else {
      handleNext();
    }
  };

  const handleBookAppointment = async () => {
    if (state?.selectedSlot === "") {
      setErrorMsg("Choose Preferred Slot");
      setShowError(true);
      return;
    }

    await submitNewAppointment()
      .then((res) => {
        if (res) {
          setModalData({
            isSuccess: true,
            headerText: "Appointment Booked",
            subText: (
              <Typography>
                In Clinic Appointment booked successfully on{" "}
                <Typography
                  style={{ wordWrap: "break-word" }}
                  fontWeight={"bold"}
                >
                  “
                  {moment(
                    res?.appointment_date || res?.data?.appointment_date
                  ).format(`DD MMM'YY`)}{" "}
                  at {state?.selectedSlot || res?.appointment_slot}”
                </Typography>
              </Typography>
            ),
            actionButtonText: "",
            actionButtonOnClick: () => {
              return;
            },
          });
          setOpenModal(true);
          setLoading(false);
        } else {
          setModalData({
            isSuccess: false,
            headerText: "Appointment Booking Failed",
            subText: (
              <Typography>
                We could not book your In Person Appointment on{" "}
                <Typography
                  style={{ wordWrap: "break-word" }}
                  display={"inline"}
                  fontWeight={"bold"}
                >
                  “{moment(state?.selectedDate).format(`DD MMM'YY`)} at{" "}
                  {state?.selectedSlot}”
                </Typography>
                . Please try again
              </Typography>
            ),
            actionButtonText: "Try Again",
            actionButtonOnClick: () => {
              setOpenModal(false);
              handleBookAppointment();
            },
          });
          setOpenModal(true);
          setLoading(false);
        }

        // fetchTimeSlots()
        // handleNewAppointmentClick();
      })
      .catch((err) => {
        setModalData({
          isSuccess: false,
          headerText: "Appointment Booking Failed",
          subText: typeof err == 'string' ? (
            err
          ) : (
            <Typography>
              We could not book your In Person Appointment on{" "}
              <Typography
                style={{ wordWrap: "break-word" }}
                display={"inline"}
                fontWeight={"bold"}
              >
                “{moment(state?.selectedDate).format(`DD MMM'YY`)} at{" "}
                {state?.selectedSlot}”
              </Typography>
              . Please try again
            </Typography>
          ),
          actionButtonText: "Try Again",
          actionButtonOnClick: () => {
            setOpenModal(false);
            handleBookAppointment();
          },
        });
        setOpenModal(true);
        setLoading(false);
      }
      );
  };

  const handleNextWeekClick = () => {
    if (isMobileScreen) {
      setDaysMobile(
        daysMobile.map((day) => {
          return new Date(day.getTime() + 3 * 24 * 60 * 60 * 1000);
        })
      );
      return;
    }
    setDays(
      days.map((day) => {
        return new Date(day.getTime() + 7 * 24 * 60 * 60 * 1000);
      })
    );
  };

  const handlePreviousWeekClick = () => {
    if (isMobileScreen) {
      if (daysMobile[0] <= new Date()) {
        return;
      }
      setDaysMobile(
        daysMobile.map((day) => {
          return new Date(day.getTime() - 3 * 24 * 60 * 60 * 1000);
        })
      );
      return;
    }
    if (days[0] <= new Date()) {
      return;
    }
    setDays(
      days.map((day) => {
        return new Date(day.getTime() - 7 * 24 * 60 * 60 * 1000);
      })
    );
  };

  const handleClickCalenderCard = (e, day) => {
    if(slotsLoading){
      return;
    }
    setState({ selectedDate: day, selectedSlot: "" });
  };

  const shouldShowGuardianInputs = ()=>{
    if(state.age.no_of_years!=="" && state.age.no_of_years<18){
      setState({shouldShowGuardianInputs:true})
      return true;
    }else if(state.dob!=="" && dayjs().diff(state.dob,'year')<18 ){
      setState({shouldShowGuardianInputs:true})
      return true;
    }
    setState({shouldShowGuardianInputs:false})
    return false;
  }

  return (
    <>
      {isMobileScreen ? (
        <Box>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{ marginTop: "40px" }}
            ContentProps={{
              sx: {
                background: "red",
                marginTop: "40px",
              },
            }}
            open={showError}
            onClose={() => setShowError(false)}
            autoHideDuration={6000}
            message={errorMsg}
          />

          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            mt={1}
            paddingX={1}
          >
            <Stepper
              className={stepperStyles.root}
              activeStep={activeStep}
              alternativeLabel
              sx={{ flex: 1 }}
            >
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {activeStep === 0 && (
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              mt={0}
              paddingX={1}
              mb={8}
            >
              <Box display={"flex"} gap={1} alignItems={"center"} mt={0}>
                <IconButton
                  onClick={
                    activeStep === 0 ? handleGoBack : handleBack
                  }
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                  Book Appointment
                </Typography>
              </Box>
              <Box>
                <Box display={"flex"} flexDirection={"column"} gap={2} my={2}>
                  <SearchInput
                    disabled={selectedOption ? true : false}
                    isTextInput={true}
                    type={"text"}
                    label={"Full Name *"}
                    value={state.fullName}
                    isSearchable={true}
                    setValue={(val) => {
                      const regex = /^[A-Za-z ]*$/;
                      if (regex.test(val)) {
                        setState({ fullName: val });
                        if (errorField === "patient name") {
                          setErrorField("");
                        }
                      }
                    }}
                    searchBy={"name"}
                    searchFunction={handlePatientSearch}
                    searchOptionMappingFunction={(elem) => {
                      return { title: elem.phone, value: elem };
                    }}
                    setSelectedOption={setSelectedOption}
                    helperText={
                      errorField === "patient name" && errorMsg
                    }
                    error={errorField === "patient name"}
                  />
                  <SearchInput
                    disabled={selectedOption ? true : false}
                    type={"text"}
                    max={10}
                    label={"Phone Number *"}
                    value={state.phoneNumber}
                    setValue={(val) => {
                      const onlyNums = val.replace(/[^0-9]/g, "");
                      if (onlyNums?.length > 10) {
                        return;
                      } else {
                        setState({ phoneNumber: onlyNums });
                        if (errorField === "phone number") {
                          setErrorField("");
                        }
                      }
                    }}
                    isSearchable={true}
                    isPhoneInput={true}
                    searchBy={"phone"}
                    // disabled={state.phoneNumber.length === 10}
                    searchFunction={handlePatientSearch}
                    searchOptionMappingFunction={(elem) => {
                      return { title: elem.phone, value: elem };
                    }}
                    setSelectedOption={setSelectedOption}
                    helperText={
                      errorField === "phone number" && errorMsg
                    }
                    error={errorField === "phone number"}
                  />
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={state.gender}
                      onChange={(e) => {
                        setState({ gender: e.target.value });
                      }}
                    >
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Others"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Box
                    display={"flex"}
                    alignItems={"start"}
                    gap={1}
                    boxSizing={"border-box"}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        label="Date of Birth"
                        maxDate={dayjs()}
                        value={state.dob ? state.dob : null}
                        onChange={(value) => {
                          if (
                            errorField === "age" ||
                            errorField === "dob"
                          ) {
                            setErrorField("");
                          }
                          if (value) {
                            // If a valid date is selected, calculate the age
                            const today = new Date();
                            const birthDate = new Date(value);

                            let years =
                              today.getFullYear() -
                              birthDate.getFullYear();
                            let months =
                              today.getMonth() - birthDate.getMonth();

                            if (months < 0) {
                              years--;
                              months += 12;
                            }


                            if (!isNaN(years) && years <= 120 && years >=0) {
                              setState({
                                dob: value,
                                age: {
                                  no_of_months: months, // Optionally calculate months
                                  no_of_years: years,
                                },
                              });
                            } else {
                              setState({
                                dob: value,
                                age: {
                                  no_of_months: "",
                                  no_of_years: "", // Default value for invalid/over-limit age
                                },
                              });
                            }
                          } else {
                            // setErrorMsg("Patient age couldn't be more than 120")
                            // setShowError(true)
                            setState({
                              dob: "",
                              age: {
                                no_of_months: "",
                                no_of_years: "",
                              },
                            });
                          }
                        }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              endAdornment: (
                                <Box
                                  width={{
                                    xs: 40,
                                    marginRight: 4,
                                  }}
                                  component="img"
                                  src={"/images/calenderIcon.svg"}
                                />
                              ),
                            },
                            required: true,
                            helperText:
                              errorField === "dob" && errorMsg,
                            error: errorField === "dob",
                          },
                        }}
                        
                      />
                    </LocalizationProvider>

                    <Typography alignSelf={"center"} variant="subtitle2" color={theme.palette.white.dark}>OR</Typography>
                    <FormControl sx={{ width: "25ch" }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-age">
                        Age <span>*</span>
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-age"
                        label={"Age"}
                        value={
                          typeof state.age === "object"
                            ? state?.age?.no_of_years
                            : state.age
                        }
                        required={true}
                        helperText={errorField === "age" && errorMsg}
                        error={errorField === "age"}
                        onChange={(e) => {
                          const onlyNums = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          if (onlyNums > 120 || onlyNums < 0) {
                            return;
                          } else {
                            setState({
                              age: { no_of_years: onlyNums || "" },
                            });
                            if (
                              errorField === "age" ||
                              errorField === "dob"
                            ) {
                              setErrorField("");
                            }
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            years
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                  <SearchInput
                    disabled={selectedOption ? true : false}
                    label={"Patient UID"}
                    isTextInput={true}
                    type={"text"}
                    value={state.patientUHID}
                    setValue={(val) => {
                      if(val?.length>17){
                        return;
                      }else{
                        setState({ patientUHID: val });
                      }
                    }}
                    isSearchable={true}
                    searchBy={"uhid"}
                    searchFunction={handlePatientSearch}
                    searchOptionMappingFunction={(elem) => {
                      return { title: elem.patient_hid, value: elem };
                    }}
                    setSelectedOption={setSelectedOption}
                  />

                  {state.shouldShowGuardianInputs && (
                      <>
                        {/* <SearchInput label={'Guardian Name'} isSearchable={false}  value={state.guardianName} setValue={(val)=>{setState({guardianName:val})}}/> */}
                        {/* <SearchInput label={'Guardian Contact No.'} isSearchable={false}  value={state.guardianContactNo} setValue={(val)=>{setState({guardianContactNo:val})}}/> */}

                        <TextField
                          label={"Guardian Name"}
                          required={state.age?.no_of_years < 18}
                          value={state.guardianName}
                          onChange={(e) => {
                            const regex = /^[A-Za-z ]*$/;
                            if (regex.test(e.target.value)) {
                              setState({
                                guardianName: e.target.value,
                              });
                              if (errorField === "guardian name") {
                                setErrorField("");
                              }
                            } else {
                              return;
                            }
                          }}
                          helperText={
                            errorField === "guardian name" && errorMsg
                          }
                          error={errorField === "guardian name"}
                        />
                        <TextField
                          label={"Guardian Contact No. "}
                          required={state.age?.no_of_years < 18}
                          value={state.guardianContactNo}
                          // onChange={(e)=>{
                          //     if(e.target.value.length > 10){
                          //         return
                          //     }
                          //     else{
                          //         setState({guardianContactNo:e.target.value})
                          //     }
                          // }}
                          onChange={(e) => {
                            const onlyNums = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            if (onlyNums?.length > 10) {
                              return;
                            } else {
                              setState({
                                guardianContactNo: onlyNums,
                              });
                              if (errorField === "guardian contact") {
                                setErrorField("");
                              }
                            }
                          }}
                          helperText={
                            errorField === "guardian contact" &&
                            errorMsg
                          }
                          error={errorField === "guardian contact"}
                        />
                      </>
                    )}
                  <SearchInput
                    label={"Email Address"}
                    isSearchable={false}
                    value={state.email}
                    setValue={(val) => {
                      setState({ email: val });
                      if (errorField === "email") {
                        setErrorField("");
                      }
                    }}
                    helperText={errorField === "email" && errorMsg}
                    error={errorField === "email"}
                  />
                  <SearchInput
                    label={"Referring Doctor"}
                    isSearchable={false}
                    value={state.referringDoctor}
                    setValue={(val) => {
                      const regex = /^[A-Za-z ]*$/;
                      if (regex.test(val)) {
                        setState({ referringDoctor: val });
                      } else {
                        return;
                      }
                    }}
                  />

                  <TextField
                    id="outlined-multiline-static"
                    label="Address"
                    multiline
                    rows={2}
                    sx={{ width: "100%" }}
                    value={state.address}
                    onChange={(e) => {
                      setState({ address: e.target.value });
                    }}
                  />
                  <Box display={"flex"} gap={1}>
                    <TextField
                      label="Pincode"
                      sx={{ width: "100%" }}
                      value={state.pincode}
                      onChange={(e) => {
                        const onlyNums = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        if (onlyNums?.length > 6) {
                          return;
                        }
                        if (onlyNums || e.target.value === "") {
                          setState({ pincode: e.target.value });
                          if (errorField === "pincode") {
                            setErrorField("");
                          }
                        }
                      }}
                      helperText={
                        errorField === "pincode" && errorMsg
                      }
                      error={errorField === "pincode"}
                    />

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Country
                      </InputLabel>
                      <Select
                        label="Country"
                        value={state.country}
                        onChange={(e) => {
                          setState({ country: e.target.value });
                        }}
                      >
                        {state.countryOptions?.map((country) => {
                          return <MenuItem value={country}>{country}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box display={"flex"} gap={1} mb={8}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        State
                      </InputLabel>
                      <Select
                        label="State"
                        value={state.state}
                        onChange={(e) => {
                          setState({ state: e.target.value });
                        }}
                        disabled={state.country === ""}
                      >
                        {state.stateOptions?.map((state) => {
                          return <MenuItem value={state}>{state}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Select
                        label="City"
                        value={state.city}
                        onChange={(e) => {
                          setState({ city: e.target.value });
                        }}
                        disabled={state.state === ""}
                      >
                        {state.cityOptions?.map((city) => {
                          return <MenuItem value={city}>{city}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {activeStep === 1 && (
            <Box
              flex={1}
              backgroundColor={theme.palette.white.main}
              borderRadius={"6px"}
              padding={2}
              mb={8}
            >
              <Box
                width={"100%"}
                height={60}
                backgroundColor={theme.palette.primary.light}
                borderRadius={"10px"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Box
                  width={50}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ cursor: "pointer" }}
                  onClick={handlePreviousWeekClick}
                >
                  <ChevronLeftIcon fontSize="medium" />
                </Box>
                <Box
                  flex={1}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  paddingY={"4px"}
                >
                  {daysMobile.map((elem) => {
                    return (
                      <CalenderCard
                        isSelected={
                          state?.selectedDate &&
                          state?.selectedDate?.toLocaleDateString() ===
                            elem?.toLocaleDateString()
                            ? true
                            : false
                        }
                        day={elem}
                        onClick={(e) => handleClickCalenderCard(e, elem)}
                      />
                    );
                  })}
                </Box>
                <Box
                  width={50}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ cursor: "pointer" }}
                  onClick={handleNextWeekClick}
                >
                  <ChevronRightIcon fontSize="medium" />
                </Box>
              </Box>

              <Box my={2} display={"flex"} flexDirection={"column"} gap={2}>
                <TextField
                  label="Clinic Name"
                  variant="outlined"
                  sx={{ flex: 1}}
                  value={state.clinicName}
                  onChange={(e) => {
                    return;
                  }}
                  focused={false}
                  InputProps={{sx: {caretColor: "white",}}}
                />
                <TextField label="Clinic Address" focused={false} InputProps={{sx: {caretColor: "white",}}} variant="outlined" multiline rows={1} sx={{flex:1}} value={state.clinicAddress} onChange={(e)=>{return;}}/>
              </Box>

              <Box overflow={"auto"}>
                <Box
                  display={"flex"}
                  minHeight={"50px"}
                  flexDirection={"column"}
                  gap={2}
                >
                  <Typography
                    fontWeight={"600"}
                    color={theme.palette.white.dark}
                  >
                    MORNING
                  </Typography>

                  {slotsLoading ? (
                    <p>
                      <Skeleton animation="wave"></Skeleton>
                      <Skeleton animation="wave"></Skeleton>
                      <Skeleton animation="wave"></Skeleton>
                    </p>
                  ) : (
                    <Box display={"grid"} gap={1} gridTemplateColumns={'auto auto auto'}>
                      {slots &&
                        slots["morning"]?.map((slot) => {
                          return (
                            <SlotComponent
                              slot={slot}
                              isAvailable={true}
                              isSelected={
                                state.selectedSlot === slot ? true : false
                              }
                              onClick={() => {
                                setState({
                                  selectedSlot: slot,
                                  slotType: "morning",
                                });
                              }}
                            />
                          );
                        })}
                    </Box>
                  )}
                </Box>
                <Box
                  display={"flex"}
                  minHeight={"50px"}
                  flexDirection={"column"}
                  gap={2}
                  my={4}
                >
                  <Typography
                    fontWeight={"600"}
                    color={theme.palette.white.dark}
                  >
                    AFTERNOON
                  </Typography>

                  {slotsLoading ? (
                    <p>
                      <Skeleton animation="wave"></Skeleton>
                      <Skeleton animation="wave"></Skeleton>
                      <Skeleton animation="wave"></Skeleton>
                    </p>
                  ) : (
                    <Box display={"grid"} gap={1} gridTemplateColumns={'auto auto auto'}>
                      {slots &&
                        slots["afternoon"]?.map((slot) => {
                          return (
                            <SlotComponent
                              slot={slot}
                              isAvailable={true}
                              isSelected={
                                state.selectedSlot === slot ? true : false
                              }
                              onClick={() => {
                                setState({
                                  selectedSlot: slot,
                                  slotType: "afternoon",
                                });
                              }}
                            />
                          );
                        })}
                    </Box>
                  )}
                </Box>
                <Box
                  display={"flex"}
                  minHeight={"50px"}
                  flexDirection={"column"}
                  gap={2}
                >
                  <Typography
                    fontWeight={"600"}
                    color={theme.palette.white.dark}
                  >
                    EVENING
                  </Typography>
                  {slotsLoading ? (
                    <p>
                      <Skeleton animation="wave"></Skeleton>
                      <Skeleton animation="wave"></Skeleton>
                      <Skeleton animation="wave"></Skeleton>
                    </p>
                  ) : (
                    <Box display={"grid"} gap={1} gridTemplateColumns={'auto auto auto'}>
                      {slots &&
                        slots["evening"]?.map((slot) => {
                          return (
                            <SlotComponent
                              slot={slot}
                              isAvailable={true}
                              isSelected={
                                state.selectedSlot === slot ? true : false
                              }
                              onClick={() => {
                                setState({
                                  selectedSlot: slot,
                                  slotType: "evening",
                                });
                              }}
                            />
                          );
                        })}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}

          <Box
            position={"fixed"}
            width={"100vw"}
            boxSizing={"border-box"}
            bottom={0}
            height={60}
            backgroundColor={theme.palette.white.mobileLight}
            zIndex={100}
            paddingX={2}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={2}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setActiveStep(0);
                handleGoBack()
              }}
              sx={{ flex: 1, borderRadius: "10px" }}
            >
              Cancel
            </Button>
            {activeStep === 0 && (
              <Button
                variant="contained"
                onClick={handleNavigateToSlotPicker}
                sx={{ flex: 1, borderRadius: "10px" }}
                disabled={state.fullName=='' || state.phoneNumber=="" || (state.age.no_of_years=="" && state.dob=="") || (state.shouldShowGuardianInputs && (state.guardianName==="" || state.guardianContactNo===""))}
              >
                Continue
              </Button>
            )}
            {activeStep === 1 && (
              <LoadingButton
                variant="contained"
                loading={loading}
                sx={{
                  border: `1px solid  ${theme.palette.primary.main}`,
                  flex: 2,
                  borderRadius: "10px",
                }}
                onClick={handleBookAppointment}
                disabled={state.selectedSlot===''}
              >
                Book Appointment
              </LoadingButton>
            )}
          </Box>

          <ModalComponent
            open={openModal}
            setOpen={setOpenModal}
            isSuccess={modalData.isSuccess}
            headerText={modalData.headerText}
            subText={modalData.subText}
            actionButtonText={modalData.actionButtonText}
            actionButtonOnClick={modalData.actionButtonOnClick}
          />
        </Box>
      ) : (
        <Box height={"100%"} width={"100%"} zIndex={100} position={"absolute"}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{ marginTop: "40px" }}
            ContentProps={{
              sx: {
                background: "red",
                marginTop: "40px",
              },
            }}
            open={showError}
            onClose={() => setShowError(false)}
            autoHideDuration={6000}
            message={errorMsg}
          />
          <GradientBox>
            <Grid container height="100%" spacing={2}>
              <Grid
                height="100%"
                item
                xs={3.3}
                sx={{
                  boxShadow: "1px 1px 2px 2px rgba(0,0,0,0.2)",
                  backgroundColor: "#ffffff",
                }}
              >
                <Box padding={2}>
                  <Typography>Add All Appointment Info</Typography>
                </Box>

                <Box display={"flex"} justifyContent={"center"}>
                  <Stepper className={stepperStyles.root} activeStep={activeStep} orientation="vertical">
                    {steps.map((step,index) => (
                      <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                        {index!==steps.length-1 && <StepContent sx={{height:'40px'}}>
                        </StepContent>}
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>

              <Grid
                height="100%"
                item
                xs={8.7}
                backgroundColor={theme.palette.primary.background}
              >
                <Box
                  spacing={2}
                  height={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                >
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <IconButton
                      onClick={
                        activeStep === 0
                          ? handleGoBack
                          : handleBack
                      }
                    >
                      <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography fontWeight={"bold"}>
                      Book Appointment
                    </Typography>
                  </Box>

                  {activeStep === 0 && (
                    <Box
                      width={"92%"}
                      flex={1}
                      backgroundColor={theme.palette.white.main}
                      borderRadius={"6px"}
                      padding={2}
                    >
                      <Typography mb={2}>Patient Details</Typography>
                      <Grid
                        container
                        sx={{ overflowY: "auto" }}
                        height={`calc(100vh - 300px)`}
                        spacing={2}
                        mt={1}
                      >
                        <Grid item xs={6}>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                            mb={1}
                            height={390}
                          >
                            <SearchInput
                              disabled={selectedOption ? true : false}
                              isTextInput={true}
                              type={"text"}
                              label={"Full Name *"}
                              value={state.fullName}
                              isSearchable={true}
                              setValue={(val) => {
                                const regex = /^[A-Za-z ]*$/;
                                if (regex.test(val)) {
                                  setState({ fullName: val });
                                  if (errorField === "patient name") {
                                    setErrorField("");
                                  }
                                }
                              }}
                              searchBy={"name"}
                              searchFunction={handlePatientSearch}
                              searchOptionMappingFunction={(elem) => {
                                return { title: elem.phone, value: elem };
                              }}
                              setSelectedOption={setSelectedOption}
                              helperText={
                                errorField === "patient name" && errorMsg
                              }
                              error={errorField === "patient name"}
                            />
                            <SearchInput
                              disabled={selectedOption ? true : false}
                              type={"text"}
                              max={10}
                              label={"Phone Number *"}
                              value={state.phoneNumber}
                              setValue={(val) => {
                                const onlyNums = val.replace(/[^0-9]/g, "");
                                if (onlyNums?.length > 10) {
                                  return;
                                } else {
                                  setState({ phoneNumber: onlyNums });
                                  if (errorField === "phone number") {
                                    setErrorField("");
                                  }
                                }
                              }}
                              isSearchable={true}
                              isPhoneInput={true}
                              searchBy={"phone"}
                              // disabled={state.phoneNumber.length === 10}
                              searchFunction={handlePatientSearch}
                              searchOptionMappingFunction={(elem) => {
                                return { title: elem.phone, value: elem };
                              }}
                              setSelectedOption={setSelectedOption}
                              helperText={
                                errorField === "phone number" && errorMsg
                              }
                              error={errorField === "phone number"}
                            />
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={state.gender}
                                onChange={(e) => {
                                  setState({ gender: e.target.value });
                                }}
                              >
                                <FormControlLabel
                                  value="male"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="female"
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="other"
                                  control={<Radio />}
                                  label="Others"
                                />
                              </RadioGroup>
                            </FormControl>
                            <Box
                              display={"flex"}
                              alignItems={"start"}
                              gap={1}
                              boxSizing={"border-box"}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  format="DD/MM/YYYY"
                                  label="Date of Birth"
                                  maxDate={dayjs()}
                                  value={state.dob ? state.dob : null}
                                  onChange={(value) => {
                                    if (
                                      errorField === "age" ||
                                      errorField === "dob"
                                    ) {
                                      setErrorField("");
                                    }
                                    if (value) {
                                      // If a valid date is selected, calculate the age
                                      const today = new Date();
                                      const birthDate = new Date(value);

                                      let years =
                                        today.getFullYear() -
                                        birthDate.getFullYear();
                                      let months =
                                        today.getMonth() - birthDate.getMonth();

                                      if (months < 0) {
                                        years--;
                                        months += 12;
                                      }


                                      if (!isNaN(years) && years <= 120 && years >=0) {
                                        setState({
                                          dob: value,
                                          age: {
                                            no_of_months: months, // Optionally calculate months
                                            no_of_years: years,
                                          },
                                        });
                                      } else {
                                        setState({
                                          dob: value,
                                          age: {
                                            no_of_months: "",
                                            no_of_years: "", // Default value for invalid/over-limit age
                                          },
                                        });
                                      }
                                    } else {
                                      // setErrorMsg("Patient age couldn't be more than 120")
                                      // setShowError(true)
                                      setState({
                                        dob: "",
                                        age: {
                                          no_of_months: "",
                                          no_of_years: "",
                                        },
                                      });
                                    }
                                  }}
                                  slotProps={{
                                    textField: {
                                      required: true,
                                      helperText:
                                        errorField === "dob" && errorMsg,
                                      error: errorField === "dob",
                                    },
                                  }}
                                />
                              </LocalizationProvider>

                              <Typography alignSelf={"center"}>OR</Typography>
                              <FormControl
                                sx={{ width: "25ch", flex: 1 }}
                                variant="outlined"
                              >
                                <InputLabel htmlFor="outlined-adornment-age">
                                  Age <span>*</span>
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-age"
                                  label={"Age"}
                                  value={
                                    typeof state.age === "object"
                                      ? state?.age?.no_of_years
                                      : state.age
                                  }
                                  required={true}
                                  helperText={errorField === "age" && errorMsg}
                                  error={errorField === "age"}
                                  onChange={(e) => {
                                    const onlyNums = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    if (onlyNums > 120 || onlyNums < 0) {
                                      return;
                                    } else {
                                      setState({
                                        age: { no_of_years: onlyNums || "" },
                                      });
                                      if (
                                        errorField === "age" ||
                                        errorField === "dob"
                                      ) {
                                        setErrorField("");
                                      }
                                    }
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      years
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                            </Box>
                            <SearchInput
                              disabled={selectedOption ? true : false}
                              label={"Patient UID"}
                              isTextInput={true}
                              type={"text"}
                              value={state.patientUHID}
                              setValue={(val) => {
                                if(val?.length>17){
                                  return;
                                }else{
                                  setState({ patientUHID: val });
                                }
                              }}
                              isSearchable={true}
                              searchBy={"uhid"}
                              searchFunction={handlePatientSearch}
                              searchOptionMappingFunction={(elem) => {
                                return { title: elem.patient_hid, value: elem };
                              }}
                              setSelectedOption={setSelectedOption}
                            />

                            {state.shouldShowGuardianInputs && (
                                <>
                                  {/* <SearchInput label={'Guardian Name'} isSearchable={false}  value={state.guardianName} setValue={(val)=>{setState({guardianName:val})}}/> */}
                                  {/* <SearchInput label={'Guardian Contact No.'} isSearchable={false}  value={state.guardianContactNo} setValue={(val)=>{setState({guardianContactNo:val})}}/> */}

                                  <TextField
                                    label={"Guardian Name"}
                                    required={state.age?.no_of_years < 18}
                                    value={state.guardianName}
                                    onChange={(e) => {
                                      const regex = /^[A-Za-z ]*$/;
                                      if (regex.test(e.target.value)) {
                                        setState({
                                          guardianName: e.target.value,
                                        });
                                        if (errorField === "guardian name") {
                                          setErrorField("");
                                        }
                                      } else {
                                        return;
                                      }
                                    }}
                                    helperText={
                                      errorField === "guardian name" && errorMsg
                                    }
                                    error={errorField === "guardian name"}
                                  />
                                  <TextField
                                    label={"Guardian Contact No. "}
                                    required={state.age?.no_of_years < 18}
                                    value={state.guardianContactNo}
                                    // onChange={(e)=>{
                                    //     if(e.target.value.length > 10){
                                    //         return
                                    //     }
                                    //     else{
                                    //         setState({guardianContactNo:e.target.value})
                                    //     }
                                    // }}
                                    onChange={(e) => {
                                      const onlyNums = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      if (onlyNums?.length > 10) {
                                        return;
                                      } else {
                                        setState({
                                          guardianContactNo: onlyNums,
                                        });
                                        if (errorField === "guardian contact") {
                                          setErrorField("");
                                        }
                                      }
                                    }}
                                    helperText={
                                      errorField === "guardian contact" &&
                                      errorMsg
                                    }
                                    error={errorField === "guardian contact"}
                                  />
                                </>
                              )}
                            <SearchInput
                              label={"Email Address"}
                              isSearchable={false}
                              value={state.email}
                              setValue={(val) => {
                                setState({ email: val });
                                if (errorField === "email") {
                                  setErrorField("");
                                }
                              }}
                              helperText={errorField === "email" && errorMsg}
                              error={errorField === "email"}
                            />
                            <SearchInput
                              label={"Referring Doctor"}
                              isSearchable={false}
                              value={state.referringDoctor}
                              setValue={(val) => {
                                const regex = /^[A-Za-z ]*$/;
                                if (regex.test(val)) {
                                  setState({ referringDoctor: val });
                                } else {
                                  return;
                                }
                              }}
                            />
                            <Typography
                              my={2}
                              variant="caption"
                              sx={{ cursor: "pointer" }}
                              color={theme.palette.primary.main}
                              textTransform={"uppercase"}
                              onClick={toggleShowAdditionalfields}
                            >
                              {`${!showAdditionalFields ? "show" : "hide"}`}{" "}
                              additional fields
                            </Typography>

                            {/* <Typography color={theme.palette.primary.main}>show additional fields</Typography> */}
                          </Box>
                        </Grid>

                        {showAdditionalFields && (
                          <Grid item xs={6}>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              gap={2}
                            >
                              <TextField
                                id="outlined-multiline-static"
                                label="Address"
                                multiline
                                rows={2}
                                sx={{ width: "100%" }}
                                value={state.address}
                                onChange={(e) => {
                                  setState({ address: e.target.value });
                                }}
                              />
                              <Box
                                display={"flex"}
                                gap={2}
                                alignItems={"start"}
                              >
                                <TextField
                                  label="Pincode"
                                  sx={{ width: "100%" }}
                                  value={state.pincode}
                                  onChange={(e) => {
                                    const onlyNums = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    if (onlyNums?.length > 6) {
                                      return;
                                    }
                                    if (onlyNums || e.target.value === "") {
                                      setState({ pincode: e.target.value });
                                      if (errorField === "pincode") {
                                        setErrorField("");
                                      }
                                    }
                                  }}
                                  helperText={
                                    errorField === "pincode" && errorMsg
                                  }
                                  error={errorField === "pincode"}
                                />

                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Country
                                  </InputLabel>
                                  <Select
                                    label="Country"
                                    value={state.country}
                                    onChange={(e) => {
                                      setState({ country: e.target.value });
                                    }}
                                  >
                                    {state.countryOptions?.map((country) => {
                                      return (
                                        <MenuItem value={country}>
                                          {country}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Box>
                              <Box display={"flex"} gap={2}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    State
                                  </InputLabel>
                                  <Select
                                    label="State"
                                    value={state.state}
                                    onChange={(e) => {
                                      setState({ state: e.target.value });
                                    }}
                                    disabled={state.country === ""}
                                  >
                                    {state.stateOptions?.map((state) => {
                                      return (
                                        <MenuItem value={state}>
                                          {state}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    City
                                  </InputLabel>
                                  <Select
                                    label="City"
                                    value={state.city}
                                    onChange={(e) => {
                                      setState({ city: e.target.value });
                                    }}
                                    disabled={state.state === ""}
                                  >
                                    {state.cityOptions?.map((city) => {
                                      return (
                                        <MenuItem value={city}>{city}</MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                  {activeStep === 1 && (
                    <Box
                      width={"92%"}
                      flex={1}
                      backgroundColor={theme.palette.white.main}
                      borderRadius={"6px"}
                      padding={2}
                    >
                      <Box
                        width={"100%"}
                        height={60}
                        backgroundColor={theme.palette.primary.light}
                        borderRadius={"10px"}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          width={50}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{ cursor: "pointer" }}
                          onClick={handlePreviousWeekClick}
                        >
                          <ChevronLeftIcon fontSize="medium" />
                        </Box>
                        <Box
                          flex={1}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          paddingY={"4px"}
                        >
                          {days.map((elem) => {
                            return (
                              <CalenderCard
                                isSelected={
                                  state?.selectedDate &&
                                  state?.selectedDate?.toLocaleDateString() ===
                                    elem?.toLocaleDateString()
                                    ? true
                                    : false
                                }
                                day={elem}
                                onClick={(e) =>
                                  handleClickCalenderCard(e, elem)
                                }
                              />
                            );
                          })}
                        </Box>
                        <Box
                          width={50}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{ cursor: "pointer" }}
                          onClick={handleNextWeekClick}
                        >
                          <ChevronRightIcon fontSize="medium" />
                        </Box>
                      </Box>

                      <Box my={2} display={"flex"} flexDirection={"column"} gap={2}>
                        <TextField
                          label="Clinic Name"
                          variant="outlined"
                          sx={{ flex: 1 }}
                          value={state.clinicName}
                          onChange={(e) => {
                            return;
                          }}
                          focused={false}
                          InputProps={{sx: {caretColor: "white",}}}
                        />
                        <TextField label="Clinic Address" onChange={(e) => {return;}} focused={false} InputProps={{sx: {caretColor: "white",}}} variant="outlined" multiline rows={1} sx={{flex:1}} value={state.clinicAddress}/>
                      </Box>

                      <Box height={"calc(100vh - 480px)"} overflow={"auto"}>
                        <Box
                          display={"flex"}
                          minHeight={"50px"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Typography
                            fontWeight={"600"}
                            color={theme.palette.white.dark}
                          >
                            MORNING
                          </Typography>

                          {slotsLoading ? (
                            <p>
                              <Skeleton animation="wave"></Skeleton>
                              <Skeleton animation="wave"></Skeleton>
                              <Skeleton animation="wave"></Skeleton>
                            </p>
                          ) : (
                            <Box display={"flex"} gap={1} flexWrap={"wrap"}>
                              {slots &&
                                slots["morning"]?.map((slot) => {
                                  return (
                                    <SlotComponent
                                      slot={slot}
                                      isAvailable={true}
                                      isSelected={
                                        state.selectedSlot === slot
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        setState({
                                          selectedSlot: slot,
                                          slotType: "morning",
                                        });
                                      }}
                                    />
                                  );
                                })}
                            </Box>
                          )}
                        </Box>
                        <Box
                          display={"flex"}
                          minHeight={"50px"}
                          flexDirection={"column"}
                          gap={2}
                          my={4}
                        >
                          <Typography
                            fontWeight={"600"}
                            color={theme.palette.white.dark}
                          >
                            AFTERNOON
                          </Typography>

                          {slotsLoading ? (
                            <p>
                              <Skeleton animation="wave"></Skeleton>
                              <Skeleton animation="wave"></Skeleton>
                              <Skeleton animation="wave"></Skeleton>
                            </p>
                          ) : (
                            <Box display={"flex"} gap={1} flexWrap={"wrap"}>
                              {slots &&
                                slots["afternoon"]?.map((slot) => {
                                  return (
                                    <SlotComponent
                                      slot={slot}
                                      isAvailable={true}
                                      isSelected={
                                        state.selectedSlot === slot
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        setState({
                                          selectedSlot: slot,
                                          slotType: "afternoon",
                                        });
                                      }}
                                    />
                                  );
                                })}
                            </Box>
                          )}
                        </Box>
                        <Box
                          display={"flex"}
                          minHeight={"50px"}
                          flexDirection={"column"}
                          gap={2}
                        >
                          <Typography
                            fontWeight={"600"}
                            color={theme.palette.white.dark}
                          >
                            EVENING
                          </Typography>
                          {slotsLoading ? (
                            <p>
                              <Skeleton animation="wave"></Skeleton>
                              <Skeleton animation="wave"></Skeleton>
                              <Skeleton animation="wave"></Skeleton>
                            </p>
                          ) : (
                            <Box display={"flex"} gap={1} flexWrap={"wrap"}>
                              {slots &&
                                slots["evening"]?.map((slot) => {
                                  return (
                                    <SlotComponent
                                      slot={slot}
                                      isAvailable={true}
                                      isSelected={
                                        state.selectedSlot === slot
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        setState({
                                          selectedSlot: slot,
                                          slotType: "evening",
                                        });
                                      }}
                                    />
                                  );
                                })}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}

                  <Box
                    width={"92%"}
                    height={60}
                    backgroundColor={theme.palette.white.medium}
                    borderRadius={"6px"}
                    marginBottom={8}
                    padding={1}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setActiveStep(0);
                        handleGoBack();
                      }}
                      className={"camelCaseBtn"}
                    >
                      Cancel
                    </Button>
                    {activeStep === 0 && (
                      <Button
                        variant="contained"
                        onClick={handleNavigateToSlotPicker}
                        disabled={state.fullName=='' || state.phoneNumber=="" || (state.age.no_of_years!=="" ? false : state.dob=="") || (state.shouldShowGuardianInputs && (state.guardianName==="" || state.guardianContactNo===""))}
                      >
                        Continue
                      </Button>
                    )}
                    {activeStep === 1 && (
                      <LoadingButton
                        variant="contained"
                        loading={loading}
                        sx={{
                          border: `1px solid  ${theme.palette.primary.main}`,
                        }}
                        onClick={handleBookAppointment}
                        disabled={state.selectedSlot===''}
                      >
                        Book Appointment
                      </LoadingButton>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </GradientBox>
          <ModalComponent
            open={openModal}
            setOpen={setOpenModal}
            isSuccess={modalData.isSuccess}
            headerText={modalData.headerText}
            subText={modalData.subText}
            actionButtonText={modalData.actionButtonText}
            actionButtonOnClick={modalData.actionButtonOnClick}
          />
          
        </Box>
      )}
      <OverwriteModalComponent
        open={openOverModal}
        setOpen={setOverModal}
        isSuccess={true}
        headerText={"Do you want to overwrite patient’s information?"}
        subText={""}
        actionButtonOnClick={() => {
          setOverModal(false);
          handleNext();
        }}
      />
    </>
  );
};

const SlotComponent = ({ slot, isAvailable, isSelected, onClick }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  if (isAvailable) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        // flex={isMobileScreen?1:0}
        minWidth={"100px"}
        // maxWidth={'31%'}
        height={"50px"}
        borderRadius={"10px"}
        border={`1px solid ${theme.palette.primary.medium}`}
        backgroundColor={isSelected ? theme.palette.primary.light : ""}
        sx={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <Typography color={isSelected ? "black" : theme.palette.primary.main}>
          {slot}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flex={1}
        minWidth={"100px"}
        maxWidth={'31%'}
        height={"50px"}
        borderRadius={"10px"}
        border={`1px solid ${theme.palette.white.dark}`}
        sx={{ cursor: "not-allowed" }}
        onClick={onClick}
      >
        <Typography color={theme.palette.white.dark}>{slot}</Typography>
      </Box>
    );
  }
};

const CalenderCard = ({ isSelected, day, onClick }) => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const months_short = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  return (
    <>
      {isMobileScreen ? (
        <Box
          height={"100%"}
          width={1 / 3}
          borderRadius={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          sx={{ cursor: "pointer", boxShadow:'0px 4px 24px 0px rgba(0,0,0,0.08)' }}
          backgroundColor={
            isSelected ? theme.palette.white.main : theme.palette.primary.light
          }

          onClick={onClick}
        >
          <Typography fontSize={"small"}>{daysOfWeek[day.getDay()]}</Typography>
          <Typography fontWeight={600}>{`${day.getDate()} ${
            months_short[day.getMonth()]
          }`}</Typography>
        </Box>
      ) : (
        <Box
          height={"100%"}
          width={1 / 7}
          borderRadius={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          sx={{ cursor: "pointer", boxShadow:isSelected?'0px 4px 24px 0px rgba(0,0,0,0.08)':""  }}
          backgroundColor={
            isSelected ? theme.palette.white.main : theme.palette.primary.light
          }
          onClick={onClick}
        >
          <Typography fontSize={"small"}>{daysOfWeek[day.getDay()]}</Typography>
          <Typography fontWeight={600}>{`${day.getDate()} ${
            months_short[day.getMonth()]
          }`}</Typography>
        </Box>
      )}
    </>
  );
};

export default NewAppointment;
