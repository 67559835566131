/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Skeleton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { SyntheticEvent, useEffect, useReducer, useState } from 'react'
import Card from '../../../utils/Card'
import { CARD_SHADOW } from '../../../utils/Constants'
import CardHeader from '../../../utils/CardHeader'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { OBG_NOTES, PER_ABDOMEN } from '../../../../../../shared/constants'
  
  const EditorModules = {
    toolbar: [
      ["bold", "italic", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  
  const initState = {
    details: '',
  }

const PerAbdomen = (props:any) => {

    const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isFocused: boolean = props.isFocused || false;
  const sectionData = props?.sectionData?.[0];

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
        ...state,
        ...action
    }
},initState)

  const { setTabData } = props;
//   const {
//     prescription: { instantRxData },
//   } = useSelector((state: any) => state.prescription);

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };


  useEffect(()=>{
    console.log("state",state)
  },[state])

  useEffect(() => {
    if (sectionData) {
      const data = sectionData?.value;
      const comment = data?.find((elem:any)=>elem?.name === 'comment')

      setState({
        details : comment? comment?.value : ''
      })

      setLoading(false);
    }
  }, [sectionData]);

  const resetTabData = ()=>{
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_NOTES) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PER_ABDOMEN) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      return {...val,value:''};
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
  }


  const renderHeaderButtons = ()=>{

    const buttons = [
        {
            name:'Delete',
            icon:'icon_delete_rx.svg',
            onClick:()=>{
                setState({initState})
                resetTabData();
                props?.setShowHistory({
                    ...props?.showHistory,
                    ["Per Abdomen"]: false,
                })
            }
        },
    ]

    

    return (
        <>
            {buttons.map((elem,index)=>{
                return (
                    <button
                        key={elem.name}
                        style={{
                            outline: "none",
                            background: "none",
                            backgroundColor:theme.palette.primary.light,
                            padding: 8,
                            // marginRight: index !== imgs.length - 1 ? 16 : 0,
                            border: 0,
                            borderRadius:'50%',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                    >
                        <Tooltip
                            title={elem.name}
                        >
                            <img
                                onClick={elem.onClick}
                                src={`/images/${elem.icon}`}
                                alt={elem.icon}
                            />
                        </Tooltip>
                    </button>
                )
            })}
            
        </>
    )
    
  }

  const validateInput = (value:any,type:string)=>{
    switch(type){
        case 'details':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
  }

  const updateHistory = (event?:SyntheticEvent, value?: string | null, forKey?:string)=>{
    
    const target =
      (event?.target as HTMLInputElement) ||
      (event?.target as HTMLElement);

    const ariaLabel = target?.ariaLabel as string;
    const inputName = target?.name as string;

    const key = forKey || inputName;

    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_NOTES) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PER_ABDOMEN) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === key) {
                        return {
                          ...val,
                          value: value,
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
    return;
  }



  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Per Abdomen"
        >
          <CardHeader
            headerImg=""
            headerTitle="Per Abdomen"
          >
            <Box display={'flex'} gap={1}>
                {renderHeaderButtons()}
            </Box>
            
          </CardHeader>

          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <ReactQuill
                id="rx-examination-findings"
                theme="snow"
                placeholder="Enter details"
                modules={EditorModules}
                value={state.details}
                onChange={(content, _delta, _source, editor) => {
                    let newComment = content;
                    if (editor.getLength() <= 1) {
                        newComment = "";
                    }
                    if (editor.getLength() > 1500) {
                        newComment = newComment.substring(0, 1500);
                    }
                    setState({
                        details:newComment
                    })
                    // setTabData((prev: any) => {
                //     return prev.map((section: any) => {
                //     if (section.view_type === MENSTRUAL_HISTORY) {
                //         return {
                //         ...section,
                //         rows: section.rows.map((row: any, index: number) => {
                //             if (index === 0) {
                //             return {
                //                 ...row,
                //                 value: row.value.map((val: any, index: number) => {
                //                 if (index === 0) {
                //                     return {
                //                     ...val,
                //                     comments: newComment ? newComment : "",
                //                     };
                //                 }
                //                 return val;
                //                 }),
                //             };
                //             }
                //             return row;
                //         }),
                //         };
                //     }
                //     return section;
                //     });
                // });
                }}
                onBlur={(e:any)=>{
                    updateHistory(e,state.details,'comment');
                }}
                style={{ color: "#000000" }}
            />
          </Box>
          

          
        </Card>
      )}
      {!loading && isMobileScreen && (
        <>
          <ReactQuill
                id="rx-examination-findings"
                theme="snow"
                placeholder="Enter details"
                modules={EditorModules}
                value={state.details}
                onChange={(content, _delta, _source, editor) => {
                    let newComment = content;
                    if (editor.getLength() <= 1) {
                        newComment = "";
                    }
                    if (editor.getLength() > 1500) {
                        newComment = newComment.substring(0, 1500);
                    }
                    setState({
                        details:newComment
                    })
                    // setTabData((prev: any) => {
                //     return prev.map((section: any) => {
                //     if (section.view_type === MENSTRUAL_HISTORY) {
                //         return {
                //         ...section,
                //         rows: section.rows.map((row: any, index: number) => {
                //             if (index === 0) {
                //             return {
                //                 ...row,
                //                 value: row.value.map((val: any, index: number) => {
                //                 if (index === 0) {
                //                     return {
                //                     ...val,
                //                     comments: newComment ? newComment : "",
                //                     };
                //                 }
                //                 return val;
                //                 }),
                //             };
                //             }
                //             return row;
                //         }),
                //         };
                //     }
                //     return section;
                //     });
                // });
                }}
                onBlur={(e:any)=>{
                    updateHistory(e,state.details,'comment');
                }}
                style={{ color: "#000000" }}
          />
        </>
      )}
    </>
  )
}

export default PerAbdomen