import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#494E9D",
      white: "#FFFFFF",
      medium: "#3755aa33",
      light: "#ECEDFD",
      lighter:'#F5F5FA',
      negative: "#EF6800",
      background: "#F4F6F9",
      loginBackground: "#f7fbff",
      loginBackground2: "#dee0ff",
      skyblue: "#2196F3",
      splashScreenGradient1:'#10145C',
      splashScreenGradient2:'#494E9D',
      slotTableBackground:'#F5F5FA',
    },
    secondary: {
      main: "#34B3A8",
      dark: "#73CBBF",
      light: "#83d2d833",
    },
    white: {
      main: "#FFFFFF",
      mobileLight:'#EEEEEE',
      medium: "#0000000d",
      outline: "#C0C0C0",
      dark: "#919191",
      lightText:'#6F6F6F',
      greyText:'#838383',
      darkerText:'#1a1a1a'
    },
    light: {
      main: "#ECEDFD",
    },
    negative: {
      main: "#F4939A",
      dark: "#F4939A",
      light: "#f4939a33",
    },
    blue: {
      main: "#21A6F1",
      light: "#ECF7FE",
      highlight:"#3F46FF",
      lightHighlight:"#494E9D",
    },
    green: {
      main: "#12B76A",
      light: "#ECFDF3",
    },
    red: {
      main: "#FB1D20",
      light: "#FFE7E7",
      medium:'#F4939A',
    },
    calenderCard: {
      completed: "#494E9D",
      inprogress: "#12B76A",
      scheduled: "#F4939A",
      cancelled: "#FB1D20",
      pendingPayment: "#9C27B0",
    },
    action:{
      main:'#000000'
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button":
            {
              "-webkit-appearance": "none",
              margin: 0,
            },
          "&[type=number]": {
            "-moz-appearance": "textfield",
          },
        },
      },
    },
    // MuiTableCell:{
    //   styleOverrides:{
    //     root: {  //This can be referred from Material UI API documentation.
    //       padding: '4px 24px',
    //       backgroundColor: "#eaeaea",
    //   },
    //   }
    // }
  },
});

const MUICustomTheme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MUICustomTheme;
