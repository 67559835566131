import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Skeleton, Snackbar, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { ADMIN_ABDM_HEALTH_FACILITY_REGISTRY, ADMIN_ACCOUNT_FOR, ADMIN_ADDRESS, ADMIN_ADD_CLINIC_IMAGES, ADMIN_ADD_LOGO, ADMIN_ADD_NEW_CLINICS, ADMIN_ADD_TO_INDIAS_DIGITAL_ECOSYSTEM, ADMIN_CANCEL, ADMIN_CLINIC_CONTACT_NUMBER, ADMIN_CLINIC_DETAILS, ADMIN_CLINIC_NAME, ADMIN_CLINIC_SPECIALIZATION, ADMIN_DIRECTIONS_TO_CLINIC, ADMIN_EDIT, ADMIN_HEALTHCARE_FACILITY_REGISTRY, ADMIN_HEALTH_FACILITY_REGISTRY, ADMIN_HOW_TO_CREATE, ADMIN_LINK_GOOGLE_BUSINESS, ADMIN_LINK_NOW, ADMIN_MAX_USER_LIMIT, ADMIN_NEW_CLINIC, ADMIN_NEW_USER, ADMIN_PERSONAL_WEBSITE_URL, ADMIN_REGISTER_NOW, ADMIN_SERVICE, ADMIN_SERVICES, ADMIN_SUBMIT, ADMIN_TIMINGS, ADMIN_UPLOAD_IMAGE, ADMIN_VIEW_ALL_CLINIC, ADMIN_WATCH_VIDEO } from '../../../../assets/translations/admin';
import { LoadingButton } from '@mui/lab';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClinicDetails, resetClinicDetails } from '../../../../redux/reducers/admin.slice';
import { getFeatureValue, useAuthorization } from '../../../../shared/Utils/useAuthorization';

const sections = [
    {
        label:'Clinic Management',
        value:'/admin/clinicmanagement'
    },
    {
        label:'User Management',
        value:'/admin/usermanagement'
    },
    {
        label:'Slot Management',
        value:'/admin/slotmanagement'
    },
]

const initState = {
    clinicName:'',
    address:'',
    pincode:'',
    country:'',
    state:'',
    city:'',
    clinicContactNumber:'',
    maxUserLimit:'',
    logo:'',
    images:[],
    personalWebsiteURL:'',
    services:[],
    timing:[
        {"Mon-Fri" : "9:00AM - 1:00PM"},
        {"Sat-Sun" : "By Appointments"}
    ],
    appointment_slots:{},
    healthcareFacilityRegistry:'XXXX XXXX XXXX XXXX',
    facebooklink:'',
    instagramlink:'',

}

const initErrorState = {
    errorField:'',
    errorMsg:'',
    showError:false
}

const ClinicDetails = () => {

  //Hook
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParam,setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  //State
  const {clinicDetails,loader} = useSelector((state)=>state.admin)
  const [pathname,setPathname] = useState('');
  const [state,setState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },initState)
  const [errorState,setErrorState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },initErrorState)

  const userFeatures = useAuthorization();

  //Effects

  useEffect(()=>{

    return ()=>{
        dispatch(resetClinicDetails());
    }
  },[])

  useEffect(()=>{
    if(location.pathname){
        setPathname(location.pathname);
    }
  },[location])

  useEffect(()=>{
    if(searchParam.get('account_id')){
        dispatch(fetchClinicDetails({account_id:searchParam.get('account_id')}))
    }
  },[searchParam])

  useEffect(()=>{
    setState({
        clinicName:clinicDetails?.account_name || "",
        address:`${clinicDetails?.address?.line || ''} ${clinicDetails?.address?.city || ''} ${clinicDetails?.address?.state || ''} ${clinicDetails?.address?.country || ''} ${clinicDetails?.address?.pincode || ''}`,
        pincode:clinicDetails.address ? clinicDetails?.address?.pincode : '',
        country:clinicDetails.address ? clinicDetails?.address?.country : '',
        state:clinicDetails.address ? clinicDetails?.address?.state : '',
        city:clinicDetails.address ? clinicDetails?.address?.city : '',
        clinicContactNumber:clinicDetails.emergency_info ? clinicDetails.emergency_info : '',
        maxUserLimit:clinicDetails?.max_user_allowed || "",
        logo:clinicDetails?.clinic_logo_url || "",
        images:clinicDetails?.clinic_images_url || [],
        personalWebsiteURL:clinicDetails?.clinic_website,
        services:clinicDetails?.services,
        timing:[
            {"Mon-Fri" : "9:00AM - 1:00PM"},
            {"Sat-Sun" : "By Appointments"}
        ],
        appointment_slots:clinicDetails?.appointment_slots ? clinicDetails?.appointment_slots : {},
        healthcareFacilityRegistry:'XXXX XXXX XXXX XXXX',
        facebooklink:'',
        instagramlink:'',
    })
  },[clinicDetails])

  //Functions
  const handleClickEdit = ()=>{
    navigate({
        pathname: "/admin/clinicmanagement/editclinic",
        search: createSearchParams({
            account_id: searchParam.get('account_id'),
        }).toString()
    })
    return;
  }
  const handleClickNewUser = ()=>{
    navigate({
        pathname: "/admin/usermanagement",
        search: createSearchParams({
            account_id: searchParam.get('account_id'),
            account_name: searchParam.get('account_name'),
        }).toString()
    });
    return;
  }
  const handleClickBack = ()=>{
    navigate('/admin/clinicmanagement/allclinic')
    return;
  }
  const handleClickDirectionsToClinic = ()=>{
    if(!state.address?.trim()){
        setErrorState({
            errorField:'directions',
            errorMsg:'Unable to redirect',
            showError:true
        })
        return;
    }
    window.open(`https://www.google.com/maps/place/${encodeURI(state.address)}/`,'_blank', 'rel=noopener noreferrer')
    return;
  }
  const handleClickFacebook = ()=>{
    return;
  }
  const handleClickInstagram = ()=>{
    return;
  }

  const formatTiming = (slots)=>{
    if(!slots) return "";

    return (
        <Typography variant='caption'>
            {slots.Morning && slots.Morning.length && (
                <Typography variant='caption' display={'block'} color={theme.palette.white.greyText}>{"Morning: "+slots.Morning[0]+'-'+slots.Morning[1]}</Typography>
            )}
            {slots.Afternoon && slots.Afternoon.length && (
                <Typography variant='caption' display={'block'} color={theme.palette.white.greyText}>{"Afternoon: "+slots.Afternoon[0]+'-'+slots.Afternoon[1]}</Typography>
            )}
            {slots.Evening && slots.Evening.length && (
                <Typography variant='caption' display={'block'} color={theme.palette.white.greyText}>{"Evening: "+slots.Evening[0]+'-'+slots.Evening[1]}</Typography>
            )}
            {slots.Night && slots.Night.length && (
                <Typography variant='caption' display={'block'} color={theme.palette.white.greyText}>{"Night: "+slots.Night[0]+'-'+slots.Night[1]}</Typography>
            )}
        </Typography>
    )
  }


  return (
    <>
      {isMobileScreen ? (
        <>
          
        </>
      ) : (
        <Box height={"100%"} width={"100%"} zIndex={100} position={"absolute"}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                ContentProps={{ sx: { background: "red", marginTop: "40px" } }}
                open={errorState.showError}
                onClose={() => setErrorState({showError:false})}
                autoHideDuration={6000}
                message={errorState.errorMsg}
            />


          <Box >
            <Grid container height={{xs:"100%",md:`calc(100vh - 42px)`}} width={{xs:"100%",md:`calc(100vw - 60px)`}} spacing={4}>
              
              {/* Left Panel */}
              <Grid
                item
                height="100%"
                xs={3.3}
                
              >
                    <Box 
                        width={'100%'} 
                        height={'100%'}
                        sx={{
                            // boxShadow: "1px 1px 4px 2px rgba(0,0,0,0.2)",
                            backgroundColor: "#ffffff",
                            borderRadius:'0px 8px 0px 0px'
                        }}
                    >
                        {
                            sections.map((elem)=>{
                                return (
                                    <Box 
                                        key={elem.label}
                                        width={'100%'} 
                                        boxSizing={'border-box'}
                                        paddingX={4} 
                                        paddingY={2} 
                                        display={'flex'} 
                                        justifyContent={'space-between'} 
                                        alignItems={'center'}
                                        onClick={()=>{
                                            // navigate(elem.value)
                                        }}
                                        sx={{
                                            cursor:'pointer'
                                        }}
                                    >
                                        <Typography 
                                            variant='subtitle2' 
                                            color={pathname.includes(elem.value) ? theme.palette.primary.main : 'black'}
                                            fontWeight={pathname.includes(elem.value) ? "bold" : 'regular'}
                                        >
                                            {elem.label}
                                        </Typography>
                                        {
                                            pathname.includes(elem.value) && (
                                                <ArrowForwardIosOutlinedIcon fontSize='small' sx={{color:theme.palette.primary.main}}/>
                                            )
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
              </Grid>


              {/* Right Panel */}
              <Grid
                item
                height="100%"
                xs
                spacing={10}
              >
                <Grid
                    container
                    height={'100%'}
                    spacing={2}
                    direction={'column'}
                >
                    <Grid
                        item
                        xs
                        height={'10%'}
                    >
                        <Box 
                            width={'100%'} 
                            height={'100%'}
                            sx={{
                                backgroundColor: "#ffffff",
                                boxSizing:'border-box',
                                flex:1,
                                borderRadius:'8px',
                                overflow:'hidden',
                                position:'relative'
                            }}
                        >
                            {/* Heading */}
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={2}>
                                
                                {/* Title */}
                                <Box display={'flex'} gap={0} alignItems={'center'}>
                                    <IconButton
                                        onClick={handleClickBack}
                                    >
                                        <ArrowBackIcon/>
                                    </IconButton>
                                    <Typography variant='subtitle1' fontWeight={'bold'}>{ADMIN_VIEW_ALL_CLINIC['eng']}</Typography>
                                </Box>

                                {/* Header Buttons */}
                                <Box display={'flex'} gap={2}>
                                    <Button
                                        variant='contained'
                                        startIcon={<AddOutlinedIcon/>}
                                        onClick={handleClickNewUser}
                                        sx={{
                                            display:userFeatures.includes(getFeatureValue('USER_CREATION')) ? 'flex' : 'none'
                                        }}
                                    >
                                        <Typography variant='subtitle2' textTransform={'none'}>{ADMIN_NEW_USER['eng']}</Typography>
                                    </Button>
                                    
                                </Box>

                            </Box>  

                            {/* Details */}
                            {loader ? (
                                <Box p={2}>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                </Box>
                            ) : (
                                <Box height={'100%'} display={'flex'} flexDirection={'column'} sx={{boxSizing:'border-box'}}  overflow={'auto'} paddingX={2} paddingTop={1} paddingBottom={10}>
                                    <Box display={'flex'} gap={3}>

                                        {/* Left */}
                                        <Box flex={1} display={'flex'} flexDirection={'column'} gap={4}>
                                            <Box display={'flex'} gap={2} alignItems={'center'}>
                                                <Box
                                                    width={{ xs: 100 }}
                                                    height={{xs:100}}
                                                    component="img"
                                                    alt="clinic"
                                                    src={state.logo || "/images/healthcarefacilitybackground.svg"}
                                                
                                                    sx={{borderRadius:'50%',overflow:'hidden',backgroundColor:theme.palette.primary.lighter,backgroundPosition:'center',backgroundSize:'cover'}}
                                                />
                                                
                                                <Box flex={1} display={"flex"} flexDirection={'column'} gap={0.5} >
                                                    <Typography fontWeight={'bold'}>{`${state.clinicName}`}</Typography>
                                                    <Box display={'flex'} alignItems={'center'} gap={1}>
                                                        <LocalPhoneOutlinedIcon color='primary' fontSize='6px'/>
                                                        <Typography variant='caption' color={theme.palette.primary.main}>{`${state.clinicContactNumber}`}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <Box display={'flex'} gap={1}>
                                                {state.images.map(elem=>{
                                                    return (
                                                        <Box 
                                                            display={'flex'} 
                                                            width={'100px'}
                                                            height={'100px'}
                                                            flexDirection={'column'} 
                                                            justifyContent={'center'} 
                                                            alignItems={'center'}
                                                            gap={0.5}
                                                            sx={{
                                                                cursor:'pointer',
                                                                borderRadius:"14px",
                                                                backgroundColor:theme.palette.primary.lighter,
                                                                overflow:'hidden'
                                                            }}
                                                        >
                                                            <Box
                                                                width={'100%'}
                                                                height={'100%'}
                                                                component="img"
                                                                alt="clinic"
                                                                src={elem}
                                                                sx={{color:'primary',backgroundPosition:'center',backgroundSize:'cover'}}
                                                            />
                                                        </Box>
                                                    )
                                                })}
                                            </Box>

                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} gap={1}>
                                                <Typography variant='caption' fontWeight={'bold'}>{ADMIN_SERVICES['eng']}</Typography>
                                                <ul style={{paddingLeft:20,margin:0,color:theme.palette.white.greyText}}>
                                                    {state.services?.map(elem=>{
                                                        return (
                                                            <li >
                                                                <Typography variant='caption' color={theme.palette.white.greyText}>{elem}</Typography>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </Box>
                                        </Box>

                                        {/* Center */}
                                        <Box flex={1} display={'flex'} flexDirection={'column'} gap={2}>
                                            <Box display={'flex'} gap={2} flexDirection={'column'} justifyContent={'start'}>
                                                
                                                {/* <Box display={'flex'} flexDirection={'column'}>
                                                    <Typography variant='caption' fontWeight={'bold'}>{ADMIN_TIMINGS['eng']}</Typography>
                                                
                                                    <Box display={'flex'} mt={1} flexDirection={'column'} gap={2}>
                                                        
                                                        {formatTiming(state.appointment_slots)}
                                                    </Box>
                                                </Box> */}

                                                <Box display={'flex'} mt={1} flexDirection={'column'}>
                                                    <Typography variant='caption' fontWeight={'bold'}>{ADMIN_ADDRESS['eng']}</Typography>
                                                    <Typography variant='caption' color={theme.palette.white.greyText}>{state.address}</Typography>
                                                </Box>

                                                <Box>
                                                    <Button
                                                        startIcon={<LocationOnOutlinedIcon fontSize='8px'/>}
                                                        onClick={handleClickDirectionsToClinic}
                                                    >
                                                        <Typography variant='caption' textTransform={'none'} color={theme.palette.primary.main}>{ADMIN_DIRECTIONS_TO_CLINIC['eng']}</Typography>
                                                    </Button>
                                                </Box>

                                                <Box>
                                                    <Typography variant='caption' color={theme.palette.white.greyText}>{state.personalWebsiteURL}</Typography>
                                                </Box>

                                                <Box display={'flex'} gap={0}>
                                                    <IconButton onClick={handleClickFacebook} sx={{padding:0.5}}>
                                                        <FacebookIcon color='primary' fontSize='small'/>
                                                    </IconButton>
                                                    <IconButton onClick={handleClickInstagram} sx={{padding:0.5}}>
                                                        <InstagramIcon color='primary' fontSize='small'/>
                                                    </IconButton>
                                                </Box>

                                                <Box display={'flex'} mt={1} flexDirection={'column'}>
                                                    <Typography variant='caption' fontWeight={'bold'}>{ADMIN_HEALTHCARE_FACILITY_REGISTRY['eng']}</Typography>
                                                    <Typography variant='caption' color={theme.palette.white.greyText}>{state.healthcareFacilityRegistry}</Typography>
                                                </Box>

                                                <Box display={'flex'} mt={1} flexDirection={'column'}>
                                                    <Typography variant='caption' fontWeight={'bold'}>{ADMIN_MAX_USER_LIMIT['eng']}</Typography>
                                                    <Typography variant='caption' color={theme.palette.white.greyText}>{state.maxUserLimit}</Typography>
                                                </Box>

                                            </Box>
                                        </Box>

                                        {/* Right */}
                                        <Box display={'flex'} flexDirection={'column'} gap={2}>
                                            <Box display={'flex'} gap={2} flexDirection={'column'} justifyContent={'start'}>
                                                
                                                <Button
                                                    variant='outlined'
                                                    onClick={handleClickEdit}
                                                    sx={{
                                                        borderRadius:'8px',
                                                        backgroundColor:theme.palette.primary.lighter,
                                                        display:userFeatures.includes(getFeatureValue('CLINIC_UPDATION')) ? 'flex' : 'none'
                                                    }}
                                                    startIcon={<EditIcon/>}
                                                >
                                                    <Typography textTransform={'none'}>{ADMIN_EDIT['eng']}</Typography>
                                                </Button>

                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )} 

                            
                        </Box>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  )
}

export default ClinicDetails