import { Box, Typography, useTheme } from '@mui/material';
import React from 'react'

const MobileLoginSplashScreen = () => {
    const theme = useTheme();
    return (
      <Box 
        position={'relative'}
        sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            height:'100vh',
            width:'100vw',
            background:`linear-gradient(to bottom, ${theme.palette.primary.splashScreenGradient2}, ${theme.palette.primary.splashScreenGradient1})`,
        }}
      >
        <Box position="absolute" width={150} height={100} top={0} left="45%" sx={{backgroundImage:`url(/images/grid_pattern_login.svg)`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}/>
        <Box position="absolute"  width={150} height={100} bottom={0} left={"10%"} sx={{backgroundImage:`url(/images/grid_pattern_login.svg)`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}/>
  
            <Box
                component="img"
                width={250}
                marginBottom={4}
                alt="Login Image"
                src="/images/loginImage.svg"
            />
            <Box component="div" paddingX={{xs:1, md:4, lg:18}}>
                <Typography fontWeight="Medium" textAlign="center" color={theme.palette.white.main} fontSize={20} marginBottom={2}>Integrated Healthcare  <br/> Now deliver care seamlessly</Typography>
                <Typography fontWeight="Medium" textAlign="center" color={theme.palette.white.main} fontSize={10}>Connecting the dots across clinical and <br/> non-clinical domains along the healthcare continuum.</Typography>
            </Box>
      </Box>
    )
}

export default MobileLoginSplashScreen