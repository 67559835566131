import {  Box,Select, Card, FormControl, Autocomplete, InputLabel, CardContent, CardHeader, IconButton,Button,TextField, InputAdornment,Link,MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import {styled} from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import SearchIcon from '@mui/icons-material/Search'; // Replace with your desired icon


const diseases = [
  { name: "Influenza" },
  { name: "Common Cold" },
  { name: "Pneumonia" },
  { name: "Gastroenteritis" },
  { name: "Urinary Tract Infection (UTI)" },
  { name: "Bronchitis" },
  { name: "Acute Sinusitis" },
  { name: "Strep Throat" },
  { name: "Gastroesophageal Reflux Disease (GERD)" },
  { name: "Migraine" },
  { name: "Asthma" },
  { name: "Chronic Obstructive Pulmonary Disease (COPD)" },
  { name: "Acute Myocardial Infarction (Heart Attack)" },
  { name: "Type 2 Diabetes Mellitus" },
  { name: "Hypertension (High Blood Pressure)" },
  { name: "Gastric Ulcer" },
  { name: "Kidney Stones" },
  { name: "Hepatitis B" },
  { name: "Crohn's Disease" },
  { name: "Rheumatoid Arthritis" },
  { name: "Fibromyalgia" },
  { name: "Chronic Kidney Disease" },
  { name: "Lupus" },
  { name: "Anxiety Disorders" },
  { name: "Depression" },
  { name: "Obsessive-Compulsive Disorder (OCD)" },
  { name: "Post-Traumatic Stress Disorder (PTSD)" },
  { name: "Schizophrenia" },
  { name: "Bipolar Disorder" },
  { name: "Alzheimer's Disease" },
  { name: "Parkinson's Disease" },
  { name: "Multiple Sclerosis" },
  { name: "Chronic Fatigue Syndrome" },
  { name: "Osteoarthritis" },
  { name: "Celiac Disease" },
  { name: "Epilepsy" },
  { name: "Pancreatitis" },
  { name: "Diverticulitis" },
  { name: "Pulmonary Embolism" },
  { name: "Deep Vein Thrombosis (DVT)" },
  { name: "Meningitis" },
  { name: "Tuberculosis" },
  { name: "HIV/AIDS" },
  { name: "Ovarian Cancer" },
  { name: "Breast Cancer" },
  { name: "Prostate Cancer" },
  { name: "Colon Cancer" },
  { name: "Leukemia" },
  { name: "Lymphoma" },
  { name: "Melanoma" },
  { name: "Osteoporosis" },
  { name: "Anemia" },
  { name: "Hypothyroidism" },
  { name: "Hyperthyroidism" },
  { name: "Endometriosis" },
  { name: "Polycystic Ovary Syndrome (PCOS)" },
  { name: "Interstitial Cystitis (IC)" },
  { name: "Chronic Migraine" },
  { name: "Peripheral Artery Disease (PAD)" },
  { name: "Chronic Bronchitis" },
  { name: "Hemorrhoids" },
  { name: "Obstructive Sleep Apnea" },
  { name: "Gallstones" },
  { name: "Gout" },
  { name: "Lactose Intolerance" },
  { name: "Glaucoma" },
  { name: "Macular Degeneration" },
  { name: "Cataracts" },
  { name: "Otitis Media (Middle Ear Infection)" },
  { name: "Vertigo" },
  { name: "Tinnitus" },
  { name: "Temporomandibular Joint (TMJ) Dysfunction" },
  { name: "Gingivitis" },
  { name: "Tooth Decay (Cavities)" },
  { name: "Hemorrhagic Stroke" },
  { name: "Ischemic Stroke" },
  { name: "Peripheral Neuropathy" },
  { name: "Mild Cognitive Impairment (MCI)" },
  { name: "Restless Legs Syndrome (RLS)" },
  { name: "Chronic Obstructive Sleep Apnea (COSA)" },
  { name: "Diverticulosis" },
  { name: "Herniated Disc" },
  { name: "Obesity" },
  { name: "Cirrhosis" },
  { name: "Non-alcoholic Fatty Liver Disease (NAFLD)" },
  { name: "Sleep Paralysis" },
  { name: "Anorexia Nervosa" },
  { name: "Bulimia Nervosa" }
];



const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      display: 'inline-block',
    },
    circle: {
      stroke: '#000', // Adjust color as needed
      strokeWidth: 4,
    },
    avatar: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    progressTeller: {
        border: '1px solid #d07173',
        padding: '5px',
        backgroundColor: '#fff2f3',
        fontSize: '12px',
        borderRadius: '6px',
    },
    roleTeller:{
        border: "1px solid #eaebfa",
        background: "#eaebfa",
        padding: "5px 10px",
        borderRadius: "30px",
        color: "#4d55d6",
        textTransform: "uppercase",
        fontWeight: "600",
        fontSize: "12px",
    },
    gridMargin: {
        /*marginBottom: "20px",*/
        height: "100%",

    },
    cardHeaderAction: {
        fontSize: '14px',
        color: '#494E9D',
        fontWeight: '500',
        fontFamily: 'Roboto',
    },
    cardHeader: {
        fontSize: '15px',
        fontWeight: '600',
    }
  }));
export const ContainerCard = ({ children, id,edit, onEditClick,title,image,absolute }) => {
    const classes = useStyles();

    return (
      <Card sx={{ position: 'relative' }}>
        {(edit!=id) && (
          <CardHeader
            sx={absolute ? { position: 'absolute',top: '0px', right: '5px' } : { position:  'relative' }}
            action={
              <IconButton aria-label="edit" onClick={()=>onEditClick(id)} className={classes.cardHeaderAction}>
                <img src='/images/admin/user/edit.svg'/>&nbsp;Edit
              </IconButton>
            }
            title={<>
                <CardHeadingContainer>
                    <CardHeadingItemHolder>
                        <img src={image} alt={title} />
                    </CardHeadingItemHolder>
                    <CardHeadingItemHolder>
                        {title}
                    </CardHeadingItemHolder>
                </CardHeadingContainer>
                
                
            </>}
            className={classes.cardHeader}
          />
        )}
        <CardContent>
          <Box>{children}</Box>
        </CardContent>
      </Card>
    );
  };

export const CardEmptyMessage = ({title,subTitle,lable,onClick})=>{
    return (
        <CardEmptyMessageHolder onClick={onClick}>
            <CardEmptyTitle>{title}</CardEmptyTitle>
            <CardEmptySubTitle>{subTitle}</CardEmptySubTitle>
            <CardEmptyEditLinkBar>
                <CardEmptyLinkItem >{lable}</CardEmptyLinkItem>
                <CardEmptyLinkItem >
                    <ArrowForwardIosIcon  />
                </CardEmptyLinkItem>
            </CardEmptyEditLinkBar>
        </CardEmptyMessageHolder>    
    )
}  

export const CardEditField = ({title,subTitle,lable,type,onChange,id,value,selectValues})=>{
    return (<CardEmptyMessageHolder onClick={onChange}>
        <CardEmptyTitle>{title}</CardEmptyTitle>
        <CardEmptySubTitle>{subTitle}</CardEmptySubTitle>
        {type=="textarea" &&  <TextareaAutosize id={id} value={value} minRows={4} style={{ width: "100%",borderRadius:"7px",padding:"10px" }} onChange={onChange} placeholder={lable} />}
        {type=="speciality" && <FormControl fullWidth>
                                    <InputLabel id={id+"-label"}>Select Speciality</InputLabel>
                                    <Select
                                        labelId={id+"-label"}
                                        id={id}
                                        value={value}
                                        onChange={(event)=>{event.target.id=id;onChange(event)}}
                                        label="Select Speciality"
                                        sx={{
                                          "& .MuiOutlinedInput-root .MuiInputBase-input": {
                                            backgroundColor: "#ffffff",
                                            
                                          },
                                          "& .MuiFormControl-root": {
                                            backgroundColor: "#ffffff",
                                          },
                                        }}
                                    >
                                        {Object.keys(selectValues).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {selectValues[key]}
                                        </MenuItem>
                                        ))}
                                    
                                    </Select>
                                </FormControl>}
    </CardEmptyMessageHolder>)    
    
}

export const CardEditTreatmentField = ({title,subTitle,onChange,values,onAdd})=>{
    return (<CardEmptyMessageHolder onClick={onChange}>
        <CardEmptyTitle>{title}</CardEmptyTitle>
        <CardEmptySubTitle>{subTitle}</CardEmptySubTitle>
        
        <Autocomplete
            multiple
            id="tags-outlined"
            options={diseases}
            getOptionLabel={(option) => option.name}
            defaultValue={values}
            filterSelectedOptions
            onChange={onChange}
            sx={{
              "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                backgroundColor: "#ffffff",
                
              },
              "& .MuiInputBase-root": {
                backgroundColor: "#ffffff",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Commonly Treats"
                placeholder="Search"
              />
            )}
          />
    </CardEmptyMessageHolder>)
}
export const CardEditSocialField = ({title,subTitle,onChange,values,onAdd})=>{
    return (<CardEmptyMessageHolder onClick={onChange}>
        <CardEmptyTitle>{title}</CardEmptyTitle>
        <CardEmptySubTitle>{subTitle}</CardEmptySubTitle>
        <SocialInputs onAdd={onAdd} values={values} onChange={onChange}/>
    </CardEmptyMessageHolder>)    
    
}

export const SocialView =({values})=>{
    return (
    <SocialIconsHolder >
        {values.map((input, index) => (
          <>{input.value&&
          <SingleSocialIconHolder>
            <img src={`/images/admin/user/${input.type}.svg`}/>
            <div style={{textTransform:"capitalize"}}>{input.type}</div>
            <div>
              <Link href={input.value} target="_blank" rel="noopener noreferrer">
                <span>View</span>
                <span><ArrowForwardIosIcon  /></span>
              </Link>
            </div>
          </SingleSocialIconHolder>}
          </>
        ))}
        
    </SocialIconsHolder>)
}
export const SocialInputs =({onAdd,values,onChange})=>{
    console.log("SocialInputs values",values)
    return (
        <>
        {values.map((input, index) => (
            <>
                {input.type=="linkedin" && <SocialMediaLinkInput
                    label=""
                    variant="outlined"
                    placeholder='Linkedin URL'
                    type='url'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <img src='/images/admin/user/linkdin.svg'/>
                        </InputAdornment>
                        ),
                    }}
                    value={input.value}
                    onChange={(e)=>onChange(e,index)}
                />}
                {input.type=="instagram" && <SocialMediaLinkInput
                    label=""
                    variant="outlined"
                    type='url'
                    placeholder='Instagram URL'
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <img src='/images/admin/user/insta.svg'/>
                        </InputAdornment>
                        ),
                    }}
                    value={input.value}
                    onChange={(e)=>onChange(e,index)}
                />}
                {input.type=="other" && <SocialMediaLinkInput
                    label=""
                    variant="outlined"
                    placeholder='Enter URL'
                    type='url'
                    value={input.value}
                    onChange={(e)=>onChange(e,index)}
                />}
            </>
        ))}
         
             <CardEmptyEditLinkBar onClick={()=>onAdd([...values,{type:"other",value:""}])}>
                <CardEmptyLinkItem >{"Add more"}</CardEmptyLinkItem>
                <CardEmptyLinkItem >
                    <ArrowForwardIosIcon  />
                </CardEmptyLinkItem>
            </CardEmptyEditLinkBar>

        </>
    )
}
export const EditBar =({onCancel,onSave}) => {
    return (
        <EditBarHolder>
            <EditBarItem >
                <Button onClick={onCancel} variant="outlined">Cancel</Button>

            </EditBarItem>
            <EditBarItem >
                <Button onClick={onSave} variant="contained" color="primary">
                    Save
                </Button>
            </EditBarItem>
        </EditBarHolder>
    )
}

export const CommonlyTreats =({values}) =>{
  return (<CommonlyTreatsHolder>
          {(values || []).map((input, index) => (
            <SingleCommonlyTreatHolder key={index}>
              {input.name}
            </SingleCommonlyTreatHolder>
          ))}
        </CommonlyTreatsHolder>)

}

export const CardItemContent = styled('div')(
    ({ theme }) => `
      padding-left: 40px;
      `,
  
)
export  const CardHeadingContainer = styled('div')(
    ({ theme }) => `
    
    display: flex;
    align-items: center;
    spacing: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    
  `,
  );

export const SocialMediaLinkInput = styled(TextField)(
    ({ theme }) => `
        width:100%;
        margin-bottom:10px;
    `,
)

export  const CardHeadingItemHolder = styled('div')(
    ({ theme }) => `
    
    padding-right: 10px;
    font-size: 18px;
    height: 100%;
    font-family: Roboto;
    color: #313131;
    font-weight: 700;
  `,
  );

 export const CardEmptyMessageHolder = styled('div')(
    ({ theme }) => `
        background-color: #F1F1F8;
        border-radius: 10px;
        text-align: center;
        padding: 10px;
    `,
 );
 
 export const CardEmptyTitle = styled('div')(
    ({ theme }) => `
        font-weight: 600;
        `,
 )
 export const CardEmptySubTitle = styled('div')(
    ({ theme }) => `
        font-size: 12px;
        padding-bottom: 10px;
        `,
 )

 export const CommonlyTreatsHolder = styled('div')(
    ({ theme }) => `
      display: flex;
      flex-wrap: wrap;

    `,
 )

 export const SingleCommonlyTreatHolder = styled('div')(
  ({ theme }) => `
    width:50%;
    &:before {
      content: "•";
      
      padding-right: 10px;
    }
  `,
)

export const SocialIconsHolder = styled('div')(
  ({ theme }) => `
    display: flex;
    flex-wrap: wrap;

  `,
)

export const SingleSocialIconHolder = styled('div')(
({ theme }) => `
  width:33.33%;
  
`,
)
 export const CardEmptyEditLinkBar = styled('div')(
    ({ theme }) => `
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border: 1px solid #494E9D;
        border-radius: 10px;
        padding: 10px;
        color: #494E9D;
        cursor: pointer;
    `
 )

 export const CardEmptyLinkItem = styled('div')(
    ({ theme }) => `
    
    `,
 )

 export const EditBarHolder = styled('div')(
    ({ theme }) => `
        background: #ffffff;
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        bottom: 0;
        
        padding: 10px;
        border-radius: 10px;
        width:65%;
    `,
 )

 export const EditBarItem = styled('div')(
    ({ theme }) => ``,
 )