import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { ADMIN_ADD, ADMIN_ADD_CLINIC, ADMIN_ADD_CLINIC_INIT_HEADING, ADMIN_CANCEL, ADMIN_CREATE_CLINIC, ADMIN_CREATE_NEW_CLINIC_ACCOUNT, ADMIN_CREATE_NEW_CLINIC_ACCOUNT_SUB, ADMIN_CREATE_NEW_USERS, ADMIN_CREATE_USERS, ADMIN_EMAIL, ADMIN_FEATURES, ADMIN_FIRST_NAME, ADMIN_LETS_GET_STARTED, ADMIN_LETS_GET_STARTED_SUB, ADMIN_NEW_USER, ADMIN_PHONE_NUMBER, ADMIN_ROLE, ADMIN_SUPER_ADMIN_SETUP_SUB, ADMIN_TAB_ADD_NEW_USER, ADMIN_TAB_MANAGE_EXISTING_USERS } from '../../../../assets/translations/admin';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { color, style, typography } from '@mui/system';

const sections = [
    {
        label:'Clinic Management',
        value:'/admin/clinicmanagement/allclinic'
    },
    {
        label:'User Management',
        value:'/admin/usermanagement'
    },
    {
        label:'Slot Management',
        value:'/admin/slotmanagement'
    },
]


const SuperAdminSetup = () => {

  //Hooks
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();

  //States
  const [pathname,setPathname] = useState('');
  

  //Effects
  useEffect(()=>{
    if(location.pathname){
        setPathname(location.pathname);
    }
  },[location])

  //Functions
  const handleAddClinic = ()=>{
    return;
  }


  

  return (
    <>
      {isMobileScreen ? (
        <>
          
        </>
      ) : (
        <Box height={"100%"} width={"100%"} zIndex={100} position={"absolute"}>
          
          <Box >
            <Grid container height={{xs:"100%",md:`calc(100vh - 42px)`}} width={{xs:"100%",md:`calc(100vw - 60px)`}} spacing={4}>
              
              {/* left panel */}
              <Grid
                item
                height="100%"
                xs={3.3}
                
              >
                <Box 
                    width={'100%'} 
                    height={'100%'}
                    sx={{
                        // boxShadow: "1px 1px 4px 2px rgba(0,0,0,0.2)",
                        backgroundColor: "#ffffff",
                        borderRadius:'0px 8px 0px 0px'
                    }}
                >
                    {
                        sections.map((elem)=>{
                            return (
                                <Box 
                                    key={elem.label}
                                    width={'100%'} 
                                    boxSizing={'border-box'}
                                    paddingX={4} 
                                    paddingY={2} 
                                    display={'flex'} 
                                    justifyContent={'space-between'} 
                                    alignItems={'center'}
                                    onClick={()=>{
                                        // navigate(elem.value)
                                    }}
                                    sx={{
                                        cursor:'pointer'
                                    }}
                                >
                                    <Typography 
                                        variant='subtitle2' 
                                        color={pathname.includes(elem.value) ? theme.palette.primary.main : 'black'}
                                        fontWeight={pathname.includes(elem.value) ? "bold" : 'regular'}
                                    >
                                        {elem.label}
                                    </Typography>
                                    {
                                        pathname.includes(elem.value) && (
                                            <ArrowForwardIosOutlinedIcon fontSize='small' sx={{color:theme.palette.primary.main}}/>
                                        )
                                    }
                                </Box>
                            )
                        })
                    }
                </Box>
              </Grid>

              {/* right panel */}
              <Grid
                item
                height="100%"
                xs
                spacing={10}
              >
                <Grid
                    container
                    height={'100%'}
                    spacing={2}
                    direction={'column'}
                >
                    <Grid
                        item
                        xs
                    >
                        <Box 
                            width={'100%'} 
                            height={'100%'}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={6}
                            sx={{
                                // boxShadow: "1px 1px 2px 2px rgba(0,0,0,0.2)",
                                backgroundColor: "#ffffff",
                                boxSizing:'border-box',
                                flex:1,
                                borderRadius:'8px',
                                // marginX:'50px'
                            }}
                        >
                            <Box display={'flex'}>
                                <Typography textAlign={'center'} fontWeight={'bold'}>{ADMIN_ADD_CLINIC_INIT_HEADING['eng']}</Typography>
                            </Box>

                            <Box width={'100%'} display={'flex'} justifyContent={'space-around'}>
                                <Box flex={1} height={'100%'} display={'flex'} flexDirection={'column'}>
                                    <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={1}>
                                        <Box
                                            width={{xs:150 ,md: 200 }}
                                            component="img"
                                            alt="add clnic"
                                            src="/images/clinicmanagementinit.svg"
                                        />
                                        <Typography fontWeight={'bold'} mt={1}>{ADMIN_CREATE_NEW_CLINIC_ACCOUNT['eng']}</Typography>
                                        <Typography maxWidth={'260px'} variant='subtitle2' textAlign={"center"} color={theme.palette.white.greyText}>{ADMIN_CREATE_NEW_CLINIC_ACCOUNT_SUB['eng']}</Typography>

                                        <Button
                                            variant='contained'
                                            sx={{
                                                marginTop:2,
                                                borderRadius:'8px',
                                                paddingY:1
                                            }}
                                        >
                                            <Typography textTransform={'none'}>{ADMIN_CREATE_CLINIC['eng']}</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                <Box flex={1} height={'100%'} display={'flex'} flexDirection={'column'}>
                                    <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={1}>
                                        <Box
                                            width={{xs:150 ,md: 200 }}
                                            component="img"
                                            alt="add clnic"
                                            src="/images/userimg.svg"
                                        />
                                        <Typography fontWeight={'bold'} mt={1}>{ADMIN_CREATE_NEW_USERS['eng']}</Typography>
                                        <Typography maxWidth={'300px'} variant='subtitle2' textAlign={"center"} color={theme.palette.white.greyText}>{ADMIN_SUPER_ADMIN_SETUP_SUB['eng']}</Typography>

                                        <Button
                                            variant='contained'
                                            sx={{
                                                marginTop:2,
                                                borderRadius:'8px',
                                                paddingY:1
                                            }}
                                        >
                                            <Typography textTransform={'none'}>{ADMIN_CREATE_USERS['eng']}</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  )
}

export default SuperAdminSetup