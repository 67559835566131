import { styled } from "@mui/system";
import { MEAL_PREFERENCE, MEDICATION_HISTORY, PHYSICAL_ACTIVITY, SOCIAL_HISTORY } from "../../../../../../../shared/constants";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";


const isPatientHistoryEmpty = (sectionData: any) => {
    if (!sectionData) {
        return
    }
    const splitData = sectionData?.rows?.filter(
        (row: any) => (row?.view_type !== SOCIAL_HISTORY && row?.view_type !== MEAL_PREFERENCE && row?.view_type !== PHYSICAL_ACTIVITY && row?.view_type !== MEDICATION_HISTORY) 
    );
    const noSocialHistory = sectionData?.rows?.filter((row: any) => row?.view_type === SOCIAL_HISTORY)[0]
        ?.value?.every((val: any) => val?.status?.length === 0);

    const noComplaintHistory = splitData?.every(
        (row: any) => row?.value?.length === 0
    );

    const noMealPref = sectionData?.rows?.filter((row: any) => row?.view_type === MEAL_PREFERENCE)[0]
    ?.value?.length === 0;

    const noPhysicalActivity = sectionData?.rows?.filter((row: any) => row?.view_type === PHYSICAL_ACTIVITY)[0]
    ?.value?.length === 0;

    const noMedicationHistory = sectionData?.rows?.filter((row: any) => row?.view_type === MEDICATION_HISTORY)[0]
    ?.value?.every((val: any) => val?.value === '');

    const isEmpty = noComplaintHistory && noSocialHistory && noMealPref && noPhysicalActivity && noMedicationHistory;

    return isEmpty;
};

export const CustomTextarea = styled(BaseTextareaAutosize)(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    padding: "12px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    border: `2px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: "4px",
    "&:hover": {
        borderColor: "#494E9D",
    },
    "&:focus-visible": {
        border: "2px solid #494E9D",
        outline: "#494E9D 2px",
    },
}));

export {
    isPatientHistoryEmpty
}