import { Box, Button, IconButton, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "14px",
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "14px",
};

const OverwriteModalComponent = ({
  open,
  setOpen,
  headerText,
  subText,
  actionButtonOnClick,
}) => {

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        // if(isSuccess){
        //   window.location.reload();
        // }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={isMobileScreen? style2 : style} display={"flex"} flexDirection={"column"} gap={1}>
        <Box display={"flex"} justifyContent={"space-between"} gap={2}>
          <Box display={"flex"} alignItems={"center"}>
            <Box
              width={{ md: 24 }}
              component="img"
              alt="add patient"
              src={"/images/editicon.svg"}
            />
          </Box>

          <Box display={"flex"} alignItems={"center"}>
            <Typography variant={"h6"} fontWeight={"bold"}>
              {headerText}
            </Typography>
          </Box>

          <Box>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box
          maxHeight={300}
          overflow={"auto"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
        >
          <Typography variant="subtitle1" textAlign={"left"}>
            {subText}
          </Typography>
          <Box display={"flex"} gap={2} alignSelf={"flex-end"}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false);
              }}
            >
              {"Cancel"}
            </Button>
            <Button variant="contained" onClick={actionButtonOnClick}>
              {"YES, OVERWRITE"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default OverwriteModalComponent;
