/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Skeleton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { SyntheticEvent, useEffect, useReducer, useState } from 'react'
import Card from '../../../utils/Card'
import { CARD_SHADOW } from '../../../utils/Constants'
import CardHeader from '../../../utils/CardHeader'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { MARITAL_HISTORY, OBG_HISTORY } from '../../../../../../shared/constants'


interface MaritalHistoryProps {
    sex: string;
    delivery: string;
    birthWeight: string;
    age: string;
    comments: string;
    advice: string;
  }
  
  const EditorModules = {
    toolbar: [
      ["bold", "italic", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  
  const initState = {
    marriageStatus: '',
    marriedSince: '',
    consanguinity: '',
    comments: '',
  }

const MaritalHistory = (props:any) => {

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isFocused: boolean = props.isFocused || false;
    const sectionData = props?.sectionData?.[0];

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
        ...state,
        ...action
    }
},initState)

  const { setTabData } = props;
//   const {
//     prescription: { instantRxData },
//   } = useSelector((state: any) => state.prescription);

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };

  useEffect(() => {
    if (sectionData) {
        const data = sectionData?.value
        const marriageStatus = data?.find((elem:any)=>elem?.name === 'married_status')
        const marriageSince = data?.find((elem:any)=>elem?.name === 'Married since')
        const consanguinity = data?.find((elem:any)=>elem?.name === 'Consanguinity')
        const comment = data?.find((elem:any)=>elem?.name === 'comment')

        console.log("render",comment)
        
        setState({
            marriageStatus: marriageStatus ? marriageStatus?.value : '',
            marriedSince: marriageSince ? marriageSince?.value : '',
            consanguinity: consanguinity ? consanguinity?.value : '',
            comments: comment ? comment?.value : '',
        })
      setLoading(false);
    }
  }, [sectionData]);


  const renderHeaderButtons = ()=>{

    const buttons = [
        {
            name:'Delete',
            icon:'icon_delete_rx.svg',
            onClick:()=>{
                setState({initState})
                resetTabData();
                props?.setShowHistory({
                    ...props?.showHistory,
                    ["Marital History"]: false,
                })
            }
        },
    ]

    

    return (
        <>
            {buttons.map((elem,index)=>{
                return (
                    <button
                        key={elem.name}
                        style={{
                            outline: "none",
                            background: "none",
                            backgroundColor:theme.palette.primary.light,
                            padding: 8,
                            // marginRight: index !== imgs.length - 1 ? 16 : 0,
                            border: 0,
                            borderRadius:'50%',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                    >
                        <Tooltip
                            title={elem.name}
                        >
                            <img
                                onClick={elem.onClick}
                                src={`/images/${elem.icon}`}
                                alt={elem.icon}
                            />
                        </Tooltip>
                    </button>
                )
            })}
            
        </>
    )
    
  }

  const resetTabData = ()=>{
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === MARITAL_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      return {...val,value:''};
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
  }

  const validateInput = (value:any,type:string)=>{
    switch(type){
        case 'marriedSince':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
  }

  const updateHistory = (event?:SyntheticEvent, value?: string | null, forKey?:string)=>{
    
    const target =
      (event?.target as HTMLInputElement) ||
      (event?.target as HTMLElement);

    const ariaLabel = target?.ariaLabel as string;
    const inputName = target?.name as string;

    const key = forKey || inputName;

    let data;

    setTabData((prev: any) => {
        data = prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === MARITAL_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === key) {
                        return {
                          ...val,
                          value: value,
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
        console.log("data",data)
        return data;
      });
    return;
  }



  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Marital History"
        >
          <CardHeader
            headerImg=""
            headerTitle="Marital History"
          >
            <Box display={'flex'} gap={1}>
                {renderHeaderButtons()}
            </Box>
            
          </CardHeader>

          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box display={'flex'} sx={{width:'100%'}} justifyContent={'space-between'} gap={4}>
                <RadioGroup
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="married_status"
                    row
                    value={state.marriageStatus || ''}
                    onClick={(e:any)=>{
                        if(e?.target?.value){
                            setState({marriageStatus:e.target.value})
                        }
                    }}
                    onBlur={(e:any)=>{updateHistory(e,e.target.value)}}
                >
                    <FormControlLabel value="married" control={<Radio />} label="Married" />
                    <FormControlLabel value="unmarried" control={<Radio />} label="Unmarried" />
                    <FormControlLabel value="divorced" control={<Radio />} label="Divorced" />
                </RadioGroup>

                <TextField
                    name={`Married since`}
                    label={'Married Since'}
                    value={state.marriedSince}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'marriedSince')){
                            setState({
                                marriedSince:e.target.value
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                    sx={{width:'200px'}}
                />

                <Box display={'flex'} gap={2} justifyContent={'start'} alignItems={'center'} sx={{flex:1}}>
                    <Typography>Consanguinity</Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="Consanguinity"
                        row
                        value={state.consanguinity || ''}
                        onClick={(e:any)=>{
                            if(e?.target?.value){
                                setState({consanguinity:e.target.value})
                            }
                        }}
                        onBlur={(e:any)=>{updateHistory(e,e.target.value)}}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Box>
            </Box>
            <ReactQuill
                id="rx-examination-findings"
                theme="snow"
                placeholder="Enter some advice for the patient"
                modules={EditorModules}
                value={state.comments}
                onChange={(content, _delta, _source, editor) => {
                    let newComment = content;
                    if (editor.getLength() <= 1) {
                        newComment = "";
                    }
                    if (editor.getLength() > 1500) {
                        newComment = newComment.substring(0, 1500);
                    }
                    setState({
                        comments:newComment
                    })
                    // setTabData((prev: any) => {
                //     return prev.map((section: any) => {
                //     if (section.view_type === MENSTRUAL_HISTORY) {
                //         return {
                //         ...section,
                //         rows: section.rows.map((row: any, index: number) => {
                //             if (index === 0) {
                //             return {
                //                 ...row,
                //                 value: row.value.map((val: any, index: number) => {
                //                 if (index === 0) {
                //                     return {
                //                     ...val,
                //                     comments: newComment ? newComment : "",
                //                     };
                //                 }
                //                 return val;
                //                 }),
                //             };
                //             }
                //             return row;
                //         }),
                //         };
                //     }
                //     return section;
                //     });
                // });
                }}
                onBlur={(e:any)=>{
                    updateHistory(e,state.comments,'comment');
                }}
                style={{ color: "#000000" }}
            />
          </Box>
          

          
        </Card>
      )}
      {!loading && isMobileScreen && (
        <>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="married_status"
                  row
                  value={state.marriageStatus || ''}
                  onClick={(e:any)=>{
                      if(e?.target?.value){
                          setState({marriageStatus:e.target.value})
                      }
                  }}
                  onBlur={(e:any)=>{updateHistory(e,e.target.value)}}
              >
                  <FormControlLabel value="married" control={<Radio />} label="Married" />
                  <FormControlLabel value="unmarried" control={<Radio />} label="Unmarried" />
                  <FormControlLabel value="divorced" control={<Radio />} label="Divorced" />
              </RadioGroup>

              <TextField
                  name={`Married since`}
                  label={'Married Since'}
                  value={state.marriedSince}
                  onChange={(e)=>{
                      if(validateInput(e.target.value,'marriedSince')){
                          setState({
                              marriedSince:e.target.value
                          })
                      }
                  }}
                  onBlur={(event: any) => {
                      updateHistory(event, event.target.value);
                  }}
                  sx={{flex:1}}
              />

              <Box display={'flex'} gap={2} justifyContent={'start'} alignItems={'center'} sx={{flex:1}}>
                  <Typography>Consanguinity</Typography>
                  <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="Consanguinity"
                      row
                      value={state.consanguinity || ''}
                      onClick={(e:any)=>{
                          if(e?.target?.value){
                              setState({consanguinity:e.target.value})
                          }
                      }}
                      onBlur={(e:any)=>{updateHistory(e,e.target.value)}}
                  >
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
              </Box>
              <ReactQuill
                  id="rx-examination-findings"
                  theme="snow"
                  placeholder="Enter some advice for the patient"
                  modules={EditorModules}
                  value={state.comments}
                  onChange={(content, _delta, _source, editor) => {
                      let newComment = content;
                      if (editor.getLength() <= 1) {
                          newComment = "";
                      }
                      if (editor.getLength() > 1500) {
                          newComment = newComment.substring(0, 1500);
                      }
                      setState({
                          comments:newComment
                      })
                      // setTabData((prev: any) => {
                  //     return prev.map((section: any) => {
                  //     if (section.view_type === MENSTRUAL_HISTORY) {
                  //         return {
                  //         ...section,
                  //         rows: section.rows.map((row: any, index: number) => {
                  //             if (index === 0) {
                  //             return {
                  //                 ...row,
                  //                 value: row.value.map((val: any, index: number) => {
                  //                 if (index === 0) {
                  //                     return {
                  //                     ...val,
                  //                     comments: newComment ? newComment : "",
                  //                     };
                  //                 }
                  //                 return val;
                  //                 }),
                  //             };
                  //             }
                  //             return row;
                  //         }),
                  //         };
                  //     }
                  //     return section;
                  //     });
                  // });
                  }}
                  onBlur={(e:any)=>{
                      updateHistory(e,state.comments,'comment');
                  }}
                  style={{ color: "#000000" }}
              />
          </Box>
        </>
      )}
    </>
  )
}

export default MaritalHistory