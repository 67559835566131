import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useAuthorization } from '../../../shared/Utils/useAuthorization';
import { Box, Grid, IconButton, Menu, MenuItem, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllInvoice } from '../../../redux/reducers/appointment.slice';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Invoice from '../prescription/utils/Invoice';
import html2PDF from 'jspdf-html2canvas';
import PDFModal from '../../../shared/Pdf-Modal/Pdf-Modal';
import { INVOICE_DOCUMENTS, PATIENT_DOCUMENTS, SVAAS_DOCUMENTS } from '../../../shared/constants';

const initState = {
    
}

const initErrorState = {
    errorField:'',
    errorMsg:'',
    showError:false
}

const columns = [
    { id: 'srno', label: 'SR No', minWidth: 20,align: 'left', },
    { id: 'patientinfo', label: 'Patient Info', minWidth: 170,align: 'left', },
    { id: 'mobileno', label: 'Mobile No', minWidth: 100,align: 'left', },
    {
      id: 'service',
      label: 'Service',
      minWidth: 140,
      align: 'left',
    },
    {
      id: 'visittype',
      label: 'Visit Type',
      minWidth: 160,
      align: 'left',
    },
    {
      id: 'type',
      label: 'Type',
      minWidth: 140,
      align: 'left',
    },
    {
      id: 'mode',
      label: 'Mode',
      minWidth: 140,
      align: 'left',
    },
    {
      id: 'amountpaid',
      label: 'Amount Paid',
      minWidth: 140,
      align: 'left',
    },
    {
      id: 'balance',
      label: 'Balance',
      minWidth: 140,
      align: 'left',
    },
    {
      id: 'total',
      label: 'Total',
      minWidth: 140,
      align: 'left',
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 50,
      align: 'left',
    }
];

const AllInvoice = () => {

    //variables


    //state
    const [pathname,setPathname] = useState('');
    const [state,setState] = useReducer((state,action)=>{
        return {
            ...state,
            ...action
        }
    },initState)
    const [errorState,setErrorState] = useReducer((state,action)=>{
        return {
            ...state,
            ...action
        }
    },initErrorState)
    const [rows,setRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [resultsCount, setResultsCount] = useState(0);

    let evaluatedKey = useRef(null);
    let prevEvaluatedKey = useRef([]);
    const setEvaluatedKey = (val) => {
        evaluatedKey.current = val;
    };
    const setPrevEvaluatedKey = (val) => {
        prevEvaluatedKey.current = val;
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [selectedInvoice,setSelectedInvoice] = useState({});

    const [modalOpen, setModalOpen] = useState(false);
    const [contentType, setContentType] = useState("");
    const [previewPDF,setPreviewPDF] = useState(null);

    const handleClose = () => {
        setModalOpen(false);
    };

    //hooks
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {allinvoice,loader} = useSelector((state)=>state.appointments)
    const userFeatures = useAuthorization();
    const [searchParams, setSearchParams] = useSearchParams();

    //useEffect
    useEffect(()=>{
        if(location.pathname){
            setPathname(location.pathname);
        }
    },[location])

    useEffect(()=>{
        setEvaluatedKey(null);
        setPrevEvaluatedKey([]);
        FetchAllInvoice(rowsPerPage,evaluatedKey);
    },[])

    useEffect(()=>{
        console.log("allinvoice",allinvoice)
        if(allinvoice.length){
            setRows(allinvoice)
            console.log(allinvoice)
        }
    },[allinvoice])

    //function
    const FetchAllInvoice = async (limit,isPrev,isLast) => {
        const response = await dispatch(fetchAllInvoice({
            patientId:searchParams.get('patient_id'),
            params:{
                ...(isLast !== false &&
                    (isPrev === true
                    ? prevEvaluatedKey.current[prevEvaluatedKey.current.length - 1]
                    : evaluatedKey.current)
                    ? {
                        last_evaluatedKey:
                            isPrev === true
                            ? prevEvaluatedKey.current[
                                prevEvaluatedKey.current.length - 1
                                ]
                            : evaluatedKey.current,
                        }
                    : {}),
            }
        }));
        
        setResultsCount(response?.payload?.data?.count)
        setEvaluatedKey(response?.payload?.data?.lastEvaluatedKey)
    }
    
    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            console.log("next page");
            setPrevEvaluatedKey([...prevEvaluatedKey.current, evaluatedKey.current]);
            setPage(newPage);
            FetchAllInvoice(rowsPerPage);
        } else {
            console.log("prev page");
            prevEvaluatedKey.current.pop();
            setPage(newPage);
            FetchAllInvoice(rowsPerPage, true);
        }
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const handleClickRow = ()=>{
        
        return;
    }

    const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
          
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }
    
    
    const handleClickViewInvoice = (data)=>{
        console.log("selectedInvoice",selectedInvoice)
        const input = document.getElementById("invoice-1");
        html2PDF(input, {
            jsPDF: {
            format: "a4",
            },
            imageType: "image/jpeg",
            output: "./pdf/invoice.pdf",
            margin: { right: 0, top: 0, bottom: 0, left: 0 },
            success: async (pdf) => {
                // pdf.output('dataurlnewwindow');
                
                const arrayBuffer = await pdf.output('arraybuffer');
                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

                setPreviewPDF(blob);
                setModalOpen(true);
                setContentType('application/pdf');
            },
        });
        return;
    }
    
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    return (
        <>
            {isMobileScreen ? (
                <>
                
                </>
            ) : (
                <Box height={"100%"} width={"100%"} backgroundColor={'#F4F6F9'} zIndex={100} position={"absolute"}>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        ContentProps={{ sx: { background: "red", marginTop: "40px" } }}
                        open={errorState.showError}
                        onClose={() => setErrorState({showError:false})}
                        autoHideDuration={6000}
                        message={errorState.errorMsg}
                    />

                    


                    <Box height={'100%'} width={'100%'} display={'flex'} flexDirection={'column'} sx={{boxSizing:'border-box'}} padding={4} paddingRight={12}>
                        
                        <Box sx={{display:'flex',gap:1,mb:2}}>
                            <Box
                                component={'img'}
                                src='/images/invoicelist_logo.svg'
                                sx={{
                                    width: "20px",
                                }}
                            />
                            <Typography variant="h5" fontWeight={'bold'} color={theme.palette.primary.main} sx={{fontSize:'16px'}} >INVOICE LIST</Typography>
                        </Box>

                        <Paper
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                overflowX:'scroll',
                                boxShadow:'none',
                            }}
                        
                        >
                            {loader ? (
                                <Box p={1}>
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                </Box>
                            ) : (
                                <TableContainer
                                    sx={{ height: "100%", maxHeight: "100%", flex: 1,overflowX:'auto' }}
                                    //  style={{ height: "100%", maxHeight: "100%",width:'100%',maxWidth:'100%',minWidth:'100%', flex: 1,overflowX:'scroll'}}
                                        
                                >
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                    key={column?.id}
                                                    align={column?.align}
                                                    // style={{ minWidth: column?.minWidth }}
                                                    >
                                                        <Typography variant={'subtitle2'} textTransform={'none'} fontWeight={'bold'}>{column?.label}</Typography>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            
                                            {rows.map((row,index) => {
                                                return (
                                                <TableRow hover={row.is_active?true:false} role="checkbox" tabIndex={-1} key={row.clinicID} onClick={()=>{handleClickRow(row.account_id,row.account_name)}} sx={{
                                                        cursor:'pointer',
                                                        backgroundColor:row.is_active?'white':theme.palette.red.light,
                                                    }}
                                                >
                                                    <TableCell key={columns[0].id} align={columns[0].align}>
                                                        <Box sx={{overflowX:'auto'}}>
                                                            <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{index+1}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell key={columns[1].id} align={columns[1].align}>
                                                        <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                                                            <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.name}</Typography>
                                                            {(row?.gender || row?.age) && <Typography variant={'caption'} textTransform={'none'} color={theme.palette.white.greyText}>{`(${row?.gender ? (row?.gender==='male' ? 'M' : 'F') : ''} ${row?.age ? (row?.age+'y') : ''})`}</Typography>}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell key={columns[2].id} align={columns[2].align}>
                                                        <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.phone}</Typography>
                                                    </TableCell>
                                                    <TableCell key={columns[3].id} align={columns[3].align} sx={{maxWidth:100}}>
                                                        <Tooltip title={row?.invoice_details?.map(elem=>elem?.category).join(', ')}>
                                                            <Typography variant={'subtitle2'} noWrap textTransform={'none'} color={theme.palette.white.greyText}>{row?.invoice_details?.map(elem=>elem?.category).join(', ')}</Typography> 
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell key={columns[4].id} align={columns[4].align} sx={{maxWidth:100}}>
                                                        <Tooltip title={row?.invoice_details?.map(elem=>elem?.type).join(', ')}>
                                                            <Typography variant={'subtitle2'} noWrap textTransform={'none'} color={theme.palette.white.greyText}>
                                                                {row?.invoice_details?.map(elem=>elem?.type).join(', ')}
                                                            </Typography>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell key={columns[5].id} align={columns[5].align}>
                                                        <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.payment_option}</Typography>
                                                    </TableCell>
                                                    <TableCell key={columns[6].id} align={columns[6].align}>
                                                        <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.payment_mode}</Typography>
                                                    </TableCell>
                                                    <TableCell key={columns[7].id} align={columns[7].align}>
                                                        <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.amount_received}</Typography>
                                                    </TableCell>
                                                    <TableCell key={columns[8].id} align={columns[8].align}>
                                                        <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{(row?.invoice_amount && row?.amount_received) ? (row?.invoice_amount - row?.amount_received) : 'NA'}</Typography>
                                                    </TableCell>
                                                    <TableCell key={columns[9].id} align={columns[9].align}>
                                                        <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.invoice_amount}</Typography>
                                                    </TableCell>
                                                    <TableCell key={columns[10].id} align={columns[10].align}>
                                                        <Box display={'flex'}>
                                                            <IconButton onClick={(e)=>{handleClickMenu(e); setSelectedInvoice(row) ;e.stopPropagation();}}>
                                                                <MoreVertOutlinedIcon color='primary'/>
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleCloseMenu}
                                    >
                                        <MenuItem onClick={handleClickViewInvoice}>View Invoice</MenuItem>
                                        {/* <MenuItem onClick={()=>{return}}>Print Invoice</MenuItem>
                                        <MenuItem onClick={()=>{return}}>Share Invoice</MenuItem> */}
                                    </Menu>
                                </TableContainer>
                            )}
                            
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={resultsCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                disabled={loader}
                            />
                        </Paper>

                    </Box>
                </Box>
            )}
            <PDFModal
                From={INVOICE_DOCUMENTS}
                // pdfUrl={previewPDF}
                pdfBlobProp={previewPDF}
                displayType={contentType}
                handleClose={handleClose}
                modalOpen={modalOpen}
            />
            <Box sx={{zIndex:-100}}>
                <Invoice data={selectedInvoice}/>
            </Box>
            
        </>
    )
}

export default AllInvoice