/* eslint-disable no-inner-declarations */
import React, { useEffect, useRef, useState, useReducer } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import UAParser from "ua-parser-js";
import {
  Button,
  Drawer,
  Fab,
  Grid,
  InputLabel,
  Modal,
  Popper,
  Skeleton,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";

import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import StarIcon from "@mui/icons-material/Star";
import moment from "moment";
import {
  useTheme as useMaterialTheme,
  useColorScheme as useMaterialColorScheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
} from "@mui/material/styles";
import {
  extendTheme as extendJoyTheme,
  useColorScheme,
  CssVarsProvider,
  THEME_ID,
} from "@mui/joy/styles";

import { useSlotProps } from "@mui/base/utils";
import Input from "@mui/joy/Input";
//   import Stack from '@mui/joy/Stack';
import { FormControl as JoyFormControl } from "@mui/joy";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";

import { IconButton as JoyIconButton } from "@mui/joy";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { LocalizationProvider as LP } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { unstable_useSingleInputDateRangeField as useSingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";

import Checkbox from "@mui/material/Checkbox";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";

import Menu from "@mui/material/Menu";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import CallIcon from "@mui/icons-material/Call";
import { useClearableField } from "@mui/x-date-pickers/hooks";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Popover from "@mui/material/Popover";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllApponitments,
  fetchAllConfirmedApponitments,
  fetchAppointmentType,
  fetchDoctorTemplates,
  resetAppointments,
  setIsReschedule,
  setRescheduleData,
  setSelectedAppointment,
  updateApponitment,
} from "../redux/reducers/appointment.slice";
import { GradientBox } from "./components/appointment/GradientBox";

import { getAge, getDayFormat } from "../shared/Utils/utilities";
import { SmallSearchInput } from "./components/appointment/SmallSearchInput";

import NewAppointment from "./components/appointment/NewAppointment";
import getAppoitmentTags from "../shared/Utils/getAppointmentTags";
import getAppoitmentActions from "../shared/Utils/getAppointmentActions";
import {
  fetchAccountDetails,
  fetchDoctorsList,
  logout,
  setClinicName,
  setSelectedDoctor,
} from "../redux/reducers/auth.slice";
import _ from "lodash";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  ROUTE_ALL_INVOICES,
  ROUTE_CREATE_INVOICE,
  ROUTE_LOGIN,
  ROUTE_NEW_APPOINTMENT,
  ROUTE_NEW_PATIENT,
  ROUTE_PRESCRIPTION,
} from "../shared/routes/route.constants";
import LoadingButton from "@mui/lab/LoadingButton";
import Carousel from "react-material-ui-carousel";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DateCalendar } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import { APIClient } from "../shared/Utils/api-client";
import Backdrop from "@mui/material/Backdrop";
import { setToggleRepopulateTable } from "../redux/reducers/prescription.slice";
import { getFeatureValue, useAuthorization } from "../shared/Utils/useAuthorization";

const initalState = {
  inActivityTimeout: 60000, // 1minute
  defaultRefreshInterval: 30000, // 30s
  currRefreshInterval: 30000, //
  setRefreshTimer: false,
  callInitiateLoading: false,
  apptRefreshTimer: null,
  isNfnDisabled: false, //if false, appt refreshes using notifications. if true autorefresh happens.
  show: false,
  loading: false,
  isRoleDoctor:
    localStorage.getItem("userRole")?.indexOf("doctor") !== -1 ||
    localStorage.getItem("userRole")?.indexOf("admin") !== -1,
  linkedDoctor: null,
  doctorList: null,

  firstTimeLoading: true,
  mobileNav: false,
  userIpAddress: null,
  alert: {
    type: "",
    title: "",
    text: "",
  },
  appointmentConfig: null,
  queryParams: {},
  smsStatusData: null,
  isSmsModalOpen: false,
  smsStatus: "",
  isTriggerLoading: false,
  listLoading: false,
  isOnlineMode: false,
  isAfterCallModalOpen: false,

  callAppointmentData: null,
  selectedAppointment: null,
  isLinkModalOpen: false,
  appointmentFilter: "30",
  open: false,
  selectClinic: "svaas",
  start_date: "2022-09-17",
  end_date: "2024-10-30",
  table_tab: "Scheduled",
};

const MobileFilterButton =[
  {
    id:0,
    name:"All",
    value:"30"
  },
  {
    id:1,
    name:"Today",
    value:"0"
  },
  {
    id:2,
    name:"Next 7 days",
    value:"7"
  }
]

const MobileFilterStyle = {
   filterButtonStyle:{
     border:"0.5px solid #DDD",
     background:"#ffff",
     borderRadius:"6px",
     color:"#313131",
     fontSize:"14px",
     fontWeight:600,
     lineHeight:"16px",
     textTransform:"none !important",
   },
   selectedFilterButtonStyle:{
      border:"1px solid #494E9D",
      borderRadius:"6px",
      background: "#EEEEF7 !important",
      color:"#494E9D",
      fontSize:"14px",
      fontWeight:600,
      lineHeight:"16px",
      textTransform:"none !important",
    },
   filterButtonGroupStyle:{
      display:"flex",
      gap:"8px",
      padding:"16px 0px"
   },
   footerStyle:{
     display:"flex",
     gap:"20px",
     padding:"16px"
   }
}

const mobileFilter = {
  dateValue: [dayjs(), dayjs()],
  selectClinic: "",
  visitTypeFilter: "",
  statusFilter: "",
  checkedClinic: true,
  checkedVideo: true,
};

const AppointmentsPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const {showArticleCard} = useSelector((state) => state.prescription);
  const {
    appoitments,
    confirmedAppoitments,
    loader,
    confirmedLoader,
    appointmentReasons,
    updateLoader,
    showMobileSearch,
  } = useSelector((state) => state.appointments);

  const { userDetails, profile, accountInfo, doctorsList, selectedDoctor } =
    useSelector((state) => state.auth);
  const [start_date, setStartDate] = useState(moment());
  const [end_date, setEndDate] = useState(moment());
  const [scheduledCount, setScheduledCount] = useState(0);
  const [, setInProcessCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [othersCount, setOthersCount] = useState(0);
  const [alignment, setAlignment] = React.useState("center");
  const [names, setNames] = useState([]);
  const [openNewAppointment, setOpenNewAppointment] = useState(false);
  let [searchValue, setSearchValue] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  // const [selectClinic, setSelectedClinic] = useState(null);
  const [_appoitments, setAllAppoitments] = useState([]);
  const [openNewPatient, setOpenNewPatient] = useState(false);
  const [countLoader, setCountLoader] = useState(true);
  const [reason, setReason] = useState(null);
  const [nextAppointmentTime, setNextAppointmentTime] = useState("7 Days");
  const [_confirmedAppoitments, setconfirmedAppointments] = useState([]);
  const [dateValue, setDateValue] = useState([dayjs(), dayjs()]); //from calender component
  const [selectedAppointment, _setSelectedAppointment] = useState(null);
  const [searchDate, setSearchDate] = useState(moment());
  const [calenderSearchDate, setCalenderSearchDate] = useState(moment());
  const [userIpAddress, setUserIdAddress] = useState("");
  const [activeCancel, setActiveCancel] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const is1350px = useMediaQuery("(min-width:1350px)");
  // const [firstEvaluatedKey, setFirstEvaluatedKey] = useState(null);
  // const [evaluatedKey, setEvaluatedKey] = useState(null);
  // const [prevEvaluatedKey,setPrevEvaluatedKey] = useState([]);
  let [visitTypeFilter, setVisitTypeFilter] = useState("");
  let [statusFilter, setStatusFilter] = useState("");
  let [checkedClinic, setCheckedClinic] = React.useState(true);
  let [checkedVideo, setCheckedVideo] = React.useState(true);

  const [showFilters, setShowFilters] = useState(false);

  const [triggerRerender, setTriggerRerender] = useState(0);

  const [isStaffLogin, setIsStaffLogin] = useState(false);
  // const [selectedDoctor,setSelectedDoctor] = useState('');
  const [backdrop, setBackdrop] = useState(false);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [disableMobileFilterButton, setDisableMobileFilterButton] = useState(true)
  const [mobileFilterValue, setMobileFilterValue] = useState('')
  const [selectedButtonId, setSelectedButtonId] = useState()

  const [selectedDateFilterValue, setSelectedDateFilterValue] = useState([
    dayjs(),
    dayjs(),
  ]);

  let abortController = new AbortController();
  let signal = abortController.signal;

  let confirmedAppointmentsAbortController = new AbortController();
  let confirmedAppointmentsSignal = confirmedAppointmentsAbortController.signal;

  const [openDatePickerModal, setOpenDatePickerModal] = useState(false);
  const [state, setState] = useReducer((state, action) => {
    return {
      ...state,
      ...action,
    };
  }, initalState);

  const [mobileFilterState, setMobileFilterState] = useReducer(
    (state, action) => {
      return {
        ...state,
        ...action,
      };
    },
    mobileFilter
  );

  const [isSearch, setIsSearch] = useState(false);

  let evaluatedKey = useRef(null);
  let prevEvaluatedKey = useRef([]);
  const setEvaluatedKey = (val) => {
    evaluatedKey.current = val;
  };
  const setPrevEvaluatedKey = (val) => {
    prevEvaluatedKey.current = val;
  };

  const selectClinic = useRef(null);
  const setSelectedClinic = (val) => {
    selectClinic.current = val;
    setMobileFilterState({ selectClinic: val });
  };

  const [accountIdMap,setAccountIdMap] = useState([]);

  const userFeatures = useAuthorization();

  useEffect(()=>{
    console.log("accountIdMap",accountIdMap)
  },[accountIdMap])

  useEffect(()=>{
    if(!selectedDoctor && doctorsList.length > 0){
      handleChangeDoctor(doctorsList[0].cognito_user_sub)
    }
  },[doctorsList])

  useEffect(() => {
    if (accountInfo?.account_id) {
      setMobileFilterState({
        selectClinic: accountInfo?.account_id,
      });
      // setSelectedClinic(accountInfo?.account_id)
    }
  }, [accountInfo?.account_id]);

  useEffect(() => {
    if (statusFilter !== "" || visitTypeFilter !== "") {
      if (accountInfo?.account_id) {
        getConfirmedAppintments(accountInfo?.account_id, 10, false);
        getAppointmentCountData(accountInfo?.account_id, false);
      }
    }
  }, [statusFilter, visitTypeFilter]);

  useEffect(() => {
    getConfirmedAppintments(accountInfo?.account_id, 10, false);
    getAppointmentCountData(accountInfo?.account_id, false);
  }, [checkedClinic, checkedVideo]);

  useEffect(() => {
    getClinicData();
  }, [profile, userDetails, dispatch]);

  useEffect(() => {
    getIPAddress().then((ip) => setUserIdAddress(ip));
    ResetMobileFilters();
  }, []);

  useEffect(() => {
    if (accountInfo?.account_id) {
      setEvaluatedKey(null);
      setPrevEvaluatedKey([]);
      getConfirmedAppintments(accountInfo?.account_id);
      // getAppointmentCountData(accountInfo?.account_id, false);
    }
  }, [state.table_tab]);

  useEffect(() => {
    getAppointmentCountData(accountInfo?.account_id, false);
  }, [alignment, start_date, end_date]);

  useEffect(() => {
    setconfirmedAppointments(confirmedAppoitments);
    // filterData(checkedClinic, checkedVideo);
    setEvaluatedKey(confirmedAppoitments?.last_evaluatedKey);
  }, [confirmedAppoitments]);

  useEffect(() => {
    setAllAppoitments(appoitments);
  }, [appoitments]);

  useEffect(() => {
    if (searchValue === "") {
      setIsSearch(false);
      setEvaluatedKey(null);
      setPrevEvaluatedKey([]);
      getConfirmedAppintments(accountInfo?.account_id);
      getAppointmentCountData(accountInfo?.account_id, false);
    }
  }, [searchValue]);

  useEffect(() => {
    // const isStatusAllowed = (status) =>
    //   ["booked", "checked-in", "consulting", "scheduled"].includes(status);
    // setScheduledCount(
    //   _confirmedAppoitments?.rows?.filter((appointment) =>
    //     isStatusAllowed(appointment.appointment_status.toLowerCase())
    //   ).length
    // );
    setInProcessCount(
      _confirmedAppoitments?.rows?.filter(
        (a) => a.appointment_status.toLowerCase() === "consulting"
      ).length
    );
    // setCompletedCount(
    //   _confirmedAppoitments?.rows?.filter(
    //     (a) => a.appointment_status.toLowerCase() === "done"
    //   ).length
    // );

    // const isStatusNotAllowed = (status) =>
    //   !["booked", "checked-in", "consulting", "scheduled", "done"].includes(
    //     status
    //   );
    // setOthersCount(
    //   _confirmedAppoitments?.rows?.filter((appointment) =>
    //     isStatusNotAllowed(appointment.appointment_status.toLowerCase())
    //   ).length
    // );
  }, [_confirmedAppoitments?.rows]);

  useEffect(() => {
    if (isStaffLogin && userDetails?.attributes?.sub) {
      dispatch(fetchDoctorsList(userDetails?.attributes?.sub));
    }
  }, [isStaffLogin,selectClinic.current]);

  useEffect(() => {
    setEvaluatedKey(null);
    setPrevEvaluatedKey([]);
    getConfirmedAppintments(accountInfo?.account_id);
    getAppointmentCountData(accountInfo?.account_id, false);
  }, [selectedDoctor]);

  useEffect(() => {
    if (
      userDetails?.attributes["custom:role"] &&
      userDetails?.attributes["custom:role"].includes("r:staff")
    ) {
      setIsStaffLogin(true);
    }
  }, [userDetails?.attributes]);

  useEffect(() => {
    setMobileFilterState({
      dateValue: dateValue,
      visitTypeFilter: visitTypeFilter,
      statusFilter: statusFilter,
      checkedClinic: checkedClinic,
      checkedVideo: checkedVideo,
    });
  }, [visitTypeFilter, statusFilter, checkedClinic, checkedVideo, dateValue]);

  //functions

  const handleChangeDoctor = (val) => {
    if (val === "none") {
      dispatch(setSelectedDoctor(""));
      return;
    }
    dispatch(setSelectedDoctor(val));
  };

  const getClinicData = () => {
    const dListRaw = localStorage.getItem("uAccounts");
    let accountNames = [];
    let accountsMap= [];

    if (profile) {
      if (dListRaw) {
        try {
          const parsedData = JSON.parse(dListRaw);

          if (
            parsedData &&
            typeof parsedData === "object" &&
            parsedData.account_ids
          ) {
            const accountIds = JSON.parse(parsedData.account_ids);
            accountsMap = Object.keys(accountIds).map(elem=>{
              return {
                label:accountIds[elem],
                value:elem
              }
            })

            if (Array.isArray(accountIds)) {
              accountNames = accountIds;
              
            } else if (typeof accountIds === "object") {
              
              setAccountIdMap(accountsMap)
              dispatch(setClinicName(accountsMap))
              accountNames = Object.values(accountIds)
            } else {
              accountNames.push(accountIds);
            }
          }
        } catch (e) {
          console.error("Error parsing session data:", e);
        }
      } else if (profile?.account_list) {
        accountNames = profile.account_list;
      }
      
      setNames(accountNames);

      const savedClinic = localStorage.getItem("cAccount");
      const clinicToSelect =
        savedClinic && accountsMap.find(elem=>elem.value===savedClinic)
          ? savedClinic
          : accountNames.length > 0
          ? accountsMap.find(elem=>elem.label==accountNames[0])?.value
          : null;

      if (clinicToSelect) {
        setSelectedClinic(clinicToSelect);
        localStorage.setItem("cAccount", clinicToSelect);
        dispatch(
          fetchAccountDetails({
            doctor_id: selectedDoctor
              ? selectedDoctor
              : userDetails?.attributes?.sub,
            account_id: clinicToSelect,
          })
        );
        getAllAppointmentData(clinicToSelect);
      }
    }
  };

  const handleAlignment = async (newAlignment, value) => {
    if (loader || confirmedLoader || updateLoader) return;

    if (value !== null) {
      setOthersCount(0);
      setScheduledCount(0);
      setInProcessCount(0);
      setOthersCount(0);
      setCompletedCount(0);

      ResetMobileFilters();

      setconfirmedAppointments([]);
      let start_date = moment().format("YYYY-MM-DD");

      setAlignment(value);

      if (value === "left") {
        handleFilterReset();
        setPrevEvaluatedKey([]);
        setStartDate(start_date);
        setEndDate(moment(start_date).add("days", "6").format("YYYY-MM-DD"));
        dispatch(
          fetchAllConfirmedApponitments({
            id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
            params: {
              start_date: start_date,
              end_date: moment(start_date)
                .add("days", "6")
                .format("YYYY-MM-DD"),
              appt_status: "confirmed",
              account_id: selectClinic.current,
              show_next_appt: true,
            },
          })
        );
      } else {
        setStartDate(start_date);
        setEndDate(start_date);
        const response = await dispatch(
          fetchAllConfirmedApponitments({
            id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
            params: {
              start_date: start_date,
              end_date: start_date,
              appt_status: "confirmed",
              show_next_appt: true,
              account_id: selectClinic.current,
              limit: 10,
              status: state?.table_tab,
              ...(!checkedClinic &&
                checkedVideo && { appointment_type: "online" }),
              ...(checkedClinic &&
                !checkedVideo && { appointment_type: "offline" }),
              ...(statusFilter && statusFilter !== "all"
                ? { appointment_status: statusFilter }
                : {}),
              ...(visitTypeFilter && visitTypeFilter !== "all"
                ? { visit_type: visitTypeFilter }
                : {}),
            },
          })
        );

        setEvaluatedKey(response?.payload?.data?.last_evaluatedKey);
      }

      setSearchDate(moment());
      setSearchValue("");
    }
  };

  const handleAppointmentFilterChange = (val) => {
    if (val !== null) {
      setState({ appointmentFilter: val });
      dispatch(
        fetchAllApponitments({
          id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
          params: {
            start_date:
              val === "7"
                ? moment(start_date).add("days", "1").format("YYYY-MM-DD")
                : moment(start_date).format("YYYY-MM-DD"),
            end_date: moment(start_date)
              .add("days", val?.toString())
              .format("YYYY-MM-DD"),
            appt_status: "not_confirmed",
            account_id: selectClinic.current,
          },
        })
      );
    }
  };

  async function getIPAddress() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  }

  const handleChangeClinic = (event) => {
    setEvaluatedKey(null);
    setPrevEvaluatedKey([]);
    searchValue = "";
    setSearchValue("");
    localStorage.setItem("cAccount", event?.target?.value);
    checkedClinic = true;
    setCheckedClinic(true);
    checkedVideo = true;
    setCheckedVideo(true);
    statusFilter = "";
    setStatusFilter("");
    visitTypeFilter = "";
    setVisitTypeFilter("");
    setAlignment("center");
    dispatch(resetAppointments());
    setSelectedClinic(event?.target?.value);
    getAllAppointmentData(event?.target?.value);
    dispatch(
      fetchAccountDetails({
        doctor_id: selectedDoctor
          ? selectedDoctor
          : userDetails?.attributes?.sub,
        account_id: event?.target?.value,
      })
    );
  };

  const handleNewPatientClick = () => {
    // setOpenNewPatient((prev) => !prev);
    navigate(`${ROUTE_NEW_PATIENT}`);
  };
  const handleNewAppointmentClick = () => {
    dispatch(setIsReschedule(false));
    navigate(ROUTE_NEW_APPOINTMENT);
    // setState({ isReschedule: false });
    // setOpenNewAppointment((prev) => !prev);
  };

  const getPendingAppointment = async (key) => {
    const response = await dispatch(
      fetchAllApponitments({
        id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
        params: {
          start_date:
            state.appointmentFilter === "7"
              ? moment(start_date).add("days", "1").format("YYYY-MM-DD")
              : moment(start_date).format("YYYY-MM-DD"),
          end_date: moment(start_date)
            .add("days", state.appointmentFilter)
            .format("YYYY-MM-DD"),
          appt_status: "not_confirmed",
          account_id: key,
        },
      })
    );

    if (response?.payload?.status === undefined) {
      localStorage.removeItem("emrToken");
      localStorage.clear();
      dispatch(resetAppointments());
      dispatch(logout());
      window.location.href = `${ROUTE_LOGIN}`;
    }
  };

  const getConfirmedAppintments = async (key, limit, isLast, isPrev) => {
    if (!key) {
      return;
    }

    const reg = new RegExp("^[0-9]+$");
    let isPhone = false;
    if (reg.test(searchValue)) {
      isPhone = true;
    }

    confirmedAppointmentsAbortController.abort();
    confirmedAppointmentsAbortController = new AbortController();
    confirmedAppointmentsSignal = confirmedAppointmentsAbortController.signal;

    const response = await dispatch(
      fetchAllConfirmedApponitments({
        id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
        params: {
          start_date: moment(start_date.toString()).format("YYYY-MM-DD"),
          end_date: moment(end_date.toString()).format("YYYY-MM-DD"),
          appt_status: "confirmed",
          show_next_appt: true,
          account_id: key,
          limit: limit ? limit : 10,
          status: state?.table_tab,
          ...(!checkedClinic && checkedVideo && { appointment_type: "online" }),
          ...(checkedClinic &&
            !checkedVideo && { appointment_type: "offline" }),
          ...(isLast !== false &&
          (isPrev === true
            ? prevEvaluatedKey.current[prevEvaluatedKey.current.length - 1]
            : evaluatedKey.current)
            ? {
                last_evaluatedKey:
                  isPrev === true
                    ? prevEvaluatedKey.current[
                        prevEvaluatedKey.current.length - 1
                      ]
                    : evaluatedKey.current,
              }
            : {}),

          ...(statusFilter && statusFilter !== "all"
            ? { appointment_status: statusFilter }
            : {}),
          ...(visitTypeFilter && visitTypeFilter !== "all"
            ? { visit_type: visitTypeFilter }
            : {}),
          ...(searchValue && !isPhone ? { patient_name: searchValue } : {}),
          ...(searchValue && isPhone ? { patient_mobile: searchValue } : {}),
        },
        signal: confirmedAppointmentsSignal,
      })
    );

    if (statusFilter === "all") {
      setStatusFilter("");
    }
    if (visitTypeFilter === "all") {
      setVisitTypeFilter("");
    }

    setEvaluatedKey(response?.payload?.data?.last_evaluatedKey);
    // if (response?.payload?.status === undefined) {
    //   localStorage.removeItem("emrToken");
    //   localStorage.clear();
    //   dispatch(resetAppointments());
    //   dispatch(logout());
    //   window.location.href = `${ROUTE_LOGIN}`;
    // }
  };

  const getAllAppointmentData = async (key) => {
    getPendingAppointment(key);
    getConfirmedAppintments(key);
    getAppointmentCountData(key);
  };

  const setAppoitmentStatus = async (key, data) => {
    let details = new UAParser().getResult();

    let event = {
      appointment_status: key === "start" ? "Consulting" : key,
      is_svaas_appt: true,
      ip_address: userIpAddress,
      browser_info: {
        browser: details.browser,
        OS: details.os,
        device: details.device,
        cpu: details.cpu,
        engine: details.engine,
      },
    };

    if (reason) {
      event.remarks = reason;
    }

    if (key === "viewrx") {
      dispatch(
        fetchDoctorTemplates({
          params: {
            doctor_id: selectedDoctor
              ? selectedDoctor
              : userDetails?.attributes?.sub,
            // thb_appt_id : userDetails
            multi: true,
          },
        })
      );
      navigate(`/${ROUTE_PRESCRIPTION(data?.id)}?showVideo=false&backbutton=true`);
      return;
    }

    if (key === "resume") {
      dispatch(
        fetchDoctorTemplates({
          params: {
            doctor_id: selectedDoctor
              ? selectedDoctor
              : userDetails?.attributes?.sub,
            multi: true,
          },
        })
      );

      if (data?.appointment_type === "offline") {
        navigate(
          `/${ROUTE_PRESCRIPTION(data?.id)}?showVideo=false&backbutton=true`
        );
      } else {
        navigate(
          `/${ROUTE_PRESCRIPTION(data?.id)}?showVideo=true&backbutton=true`
        );
      }

      return;
    }

    if (key === "start" || key === "Consulting") {
      dispatch(setToggleRepopulateTable(true));
      dispatch(
        fetchDoctorTemplates({
          params: {
            doctor_id: selectedDoctor
              ? selectedDoctor
              : userDetails?.attributes?.sub,
            multi: true,
          },
        })
      );

      const _data = await dispatch(
        updateApponitment({ id: data?.id, body: event })
      );

      if (_data?.payload?.status === 200) {
        if (data?.appointment_type === "offline") {
          navigate(
            `/${ROUTE_PRESCRIPTION(data?.id)}?showVideo=false&backbutton=true`
          );
        } else {
          navigate(
            `/${ROUTE_PRESCRIPTION(data?.id)}?showVideo=true&backbutton=true`
          );
        }
      } else {
        setErrorMsg("Something Went Wrong");
        setShowError(true);
        return;
      }

      return;
    } else {
      const _data = await dispatch(
        updateApponitment({ id: data?.id, body: event })
      );

      if (_data?.payload?.status === 200) {
        // window.location.reload();
        setPrevEvaluatedKey([]);
        setEvaluatedKey(null);
        getAllAppointmentData(selectClinic.current);
        setCancelModal(false);
        setNextAppointmentTime("7 Days");
        setReason(null);
      } else {
        setErrorMsg("Something Went Wrong");
        setShowError(true);
        return;
      }
    }
  };

  const filterData = (clinic, video) => {
    setconfirmedAppointments({
      rows: confirmedAppoitments?.rows?.filter((appointment) => {
        if (video && appointment.appointment_type === "online") {
          return true;
        } else if (clinic && appointment.appointment_type !== "online") {
          return true;
        }
        return false;
      }),
    });
  };

  const tableFilterData = (key) => {
    let _data = confirmedAppoitments?.rows.slice();
    if (key === "patient") {
      setconfirmedAppointments({
        ...confirmedAppoitments,
        rows: _data?.sort((a, b) => {
          const nameA = a?.patient?.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b?.patient?.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names must be equal
        }),
      });
    }
    if (key === "status") {
      setconfirmedAppointments({
        ...confirmedAppoitments,
        rows: _data?.sort((a, b) => {
          const nameA = a?.appointment_status.toUpperCase(); // ignore upper and lowercase
          const nameB = b?.appointment_status.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names must be equal
        }),
      });
    }

    if (key === "time") {
      setconfirmedAppointments({
        ...confirmedAppoitments,
        rows: _data?.sort((a, b) => {
          const timeA = new Date(a.appointment_slot);
          const timeB = new Date(b.appointment_slot);
          return timeA - timeB; // sorts in ascending order of visit times
        }),
      });
    }
  };

  const getAppointmentCountData = (key, isLast) => {
    if (!key) {
      return;
    }

    abortController.abort();
    abortController = new AbortController();
    signal = abortController.signal;

    const reg = new RegExp("^[0-9]+$");
    let isPhone = false;
    if (reg.test(searchValue)) {
      isPhone = true;
    }

    setScheduledCount(0);
    setCompletedCount(0);
    setOthersCount(0);

    setCountLoader(true);
    APIClient.get(
      `appointment/appointments/countbyrange/${
        selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub
      }`,
      {
        params: {
          start_date: moment(start_date.toString()).format("YYYY-MM-DD"),
          end_date: moment(end_date.toString()).format("YYYY-MM-DD"),
          appt_status: "confirmed",
          show_next_appt: true,
          account_id: key,
          // limit: limit?limit:10,
          status: state?.table_tab,
          ...(!checkedClinic && checkedVideo && { appointment_type: "online" }),
          ...(checkedClinic &&
            !checkedVideo && { appointment_type: "offline" }),
          ...(statusFilter && statusFilter !== "all"
            ? { appointment_status: statusFilter }
            : {}),
          ...(visitTypeFilter && visitTypeFilter !== "all"
            ? { visit_type: visitTypeFilter }
            : {}),
          ...(searchValue && !isPhone ? { patient_name: searchValue } : {}),
          ...(searchValue && isPhone ? { patient_mobile: searchValue } : {}),
        },
      },
      signal
    ).then((res) => {
      if (res?.status === 200) {
        setScheduledCount(res?.data?.count_scheduled);
        setCompletedCount(res?.data?.count_completed);
        setOthersCount(res?.data?.count_others);
        setCountLoader(false);
      }
    });

    if (statusFilter === "all") {
      setStatusFilter("");
    }
    if (visitTypeFilter === "all") {
      setVisitTypeFilter("");
    }
  };

  const filterSearchOnChange = (key) => {
    setSearchValue(key);
  };

  const getAppointmentData = (key) => {
    setSearchValue(key);

    if (key.length < 3) {
      // Reset to the initial data
      let _data = confirmedAppoitments?.rows?.filter((a) => {
        let isVideo = checkedVideo;
        let isClinic = checkedClinic;

        if (
          (isVideo && a?.appointment_type === "online") ||
          (isClinic && a?.appointment_type === "offline")
        ) {
          return true;
        }

        return false;
      });

      setconfirmedAppointments({
        rows: _data,
      });
    } else {
      let _data = confirmedAppoitments?.rows?.filter((a) => {
        let name = a?.patient?.name?.toLowerCase();
        let _key = key?.toLowerCase();
        let isVideo = checkedVideo;
        let isClinic = checkedClinic;

        if (
          (isVideo && a?.appointment_type === "online") ||
          (isClinic && a?.appointment_type === "offline")
        ) {
          return name?.includes(_key) || a?.patient?.phone.includes(key);
        }

        return false;
      });

      setconfirmedAppointments({
        rows: _data,
      });
    }
  };

  const getDateValue = (key) => {
    setDateValue(key);
    setOthersCount(0);
    setScheduledCount(0);
    setCompletedCount(0);
    setInProcessCount(0);
    setOthersCount(0);

    if (!key) {
      return;
    }

    if (key[0] && key[1] === null) {
      return;
    }
    // if (key[1]?.format("DD/MM/YYYY") === dayjs().format("DD/MM/YYYY")) {
    //   return;
    // }

    if (key && key[1] !== null) {
      setPrevEvaluatedKey([]);
      setSearchDate(moment(key[0].toString()));
      setStartDate(key[0]);
      setEndDate(key[1]);
      APIClient.get(
        `appointment/appointments/countbyrange/${
          selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub
        }`,
        {
          params: {
            start_date: moment(key[0].toString()).format("YYYY-MM-DD"),
            end_date: moment(key[1].toString()).format("YYYY-MM-DD"),
            appt_status: "confirmed",
            show_next_appt: true,
            account_id: selectClinic.current,
            limit: 10,
            status: state?.table_tab,
            ...(!checkedClinic &&
              checkedVideo && { appointment_type: "online" }),
            ...(checkedClinic &&
              !checkedVideo && { appointment_type: "offline" }),
            // ...(prevEvaluatedKey.length && prevEvaluatedKey[prevEvaluatedKey.length - 1] ? { last_evaluatedKey: prevEvaluatedKey[prevEvaluatedKey.length - 1] } : {}),
            ...(statusFilter ? { appointment_status: statusFilter } : {}),
            ...(visitTypeFilter ? { visit_type: visitTypeFilter } : {}),
          },
        }
      ).then((res) => {
        if (res?.status === 200) {
          setScheduledCount(res?.data?.count_scheduled);
          setCompletedCount(res?.data?.count_completed);
          setOthersCount(res?.data?.count_others);
        }
      });

      dispatch(
        fetchAllConfirmedApponitments({
          id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
          params: {
            start_date: moment(key[0].toString()).format("YYYY-MM-DD"),
            end_date: moment(key[1].toString()).format("YYYY-MM-DD"),
            appt_status: "confirmed",
            account_id: selectClinic.current,
            show_next_appt: true,
            limit: alignment === "left" && isMobileScreen ? undefined : 10,
            status:
              alignment === "left" && isMobileScreen
                ? undefined
                : state?.table_tab,
            ...(!checkedClinic &&
              checkedVideo && { appointment_type: "online" }),
            ...(checkedClinic &&
              !checkedVideo && { appointment_type: "offline" }),
            ...(statusFilter ? { appointment_status: statusFilter } : {}),
            ...(visitTypeFilter ? { visit_type: visitTypeFilter } : {}),
          },
        })
      );
    }
  };

  const getBackDateAppointments = (key) => {
    setOthersCount(0);
    setScheduledCount(0);
    setInProcessCount(0);
    setCompletedCount(0);
    setOthersCount(0);
    if (key === "1") {
      let _key = moment(searchDate).subtract("days", key);
      setStartDate(_key);
      setEndDate(_key);
      setDateValue([dayjs(_key.toString()), dayjs(_key.toString())]);
      setSearchDate(_key);
      dispatch(
        fetchAllConfirmedApponitments({
          id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
          params: {
            start_date: moment(_key).format("YYYY-MM-DD"),
            end_date: moment(_key).format("YYYY-MM-DD"),
            appt_status: "confirmed",
            account_id: selectClinic.current,
            show_next_appt: true,
            // limit: 10,
            status: state?.table_tab,
            ...(statusFilter ? { appointment_status: statusFilter } : {}),
            ...(visitTypeFilter ? { visit_type: visitTypeFilter } : {}),
          },
        })
      );
    } else {
      let _key = moment(calenderSearchDate).subtract("days", key);
      let _next = moment(_key).add("days", "6");
      setStartDate(_key);
      setEndDate(_next);
      setCalenderSearchDate(_key);
      setconfirmedAppointments([]);
      dispatch(
        fetchAllConfirmedApponitments({
          id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
          params: {
            start_date: moment(_key).format("YYYY-MM-DD"),
            end_date: moment(_next).format("YYYY-MM-DD"),
            appt_status: "confirmed",
            account_id: selectClinic.current,
            show_next_appt: true,
          },
        })
      );
    }
  };

  const getNextDateAppointments = (key) => {
    setOthersCount(0);
    setScheduledCount(0);
    setInProcessCount(0);
    setCompletedCount(0);
    setOthersCount(0);
    setconfirmedAppointments([]);

    if (key === "1") {
      let _key = moment(searchDate).add("days", key);
      setSearchDate(_key);
      setStartDate(_key);
      setDateValue([dayjs(_key.toString()), dayjs(_key.toString())]);
      setEndDate(_key);
      dispatch(
        fetchAllConfirmedApponitments({
          id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
          params: {
            start_date: moment(_key).format("YYYY-MM-DD"),
            end_date: moment(_key).format("YYYY-MM-DD"),
            appt_status: "confirmed",
            account_id: selectClinic.current,
            show_next_appt: true,
            // limit: 10,
            status: state?.table_tab,
            ...(statusFilter ? { appointment_status: statusFilter } : {}),
            ...(visitTypeFilter ? { visit_type: visitTypeFilter } : {}),
          },
        })
      );
    } else {
      let _key = moment(calenderSearchDate).add("days", key);
      let _next = moment(_key).add("days", "6");
      setStartDate(_key);
      setEndDate(_next);
      setCalenderSearchDate(_key);
      dispatch(
        fetchAllConfirmedApponitments({
          id: selectedDoctor ? selectedDoctor : userDetails?.attributes?.sub,
          params: {
            start_date: moment(_key).format("YYYY-MM-DD"),
            end_date: moment(_next).format("YYYY-MM-DD"),
            appt_status: "confirmed",
            account_id: selectClinic.current,
            show_next_appt: true,
          },
        })
      );
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "14px",
    boxShadow: 24,
    p: 4,
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "14px",
    boxShadow: 24,
    p: 2,
  };
  const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 280,
    bgcolor: "background.paper",
    borderRadius: "14px",
    boxShadow: 24,
    p: 4,
  };
  const setMoreOptions = (key, data) => {
    setEvaluatedKey(null);
    setPrevEvaluatedKey([]);

    let _data = _confirmedAppoitments?.rows?.find(
      (key) => key?.id === data?.id
    );
    if (
      key === "Prescription" ||
      key === "LastPrescription"
    ) {
      if (key === "LastVisit") {
        dispatch(setToggleRepopulateTable(true));
      }
      dispatch(
        fetchDoctorTemplates({
          params: {
            doctor_id: selectedDoctor
              ? selectedDoctor
              : userDetails?.attributes?.sub,
            multi: true,
          },
        })
      );
      navigate(`/${ROUTE_PRESCRIPTION(data?.id)}`);
      return;
    }
    if (key === "Timeline" || key === "History") {
      dispatch(
        fetchDoctorTemplates({
          params: {
            doctor_id: selectedDoctor
              ? selectedDoctor
              : userDetails?.attributes?.sub,
            multi: true,
          },
        })
      );
      navigate(
        `/${ROUTE_PRESCRIPTION(
          data?.id
        )}?showVideo=false&timeline=true&backbutton=true`
      );
      return;
    }
    if (key === "LastVisit") {
      dispatch(
        fetchDoctorTemplates({
          params: {
            doctor_id: selectedDoctor
              ? selectedDoctor
              : userDetails?.attributes?.sub,
            multi: true,
          },
        })
      );
      navigate(
        `/${ROUTE_PRESCRIPTION(
          data?.id
        )}?showVideo=false&lastVisit=true&backbutton=true`
      );
      return;
    }
    if(key === "RxView"){
      dispatch(
        fetchDoctorTemplates({
          params: {
            doctor_id: selectedDoctor
              ? selectedDoctor
              : userDetails?.attributes?.sub,
            multi: true,
          },
        })
      );
      navigate(
        `/${ROUTE_PRESCRIPTION(
          data?.id
        )}?backbutton=true`
      );
      return;
    }
    if (key === "Uploads") {
      dispatch(
        fetchDoctorTemplates({
          params: {
            doctor_id: selectedDoctor
              ? selectedDoctor
              : userDetails?.attributes?.sub,
            multi: true,
          },
        })
      );
      navigate(
        `/${ROUTE_PRESCRIPTION(
          data?.id
        )}?showVideo=false&document=true&backbutton=true`
      );
      return;
    }

    if (key === "Cancelled") {
      setActiveCancel("Cancel");
      setCancelModal(true);
      _setSelectedAppointment(data);
      dispatch(fetchAppointmentType("cancelled"));
      return;
    }
    if (key === "Declined") {
      setActiveCancel("Decline");
      setCancelModal(true);
      _setSelectedAppointment(data);
      dispatch(fetchAppointmentType("declined"));
      return;
    }
    if (key === "Re-Scheduled") {
      dispatch(setIsReschedule(true));
      dispatch(setRescheduleData({ ..._data?.patient, id: _data?.id }));

      navigate(ROUTE_NEW_APPOINTMENT);

      // setState({
      //   isReschedule: true,
      //   rescheduleData: { ..._data?.patient, id: _data?.id },
      // });
      // setOpenNewAppointment(true);
      return;
    }
    if (key === "Checked-In" || key === "Consulting" || key === "Done") {
      setAppoitmentStatus(key, data);
      return;
    }
    if (key === "BookAppointment" || key === "Follow-up") {
      if(key === "Follow-up"){
        localStorage.setItem('isFollowUp',data?.id);
      }
      dispatch(setIsReschedule(false));
      dispatch(setRescheduleData({ ..._data?.patient, id: _data?.id }));

      navigate(ROUTE_NEW_APPOINTMENT);

      // setState({
      //   isReschedule: false,
      //   rescheduleData: { ..._data?.patient, id: _data?.id },
      // });
      // setOpenNewAppointment(true);
      return;
    } 
    if(key === "ViewInvoice"){
      console.log("ViewInvoice",data)
      if(data?.patient_id){
        navigate({
          pathname: "/"+ROUTE_ALL_INVOICES,
          search: createSearchParams({
              patient_id: data?.patient_id,
  
          }).toString()
        });
      }
      
    }
    if(key === "CreateInvoice"){
      navigate({
        pathname: "/"+ROUTE_CREATE_INVOICE,
        search: createSearchParams({
            account_id: data?.account_id,
            appointment_id: data?.id,
            patient_id: data?.patient_id,
            doctor_id: data?.doctor_id

        }).toString()
      });
    }
    else {
      dispatch(setSelectedAppointment(data));
    }
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(0.2),
      height: 30,
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));

  const handleFilterReset = () => {
    statusFilter = "";
    visitTypeFilter = "";
    checkedClinic = true;
    checkedVideo = true;

    setSearchValue("");
    setStatusFilter("");
    setVisitTypeFilter("");
    setCheckedClinic(true);
    setCheckedVideo(true);
    getDateValue([dayjs(), dayjs()]);
  };

  const _handleChangeClinic = (event) => {
    if (!event.target.checked) {
      setCheckedVideo(true);
    }
    setCheckedClinic(event.target.checked);
  };

  const handleChangeVideo = (event) => {
    if (!event.target.checked) {
      setCheckedClinic(true);
    }
    setCheckedVideo(event.target.checked);
  };

  const handleApplyMobileFilters = () => {
    setSelectedClinic(mobileFilterState.selectClinic);
    localStorage.setItem("cAccount", mobileFilterState.selectClinic);
    dispatch(
      fetchAccountDetails({
        doctor_id: selectedDoctor
          ? selectedDoctor
          : userDetails?.attributes?.sub,
        account_id: mobileFilterState.selectClinic,
      })
    );

    visitTypeFilter = mobileFilterState.visitTypeFilter;
    statusFilter = mobileFilterState.statusFilter;
    checkedClinic = mobileFilterState.checkedClinic;
    checkedVideo = mobileFilterState.checkedVideo;

    getDateValue(selectedDateFilterValue);
    getPendingAppointment(selectClinic.current);

    setVisitTypeFilter(mobileFilterState.visitTypeFilter);
    setStatusFilter(mobileFilterState.statusFilter);
    setCheckedClinic(mobileFilterState.checkedClinic);
    setCheckedVideo(mobileFilterState.checkedVideo);
  };

  const ResetMobileFilters = () => {
    setMobileFilterState({
      dateValue: [dayjs(), dayjs()],
      selectClinic: accountIdMap.length ? accountIdMap[0]?.value : "",
      visitTypeFilter: "",
      statusFilter: "",
      checkedClinic: true,
      checkedVideo: true,
    });
    setSelectedDateFilterValue([dayjs(), dayjs()]);
  };

  const shortcutsItems = [
    {
      label: "Today",
      getValue: () => {
        const today = dayjs();
        return [today, today];
      },
    },
    {
      label: "Yesterday",
      getValue: () => {
        const today = dayjs();
        const yesterday = today.subtract(1, "day");
        return [yesterday, yesterday];
      },
    },
    {
      label: "This Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "Past Month",
      getValue: () => {
        const today = dayjs();
        const pastMonth = today.subtract(1, "month");
        return [pastMonth.startOf("month"), pastMonth.endOf("month")];
      },
    },
    {
      label: "Past 3 Months",
      getValue: () => {
        const today = dayjs();
        const pastMonth = today.subtract(1, "month");
        const past3Months = pastMonth.subtract(2, "month");
        return [past3Months.startOf("month"), pastMonth.endOf("month")];
      },
    },
  ];
  const actions = [
    {
      icon: (
        <Box
          width={{ md: 30 }}
          component="img"
          alt="add appointment"
          src="/images/calenderIcon2.svg"
        />
      ),
      name: "Add Appointment",
      permission:'CREATE_APPOINTMENT',
      action: () => {
        // setOpenNewAppointment(true);
        navigate(ROUTE_NEW_APPOINTMENT);
      },
    },
    {
      icon: (
        <Box
          width={{ md: 25 }}
          component="img"
          alt="add patient"
          src="/images/addpatientCTA.svg"
        />
      ),
      name: "Add Patient",
      permission:"REGISTER_USER",
      action: () => {
        // setOpenNewPatient(true);
        navigate(ROUTE_NEW_PATIENT);
      },
    },
  ];

  const statusTypes = [
    {
      label: "Scheduled",
      value: "Booked",
      backgroundColor: theme.palette.blue.light,
      borderColor: theme.palette.blue.main,
    },
    {
      label: "Checked-In",
      value: "Checked-In",
      backgroundColor: theme.palette.blue.light,
      borderColor: theme.palette.blue.main,
    },
    {
      label: "In-Process",
      value: "Consulting",
      backgroundColor: theme.palette.green.light,
      borderColor: theme.palette.green.main,
    },
    {
      label: "Completed",
      value: "Done",
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
    },
    {
      label: "Cancelled",
      value: "Cancelled",
      backgroundColor: theme.palette.red.light,
      borderColor: theme.palette.red.main,
    },
    {
      label: "Declined",
      value: "Declined",
      backgroundColor: theme.palette.red.light,
      borderColor: theme.palette.red.main,
    },
    {
      label: "No-Show",
      value: "No-Show",
      backgroundColor: theme.palette.red.light,
      borderColor: theme.palette.red.main,
    },
    {
      label: "Missed",
      value: "Missed",
      backgroundColor: theme.palette.red.light,
      borderColor: theme.palette.red.main,
    },
  ];

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        ContentProps={{
          sx: {
            background: "red",
          },
        }}
        open={showError}
        onClose={() => setShowError(false)}
        autoHideDuration={6000}
        message={errorMsg}
      />
      {isMobileScreen ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              padding: 1,
            }}
          >
            {/* <Fab
              color="primary"
              aria-label="add"
              sx={{ position: "fixed", bottom: 20, right: 20 }}
              onClick={() => setOpenNewAppointment(true)}
            >
              <AddIcon />
            </Fab> */}
            <SpeedDial
              ariaLabel="FAB"
              sx={{ position: "fixed", bottom: 20, right: 20, display: !(userFeatures.includes(getFeatureValue('CREATE_APPOINTMENT')) && userFeatures.includes(getFeatureValue('REGISTER_USER'))) ? 'none' : 'flex'}}
              icon={<SpeedDialIcon />}
              onClick={() => {
                setBackdrop((prev) => !prev);
              }}
            >
              <Backdrop open={backdrop} />
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  tooltipOpen={true}
                  icon={action.icon}
                  tooltipTitle={<Typography noWrap>{action.name}</Typography>}
                  onClick={action.action}
                  sx={{
                    display:userFeatures.includes(getFeatureValue(action.permission)) ? 'flex' : 'none'
                  }}
                />
              ))}
            </SpeedDial>

            {showMobileSearch && (
              <Box display={"flex"} justifyContent={"center"} mt={2}>
                <SmallSearchInput
                  disabled={alignment === "left" ? true : false}
                  label={"Search by Patient Name/Mobile No."}
                  value={searchValue}
                  getAppointmentData={(key) => filterSearchOnChange(key)}
                  getConfirmedAppintments={(limit) => {
                    setIsSearch(true);
                    setEvaluatedKey(null);
                    setPrevEvaluatedKey([]);
                    getConfirmedAppintments(
                      accountInfo?.account_id,
                      limit,
                      false
                    );
                    getAppointmentCountData(accountInfo?.account_id, false);
                  }}
                />
              </Box>
            )}

            <Box
              sx={{ mt: 2 }}
              display={
                !userFeatures.includes(getFeatureValue("ENABLE_AUTO_APPROVE"))
                  ? "block"
                  : "none"
              }
            >
              <Box
                className="filter-main-container"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "24px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      background: "rgba(55, 85, 170, 0.2)",
                    }}
                  >
                    <Box
                      component="img"
                      width="20px"
                      src="/images/calenderIcon2.svg"
                    />
                  </Box>
                 {appoitments?.rows?.length > 0 && <Box
                    sx={{ display: "flex", alignItems: "center", gap: "9px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#494E9D",
                      }}
                    >
                      {appoitments?.rows?.length ? appoitments?.rows?.length : 0}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#6F6F6F",
                      }}
                    >
                      To be Confirmed
                    </Typography>
                  </Box>}
                </Box>
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "4px",
                  }}
                >
                  <IconButton
                    disabled={loader || countLoader || confirmedLoader}
                    sx={{ padding: "6px" }}
                    onClick={() => {
                      setShowMobileFilter(!showMobileFilter);
                    }}
                  >
                    <FilterAltIcon color="primary" fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              {loader ? (
                <>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </>
              ) : _appoitments?.rows?.length === 0 ? (
                <Box
                  display={"flex"}
                  height={250}
                  gap={2}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    boxShadow: `0px 0px 10px 4px ${theme.palette.white.medium}`,
                  }}
                >
                  <Box
                    width={{ md: 80 }}
                    component="img"
                    alt="No Appointments"
                    src={"/images/noappointmentsImage2.svg"}
                    sx={{}}
                  />
                  <Typography fontWeight={"bold"} textAlign={"center"}>
                    There are no new appointments <br /> pending right now
                  </Typography>
                  <Typography
                    variant="caption"
                    textAlign={"center"}
                    color={theme.palette.white.dark}
                  >
                    SVAAS helps to manage and <br />
                    accept your appointments here
                  </Typography>
                </Box>
              ) : (
                _appoitments && (
                    <Carousel
                      animation={"slide"}
                      autoPlay={false}
                      activeIndicatorIconButtonProps={{
                        style: { color: theme.palette.primary.main,marginTop: '5px' },
                      }}
                      indicatorIconButtonProps={{
                        style: { color: theme.palette.primary.light },
                      }}
                      navButtonsAlwaysInvisible={true}
                    >
                      {_appoitments?.rows?.map((elem) => {
                        return (
                          <ActionCard
                            data={elem}
                            setCancelModal={(key, data) =>
                              setMoreOptions(key, data)
                            }
                            loader={loader}
                            accountInfo={accountInfo}
                            setAppoitmentStatus={(key, data, type) =>
                              setAppoitmentStatus(key, data, type)
                            }
                          />
                        );
                      })}
                    </Carousel>
                )
              )}
            </Box>

            {alignment === "left" ? (
              <Box
                sx={{
                  height: 50,
                  justifyContent: "space-between",
                  mt: 2,
                  display:userFeatures.includes(getFeatureValue('CONFIRMED_APPOINTMENTS')) ? 'flex' : 'none'
                }}
              >
                <Box display={"flex"} gap={2} height={"fit-content"}>
                  <Tooltip title={"Scheduled & In Progress"}>
                    <Box width={58} height={35} sx={{ cursor: "pointer" }}>
                      <GradientCard
                        text1={scheduledCount === 0 ? "-" : scheduledCount}
                        text2={"Scheduled"}
                        type={"mobileCalender"}
                      />
                    </Box>
                  </Tooltip>
                  <Box
                    width={58}
                    height={35}
                    sx={{ cursor: "pointer", marginLeft: 1 }}
                  >
                    <GradientCard
                      text1={completedCount === 0 ? "-" : completedCount}
                      text2={"Completed"}
                      type={"mobileCalender"}
                    />
                  </Box>
                  <Box
                    width={58}
                    height={35}
                    sx={{ cursor: "pointer", marginLeft: 1 }}
                  >
                    <GradientCard
                      text1={othersCount === 0 ? "-" : othersCount}
                      text2={"Others"}
                      type={"mobileCalender"}
                    />
                  </Box>
                </Box>

                <Box
                  display={"flex"}
                  gap={1}
                  marginLeft={3}
                  marginTop={1}
                  flexWrap={"wrap"}
                  alignItems={"center"}
                >
                  {/* <TableFilterComponent
                    disabled={alignment === "left" ? true : false}
                    tableFilterData={(key) => tableFilterData(key)}
                  /> */}
                  <Box height={"100%"} display={"flex"} alignItems={"center"}>
                    <TableViewSelector
                      handleAlignment={handleAlignment}
                      alignment={alignment}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  height: 'fit-content',
                  justifyContent: "space-between",
                  mt: 2,
                  mb:2,
                  display:userFeatures.includes(getFeatureValue('CONFIRMED_APPOINTMENTS')) ? 'flex' : 'none'
                }}
              >
                <Box display={"flex"} gap={2} height={"fit-content"}>
                  <Box
                    width={58}
                    height={35}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      if (!loader && !confirmedLoader && !updateLoader) {
                        setState({ table_tab: "Scheduled" });
                      }
                    }}
                  >
                    <GradientCard
                      text1={scheduledCount === 0 ? "-" : scheduledCount}
                      text2={"Scheduled"}
                      type={"mobile"}
                      selected={state.table_tab === "Scheduled"}
                    />
                  </Box>
                  <Box
                    width={58}
                    height={35}
                    sx={{ cursor: "pointer", marginLeft: 1 }}
                    onClick={() => {
                      if (!loader && !confirmedLoader && !updateLoader) {
                        setState({ table_tab: "Completed" });
                      }
                    }}
                  >
                    <GradientCard
                      text1={completedCount === 0 ? "-" : completedCount}
                      text2={"Completed"}
                      type={"mobile"}
                      selected={state.table_tab === "Completed"}
                    />
                  </Box>
                  <Box
                    width={48}
                    height={35}
                    sx={{ cursor: "pointer", marginLeft: 1 }}
                    onClick={() => {
                      if (!loader && !confirmedLoader && !updateLoader) {
                        setState({ table_tab: "Others" });
                      }
                    }}
                  >
                    <GradientCard
                      text1={othersCount === 0 ? "-" : othersCount}
                      text2={"Others"}
                      type={"mobile"}
                      selected={state.table_tab === "Others"}
                    />
                  </Box>
                </Box>

                <Box
                  display={"flex"}
                  flexWrap={"wrap"}
                  marginLeft={3}
                  marginTop={1}
                  gap={1}
                  alignItems={"center"}
                >
                  {/* <TableFilterComponent
                    disabled={alignment === "left" ? true : false}
                    tableFilterData={(key) => tableFilterData(key)}
                  /> */}
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: "4px",
                    }}
                  >
                    <IconButton
                      disabled={loader || countLoader || confirmedLoader}
                      sx={{ padding: "6px" }}
                      onClick={() => {
                        setShowFilters((prev) => !prev);
                      }}
                    >
                      <FilterAltIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <TableViewSelector
                      handleAlignment={handleAlignment}
                      alignment={alignment}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            <Box mt={1} flex={1} display={userFeatures.includes(getFeatureValue('CONFIRMED_APPOINTMENTS')) ? 'block' : 'none'}>
              {alignment === "left" ? (
                <MobileSchedulerComponent
                  appointments={_confirmedAppoitments}
                  table_tab={state.table_tab}
                  confirmedLoader={confirmedLoader}
                  getBackDateAppointments={(key) =>
                    getBackDateAppointments(key)
                  }
                  getNextDateAppointments={(key) =>
                    getNextDateAppointments(key)
                  }
                  setAppoitmentStatus={(key, data, type) =>
                    setAppoitmentStatus(key, data, type)
                  }
                  setMoreOptions={(evt, data) => setMoreOptions(evt, data)}
                  loader={updateLoader}
                  getDateValue={(key) => getDateValue(key)}
                />
              ) : (
                <TableComponent
                  confirmedLoader={confirmedLoader}
                  data={_confirmedAppoitments}
                  table_tab={state.table_tab}
                  setAppoitmentStatus={(key, data, type) =>
                    setAppoitmentStatus(key, data, type)
                  }
                  setMoreOptions={(evt, data) => setMoreOptions(evt, data)}
                  loader={updateLoader}
                  handleNewAppointmentClick={handleNewAppointmentClick}
                  getConfirmedAppintments={(limit, isPrev) =>
                    getConfirmedAppintments(
                      accountInfo?.account_id,
                      limit,
                      undefined,
                      isPrev
                    )
                  }
                  setPrevEvaluatedKey={setPrevEvaluatedKey}
                  prevEvaluatedKey={prevEvaluatedKey}
                  evaluatedKey={evaluatedKey}
                  totalData={
                    (state.table_tab === "Scheduled" && scheduledCount) ||
                    (state.table_tab === "Completed" && completedCount) ||
                    (state.table_tab === "Others" && othersCount) ||
                    0
                  }
                  isSearch={isSearch}
                />
              )}
            </Box>
          </Box>
          {cancelModal && (
            <Modal
              open={cancelModal}
              onClose={() => {
                setReason(null);
                setCancelModal(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={isMobileScreen ? styleMobile : style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <b>
                    Select a reason for{" "}
                    {activeCancel == "Cancel" ? "Cancelling" : "Declining"}{" "}
                    appointment
                  </b>
                </Typography>
                <FormControl fullWidth sx={{ marginTop: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reason}
                    label="Reason"
                    // onChange={handleChange}
                    onChange={(evt) => setReason(evt.target.value)}
                  >
                    {appointmentReasons &&
                      appointmentReasons.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: "20px" }}>
                  <TextField
                    value={nextAppointmentTime}
                    id="outlined-required"
                    label="Book next appointment after"
                    onChange={(evt) => setNextAppointmentTime(evt.target.value)}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{
                    marginTop: "20px",
                    display: `${isMobileScreen ? "flex" : "inline-block"}`,
                    textAlign: "right",
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setReason(null);
                      setNextAppointmentTime("7 Days");
                      setCancelModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={reason ? false : true}
                    loading={updateLoader}
                    sx={{
                      marginLeft: `${isMobileScreen ? "0" : "20px"}`,
                    }}
                    onClick={() =>
                      setAppoitmentStatus(
                        activeCancel === "Cancel" ? "Cancelled" : "Declined",
                        selectedAppointment
                      )
                    }
                  >
                    {activeCancel} Appointment
                  </LoadingButton>
                </FormControl>
              </Box>
            </Modal>
          )}
          <Drawer
            anchor={"bottom"}
            open={showFilters}
            onClose={() => {
              setShowFilters(false);
            }}
            PaperProps={{
              sx: {
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                overflow: "visible",
              },
            }}
          >
            <Box
              width={"100%"}
              height={"auto"}
              display={"flex"}
              position={"relative"}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "-20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: theme.palette.white.main,
                  borderRadius: "50%",
                  padding: 1,
                  zIndex: 100,
                }}
                onClick={() => {
                  setShowFilters(false);
                }}
              >
                <CloseIcon />
              </Box>

              <Box
                position={"relative"}
                display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                maxHeight={"80vh"}
                overflow={"scroll"}
                paddingX={2}
                paddingTop={2}
                gap={2}
              >
                {/* Header */}
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  width={"100%"}
                  alignItems={"center"}
                >
                  <Typography fontSize={"18px"}>Filter</Typography>
                  <Button
                    onClick={() => {
                      ResetMobileFilters();
                    }}
                    sx={{ textTransform: "none", fontSize: "14px" }}
                    color={"primary"}
                  >
                    Reset
                  </Button>
                </Box>

                {/* Date select */}
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.white.dark}
                    mb={1}
                  >
                    Date
                  </Typography>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateRangePicker"]}>
                        <DateRangePicker
                          localeText={{
                            start: "From",
                            end: "To",
                          }}
                          value={mobileFilterState.dateValue}
                          onChange={(newValue) => {
                            if (!newValue[0] || !newValue[1]) {
                              return;
                            }
                            setMobileFilterState({ dateValue: newValue });
                            setSelectedDateFilterValue(newValue);
                            // getDateValue(newValue);
                          }}
                          slotProps={{
                            textField: {
                              InputProps: {
                                startAdornment: (
                                  <Box
                                    width={{
                                      md: 40,
                                      marginRight: 4,
                                    }}
                                    component="img"
                                    src={"/images/calenderIcon.svg"}
                                  />
                                ),
                              },
                              onKeyDown: (e) => {
                                e.preventDefault();
                              },
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <Box display={"flex"} gap={1} flexWrap={"wrap"} mt={2}>
                      {shortcutsItems.map((elem) => {
                        return (
                          <Box
                            padding={1}
                            border={`1px solid ${theme.palette.white.mobileLight}`}
                            borderRadius={"8px"}
                            backgroundColor={
                              selectedDateFilterValue &&
                              selectedDateFilterValue[0].format(
                                "DD/MM/YYYY"
                              ) === elem.getValue()[0].format("DD/MM/YYYY") &&
                              selectedDateFilterValue &&
                              selectedDateFilterValue[1].format(
                                "DD/MM/YYYY"
                              ) === elem.getValue()[1].format("DD/MM/YYYY")
                                ? theme.palette.primary.medium
                                : theme.palette.white.main
                            }
                            onClick={() => {
                              setMobileFilterState({
                                dateValue: elem.getValue(),
                              });
                              setSelectedDateFilterValue(elem.getValue());
                            }}
                          >
                            <Typography fontWeight={300}>
                              {elem.label}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>

                <Divider />

                {/* Clinic Select */}
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.white.dark}
                    mb={0}
                  >
                    Clinic
                  </Typography>
                  <Box display={"flex"} gap={1} flexWrap={"wrap"} mt={1}>
                    {accountIdMap.map((elem) => {
                      return (
                        <Box
                          padding={1}
                          border={`1px solid ${theme.palette.white.mobileLight}`}
                          borderRadius={"8px"}
                          backgroundColor={
                            mobileFilterState.selectClinic === elem.value
                              ? theme.palette.primary.medium
                              : theme.palette.white.main
                          }
                          onClick={() => {
                            // setSelectedClinic(elem);
                            // setTriggerRerender(Math.random());

                            setMobileFilterState({ selectClinic: elem.value });
                          }}
                        >
                          <Typography fontWeight={300}>{elem.label}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>

                <Divider />

                {/* Visit Type */}
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.white.dark}
                    mb={0}
                  >
                    Visit Type
                  </Typography>
                  <Box display={"flex"} gap={1} flexWrap={"wrap"} mt={1}>
                    {[
                      { label: "New", value: "New" },
                      { label: "Repeat", value: "Repeat" },
                    ].map((elem) => {
                      return (
                        <Box
                          padding={1}
                          border={`1px solid ${theme.palette.white.mobileLight}`}
                          borderRadius={"8px"}
                          backgroundColor={
                            mobileFilterState.visitTypeFilter === elem.value
                              ? theme.palette.primary.medium
                              : theme.palette.white.main
                          }
                          onClick={() => {
                            if (
                              mobileFilterState.visitTypeFilter === elem.value
                            ) {
                              // setVisitTypeFilter('');
                              setMobileFilterState({ visitTypeFilter: "" });
                              return;
                            }
                            // setVisitTypeFilter(elem.value)
                            setMobileFilterState({
                              visitTypeFilter: elem.value,
                            });
                          }}
                        >
                          <Typography fontWeight={300}>{elem.label}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>

                <Divider />

                {/* Status Filter */}
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.white.dark}
                    mb={0}
                  >
                    Status
                  </Typography>
                  <Box display={"flex"} gap={1} flexWrap={"wrap"} mt={1}>
                    {statusTypes.map((elem) => {
                      return (
                        <Box
                          padding={1}
                          border={
                            mobileFilterState.statusFilter === elem.value
                              ? `1px solid ${elem.borderColor}`
                              : undefined
                          }
                          borderRadius={"8px"}
                          backgroundColor={elem.backgroundColor}
                          onClick={() => {
                            if (mobileFilterState.statusFilter === elem.value) {
                              // setStatusFilter("")
                              setMobileFilterState({ statusFilter: "" });
                              return;
                            }
                            // setStatusFilter(elem.value);
                            setMobileFilterState({ statusFilter: elem.value });
                          }}
                          display={"flex"}
                          gap={1}
                          alignItems={"center"}
                        >
                          <Box
                            width={"8px"}
                            height={"8px"}
                            borderRadius={"50%"}
                            backgroundColor={elem.borderColor}
                          ></Box>
                          <Typography fontWeight={300}>{elem.label}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>

                <Divider />

                {/* Appointment Type */}
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.white.dark}
                    mb={1}
                  >
                    Type of Appointment
                  </Typography>
                  <Box display={"flex"} gap={1}>
                    <Box
                      padding={1}
                      border={`1px solid ${theme.palette.white.mobileLight}`}
                      borderRadius={"8px"}
                      backgroundColor={
                        mobileFilterState.checkedClinic
                          ? theme.palette.primary.medium
                          : theme.palette.white.main
                      }
                      onClick={(event) => {
                        if (checkedClinic) {
                          // setCheckedVideo(true);
                          // setCheckedClinic(false);
                          setMobileFilterState({
                            checkedClinic: false,
                            checkedVideo: true,
                          });
                          return;
                        }
                        // setCheckedClinic(true);
                        setMobileFilterState({
                          checkedClinic: true,
                        });
                      }}
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box
                        width={{ md: 20 }}
                        component="img"
                        alt="video"
                        src={"/images/clinicIcon2.svg"}
                        sx={{ marginTop: "2px" }}
                      />
                      <Typography fontWeight={300}>{"In Clinic"}</Typography>
                    </Box>
                    <Box
                      padding={1}
                      border={`1px solid ${theme.palette.white.mobileLight}`}
                      borderRadius={"8px"}
                      backgroundColor={
                        mobileFilterState.checkedVideo
                          ? theme.palette.primary.medium
                          : theme.palette.white.main
                      }
                      onClick={(event) => {
                        if (checkedVideo) {
                          // setCheckedVideo(false);
                          // setCheckedClinic(true);
                          setMobileFilterState({
                            checkedVideo: false,
                            checkedClinic: true,
                          });
                          return;
                        }
                        // setCheckedVideo(true);
                        setMobileFilterState({ checkedVideo: true });
                      }}
                      display={"flex"}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box
                        width={{ md: 20 }}
                        component="img"
                        alt="video"
                        src={"/images/cameraIcon2.svg"}
                        sx={{ marginTop: "2px" }}
                      />
                      <Typography fontWeight={300}>
                        {"Video Consultation"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  position={"sticky"}
                  bottom={0}
                  width={"100%"}
                  backgroundColor={theme.palette.white.main}
                >
                  <Divider />

                  {/* Buttons */}
                  <Box display={"flex"} gap={1} mt={2} paddingBottom={2}>
                    <Button
                      variant="outlined"
                      sx={{ flex: 1, padding: 1 }}
                      onClick={() => {
                        setShowFilters(false);
                      }}
                    >
                      <Typography textTransform={"none"}>Cancel</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ flex: 1, padding: 1 }}
                      onClick={() => {
                        setSearchValue("");
                        handleApplyMobileFilters();
                        setShowFilters(false);
                      }}
                    >
                      <Typography textTransform={"none"}>Apply</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
          <Drawer
            anchor={"bottom"}
            open={showMobileFilter}
            onClose={() => {
              setShowMobileFilter(false);
              setDisableMobileFilterButton(true);
            }}
            PaperProps={{
              sx: {
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                overflow: "visible",
              },
            }}
          >
            <Box
            width={"100%"}
            height={"auto"}>
             <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "-20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: theme.palette.white.main,
                  borderRadius: "50%",
                  padding: 1,
                  zIndex: 100,
                }}
                onClick={() => {
                  setShowMobileFilter(false);
                  setDisableMobileFilterButton(true);
                }}
              >
                <CloseIcon />
              </Box>
              <Box className="header" sx={{display:"flex", justifyContent:"space-between", padding:"24px 16px 24px 16px"}}>
               <Typography sx={{color:"#313131", fontSize:"20px", fontWeight:700, lineHeight:"20px"}}>Filter</Typography>
               <Typography onClick={()=>{
                setDisableMobileFilterButton(true);
               }} sx={{color:"#494E9D", fontSize:"16px", fontWeight:600, lineHeight:"16px"}}>Reset</Typography>   
              </Box>
              <Box className="content-section">
               <Box className="filter-section" sx={{padding:"0px 16px"}}>
                <Typography sx={{color:"#6F6F6F", fontSize:"14px", fontWeight:600}}>Appointment requests</Typography>
                <Box className="filter-button-group" sx={MobileFilterStyle.filterButtonGroupStyle}>
                  {MobileFilterButton.map((elem, index)=>{
                    return <Button key={index} onClick={()=>{
                      setDisableMobileFilterButton(false);
                      setMobileFilterValue(elem?.value);
                      setSelectedButtonId(elem?.id)
                    }} variant="outlined" sx={!disableMobileFilterButton && selectedButtonId === index ? MobileFilterStyle?.selectedFilterButtonStyle : MobileFilterStyle?.filterButtonStyle}>{elem?.name}</Button>
                  })}
                </Box>
               </Box>
              </Box>
              <Divider />
              <Box className="footer-section" sx={MobileFilterStyle.footerStyle}>
              <Button
                      variant="outlined"
                      disabled={disableMobileFilterButton}
                      sx={{ flex: 1, padding: 1 }}
                      onClick={() => {
                        setShowMobileFilter(false);
                        setDisableMobileFilterButton(true);
                      }}
                    >
                      <Typography textTransform={"none"}>Cancel</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      disabled={disableMobileFilterButton}
                      sx={{ flex: 1, padding: 1 }}
                      onClick={() => {
                        handleAppointmentFilterChange(mobileFilterValue)
                        setShowMobileFilter(false);
                      }}
                    >
                      <Typography textTransform={"none"}>Apply</Typography>
                    </Button>
              </Box>
              </Box>
          </Drawer>
        </>
      ) : (
        <>
          <Box>
            <GradientBox>
              <Grid
                container
                height="100%"
                spacing={2}
                sx={{ marginTop: "0px" }}
              >
                {/* -------Left Panel-------- */}
                <Grid
                  height="100%"
                  item
                  xs={3.3}
                  sx={{
                    // boxShadow: "1px 1px 2px 2px rgba(0,0,0,0.2)",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <Stack height={"88vh"} spacing={2} padding={2}>
                    <Typography variant="h6">
                      {profile && (
                        <>
                          {" "}
                          <Typography
                            variant="h6"
                            display={"inline"}
                            fontWeight={600}
                          >
                            {!profile?.name?.includes("Dr ") && profile?.role?.includes('doctor') ? "Dr" : ""}{" "}
                            {profile?.name}
                          </Typography>
                          ,{" "}
                          <Typography
                            display={"inline"}
                            variant="h6"
                            fontWeight={"light"}
                            color={theme.palette.white.dark}
                          >
                            Welcome back !
                          </Typography>
                        </>
                      )}
                    </Typography>
                   {<Box
                      sx={{
                        width: "fit-content",
                        border: `1px solid ${theme.palette.white.outline}`,
                        borderRadius: "10px",
                        padding: "4px",
                      }}
                      display={!userFeatures.includes(getFeatureValue('ENABLE_AUTO_APPROVE')) ? 'block' : 'none'}
                    >
                      <StyledToggleButtonGroup
                        color="primary"
                        value={state.appointmentFilter}
                        exclusive
                        onChange={handleAppointmentFilterChange}
                        aria-label="Platform"
                      >
                        <ToggleButton
                          value="30"
                          sx={{ padding: "6px 10px", textTransform: "none" }}
                        >
                          All
                        </ToggleButton>
                        <ToggleButton
                          value="0"
                          sx={{ padding: "6px 10px", textTransform: "none" }}
                        >
                          Today
                        </ToggleButton>
                        <ToggleButton
                          value="7"
                          sx={{ padding: "6px 10px", textTransform: "none" }}
                        >
                          Next 7 Days
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    </Box>}

                    {!userFeatures.includes(getFeatureValue('ENABLE_AUTO_APPROVE')) && appoitments?.rows?.length > 0 && (
                      <Box>
                        <GradientCard
                          text1={`${
                            appoitments && appoitments?.rows
                              ? appoitments?.rows?.length
                              : "0"
                          }`}
                          text2="To be Confirmed"
                          type="primary"
                        />
                      </Box>
                    )}

                    <Box overflow={"auto"} display={!userFeatures.includes(getFeatureValue('ENABLE_AUTO_APPROVE')) ? 'block' : 'none'}>
                      {loader ? (
                        <>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </>
                      ) : _appoitments?.rows?.length === 0 ? (
                        <Box
                          display={"flex"}
                          height={"70vh"}
                          gap={2}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Box
                            width={{ md: 80 }}
                            component="img"
                            alt="No Appointments"
                            src={"/images/noappointmentsImage2.svg"}
                            sx={{}}
                          />
                          <Typography
                            fontWeight={"bold"}
                            textAlign={"center"}
                            lineHeight={"120%"}
                          >
                            There are no new appointments <br /> pending right
                            now
                          </Typography>
                          <Typography
                            variant="caption"
                            textAlign={"center"}
                            color={theme.palette.white.dark}
                            lineHeight={"150%"}
                          >
                            SVAAS helps to manage and <br />
                            accept your appointments here
                          </Typography>
                        </Box>
                        // <Box>
                        // <Box>
                        //  <Typography sx={{fontSize:"14px", color:"#494E9D", fontWeight:"700"}}>Articles curated for you</Typography>
                        // </Box>
                        // {!showArticleCard ?<Box onClick={()=>navigate('/articles')} sx={{position:'relative', left:"-13px", marginBottom:"30px"}}>
                        //   <Box component="img" src="/images/article-4.png" width="100%" />
                        // </Box> : <Box onClick={()=>navigate('/articles')} sx={{position:'relative', left:"-13px", marginBottom:"30px"}}>
                        //   <Box component="img" src="/images/article-6.svg" width="100%" />
                        // </Box>}
                        // </Box>
                      ) : (
                        _appoitments &&
                        _appoitments?.rows?.map((elem) => {
                          return (
                            <ActionCard
                              data={elem}
                              setCancelModal={(key, data) =>
                                setMoreOptions(key, data)
                              }
                              loader={loader}
                              accountInfo={accountInfo}
                              setAppoitmentStatus={(key, data, type) =>
                                setAppoitmentStatus(key, data, type)
                              }
                            />
                          );
                        })
                        // <Box>
                        // <Box>
                        //  <Typography sx={{fontSize:"14px", color:"#494E9D", fontWeight:"700"}}>Articles curated for you</Typography>
                        // </Box>
                        // {!showArticleCard ?<Box onClick={()=>navigate('/articles')} sx={{position:'relative', left:"-13px", marginBottom:"30px"}}>
                        //   <Box component="img" src="/images/article-4.png" width="100%" />
                        // </Box> : <Box onClick={()=>navigate('/articles')} sx={{position:'relative', left:"-13px", marginBottom:"30px"}}>
                        //   <Box component="img" src="/images/article-6.svg" width="100%" />
                        // </Box>}
                        // </Box>
                      )}
                    </Box>
                  </Stack>
                </Grid>

                {/* --------Right panel------- */}
                <Grid
                  className="appointment-right-panel"
                  height="95vh"
                  item
                  xs={8.7}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <Box
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    paddingRight={2}
                    paddingTop={0}
                  >
                    {/* Reminder,Search,New Patient */}
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"start"}
                    >
                      {confirmedAppoitments?.next_upcoming_appointment &&
                        Object.keys(
                          confirmedAppoitments?.next_upcoming_appointment
                        )?.length !== 0 && (
                          <ReminderComponent
                            message={
                              confirmedAppoitments?.next_upcoming_appointment
                                ?.ticker_text
                            }
                          />
                        )}

                      {/* <Box>
                        <Button onClick={() => navigate("/articles")}>
                          Show Article
                        </Button>
                      </Box> */}

                      <Box display={"flex"} gap={1} marginLeft={"auto"}>
                        <Box display={"flex"} alignItems={"end"} gap={1}>
                          <SmallSearchInput
                            disabled={alignment === "left" ? true : false}
                            label={"Search by Patient Name/Mobile No."}
                            value={searchValue}
                            getAppointmentData={(key) =>
                              filterSearchOnChange(key)
                            }
                            getConfirmedAppintments={(limit) => {
                              setIsSearch(true);
                              setEvaluatedKey(null);
                              setPrevEvaluatedKey([]);
                              getConfirmedAppintments(
                                accountInfo?.account_id,
                                limit,
                                false
                              );
                              getAppointmentCountData(
                                accountInfo?.account_id,
                                false
                              );
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>

                    {/* Date Picker,Clinic Picker,View Picker,Filters,New Appointment */}
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"end"}
                      mt={1}
                      gap={1}
                    >
                      <Box display={"flex"} alignItems={"end"} gap={1}>
                        <Box
                          sx={{
                            border: `1px solid ${theme.palette.white.outline}`,
                            paddingX: "10px",
                            backgroundColor: theme.palette.white.main,
                            borderRadius: "8px",
                            overflow: "hidden",
                            height: "37px",
                          }}
                        >
                          <Button
                            variant="text"
                            disabled={alignment === "left" ? true : false}
                            disableElevation
                            // onClick={() => getTodayAppointments()}
                            onClick={(e) => {
                              setOpenDatePickerModal(!openDatePickerModal);
                              return;
                            }}
                            sx={{
                              paddingX: 0,
                              height: "36px",
                              color: "black",
                            }}
                            startIcon={
                              // <CalendarTodayIcon sx={{ width: "12px" }} />
                              <Box
                                width={{ md: 24 }}
                                component="img"
                                alt="video"
                                src={"/images/calenderIcon.svg"}
                                sx={{ marginTop: "0px" }}
                              />
                            }
                          >
                            <Typography
                              textTransform={"none"}
                              variant="subtitle1"
                              noWrap
                            >
                              {getDayFormat(searchDate, true, dateValue)}
                            </Typography>
                          </Button>
                          <Modal
                            open={openDatePickerModal}
                            onClose={() => {
                              setOpenDatePickerModal(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style2}>
                              <Box>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={["DateRangePicker"]}
                                  >
                                    <DateRangePicker
                                      localeText={{
                                        start: "From",
                                        end: "To",
                                      }}
                                      value={
                                        selectedDateFilterValue || dateValue
                                      }
                                      onChange={(newValue) => {
                                        if (!newValue[0] || !newValue[1]) {
                                          return;
                                        }
                                        setSelectedDateFilterValue(newValue);
                                        // getDateValue(newValue);
                                      }}
                                      slotProps={{
                                        textField: {
                                          InputProps: {
                                            startAdornment: (
                                              <Box
                                                width={{
                                                  md: 30,
                                                  marginRight: 4,
                                                }}
                                                component="img"
                                                src={"/images/calenderIcon.svg"}
                                                sx={{}}
                                              />
                                            ),
                                          },
                                          onKeyDown: (e) => {
                                            e.preventDefault();
                                          },
                                        },
                                      }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Box>

                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                gap={1}
                                mt={2}
                              >
                                {shortcutsItems.map((elem) => {
                                  return (
                                    <Box
                                      sx={{
                                        backgroundColor:
                                          selectedDateFilterValue &&
                                          selectedDateFilterValue[0].format(
                                            "DD/MM/YYYY"
                                          ) ===
                                            elem
                                              .getValue()[0]
                                              .format("DD/MM/YYYY") &&
                                          selectedDateFilterValue &&
                                          selectedDateFilterValue[1].format(
                                            "DD/MM/YYYY"
                                          ) ===
                                            elem
                                              .getValue()[1]
                                              .format("DD/MM/YYYY")
                                            ? theme.palette.primary.medium
                                            : theme.palette.white.main,
                                        border: `1px solid ${theme.palette.white.outline}`,
                                        borderRadius: "10px",
                                        padding: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        ":hover": {
                                          backgroundColor:
                                            theme.palette.white.mobileLight,
                                        },
                                      }}
                                      onClick={() => {
                                        setSelectedDateFilterValue(
                                          elem.getValue()
                                        );
                                      }}
                                    >
                                      <Typography>{elem.label}</Typography>
                                    </Box>
                                  );
                                })}
                                <Box display={"flex"} gap={1}>
                                  <Box
                                    sx={{
                                      flex: 1,
                                      border: `1px solid ${theme.palette.white.outline}`,
                                      borderRadius: "10px",
                                      padding: "10px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      ":hover": {
                                        backgroundColor:
                                          theme.palette.white.mobileLight,
                                      },
                                    }}
                                    onClick={() => {
                                      getDateValue([dayjs(), dayjs()]);
                                      setOpenDatePickerModal(false);
                                      setSelectedDateFilterValue(null);
                                    }}
                                  >
                                    <Typography>{"Reset"}</Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      flex: 1,
                                      backgroundColor: selectedDateFilterValue
                                        ? theme.palette.primary.main
                                        : theme.palette.white.outline,
                                      borderRadius: "10px",
                                      padding: "10px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (!selectedDateFilterValue) {
                                        return;
                                      }
                                      getDateValue(selectedDateFilterValue);
                                      setOpenDatePickerModal(false);
                                      setSelectedDateFilterValue(null);
                                    }}
                                  >
                                    <Typography
                                      color={theme.palette.white.main}
                                    >
                                      {"Apply"}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                        </Box>

                        {/* <CalenderComponent
                          getDateValue={(key) => getDateValue(key)}
                          dateValue={dateValue}
                          disabled={
                            confirmedLoader
                              ? true
                              : alignment === "left"
                              ? true
                              : false
                          }
                        /> */}

                        {/* clinic select */}
                        <Tooltip title={selectClinic.current} placement="top">
                          <FormControl sx={{ m: 0, width: 100, padding: "0px" }}>
                            <Select
                              label="Select Account"
                              value={selectClinic.current}
                              onChange={handleChangeClinic}
                              disabled={
                                confirmedLoader
                                  ? true
                                  : names.length == 1
                                  ? true
                                  : alignment === "left"
                                  ? true
                                  : false
                              }
                              input={
                                <OutlinedInput
                                  className="nopadding-select-mui"
                                  sx={{ padding: "0px" }}
                                />
                              }
                              // MenuProps={MenuProps}
                              // inputProps={{ "aria-label": "Without label" }}
                              sx={{
                                padding: "1px",
                                backgroundColor: theme.palette.white.main,
                                borderRadius: "8px",
                              }}
                            >
                              {accountIdMap?.map((account) => {
                                return (
                                  <MenuItem key={account.value} value={account.value}>
                                    {account.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Tooltip>
                        

                        {/* doctors drop down */}
                        {isStaffLogin && (
                          <FormControl
                            sx={{ m: 0, width: 110, padding: "0px" }}
                          >
                            <Select
                              className="filterSelect"
                              label="Doctors"
                              value={selectedDoctor}
                              onChange={(e) => {
                                handleChangeDoctor(e.target.value);
                              }}
                              disabled={
                                confirmedLoader
                                  ? true
                                  : alignment === "left"
                                  ? true
                                  : false
                              }
                              displayEmpty
                              input={
                                <OutlinedInput
                                  className="nopadding-select-mui"
                                  sx={{ padding: "0px" }}
                                />
                              }
                              sx={{
                                padding: "1px",
                                backgroundColor: theme.palette.white.main,
                                borderRadius: "8px",
                              }}
                            >
                              <MenuItem disabled value="">
                                <Typography>Doctors</Typography>
                              </MenuItem>
                              {/* <MenuItem value="none">
                                <Typography>{"None"}</Typography>
                              </MenuItem> */}
                              {doctorsList?.map((elem) => {
                                return (
                                  <MenuItem value={elem.cognito_user_sub}>
                                    <Typography>{elem.name}</Typography>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        )}

                        {(!isStaffLogin ||
                          (isStaffLogin && selectedDoctor)) && (
                          <>
                            {/* visit type */}
                            <FormControl
                              sx={{ m: 0, width: 110, padding: "0px" }}
                            >
                              <Select
                                label="visit type"
                                className="filterSelect"
                                value={visitTypeFilter}
                                onChange={(e) => {
                                  setVisitTypeFilter(e.target.value);
                                }}
                                disabled={
                                  confirmedLoader
                                    ? true
                                    : alignment === "left"
                                    ? true
                                    : false
                                }
                                displayEmpty
                                input={
                                  <OutlinedInput
                                    className="nopadding-select-mui"
                                    sx={{ padding: "0px" }}
                                  />
                                }
                                sx={{
                                  padding: "1px",
                                  backgroundColor: theme.palette.white.main,
                                  borderRadius: "8px",
                                }}
                              >
                                <MenuItem disabled value="">
                                  <Typography>Visit Type</Typography>
                                </MenuItem>
                                <MenuItem value="all">
                                  <Typography>All</Typography>
                                </MenuItem>
                                <MenuItem value="New">
                                  <Typography>New</Typography>
                                </MenuItem>
                                <MenuItem value="Repeat">
                                  <Typography>Repeat</Typography>
                                </MenuItem>
                              </Select>
                            </FormControl>

                            {/* status */}
                            <FormControl
                              sx={{ m: 0, width: 100, padding: "0px" }}
                            >
                              <Select
                                label="status"
                                className="filterSelect"
                                value={statusFilter}
                                onChange={(e) => {
                                  setStatusFilter(e.target.value);
                                }}
                                disabled={
                                  confirmedLoader
                                    ? true
                                    : alignment === "left"
                                    ? true
                                    : false
                                }
                                displayEmpty
                                input={
                                  <OutlinedInput
                                    className="nopadding-select-mui"
                                    sx={{ padding: "0px" }}
                                  />
                                }
                                // MenuProps={MenuProps}
                                // inputProps={{ "aria-label": "Without label" }}
                                sx={{
                                  padding: "1px",
                                  backgroundColor: theme.palette.white.main,
                                  borderRadius: "8px",
                                }}
                              >
                                <MenuItem disabled value="">
                                  <Typography>Status</Typography>
                                </MenuItem>
                                <MenuItem value="all">
                                  <Typography>All</Typography>
                                </MenuItem>
                                <MenuItem value="Booked">
                                  <Typography>Scheduled</Typography>
                                </MenuItem>
                                <MenuItem value="Checked-In">
                                  <Typography>Checked-In</Typography>
                                </MenuItem>
                                <MenuItem value="Consulting">
                                  <Typography>In-Process</Typography>
                                </MenuItem>
                                <MenuItem value="Done">
                                  <Typography>Completed</Typography>
                                </MenuItem>
                                <MenuItem value="Cancelled">
                                  <Typography>Cancelled</Typography>
                                </MenuItem>
                                <MenuItem value="Declined">
                                  <Typography>Declined</Typography>
                                </MenuItem>
                                <MenuItem value="No-Show">
                                  <Typography>No-Show</Typography>
                                </MenuItem>
                                <MenuItem value="Missed">
                                  <Typography>Missed</Typography>
                                </MenuItem>
                                {/* <MenuItem value="Re-Scheduled">
                                  <Typography>Rescheduled</Typography>
                                </MenuItem> */}
                              </Select>
                            </FormControl>

                            {/* Checkbox filter */}
                            <Box
                              display={"flex"}
                              gap={1}
                              sx={{
                                border: `1px solid ${theme.palette.white.outline}`,
                                paddingX: "2px",
                                backgroundColor: theme.palette.white.main,
                                borderRadius: "8px",
                                height: "37px",
                              }}
                            >
                              <CheckboxFilter
                                filterData={(clinic, video) =>
                                  filterData(clinic, video)
                                }
                                setCheckedClinic={(evt) =>
                                  setCheckedClinic(evt)
                                }
                                setCheckedVideo={(evt) => setCheckedVideo(evt)}
                                checkedClinic={checkedClinic}
                                checkedVideo={checkedVideo}
                                disabled={confirmedLoader ? true : false}
                                handleChangeClinic={(evt) =>
                                  _handleChangeClinic(evt)
                                }
                                handleChangeVideo={(evt) =>
                                  handleChangeVideo(evt)
                                }
                              />
                              {/* <Divider orientation="vertical" flexItem />
                              <TableFilterComponent
                                disabled={alignment === "left" ? true : false}
                                tableFilterData={(key) => tableFilterData(key)}
                              /> */}
                            </Box>

                            {/* reset button */}
                            <Button
                              variant="text"
                              sx={{
                                width: "fit-content",
                                minWidth: "fit-content",
                                textTransform: "none",
                                paddingX: "4px",
                              }}
                              onClick={handleFilterReset}
                              disabled={
                                loader || confirmedLoader || countLoader
                              }
                            >
                              Reset
                            </Button>
                          </>
                        )}
                      </Box>

                      <Box display={"flex"} gap={1}>
                        <Tooltip title="Register patient">
                          <Button
                            variant="outlined"
                            disableFocusRipple
                            onClick={handleNewPatientClick}
                            sx={{
                              maxWidth: "40px",
                              minWidth: "40px",
                              padding: "4px",
                              backgroundColor: theme.palette.white.main,
                              borderRadius: "8px",
                              display:userFeatures.includes(getFeatureValue('REGISTER_USER')) ? 'flex' : 'none'
                            }}
                          >
                            <Box
                              width={{ md: 25 }}
                              component="img"
                              alt="add patient"
                              src="/images/addpatient.svg"
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title={"Book an Appointment"}>
                          <Button
                            variant="contained"
                            startIcon={is1350px && <AddIcon />}
                            size="small"
                            sx={{
                              padding: `${is1350px ? "8px" : "6px"} ${
                                is1350px ? "8px" : "8px"
                              }`,
                              borderRadius: "8px",
                              maxWidth: `${is1350px ? "fit-content" : "40px"}`,
                              minWidth: `${is1350px ? "fit-content" : "40px"}`,
                              display:userFeatures.includes(getFeatureValue('CREATE_APPOINTMENT')) ? 'flex' : 'none'

                            }}
                            onClick={handleNewAppointmentClick}
                          >
                            {is1350px ? (
                              <Typography
                                variant="caption"
                                sx={{ display: { xs: "none", lg: "block" } }}
                              >
                                NEW APPOINTMENT
                              </Typography>
                            ) : (
                              <AddIcon />
                            )}
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>

                    {/* <Box mt={2}>
                      <Divider
                        sx={{
                          border: "none",
                          height: "1px",
                          background: `linear-gradient(to right, #FFFFFF, #d9d9d9, #d9d9d9, #FFFFFF)`,
                        }}
                      />
                    </Box> */}

                    <Box
                      mt={2}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      display={userFeatures.includes(getFeatureValue('CONFIRMED_APPOINTMENTS')) ? 'flex' : 'none'}
                    >
                      {alignment === "left" ? (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box
                            border={"1px solid rgba(0,0,0,0.1)"}
                            borderRadius={"20px"}
                            padding={"4px 12px"}
                            display={"flex"}
                            gap={2}
                          >
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                              <Box
                                width={"8px"}
                                height={"8px"}
                                borderRadius={"50%"}
                                backgroundColor={theme.palette.blue.main}
                              ></Box>
                              <Typography variant="caption">
                                {" "}
                                {countLoader ? (
                                  <Skeleton></Skeleton>
                                ) : (
                                  <>{scheduledCount} Scheduled</>
                                )}
                              </Typography>
                            </Box>

                            {/* <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Box
                              width={"8px"}
                              height={"8px"}
                              borderRadius={"50%"}
                              backgroundColor={theme.palette.secondary.main}
                            ></Box>
                            <Typography variant="caption">
                              {" "}
                              {inProcessCount} In Process
                            </Typography>
                          </Box> */}

                            <Box display={"flex"} alignItems={"center"} gap={1}>
                              <Box
                                width={"8px"}
                                height={"8px"}
                                borderRadius={"50%"}
                                backgroundColor={theme.palette.primary.main}
                              ></Box>
                              <Typography variant="caption">
                                {" "}
                                {countLoader ? (
                                  <Skeleton></Skeleton>
                                ) : (
                                  <> {completedCount} Completed</>
                                )}
                              </Typography>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                              <Box
                                width={"8px"}
                                height={"8px"}
                                borderRadius={"50%"}
                                backgroundColor={theme.palette.red.main}
                              ></Box>
                              <Typography variant="caption">
                                {" "}
                                {countLoader ? (
                                  <Skeleton></Skeleton>
                                ) : (
                                  <> {othersCount} Others</>
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box display={"flex"} gap={2}>
                          <Tooltip title={"Scheduled & In Progress"}>
                            <Box
                              width={150}
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                if (!loader && !confirmedLoader) {
                                  setState({ table_tab: "Scheduled" });
                                }
                              }}
                            >
                              <GradientCard
                                text1={
                                  countLoader ? (
                                    <>
                                      <Skeleton></Skeleton>
                                    </>
                                  ) : scheduledCount === 0 ? (
                                    "-"
                                  ) : (
                                    scheduledCount
                                  )
                                }
                                text2={"Scheduled"}
                                type={"grey"}
                                selected={state.table_tab === "Scheduled"}
                              />
                            </Box>
                          </Tooltip>
                          <Box
                            width={150}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              if (!loader && !confirmedLoader) {
                                setState({ table_tab: "Completed" });
                              }
                            }}
                          >
                            <GradientCard
                              text1={
                                countLoader ? (
                                  <>
                                    <Skeleton></Skeleton>
                                  </>
                                ) : completedCount === 0 ? (
                                  "-"
                                ) : (
                                  completedCount
                                )
                              }
                              text2={"Completed"}
                              type={"grey"}
                              selected={state.table_tab === "Completed"}
                            />
                          </Box>
                          <Tooltip title={"No Show, Cancelled, Missed"}>
                            <Box
                              width={150}
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                if (!loader && !confirmedLoader) {
                                  setState({ table_tab: "Others" });
                                }
                              }}
                            >
                              <GradientCard
                                text1={
                                  countLoader ? (
                                    <>
                                      <Skeleton></Skeleton>
                                    </>
                                  ) : othersCount === 0 ? (
                                    "-"
                                  ) : (
                                    othersCount
                                  )
                                }
                                text2={"Others"}
                                type={"grey"}
                                selected={state.table_tab === "Others"}
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                      )}

                      {/* <Box display={"flex"} gap={1}>
                  
                      <TableViewSelector />
                      
                    </Box> */}
                      <Box
                        height={"100%"}
                        alignItems={"center"}
                        display={userFeatures.includes(getFeatureValue('CONFIRMED_APPOINTMENTS')) ? 'flex' : 'none'}
                      >
                        <TableViewSelector
                          handleAlignment={handleAlignment}
                          alignment={alignment}
                        />
                      </Box>
                    </Box>

                    <Box mt={0} flex={1} display={userFeatures.includes(getFeatureValue('CONFIRMED_APPOINTMENTS')) ? 'flex' : 'none'}>
                      {alignment === "left" ? (
                        <SchedulerComponent
                          appointments={_confirmedAppoitments}
                          table_tab={state.table_tab}
                          confirmedLoader={confirmedLoader}
                          getBackDateAppointments={(key) =>
                            getBackDateAppointments(key)
                          }
                          getNextDateAppointments={(key) =>
                            getNextDateAppointments(key)
                          }
                          setAppoitmentStatus={(key, data, type) =>
                            setAppoitmentStatus(key, data, type)
                          }
                          setMoreOptions={(evt, data) =>
                            setMoreOptions(evt, data)
                          }
                          loader={updateLoader}
                        />
                      ) : (
                        <TableComponent
                          confirmedLoader={confirmedLoader}
                          data={_confirmedAppoitments}
                          table_tab={state.table_tab}
                          setAppoitmentStatus={(key, data, type) =>
                            setAppoitmentStatus(key, data, type)
                          }
                          setMoreOptions={(evt, data) =>
                            setMoreOptions(evt, data)
                          }
                          loader={updateLoader}
                          handleNewAppointmentClick={handleNewAppointmentClick}
                          getConfirmedAppintments={(limit, isPrev) =>
                            getConfirmedAppintments(
                              accountInfo?.account_id,
                              limit,
                              undefined,
                              isPrev
                            )
                          }
                          setPrevEvaluatedKey={setPrevEvaluatedKey}
                          prevEvaluatedKey={prevEvaluatedKey}
                          evaluatedKey={evaluatedKey}
                          totalData={
                            (state.table_tab === "Scheduled" &&
                              scheduledCount) ||
                            (state.table_tab === "Completed" &&
                              completedCount) ||
                            (state.table_tab === "Others" && othersCount) ||
                            0
                          }
                          isSearch={isSearch}
                        />
                      )}

                      {/*  */}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </GradientBox>
          </Box>
          {cancelModal && (
            <Modal
              open={cancelModal}
              onClose={() => {
                setReason(null);
                setCancelModal(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <b>
                    Select a reason for{" "}
                    {activeCancel == "Cancel" ? "Cancelling" : "Declining"}{" "}
                    appointment
                  </b>
                </Typography>
                <FormControl fullWidth sx={{ marginTop: "20px" }}>
                  <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reason}
                    label="Reason"
                    // onChange={handleChange}
                    onChange={(evt) => setReason(evt.target.value)}
                  >
                    {appointmentReasons &&
                      appointmentReasons.map((item) => {
                        return <MenuItem value={item}>{item}</MenuItem>;
                      })}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: "20px" }}>
                  <TextField
                    value={nextAppointmentTime}
                    id="outlined-required"
                    label="Book next appointment after"
                    onChange={(evt) => setNextAppointmentTime(evt.target.value)}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{
                    marginTop: "20px",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setReason(null);
                      setNextAppointmentTime("7 Days");
                      setCancelModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={reason ? false : true}
                    loading={updateLoader}
                    sx={{
                      marginLeft: "20px",
                    }}
                    onClick={() =>
                      setAppoitmentStatus(
                        activeCancel === "Cancel" ? "Cancelled" : "Declined",
                        selectedAppointment
                      )
                    }
                  >
                    {activeCancel} Appointment
                  </LoadingButton>
                </FormControl>
              </Box>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default AppointmentsPage;

const TableComponent = ({
  data,
  table_tab,
  setAppoitmentStatus,
  setMoreOptions,
  confirmedLoader,
  loader,
  handleNewAppointmentClick,
  totalData,
  getConfirmedAppintments,
  setPrevEvaluatedKey,
  prevEvaluatedKey,
  evaluatedKey,
  isSearch,
}) => {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { confirmedAppoitments, lastEvalKeyStack } = useSelector(
    (state) => state.appointments
  );
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [menu, setMenu] = useState(false);
  const columns = [
    { id: "icon", label: "", minWidth: 20 },
    { id: "patientinfo", label: "Patient Info", minWidth: 170 },
    { id: "account_id", label: "Clinic", minWidth: 100 },
    {
      id: "date",
      label: "Date",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "time",
      label: "Time",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "visittype",
      label: "Visit Type",
      minWidth: 100,
      align: "left",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 100,
      align: "left",
    },
    {
      id: "tags",
      label: "Tags",
      minWidth: 100,
      align: "left",
    },
    {
      id: "action",
      label: "Action",
      minWidth: 180,
      align: "left",
    },
  ];

  // const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [selectedRow, setSelectedRow] = useState({});

  function createData(
    name,
    gender,
    age,
    phone_number,
    appointment_type,
    account_id,
    account_name,
    date,
    time,
    visit_type,
    status,
    appointment_action_tags,
    appointment_action,
    source,
    id,
    appointment_id,
    months,
    appointment_mode,
    patient_id,
    doctor_id
  ) {
    return {
      name,
      gender,
      age,
      phone_number,
      appointment_type,
      account_id,
      account_name,
      date,
      time,
      visit_type,
      status,
      appointment_action_tags,
      appointment_action,
      source,
      id,
      appointment_id,
      months,
      appointment_mode,
      patient_id,
      doctor_id
    };
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    if (prevEvaluatedKey.current?.length === 0) {
      setPage(0);
    }
  }, [prevEvaluatedKey.current]);

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      console.log("next page");
      setPrevEvaluatedKey([...prevEvaluatedKey.current, evaluatedKey.current]);
      setPage(newPage);
      getConfirmedAppintments(10);
    } else {
      console.log("prev page");
      prevEvaluatedKey.current.pop();
      setPage(newPage);
      getConfirmedAppintments(10, true);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    getConfirmedAppintments(event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (data?.rows?.length !== 0) {
      if (table_tab === "Scheduled") {
        const isStatusAllowed = (status) =>
          ["booked", "checked-in", "consulting", "scheduled"].includes(status);
        let _data = [];
        _data = data?.rows?.map((elem) => {
          return createData(
            elem.patient?.name,
            elem.patient?.gender,
            elem.patient?.age?.no_of_years,
            elem.patient?.phone,
            elem.appointment_type,
            elem.account_id,
            elem.account_name,
            elem.appointment_date,
            elem.appointment_slot,
            elem.visit_type,
            elem.appointment_status,
            elem.appointment_action_tags,
            elem.appointment_action,
            elem.source,
            elem?.id,
            elem?.appointment_id,
            elem.patient?.age?.no_of_months,
            elem?.appointment_mode,
            elem?.patient_id,
            elem?.doctor_id
          );
        });
        setRows(_data);
        return;
      }
      if (table_tab === "Completed") {
        const excludedStatuses = ["done"];
        let _data = [];
        _data = data?.rows?.map((elem) => {
          return createData(
            elem.patient?.name,
            elem.patient?.gender,
            elem.patient?.age?.no_of_years,

            elem.patient?.phone,
            elem.appointment_type,
            elem.account_id,
            elem.account_name,
            elem.appointment_date,
            elem.appointment_slot,
            elem.visit_type,
            elem.appointment_status,
            elem.appointment_action_tags,
            elem.appointment_action,
            elem.source,
            elem?.id,
            elem?.appointment_id,
            elem.patient?.age?.no_of_months,
            elem?.appointment_mode,
            elem?.patient_id,
            elem?.doctor_id
          );
        });
        setRows(_data);
        return;
      }
      if (table_tab === "Others") {
        const isStatusNotAllowed = (status) =>
          !["booked", "checked-in", "consulting", "scheduled", "done"].includes(
            status
          );
        let _data = [];
        _data = data?.rows?.map((elem) => {
          return createData(
            elem.patient?.name,
            elem.patient?.gender,
            elem.patient?.age?.no_of_years,

            elem.patient?.phone,
            elem.appointment_type,
            elem.account_id,
            elem.account_name,
            elem.appointment_date,
            elem.appointment_slot,
            elem.visit_type,
            elem.appointment_status,
            elem.appointment_action_tags,
            elem.appointment_action,
            elem.source,
            elem?.id,
            elem?.appointment_id,
            elem.patient?.age?.no_of_months,
            elem?.appointment_mode,
            elem?.patient_id,
            elem?.doctor_id
          );
        });
        setRows(_data);
        return;
      }

      setPage(0);
    } else {
      setRows([]);
    }
  }, [table_tab, data?.rows]);

  return (
    <>
      {isMobileScreen ? (
        <>
          {confirmedLoader ? (
            <Box sx={{ padding: "20px" }}>
              {" "}
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            <Box display={"flex"} flexDirection={"column"} gap={2} width={'100%'}>
              {rows?.length > 0 ? (
                <>
                  {rows.map((__row, index) => {
                    return (
                      <>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          padding={2}
                          sx={{
                            borderRadius: "10px",
                            boxShadow: `0px 0px 10px 4px ${theme.palette.white.medium}`,
                          }}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            gap={1}
                          >
                            <Box display={"flex"} gap={1}>
                              <Box>
                                <Box
                                  width={{ md: 20 }}
                                  component="img"
                                  alt="video"
                                  src={
                                    __row.appointment_type === "online"
                                      ? "/images/cameraIcon2.svg"
                                      : "/images/clinicIcon2.svg"
                                  }
                                  sx={{ marginTop: "2px" }}
                                />
                              </Box>
                              <Box display={"flex"} flexDirection={"column"}>
                                <Typography
                                  variant="caption"
                                  color={theme.palette.primary.main}
                                  fontWeight={"bold"}
                                >
                                  {`${__row.name} (${
                                    __row.gender.toLowerCase() === "female"
                                      ? "F"
                                      : __row.gender.toLowerCase() === "male"
                                      ? "M"
                                      : "O"
                                  }, ${__row.age}y)`}
                                </Typography>
                                <Typography variant="caption">
                                  {__row.phone_number}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              {getAppoitmentActions({
                                actions: __row.appointment_action,
                                subject: index,
                                key: __row.status,
                                setAppoitmentStatus: (key) =>
                                  setAppoitmentStatus(key, __row),
                                loader: loader,
                              })}
                              {__row?.appointment_action_tags?.length !== 0 && (
                                <>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                      menu ? "long-menu" : undefined
                                    }
                                    aria-expanded={menu ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                      setAnchorEl(e.currentTarget);
                                      setMenu(true);
                                      setSelectedRow(__row);
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </>
                              )}
                            </Box>
                          </Box>
                          <Divider sx={{ my: 1 }} />
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={1}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={600}
                                >
                                  Clinic
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color={theme.palette.white.lightText}
                                >
                                  {__row.account_name}
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={600}
                                >
                                  Date
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color={theme.palette.white.lightText}
                                >
                                  {moment(__row.date).format("DD MMM YYYY")}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={600}
                                >
                                  Time
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color={theme.palette.white.lightText}
                                >
                                  {__row.time}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={600}
                                >
                                  Visit Type
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color={theme.palette.white.lightText}
                                >
                                  {__row?.appointment_mode==="Follow-up" ? __row?.appointment_mode : __row.visit_type}
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={600}
                                >
                                  Status
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color={theme.palette.white.lightText}
                                >
                                  {getAppoitmentTags(__row.status)}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={600}
                                >
                                  Tags
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  color={theme.palette.white.lightText}
                                >
                                  {__row.source || "non-svaas"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                  <Box display={"flex"} gap={1}>
                    <Button
                      variant="outlined"
                      sx={{ flex: 1 }}
                      disabled={page <= 0}
                      onClick={(e) => {
                        handleChangePage(e, page - 1);
                      }}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ flex: 1 }}
                      disabled={(page + 1) * 10 >= totalData}
                      onClick={(e) => {
                        handleChangePage(e, page + 1);
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  {isSearch ? (
                    <Box
                      position={"relative"}
                      padding={2}
                      display={"flex"}
                      gap={2}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"center"}
                      sx={{
                        boxShadow: `0px 0px 10px 4px ${theme.palette.white.medium}`,
                      }}
                    >
                      <Box
                        width={{ md: 80 }}
                        component="img"
                        alt="No Appointments"
                        src={"/images/nosearchresultsimg.svg"}
                        sx={{}}
                      />
                      <Typography
                        fontWeight={"bold"}
                        textAlign={"center"}
                        sx={{ marginTop: "10px" }}
                      >
                        We couldn't find anything matching your search.
                      </Typography>
                      <Typography
                        variant="caption"
                        textAlign={"center"}
                        lineHeight={1}
                      >
                        Try again with a different term
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      position={"relative"}
                      padding={2}
                      display={"flex"}
                      gap={2}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"center"}
                      sx={{
                        boxShadow: `0px 0px 10px 4px ${theme.palette.white.medium}`,
                      }}
                    >
                      <Box
                        width={{ md: 80 }}
                        component="img"
                        alt="No Appointments"
                        src={"/images/noappointmentimg.svg"}
                        sx={{}}
                      />
                      <Typography
                        fontWeight={"bold"}
                        textAlign={"center"}
                        sx={{ marginTop: "10px" }}
                      >
                        There are no appointments in your schedule right now
                      </Typography>
                      <Typography
                        variant="caption"
                        textAlign={"center"}
                        color={theme.palette.white.dark}
                      >
                        SVAAS lets you see all the appointments for the day
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
          <Menu open={menu} anchorEl={anchorEl} onClose={() => setMenu(false)}>
            {selectedRow?.appointment_action_tags?.map((item) => {
              return (
                <MenuItem
                  key={item?.label}
                  onClick={() => {
                    setMoreOptions(item?.value, selectedRow);
                    setMenu(false);
                  }}
                >
                  {item?.label}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      ) : (
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: `calc(100vh - 270px)`,
            maxHeight: "100%",
          }}
        >
          {confirmedLoader ? (
            <Box sx={{ padding: "20px" }}>
              {" "}
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            <>
              {" "}
              <TableContainer
                sx={{ height: "100%", maxHeight: "100%", flex: 1 }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => {
                        if (index === 0 || index === columns.length - 1) {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              <Typography
                                variant="subtitle2"
                                fontWeight={"bold"}
                              >
                                {column?.label}
                              </Typography>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            <Typography variant="subtitle2" fontWeight={"bold"}>
                              {column?.label}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>

                  {rows?.length > 0 ? (
                    <TableBody>
                      {rows.map((__row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            sx={{ height: 80 }}
                          >
                            {/* {columns.map((column) => {
                              const value = __row[column.id];
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                          );
                          })} */}
                            <TableCell
                              key={columns[0].id}
                              align={columns[0].align}
                              sx={{
                                paddingX: 0,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  width={{ md: 20 }}
                                  component="img"
                                  alt="video"
                                  src={
                                    __row.appointment_type === "online"
                                      ? "/images/cameraIcon2.svg"
                                      : "/images/clinicIcon2.svg"
                                  }
                                  sx={{ marginTop: "0px" }}
                                />
                              </Box>
                            </TableCell>
                            <TableCell
                              key={columns[1].id}
                              align={columns[1].align}
                            >
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                              >
                                <Box display={"flex"} flexDirection={"column"}>
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={400}
                                  >
                                    {`${__row.name} (${
                                      __row.gender.toLowerCase() === "female"
                                        ? "F"
                                        : __row.gender.toLowerCase() === "male"
                                        ? "M"
                                        : "O"
                                    }, ${__row.age}y)
                                    `}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={400}
                                  >
                                    {__row.phone_number}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              key={columns[2].id}
                              align={columns[2].align}
                            >
                              <Typography variant="subtitle2" fontWeight={400}>
                                {__row.account_name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              key={columns[3].id}
                              align={columns[3].align}
                              sx={{ minWidth: 120 }}
                            >
                              <Typography variant="subtitle2" fontWeight={400}>
                                {moment(__row.date).format("DD MMM YYYY")}
                              </Typography>
                            </TableCell>
                            <TableCell
                              key={columns[4].id}
                              align={columns[4].align}
                            >
                              <Typography variant="subtitle2" fontWeight={400}>
                                {__row.time}
                              </Typography>
                            </TableCell>
                            <TableCell
                              key={columns[5].id}
                              align={columns[5].align}
                            >
                              <Typography variant="subtitle2" fontWeight={400}>
                                {__row?.appointment_mode==="Follow-up" ? __row?.appointment_mode : __row.visit_type}
                              </Typography>
                            </TableCell>
                            <TableCell
                              key={columns[6].id}
                              align={columns[6].align}
                              sx={{ minWidth: 110 }}
                            >
                              <Typography variant="subtitle2" fontWeight={400}>
                                {/* {__row.status === "Booked" ? "Scheduled" : __row.status} */}
                                {getAppoitmentTags(__row.status)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              key={columns[7].id}
                              align={columns[7].align}
                            >
                              <Typography variant="subtitle2" fontWeight={400}>
                                {__row.source || "non-svaas"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              key={columns[8].id}
                              align={columns[8].align}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {getAppoitmentActions({
                                  actions: __row.appointment_action,
                                  subject: index,
                                  key: __row.status,
                                  setAppoitmentStatus: (key) =>
                                    setAppoitmentStatus(key, __row),
                                  loader: loader,
                                })}
                                {__row?.appointment_action_tags?.length !==
                                  0 && (
                                  <>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={
                                        menu ? "long-menu" : undefined
                                      }
                                      aria-expanded={menu ? "true" : undefined}
                                      aria-haspopup="true"
                                      onClick={(e) => {
                                        setAnchorEl(e.currentTarget);
                                        setMenu(true);
                                        setSelectedRow(__row);
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={"100%"}
                        style={{ borderBottom: "none" }}
                      >
                        {isSearch ? (
                          <Box
                            position="relative"
                            padding={2}
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center" // Centers content vertically in the container
                            alignItems="center" // Centers content horizontally in the container
                            gap={1}
                            style={{
                              marginX: "auto",
                            }}
                          >
                            <Box
                              width={{ md: 200 }}
                              component="img"
                              alt="No Appointments"
                              src={"/images/nosearchresultsimg.svg"}
                              sx={{}}
                            />
                            <Typography
                              fontWeight={"bold"}
                              textAlign={"center"}
                              sx={{ marginTop: "10px" }}
                            >
                              We couldn't find anything matching your search.
                            </Typography>
                            <Typography
                              variant="caption"
                              textAlign={"center"}
                              lineHeight={1}
                            >
                              Try again with a different term
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            position="relative"
                            padding={2}
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center" // Centers content vertically in the container
                            alignItems="center" // Centers content horizontally in the container
                            gap={1}
                            style={{
                              marginX: "auto",
                            }}
                          >
                            <Typography
                              fontWeight={"bold"}
                              sx={{ marginTop: "10px" }}
                            >
                              Welcome to Appointments
                            </Typography>
                            <Box
                              width={{ md: 80 }}
                              component="img"
                              alt="No Appointments"
                              src={"/images/noapppointmentsImage.svg"}
                              sx={{}}
                            />
                            <Typography
                              variant="caption"
                              textAlign={"center"}
                              color={theme.palette.white.dark}
                            >
                              Remind patients about their upcoming appointments.
                              <br />
                              Get started by adding your first set of
                              appointments here
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color={theme.palette.primary.main}
                              sx={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={() => handleNewAppointmentClick()}
                            >
                              Add an appointment
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                // rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalData}
                rowsPerPage={10}
                rowsPerPageOptions={[10]}
                page={page}
                onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Menu
                open={menu}
                anchorEl={anchorEl}
                onClose={() => setMenu(false)}
              >
                {selectedRow?.appointment_action_tags?.map((item) => {
                  return (
                    <MenuItem
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      onClick={() => {
                        setMoreOptions(item?.value, selectedRow);
                        setMenu(false);
                      }}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </Paper>
      )}
    </>
  );
};

const SchedulerComponent = ({
  appointments,
  getBackDateAppointments,
  getNextDateAppointments,
  setAppoitmentStatus,
  confirmedLoader,
  loader,
  setMoreOptions,
}) => {
  const theme = useTheme();

  const [divHeight, setDivHeight] = useState(0);
  const [divWidth, setDivWidth] = useState(0);
  const [slotWidth, setSlotWidth] = useState(0);
  const [slotHeight, setSlotHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [timelineContainerHeight, setTimelineContainerHeight] = useState(0);
  const [days, setDays] = useState(() => {
    const currentDate = new Date();
    return Array(7)
      .fill(null)
      .map((_, index) => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + index);
        return newDate;
      });
  });

  const [_appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState({
    name: "",
    gender: "",
    age: "",
    patient_id: "",
    visit_type: "",
    phone_number: "",
    doctor_name: "",
    chief_complaints: "",
    startDate: new Date(),
    endDate: new Date(),
  });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const slotInterval = 30;

  const ref = useRef(null);
  const slotRef = useRef(null);
  const headerRef = useRef(null);
  const timelineContainerRef = useRef(null);

  const months_short = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const daysOfWeek = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];

  const timeSlots = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute of [0, 30]) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      timeSlots.push(`${formattedHour}:${formattedMinute}`);
    }
  }

  const maxMinutes = 1440;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function convertLocaleTimeStringToMinutes(time) {
    let arr = time.split(":");
    let currMinutes = arr[0] * 60 + +arr[1];

    return currMinutes;
  }

  function findEventHeight(startTime, endTime) {
    let startMinutes = convertLocaleTimeStringToMinutes(startTime);
    let endMinutes = convertLocaleTimeStringToMinutes(endTime);

    let pixels = Math.floor(
      divHeight * ((endMinutes - startMinutes) / maxMinutes)
    );

    return pixels;
  }

  function findEventStart(startTime, slots) {
    // return Math.floor(divHeight*(convertLocaleTimeStringToMinutes(startTime)/maxMinutes))+22
    let eventMinutes = convertLocaleTimeStringToMinutes(startTime);
    let slotStartMinutes = convertLocaleTimeStringToMinutes(slots);
    return Math.floor(
      slotHeight * ((eventMinutes - slotStartMinutes) / slotInterval)
    );
  }

  useEffect(() => {
    if (ref != null) {
      setDivHeight(ref.current?.scrollHeight);
      setDivWidth(ref.current?.offsetWidth);
    }
    if (slotRef != null) {
      setSlotWidth(slotRef.current?.offsetWidth);
      setSlotHeight(slotRef.current?.offsetHeight);
    }
    if (headerRef != null) {
      setHeaderHeight(headerRef.current?.offsetHeight);
    }
    if (timelineContainerRef != null) {
      setTimelineContainerHeight(timelineContainerRef.current?.scrollHeight);
    }
  }, [ref, slotRef, headerRef, timelineContainerRef]);

  useEffect(() => {
    if (ref != null) {
      const maxMinutes = 1440;
      let currMinutes = convertLocaleTimeStringToMinutes(
        new Date(new Date().toUTCString()).toLocaleTimeString("it-IT", {
          hour12: false,
        })
      );

      function convertLocaleTimeStringToMinutes(time) {
        let arr = time.split(":");
        let currMinutes = arr[0] * 60 + +arr[1];

        return currMinutes;
      }

      let offset = Math.floor(divHeight * (currMinutes / maxMinutes));
      ref.current.scrollTop = offset - 150;
    }
  }, [divHeight]);

  const handleClick = (event, app) => {
    setAnchorEl(event.currentTarget);
    setSelectedAppointment(app);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNextWeekClick = () => {
    setDays(
      days.map((day) => {
        return new Date(day.getTime() + 7 * 24 * 60 * 60 * 1000);
      })
    );
    getNextDateAppointments("7");
  };

  const handlePreviousWeekClick = () => {
    setDays(
      days.map((day) => {
        return new Date(day.getTime() - 7 * 24 * 60 * 60 * 1000);
      })
    );
    getBackDateAppointments("7");
  };

  useEffect(() => {
    if (appointments?.rows) {
      let _appointments = appointments?.rows?.filter((a) => {
        return (
          a.appointment_status !== "No-Show" &&
          a.appointment_status !== "Missed"
        );
      });

      let _data = [];

      _appointments?.map((item) => {
        let startDate = new Date(
          item.appointment_date +
            " " +
            moment(item.appointment_slot, "hh:mm A").format("HH:mm")
        );
        _data.push({
          ...item,
          startDate: startDate,
          endDate: new Date(startDate.getTime() + 600000),
          name: item?.patient?.name,
          gender: item?.patient?.gender,
          age: item?.patient?.age?.no_of_years,
          patient_id: item?.patient?.id,
          visit_type: item?.visit_type,
          phone_number: item?.patient?.phone,
          doctor_name: item?.doctor_name,
          chief_complaints: "",
          months: item?.patient?.age?.no_of_months,
        });
      });
      setAppointments(_data);
    }
  }, [appointments?.rows]);

  const getAppointmentColor = (val) => {
    switch (val) {
      case "done":
        return theme.palette.primary.main;
      case "booked":
        return "#21A6F1";
      case "checked-in":
        return "#21A6F1";
      case "consulting":
        return "#21A6F1";
      case "scheduled":
        return "#21A6F1";
      case "canceled":
        return "#FB1D20";
      case "declined":
        return "#FB1D20";
      default:
        return "#FB1D20";
    }
  };

  return (
    <>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: `calc(100vh - 240px)`,
          overflowY: "auto",
          background: "white",
          marginTop: "10px",
        }}
      >
        <Box
          ref={timelineContainerRef}
          sx={{
            position: "relative",
            width: "1px",
            height: divHeight - headerHeight,
            top: headerHeight,
          }}
        >
          <Timeline divHeight={divHeight - headerHeight} divWidth={divWidth} />
        </Box>

        <Grid
          container
          spacing={0}
          height={"100%"}
          sx={{ position: "relative", borderRadius: "0" }}
        >
          <Grid item xs={1} spacing={0}>
            <Item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50px",
                margin: 0,
                borderRadius: "0",
                border: "0.5px solid #bababa",
                position: "sticky",
                top: 0,
                zIndex: 5,
              }}
            >
              <Box
                onClick={handlePreviousWeekClick}
                sx={{
                  cursor: "pointer",
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >{`<`}</Box>
              <Box
                onClick={handleNextWeekClick}
                sx={{
                  cursor: "pointer",
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >{`>`}</Box>
            </Item>
            {timeSlots.map((elem) => {
              return (
                <Item
                  sx={{
                    height: "100px",
                    margin: 0,
                    borderRadius: "0",
                    border: "0.5px solid #bababa",
                  }}
                >
                  {elem}
                </Item>
              );
            })}
          </Grid>
          {days.map((day) => {
            return (
              <Grid item xs>
                <Item
                  ref={headerRef}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50px",
                    margin: 0,
                    borderRadius: "0",
                    border: "0.5px solid #bababa",
                    position: "sticky",
                    top: 0,
                    zIndex: 5,
                    fontSize: "12px",
                    fontWeight: "bold",
                    backgroundColor: `${
                      new Date().toLocaleDateString() ===
                      day.toLocaleDateString()
                        ? theme.palette.primary.light
                        : "white"
                    }`,
                  }}
                >{`${daysOfWeek[day.getDay()]}, ${day.getDate()} ${
                  months_short[day.getMonth()]
                }`}</Item>
                {timeSlots.map((slots) => {
                  return (
                    <Item
                      ref={slotRef}
                      sx={{
                        position: "relative",
                        height: "100px",
                        margin: 0,
                        borderRadius: "0",
                        border: "0.5px solid #bababa",
                        overflow: "visible",
                        backgroundColor: `${
                          new Date().toLocaleDateString() ===
                          day.toLocaleDateString()
                            ? theme.palette.primary.light
                            : "white"
                        }`,
                      }}
                    >
                      <Divider
                        width={"100%"}
                        light={true}
                        position="relative"
                        top={"50%"}
                      />
                      <Box
                        border={"1px dashed #bababa"}
                        width={"100%"}
                        position={"relative"}
                        top={"50%"}
                      ></Box>
                      {confirmedLoader && (
                        <>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </>
                      )}
                      {_appointments
                        ?.filter((app) => {
                          //   console.log("date strings", app.appointment_date);
                          // console.log("slots1",new Date(day.toDateString()+" "+slots),app.startDate )
                          // console.log("slots2",new Date(day.toDateString()+" "+timeSlots[timeSlots.indexOf(slots)+1]),app.startDate )
                          return (
                            app?.startDate?.toDateString() ===
                              day?.toDateString() &&
                            new Date(day?.toDateString() + " " + slots) <=
                              app.startDate &&
                            new Date(
                              day?.toDateString() +
                                " " +
                                timeSlots[timeSlots.indexOf(slots) + 1]
                            ) >= app.startDate
                          );
                        })
                        .map((app, index) => {
                          return (
                            <>
                              <Box
                                aria-describedby={id}
                                position={"relative"}
                                key={index}
                                width={`${slotWidth}px`}
                                backgroundColor={"#F3F4F6"}
                                onClick={(e) => {
                                  handleClick(e, app);
                                }}
                                height={`${findEventHeight(
                                  new Date(
                                    new Date(app.startDate).toUTCString()
                                  ).toLocaleTimeString("it-IT", {
                                    hour12: false,
                                  }),
                                  new Date(
                                    new Date(app.endDate).toUTCString()
                                  ).toLocaleTimeString("it-IT", {
                                    hour12: false,
                                  })
                                )}px`}
                                sx={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  display: "flex",
                                  zIndex: 2,
                                  top: `${findEventStart(
                                    new Date(
                                      new Date(app.startDate).toUTCString()
                                    ).toLocaleTimeString("it-IT", {
                                      hour12: false,
                                    }),
                                    slots
                                  )}px`,
                                  borderRadius: "10px",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  height={"100%"}
                                  width={"5px"}
                                  backgroundColor={getAppointmentColor(
                                    app?.appointment_status?.toLowerCase()
                                  )}
                                ></Box>
                                <Box
                                  height={"100%"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "5px",
                                  }}
                                >
                                  {app.name}
                                </Box>
                              </Box>
                            </>
                          );
                        })}
                    </Item>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        elevation={1}
      >
        <Box
          width={"400px"}
          height={"auto"}
          display={"flex"}
          position={"relative"}
        >
          <Box
            position={"absolute"}
            height={"100%"}
            width={"5px"}
            backgroundColor={getAppointmentColor(
              selectedAppointment?.appointment_status?.toLowerCase()
            )}
          ></Box>
          <Box padding={2} flex={1}>
            <Box display={"flex"}>
              <Box>
                <Box
                  width={{ md: 20 }}
                  component="img"
                  alt="video"
                  src={
                    selectedAppointment?.appointment_type === "online"
                      ? "/images/cameraIcon2.svg"
                      : "/images/clinicIcon2.svg"
                  }
                  sx={{
                    marginRight: "6px",
                    marginTop: 1,
                  }}
                />
              </Box>
              <Box flex={1}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle1" fontWeight={"bold"}>{`${
                    selectedAppointment?.name
                  } (${
                    selectedAppointment?.gender.toLowerCase() === "male"
                      ? "M"
                      : selectedAppointment?.gender.toLowerCase() === "female"
                      ? "F"
                      : "O"
                  }, ${selectedAppointment?.age}y${
                    selectedAppointment?.months > 0
                      ? ` ${selectedAppointment?.months}m`
                      : ""
                  })`}</Typography>
                  <Button
                    variant="contained"
                    color={"secondary"}
                    sx={{ borderRadius: "20px" }}
                    disableElevation
                    disableRipple
                  >
                    <Typography color={theme.palette.white.main}>
                      {selectedAppointment?.source?.toUpperCase()}
                    </Typography>
                  </Button>
                </Box>
                <Box
                  marginBottom={"10px"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={0.5}
                >
                  <Box display={"flex"} gap={0.5}>
                    <BadgeOutlinedIcon fontSize="small" />
                    <Typography variant="subtitle2" fontWeight={400}>
                      {selectedAppointment?.patient_hid}
                    </Typography>
                  </Box>
                  <Box display={"flex"} gap={0.5}>
                    <CallIcon fontSize="small" />
                    <Typography variant="subtitle2" fontWeight={400}>
                      {selectedAppointment?.phone_number}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              marginBottom={"10px"}
              width={"100%"}
              padding={2}
              backgroundColor={theme.palette.primary.light}
              sx={{
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="subtitle2" fontWeight={"bold"}>
                  {selectedAppointment?.startDate.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}{" "}
                  for{" "}
                  {(selectedAppointment?.endDate?.getTime() -
                    selectedAppointment?.startDate?.getTime()) /
                    1000 /
                    60}{" "}
                  mins
                </Typography>
                <Typography variant="subtitle2" fontWeight={"bold"}>
                  {!selectedAppointment?.doctor_name?.includes("Dr ") ? "Dr" : ""}{" "}
                  {selectedAppointment?.doctor_name}
                </Typography>
              </Box>
              <Box>
                {selectedAppointment?.visit_type && (
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: "20px",
                      backgroundColor: theme.palette.white.main,
                      paddingX: 2,
                      paddingY: 1,
                    }}
                  >
                    <Typography
                      textTransform={"capitalize"}
                      color={theme.palette.primary.main}
                    >
                      {selectedAppointment?.visit_type}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {/* <Box marginBottom={"10px"}>
              <Typography variant="subtitle2" fontWeight={400}>
                Chief Complaints: {selectedAppointment?.chief_complaints}
              </Typography>
            </Box> */}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                color={theme.palette.primary.main}
                fontWeight={"bold"}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (selectedAppointment?.appointment_type === "offline") {
                    navigate(
                      `/${ROUTE_PRESCRIPTION(
                        selectedAppointment?.id
                      )}?showVideo=false&timeline=true&backbutton=true`
                    );
                  } else {
                    navigate(
                      `/${ROUTE_PRESCRIPTION(
                        selectedAppointment?.id
                      )}?showVideo=true&timeline=true&backbutton=true`
                    );
                  }
                }}
              >
                View History
              </Typography>

              {getAppoitmentActions({
                actions: selectedAppointment.appointment_action,
                subject: selectedAppointment.index,
                key: selectedAppointment.status,
                setAppoitmentStatus: (key) =>
                  setAppoitmentStatus(key, selectedAppointment),
                loader: loader,
                onClick: () => handleClose(),
              })}
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
const MobileSchedulerComponent = ({
  appointments,
  getBackDateAppointments,
  getNextDateAppointments,
  setAppoitmentStatus,
  confirmedLoader,
  loader,
  getDateValue,
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const navigate = useNavigate();
  const [divHeight, setDivHeight] = useState(0);
  const [divWidth, setDivWidth] = useState(0);
  const [slotWidth, setSlotWidth] = useState(0);
  const [slotHeight, setSlotHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [timelineContainerHeight, setTimelineContainerHeight] = useState(0);
  const [days, setDays] = useState([new Date()]);

  useEffect(() => {
    setDays([new Date(selectedDate["$d"])]);
  }, [selectedDate]);

  const [_appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState({
    name: "",
    gender: "",
    age: "",
    patient_id: "",
    visit_type: "",
    phone_number: "",
    doctor_name: "",
    chief_complaints: "",
    startDate: new Date(),
    endDate: new Date(),
    index: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const slotInterval = 30;

  const ref = useRef(null);
  const slotRef = useRef(null);
  const headerRef = useRef(null);
  const timelineContainerRef = useRef(null);

  const months_short = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const daysOfWeek = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];

  const timeSlots = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute of [0, 30]) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      timeSlots.push(`${formattedHour}:${formattedMinute}`);
    }
  }

  const maxMinutes = 1440;

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function convertLocaleTimeStringToMinutes(time) {
    let arr = time.split(":");
    let currMinutes = arr[0] * 60 + +arr[1];

    return currMinutes;
  }

  function findEventHeight(startTime, endTime) {
    let startMinutes = convertLocaleTimeStringToMinutes(startTime);
    let endMinutes = convertLocaleTimeStringToMinutes(endTime);

    let pixels = Math.floor(
      divHeight * ((endMinutes - startMinutes) / maxMinutes)
    );

    return pixels;
  }

  function findEventStart(startTime, slots) {
    // return Math.floor(divHeight*(convertLocaleTimeStringToMinutes(startTime)/maxMinutes))+22
    let eventMinutes = convertLocaleTimeStringToMinutes(startTime);
    let slotStartMinutes = convertLocaleTimeStringToMinutes(slots);
    return Math.floor(
      slotHeight * ((eventMinutes - slotStartMinutes) / slotInterval)
    );
  }

  useEffect(() => {
    if (ref != null) {
      setDivHeight(ref.current?.scrollHeight);
      setDivWidth(ref.current?.offsetWidth);
    }
    if (slotRef != null) {
      setSlotWidth(slotRef.current?.offsetWidth);
      setSlotHeight(slotRef.current?.offsetHeight);
    }
    if (headerRef != null) {
      setHeaderHeight(headerRef.current?.offsetHeight);
    }
    if (timelineContainerRef != null) {
      setTimelineContainerHeight(timelineContainerRef.current?.scrollHeight);
    }
  }, [ref, slotRef, headerRef, timelineContainerRef]);

  useEffect(() => {
    if (ref != null) {
      const maxMinutes = 1440;
      let currMinutes = convertLocaleTimeStringToMinutes(
        new Date(new Date().toUTCString()).toLocaleTimeString("it-IT", {
          hour12: false,
        })
      );

      function convertLocaleTimeStringToMinutes(time) {
        let arr = time.split(":");
        let currMinutes = arr[0] * 60 + +arr[1];

        return currMinutes;
      }

      let offset = Math.floor(divHeight * (currMinutes / maxMinutes));
      ref.current.scrollTop = offset - 150;
    }
  }, [divHeight]);

  const handleClick = (event, app, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedAppointment({ ...app, index });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleNextWeekClick = () => {
  //   setDays(
  //     days.map((day) => {
  //       return new Date(day.getTime() + 7 * 24 * 60 * 60 * 1000);
  //     })
  //   );
  //   getNextDateAppointments("7");
  // };

  // const handlePreviousWeekClick = () => {
  //   setDays(
  //     days.map((day) => {
  //       return new Date(day.getTime() - 7 * 24 * 60 * 60 * 1000);
  //     })
  //   );
  //   getBackDateAppointments("7");
  // };

  useEffect(() => {
    if (appointments?.rows) {
      let _data = [];
      appointments?.rows?.map((item) => {
        let startDate = new Date(
          item.appointment_date +
            " " +
            moment(item.appointment_slot, "hh:mm A").format("HH:mm")
        );
        _data.push({
          ...item,
          startDate: startDate,
          endDate: new Date(startDate.getTime() + 600000),
          name: item?.patient?.name,
          gender: item?.patient?.gender,
          age: getAge(item?.patient?.year_of_birth),
          patient_id: item?.patient?.id,
          visit_type: item?.visit_type,
          phone_number: item?.patient?.phone,
          doctor_name: item?.doctor_name,
          chief_complaints: "",
        });
      });
      setAppointments(_data);
    }
  }, [appointments?.rows]);

  const getAppointmentColor = (val) => {
    switch (val) {
      case "done":
        return theme.palette.primary.main;
      case "booked":
        return "rgba(244, 147, 154, 1)";
      case "checked-in":
        return "rgba(244, 147, 154, 1)";
      case "consulting":
        return "rgba(244, 147, 154, 1)";
      case "scheduled":
        return "rgba(244, 147, 154, 1)";
      case "canceled":
        return theme.palette.primary.negative;
      case "declined":
        return theme.palette.primary.negative;
      default:
        return theme.palette.primary.negative;
    }
  };

  return (
    <>
      <Box sx={{ boxShadow: `0px 0px 10px 4px ${theme.palette.white.medium}` }}>
        <LP dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={selectedDate}
            onChange={(val) => {
              setSelectedDate(val);
              getDateValue([val, val]);
            }}
          />
        </LP>
      </Box>

      <Box
        ref={ref}
        sx={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: `calc(100vh - 240px)`,
          overflowY: "auto",
          background: "white",
          marginTop: "10px",
        }}
      >
        <Box
          ref={timelineContainerRef}
          sx={{
            position: "relative",
            width: "1px",
            height: divHeight,
            top: 0,
          }}
        >
          <Timeline divHeight={divHeight} divWidth={divWidth} />
        </Box>

        <Grid
          container
          spacing={0}
          height={"100%"}
          sx={{ position: "relative", borderRadius: "0" }}
        >
          <Grid item xs={2} spacing={0}>
            {/* <Item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50px",
                margin: 0,
                borderRadius: "0",
                border: "0.5px solid #bababa",
                position: "sticky",
                top: 0,
                zIndex: 5,
              }}
            >
              <Box
                onClick={handlePreviousWeekClick}
                sx={{
                  cursor: "pointer",
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >{`<`}</Box>
              <Box
                onClick={handleNextWeekClick}
                sx={{
                  cursor: "pointer",
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >{`>`}</Box>
            </Item> */}
            {timeSlots.map((elem) => {
              return (
                <Item
                  sx={{
                    height: "100px",
                    margin: 0,
                    borderRadius: "0",
                    border: "0.5px solid #bababa",
                  }}
                >
                  {elem}
                </Item>
              );
            })}
          </Grid>
          {days.map((day) => {
            return (
              <Grid item xs>
                {/* <Item
                  ref={headerRef}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50px",
                    margin: 0,
                    borderRadius: "0",
                    border: "0.5px solid #bababa",
                    position: "sticky",
                    top: 0,
                    zIndex: 5,
                    fontSize: "12px",
                    fontWeight: "bold",
                    backgroundColor: `${theme.palette.white.main}`,
                  }}
                >
                  {`${daysOfWeek[day.getDay()]}, ${day.getDate()} ${
                    months_short[day.getMonth()]
                  }`}
                </Item> */}
                {timeSlots.map((slots) => {
                  return (
                    <Item
                      ref={slotRef}
                      sx={{
                        position: "relative",
                        height: "100px",
                        margin: 0,
                        borderRadius: "0",
                        border: "0.5px solid #bababa",
                        backgroundColor: `${theme.palette.white.main}`,
                        overflow: "visible",
                      }}
                    >
                      <Divider
                        width={"100%"}
                        light={true}
                        position="relative"
                        top={"50%"}
                      />
                      <Box
                        border={"1px dashed #bababa"}
                        width={"100%"}
                        position={"relative"}
                        top={"50%"}
                      ></Box>
                      {confirmedLoader && (
                        <>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </>
                      )}
                      {_appointments
                        ?.filter((app) => {
                          return (
                            app?.startDate?.toDateString() ===
                              day?.toDateString() &&
                            new Date(day?.toDateString() + " " + slots) <=
                              app.startDate &&
                            new Date(
                              day?.toDateString() +
                                " " +
                                timeSlots[timeSlots.indexOf(slots) + 1]
                            ) >= app.startDate
                          );
                        })
                        .map((app, index) => {
                          return (
                            <>
                              <Box
                                aria-describedby={id}
                                position={"relative"}
                                key={index}
                                width={`${slotWidth}px`}
                                backgroundColor={"#F3F4F6"}
                                onClick={(e) => {
                                  handleClick(e, app, index);
                                }}
                                height={`${findEventHeight(
                                  new Date(
                                    new Date(app.startDate).toUTCString()
                                  ).toLocaleTimeString("it-IT", {
                                    hour12: false,
                                  }),
                                  new Date(
                                    new Date(app.endDate).toUTCString()
                                  ).toLocaleTimeString("it-IT", {
                                    hour12: false,
                                  })
                                )}px`}
                                sx={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  zIndex: 2,
                                  top: `${findEventStart(
                                    new Date(
                                      new Date(app.startDate).toUTCString()
                                    ).toLocaleTimeString("it-IT", {
                                      hour12: false,
                                    }),
                                    slots
                                  )}px`,
                                  borderRadius: "10px",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  height={"100%"}
                                  width={"5px"}
                                  backgroundColor={getAppointmentColor(
                                    app?.appointment_status?.toLowerCase()
                                  )}
                                ></Box>
                                <Box
                                  height={"100%"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "5px",
                                  }}
                                >
                                  {app.name}
                                </Box>
                              </Box>
                            </>
                          );
                        })}
                    </Item>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Drawer
        anchor={"bottom"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            overflow: "visible",
          },
        }}
      >
        <Box
          width={"100%"}
          height={"auto"}
          display={"flex"}
          position={"relative"}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "-20px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: theme.palette.white.main,
              borderRadius: "50%",
              padding: 1,
              zIndex: 100,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
          {/* <Box
            position={"absolute"}
            height={"100%"}
            width={"5px"}
            backgroundColor={getAppointmentColor(
              selectedAppointment?.appointment_status?.toLowerCase()
            )}
          ></Box> */}
          <Box padding={2} flex={1}>
            <Box display={"flex"}>
              <Box>
                <Box
                  width={{ md: 20 }}
                  component="img"
                  alt="video"
                  src={
                    selectedAppointment?.appointment_type === "online"
                      ? "/images/cameraIcon2.svg"
                      : "/images/clinicIcon2.svg"
                  }
                  sx={{
                    marginRight: "6px",
                    marginTop: 1,
                  }}
                />
              </Box>
              <Box flex={1}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle1" fontWeight={"bold"}>{`${
                    selectedAppointment?.name
                  } (${
                    selectedAppointment?.gender.toLowerCase() === "male"
                      ? "M"
                      : selectedAppointment?.gender.toLowerCase() === "female"
                      ? "F"
                      : "O"
                  }, ${selectedAppointment?.age})`}</Typography>
                  <Button
                    variant="contained"
                    color={"secondary"}
                    sx={{
                      borderRadius: "20px",
                      padding: 1,
                    }}
                    disableElevation
                    disableRipple
                  >
                    <Typography
                      variant="caption"
                      fontSize={8}
                      color={theme.palette.white.main}
                    >
                      {selectedAppointment?.source?.toUpperCase()}
                    </Typography>
                  </Button>
                </Box>
                <Box marginBottom={"10px"}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    {selectedAppointment?.patient_hid}
                  </Typography>
                  <Typography variant="subtitle2" fontWeight={400}>
                    {selectedAppointment?.phone_number}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              marginBottom={"10px"}
              padding={2}
              backgroundColor={theme.palette.primary.light}
              sx={{
                borderRadius: "10px",
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="subtitle2" fontWeight={"bold"}>
                  {selectedAppointment?.startDate.toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}{" "}
                  for{" "}
                  {(selectedAppointment?.endDate?.getTime() -
                    selectedAppointment?.startDate?.getTime()) /
                    1000 /
                    60}{" "}
                  mins
                </Typography>
                <Typography variant="subtitle2" fontWeight={"bold"}>
                  {!selectedAppointment?.doctor_name?.includes("Dr ") ? "Dr" : ""}{" "}
                  {selectedAppointment?.doctor_name}
                </Typography>
              </Box>
              <Box>
                {selectedAppointment?.visit_type && (
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: "20px",
                      backgroundColor: theme.palette.white.main,
                      paddingX: 2,
                      paddingY: 1,
                    }}
                  >
                    <Typography
                      textTransform={"capitalize"}
                      color={theme.palette.primary.main}
                    >
                      {selectedAppointment?.visit_type}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {/* <Box marginBottom={"10px"}>
              <Typography variant="subtitle2" fontWeight={400}>
                Chief Complaints: {selectedAppointment?.chief_complaints}
              </Typography>
            </Box> */}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                color={theme.palette.primary.main}
                fontWeight={"bold"}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (selectedAppointment?.appointment_type === "offline") {
                    navigate(
                      `/${ROUTE_PRESCRIPTION(
                        selectedAppointment?.id
                      )}?showVideo=false&timeline=true&backbutton=true`
                    );
                  } else {
                    navigate(
                      `/${ROUTE_PRESCRIPTION(
                        selectedAppointment?.id
                      )}?showVideo=true&timeline=true&backbutton=true`
                    );
                  }
                }}
              >
                View History
              </Typography>

              {getAppoitmentActions({
                actions: selectedAppointment.appointment_action,
                subject: selectedAppointment.index,
                key: selectedAppointment.status,
                setAppoitmentStatus: (key) =>
                  setAppoitmentStatus(key, selectedAppointment),
                loader: loader,
                onClick: () => handleClose(),
              })}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

const Timeline = ({ divHeight, divWidth }) => {
  const theme = useTheme();

  const maxMinutes = 1440;
  let currMinutes = convertLocaleTimeStringToMinutes(
    new Date(new Date().toUTCString()).toLocaleTimeString("it-IT", {
      hour12: false,
    })
  );

  function convertLocaleTimeStringToMinutes(time) {
    let arr = time.split(":");
    let currMinutes = arr[0] * 60 + +arr[1];

    return currMinutes;
  }

  let offset = Math.floor(divHeight * (currMinutes / maxMinutes));

  return (
    <Box
      component={"div"}
      backgroundColor={theme.palette.primary.negative}
      sx={{
        position: "absolute",
        zIndex: 3,
        width: divWidth,
        height: "2px",
        top: `${offset}px`,
      }}
    ></Box>
  );
};

const TableFilterComponent = ({ disabled, tableFilterData }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const is1350px = useMediaQuery("(min-width:1350px)");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isMobileScreen ? (
        <Box
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "4px",
          }}
        >
          <IconButton
            disabled={disabled}
            sx={{ padding: "6px" }}
            onClick={handleClick}
          >
            <FilterAltIcon color="primary" fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="text"
          size="small"
          disabled={disabled}
          sx={{
            display: "flex",
            gap: 1,
            minWidth: "fit-content",
            maxWidth: "fit-content",
          }}
        >
          <FilterListIcon sx={{ fontSize: 16 }} />
          {is1350px && (
            <Typography textTransform={"none"} color={"black"}>
              Filters
            </Typography>
          )}
        </Button>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => tableFilterData("patient")}>
          Patient Name
        </MenuItem>
        <MenuItem onClick={() => tableFilterData("time")}>Visit Time</MenuItem>
        <MenuItem onClick={() => tableFilterData("status")}>Status</MenuItem>
      </Menu>
    </>
  );
};

const TableViewSelector = ({ handleAlignment, alignment }) => {
  const theme = useTheme();
  const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main, // Change this to your desired color
      color: "white",
    },
  });
  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      color="primary"
      size="small"
    >
      <StyledToggleButton value="left" aria-label="left aligned">
        <Tooltip title={"Calendar view"}>
          <CalendarTodayOutlinedIcon fontSize="small" />
        </Tooltip>
      </StyledToggleButton>

      <StyledToggleButton value="center" aria-label="centered">
        <Tooltip title={"List view"}>
          <FormatListBulletedOutlinedIcon fontSize="small" />
        </Tooltip>
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};

const CheckboxFilter = ({
  filterData,
  disabled,
  checkedClinic,
  checkedVideo,
  handleChangeClinic,
  handleChangeVideo,
}) => {
  useEffect(() => {
    // This will be called whenever checkedClinic or checkedVideo changes
    filterData(checkedClinic, checkedVideo);
  }, [checkedClinic, checkedVideo]);

  return (
    <Box
      padding={"0px 4px"}
      borderRadius={"6px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={1}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <Checkbox
          checked={checkedClinic}
          disabled={disabled}
          onChange={handleChangeClinic}
          inputProps={{ "aria-label": "controlled" }}
          size="small"
          sx={{ width: "20px" }}
          disableRipple
        />
        <Box
          width={{ md: 20 }}
          component="img"
          alt="video"
          src={"/images/clinicIcon2.svg"}
          sx={{ marginRight: "6px" }}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
      >
        <Checkbox
          checked={checkedVideo}
          onChange={handleChangeVideo}
          disabled={disabled}
          inputProps={{ "aria-label": "controlled" }}
          size="small"
          sx={{ width: "20px" }}
          disableRipple
        />
        <Box
          width={{ md: 20 }}
          component="img"
          alt="video"
          src={"/images/cameraIcon2.svg"}
          sx={{ marginRight: "6px" }}
        />
      </Box>
    </Box>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: "10px",
  boxShadow: "none",
  borderRadius: "10px",
  border: "1px solid rgba(0,0,0,0.2)",
  overflow: "hidden",
}));

const ActionCard = ({
  data,
  setAppoitmentStatus,
  accountInfo,
  setCancelModal,
}) => {
  const theme = useTheme();

  return (
    <Item
    // sx={{
    //   boxShadow: `${"2px 2px 10px 0px #bfbfbf"}`,
    // }}
    >
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"space-between"}
        padding="10px"
        height={'70px'}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"start"}
          justifyContent={"center"}
          marginTop={2}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box
              width={{ md: 20 }}
              component="img"
              alt="video"
              src={
                data.appointment_type === "online"
                  ? "/images/cameraIcon2.svg"
                  : "/images/clinicIcon2.svg"
              }
              sx={{ marginRight: "6px" }}
            />
            <Typography
              variant="body2"
              fontWeight={500}
              color={theme.palette.primary.main}
            >{`${new Date(data.appointment_date).getDate()} ${new Date(
              data.appointment_date
            ).toLocaleString("default", { month: "short" })}, ${
              data.appointment_slot
            }`}</Typography>
          </Box>
          <Typography variant="caption">{accountInfo?.account_name}</Typography>
        </Box>

        <Box>
          {data?.appointment_status === "Pending" && (
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              gap={1}
              justifyContent={"flex-end"}
            >
              <Button
                variant="outlined"
                sx={{
                  padding: "4px 10px",
                  fontSize: "12px",
                  textTransform: "uppercase",
                }}
                onClick={() => setCancelModal("Declined", data)}
              >
                DECLINE
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "4px 10px",
                  fontSize: "12px",
                }}
                onClick={() => setAppoitmentStatus("Booked", data, "pending")}
              >
                ACCEPT
              </Button>
            </Box>
          )}
        </Box>

        <Box
          position={"absolute"}
          top={0}
          left={0}
          backgroundColor={theme.palette.negative.light}
          paddingX={1}
          borderRadius={"0 0 10px 0"}
        >
          <Box>
            <Typography
              color={theme.palette.primary.negative}
              variant="(caption)"
              fontWeight={500}
            >
              {data?.payment_status}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box padding="0px 10px">
        <Divider />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} padding="10px">
        <Box display={"flex"}>
          <Box></Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            justifyContent={"center"}
          >
            <Typography variant="body2" fontWeight={700}>
              {data.patient?.name} (
              {(data?.patient?.age && data?.patient?.gender === "female") ||
              data?.patient?.gender === "Female"
                ? "F"
                : data?.patient?.gender === "male" ||
                  data?.patient?.gender === "Male"
                ? "M"
                : "O"}
              , {data?.patient?.age?.no_of_years}y
              {/* {data?.patient?.age?.no_of_months > 0
                ? `${data?.patient?.age?.no_of_months}m`
                : ""} */}
              )
            </Typography>
            <Typography variant="caption">{data.patient?.phone}</Typography>
          </Box>
        </Box>
        <Box
          padding={"0px 10px"}
          height={"fit-content"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius={"16px"}
          backgroundColor={theme.palette.secondary.main}
        >
          <Typography
            variant="caption"
            sx={{
              textTransform: "uppercase",
            }}
            color={theme.palette.primary.white}
          >
            {data?.source}
          </Typography>
        </Box>
      </Box>
    </Item>
  );
};

const GradientCard = ({ text1, text2, type, selected = false }) => {
  const theme = useTheme();

  switch (type) {
    case "primary":
      return (
        <Box
          width={"100%"}
          height={"100%"}
          paddingX={3}
          paddingY={1.5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={"14px"}
          overflow={"hidden"}
          sx={{ border: `1px solid ${theme.palette.primary.main}` }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              color={theme.palette.primary.main}
            >
              {text1}
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              {text2}
            </Typography>
          </Box>
          <Box
            width={"50px"}
            height={"50px"}
            borderRadius={"50%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            backgroundColor={theme.palette.primary.medium}
          >
            <Box
              width={{ md: 30 }}
              component="img"
              alt="calender"
              src="/images/calenderIcon2.svg"
            />
          </Box>
        </Box>
      );
    case "secondary":
      return (
        <Box
          width={"100%"}
          height={"100%"}
          border={`1px solid ${theme.palette.secondary.dark}`}
          paddingX={3}
          paddingY={1.5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={"14px"}
          overflow={"hidden"}
          sx={{ backgroundColor: theme.palette.secondary.light }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              color={theme.palette.secondary.main}
            >
              {text1}
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              {text2}
            </Typography>
          </Box>

          <Box
            width={"50px"}
            height={"50px"}
            borderRadius={"50%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            backgroundColor={theme.palette.primary.white}
          >
            <StarIcon fontSize="large" color={"secondary"} />
          </Box>
        </Box>
      );
    case "negative":
      return (
        <Box
          width={"100%"}
          height={"100%"}
          border={`1px solid ${theme.palette.negative.dark}`}
          paddingX={3}
          paddingY={1.5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={"14px"}
          overflow={"hidden"}
          sx={{ backgroundColor: theme.palette.negative.light }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              color={theme.palette.negative.main}
            >
              {text1}
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              {text2}
            </Typography>
          </Box>
          <Box
            width={"50px"}
            height={"50px"}
            borderRadius={"50%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            backgroundColor={theme.palette.primary.white}
          >
            <StarIcon fontSize="large" color={"negative"} />
          </Box>
        </Box>
      );
    case "grey":
      return (
        <Box
          width={"100%"}
          height={"100%"}
          paddingX={3}
          paddingY={1.5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={"14px 14px 0px 0px"}
          overflow={"hidden"}
          sx={{
            backgroundColor: selected
              ? theme.palette.white.main
              : theme.palette.white.medium,
            boxShadow: `${selected ? "0px 4px 24px 0px rgba(0,0,0,0.08)" : ""}`,
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              color={selected ? "primary" : "black"}
            >
              {text1}
            </Typography>
            <Typography
              variant="body2"
              color={selected ? "primary" : "black"}
              fontWeight={selected ? 600 : 500}
            >
              {text2}
            </Typography>
          </Box>
        </Box>
      );
    case "mobile":
      return (
        <Box
          width={"100%"}
          height={"100%"}
          padding={0.8}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={"4px"}
          border={`1px solid ${theme.palette.white.medium}`}
          overflow={"hidden"}
          sx={{
            backgroundColor: selected
              ? theme.palette.white.main
              : theme.palette.white.medium,
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Typography
              variant="subtitle1"
              fontSize={18}
              fontWeight={600}
              color={selected ? "primary" : "black"}
              lineHeight={1.2}
            >
              {text1}
            </Typography>
            <Typography
              variant="caption"
              fontSize={12}
              fontWeight={500}
              color={selected ? "primary" : "black"}
            >
              {text2}
            </Typography>
          </Box>
        </Box>
      );
    case "mobileCalender":
      return (
        <Box
          width={"100%"}
          height={"100%"}
          padding={0.8}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={"4px"}
          border={`1px solid ${theme.palette.white.medium}`}
          overflow={"hidden"}
          sx={{
            backgroundColor: theme.palette.white.main,
            boxShadow: `0px 0px 8px 2px ${theme.palette.white.medium}`,
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Box
                width={"10px"}
                height={"10px"}
                borderRadius={"50%"}
                backgroundColor={
                  (text2 === "Scheduled" && theme.palette.blue.main) ||
                  (text2 === "Completed" && theme.palette.primary.main) ||
                  (text2 === "Others" && theme.palette.red.main)
                }
              />
              <Typography
                variant="subtitle1"
                fontWeight={600}
                color={"primary"}
              >
                {text1}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              fontWeight={500}
              color={selected ? "primary" : "black"}
            >
              {text2}
            </Typography>
          </Box>
        </Box>
      );

    default:
      return <></>;
  }
};

const ReminderComponent = ({ message }) => {
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"fit-content"}
      padding={"0px 16px"}
      height={"36px"}
      backgroundColor={theme.palette.white.medium}
      borderRadius={"10px"}
    >
      <Typography variant="body2" fontWeight={"600"}>
        {message}
      </Typography>
    </Box>
  );
};
