interface WebsiteIconProps {
    width?: string
    height?: string
    fill?: string
}
const WebsiteIcon = ({width, height, fill} : WebsiteIconProps) => {
    return(
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : "16"} height={height ? height : "16"} viewBox="0 0 16 16" fill={fill? fill : "none"}>
  <g clip-path="url(#clip0_2214_37979)">
    <path d="M7.9987 14.6667C11.6806 14.6667 14.6654 11.6819 14.6654 8.00004C14.6654 4.31814 11.6806 1.33337 7.9987 1.33337C4.3168 1.33337 1.33203 4.31814 1.33203 8.00004C1.33203 11.6819 4.3168 14.6667 7.9987 14.6667Z" stroke="#0A0666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.33203 8H14.6654" stroke="#0A0666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.9987 1.33337C9.66622 3.15894 10.6139 5.52806 10.6654 8.00004C10.6139 10.472 9.66622 12.8411 7.9987 14.6667C6.33118 12.8411 5.38353 10.472 5.33203 8.00004C5.38353 5.52806 6.33118 3.15894 7.9987 1.33337V1.33337Z" stroke="#0A0666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_2214_37979">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
        </>
    )
}
export default WebsiteIcon