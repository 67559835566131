import { ComponentType, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Skeleton,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";

import Card from "../../utils/Card";
import {
  fetchDoctorSuggestions,
  fetchPrescriptionDetails,
  fetchSignedURL,
  setEditRxSecMobile,
  setInstantRxData,
  setPastPrescriptions,
  setPrevPrescriptionDetails,
  setRxData,
  setUpdateRxData,
} from "../../../../../redux/reducers/prescription.slice";
import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import Prescription from "../../utils/Prescription";
import { useParams } from "react-router-dom";
import {
  ADVICE,
  ADVICE_NOTES,
  EXAMINATION,
  FOLLOW_UP,
  MEDICATION,
  OBG_HISTORY,
  OBG_NOTES,
  PATIENT_HISTORY,
  REFERRAL,
  SOCIAL_HISTORY,
} from "../../../../../shared/constants";
import { VITALS } from "../../../../../shared/constants";
import dayjs from "dayjs";
import {
  SectionComponentStruct,
  SectionComponents,
} from "../../utils/Constants";
import {
  handleDisablePreview,
  initDataUpdateAPI,
  isRxEmpty,
  isSectionEmpty,
} from "../../utils/Utilities";
import { renderHeaderButtons } from "../../utils/CardHeader";
import { isPatientHistoryEmpty } from "./patienthistory/utils/shared";
import { fetchDisplayUrlData } from "../../../../../shared/Utils/utils";

interface SectionComponentProps {
  id: string;
  component: ComponentType<JSX.Element>;
  formProps: any;
  componentProps: any;
}
interface RxProps {
  setActiveTab: (activeTab: number) => void;
}
export default function RX({ setActiveTab }: RxProps) {
  const [preConsultQuesnAccOpen, setPreConsultQuesnAccOpen] = useState(true);
  const [tabData, setTabData] = useState<Array<any>>([]);
  const tabDataRef = useRef(tabData);
  const [initialState, setInitialState] = useState<Array<any>>([]);
  const [focusedComponent, setFocusedComponent] = useState<string>();
  const [viewTypes, setViewTypes] = useState<string[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [signedURL, setSignedURL] = useState(null as any);
  const signedURLRef = useRef(signedURL);

  const dispatch = useDispatch();
  const currURLParams: any = useParams();

  const [preConsultQuesnState, setPreConsultQuesnState] = useState({
    chiefComplaints:'',
    patientHistory:'',
  })

  const currAppttId = currURLParams["appointment_id"];

  const { selectedAppointment, selectedTemplate } = useSelector(
    (state: any) => state.appointments
  );

  useEffect(()=>{
    if(selectedAppointment.partner_patient_data && typeof selectedAppointment.partner_patient_data == 'string'){
      const json = JSON.parse(selectedAppointment.partner_patient_data)
      if(json){
        setPreConsultQuesnState({
          chiefComplaints:json?.symptoms || '',
          patientHistory:json?.pastMedicalHistory?.diseaseName || '',
        })
      }
      
    }
  },[selectedAppointment])

  const miniTempSections = viewTypes.filter(
    (vt) =>
      vt !== VITALS &&
      vt !== PATIENT_HISTORY &&
      vt !== ADVICE &&
      vt !== REFERRAL &&
      vt !== FOLLOW_UP &&
      vt !== ADVICE_NOTES
  );

  const { profile } = useSelector((state: any) => state.auth);

  const {
    prescription: { instantRxData, editRxSecMobile, updateRxData },
  } = useSelector((state: any) => state.prescription);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleButtonClickMiniTMobile = (imageName: string) => {
    if (!isMobileScreen) return;
    if (imageName === "icon_save.svg") {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === editRxSecMobile) {
            return {
              ...section,
              openSaveTemplateMobile: !section?.openSaveTemplateMobile,
              searchExistingTemplateMobile: false,
            };
          } else {
            return section;
          }
        });
      });
    } else if (imageName === "icon_add_page.svg") {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === editRxSecMobile) {
            return {
              ...section,
              openSaveTemplateMobile: false,
              searchExistingTemplateMobile:
                !section.searchExistingTemplateMobile,
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const getPastPrescription = async () => {
    const response = await dispatch(
      fetchPrescriptionDetails({
        params: {
          type: "patient",
          limit: 10,
          id: selectedAppointment?.patient?.patient_id,
        },
      })
    );
    if (response?.payload?.data?.data?.length) {
      dispatch(setPastPrescriptions(response?.payload?.data?.data));
      const pastVitalDetails = await fetchDisplayUrlData(response?.payload?.data?.data[0]?.display_url);
      if(pastVitalDetails?.sections?.length > 0){
        dispatch(setPrevPrescriptionDetails([
         ...pastVitalDetails?.sections || [],
         {past_prescriptions:response?.payload?.data?.data[0]},
        ]))
      }
    } else {
      dispatch(setPastPrescriptions([]));
    }
  };

  const updateViewURLData = () => {
    if (initialState.length !== 0 && !_.isEqual(tabData, initialState)) {
      const updatedData = tabData.map((section) => {
        if (section.view_type === MEDICATION) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.map((medication: any) => {
                    if (
                      !medication.start_from ||
                      medication.start_from?.length === 0
                    ) {
                      return {
                        ...medication,
                        start_from: dayjs(new Date()).format(),
                      };
                    }
                    return medication;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
      setTabData(updatedData);
      if (updatedData.length !== 0) {
        initDataUpdateAPI(updatedData, signedURL);
      }
    }
  };

  const updateSignedURL = async () => {
    try {
      const response = await dispatch(
        fetchSignedURL({
          params: {
            type: "prescription",
            appointment_id: selectedAppointment?.appointment_id,
            doctor_id: selectedAppointment?.doctor_id,
            patient_id: selectedAppointment?.patient?.patient_id,
            template_id: selectedTemplate?.template_id,
            thb_appt_id: selectedAppointment?.id,
            doctor_name: profile?.name,
            appointment_hid: selectedAppointment?.appointment_hid,
            multi: true,
          },
        })
      );
      if (response?.payload?.status === 200) {
        setSignedURL(response?.payload?.data?.data);
        signedURLRef.current = response?.payload?.data?.data;
      }
      dispatch(fetchDoctorSuggestions());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // auto-save latest data on unmount

  useEffect(() => {
    return () => {
      if (
        tabDataRef.current &&
        Array.isArray(tabDataRef.current) &&
        signedURLRef.current?.url
      ) {
        const newData = _.cloneDeep(tabDataRef.current);
        initDataUpdateAPI(newData, signedURLRef.current);
      }
    };
  }, []);

  useEffect(() => {
    console.log({ id: selectedTemplate?.template_id });
    if (selectedTemplate?.template_id) {
      updateViewURLData();
      updateSignedURL();
      getPastPrescription();
      dispatch(fetchDoctorSuggestions());
    }
  }, [
    currAppttId,
    selectedAppointment?.appointment_id,
    selectedTemplate?.template_id,
  ]);

  useEffect(() => {
    if (signedURL?.status === "Done" ||  signedURL?.appt_status == 'Cancelled' || signedURL?.appt_status == 'Done') {
      handleShowPreview();
    }
    if ((signedURL?.display_url && initialState.length === 0) || updateRxData) {
      const request: Promise<AxiosResponse<any, any>> = axios.get(
        signedURL?.display_url
      );
      request.then((response: AxiosResponse) => {
        if (response.data?.sections) {
          const data = response.data?.sections;
          const updatedData = isMobileScreen
            ? data.map((sec: any) => {
                const notMiniTempSecVt = [
                  VITALS,
                  PATIENT_HISTORY,
                  ADVICE,
                  ADVICE_NOTES,
                  REFERRAL,
                  FOLLOW_UP,
                ];
                const includeMiniT =
                  !notMiniTempSecVt.includes(sec.view_type) && isMobileScreen
                    ? {
                        openSaveTemplateMobile: false,
                        searchExistingTemplateMobile: false,
                        handleButtonClickMiniTMobile:
                          handleButtonClickMiniTMobile,
                      }
                    : {};
                return { ...sec, isExpanded: false, ...includeMiniT };
              })
            : data;
          dispatch(setUpdateRxData(false));
          setTabData(updatedData);
          setInitialState(_.cloneDeep(response?.data?.sections));
        }
      });
    }
  }, [signedURL?.display_url, initialState.length, updateRxData]);

  useEffect(() => {
    setViewTypes(
      tabData
        .filter((section) => section?.view_type && !section?.disableView)
        ?.map((s) => s?.view_type)
    );
    dispatch(setRxData(tabData) as any);
    tabDataRef.current = tabData;
  }, [tabData]);

  useEffect(() => {
    if (
      !isMobileScreen ||
      (viewTypes.includes(editRxSecMobile) && isMobileScreen)
    ) {
      updateViewURLData();
    }
  }, [focusedComponent, editRxSecMobile]);

  const errorDataString = localStorage.getItem("errors");
  const errorData = errorDataString ? JSON.parse(errorDataString) : null;

  function hasNonEmptyValue(obj: any) {
    if (obj) return Object.keys(obj).some((key) => obj[key] !== "");
  }

  const handleShowPreview = () => {
    if (isMobileScreen) dispatch(setEditRxSecMobile("NoSectionActiveToEdit"));
    updateViewURLData();
    setShowPreview(true);
    // setActiveTab(1);
  };

  const updateSectionData = (event: any) => {
    event.preventDefault();
    const currentFocus = event.currentTarget;

    // const errorDataString = localStorage.getItem("errors");
    // const errorData = errorDataString ? JSON.parse(errorDataString) : null;

    // function hasNonEmptyValue(obj: any) {
    //   return Object.keys(obj).some((key) => obj[key] !== "");
    // }

    // if (errorData && hasNonEmptyValue(errorData)) {
    //   return;
    // }
    if (focusedComponent === currentFocus?.id) {
      return;
    }
    setFocusedComponent(currentFocus.id);
  };

  const sections: SectionComponentProps[] = viewTypes.map(
    (viewType: string) => {
      const compObj = SectionComponents.find(
        (data: SectionComponentStruct) =>
          data.key === viewType && !data.disableView
      );
      if (compObj) {
        return {
          id: viewType,
          component: compObj.component,
          formProps: {
            onClick: updateSectionData,
          },
          componentProps: {
            setTabData: setTabData,
            isFocused: focusedComponent === viewType,
            sectionData:
              tabData && tabData.length !== 0
                ? tabData?.filter((section) => section.view_type === viewType)
                : [],
            isSectionEmpty: isSectionEmpty(viewType, tabData),
          },
        };
      }
      return {
        id: viewType,
        component: () => <></>, // empty jsx node can be used for validation on particular render
        formProps: {},
        componentProps: {},
      };
    }
  );

  const currSecIndex = sections.findIndex(
    (section) => section.id === editRxSecMobile
  );
  const prevSec = viewTypes.find((_vt, index) => index === currSecIndex - 1);

  const nextSec = viewTypes.find((_vt, index) => index === currSecIndex + 1);

  const NextSecName = tabData.find(
    (s) => s.view_type === nextSec && !s.disableView
  )
    ? SectionComponents.find((s) => s.key === nextSec)?.name
    : undefined;

  const WrapSectionContent = (
    id: string,
    SectionComponent: ComponentType<JSX.Element>,
    formProps: any,
    componentProps: any
  ) => {
    return (
      <div
        key={id}
        style={{
          opacity:
            focusedComponent && !isMobileScreen && !componentProps.isFocused
              ? "50%"
              : "100%",
        }}
        {...formProps}
      >
        <SectionComponent {...componentProps} />
        {/* {id !==PATIENT_HISTORY ?<SectionComponent {...componentProps} /> : <p>history</p>} */}
      </div>
    );
  };

  const handleClearData = async () => {
    // check if patient history section has any row with disable_value key as true, if so, set to false and update
    const rowsWithDisabledValuePH = tabData?.find(
      (section: any) =>
        section.view_type === PATIENT_HISTORY &&
        section?.rows?.some((row: any) => row?.disable_value === true)
    );

    if (isRxEmpty(tabData) && !rowsWithDisabledValuePH) {
      setFocusedComponent("");
      console.log("Data is already empty");
      return;
    }

    setFocusedComponent("");

    const toClear = _.cloneDeep(tabData);

    const emptyData = toClear.map((section) => {


      if(section.view_type == OBG_HISTORY || section.view_type == OBG_NOTES){
        section.rows?.forEach((view:any)=>{
          view?.value?.forEach((obj:any)=>{
            obj.value = '';
          })
        })
      }
      else if (
        section.view_type !== ADVICE_NOTES &&
        section.view_type !== FOLLOW_UP &&
        section.view_type !== EXAMINATION
      ) {
        section.rows &&
          section.rows.forEach((row: any) => {
            if (Array.isArray(row.value)) {
              if (row.view_type && row.view_type === SOCIAL_HISTORY) {
                row.value.forEach((sHistory: any) => {
                  if (sHistory) {
                    Object.keys(sHistory).forEach(
                      (key) => key !== "name" && (sHistory[key] = "")
                    );
                  }
                });
              } else {
                row.disable_value = false;
                row.value = [];
              }
            } else {
              row.value = "";
            }
          });
      } else {
        if (section.rows[0].value[0]) {
          Object.keys(section.rows[0].value[0]).forEach(
            (key) => (section.rows[0].value[0][key] = "")
          );
        }
      }
      return section;
    });

    const apiRes: any = await initDataUpdateAPI(emptyData, signedURL);

    if (apiRes?.status === 200) {
      setTabData(emptyData);
      dispatch(setInstantRxData([] as any));
    }
  };

  return (
    <>
      {showPreview && (
        <Prescription
          TabData={tabData}
          EndPreview={setShowPreview}
          setActiveTab={setActiveTab}
        />
      )}
      {!showPreview && (
        <>
          {!viewTypes.includes(editRxSecMobile) && (
            <Accordion
              sx={{
                mb: isMobileScreen ? 0 : 2,
                "&.MuiPaper-root": {
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                },
              }}
              expanded={preConsultQuesnAccOpen}
            >
              <AccordionSummary
                expandIcon={
                  <Tooltip
                    title={!preConsultQuesnAccOpen ? "View more" : "Collapse"}
                  >
                    <img
                      onClick={() =>
                        setPreConsultQuesnAccOpen(!preConsultQuesnAccOpen)
                      }
                      src="/images/arrow_down.svg"
                      alt="arrow_expand"
                    />
                  </Tooltip>
                }
                id="rx-panel-header"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ marginRight: "12px" }}
                    src="/images/icon_dummy.svg"
                    alt="icon_dummy"
                  />
                  <span style={{ color: "#000000", fontWeight: "600" }}>
                    Patient Inputs from Pre Consult Questionnaire
                  </span>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    width: "calc(100% - 72px)",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {[
                      {
                        heading: "Chief Complaints",
                        details: preConsultQuesnState?.chiefComplaints || "No Data Available",
                      },
                      {
                        heading: "Patient Medical History",
                        details: preConsultQuesnState?.patientHistory || "No Data Available",
                      },
                    ].map((input, index) => {
                      return (
                        <div key={index}>
                          <h3
                            style={{
                              color: "#494E9D",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            {input.heading}:{" "}
                          </h3>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            {input.details.split(",").map((detail, index) => {
                              return (
                                <span
                                  style={{
                                    display: "flex",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    alignItems: "center",
                                  }}
                                  key={index}
                                >
                                  <span>{detail}</span>
                                  {index !==
                                    input.details.split(",").length - 1 && (
                                    <img
                                      style={{
                                        marginLeft: "8px",
                                        marginRight: "8px",
                                      }}
                                      src="/images/icon_separator.svg"
                                      alt="icon_separator"
                                    />
                                  )}
                                </span>
                              );
                            })}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  {!isMobileScreen ?<img src="/images/amico.svg" alt="" /> : null}
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
          {sections.length !== 0 ? (
            sections.map((Section: any) => {
              const { id, component, formProps, componentProps } = Section;

              const disabledView = Array.isArray(componentProps?.sectionData) ? componentProps?.sectionData?.[0]?.disableView : undefined;

              if (disabledView !== undefined && disabledView) {
                return;
              }

              const header_title = SectionComponents.find(
                (dt) => dt.key === id
              )?.name;
              const header_icon = SectionComponents.find(
                (dt) => dt.key === id
              )?.header_icon;
              if (!isMobileScreen) {
                return WrapSectionContent(
                  id,
                  component,
                  { ...formProps, id },
                  Section?.componentProps
                );
              }
              // mobile screen ui
              const isAccExpanded = componentProps.sectionData[0].isExpanded;

              return (
                <Accordion
                  sx={{
                    display:
                      viewTypes.includes(editRxSecMobile) &&
                      editRxSecMobile !== id
                        ? "none"
                        : "block",
                    boxShadow: "none",
                  }}
                  expanded={isAccExpanded}
                >
                  <AccordionSummary
                    sx={{
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        transform: `rotate(0)`,
                        display: editRxSecMobile === id ? "none" : "block",
                      },
                      "& .MuiAccordionSummary-content.Mui-expanded": {
                        justifyContent:
                          editRxSecMobile == id ? "space-between" : "unset",
                      },
                    }}
                    expandIcon={
                      <Tooltip title={"View more"}>
                        <img
                          id={id}
                          onClick={(event) => {
                            const id = event.currentTarget.id;
                            setTabData((prev) => {
                              return prev.map((section) => {
                                if (section.view_type === id) {
                                  return {
                                    ...section,
                                    isExpanded: !section.isExpanded,
                                  };
                                }
                                return section;
                              });
                            });
                          }}
                          src={`/images/${
                            isAccExpanded ? "tick_icon.svg" : "plus_icon.svg"
                          }`}
                          alt="arrow_expand"
                        />
                      </Tooltip>
                    }
                    id={`section-acc-${id}`}
                  >
                    <Box
                      className="showData"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {/* edit previous section arrow icon */}
                      {editRxSecMobile === id && (
                        <IconButton
                          sx={{
                            minWidth: "max-content",
                            padding: 0,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            if (
                              !currSecIndex ||
                              (currSecIndex === -1 && prevSec)
                            ) {
                              dispatch(setEditRxSecMobile(""));
                              setTabData((prev) => {
                                return prev.map((section) => {
                                  return {
                                    ...section,
                                    isExpanded: false,
                                  };
                                });
                              });
                            } else {
                              dispatch(setEditRxSecMobile(""));
                              setTabData((prev) => {
                                return prev.map((section) => {
                                  return {
                                    ...section,
                                    isExpanded: false,
                                  };
                                });
                              });
                            }
                          }}
                        >
                          <img
                            src={`/images/ArrowBackRounded.svg`}
                            alt="prev-section"
                            style={{ marginRight: "8px" }}
                          />
                        </IconButton>
                      )}
                      <img
                        style={{ marginRight: "10px" }}
                        src={`/images/${
                          header_icon ? header_icon : "icon_dummy.svg"
                        }`}
                        alt="section-header-icon"
                      />
                      <span
                        style={{
                          color: "#000000",
                          fontWeight: "600",
                          marginRight: "10px",
                        }}
                      >
                        {header_title}
                      </span>
                      {isAccExpanded && editRxSecMobile !== id && (
                        <IconButton
                          id={id}
                          sx={{
                            minWidth: "max-content",
                          }}
                          onClick={(event) => {
                            const id: any = event.currentTarget.id;
                            if (id !== editRxSecMobile) {
                              dispatch(setEditRxSecMobile(id));
                            }
                          }}
                        >
                          <img
                            src={`/images/edit_icon.svg`}
                            alt="init-edit-edit-section"
                          />
                        </IconButton>
                      )}
                    </Box>
                    {editRxSecMobile === id &&
                      miniTempSections.includes(editRxSecMobile) &&
                      renderHeaderButtons(
                        ["icon_save.svg", "icon_add_page.svg"],
                        handleButtonClickMiniTMobile
                      )}
                  </AccordionSummary>
                  <AccordionDetails>
                    {WrapSectionContent(
                      id,
                      component,
                      { ...formProps, id },
                      {
                        ...Section?.componentProps,
                        editSection: editRxSecMobile === id && isMobileScreen,
                      }
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          )}
          {sections.length !== 0 ? (
            <Card
              styles={{
                display: "flex",
                justifyContent: NextSecName ? "space-between" : "flex-end",
                alignItems: "center",
                paddingBottom: "15px",
                paddingTop: "15px",
                ...(viewTypes.includes(editRxSecMobile) &&
                  isMobileScreen && {
                    zIndex: "999",
                    position: "fixed",
                    right: "2.5%",
                    bottom: 0,
                    width: "86%",
                  }),
              }}
            >
              {!viewTypes.includes(editRxSecMobile) && (
                <>
                  <Button
                    sx={{
                      borderWidth: "2px",
                      borderRadius: "8px",
                      width: "87px",
                      fontWeight: "600",
                      "& :hover,": {
                        borderRadius: "8px",
                        borderWidth: "2px !important",
                      },
                    }}
                    onClick={handleClearData}
                    variant="outlined"
                  >
                    Clear
                  </Button>
                  <Button
                    id={signedURL?.id}
                    onClick={handleShowPreview}
                    sx={{ fontWeight: "600" }}
                    variant="contained"
                    disabled={handleDisablePreview(tabData)}
                  >
                    Preview Rx
                    <img
                      style={{ marginLeft: "10px" }}
                      src="/images/arrow_right.svg"
                      alt="arrow_right"
                    />
                  </Button>
                </>
              )}
              {viewTypes.includes(editRxSecMobile) && isMobileScreen && (
                <>
                  <Button
                    id={signedURL?.id}
                    sx={{
                      borderWidth: "2px",
                      borderRadius: "8px",
                      width: "136px",
                      fontWeight: "500",
                      fontSize:"12px",
                      padding:"7px",
                      textTransform: "none !important",
                      "& :hover,": {
                        borderRadius: "8px",
                        borderWidth: "2px !important",
                      },
                    }}
                    disabled={handleDisablePreview(tabData)}
                    onClick={handleShowPreview}
                    variant="outlined"
                  >
                    View Summary
                  </Button>
                  {NextSecName && (
                    <Button
                      disabled={!nextSec}
                      sx={{fontSize:"12px", textTransform: "none !important", fontWeight: 500, padding:"7px" }}
                      onClick={() => {
                        if (nextSec) {
                          dispatch(setEditRxSecMobile(nextSec as any));
                          setTabData((prev) => {
                            return prev.map((section) => {
                              if (section.view_type === prevSec) {
                                return {
                                  ...section,
                                  isExpanded: false,
                                };
                              }
                              if (section.view_type === nextSec) {
                                return {
                                  ...section,
                                  isExpanded: true,
                                };
                              }
                              return section;
                            });
                          });
                        } else {
                          dispatch(setEditRxSecMobile(""));
                        }
                      }}
                      variant="contained"
                    >
                      {NextSecName}
                      <img
                        style={{ marginLeft: "10px", width: "16px" }}
                        src="/images/arrow_right.svg"
                        alt="arrow_right"
                      />
                    </Button>
                  )}
                </>
              )}
            </Card>
          ) : (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          )}
        </>
      )}
    </>
  );
}
