import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HTMLAttributes, SyntheticEvent, useEffect, useState } from "react";
import CardHeader from "../../utils/CardHeader";
import Card from "../../utils/Card";
import { styled } from "@mui/system";
import { CARD_SHADOW } from "../../utils/Constants";
import { REFERRAL } from "../../../../../shared/constants";
import { setEditRxSecMobile } from "../../../../../redux/reducers/prescription.slice";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import CloseIcon from "@mui/icons-material/Close";

interface ReferralProps {
  speciality: string;
  dr_name: string;
  comment: string;
}

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "8px",
    paddingLeft: 0,
    border: "none",
  },
});

export default function Referral(props: any) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ReferralProps[]>([]);
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {} as ReferralProps,
  });

  const [searchValue, setSearchValue] = useState("");

  const sectionData = props?.sectionData?.[0]?.rows[0];
  const setTabData = props?.setTabData;
  const isFocused: boolean = props.isFocused || false;
  const specialityArr = sectionData?.master?.speciality?.values || [];
  const uniqueSpecilaityArray : string[] =  Array.from(new Set(specialityArr)) || [];
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(REFERRAL));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === REFERRAL
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  const handleDialogState = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
      // content :
    });
  };

  const dialogProps = {
    DialogOpen: dialog.open ? dialog.open : false,
    onHandleDialog: handleDialogState,
    headerStyles: { display: "none" },
    PaperPropsStyle: {
      margin: 0,
      width: "100%",
    },
    Layoutstyles: {
      "& .MuiDialog-container": {
        alignItems: "flex-end",
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
      },
    },
    footerStyles: {
      display: "none",
    },
    CloseButtonStyles:{
      display: "none",
    },
    onHandleSave: () => {
      // not required, custom save option is there
      return;
    },
  };

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === REFERRAL) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const addReferral = (_event: SyntheticEvent, value: string | null) => {
    if (!value) {
      return;
    }
    const newReferral: ReferralProps = {
      speciality: value,
      dr_name: "",
      comment: "",
    };
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === REFERRAL) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: [...row.value, newReferral],
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions(options.filter((option) => option !== value));
    setSearchValue("");
  };

  const removeReferral = (event: SyntheticEvent) => {
    const elem = event.target as HTMLElement;
    const { id } = elem;
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === REFERRAL) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (referral: any) => referral.speciality !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([...options, id]);
  };

  const assignAddReferralData = (event: any, value: string) => {
    if (!value) {
      return;
    }
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        speciality: value,
        dr_name: "",
        comment: "",
      },
    });
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        {data.map((datum, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
              }}
              key={index}
            >
              {datum.speciality}
            </Box>
          );
        })}
      </Box>
    );
  };

  const RefferalCardMobile = (Referral: ReferralProps, index: number) => {
    const { speciality, comment, dr_name } = Referral;
    return (
      <Card
        styles={{
          borderRadius: "8px",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
          padding: "0px",
        }}
        key={index}
      >
        <Box
          className="card-header"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px",
          }}
        >
          <Typography
            fontSize="16px"
            fontWeight="700"
            lineHeight="16px"
            color="#494E9D"
          >
            {speciality}
          </Typography>
          <IconButton>
            <Box
              id={speciality}
              aria-label="delete-referral"
              onClick={removeReferral}
              component="img"
              src="/images/DeleteIcon.svg"
            ></Box>
          </IconButton>
        </Box>
        <Divider />
        <Stack sx={{ padding: "12px" }} spacing={2}>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box
              component="img"
              marginRight="4px"
              src="/images/icon_dr.svg"
            ></Box>
            <Box>
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="100%"
                marginBottom="8px"
                color="rgba(0, 0, 0, 0.87)"
              >
                Doctor Name
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="400"
                lineHeight="160%"
                color="#6F6F6F"
              >
                {dr_name}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box
              component="img"
              marginRight="4px"
              src="/images/SpecInstrIcon.svg"
            ></Box>
            <Box>
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="100%"
                marginBottom="8px"
                color="rgba(0, 0, 0, 0.87)"
              >
                Notes
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="400"
                lineHeight="160%"
                color="#6F6F6F"
              >
                {comment}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Card>
    );
  };
  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
          }}
          title="Referral"
        >
          <CardHeader headerImg="icon_referral.svg" headerTitle="Referral">
            <></>
          </CardHeader>
          {data.length !== 0 && (
            <TableContainer sx={{ mb: 2, boxShadow: "none" }} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="all-medical-histories">
                <TableBody>
                  {data.map((referral, index) => {
                    const { speciality, dr_name, comment } = referral;
                    return (
                      <TableRow key={`complaint-${index}-${speciality}`}>
                        <StyledTableCell sx={{ width: 220 }}>
                          <TextField
                            title={speciality}
                            value={speciality}
                            label="Speciality"
                            fullWidth
                            variant="outlined"
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: 220 }}>
                          <TextField
                            title={dr_name}
                            value={dr_name}
                            label="Doctor name"
                            fullWidth
                            variant="outlined"
                            id={`${speciality}`}
                            autoComplete="off"
                            onChange={(event: any) => {
                              const id = event.target.id;
                              let value = event.target.value;
                              if (value.length > 250) {
                                value = value.substring(0, 250);
                              }
                              setData((prev) => {
                                return prev.map((elem: any) => {
                                  if (elem.speciality === id) {
                                    return {
                                      ...elem,
                                      dr_name: value,
                                    };
                                  }
                                  return elem;
                                });
                              });
                            }}
                            onBlur={(event) => {
                              setTabData((prev: any) => {
                                return prev.map((section: any) => {
                                  if (section.view_type === REFERRAL) {
                                    return {
                                      ...section,
                                      rows: section.rows.map(
                                        (row: any, index: number) => {
                                          if (index === 0) {
                                            return {
                                              ...row,
                                              value: row.value.map(
                                                (val: any) => {
                                                  if (
                                                    val.speciality ===
                                                    event.target.id
                                                  ) {
                                                    return {
                                                      ...val,
                                                      dr_name:
                                                        event.target.value,
                                                    };
                                                  }
                                                  return val;
                                                }
                                              ),
                                            };
                                          }
                                          return row;
                                        }
                                      ),
                                    };
                                  }
                                  return section;
                                });
                              });
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            autoComplete="off"
                            title={comment}
                            value={comment}
                            label="Notes"
                            fullWidth
                            id={`${speciality}`}
                            variant="outlined"
                            onChange={(event: any) => {
                              const id = event.target.id;
                              let value = event.target.value;
                              if (value.length > 250) {
                                value = value.substring(0, 250);
                              }
                              setData((prev) => {
                                return prev.map((elem: any) => {
                                  if (elem.speciality === id) {
                                    return {
                                      ...elem,
                                      comment: value,
                                    };
                                  }
                                  return elem;
                                });
                              });
                            }}
                            onBlur={(event) => {
                              setTabData((prev: any) => {
                                return prev.map((section: any) => {
                                  if (section.view_type === REFERRAL) {
                                    return {
                                      ...section,
                                      rows: section.rows.map(
                                        (row: any, index: number) => {
                                          if (index === 0) {
                                            return {
                                              ...row,
                                              value: row.value.map(
                                                (val: any) => {
                                                  if (
                                                    val.speciality ===
                                                    event.target.id
                                                  ) {
                                                    return {
                                                      ...val,
                                                      comment:
                                                        event.target.value,
                                                    };
                                                  }
                                                  return val;
                                                }
                                              ),
                                            };
                                          }
                                          return row;
                                        }
                                      ),
                                    };
                                  }
                                  return section;
                                });
                              });
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: "32px", padding: "0 !important" }}
                          align="right"
                        >
                          <Tooltip title={"Delete"}>
                            <button
                              id={speciality}
                              onClick={removeReferral}
                              style={{
                                outline: "none",
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                style={{ pointerEvents: "none" }}
                                src="/images/minus-in-circle.svg"
                                alt="remove-button"
                              />
                            </button>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Autocomplete
            id="search-by-speciality"
            filterOptions={(options, { inputValue }) => {
               return options.filter(option =>option.toLowerCase().includes(inputValue.toLowerCase()));
            }}
            options={uniqueSpecilaityArray}
            disableClearable
            value={""}
            clearOnBlur
            clearOnEscape
            inputValue={searchValue}
            popupIcon={false}
            clearIcon={false}
            onChange={addReferral}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for speciality"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (
                !data.some(
                  (speciality: any) => speciality.speciality === option
                )
              ) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {option}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Autocomplete
            id="search-by-speciality"
            filterOptions={(options, { inputValue }) => {
              return options.filter(option =>option.toLowerCase().includes(inputValue.toLowerCase()));
           }}
            options={uniqueSpecilaityArray}
            disableClearable
            value={""}
            clearOnBlur
            clearOnEscape
            inputValue={searchValue}
            popupIcon={false}
            clearIcon={false}
            onChange={(event, value) => {
              assignAddReferralData(event, value);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for speciality"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (
                !data.some(
                  (speciality: any) => speciality.speciality === option
                )
              ) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {option}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          <Box marginTop="16px">
            {data.length !== 0 &&
              data.map((referral, index) => {
                return (
                  <Box
                    component="div"
                    onClick={(_event: any) => {
                      if (_event.target.ariaLabel === "delete-referral") {
                        return;
                      }
                      setDialog({
                        ...dialog,
                        open: true,
                        mode: "UPDATE",
                        content: referral,
                      });
                    }}
                    key={index}
                  >
                    {RefferalCardMobile(referral, index)}
                  </Box>
                );
              })}
          </Box>
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
      {isMobileScreen && (
        <CustomizedDialogs {...(dialogProps as any)}>
          <Box>
            <Box className="header" sx={{ display: "flex", padding: "12px" }}>
              <Box
                component="img"
                sx={{ marginRight: 1.5 }}
                src="/images/icon_referral.svg"
              />
              <Box className="text-content" sx={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <Box>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  Referral
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="100%"
                  color="#494E9D"
                >
                  {dialog.content.speciality}
                </Typography>
                </Box>
                <Box onClick={handleDialogState}>
                  <CloseIcon sx={{ color: theme.palette.grey[500] }} />
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box className="card-content" padding={2}>
              <Stack spacing={2}>
                <TextField
                  autoComplete="off"
                  value={dialog.content?.dr_name}
                  label="Doctor name"
                  fullWidth
                  id={dialog.content.speciality}
                  variant="outlined"
                  multiline
                  onChange={(event: any) => {
                    const id = event.target.id;
                    const value = event.target.value;
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        dr_name: value,
                      },
                    });
                  }}
                />
                <TextField
                  autoComplete="off"
                  value={dialog.content?.comment}
                  label="Notes"
                  fullWidth
                  id={dialog.content.speciality}
                  variant="outlined"
                  multiline
                  onChange={(event: any) => {
                    const id = event.target.id;
                    let value = event.target.value;
                    if (value.length > 250) {
                      value = value.substring(0, 250);
                    }
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        comment: value,
                      },
                    });
                  }}
                />
              </Stack>
            </Box>
            <Box padding={2}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none !important",
                  borderRadius: "6px",
                }}
                fullWidth
                onClick={() => {
                  if (dialog.mode === "ADD") {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === REFERRAL) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: [...row.value, dialog.content],
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setSearchValue("");
                  }
                  if (dialog.mode === "UPDATE") {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === REFERRAL) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: row.value.map((val: any) => {
                                      if (val.id === dialog.content.id) {
                                        return {
                                          ...dialog.content,
                                        };
                                      }
                                      return val;
                                    }),
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                  }
                }}
              >
                {dialog.mode === "ADD" ? `Add` : `Update`} Referral
              </Button>
            </Box>
          </Box>
        </CustomizedDialogs>
      )}
    </>
  );
}
