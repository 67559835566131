// import * as dotenv from "dotenv";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./App.less";
// import { BUILD_TIME } from "./built-time";
import { ScreenType } from "./context/ScreenType";
import { persistor, store } from "./redux/store";
import RouteGuard from "./route-guards/routes";
import MUICustomTheme from "./MUICustomTheme";
import { AuthProvider } from "./AuthContext";
import { useEffect } from "react";
import { setToken } from "./redux/reducers/auth.slice";
import { LicenseInfo } from '@mui/x-license-pro';

// dotenv.config();

function App() {

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || '');

  const screenType = {
    desktop: useMediaQuery({ minWidth: 992 }),
    tablet: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    mobile: useMediaQuery({ maxWidth: 767 }),
  };

  useEffect(()=>{
    const token=new URL(window.location.href).searchParams.get("token")
    if(token && !localStorage.getItem('emrToken')){
      localStorage.setItem('emrToken',token)
    }
  },[])

  return (
    <AuthProvider>
      <MUICustomTheme>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ScreenType.Provider value={screenType}>
              <BrowserRouter>
                <RouteGuard />
              </BrowserRouter>
            </ScreenType.Provider>
          </PersistGate>
        </Provider>
      </MUICustomTheme>
    </AuthProvider>
  );
}

export default App;
