/* eslint-disable no-var */
/* eslint-disable prefer-const */
import { FC, useReducer, useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  useTheme,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginLayout from "../../../shared/layouts/LoginLayout";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { fetchProfile, setUser } from "../../../redux/reducers/auth.slice";
import { useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import OtpInput from 'react-otp-input';

const LoginOtp = () => {

    const [activeStep,setActiveStep] = useState(0);
    const [otp, setOtp] = useState('');

    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <>
          <LoginLayout>
            {activeStep===0 &&
                <>
                    <Typography
                    variant="h2"
                    fontSize={2}
                    gutterBottom
                    sx={{ marginBottom: 1 }}
                    >
                    <Typography
                        variant="h5"
                        sx={{ fontWeight: "bold", display: "inline" }}
                    >
                        Login with OTP
                    </Typography>
                    
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{marginBottom: 6}}
                    >
                        You will receive an OTP on registered mobile number 
                    </Typography>
                    <Stack spacing={2} mb={2}>
                    <TextField
                        label="Mobile Number"
                        type="number"
                        variant="outlined"
                        InputProps={{ startAdornment: "+91" }}
                        // value={state.username}
                        // onChange={changeUsername}
                    />
                    </Stack>
                    
                    <Button
                    variant="text"
                    sx={{width:'fit-content',my:1}}
                    onClick={()=>{navigate('/login')}}
                    >
                    <Typography textTransform={'none'}>Back to Sign in </Typography>
                    </Button>
            
                    <LoadingButton
                        variant="contained"
                        // loading={loading}
                        // disabled={!state.username || !state.password}
                        sx={{ marginBottom: 3, marginTop: 1 }}
                        // onClick={() => {
                        //   handleSigninSubmit();
                        // }}
                    >
                        SEND
                    </LoadingButton>
            
                    <Typography fontSize={12} 
                        // color={theme.palette.white.dark}
                    >
                    By continuing, you agree that we create an account for you (unless
                    already created), and accept our{" "}
                    <Typography
                        fontSize={12}
                        color={theme.palette.blue.lightHighlight}
                        sx={{
                        textDecoration: "underline",
                        display: "inline",
                        cursor: "pointer",
                        }}
                        onClick={() => {
                            window.open("https://svaaswellness.com/terms-conditions/")
                          }}
                    >
                        Terms and Conditions
                    </Typography>{" "}
                    and{" "}
                    <Typography
                        fontSize={12}
                        color={theme.palette.blue.lightHighlight}
                        sx={{
                        textDecoration: "underline",
                        display: "inline",
                        cursor: "pointer",
                        }}
                        onClick={() => {
                            window.open("https://svaaswellness.com/privacy-policy/")
                          }}
                    >
                        Privacy Policy
                    </Typography>
                    .
                    </Typography>
                </>
            }
            {activeStep===1 && 
                <>
                        <Typography
                            variant="h2"
                            fontSize={2}
                            gutterBottom
                            sx={{ marginBottom: 1 }}
                        >
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", display: "inline" }}
                        >
                            Login with OTP
                        </Typography>
                        
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{marginBottom: 6}}
                        >
                            You will receive an OTP on registered mobile number 
                        </Typography>
                        <Stack spacing={2} mb={2}>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{fontSize:'20px',padding:'16px',marginRight:'6px'}}
                        />
                        </Stack>
                        
                        <Button
                        variant="text"
                        sx={{width:'fit-content',my:1}}
                        //   onClick={changeRouteToForgotPassword.bind(this)}
                        >
                        <Typography textTransform={'none'}>Resend Code </Typography>
                        </Button>
                
                        <LoadingButton
                            variant="contained"
                            // loading={loading}
                            // disabled={!state.username || !state.password}
                            sx={{ marginBottom: 3, marginTop: 1 }}
                            // onClick={() => {
                            //   handleSigninSubmit();
                            // }}
                        >
                            VERIFY
                        </LoadingButton>
                
                        <Typography fontSize={12} 
                            // color={theme.palette.white.dark}
                        >
                        By continuing, you agree that we create an account for you (unless
                        already created), and accept our{" "}
                        <Typography
                            fontSize={12}
                            color={theme.palette.blue.lightHighlight}
                            sx={{
                            textDecoration: "underline",
                            display: "inline",
                            cursor: "pointer",
                            }}
                            onClick={() => {
                                window.open("https://svaaswellness.com/terms-conditions/")
                              }}
                        >
                            Terms and Conditions
                        </Typography>{" "}
                        and{" "}
                        <Typography
                            fontSize={12}
                            color={theme.palette.blue.lightHighlight}
                            sx={{
                            textDecoration: "underline",
                            display: "inline",
                            cursor: "pointer",
                            }}
                            onClick={() => {
                                window.open("https://svaaswellness.com/privacy-policy/")
                              }}
                        >
                            Privacy Policy
                        </Typography>
                        .
                        </Typography>
                </>
            }
                    
            </LoginLayout>
        </>
      );
}

export default LoginOtp;