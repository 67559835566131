const VideoIcon = ({ height = "20", width = "20", color = "#23BCB5" }) => (
  <>
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 16.5795V17.4828C17.0006 17.9563 16.9148 18.4251 16.7474 18.8626C16.5801 19.3001 16.3346 19.6977 16.025 20.0324C15.7153 20.3672 15.3476 20.6326 14.943 20.8135C14.5383 20.9943 14.1046 21.0871 13.6667 21.0865H5.33333C4.44964 21.0852 3.60249 20.7051 2.97762 20.0296C2.35276 19.3541 2.00118 18.4382 2 17.4828V8.47371C1.99942 8.00029 2.08524 7.53139 2.25255 7.09389C2.41986 6.65639 2.66537 6.25888 2.97502 5.92413C3.28466 5.58937 3.65235 5.32395 4.05703 5.14307C4.46172 4.9622 4.89543 4.86942 5.33333 4.87006L13.6667 4.86525C14.1047 4.86398 14.5387 4.95632 14.9437 5.13697C15.3486 5.31762 15.7165 5.58303 16.0263 5.91791C16.3361 6.25279 16.5815 6.65056 16.7486 7.08835C16.9157 7.52614 17.0012 7.99531 17 8.4689V9.37222M17 16.5795L20 19.0156C20.1861 19.1663 20.4074 19.2579 20.6391 19.2799C20.8707 19.302 21.1035 19.2537 21.3111 19.1405C21.5173 19.0352 21.6916 18.8685 21.8133 18.66C21.9365 18.4486 22.0012 18.2036 22 17.9537V8.02205C22.0006 7.77078 21.936 7.52437 21.8133 7.31093C21.6925 7.09678 21.5185 6.92372 21.3109 6.81155C21.1034 6.69939 20.8709 6.65264 20.64 6.67668C20.4085 6.70074 20.1874 6.79206 20 6.94096L17 9.37222M17 16.5795V9.37222"
        stroke="#494E9D"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </>
);

export default VideoIcon;
