import { useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ShowArticle } from "./articles";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch } from "react-redux";

const articleData = [
  {
    image: "/images/gastro-1.jpg",
    readDate: "2 min Read | 01 May ",
    Header:
      "Efficacy of VOS in showing reduced risk of recurrent Clostridioides difficile infection",
    Short_Summary:
      "According to a recent study, VOS, an oral microbiota therapeutic, reduced the risk of recurrent Clostridioides difficile infection (rCDI) at week 8. This study was published in the journal, Clinical infectious diseases.The ECOSPOR III trial was a phase 3, double-blind, placebo-controlled, randomized trial that included 182 adult participants with rCDI who were randomized to receive 4 capsules daily for 3 days of VOS or placebo following standard-of-care antibiotics. This post hoc analysis assessed the rate of rCDI through week 8, including Charlson comorbidity index (CCI) score category, baseline creatinine clearance, exposure to non-CDI-targeted antibiotics after dosing, number of CDI episodes, and acid-suppressing medication use at baseline.At the end of 8 weeks, comorbidities were common with a mean overall baseline age-adjusted CCI score of 4.1. However, the VOS-treated participants had a lower relative risk of recurrence across all subgroups analyzed. Hence, it can be concluded that VOS may reduce the risk of rCDI regardless of baseline characteristics, concomitant medications, or comorbidities.",
    Article_URL:
      "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
  },
  {
    image: "/images/gastro-2.jpg",
    readDate: "3 min Read | 30 April",
    Header: "Innovative Study on Gastrointestinal Disorders",
    Short_Summary:
      "A recent study found that TC-325 hemostasis powder achieved greater immediate hemostasis rates and lower 30-day rebleeding rates when compared to contemporary standard endoscopic treatment (SET). This study’s results were published in the journal, Gastroenterology.This study was a multicenter, randomized controlled trial that was patient-and outcome assessor-blinded. Patients who presented with active bleeding from an upper or lower GI lesion were randomly allocated to receive either TC-325 alone or standard endoscopic treatment (SET). A total of 106 patients made it in the study population (55 in TC-325, 51 in SET), with 1 exclusion in TC- 325 and 5 in SET. Primary outcome of the study was 30-day rebleeding while the secondary objectives were immediate hemostasis and other clinically relevant endpoints.It was observed that the 30-day rebleeding was significantly lower in the TC-325. The immediate hemostasis rates were 100% and 68.6% in the TC-325 group and SET group, respectively. The other secondary outcomes were not different between the two groups. From these results, it may be concluded that when compared to the contemporary SET, TC-325 hemostatic powder results in greater immediate hemostasis rates followed by lower 30-day rebleeding rates.",
    Article_URL:
      "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
  },
  {
    image: "/images/gastro-3.png",
    readDate: "2 min Read | 25 April",
    Header:
      "Comparison of radiofrequency ablation with hybrid argon plasma coagulation for eradicating Barrett's esophagus",
    Short_Summary:
      "A recent study suggests that hybrid argon plasma coagulation (H-APC) has slightly better outcomes than radiofrequency ablation (RFA) for eradicating Barrett’s esophagus following endoscopic resection. This study was published in the journal, Surgical Endoscopy.This prospective, randomized trial included 101 patients who were randomly assigned to either H-APC (n=54) or RFA (n=47). For the H-APC technique, 60W was applied while RFA consisted of a 90° focal catheter and a simplified protocol of 12 J/cm2 × 3 or with a Halo 360° balloon and 10 J/cm2/cleaning/10 J/cm2. Eradication rates as well as adverse events were reported. Follow-up examinations were done after 3, 6, 12 and 24 months. The median follow-up period for the short-term was 6.0 months while it was 21 months for long term. Overall, 211 ablations were performed.It was observed that eradication rates after long-term follow-up were 74.2% and 82.9% in the RFA and H-APC groups. The mean score of post-interventional pain was significantly higher at 4.56/10 and duration of 7.54 days while it was 2.07/10 over 3.59 days in the H-APC group. Stenosis that required intervention was reported in 3.7% of patients in the H-APC arm and 14.9% of those in the RFA arm.From the above results, it can be concluded that although both ablation techniques give good results with respect to the eradication rates, H-APC group showed slightly better outcome. Even the severity and duration of pain were significantly greater for the RFA group than the H-APC group.",
    Article_URL:
      "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
  },
  {
    image: "/images/gastro-4.jpg",
    readDate: "1 min Read | 20 April",
    Header:
      "Efficacy and safety of thalidomide for the treatment of recurrent bleeding due to small-intestinal angiodysplasia",
    Short_Summary:
      "A recent study suggests that thalidomide is safe and efficacious for use in treating recurrent bleeding due to small-intestinal angiodysplasia (SIA). This study’s results were published in The New England Journal of Medicine.This multicenter, randomized, double-blind, placebo-controlled trial included 150 patients with at least four episodes of recurrent bleeding during the previous year due to SIA. They were randomized to receive either 100 mg (n=51) or 50 mg (n=49) of oral thalidomide or placebo (n=50) for four months. The patients were followed up for at least one year after the treatment period. The primary endpoint of the study was effective response, marked by reduction of at least 50% of bleeding episodes after thalidomide treatment.At the end of the treatment period, it was seen that 68.6%, 51.0%, and 16.0% of the patients in the 100-mg thalidomide group, 50-mg thalidomide group, and placebo group, respectively showed effective response. From the above results, it can be concluded that treatment with thalidomide may result in a reduction in bleeding in patients with recurrent bleeding due to SIA.",
    Article_URL:
      "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
  },
];

const ArticlesDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentDate = new Date();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [like, setLike] = useState(new Array(articleData.length).fill(false));
  const [cardData, setCardData] = useState(location.state.cardData)
  const { Header, Short_Summary, Long_Summary, bannerImage } = cardData;
  const handleLike = (index: any) => {
    const updatedLikes = [...like];
    updatedLikes[index] = !updatedLikes[index];
    setLike(updatedLikes);
  };

  const handleScroll = (item : any) => {
     setCardData(item)
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "nearest",
      });
    }
  }
  return (
    <Box
     
      width="95vw"
      sx={{ overflowY: "scroll", background: "#fffff", height: "100vh" }}
    >
      <Box
      ref={ref}
        className="banner-section"
        sx={{
          backgroundImage:
            bannerImage === 1
              ? "url(/images/gastro-9.png)"
              : bannerImage === 2
              ? "url(/images/test-1.png)"
              : "url(/images/article-banner.png)",
          minHeight: "60vh",
          maxHeight:"70vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "93vw",
          backgroundPosition: "center",
        }}
      >
        <Box onClick={()=>{
            navigate('/articles')
        }} sx={{background:"#EEE", height:"34px", width:"92px", borderRadius:"4px", display:"flex", alignItems:"center", position:"relative", top:"40px", left:"40px", "&:hover":{
            cursor:"pointer"
        
        }}}>
            <ChevronLeftIcon sx={{color:"#5D5A88"}}/>
            <Typography>Go Back</Typography>
        </Box>
        <Box
          className="description-section"
          sx={{ marginLeft: "40px", paddingTop: "90px" }}
        >
          <Box
            className="intro-section"
            sx={{ display: "flex", alignItems: "center", gap: "20px" }}
          >
            <Box sx={{ borderRadius: "36.55px", border: "2px solid #83D2D8" }}>
              <Typography
                sx={{
                  padding: "0px 5px",
                  fontSize: "18px",
                  fontWeight: "800",
                  color: "#ffff",
                }}
              >
                Gastrointestinal Disorder
              </Typography>
            </Box>
            <Box
              className="read-date-section"
              sx={{ display: "flex", alignItems: "center", gap: "20px" }}
            >
              <Typography sx={{ color: "#ffff", fontWeight: "400" }}>
                {cardData?.readDate}
              </Typography>
            </Box>
          </Box>
          <Box
            className="heading-section"
            sx={{ width: "60%", marginTop: "20px" }}
          >
            <Typography
              sx={{ color: "#ffff", fontSize: "30px", fontWeight: "800" }}
            >
              {Header}
            </Typography>
            <Typography
              sx={{
                color: "#ffff",
                fontSize: "20px",
                fontWeight: "400",
                marginTop: "20px",
                display: "-webkit-box",
                WebkitLineClamp: 2, // Limit to four lines
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {`"${Short_Summary}"`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginLeft: "40px", marginTop: "40px" }}>
        <ShowArticle htmlContent={bannerImage === 1 || bannerImage === 2 ? Long_Summary : Short_Summary} />
      </Box>
      <Box className="other-article-section">
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "800",
            marginTop: "20px",
            marginLeft: "40px",
            color: "#5D5A88",
          }}
        >
          Other Articles
        </Typography>
        <Box
          className="article-sections"
          sx={{
            display: "flex",
            marginLeft: "40px",
            justifyContent: "space-between",
            marginRight: "40px",
            marginTop: "20px",
            marginBottom: "20px",
            gap:"10px"
          }}
        >
          {articleData.map((item: any, index: any) => (
            <Box
              className="main-card"
              sx={{
                boxShadow: "0 5px 10px rgba(107, 86, 151, .28)",
                background: "#ffff",
                borderRadius: "8px",
                minWidth: "250px",
                maxWidth:"100%",
              }}
            >
              <Box className="card-content-section" padding="10px">
                <Box className="card-image">
                  <Box
                    component="img"
                    src={item?.image}
                    sx={{ borderRadius: "10px 10px 0px 0px", minWidth:"250px", maxWidth:"400px", width:"100%" }}
                  />
                </Box>
                <Box
                  className="read-date-section"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography color="grey">{item?.readDate}</Typography>
                  <IconButton onClick={() => handleLike(index)}>
                    {like[index] ? (
                      <FavoriteIcon style={{ color: "red" }} />
                    ) : (
                      <FavoriteBorderIcon />
                    )}
                  </IconButton>
                </Box>
                <Box
                  className="main-heading"
                  marginTop="10px"
                  onClick={()=>handleScroll(item)}
                  sx={{maxWidth:"400px",minWidth:"250px"}}
                >
                  <Typography
                    fontSize="18px"
                    fontWeight="700"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2, // Limit to four lines
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {item?.Header}
                  </Typography>
                </Box>
                <Box
                  className="sub-heading"
                  sx={{ maxWidth: "400px", marginTop: "10px" }}
                >
                  <Typography
                    fontSize="12px"
                    color="grey"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 4, // Limit to four lines
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {item?.Short_Summary}
                  </Typography>
                </Box>
                <Box
                  className="read-more-section"
                  marginTop="20px"
                  display="flex"
                  alignItems="center"
                  gap="2px"
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#5225B5",
                      cursor: "pointer",
                    },
                  }}
                  onClick={()=>handleScroll(item)}
                >
                  <Typography color="#5225B5" fontSize="16px">
                    Read More
                  </Typography>

                  <ArrowForwardIcon style={{ color: "#5225B5" }} />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ArticlesDetails;
