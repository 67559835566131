import {
  Autocomplete,
  Box,
  IconButton,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {useTheme} from "@mui/material/styles"
import { SyntheticEvent, useEffect, useState } from "react";
import { TabComponent, allyProps } from "../RightPanel";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSignedURL,
  setInstantRxData,
} from "../../../../redux/reducers/prescription.slice";
import axios from "axios";
import { APIClient } from "../../../../shared/Utils/api-client";
import InstantRxIcons from "../../../../shared/icons/InstRxIcons";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchDisplayUrlData,
  getTemplateUrl,
} from "../../../../shared/Utils/utils";
import {
  ADVICE,
  ADVICE_NOTES,
  ALLERGIES,
  COMPLAINTS,
  DIAGNOSIS,
  EXAMINATION,
  FAMILY_HISTORY,
  FOLLOW_UP,
  INVESTIGATIONS,
  MEDICAL_HISTORY,
  MEDICATION,
  PATIENT_HISTORY,
  REFERRAL,
  SOCIAL_HISTORY,
  SURGICAL_HISTORY,
  VITALS,
} from "../../../../shared/constants";

interface InstantRxProps {
  type?: string;
  showInstantRx: boolean;
  setShowInstantRx: (showInstantRx: boolean) => void;
}
const InstantRx = ({
  type,
  showInstantRx,
  setShowInstantRx,
}: InstantRxProps) => {
  const dispatch = useDispatch();
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [tabActive, setTabActive] = useState(0);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState<string>();
  const [templateData, setTemplateData] = useState([]);
  const [displayUrl, setDisplayUrl] = useState<string>();
  const [templateId, setTemplateId] = useState<string>();
  const [buttonDisable, setButtonDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>();
  const {
    prescription: { rxData },
  } = useSelector((state: any) => state.prescription);
  const { selectedAppointment, selectedTemplate } = useSelector(
    (state: any) => state.appointments
  );

  useEffect(() => {
    if (type === "use-instant") {
      getTemplates();
    }
  }, []);

  const handleChange = async (_event: SyntheticEvent, newValue: number) => {
    setTabActive(newValue);
    setButtonDisable(true);
    if (newValue === 1) {
      getTemplates();
    }
  };

  const handleSaveInstantRx = async () => {
    const templateParam = {
      selectedAppointment: selectedAppointment,
      templateId: templateId,
      data: rxData,
      templateType: "instant_template",
      selectedTemplate: selectedTemplate,
    };
    setLoading(true);
    const response: any = await getTemplateUrl(templateParam);
    if (response?.data?.statusCode === 200) {
      const displayUrl = await axios.put(response?.data?.data?.url, {
        section: rxData,
      });
      if (displayUrl?.status === 200) {
        APIClient.post("prescription/save-template", {
          id: response?.data?.data?.id,
          filename: value,
          
        })
          .then((res) => {
            if (res?.data?.statusCode === 200) {
              setLoading(false);
              handleClose();
              setButtonDisable(true);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  const handleUpdateInstaRx = async () => {
    setLoading(true);
    const templateParam = {
      templateType: "update_instant_template",
      templateId: templateId,
    };
    const response: any = await getTemplateUrl(templateParam);
    if (response?.data?.statusCode === 200) {
      const displayUrl = await axios.put(response?.data?.data?.url, {
        section: rxData,
      });
      if (displayUrl?.status === 200) {
        setLoading(false);
        handleClose();
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const getTemplates = async () => {
    const response = await APIClient.get("prescription/get-template", {
      params: {
        type: "instant_template",
        template_id: selectedTemplate?.template_id,
      },
    });
    if (response?.data?.statusCode === 200) {
      setTemplateData(response?.data?.data);
      const optionsArr = response?.data?.data.map((templateName: any) => {
        const fileName = {
          label: templateName?.filename,
        };
        return fileName;
      });
      setOptions(optionsArr);
    }
  };

  const hasValue = (value: any) => {
    const strinppedValue = value?.replace(/<[^>]*>/g, "");
    if (strinppedValue?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleTemplates = (event: any, type:string) => {
    const chiefComplaintSection = rxData.filter(
      (section: any) => section?.view_type === COMPLAINTS
    );
    const DiagnosisSection = rxData.filter(
      (section: any) => section?.view_type === DIAGNOSIS
    );
    const ExaminationSection = rxData.filter(
      (section: any) => section?.view_type === EXAMINATION
    );
    const MedicationSection = rxData.filter(
      (section: any) => section?.view_type === MEDICATION
    );
    const LabInvstigationSection = rxData.filter(
      (section: any) => section?.view_type === INVESTIGATIONS
    );
    const ReferalSection = rxData.filter(
      (section: any) => section?.view_type === REFERRAL
    );
    const AdviceSection = rxData.filter(
      (section: any) => section?.view_type === ADVICE
    );
    const FollowUpSection = rxData.filter(
      (section: any) => section?.view_type === FOLLOW_UP
    );
    const AdditionalNoteSection = rxData.filter(
      (section: any) => section?.view_type === ADVICE_NOTES
    );
    const AdditionalSectionDataStatus = hasValue(
      AdditionalNoteSection[0]?.rows[0]?.value[0]?.value
    );
    const chiefComplaintSectionLength = chiefComplaintSection[0]?.rows.some(
      (value: any) => value?.value?.length > 0
    );
    const DiagnosisSectionLength = DiagnosisSection[0]?.rows?.some(
      (value: any) => value?.value?.length > 0
    );
    const ExamimationSectionDataStatus = hasValue(
      ExaminationSection[0]?.rows[0]?.value[0]?.comment
    );
    const MedicationSectionLength = MedicationSection[0]?.rows?.some(
      (value: any) => value?.value?.length > 0
    );
    const LabInvstigationSectionLength = LabInvstigationSection[0]?.rows.some(
      (value: any) => value?.value?.length > 0
    );
    const ReferalSectionLength = ReferalSection[0]?.rows.some(
      (value: any) => value?.value?.length > 0
    );
    const AdviceSectionLength = AdviceSection[0]?.rows.some(
      (value: any) => value?.value?.length > 0
    );
    const FollowUpSectionLength = FollowUpSection[0]?.rows.some(
      (value: any) => value?.value[0]?.date
    );
    if(
      chiefComplaintSectionLength ||
      DiagnosisSectionLength ||
      ExamimationSectionDataStatus ||
      MedicationSectionLength ||
      LabInvstigationSectionLength ||
      ReferalSectionLength ||
      AdviceSectionLength ||
      FollowUpSectionLength ||
      AdditionalSectionDataStatus){

    if(event.target.value.length > 0 && type === "save"){
      setValue(event.target.value);
      setButtonDisable(false);
    }
    else{
      setButtonDisable(true)
    }
     if(type === "update"){
      const data: any = templateData?.filter(
        (template: any) => template?.filename === event.target.outerText
      );
      if (data?.length > 0) {
        setTemplateId(data[0]?.id);
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    }
  }
  else{
     setButtonDisable(true)
  }
  }
  const handleClose = () => {
    setShowInstantRx(false);
  };

  const handleSearchTemplate = (event: any) => {
    if (event?.target?.outerText?.length > 0) {
      const data = templateData
        ?.filter(
          (template: any) => template?.filename === event.target.outerText
        )
        .map((url: any) => {
          return url?.display_url;
        });
      setDisplayUrl(data[0]);
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };
  const handleUseThisTemplate = async () => {
    setLoading(true);
    if (displayUrl) {
      const displayUrlData = await fetchDisplayUrlData(displayUrl);
      dispatch(setInstantRxData(displayUrlData));
      handleClose();
      setLoading(false)
    }
  };
  return (
    <Modal
      open={showInstantRx}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        className="main-rx-container"
        sx={{
          background: "#fff",
          minWidth: isMobileScreen ? "365px" : "552px",
          height: "fit-content",
          borderRadius: "14px",
        }}
      >
        <Box
          className="rx-header"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="19px 16px 0px 26px"
        >
          <Box display="flex" alignItems="center" gap="12px">
            <InstantRxIcons type={type} />
            <Typography fontSize="20px" fontWeight="600" color="#494E9D">
              {type === "create-instant"
                ? "Create Instant Prescription"
                : "Use Instant Prescription"}
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {type === "create-instant" ? (
          <Box className="save-instant">
            <Box
              className="tab-header"
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Tabs value={tabActive} onChange={handleChange}>
                <Tab
                  label="CREATE NEW"
                  {...allyProps(0)}
                  sx={{ fontSize: "14px", fontWeight: "500" }}
                />
                <Tab label="UPDATE EXISTING" {...allyProps(1)} />
              </Tabs>
            </Box>
            <TabComponent tabActive={tabActive} index={0} height="90px">
              <Box
                display="flex"
                alignItems="center"
                gap="16px"
                padding="0px 16px"
              >
                <TextField
                  label="Template Name"
                  sx={{ minWidth: isMobileScreen ? "250px" : "422px", maxWidth: "100%" }}
                  inputProps={{maxLength: 40}}
                  onChange={(event) => handleTemplates(event, "save")}
                />
                <LoadingButton
                  disabled={buttonDisable}
                  onClick={handleSaveInstantRx}
                  variant="contained"
                  loading={loading}
                  loadingPosition="center"
                  sx={{ textTransform: "none !important" }}
                >
                  Save
                </LoadingButton>
              </Box>
            </TabComponent>

            <TabComponent tabActive={tabActive} index={1} height="90px">
              <Box
                display="flex"
                alignItems="center"
                gap="16px"
                padding="0px 16px"
              >
                <Autocomplete
                  options={options}
                  onChange={(event) => handleTemplates(event, "update")}
                  sx={{
                    width: "100%",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select for existing template" />
                  )}
                />
                <LoadingButton
                  disabled={buttonDisable}
                  onClick={handleUpdateInstaRx}
                  variant="contained"
                  loading={loading}
                  loadingPosition="center"
                  sx={{ textTransform: "none !important" }}
                >
                  Update
                </LoadingButton>
              </Box>
            </TabComponent>
          </Box>
        ) : (
          <Box
            className="search-existing-template"
            display="flex"
            gap="16px"
            padding="20px"
            alignItems="center"
          >
            <Autocomplete
              options={options}
              onChange={(event) => handleSearchTemplate(event)}
              sx={{
                width: "100%",
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select instant prescription" />
              )}
            />
            <LoadingButton
              disabled={buttonDisable}
              onClick={handleUseThisTemplate}
              variant="contained"
              loading={loading}
              loadingPosition="center"
              sx={{ textTransform: "none !important", padding: "0px", height:"53px", width: "100px" }}
            >
              Use this
            </LoadingButton>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default InstantRx;
