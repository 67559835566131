import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ReactNode, createElement, useEffect, useState } from "react";
import Card from "./Card";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { initDataUpdateAPI } from "./Utilities";
import { setUpdateRxData } from "../../../../redux/reducers/prescription.slice";

interface ConfigProps {
  modalState: boolean;
  toggleModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

const TemplateConfigurator = ({
  modalState,
  toggleModalState,
}: ConfigProps) => {
  const {
    prescription: { rxData, signedURL },
  } = useSelector((state: any) => state.prescription);
  const dispatch = useDispatch();
  const [viewData, setViewData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (rxData && rxData?.length !== 0) {
      const allViews = rxData?.map((section: any) => {
        return {
          ...section,
          disableView: section.disableView,
        };
      });
      setViewData(allViews);
      setLoading(false);
    }
  }, []);

  const handleModalClose = () => {
    toggleModalState(false);
    return;
  };

  const renderViews = () => {
    const viewListRows: any = [];
    let startIndex = 0,
      endIndex = 5,
      iterCount = 1;
    for (let index = 0; index < viewData.length; index++) {
      if (index % 5 === 0) {
        viewListRows.push(viewData.slice(startIndex, endIndex));
        startIndex = endIndex;
        iterCount++;
        endIndex = endIndex * iterCount;
      }
    }

    const getViewRows = () => {
      // const noOfRows = isMobileScreen ? 2 : 3;
      return viewListRows.map((viewList: any, index: any) => {
        return (
          <>
            <Box sx={{ maxWidth: !isMobileScreen ? "33.33%" : "50%" }}>
              {viewList.map((view: any) => {
                const { header, disableView } = view;
                return (
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    control={
                      <Checkbox
                        id={header}
                        onClick={() => {
                          setViewData((prev: any) => {
                            return prev.map((view: any) => {
                              if (view.header === header) {
                                return {
                                  ...view,
                                  disableView: !view.disableView,
                                };
                              }
                              return view;
                            });
                          });
                        }}
                        checked={!disableView}
                      />
                    }
                    label={header}
                  />
                );
              })}
            </Box>
          </>
        );
      });
    };
    return createElement(
      FormGroup,
      {
        sx: {
          display: "flex",
          flexDirection: "row",
          padding: "10px 20px",
          flexWrap: "wrap",
          maxHeight: "400px",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      },
      ...getViewRows()
    );
  };

  const applyChanges = async () => {
    if (viewData.length !== 0) {
      const apiRes: any = await initDataUpdateAPI(viewData, signedURL);
      if (apiRes?.status === 200) {
        handleModalClose();
        dispatch(setUpdateRxData(true));
      }
      return;
    }
  };

  const handleDisableApplyChange = () => {
    const enabledViews = viewData?.filter((view: any) => !view?.disableView);
    const disableApply =
      Array.isArray(enabledViews) && enabledViews?.length < 1;
    return disableApply;
  };

  return (
    <Modal
      open={modalState}
      onClose={handleModalClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: !isMobileScreen ? "center" : "flex-end",
        m: 0,
      }}
    >
      <Card
        styles={{
          maxWidth: "736px",
          minHeight: "372px",
          padding: 0,
          margin: 0,
          borderBottomRightRadius: isMobileScreen ? 0 : "8px",
          borderBottomLeftRadius: isMobileScreen ? 0 : "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px",
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: "20px",
              color: "#494E9D",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            Configure Template
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              paddingRight: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider
          sx={{ border: "1px solid #D7D7D7", margin: "0 !important" }}
          orientation="vertical"
          variant="middle"
          flexItem
        />
        {!loading ? (
          renderViews()
        ) : (
          <Box sx={{ padding: "10px 20px" }}>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Box>
        )}
        {!loading ? (
          <Stack
            sx={{
              padding: "10px 20px",
              justifyContent: !isMobileScreen ? "flex-end" : "space-between",
            }}
            spacing={2}
            direction="row"
          >
            <Button onClick={handleModalClose} variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={handleDisableApplyChange()}
              onClick={applyChanges}
              variant="contained"
            >
              Apply Changes
            </Button>
          </Stack>
        ) : (
          <Box sx={{ padding: "10px 20px" }}>
            <Skeleton animation="wave" />
          </Box>
        )}
      </Card>
    </Modal>
  );
};

export default TemplateConfigurator;
