/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HTMLAttributes, SyntheticEvent, useEffect, useRef, useState } from "react";
import CardHeader from "../../utils/CardHeader";
import Card from "../../utils/Card";
import { styled } from "@mui/system";
import { CARD_SHADOW } from "../../utils/Constants";
import { DIABETES_ASSESSMENT, GENERAL_PHYSICAL_EXAMINATION, REFERRAL } from "../../../../../shared/constants";
import { setEditRxSecMobile } from "../../../../../redux/reducers/prescription.slice";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import DoctorSuggestions from "../../utils/DoctorSuggestions";
import { fetchSuggestions, seperateUrlAndParams } from "../../../../../shared/Utils/utils";
import { useDebounce } from "../../../../../shared/Utils/useDebounce";
import ReactQuill from "react-quill";

interface SymptomProps {
  group: number;
  key: string;
  label: string;
  options: any;
  type: string;
  value: string;
}

const EditorModules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    ["bold", "italic", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

const GeneralPhysicalExamination = (props: any) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SymptomProps[]>([]);
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {} as SymptomProps,
  });
  const removeBreaks = /(<([^>]+)>)/gi;

  const [searchValue, setSearchValue] = useState("");

  const sectionData = props?.sectionData?.[0]?.rows;
  const setTabData = props?.setTabData;
  const isFocused: boolean = props.isFocused || false;
  const symptomArr = sectionData?.master?.symptom?.values || [];
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const autoref = useRef<HTMLDivElement>(null);

  const apiurldata = sectionData?.search?.api;

  const [valUpdateSearch, setValUpdateSearch] = useState("");
  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);

  const [comment, setComment] = useState('');
  

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(GENERAL_PHYSICAL_EXAMINATION));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (sectionData) {
      setData([...sectionData]);
      setComment(sectionData?.find((elem:any)=>elem.key==='comment')?.value || '')
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === GENERAL_PHYSICAL_EXAMINATION
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);


  useEffect(()=>{
    console.log("data123",data)
  },[data])

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === GENERAL_PHYSICAL_EXAMINATION) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const updateCondition = (event: SyntheticEvent, value: string | null) => {
    let newValue: any, newId: any;

    const target =
      (event.target as HTMLInputElement) ||
      (event.target as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName)?.split("-");
    
    const fieldName = nameArr[0];
    
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === GENERAL_PHYSICAL_EXAMINATION) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (row.key === fieldName) {
                return {
                  ...row,
                  value: data.find(elem=>elem.key===fieldName)?.value
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });

    setOptions([]);
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        
        {data.map((datum:any, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
              }}
              key={index}
            >
              {`${datum?.label || ''} ${datum?.label ? `(${datum?.value})` : datum?.value?.replace(removeBreaks, "")}`}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
          }}
          title="General Physical Examination"
          isReadOnly={props?.isReadOnly}
        >
          <CardHeader headerImg="icon_general_physical_examination.svg" headerTitle="General Physical Examination">
            <></>
          </CardHeader>
          <Box>
            <Box display={'flex'} gap={4} pr={4}>
              <Box display={'flex'} flexDirection={'column'}>
                {data.length !== 0 && data?.filter(elem=>elem.group===1)?.map(elem=>{
                  return (
                    <Box display={'flex'} gap={8} mb={4} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography>{elem.label}</Typography>
                      <FormControl>
                          <RadioGroup
                            row
                            id={`${elem.key}-state`}
                            name={`${elem.key}-state`}
                            value={elem.value || ''}
                          >
                            {elem.options.map((opt:any)=>{
                              return (
                                <FormControlLabel 
                                  id={`${elem.key}-state`}
                                  name={`${elem.key}-state`}
                                  value={opt}
                                  control={<Radio
                                    
                                    inputProps={
                                      {
                                        onBlur:(event: any) => {
                                          updateCondition(event as any, event.target.value);
                                        },
                                        name:`${elem.key}-state`,
                                        id:`${elem.key}-state`,
                                        onClick:(e:any)=>{
                                          const checked = e.target.checked;
                                          if(checked){
                                            setData((prev: any) => {
                                              return prev.map((elem: any) => {
                                                if (elem.key.toString() === e.target.name?.split('-')[0]) {
                                                  return {
                                                    ...elem,
                                                    value: opt,
                                                  };
                                                }
                                                return elem;
                                              });
                                            });
                                          }
                                        }
                                      }
                                    }
                                    
                                  onBlur={(event: any) => {
                                    updateCondition(event as any, event.target.value);
                                  }}
                                />} label={opt} />
                              )
                            })}
                            
                          </RadioGroup>
                      </FormControl>
                    </Box>
                  )
                })}
              </Box>

              <Box sx={{height:'200px',width:'2px'}}>
                <Divider light={true} orientation="vertical"/>
              </Box>

              <Box display={'flex'} flexDirection={'column'}>
                {data.length !== 0 && data?.filter(elem=>elem.group===2)?.map(elem=>{
                  return (
                    <Box display={'flex'} gap={8} mb={4} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography>{elem.label}</Typography>
                      <FormControl>
                          <RadioGroup
                            row
                            id={`${elem.key}-state`}
                            name={`${elem.key}-state`}
                            value={elem.value || ''}
                          >
                            {elem.options.map((opt:any)=>{
                              return (
                                <FormControlLabel 
                                  id={`${elem.key}-state`}
                                  name={`${elem.key}-state`}
                                  value={opt}
                                  control={<Radio
                                    
                                    inputProps={
                                      {
                                        onBlur:(event: any) => {
                                          updateCondition(event as any, event.target.value);
                                        },
                                        name:`${elem.key}-state`,
                                        id:`${elem.key}-state`,
                                        onClick:(e:any)=>{
                                          const checked = e.target.checked;
                                          if(checked){
                                            setData((prev: any) => {
                                              return prev.map((elem: any) => {
                                                if (elem.key.toString() === e.target.name?.split('-')[0]) {
                                                  return {
                                                    ...elem,
                                                    value: opt,
                                                  };
                                                }
                                                return elem;
                                              });
                                            });
                                          }
                                        }
                                      }
                                    }
                                    
                                  onBlur={(event: any) => {
                                    updateCondition(event as any, event.target.value);
                                  }}
                                />} label={opt} />
                              )
                            })}
                            
                          </RadioGroup>
                      </FormControl>
                    </Box>
                  )
                })}
              </Box>
            </Box>
            <Box>
              <ReactQuill
                id="rx-additional-notes"
                theme="snow"
                placeholder="Enter comments"
                modules={EditorModules}
                value={comment}
                onChange={(content, delta, source, editor) => {
                  let newValue = content;
                  if (editor.getLength() <= 1) {
                    newValue = "";
                  }
                  if (editor.getLength() > 1500) {
                    newValue = newValue.substring(0, 1500);
                  }
                  setComment(newValue)
                }}
                onBlur={()=>{
                  setTabData((prev: any) => {
                    return prev.map((section: any) => {
                      if (section.view_type === GENERAL_PHYSICAL_EXAMINATION) {
                        return {
                          ...section,
                          rows: section.rows.map((row: any, index: number) => {
                            if (row.key === 'comment') {
                              return {
                                ...row,
                                value: comment
                              };
                            }
                            return row;
                          }),
                        };
                      }
                      return section;
                    });
                  });
                }}
                style={{ color: "#000000" }}
              />
            </Box>
          </Box>

          
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box display={'flex'} flexDirection={'column'}>
              {data.length !== 0 && data?.map(elem=>{
                return (
                  <Box display={'flex'} gap={8} mb={2} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography>{elem.label}</Typography>
                    <FormControl>
                        <RadioGroup
                          row
                          id={`${elem.key}-state`}
                          name={`${elem.key}-state`}
                          value={elem?.value || ''}
                        >
                          {elem?.options?.map((opt:any)=>{
                            return (
                              <FormControlLabel 
                                id={`${elem.key}-state`}
                                name={`${elem.key}-state`}
                                value={opt}
                                control={<Radio
                                  
                                  inputProps={
                                    {
                                      onBlur:(event: any) => {
                                        updateCondition(event as any, event.target.value);
                                      },
                                      name:`${elem.key}-state`,
                                      id:`${elem.key}-state`,
                                      onClick:(e:any)=>{
                                        const checked = e.target.checked;
                                        if(checked){
                                          setData((prev: any) => {
                                            return prev.map((elem: any) => {
                                              if (elem.key.toString() === e.target.name?.split('-')[0]) {
                                                return {
                                                  ...elem,
                                                  value: opt,
                                                };
                                              }
                                              return elem;
                                            });
                                          });
                                        }
                                      }
                                    }
                                  }
                                  
                                onBlur={(event: any) => {
                                  updateCondition(event as any, event.target.value);
                                }}
                              />} label={opt} />
                            )
                          })}
                          
                        </RadioGroup>
                    </FormControl>
                  </Box>
                )
              })}
            </Box>
            <ReactQuill
                id="rx-additional-notes"
                theme="snow"
                placeholder="Enter comments"
                modules={EditorModules}
                value={comment}
                onChange={(content, delta, source, editor) => {
                  let newValue = content;
                  if (editor.getLength() <= 1) {
                    newValue = "";
                  }
                  if (editor.getLength() > 1500) {
                    newValue = newValue.substring(0, 1500);
                  }
                  setComment(newValue)
                }}
                onBlur={()=>{
                  setTabData((prev: any) => {
                    return prev.map((section: any) => {
                      if (section.view_type === GENERAL_PHYSICAL_EXAMINATION) {
                        return {
                          ...section,
                          rows: section.rows.map((row: any, index: number) => {
                            if (row.key === 'comment') {
                              return {
                                ...row,
                                value: comment
                              };
                            }
                            return row;
                          }),
                        };
                      }
                      return section;
                    });
                  });
                }}
                style={{ color: "#000000" }}
              />
          </Box>
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
    </>
  )
}

export default GeneralPhysicalExamination