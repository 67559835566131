import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'

const VitalDataEmpty = () => {
    const theme = useTheme();
  return (
    <Box display={'flex'} height={'100%'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={0}>
        <Box
            component={'img'}
            src='/images/pastvitalsempty.svg'
        />
        <Typography fontSize={18} fontWeight={'bold'}>No Data Available</Typography>
        <Typography fontSize={14} fontWeight={400} color={theme.palette.white.greyText}>This parameter does not contain any past vital information.</Typography>
    </Box>
  )
}

export default VitalDataEmpty