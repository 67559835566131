import {  Box,Grid, Card, Typography, useMediaQuery, useTheme, TextField , InputLabel, Select,IconButton,Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import { createSearchParams,  useSearchParams } from 'react-router-dom';
import { APIClient } from "../../../../../shared/Utils/api-client";//"../../../../shared/Utils/api-client";
import { CircularProgress, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {ContainerCard,CardItemContent, CardEditField,CardEditSocialField,CardEmptyMessage,CardEditTreatmentField,EditBar,CommonlyTreats,SocialView} from './commonviews';
import { API_ROUTE_DOCTOR_DETAILS,API_ROUTE_UPDATE_ONLINEDOC } from '../../../../../shared/routes/route.constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      display: 'inline-block',
    },
    circle: {
      stroke: '#B95B5B !important', // Adjust color as needed
      strokeWidth: 4,
    },
    avatar: {
      position: 'absolute',
      top: '44%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    progressTeller: {
        border: '1px solid #d07173',
        padding: '5px',
        backgroundColor: '#fff2f3',
        fontSize: '12px',
        borderRadius: '6px',
    },
    roleTeller:{
        border: "1px solid #eaebfa",
        background: "#eaebfa",
        padding: "5px 10px",
        borderRadius: "30px",
        color: "#4d55d6",
        textTransform: "uppercase",
        fontWeight: "600",
        fontSize: "12px",
    },
    gridMargin: {
        /*marginBottom: "20px",*/
        height: "100%",

    },
    cardHeaderAction: {
        fontSize: '12px',
        color: '#494E9D',
        fontWeight: '600',
    },
    cardHeader: {
        fontSize: '15px',
        fontWeight: '600',
    }
  }));
const sections = [
    {
        label:'Clinic Management',
        value:'/admin/clinicmanagement/allclinic'
    },
    {
        label:'User Management',
        value:'/admin/usermanagement'
    },
    {
        label:'Slot Management',
        value:'/admin/slotmanagement'
    },
]

const DoctorProfileView = () => {

  //Hooks
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();


  //States
  const [pathname,setPathname] = useState('');
  const [doctor,setDoctor] = useState({});
  const [profileCompleted,setProfileCompleted] = useState(0);
  const [doctorRValue,setDoctorRValue] = useState({});
  const [specialities, setSpecialities] = useState([]);  
  const { clinic_id,doctor_id,account_name } = useParams();
  const [editMode, setEditMode] = useState('');
  const [loading, setLoading] = useState(true);
  const [socialInputs, setSocialInputs] = useState([
    {
        type:"linkedin",
        value:""
    },
    {
        type:"instagram",
        value:""
    }
]);

  useEffect(()=>{
        
        getSpecialities();  
        
      if(doctor_id=="newuser"){
        if(sessionStorage.getItem("new_user_profile_data")){
            setDoctor(JSON.parse(sessionStorage.getItem("new_user_profile_data")));
        }
        else{
            setDoctor({social_media:socialInputs});
        }
        setLoading(false);
      }
      else{
        getDoctor();
      }
      //getDoctor();
  },[])

  const setProfileComplition = (doctor) => {
      let completed = 10;
      if (doctor.Specialisation&&doctor.Specialisation.length > 0) {
          //Registration
          completed += 10;
      }
      if (doctor.about_me&&doctor.about_me.length > 0) {
          completed += 10;
      }
      if (doctor.commonly_treats&&doctor.commonly_treats.length > 0) {
          completed += 10;
      }
      if (doctor.qualification&&doctor.qualification.length > 0) {
          completed += 10;
      }
      if (doctor.experience&&doctor.experience.length > 0) {
          completed += 10;
      }
      if (doctor.awards&&doctor.awards.length > 0) {
          completed += 10;
      }
      if (doctor.member_of_society&&doctor.member_of_society.length > 0) {
          completed += 10;
      }
      if(doctor.social_media&&doctor.social_media.length>0){
          completed+=10;
      }
      setProfileCompleted(completed);      
      return;
  }
  const getSpecialities = async () => {
    try {
        const response = await APIClient.get("prescription/suggestions", {
        params: {
            type: "speciality",
            active_account_id : "svaas"
        },
        });
        if (response?.data?.statusCode === 200) {
            
            const newObj = (response.data.data).reduce((acc, curr) => {
            acc[curr.id] = curr.name;
            return acc;
        }, {});
        
            setSpecialities(newObj);
        
        }
    }
    catch(err){
      console.log(err);
    }
    
  }

  const getDoctor =async ()=>{
    try{
        const response = await APIClient.get(API_ROUTE_DOCTOR_DETAILS(clinic_id,doctor_id));
        if (response.status === 200) {
          let nData=response?.data;
          
          if(typeof response.data.social_media!=="object"||(response.data.social_media).length<1||(response.data.social_media).length==undefined){
            
              nData={...response?.data,social_media:socialInputs};    
              
            }
          nData.social_media.forEach((element,index) => {
            if( element.value==undefined){
              nData.social_media[index].value="";
            }
          });  
          setSocialInputs(nData.social_media);
          setDoctor(nData); 
          setDoctorRValue(nData);
          setProfileComplition(response?.data);
          setLoading(false);
        }
    }
    catch(err){
        navigate(-1);  
      console.log(err);
    }
    
  }

  const ProfileCompletion = ({ progress }) => {
    const classes = useStyles();
    
    return (
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={progress}
          className={classes.circle}
          sx={{ color: '#B95B5B' }}
          size={50}
        />
        <Avatar src={doctor?.profile_pic} alt={doctor?.doctor_name} className={classes.avatar} />
      </div>
    );
  };

  const handleInputChange =(event)=>{
      if(event.target.id){
        if(event.target.id==="experience"){
            event.target.value=parseInt(event.target.value);
            if(event.target.value<0){
                event.target.value=0;
            }
            if(event.target.value>90){
                event.target.value=90;
            }        
        }    
        setDoctor({...doctor,[event.target.id]:event.target.value});
      }
  }

  const handleCommonlyTreatedInputChange =(event,values)=>{
      if(typeof values!=="object")
          return;
      
      setDoctor({...doctor,commonly_treats:values});
  }
  const handleSocialInputChange =(event,index)=>{
    
    if(typeof event.target.value!=="string"||typeof index!=="number")
        return;
      let newInputs = [...socialInputs];
      newInputs[index].value = event.target.value;
      //setSocialInputs(newInputs);
      setDoctor({...doctor,social_media:newInputs});
      

  }
  const handleEditClick =(value)=>{
    
    setEditMode(value);
    return;

  }
  const socialInputsAdd =(values)=>{
    setSocialInputs(values);
    setDoctor({...doctor,social_media:values});
    return;
  }
  const handleCancelClick =()=>{
    
    setDoctor(doctorRValue);
    setEditMode('');
    return;
  }
  const handleSaveClick = async ()=>{
    setLoading(true);
    
    if(doctor_id==="newuser"){
        sessionStorage.setItem('new_user_profile_data',JSON.stringify(doctor))
        
        setEditMode('');
        setLoading(false);
      return;
    }    
    try{
        const response = await APIClient.put(API_ROUTE_UPDATE_ONLINEDOC(clinic_id,doctor_id),{...doctor,'cognito_user_sub':doctor_id});
        if (response.status === 200) {
          setDoctor(response?.data); 
          setDoctorRValue(response?.data);

        }
        setEditMode('');
        setLoading(false);
    }
    catch(err){
      console.log(err);
    }
    
    return;
  }
  const handleClickBack = ()=>{
    
        if(searchParams.get('isEdit')){
            navigate({
                pathname: "/admin/usermanagement",
                search: createSearchParams({
                    isEdit:true,
                    account_id: searchParams.get('account_id'),
                    account_name: searchParams.get('account_name'),
                    user_id: searchParams.get('user_id'),
                    cognito_user_sub:searchParams.get('cognito_user_sub'),
                }).toString()
            });
        }else{
            navigate({
                pathname: "/admin/usermanagement",
                search: createSearchParams({
                    account_id: searchParams.get('account_id'),
                    account_name: searchParams.get('account_name'),
                }).toString()
            });
        }
        
       return;
  }
  const GeneralInfo =() => {
    
      return (<>
        
      </>);
  };
  //Effects
  useEffect(()=>{
    if(location.pathname){
        setPathname(location.pathname);
    }
  },[location])
  const classes = useStyles();
  return (
    <>
      {isMobileScreen ? (
        <>
          
        </>
      ) : (
        <Box height={"100%"} width={"100%"} zIndex={100} position={"absolute"}>
          
          <Box >
            <Grid container autoHeight  height={{xs:"100%",md:`calc(100vh - 42px)`}} width={{xs:"100%",md:`calc(100vw - 60px)`}} spacing={4}>
              
              {/* left panel */}
              <Grid
                item
                height="100%"
                xs={3.3}
                
              >
                <Box 
                    width={'100%'} 
                    height={'100%'}
                    sx={{
                        // boxShadow: "1px 1px 4px 2px rgba(0,0,0,0.2)",
                        backgroundColor: "#ffffff",
                        borderRadius:'0px 8px 0px 0px'
                    }}
                >
                    {
                        sections.map((elem)=>{
                            return (
                                <Box 
                                    key={elem.label}
                                    width={'100%'} 
                                    boxSizing={'border-box'}
                                    paddingX={4} 
                                    paddingY={2} 
                                    display={'flex'} 
                                    justifyContent={'space-between'} 
                                    alignItems={'center'}
                                    onClick={()=>{
                                        // navigate(elem.value)
                                    }}
                                    sx={{
                                        cursor:'pointer'
                                    }}
                                >
                                    <Typography 
                                        variant='subtitle2' 
                                        color={pathname.includes(elem.value) ? theme.palette.primary.main : 'black'}
                                        fontWeight={pathname.includes(elem.value) ? "bold" : 'regular'}
                                    >
                                        {elem.label}
                                    </Typography>
                                    {
                                        pathname.includes(elem.value) && (
                                            <ArrowForwardIosOutlinedIcon fontSize='small' sx={{color:theme.palette.primary.main}}/>
                                        )
                                    }
                                </Box>
                            )
                        })
                    }
                </Box>
              </Grid>

              {/* right panel */}
              <Grid
                item
                height="100%"
                xs
                spacing={10}
                overflow={'scroll'}
              >
                {loading?<>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                </>:<>
               
                    <Grid container autoHeight spacing={2} sx={{paddingBottom:'45px'}}>
                        <Grid item xs={12}>
                            {/* Title */}
                            <Box display={'flex'} gap={0} alignItems={'center'}>
                                    <IconButton
                                        onClick={handleClickBack}
                                    >
                                        <ArrowBackIcon/>
                                    </IconButton>
                                    <Typography variant='subtitle1' fontWeight={'bold'}>Doctor Profile</Typography>
                                </Box>

                        </Grid>    
                        <Grid item xs={12} className={classes.gridMargin}>
                            <ContainerCard absolute={true} id="profileHeader" edit={editMode} onEditClick={handleEditClick}  title={''}>
                                {/* Your card content here */}
                                
                                <Grid container spacing={2}>
                                    <Grid item xs={5} >
                                        <Grid container>
                                            <Grid item xs={6} textAlign={"center"}>
                                                <ProfileCompletion progress={profileCompleted} />
                                                <div>
                                                    <span className={classes.progressTeller}>{profileCompleted}% Profile Completed</span>
                                                </div>
                                                
                                                
                                            </Grid>    
                                            <Grid item xs={6} textAlign={"center"} sx={{borderRight:'1px solid #DDDDDD'}}>
                                                <Typography variant="h5">{doctor?.doctor_name}</Typography>
                                                <div >
                                                    <span className={classes.roleTeller}>Doctor</span>
                                                </div>
                                            </Grid>    
                                        </Grid>   
                                    </Grid>
                                    <Grid item xs={7}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <Grid container>
                                                        <Grid item xs={3}>
                                                            <img src='/images/admin/user/location.svg' alt="location" />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <div style={{fontWeight:'400',fontFamily:'Nunito Sans, sans-serif',fontSize:'14px',color:'#6F6F6F'}}>
                                                                Location
                                                            </div>
                                                            <div  style={{fontWeight:'700',fontFamily:'Nunito Sans, sans-serif',fontSize:'16px',color:'#313131'}}>

                                                                {doctor?.location?.city}{doctor?.location?.state?", "+doctor.location.state:"No Data"}
                                                            </div>
                                                        </Grid>    
                                                    </Grid>    
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container>
                                                        <Grid item xs={3}>
                                                            <img src='/images/admin/user/experience.svg' alt="experience" />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <div  style={{fontWeight:'400',fontFamily:'Nunito Sans, sans-serif',fontSize:'14px',color:'#6F6F6F'}}>
                                                                Experience
                                                            </div>
                                                            <div  style={{fontWeight:'700',fontFamily:'Nunito Sans, sans-serif',fontSize:'16px',color:'#313131'}}>
                                                                {editMode=="profileHeader" && <TextField 
                                                                    type="number" 
                                                                    InputProps={{
                                                                        inputProps: {
                                                                        min: 1,
                                                                        max: 90,
                                                                        },
                                                                    }}  
                                                                    sx={{width:'100%',marginTop:'10px'}}
                                                                    placeholder='Experience'
                                                                    key={"experience"} 
                                                                    onChange={handleInputChange} 
                                                                    id={"experience"} 
                                                                    value={doctor?.experience}  
                                                                    label="Add Experience"/>} 
                                                                {editMode!="profileHeader" ?parseInt(doctor?.experience)>0? doctor?.experience+" +Years":" No Data ":""}
                                                            </div>
                                                        </Grid>    
                                                    </Grid>    
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container>
                                                        <Grid item xs={3}>
                                                            <img src='/images/admin/user/language.svg' alt="Language" />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <div  style={{fontWeight:'400',fontFamily:'Nunito Sans, sans-serif',fontSize:'14px',color:'#6F6F6F'}}>
                                                                Language
                                                            </div>
                                                            <div  style={{fontWeight:'700',fontFamily:'Nunito Sans, sans-serif',fontSize:'16px',color:'#313131'}}>
                                                                {editMode=="profileHeader" && <TextField sx={{width:'100%',marginTop:'10px'}} key={"language"} onChange={handleInputChange} id={"language"} value={doctor?.language}  label="Add language"/>} 
                                                                {editMode!="profileHeader" && doctor?.language}
                                                                
                                                            </div>
                                                        </Grid>    
                                                    </Grid>    
                                                </Grid>    
                                            </Grid>    
                                    </Grid>        
                                </Grid>
                            </ContainerCard>
                        </Grid>
                        <Grid item xs={6} className={classes.gridMargin}>
                            <ContainerCard id="medicalreg_no" edit={editMode} onEditClick={handleEditClick} 
                            title={'Registration No.'}
                            image={'/images/admin/user/registrationNumber.svg'}
                        >
                                {/* Your card content here */}
                                {editMode=="medicalreg_no" && <CardEditField  onChange={handleInputChange} id={"medicalreg_no"} value={doctor.medicalreg_no} title="Add registration number" type={"textarea"} subTitle="This should be your registration number" lable="Add registration number"/>}
                                {editMode!="medicalreg_no"?(doctor.medicalreg_no!=''?<CardItemContent>{doctor.medicalreg_no}</CardItemContent>:<CardEmptyMessage onClick={()=>handleEditClick("medicalreg_no")} title="Add registration number" subTitle="This should be your registration number" lable="Add registration number"/>):''}
                                
                            </ContainerCard>
                        </Grid>  
                        <Grid item xs={6} className={classes.gridMargin}>
                            <ContainerCard id="speciality" edit={editMode} onEditClick={handleEditClick} 
                            title={'Speciality'}
                            image={'/images/admin/user/specialization.svg'}
                                >
                                {/* Your card content here */}
                                {editMode=="speciality" && <CardEditField  onChange={handleInputChange} id={"speciality"} value={doctor.speciality} title="Add speciality" type={"speciality"} subTitle="This should be speciality" selectValues={specialities} lable="Add speciality"/>}
                                
                                {editMode!="speciality"?(doctor.speciality!=''?<CardItemContent>{specialities[doctor.speciality]}</CardItemContent>:<CardEmptyMessage onClick={()=>handleEditClick("speciality")} title="Add Specialization" subTitle="This should include about  speciality" lable="Add Specialization"/>):''}
                                
                                
                            </ContainerCard>
                        </Grid>  
                        <Grid item xs={12} className={classes.gridMargin}>
                            <ContainerCard id="about_me" edit={editMode} onEditClick={handleEditClick} 
                            title={'About the Doctor'}
                            image={'/images/admin/user/aboutDoctor.svg'}
                                >
                                {/* Your card content here */}
                                {editMode=="about_me" && <CardEditField  onChange={handleInputChange} id={"about_me"} value={doctor.about_me} title="Add about you" type={"textarea"} subTitle="This should include about your work experience, speciality, achievements and more. It should be minimum 700 characters" lable="Add about you"/>}
                                {editMode!="about_me"?(doctor.about_me!=''?<CardItemContent>{doctor.about_me}</CardItemContent>:<CardEmptyMessage onClick={()=>handleEditClick("about_me")} title="Add about you" subTitle="This should include about your work experience, speciality, achievements and more. It should be minimum 700 characters" lable="Add about you"/>):''}
                            </ContainerCard>
                        </Grid> 
                        <Grid item xs={12} className={classes.gridMargin}>
                            <ContainerCard id={"commonly_treats"} edit={editMode} onEditClick={handleEditClick} 
                            title={'Commonly Treats'}
                            image={'/images/admin/user/ComonlyTreats.svg'}
                            >
                                {/* Your card content here */}
                                
                                {editMode=="commonly_treats" && <CardEditTreatmentField  onChange={handleCommonlyTreatedInputChange} id={"commonly_treats"} values={doctor.commonly_treats} title="Add commonly treats"  subTitle="This helps you come up in relevant search results" lable="Search"/>}
                                {editMode!="commonly_treats"?(doctor.commonly_treats!=''?<CardItemContent><CommonlyTreats values={doctor.commonly_treats}/></CardItemContent>:<CardEmptyMessage onClick={()=>handleEditClick("commonly_treats")} title="Add commonly treats" subTitle="This helps you come up in relevant search results" lable="Add commonly treats"/>):''}
                            </ContainerCard>
                        </Grid>  
                        <Grid item xs={12} className={classes.gridMargin}>
                            <ContainerCard id={"qualification"} edit={editMode} onEditClick={handleEditClick} 
                            title={'Educational Qualifications'}
                            image={'/images/admin/user/educationalQualification.svg'}
                            >
                                {/* Your card content here */}
                                {editMode=="qualification" && <CardEditField  onChange={handleInputChange} id={"qualification"} value={doctor.qualification} title="Add qualification" type={"textarea"} subTitle="This should be your qualification" lable="Add qualification"/>}
                                {editMode!="qualification"?(doctor.qualification!=''?<CardItemContent>{doctor.qualification}</CardItemContent>:<CardEmptyMessage onClick={()=>handleEditClick("qualification")} title="Add qualification" subTitle="This should be your qualification" lable="Add qualification"/>):''}
                                
                            </ContainerCard>
                        </Grid>  
                        <Grid item xs={12} className={classes.gridMargin}>
                            <ContainerCard id={"experience"} edit={editMode} onEditClick={handleEditClick} 
                            title={'Experience'}
                            image={'/images/admin/user/experience.svg'}
                            >
                                {/* Your card content here */}
                                {editMode=="experience" && <CardEditField  onChange={handleInputChange} type={"textarea"} value={doctor.experience_detail} id={"experience_detail"} title="Add your experience" subTitle="Add your work experience to build patient trust" lable="Add now"/>}
                                {editMode!="experience"?(doctor.experience_detail!=''?<CardItemContent>{doctor.experience_detail}</CardItemContent>:<CardEmptyMessage onClick={()=>handleEditClick("experience")} title="Add your experience" subTitle="Add your work experience to build patient trust" lable="Add now"/>):""}
                            </ContainerCard>
                        </Grid>  
                        <Grid item xs={6} className={classes.gridMargin}>
                            <ContainerCard id={"awards"} edit={editMode} onEditClick={handleEditClick} 
                            title={'Awards & Publication'}
                            image={'/images/admin/user/awards.svg'}
                            >
                                {/* Your card content here */}
                                {editMode=="awards" && <CardEditField  onChange={handleInputChange} type={"textarea"} value={doctor.awards} id={"awards"} title="Add Awards & Publication" subTitle="Add all the awards you’ve won & the work you’ve published" lable="Add Awards & Publication"/>}

                                {editMode!="awards"?(doctor.awards!=''?<CardItemContent>{doctor.awards}</CardItemContent>:<CardEmptyMessage onClick={()=>handleEditClick("awards")} title="Add Awards & Publication" subTitle="Add all the awards you’ve won & the work you’ve published" lable="Add Awards & Publication"/>):''}
                            </ContainerCard>
                        </Grid>  
                        <Grid item xs={6} className={classes.gridMargin}>
                            <ContainerCard id={"specializations"} edit={editMode} onEditClick={handleEditClick} 
                            title={'Specializations'}
                            image={'/images/admin/user/specialization.svg'}
                            >
                                {/* Your card content here */}
                                {editMode=="specializations" && <CardEditField  onChange={handleInputChange} type={"textarea"} value={doctor.specializations} id={"specializations"} title="Add Specialisations" subTitle="Add your specialisation you are expert in" lable="Add Specialisation"/>}

                                {editMode!="specializations"?(doctor.specializations!=''?<CardItemContent>{doctor.specializations}</CardItemContent>:<CardEmptyMessage onClick={()=>handleEditClick("specializations")} title="Add Specialisations" subTitle="Add your specialisation you are expert in" lable="Add Specialisation"/>):''}
                            </ContainerCard>
                        </Grid>  
                        <Grid item xs={6} className={classes.gridMargin}>
                            <ContainerCard id={"member_of_society"} edit={editMode} onEditClick={handleEditClick} 
                            title={'Member of Society or Group'}
                            image={'/images/admin/user/member.svg'}
                            >
                                {/* Your card content here */}
                                {editMode=="member_of_society" && <CardEditField  onChange={handleInputChange} type={"textarea"} value={doctor.member_of_society} id={"member_of_society"} title="Add society or group " subTitle="Add the societies or group you’re member of" lable="Add Member"/>}

                                {editMode!="member_of_society"?(doctor.member_of_society!=''?<CardItemContent>{doctor.member_of_society}</CardItemContent>:<CardEmptyMessage onClick={()=>handleEditClick("member_of_society")} title="Add society or group " subTitle="Add the societies or group you’re member of" lable="Add Member"/>):''}
                            </ContainerCard>
                        </Grid>  
                        <Grid item xs={6} className={classes.gridMargin}>
                            <ContainerCard id={"social_media"} edit={!editMode} onEditClick={handleEditClick} title={'Social Media'}
                                image={'/images/admin/user/social.svg'}
                                >
                                {/* Your card content here */}
                               
                                {editMode=="social_media" && <CardEditSocialField  onChange={handleSocialInputChange} onAdd={socialInputsAdd}  values={doctor.social_media} id={"social_media"} title="Add your social media" subTitle="Add your social media handles to build patient trust" />}
                                {editMode!="social_media"?(typeof doctor.social_media==="object"&&doctor.social_media.length>0&&!(doctor.social_media).every(obj =>  obj.value === "")?<><SocialView values={doctor.social_media} /></>:<CardEmptyMessage onClick={()=>handleEditClick("social_media")} title="Add your social media" subTitle="Add your social media handles to build patient trust" lable="Add Social Media"/>):""}
                            </ContainerCard>
                        </Grid> 
                        <Grid item xs={12} className={classes.gridMargin}>
                            <img onClick={()=>{window.location.href =("https://abha.abdm.gov.in/abha/v3/register/aadhaar")}} src='/images/admin/user/HealthcareRegistry.svg' width={'100%'}/>
                        </Grid>   
                    </Grid>
                    
                    {editMode && <>
                            <div style={{position:'relative'}}>
                                <EditBar onCancel={handleCancelClick} onSave={handleSaveClick}/>
                            </div>
                        </>}    
                </>}        
              </Grid>
             
            </Grid>
          </Box>
        
            


        </Box>
      )}
    </>
  )
}




export default DoctorProfileView
