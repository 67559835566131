import { Box, Button } from "@mui/material";

interface OnwardsIconProps {
    fillColor?: string
}
const OnwardsIcon: React.FC<OnwardsIconProps> = ({ fillColor}) => {
  return (
    <Box sx={{ cursor: "pointer" }}>
      <svg
        width="10"
        height="18"
        viewBox="0 0 10 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.425428 1.128C-0.103071 1.69073 -0.101524 2.56782 0.428956 3.12868L5.98225 9L0.42732 14.8859C-0.101888 15.4466 -0.101412 16.3229 0.428406 16.8831C1.00209 17.4896 1.96754 17.4896 2.54122 16.8831L9.09015 9.95912C9.59912 9.42099 9.59912 8.57901 9.09015 8.04088L2.54817 1.12425C1.97135 0.514404 1.00009 0.516117 0.425428 1.128Z"
          fill={fillColor}
        />
      </svg>
    </Box>
  );
};
export default OnwardsIcon;
