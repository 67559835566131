import {
    Box,
    Divider,
    // Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
  } from "@mui/material";
  import moment from "moment";
  import { useTheme } from "@mui/material/styles";
  import { useDispatch, useSelector } from "react-redux";
  import {
    fetchPrescriptionDetails,
    setShowArtilceCard,
  } from "../../../../redux/reducers/prescription.slice";
  import { useEffect, useRef, useState } from "react";
  import {
    fetchDisplayUrlData,
    sendPrescription,
  } from "../../../../shared/Utils/utils";
  import Button from "../../../../shared/Button/Button";
  import html2PDF from "jspdf-html2canvas";
  import DOMPurify from "dompurify";
  import "../../../../shared/layouts/style.css";
  
  import {
    ADVICE,
    ADVICE_NOTES,
    COMPLAINTS,
    DIAGNOSIS,
    EXAMINATION,
    FOLLOW_UP,
    INVESTIGATIONS,
    MEDICAL_HISTORY,
    MEDICATION,
    PATIENT_HISTORY,
    REFERRAL,
  } from "../../../../shared/constants";
  import CustomizedDialogs from "../../../../shared/Dialog/Dialog";
  import { useNavigate } from "react-router-dom";
  import {
    API_ROUTE_SAVE_PRESCRIPTION,
    ROUTE_APPOINTMENTS,
  } from "../../../../shared/routes/route.constants";
  import { APIClient } from "../../../../shared/Utils/api-client";
  import LoadingButton from "@mui/lab/LoadingButton";
  import PhoneIcon from "../../../../shared/icons/PhoneIcon";
  import MailIcon from "../../../../shared/icons/MailIcon";
  import WebsiteIcon from "../../../../shared/icons/WebsiteIcon";
  import LabTestIcon from "../../../../shared/icons/LabTestIcon";
  import TabletIcon from "../../../../shared/icons/TabletIcon";
  import { updateApponitment } from "../../../../redux/reducers/appointment.slice";
  import dayjs from "dayjs";
  import EndConsultationIcon from "../../../../shared/icons/EndConsultationIcon";
  import { textAlign } from "@mui/system";

  const tableColumns = [
    {
        label:'Sr No.',
        value:'sr',
    },
    {
        label:'Category',
        value:'category',
    },
    {
        label:'Visit Type',
        value:'type',
    },
    {
        label:'Qty',
        value:'quantity',
    },
    {
        label:'Unit Cost',
        value:'cost',
    },
    {
        label:'Sub Total',
        value:'total',
    },
  ]
  
  const Invoice = (props: any) => {
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
    const prescriptionTableHeader = [
      {
        headerName: "Medicine Name",
      },
      {
        headerName: "Qty/Dose",
      },
      {
        headerName: "Frequency",
      },
      {
        headerName: "Duration",
      },
      {
        headerName: "Meal Pref",
      },
      {
        headerName: "Start From",
      },
      {
        headerName: "Special Instruction",
      },
    ];
    const LabTestTableHeader = [
      {
        headerName: "Lab Test",
      },
      {
        headerName: "Test on",
      },
      {
        headerName: "Repeat on",
      },
      {
        headerName: "Remarks",
      },
    ];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [prescriptionDetails, setPrescriptionDetails] = useState([]);
    const [openDialog, setOpenDialog] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();
    const [dateAndTime, setDateAndTime] = useState<any>();
    const [moleculeName, setMoleculeName] = useState<any>([]);
    const TableStyle = {
      cell: {
        borderLeft: "1px solid #D9D9D9",
        fontSize: isMobileScreen ? "10px" : "14px",
        padding: isMobileScreen ? "0px" : "16px",
        textAlign: "left",
      },
      medicalLabCell: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        borderBottom: "0px",
        padding: isMobileScreen ? "0px" : "16px",
        fontSize: isMobileScreen ? "10px" : "14px",
      },
      medicineCell: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        borderBottom: "0px",
      },
    };
    const prescriptionStyle = {
      header: {
        fontSize: isMobileScreen ? "9px" : "12px",
        fontWeight: "400",
        lineHeight: "18px",
        color: "#0A0666",
      },
      patientDetailsHeader: {
        fontSize: isMobileScreen ? "8px" : "14px",
        fontWeight: "400",
        lineHeight: "16.41px",
      },
      patientFindingsHeader: {
        fontSize: isMobileScreen ? "12px" : "16px",
        fontWeight: "600",
        lineHeight: "24px",
        color: "#0A0666",
      },
      patientFindingsValue: {
        fontSize: isMobileScreen ? "12px" : "16px",
        fontWeight: "400",
        lineHeight: "24px",
      },
      prescriptionContentStyle: {
        display: "flex",
        gap: "10px",
      },
      prescriptionIconStyle: {
        display: "inline-flex",
        justifyContent: "flex-start",
        height: isMobileScreen ? "27px" : "100%",
      },
    };
    const {
      prescription: { signedURL },
    } = useSelector((state: any) => state.prescription);
  
    const { accountInfo, profile } = useSelector((state: any) => state.auth);
    
  //   const convertAddressToString = (accountInfo: any) => {
  //     const { address = {} } = accountInfo;
  //     const { line = "", city = "", state = "", zipcode = "" } = address;
  
  //     if (Object?.values(address)?.length) {
  //         return `${line} ${city} ${state} - ${zipcode}`;
  //     }
  
  //     return "";
  // };
  
  
    // const getPrescriptionDetails = async () => {
    //   dispatch(
    //     fetchPrescriptionDetails({
    //       params: { type: "patient", limit: "1", id: patient?.patient_id },
    //     })
    //   );
    // };
  
    
    useEffect(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
      }
    }, []);
  
    
  
    
  
    return (
      <Box>
        <Box
          ref={ref}
          id="invoice-1"
          sx={{ background: "#fff", border: "1px solid #D9D9D9" }}
        >
          <Box
            className="prescription-header"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "128px",
              background:
                "linear-gradient(63.58deg, #E3F1FD -26.63%, #FFFFFF 100%)",
              padding: "10px 20px 10px 20px",
            }}
          >
            <Box className="clinic-details" width="100%">
              <Box>
                <Typography
                  fontSize={isMobileScreen ? "12px" : "20px"}
                  fontWeight="700"
                  lineHeight="30px"
                  color="#0A0666"
                >
                  {accountInfo?.clinic_name ? accountInfo?.clinic_name : ""}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="6px">
                <PhoneIcon fill={"#0A0666"} width="16" height="16" />
                <Typography style={prescriptionStyle.header}>
                  {accountInfo?.emergency_info ? accountInfo?.emergency_info : ""}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="6px">
                <MailIcon fill={"#0A0666"} width="16" height="16" />
                <Typography style={prescriptionStyle.header}>
                  {accountInfo?.email ? accountInfo?.email : ""}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="6px">
                <WebsiteIcon />
                <Typography style={prescriptionStyle.header}>
                  {accountInfo?.clinic_website ? accountInfo?.clinic_website : ""}
                </Typography>
              </Box>
            </Box>
            {/* <Box className="clinic-logo" textAlign="center">
              <Box
                component="img"
                src={accountInfo?.clinic_logo}
                sx={{ width:isMobileScreen ? "20px" : "100px" }}
              />
            </Box> */}
            <Box className="doctor-details" width="100%" textAlign="end">
              <Typography
                fontSize={isMobileScreen ? "12px" : "20px"}
                fontWeight="700"
                lineHeight="30px"
                color="#0A0666"
              >
                {profile?.name ? `DR.${profile?.name}` : ""}
              </Typography>
              <Typography style={prescriptionStyle.header} textAlign="right">
                {profile?.qualification ? profile?.qualification : ""}
              </Typography>
              <Typography style={prescriptionStyle.header} textAlign="right">
                {profile?.medicalreg_no
                  ? `Registration No:${profile?.medicalreg_no}`
                  : ""}
              </Typography>
              <Typography style={prescriptionStyle.header} textAlign="right">
                {profile?.phone ? `+91 ${profile?.phone}` : ""}
              </Typography>
            </Box>
          </Box>
          <Box
            className="patient-details"
            display="flex"
            gap={isMobileScreen ? "2px" : "42px"}
            borderBottom="1px solid #0A0666"
            margin={isMobileScreen ? "0px 0px 23px 0px" : "0px 32px 24px 32px"}
            padding="16px 0px"
            justifyContent={isMobileScreen ? "space-between" : undefined}
          >
            <Typography style={prescriptionStyle.patientDetailsHeader}>
              Name : {props?.data?.name}
            </Typography>
            <Typography style={prescriptionStyle.patientDetailsHeader}>
              Gender :{" "}
              {props?.data?.patient?.gender?.charAt(0)?.toUpperCase() + props?.data?.patient?.gender?.slice(1)}
            </Typography>
            <Typography style={prescriptionStyle.patientDetailsHeader}>
              Age : {props?.data?.patient?.age?.no_of_years}
            </Typography>
            <Typography style={prescriptionStyle.patientDetailsHeader}>
              Mobile : {props?.data?.country_code ? props?.data?.country_code+'-' : ''}{props?.data?.phone}
            </Typography>
            <Typography style={prescriptionStyle.patientDetailsHeader}>
              UID : {props?.data?.patient?.patient_hid}
            </Typography>
          </Box>
          <Box
            className="patient-finding"
            margin={isMobileScreen ? "10px" : "32px"}
          >
            <Box display={"flex"} flexDirection={'column'} gap={2}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box display={"flex"} gap={4} alignItems={'center'}>
                            <Typography>{`Payment Mode : ${props?.data?.payment_option}`}</Typography>
                            {/* <Box display={'flex'} gap={1} alignItems={'center'}>
                                <Typography>{`Payment Status :`}</Typography>
                                <Box py={1} px={2}  sx={{borderRadius:'22px',backgroundColor:props?.data?.payment_status === 'Pending' ? '#f4939a33' : '#ECFDF3'}}>
                                    <Typography>{props?.data?.payment_status}</Typography>
                                </Box>
                            </Box> */}
                            
                        </Box>
                        <Box>
                            <Typography color={'primary'}>{`Date : ${dayjs(props?.data?.date,'YYYY-MM-DD').format('DD MMM YYYY')}`}</Typography>
                        </Box>
                    </Box>
                    
                    <Box>
                        <TableContainer>
                            <Table sx={{ border: "1px solid #D9D9D9" }}>
                                <TableHead sx={{ background: "#F0F7FE" }}>
                                    <TableRow>
                                        {tableColumns?.map((col,index)=>{
                                            return (
                                                <TableCell
                                                    sx={{
                                                        fontSize: isMobileScreen ? "8px" : "14px",
                                                        padding: isMobileScreen ? "2px" : "16px",
                                                    }}
                                                >
                                                    <Typography>{col?.label}</Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props?.data?.invoice_details?.map((elem:any,index:any)=>{
                                        return (
                                            <TableRow key={elem?.id} sx={{ borderBottom: "1px solid #D9D9D9" }}>
                                                {tableColumns?.map((col,index)=>{
                                                    if(col?.value === 'sr'){
                                                        return (
                                                            <TableCell 
                                                                sx={TableStyle.cell}
                                                            >
                                                                <Typography>{index+1}</Typography>
                                                            </TableCell>
                                                        )
                                                    }
                                                    return (
                                                        <TableCell
                                                            sx={TableStyle.cell}
                                                        >
                                                            <Typography>{elem[col.value]}</Typography>
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>

                    <Box display={'flex'} justifyContent={'end'}>
                        <Box display={'flex'} flexDirection={'column'} width={'400px'} gap={1}>
                            <Box>
                                <Typography fontWeight={'bold'}>Summary</Typography>
                            </Box>
                            <Divider/>
                            <Box display={'flex'} flexDirection={'column'} gap={1}>
                                    <Box display={'flex'}>
                                        <Box sx={{flex:1}}><Typography>{`Sub Total (Rs):`}</Typography></Box>
                                        <Box sx={{flex:1}}><Typography>{props?.data?.billed_amount}</Typography></Box>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Box sx={{flex:1}}><Typography>{`Discount:`}</Typography></Box>
                                        <Box sx={{flex:1}}><Typography>{(props?.data?.discount || 0) + '%'}</Typography></Box>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Box sx={{flex:1}}><Typography>{`Amount Paid (Rs):`}</Typography></Box>
                                        <Box sx={{flex:1}}><Typography>{props?.data?.amount_received}</Typography></Box>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Box sx={{flex:1}}><Typography>{`Balance (Rs) :`}</Typography></Box>
                                        <Box sx={{flex:1}}><Typography>{props?.data?.invoice_amount-props?.data?.amount_received}</Typography></Box>
                                    </Box>
                            </Box>
                            <Divider/>
                            <Box>
                                <Box display={'flex'}>
                                    <Box sx={{flex:1}}><Typography variant="h6" fontWeight={'bold'}>{`Total (Rs)`}</Typography></Box>
                                    <Box sx={{flex:1}}><Typography variant="h6" fontWeight={'bold'}>{props?.data?.invoice_amount}</Typography></Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
            </Box>
          </Box>
          
          <Box className="footer-box">
            <Box sx={{ textAlign: "center", paddingBottom:"5px" }}>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  fontSize:isMobileScreen ? "10px":  "14px",
                  fontWeight: "400",
                }}
              >
                *This is an automated generated document and doesn't require
                signature.*
              </Typography>
            </Box>
            <Box
              sx={{
                background: "linear-gradient(64deg, #E3F1FD -26.63%, #FFF 100%)",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap:"8px"
              }}
            >
              <Box component="img" sx={{width:"16px", height:"16px"}} src="/images/icon_location.svg" />
              <Typography
                sx={{
                  fontSize:isMobileScreen ? "10px": "13px",
                  fontWeight: "400",
                  lineHeight: "19.5px",
                  letterSpacing: "0.15px",
                  color: "#0A0666",
                }}
              >
                {/* {convertAddressToString(accountInfo)} */}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  export default Invoice;
  