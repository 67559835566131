import {  Skeleton,Grid, Card, Typography, Autocomplete, useTheme, CardContent, CardHeader, IconButton,Button,TextField, InputAdornment,Link } from '@mui/material';
import React from 'react'
import { makeStyles } from '@mui/styles';
import {styled} from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import SearchIcon from '@mui/icons-material/Search'; // Replace with your desired icon

export const NoData = styled('div')(
    ({ theme }) => `
    padding: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background: #494e9d;
    color: #fff;
    text-transform: capitalize;
    `,
 )

 export const HeaderTitleButtonFlex = styled('div')(
    ({ theme }) => `
    display: flex;
    justify-content: space-between;
    `
 )

 export const HeaderTitleButtonItem = styled('div')(
    ({ theme }) => `
    
    `
 )  
 export const ShowSkeleton = ({count}) => {
    const skeletons = Array.from({ length: count }, (_, index) => index + 1);
        console.log("skeletons",skeletons,count);
    return (
        <>
            {skeletons.map((skeleton) => (
                <>
                    
                    <Skeleton animation='wave'/>
                </>
                
            ))}
        </>
    )
 }

 ShowSkeleton.defaultProps = {
    count: 3,
  };