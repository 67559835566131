import {  InputAdornment, TextField, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SmallSearchInput = ({label,value,getAppointmentData,disabled,getConfirmedAppintments}) => {
    
    const theme = useTheme();

    return (
      <TextField
        onChange={(evt)=>getAppointmentData(evt.target.value)}
        placeholder={label}
        value={value}
        disabled={disabled}
        className={`mui-textfield-nopadding-small`}
        sx={{backgroundColor:theme.palette.white.main,width:330,borderRadius:'8px'}}
        InputProps={{
          endAdornment: <InputAdornment position="start" sx={{margin:0,cursor:'pointer'}} onClick={()=>{if(value.trim().length>2){getConfirmedAppintments()} }}><SearchIcon/></InputAdornment>,
        }}

    />
    );
  };