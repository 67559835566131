import { Box, Button, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Modal, Paper, Select, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { ADMIN_ABDM_HEALTH_FACILITY_REGISTRY, ADMIN_ACCOUNT_FOR, ADMIN_ADDRESS, ADMIN_ADD_CLINIC_IMAGES, ADMIN_ADD_LOGO, ADMIN_ADD_NEW_CLINICS, ADMIN_ADD_TO_INDIAS_DIGITAL_ECOSYSTEM, ADMIN_ALL_CLINIC, ADMIN_CANCEL, ADMIN_CLINIC_CONTACT_NUMBER, ADMIN_CLINIC_DETAILS, ADMIN_CLINIC_NAME, ADMIN_CLINIC_SPECIALIZATION, ADMIN_DELETE, ADMIN_DIRECTIONS_TO_CLINIC, ADMIN_EDIT, ADMIN_HEALTHCARE_FACILITY_REGISTRY, ADMIN_HEALTH_FACILITY_REGISTRY, ADMIN_HOW_TO_CREATE, ADMIN_LINK_GOOGLE_BUSINESS, ADMIN_LINK_NOW, ADMIN_MAX_USER_LIMIT, ADMIN_NEW_CLINIC, ADMIN_NEW_USER, ADMIN_PERSONAL_WEBSITE_URL, ADMIN_REGISTER_NOW, ADMIN_SERVICE, ADMIN_SERVICES, ADMIN_SUBMIT, ADMIN_TIMINGS, ADMIN_UPLOAD_IMAGE, ADMIN_VIEW_ALL_CLINIC, ADMIN_WATCH_VIDEO } from '../../../../assets/translations/admin';
import { LoadingButton } from '@mui/lab';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllClinics } from '../../../../redux/reducers/admin.slice';
import { APIClient } from '../../../../shared/Utils/api-client';
import { API_ROUTE_DELETE_CLINIC, API_ROUTE_EDIT_CLINIC, API_ROUTE_SEARCH_CLINIC, ROUTE_ADMIN_CLINIC_MANAGEMENT_INIT } from '../../../../shared/routes/route.constants';
import { getFeatureValue, useAuthorization } from '../../../../shared/Utils/useAuthorization';
import { debounce } from 'lodash';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:"14px",
    p: 2,
    
  };
  const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 280,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:"14px",
    p: 2,
  };

const sections = [
    {
        label:'Clinic Management',
        value:'/admin/clinicmanagement/allclinic'
    },
    {
        label:'User Management',
        value:'/admin/usermanagement'
    },
    {
        label:'Slot Management',
        value:'/admin/slotmanagement'
    },
]

const initState = {
    
}

const columns = [
    { id: 'clinicId', label: 'Clinic ID', minWidth: 100,align: 'left', },
    { id: 'clinicName', label: 'Clinic', minWidth: 170,align: 'left', },
    { id: 'address', label: 'Address', minWidth: 100,align: 'left', },
    {
      id: 'mobileNumber',
      label: 'Mobile Number',
      minWidth: 140,
      align: 'left',
    },
    {
      id: 'timing',
      label: 'Timings',
      minWidth: 160,
      align: 'left',
    },
    {
      id: 'maxUserLimit',
      label: 'Max User Limit',
      minWidth: 140,
      align: 'left',
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 140,
      align: 'left',
    }
  ];
  
function createData(clinicId,clinicName, address,mobileNumber, timings, maxUserLimit) {
return {clinicId, clinicName, address, timings, mobileNumber, maxUserLimit };
}


const initErrorState = {
    errorField:'',
    errorMsg:'',
    showError:false
}

const AllClinic = () => {

  //Hook
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {clinicList,loader} = useSelector((state)=>state.admin)

  //State
  const [pathname,setPathname] = useState('');
  const [state,setState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },initState)
  const [errorState,setErrorState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },initErrorState)
  const [rows,setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [resultsCount, setResultsCount] = useState(0);

  let evaluatedKey = useRef(null);
  let prevEvaluatedKey = useRef([]);
  const setEvaluatedKey = (val) => {
    evaluatedKey.current = val;
  };
  const setPrevEvaluatedKey = (val) => {
    prevEvaluatedKey.current = val;
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [selectedAccountID,setSelectedAccountID] = useState('');
  const [selectedAccount,setSelectedAccount] = useState({});

  const [openDeleteModal,setOpenDeleteModal] = useState(false);

  const [deleteLoader, setDeleteLoader] = useState(false);

  const userFeatures = useAuthorization();

  const [clinicSearchValue, setClinicSearchValue] = useState('');

  const [noSearchResults, setNoSearchResults] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);


  //Effects
  useEffect(()=>{
    if(location.pathname){
        setPathname(location.pathname);
    }
  },[location])

  useEffect(()=>{
    setEvaluatedKey(null);
    setPrevEvaluatedKey([]);
    FetchAllClinics(rowsPerPage,evaluatedKey);
  },[])

  useEffect(()=>{
    if(clinicList.length){
        setRows(clinicList)
    }
  },[clinicList])
  
  //Functions

  const FetchAllClinics = async (limit,isPrev,isLast) => {
    const response = await dispatch(fetchAllClinics({
        params:{
            limit:limit,
            ...(isLast !== false &&
                (isPrev === true
                  ? prevEvaluatedKey.current[prevEvaluatedKey.current.length - 1]
                  : evaluatedKey.current)
                  ? {
                      last_evaluatedKey:
                        isPrev === true
                          ? prevEvaluatedKey.current[
                              prevEvaluatedKey.current.length - 1
                            ]
                          : evaluatedKey.current,
                    }
                  : {}),
        }
    }));
    if(response && response?.payload?.data?.count===0){
        navigate(ROUTE_ADMIN_CLINIC_MANAGEMENT_INIT)
    }
    setResultsCount(response?.payload?.data?.count)
    setEvaluatedKey(response?.payload?.data?.lastEvaluatedKey)
  }

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
        console.log("next page");
        setPrevEvaluatedKey([...prevEvaluatedKey.current, evaluatedKey.current]);
        setPage(newPage);
        FetchAllClinics(rowsPerPage);
      } else {
        console.log("prev page");
        prevEvaluatedKey.current.pop();
        setPage(newPage);
        FetchAllClinics(rowsPerPage, true);
      }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickRow = (clinic_id,account_name)=>{
    navigate({
        pathname: "/admin/clinicmanagement/clinicdetails",
        search: createSearchParams({
            account_id: clinic_id,
            account_name: account_name
        }).toString()
    });
    return;
  }

  const handleClickEdit = (e)=>{
    navigate({
        pathname: "/admin/clinicmanagement/editclinic",
        search: createSearchParams({
            account_id: selectedAccountID,
        }).toString()
    })
    return;
  }
  const handleClickDelete = (e)=>{
    setOpenDeleteModal(true);
    return;
  }
  const handleClickNewClinic = ()=>{
    navigate('/admin/clinicmanagement/newclinic')
    return;
  }
  const handleClickBack = ()=>{
    return;
  }
  const handleNavigatetToUserList = (account_id,account_name)=>{
    navigate({
        pathname: "/admin/usermanagement",
        search: createSearchParams({
            account_id: account_id,
            account_name: account_name,
            show_users:true
        }).toString()
    });
    return;
  }

  const handleNavigatetToAddUser = (account_id,account_name)=>{
    navigate({
        pathname: "/admin/usermanagement",
        search: createSearchParams({
            account_id: account_id,
            account_name: account_name,
        }).toString()
    });
  }

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteClinic = async () => {

    setDeleteLoader(true);

    await APIClient.delete(API_ROUTE_DELETE_CLINIC(selectedAccountID))
    .then(res=>{
        setDeleteLoader(false)
        setOpenDeleteModal(false);
        navigate(0);
    }).catch(err=>{
        setDeleteLoader(false);
        setOpenDeleteModal(false);
        setAnchorEl(null);
        setErrorState({
            errorField:'delete clinic',
            errorMsg:err || 'Unable to disable clinic',
            showError:true
        })
    })
  }
  
  const handleClickEnable = async () => {
    try {
        setDeleteLoader(true);

        await APIClient.put(API_ROUTE_EDIT_CLINIC(selectedAccount.account_id),{
            ...selectedAccount,
            is_active:true,
        })
        .then(res=>{
            setDeleteLoader(false)
            setOpenDeleteModal(false);
            navigate(0);
        }).catch(err=>{
            setDeleteLoader(false);
            setOpenDeleteModal(false);
            setAnchorEl(null);
            // setErrorState({
            //     errorField:'enable clinic',
            //     errorMsg:err || 'Unable to enable clinic',
            //     showError:true
            // })
        })
    } catch (error) {
        console.log(error)
        // setErrorState({
        //     errorField:'enable clinic',
        //     errorMsg:error || 'Unable to enable clinic',
        //     showError:true
        // })
    }
}

    
  


  const handleSearchClinic = useCallback(debounce(async (account_id) => {
    
    setSearchLoader(true);

    await APIClient.get(API_ROUTE_SEARCH_CLINIC(100,account_id)).then((res)=>{
        setSearchLoader(false);

        if(res?.data?.rows?.length){
            setNoSearchResults(false);
            setRows(res?.data?.rows);
            setResultsCount(res?.data?.count)
        }
        else{
            setNoSearchResults(true);
        }
        
    }).catch((err)=>{
        setSearchLoader(false);
        setNoSearchResults(true);
        setErrorState({
            errorField:'search',
            errorMsg:err && typeof err !== 'object' ? err : 'Unable to Search',
            showError:true
        })
    })
  },500),[])

  const handleChangeClinicSearch = (e)=>{
    let val = e.target.value;
    setClinicSearchValue(val);

    if(val === ''){
        setNoSearchResults(false);
        setEvaluatedKey(null)
        setPrevEvaluatedKey([])
        FetchAllClinics(rowsPerPage);
    }
    else{
        if(val.length >= 3){
            handleSearchClinic(val);
        }
    }
  }

  const formatTiming = (slots)=>{
    if(!slots) return "";

    return (
        <Typography variant='caption'>
            {slots.Morning && slots.Morning.length && (
                <Typography variant='caption' display={'block'}>{"Morning: "+slots.Morning[0]+'-'+slots.Morning[1]}</Typography>
            )}
            {slots.Afternoon && slots.Afternoon.length && (
                <Typography variant='caption' display={'block'}>{"Afternoon: "+slots.Afternoon[0]+'-'+slots.Afternoon[1]}</Typography>
            )}
            {slots.Evening && slots.Evening.length && (
                <Typography variant='caption' display={'block'}>{"Evening: "+slots.Evening[0]+'-'+slots.Evening[1]}</Typography>
            )}
            {slots.Night && slots.Night.length && (
                <Typography variant='caption' display={'block'}>{"Night: "+slots.Night[0]+'-'+slots.Night[1]}</Typography>
            )}
        </Typography>
    )
  }


  return (
    <>
      {isMobileScreen ? (
        <>
          
        </>
      ) : (
        <Box height={"100%"} width={"100%"} zIndex={100} position={"absolute"}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                ContentProps={{ sx: { background: "red", marginTop: "40px" } }}
                open={errorState.showError}
                onClose={() => setErrorState({showError:false})}
                autoHideDuration={6000}
                message={errorState.errorMsg}
            />


          <Box >
            <Grid container height={{xs:"100%",md:`calc(100vh - 42px)`}} width={{xs:"100%",md:`calc(100vw - 60px)`}} spacing={4}>
              
              {/* Left Panel */}
              <Grid
                item
                height="100%"
                xs={3.3}
                
              >
                    <Box 
                        width={'100%'} 
                        height={'100%'}
                        sx={{
                            // boxShadow: "1px 1px 4px 2px rgba(0,0,0,0.2)",
                            backgroundColor: "#ffffff",
                            borderRadius:'0px 8px 0px 0px'
                        }}
                    >
                        {
                            sections.map((elem)=>{
                                return (
                                    <Box 
                                        key={elem.label}
                                        width={'100%'} 
                                        boxSizing={'border-box'}
                                        paddingX={4} 
                                        paddingY={2} 
                                        display={'flex'} 
                                        justifyContent={'space-between'} 
                                        alignItems={'center'}
                                        onClick={()=>{
                                            // navigate(elem.value)
                                        }}
                                        sx={{
                                            cursor:'pointer'
                                        }}
                                    >
                                        <Typography 
                                            variant='subtitle2' 
                                            color={pathname.includes(elem.value) ? theme.palette.primary.main : 'black'}
                                            fontWeight={pathname.includes(elem.value) ? "bold" : 'regular'}
                                        >
                                            {elem.label}
                                        </Typography>
                                        {
                                            pathname.includes(elem.value) && (
                                                <ArrowForwardIosOutlinedIcon fontSize='small' sx={{color:theme.palette.primary.main}}/>
                                            )
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
              </Grid>


              {/* Right Panel */}
              <Grid
                item
                height="100%"
                width={'100%'}
                xs
                spacing={10}
                style={{overflowX:'auto'}}
              >
                <Grid
                    container
                    height={'100%'}
                    spacing={2}
                    direction={'column'}
                    width={'100%'}
                >
                    <Grid
                        item
                        xs
                        height={'10%'}
                        width={'100%'}
                    >
                        <Box 
                            width={'100%'} 
                            height={'100%'}
                            sx={{
                                backgroundColor: "#ffffff",
                                boxSizing:'border-box',
                                flex:1,
                                borderRadius:'8px',
                                overflow:'hidden',
                                position:'relative'
                            }}
                        >
                            {/* Heading */}
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={2}>
                                
                                {/* Title */}
                                <Box display={'flex'} gap={0} alignItems={'center'}>
                                    {/* <IconButton
                                        onClick={handleClickBack}
                                    >
                                        <ArrowBackIcon/>
                                    </IconButton> */}
                                    <Typography variant='subtitle1' fontWeight={'bold'}>{ADMIN_ALL_CLINIC['eng']}</Typography>
                                </Box>

                                {/* Header Buttons */}
                                <Box display={'flex'} gap={2} alignItems={'center'}>
                                    <TextField
                                        disabled={loader}
                                        autoComplete='off'
                                        type='text'
                                        placeholder='Search Clinic id...'
                                        value={clinicSearchValue.current}
                                        onChange={handleChangeClinicSearch}
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                    />

                                    <Box display={userFeatures.includes(getFeatureValue('CLINIC_CREATION')) ? 'flex' : 'none'} gap={2} height={'40px'}>
                                        <Button
                                            variant='contained'
                                            startIcon={<AddOutlinedIcon/>}
                                            onClick={handleClickNewClinic}
                                        >
                                            <Typography variant='subtitle2' textTransform={'none'}>{ADMIN_NEW_CLINIC['eng']}</Typography>
                                        </Button>
                                    </Box>
                                </Box>

                                

                            </Box>  

                            {/* Details */}
                            <Box height={'100%'} width={'100%'} display={'flex'} flexDirection={'column'} sx={{boxSizing:'border-box'}} paddingX={4} paddingTop={1} paddingBottom={10}>
                                
                                <Paper 
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        overflowX:'scroll',
                                        boxShadow:'none',
                                    }}
                                
                                >
                                    {loader || searchLoader ? (
                                        <>
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                        </>
                                    ) : (
                                        <TableContainer 
                                            sx={{ height: "100%", maxHeight: "100%", flex: 1,overflowX:'auto' }}
                                            //  style={{ height: "100%", maxHeight: "100%",width:'100%',maxWidth:'100%',minWidth:'100%', flex: 1,overflowX:'scroll'}}
                                             
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                            key={column?.id}
                                                            align={column?.align}
                                                            // style={{ minWidth: column?.minWidth }}
                                                            >
                                                                <Typography variant={'subtitle2'} textTransform={'none'} fontWeight={'bold'}>{column?.label}</Typography>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {noSearchResults && (
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={"100%"}
                                                                style={{ borderBottom: "none" }}
                                                            >
                                                                <Box
                                                                    position="relative"
                                                                    padding={2}
                                                                    width="100%"
                                                                    display="flex"
                                                                    flexDirection="column"
                                                                    justifyContent="center" // Centers content vertically in the container
                                                                    alignItems="center" // Centers content horizontally in the container
                                                                    gap={1}
                                                                    style={{
                                                                    marginX: "auto",
                                                                    }}
                                                                >
                                                            
                                                                    <Typography>No Search Results</Typography>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                    }
                                                    {!noSearchResults && rows
                                                    .map((row,index) => {
                                                        return (
                                                        <TableRow hover={row.is_active?true:false} role="checkbox" tabIndex={-1} key={row.clinicID} onClick={()=>{handleClickRow(row.account_id,row.account_name)}} sx={{
                                                                cursor:'pointer',
                                                                backgroundColor:row.is_active?'white':theme.palette.red.light,
                                                            }}
                                                        >
                                                            <TableCell key={columns[0].id} align={columns[0].align}>
                                                                <Box width={'100px'} sx={{overflowX:'auto'}}>
                                                                    <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.account_id}</Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell key={columns[1].id} align={columns[1].align}>
                                                                <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.account_name}</Typography>
                                                            </TableCell>
                                                            <TableCell key={columns[2].id} align={columns[2].align}>
                                                                <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{`${row?.address?.city || ""} ${row?.address?.state || ""} ${row?.address?.country || ""}`}</Typography>
                                                            </TableCell>
                                                            <TableCell key={columns[3].id} align={columns[3].align}>
                                                                <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.emergency_info}</Typography>
                                                            </TableCell>
                                                            <TableCell key={columns[4].id} align={columns[4].align}>
                                                                <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>
                                                                    {/* {formatTiming(row?.appointment_slots)} */}
                                                                    {''}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell key={columns[5].id} align={columns[5].align}>
                                                                <Typography variant={'subtitle2'} textTransform={'none'} color={theme.palette.white.greyText}>{row?.max_user_allowed}</Typography>
                                                            </TableCell>
                                                            <TableCell key={columns[6].id} align={columns[6].align}>
                                                                <Box display={'flex'}>
                                                                    <Tooltip title={"View Clinic Details"}>
                                                                        <IconButton onClick={(e)=>{handleClickRow(row?.account_id,row?.account_name); e.stopPropagation();}}>
                                                                            <Box
                                                                                component={'img'}
                                                                                src='/images/viewclinicicon.svg'
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title={"View Users"}>
                                                                        <IconButton onClick={(e)=>{handleNavigatetToUserList(row?.account_id,row?.account_name); e.stopPropagation();}}>
                                                                            <Box
                                                                                component={'img'}
                                                                                src='/images/viewusersicon.svg'
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    
                                                                    <Tooltip title={"Add New Users"}>
                                                                        <IconButton onClick={(e)=>{handleNavigatetToAddUser(row?.account_id,row?.account_name); e.stopPropagation();}} sx={{display:userFeatures.includes(getFeatureValue('USER_CREATION')) ? 'flex' : 'none'}}>
                                                                            <Box
                                                                                component={'img'}
                                                                                src='/images/addusericon.svg'
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    
                                                                    <IconButton onClick={(e)=>{handleClickMenu(e); setSelectedAccountID(row?.account_id); setSelectedAccount(row) ;e.stopPropagation();}} sx={{display:userFeatures.includes(getFeatureValue('CLINIC_UPDATION')) ? 'flex' : 'none'}}>
                                                                        <MoreVertOutlinedIcon color='primary'/>
                                                                    </IconButton>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleCloseMenu}
                                            >
                                                <MenuItem sx={{display:selectedAccount.is_active ? "block" : "none"}} onClick={handleClickEdit}>{ADMIN_EDIT['eng']}</MenuItem>
                                                <MenuItem onClick={handleClickDelete}>{selectedAccount.is_active ? "Disable" : "Enable"}</MenuItem>
                                            </Menu>
                                        </TableContainer>
                                    )}
                                    
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={resultsCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        disabled={loader}
                                    />
                                </Paper>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
        <Modal
            open={openDeleteModal}
            onClose={() => {
                setOpenDeleteModal(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box
            sx={isMobileScreen ? styleMobile : style}
            display={"flex"}
            flexDirection={"column"}
            gap={1}
        >
            <Box
            maxHeight={300}
            overflow={"auto"}
            justifyContent={"center"}
            alignItems={"start"}
            display={"flex"}
            flexDirection={"column"}
            gap={0}
            my={1}
            >
            <Typography variant="h6" fontWeight={600} textAlign={"left"}>
                {`${selectedAccount.is_active ? 'Disable' : 'Enable'} Clinic`}
            </Typography>
            <Typography variant="subtitle1" textAlign={"center"}>
                {`Are you sure you want to ${selectedAccount.is_active ? 'disable' : 'enable'} ${selectedAccountID}?`}
            </Typography>
            <Box display={'flex'} width={'100%'} gap={1} sx={{mt:2}} justifyContent={'end'}>
                <Button variant="outlined" sx={{flex:1}} onClick={()=>{setOpenDeleteModal(false)}}>
                    Cancel
                </Button>
                <LoadingButton loading={deleteLoader} variant="contained" sx={{flex:1}} color='primary' onClick={selectedAccount.is_active ? handleDeleteClinic : handleClickEnable}>
                    <Typography color={theme.palette.white.main}>{selectedAccount.is_active ? 'Disable' : 'Enable'}</Typography>
                </LoadingButton>
                
            </Box>
            </Box>
        </Box>
        </Modal>
    </>
  )
}

export default AllClinic