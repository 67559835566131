import { Grid, Link, Skeleton ,Box, Button} from "@mui/material";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import  { useState, useEffect } from "react";
import { APIClient } from "../../../../shared/Utils/api-client";
import {API_ROUTE_GET_CLINICAL_MATRIX} from "../../../../shared/routes/route.constants";
import moment from "moment";
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts';
import { LineChart } from '@mui/x-charts/LineChart';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  
} from '@mui/material';

const useStyles = makeStyles({
  gridLine: {
    stroke: 'rgba(0, 0, 0, 0.15)',
    strokeDasharray: '3 3',
  },
});
const SingleHeaderCard = ({heading,headingValue,frequency,icon,handleClickOpen,type,loading}) => {
    return(
        <>
            <Card  sx={{borderRadius:"12px"}}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <span style={{fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif",color:"#838383"}}>{heading}</span>
                            
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:"right"}}>
                            {loading?<>
                              <Skeleton variant="text" />
                            </>:<>
                              <Link href="#" onClick={()=>{handleClickOpen(type)}} style={{fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif",color:"#494E9D",textDecoration:"none"}}>
                                  View All
                              </Link>
                            </>}
                                
                        </Grid>
                        <Grid item xs={12}>
                          <h3  style={{fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif",color:"#313131"}}>
                            {loading?<>
                              <Skeleton variant="text" />
                            </>:<>
                              {headingValue}
                            </>}
                            
                          </h3>
                        </Grid>  
                        <Grid item xs={6}>
                            <span  style={{fontSize:"48px",fontWeight:"600",fontFamily:"Roboto, sans-serif",color:"#313131"}}>
                              {loading?<>
                                <Skeleton variant="text" />
                              </>:<>
                                {frequency}
                              </>}
                                
                            </span> 
                            <span  style={{fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif",color:"#838383",paddingLeft:"5px"}}>
                              Times
                            </span>
                        </Grid>
                        <Grid item xs={6} sx={{position:"relative"}}>
                          <Box sx={{position:"absolute",bottom:"0",right:"0"}}>
                             {loading?<>
                                <Skeleton variant="circle" />
                              </>:<>
                                <img src={`/images/admin/matrix/${icon}`} />
                              </>}
                            
                          </Box>  
                          
                        </Grid>    
                    </Grid>
                   
                </CardContent>
            </Card>
        </>
    );
}





const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 12,
  fontFamily: "Roboto, sans-serif",
  color: "#838383",
  fontWeight: "400",
}));

const StyledSubText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 28,
  fontFamily: "Roboto, sans-serif",
  color: "#313131",
  fontWeight: "700",
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={(top + height / 2)-20}>
      {children}
    </StyledText>
  );
}

function PieCenterSubLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledSubText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledSubText>
  );
}




function  ClinicalMatrix (){
    
    const [loading,setLoading] = useState(true);
    const [clinicalMatrixData,setClinicalMatrixData] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate:moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endDate:moment().format('YYYY-MM-DD')
    });
    const [reportType,setReportType] = useState("Monthly");
    useEffect(() => {
        console.log("dateRange",dateRange);
        if(dateRange.startDate!="Invalid Date" && dateRange.endDate!="Invalid Date"){
          setLoading(true);
        
          getClinicalMatrix();
        }
        
    }, [dateRange,reportType]);
    const [openSnakbar, setOpenSnakbar] = useState(false);
    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSnakbar(false);
    };
    
    const  getClinicalMatrix = async () => {
        const currentDoctor = localStorage.getItem("userDetails")?JSON.parse(localStorage.getItem("userDetails")):[];
        try{
          APIClient.get(API_ROUTE_GET_CLINICAL_MATRIX(),{
              params:{
                  doctorId:currentDoctor?.attributes?.sub,
                  startDate:dateRange.startDate,
                  endDate:dateRange.endDate,
                  chartType:reportType
              }
          }).then((response) => {
              setClinicalMatrixData(response.data.data);
              setLoading(false);
          }).catch((error) => {
              setOpenSnakbar(true);
              console.log("error",error);
          })
        }
        catch(e){
          console.log("error",e);
        }
        
    }



    const [modelData, setModelData] = useState({
      title:"",
      icon:"",
      data:[],
    });
    const [open, setOpen] = useState(false);
  
    const handleClickOpen = (type) => {
      
      let title="";
      let icon="";
      switch (type) {
        case "complaints":
          title = "Top Symptoms";
          icon = "fever.svg";
          break;
          case "diagnosis":
            title = "Top Diagnosis";
            icon = "virus.svg";
            break;
          case "medications":
            title = "Top Medications";
            icon = "medicine.svg";
            break;
          case "tests":
            title = "Top Tests Advised";
            icon = "test.svg";
            break;      
        
      }

      setModelData({title:title,icon:icon,data:clinicalMatrixData?.clinical_metrices?.[type]})
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const classes = useStyles();
    return (
        <>
            {/* clinical matrix page starts */}
            <Grid container style={{overflow:"scroll",height:"100vh",paddingBottom:"100px",paddingLeft:"20px",paddingRight:"20px"}}>
                <Grid item xs={6}>
                    <HeadingTitle>Consultation Metrics</HeadingTitle>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      
                        {dateRange.startDate&&<DateRangePicker
                            slots={{ field:SingleInputDateRangeField }}
                            name="allowedRange"
                            format={"YYYY-MM-DD"}
                            slotProps={{
                                field: {
                                    readOnly: true
                                }
                            }}
                            sx={{ backgroundColor:"#fff",
                                  width:"250px",
                                  borderColor:"#DDDDDD",
                                  '& .MuiOutlinedInput-notchedOutline::after': {
                                    content: 'url(/images/kpi/calender.svg)', // Replace with your icon path
                                    position: 'absolute',
                                    top: '40%',
                                    right: '10px', // Adjust positioning
                                    transform: 'translateY(-50%)',
                                    width: '20px', // Adjust icon size
                                    height: '20px',
                                  },
                                }}
                            value={[dayjs(dateRange.startDate), dayjs(dateRange.endDate)]}
                           
                            onChange={([start, end]) => { 
                              setDateRange({ startDate: dayjs(start).format('YYYY-MM-DD'), endDate: dayjs(end).format('YYYY-MM-DD') }); 
                            }}
                        />}
                    </LocalizationProvider>    
                </Grid>    
                
                <Grid item xs={12}>
                    {/* Cards started */}
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                
                                <SingleHeaderCard 
                                    heading="Top Symptoms" 
                                    handleClickOpen={handleClickOpen}
                                    headingValue={clinicalMatrixData?.clinical_metrices?.complaints?.[0]?.name} // "Sore throat" }
                                    frequency={clinicalMatrixData?.clinical_metrices?.complaints?.[0]?.times}
                                    type="complaints"
                                    loading={loading}
                                    icon="fever.svg"/>
                           
                            </Grid>
                            <Grid item xs={3}>
                                <SingleHeaderCard 
                                    heading="Top Diagnosis" 
                                    handleClickOpen={handleClickOpen}
                                    headingValue={clinicalMatrixData?.clinical_metrices?.diagnosis?.[0]?.name}
                                    frequency={clinicalMatrixData?.clinical_metrices?.diagnosis?.[0]?.times}
                                    type="diagnosis"
                                    loading={loading}
                                    icon="virus.svg"/>
                           
                            </Grid>
                            <Grid item xs={3}>
                                <SingleHeaderCard 
                                    heading="Top Medications" 
                                    handleClickOpen={handleClickOpen}
                                    headingValue={clinicalMatrixData?.clinical_metrices?.medications?.[0]?.name}
                                    frequency={clinicalMatrixData?.clinical_metrices?.medications?.[0]?.times}
                                    type="medications"
                                    loading={loading}
                                    icon="medicine.svg"/>
                           
                            </Grid>
                            <Grid item xs={3}>
                                <SingleHeaderCard 
                                    heading="Top Tests Advised" 
                                    handleClickOpen={handleClickOpen}
                                    headingValue={clinicalMatrixData?.clinical_metrices?.tests?.[0]?.name}
                                    frequency={clinicalMatrixData?.clinical_metrices?.tests?.[0]?.times}
                                    type="tests"
                                    loading={loading}
                                    icon="test.svg"/>
                           
                            </Grid>        
                        </Grid>    
                    {/* cards Ended*/}
                </Grid>
                <Grid item xs={12} sx={{marginTop:"20px"}}>
                  <Card  sx={{borderRadius:"12px"}}>
                    <CardContent>
                            <Grid container>
                                <Grid item xs={6}>
                                    <span   style={{fontSize:"16px",fontWeight:"700",fontFamily:"Roboto, sans-serif",color:"#313131",paddingLeft:"5px"}}>
                                      Patient Count
                                    </span>
                                    <span   style={{fontSize:"16px",fontWeight:"400",fontFamily:"Roboto, sans-serif",color:"#64748B",paddingLeft:"5px"}}>
                                    (No. of Patients)
                                    </span>
                                </Grid>
                                <Grid item xs={6} sx={{textAlign:"right"}}>
                                  {loading?<>
                                    <Skeleton variant="text" />
                                  </>:<>
                                    <Button variant={reportType=="Daily"?"contained":"outlined"} sx={{mr:"10px",borderRadius:"8px",textTransform:"none !important"}} onClick={()=>setReportType("Daily")}>Daily</Button>

                                    <Button variant={reportType=="Weekly"?"contained":"outlined"} sx={{mr:"10px",borderRadius:"8px",textTransform:"none !important"}}  onClick={()=>setReportType("Weekly")}>Weekly</Button>

                                    <Button variant={reportType=="Monthly"?"contained":"outlined"} sx={{mr:"10px",borderRadius:"8px",textTransform:"none !important"}}  onClick={()=>setReportType("Monthly")}>Monthly</Button>
                                  </>}
                                  

                                </Grid>  
                                <Grid item xs={12}>
                                    {loading?<>
                                      <Skeleton variant="rect" />
                                    </>:<>
                                        {clinicalMatrixData?.patient_metrices?.chartData &&<LineChart
                                          grid={{ horizontal: true }}
                                          height={300}
                                          sx={{
                                            '& .MuiChartsAxis-line': {
                                              display: 'none',
                                            }  
                                          }}
                                          fill={true}
                                          series={[
                                            { data: clinicalMatrixData?.patient_metrices?.chartData.map((item) => item.doc_count),color: "#1D4ED8",fill:true },
                                            
                                          ]}
                                          xAxis={[{ scaleType: 'point', data: clinicalMatrixData?.patient_metrices?.chartData.map((item) => item.key) }]}
                                        />}
                                    </>}
                                  
                                </Grid>    
                            </Grid>  
                    </CardContent>
                  </Card>  
                  
                </Grid>
                <Grid item xs={12}>
                  <HeadingTitle>Consultation Metrics</HeadingTitle>                
                </Grid>
                <Grid item xs={12}>
                   {/*
                    <PatientChart/>
                    */}
                  
                    
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Card  sx={{borderRadius:"12px"}}>
                              <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <h5   style={{fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif",color:"#838383",paddingLeft:"5px",margin:"0"}}>
                                          Patient appointment status
                                        </h5>
                                    </Grid>  
                                    <Grid item xs={6}>
                                      {loading?<>
                                        <Skeleton variant="circle" />
                                      </>:<>
                                        {clinicalMatrixData?.appointment_metrices?.appointment_status &&
                                          <PieChart 
                                            series={[{ data: clinicalMatrixData?.appointment_metrices?.appointment_status.map((item) => {return {value:item.count,label:item.status_type,color:item.color}}), innerRadius: 60 }]} 
                                            height={180}  
                                            slotProps={{
                                              legend: { hidden: true },
                                            }}
                                          >
                                            <PieCenterLabel>
                                                  Total Value
                                            </PieCenterLabel>
                                            <PieCenterSubLabel>
                                              
                                            {clinicalMatrixData?.appointment_metrices?.patient_records?.total_visits}
                                            </PieCenterSubLabel>
                                          </PieChart>}
                                      </>}
                                     
                                    </Grid>
                                    <Grid item xs={6} paddingTop="0px" sx={{paddingTop:"0px !important"}}>
                                        <TableContainer component={Paper} sx={{boxShadow:"none"}}>
                                          <Table sx={{ minWidth: 280 }} aria-label="simple table">
                                            <TableHead>
                                              <TableRow sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif",padding:"5px"}}>
                                                <TableCell sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif",padding:"5px"}}>Status</TableCell>
                                                <TableCell sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif",padding:"5px"}} align="right">Value</TableCell>
                                                <TableCell sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif",padding:"5px"}} align="right">%</TableCell>
                                               
                                              </TableRow>
                                            </TableHead>
                                            <TableBody sx={{boxShadow:"none"}}>
                                              {loading?<>
                                                
                                                <TableRow
                                                    key={"skeleton1"}
                                                    sx={{ '& th, & td': { border: 0 },border:0 }}
                                                  >
                                                    <TableCell sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif"}} component="th" scope="row">
                                                      <Skeleton variant="text" />
                                                    </TableCell>
                                                    <TableCell  sx={{color:"#313131",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} align="right">
                                                      <Skeleton variant="text" />
                                                    </TableCell>
                                                    <TableCell  sx={{color:"#313131",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} align="right">
                                                      <Skeleton variant="text" />
                                                    </TableCell>
                                                  
                                                  </TableRow>
                                                  <TableRow
                                                    key={"skeleton2"}
                                                    sx={{ '& th, & td': { border: 0 },border:0 }}
                                                  >
                                                    <TableCell sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif"}} component="th" scope="row">
                                                      <Skeleton variant="text" />
                                                    </TableCell>
                                                    <TableCell  sx={{color:"#313131",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} align="right">
                                                      <Skeleton variant="text" />
                                                    </TableCell>
                                                    <TableCell  sx={{color:"#313131",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} align="right">
                                                      <Skeleton variant="text" />
                                                    </TableCell>
                                                  
                                                  </TableRow>
                                                  <TableRow
                                                    key={"skeleton3"}
                                                    sx={{ '& th, & td': { border: 0 },border:0 }}
                                                  >
                                                    <TableCell sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif"}} component="th" scope="row">
                                                      <Skeleton variant="text" />
                                                    </TableCell>
                                                    <TableCell  sx={{color:"#313131",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} align="right">
                                                      <Skeleton variant="text" />
                                                    </TableCell>
                                                    <TableCell  sx={{color:"#313131",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} align="right">
                                                      <Skeleton variant="text" />
                                                    </TableCell>
                                                  
                                                  </TableRow>
                                              </>:<>
                                                {clinicalMatrixData?.appointment_metrices && clinicalMatrixData?.appointment_metrices?.appointment_status.map((data) => (
                                                  <TableRow
                                                    key={data.status_type}
                                                    sx={{ '& th, & td': { border: 0 },border:0 }}
                                                  >
                                                    <TableCell sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif",padding:"5px"}} >
                                                      <span style={{height:"10px",width:"10px",borderRadius:"50%",display:"inline-block",marginRight:"5px",backgroundColor:data.color}}></span>
                                                      {data.status_type}
                                                    </TableCell>
                                                    <TableCell  sx={{color:"#313131",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif",padding:"5px"}} align="right">{data.count}</TableCell>
                                                    <TableCell  sx={{color:"#313131",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif",padding:"5px"}} align="right">{data.percent}</TableCell>
                                                  
                                                  </TableRow>
                                                ))}
                                              </>}
                                              
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                    </Grid>    
                                </Grid>  
                              </CardContent>
                            </Card>
                          </Grid>  
                          <Grid item xs={3}>
                            <Card  sx={{borderRadius:"12px"}}>
                                <CardContent>
                                  <div style={{color:"#838383",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif"}}>
                                    Patient Records
                                  </div>
                                  <div>
                                      <span  style={{color:"#1C1C1E",fontSize:"12px",fontWeight:"700",fontFamily:"Roboto, sans-serif",border:"1px solid #EAF7EC",padding:"5px",borderRadius:"15px"}}>
                                       
                                        {loading?<>
                                          <Skeleton variant="text" />
                                        </>:<>
                                          {clinicalMatrixData?.appointment_metrices?.patient_records?.new_patient}
                                        </>}
                                      </span>
                                      <span  style={{color:"#838383",fontSize:"12px",fontWeight:"400",fontFamily:"Roboto, sans-serif",paddingLeft:"5px"}}>
                                        new patient added today
                                      </span>
                                  </div>
                                  <Grid container spacing={2} sx={{marginTop:"7px"}}>
                                      <Grid item xs={6} >
                                          <Card sx={{borderRadius:"12px",background:"#eaf7ec"}}>
                                            <CardContent sx={{padding:"5px",paddingBottom:"10px !important",paddingTop:"10px !important"}}>
                                             
                                              <div style={{color:"#30AC41",fontSize:"18px",fontWeight:"700",fontFamily:"Nunito Sans, sans-serif",paddingLeft:"5px"}}>
                                                
                                                {loading?<>
                                                  <Skeleton variant="text" />
                                                </>:<>
                                                  {clinicalMatrixData?.appointment_metrices?.patient_records?.new_visits}
                                                </>}
                                              </div>
                                              <div  style={{color:"#313131",fontSize:"13px",fontWeight:"400",fontFamily:"Nunito Sans, sans-serif",paddingLeft:"5px"}}>
                                                New Visits
                                              </div>
                                            </CardContent>
                                          </Card>    
                                          
                                      </Grid> 
                                      <Grid item xs={6}>
                                          <Card sx={{borderRadius:"12px",background:"#fbf5e6"}}>
                                            <CardContent  sx={{padding:"5px",paddingBottom:"10px !important",paddingTop:"10px !important"}}>
                                             
                                              <div style={{color:"#D89E09",fontSize:"18px",fontWeight:"700",fontFamily:"Nunito Sans, sans-serif",paddingLeft:"5px"}}>
                                                
                                                {loading?<>
                                                  <Skeleton variant="text" />
                                                </>:<>
                                                  {clinicalMatrixData?.appointment_metrices?.patient_records?.repeat_visits}
                                                </>}
                                              </div>
                                              <div  style={{color:"#313131",fontSize:"13px",fontWeight:"400",fontFamily:"Nunito Sans, sans-serif",paddingLeft:"5px"}}>
                                                Repeat Visits
                                              </div>
                                            </CardContent>
                                          </Card>    
                                      </Grid> 
                                      <Grid item xs={6}>
                                         <Card sx={{borderRadius:"12px",background:"#eaf7ec"}}>
                                            <CardContent   sx={{padding:"5px",paddingBottom:"10px !important",paddingTop:"10px !important"}}>
                                             
                                              <div style={{color:"#2188FF",fontSize:"18px",fontWeight:"700",fontFamily:"Nunito Sans, sans-serif",paddingLeft:"5px"}}>
                                                
                                                {loading?<>
                                                  <Skeleton variant="text" />
                                                </>:<>
                                                  {clinicalMatrixData?.appointment_metrices?.patient_records?.total_visits}
                                                </>}
                                              </div>
                                              <div  style={{color:"#313131",fontSize:"13px",fontWeight:"400",fontFamily:"Nunito Sans, sans-serif",paddingLeft:"5px"}}>
                                                Total Visits
                                              </div>
                                            </CardContent>
                                          </Card>    
                                      </Grid> 
                                      <Grid item xs={6}>
                                         <Card sx={{borderRadius:"12px",background:"#fbf5e6"}}>
                                            <CardContent    sx={{padding:"5px",paddingBottom:"10px !important",paddingTop:"10px !important"}}>
                                             
                                              <div style={{color:"#D75C86",fontSize:"18px",fontWeight:"700",fontFamily:"Nunito Sans, sans-serif",paddingLeft:"5px"}}>
                                                
                                                {loading?<>
                                                  <Skeleton variant="text" />
                                                </>:<>
                                                  {clinicalMatrixData?.appointment_metrices?.patient_records?.repeat_perc}%
                                                </>}
                                              </div>
                                              <div  style={{color:"#313131",fontSize:"13px",fontWeight:"400",fontFamily:"Nunito Sans, sans-serif",paddingLeft:"5px"}}>
                                                Repeat Patient (%)
                                              </div>
                                            </CardContent>
                                          </Card>    
                                      </Grid>  
                                  </Grid>  
                                </CardContent>  
                            </Card>
                          </Grid>  
                          <Grid item xs={3}>
                            <Card  sx={{borderRadius:"12px"}}>
                              <CardContent  sx={{padding:"10px"}}>
                                <div   style={{color:"#838383",fontSize:"14px",fontWeight:"500",fontFamily:"Roboto, sans-serif",paddingLeft:"5px"}}>
                                  Consultation type
                                </div>
                                <div>
                                  {loading?<>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                  </>:<>
                                    <BarChart
                                      borderRadius={15}
                                      height={200}
                                      
                                      series={[
                                        { data: [clinicalMatrixData?.appointment_metrices?.appointment_type?.[0]?.count], label: 'online',color: '#F4939A', id: 'pvId' },
                                        { data: [clinicalMatrixData?.appointment_metrices?.appointment_type?.[1]?.count], label: 'offline',color: '#494E9D', id: 'uvId' },
                                      ]}
                                      grid={{ vertical: true }}
                                      sx={{
                                        '& .MuiChartsAxis-line': {
                                          display: 'none',
                                        }  
                                        ,
                                        '& .MuiChartsGrid-verticalLine':{
                                          strokeDasharray: '5 5',
                                        },
                                        '& .MuiChartsAxis-directionY':{
                                          display: 'none',
                                        } 

                                      }}
                                      slotProps={{
                                        legend: { hidden: true },
                                      }}
                                      layout="horizontal"
                                    >
                                      <Grid strokeDasharray="3 3" vertical={true} className={classes.gridLine} />
                                      
                                    </BarChart>  
                                  </>}
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: "10px",
                                }}>
                                  <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "4px"
                                  }}>
                                      <span style={{
                                        color: "#F4939A",
                                        width: "30px",
                                        height: "5px",
                                        display: "inline-block",
                                        background: "#F4939A",
                                        borderRadius: "5px"}}>

                                      </span>
                                      <span style={{fontFamily:"Inter, sans-serif",fontWeight:"400",fontSize:"12px",color:"#475569"}}>
                                        Online
                                      </span>
                                  </div>
                                  <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "4px"
                                  }}>
                                      <span style={{
                                        color: "#494E9D",
                                        width: "30px",
                                        height: "5px",
                                        display: "inline-block",
                                        background: "#494E9D",
                                        borderRadius: "5px"}}>

                                      </span>
                                      <span  style={{fontFamily:"Inter, sans-serif",fontWeight:"400",fontSize:"12px",color:"#475569"}}>
                                        Offline
                                      </span>
                                  </div>
                                </div>
                                
                              </CardContent>
                            </Card>      
                          </Grid>  
                      </Grid> 
                      
                   
                </Grid>
                

            </Grid>    
            {/* clinical matrix page ends */}
            
              <Dialog open={open} onClose={handleClose} sx={{"& .MuiDialog-paper":{borderRadius:"8px"}}}>

                <DialogTitle sx={{borderBottom:"1px solid #D7D7D7", color:"#494E9D",fontFamily:"Roboto, sans-serif",fontWeight:"600",fontSize:"20px",}}>{modelData.title}</DialogTitle>
                <div style={{position:"absolute",right:"20px",top:"20px"}}>
                  <img style={{cursor:"pointer"}} onClick={handleClose} src={`/images/admin/matrix/closeButton.svg`} />
                </div>
                <DialogContent sx={{padding:"0px"}}>
                  
                    
                      <TableContainer component={Paper} sx={{boxShadow:"none"}}>
                        <Table sx={{ width: '100%',minWidth: 600 }} aria-label="simple table">
                          <TableHead>
                            <TableRow sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif"}}>
                              <TableCell width={'75%'} sx={{color:"#313131",fontSize:"16px",fontWeight:"600",fontFamily:"Roboto, sans-serif",border:0}} align="left" >
                                <span>
                                  <img style={{width:"25px",height:"25px",paddingTop:"10px"}} onClick={handleClose} src={`/images/admin/matrix/${modelData.icon}`} />
                                </span>
                                <span style={{paddingBottom:"10px"}}>
                                  {modelData.title} 
                                </span>    
                                
                              </TableCell>
                              <TableCell width={'25%'} sx={{color:"#313131",fontSize:"16px",fontWeight:"600",fontFamily:"Roboto, sans-serif",border:0}} align="left">No. of times</TableCell>
                  
                            
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{boxShadow:"none"}}>
                            {modelData.data && modelData.data.map((data) => (
                              <TableRow
                                key={data.status_type}
                                sx={{ '& th, & td': { border: 0 },border:0 }}
                              >
                                
                                <TableCell  sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif"}} align="left">{data.sno}. {data.name}</TableCell>
                                <TableCell  sx={{color:"#838383",fontSize:"14px",fontWeight:"400",fontFamily:"Roboto, sans-serif"}} align="left">
                                  <span style={{color:"#313131"}}>
                                    {data.times}
                                  </span>
                                  <span style={{paddingLeft:"2px"}}>
                                    Times
                                  </span>
                                </TableCell>
                              
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                   
                </DialogContent>
                
              </Dialog>
              <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnakbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert
                  onClose={handleCloseSnackbar}
                  severity="error"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  something went wrong
                </Alert>
              </Snackbar>
        </>
    ) 
}

const HeadingTitle = styled('h4')(({ theme }) => ({
    fontSize: "16px",
    fontWeight: "700",
    color: '#313131',
    fontFamily: "Inter, sans-serif",
}));
export default ClinicalMatrix;