import {
  Box,
  Divider,
  // Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPrescriptionDetails,
  setShowArtilceCard,
} from "../../../../redux/reducers/prescription.slice";
import { useEffect, useRef, useState } from "react";
import {
  fetchDisplayUrlData,
  sendPrescription,
} from "../../../../shared/Utils/utils";
import Button from "../../../../shared/Button/Button";
import html2PDF from "jspdf-html2canvas";
import DOMPurify from "dompurify";
import "../../../../shared/layouts/style.css";

import {
  ADVICE,
  ADVICE_NOTES,
  BREAST,
  COMPLAINTS,
  DIABETES_ASSESSMENT,
  DIAGNOSIS,
  EXAMINATION,
  FOLLOW_UP,
  FOOT_EXAM,
  GENERAL_PHYSICAL_EXAMINATION,
  INVESTIGATIONS,
  INVESTIGATION_MONITORING,
  KNEE_EXAMINATION,
  MARITAL_HISTORY,
  MEAL_PREFERENCE,
  MEDICAL_HISTORY,
  MEDICAL_HISTORY_DIABETES,
  MEDICATION,
  MENSTRUAL_HISTORY,
  OBG_HISTORY,
  OBG_NOTES,
  OTHER_FINDINGS,
  PATIENT_HISTORY,
  PER_ABDOMEN,
  PER_SPECULUM,
  PER_VAGINUM,
  PHYSICAL_ACTIVITY,
  PHYSICAL_EXAMINATION,
  PHYSICAL_FINDING,
  PREGNANCY_HISTORY,
  PRESENTING_COMPLAINTS,
  REFERRAL,
  SYSTEMIC_EXAMINATION,
  TRIMESTER_NOTES,
} from "../../../../shared/constants";
import CustomizedDialogs from "../../../../shared/Dialog/Dialog";
import { useNavigate } from "react-router-dom";
import {
  API_ROUTE_SAVE_PRESCRIPTION,
  ROUTE_APPOINTMENTS,
} from "../../../../shared/routes/route.constants";
import { APIClient } from "../../../../shared/Utils/api-client";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneIcon from "../../../../shared/icons/PhoneIcon";
import MailIcon from "../../../../shared/icons/MailIcon";
import WebsiteIcon from "../../../../shared/icons/WebsiteIcon";
import LabTestIcon from "../../../../shared/icons/LabTestIcon";
import TabletIcon from "../../../../shared/icons/TabletIcon";
import { updateApponitment } from "../../../../redux/reducers/appointment.slice";
import dayjs from "dayjs";
import EndConsultationIcon from "../../../../shared/icons/EndConsultationIcon";
import { textAlign } from "@mui/system";
import PDFModal from "../../../../shared/Pdf-Modal/Pdf-Modal";

const Prescription = (props: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const removeBreaks = /(<([^>]+)>)/gi;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  function getQueryParams() {
    const params = new URLSearchParams(window.location.search);
    return {
      showVideo: params.get("showVideo"),
    };
  }
  const prescriptionTableHeader = [
    {
      headerName: "Medicine Name",
    },
    {
      headerName: "Qty/Dose",
    },
    {
      headerName: "Frequency",
    },
    {
      headerName: "Duration",
    },
    {
      headerName: "Meal Pref",
    },
    {
      headerName: "Start From",
    },
    {
      headerName: "Special Instruction",
    },
  ];
  const LabTestTableHeader = [
    {
      headerName: "Lab Test",
    },
    {
      headerName: "Test on",
    },
    {
      headerName: "Repeat on",
    },
    {
      headerName: "Remarks",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showVideo } = getQueryParams();
  const [prescriptionDetails, setPrescriptionDetails] = useState([]);
  const [openDialog, setOpenDialog] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();
  const [dateAndTime, setDateAndTime] = useState<any>();
  const [moleculeName, setMoleculeName] = useState<any>([]);
  const [printStatus, setPrintStatus] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const TableStyle = {
    cell: {
      borderLeft: "1px solid #D9D9D9",
      fontSize: isMobileScreen ? "10px" : "14px",
      padding: isMobileScreen ? "0px" : "16px",
      textAlign: "center",
    },
    medicalLabCell: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      borderBottom: "0px",
      padding: isMobileScreen ? "10px 0px 0px 0px" : "16px",
      fontSize: isMobileScreen ? "10px" : "14px",
    },
    medicineCell: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      borderBottom: "0px",
    },
  };
  const prescriptionStyle = {
    header: {
      fontSize: isMobileScreen ? "9px" : "12px",
      fontWeight: "400",
      lineHeight: "18px",
      color: "#0A0666",
    },
    patientDetailsHeader: {
      fontSize: isMobileScreen ? "8px" : "12px",
      fontWeight: "400",
      lineHeight: "16.41px",
    },
    patientFindingsHeader: {
      fontSize: isMobileScreen ? "12px" : "16px",
      fontWeight: "600",
      lineHeight: "24px",
      color: "#0A0666",
    },
    patientFindingsValue: {
      fontSize: isMobileScreen ? "12px" : "16px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    prescriptionContentStyle: {
      display: "flex",
      gap: "10px",
    },
    prescriptionIconStyle: {
      display: "inline-flex",
      justifyContent: "flex-start",
      height: isMobileScreen ? "27px" : "100%",
    },
  };
  const {
    prescription: { signedURL },
  } = useSelector((state: any) => state.prescription);

  
  const [prescriptionCompleted, setPrescriptionCompleted] = useState<boolean>(signedURL?.status === "Done" ? true : false)
  const [appointmentCancelledorDone, setAppointmentCancelledorDone] = useState<boolean>(signedURL?.appt_status === "Cancelled" || signedURL?.appt_status === "Done"  ? true : false)

  const {
    selectedAppointment: { patient, id },
  } = useSelector((state: any) => state.appointments);

  const EndPreview = props?.EndPreview;
  const tabData = props?.TabData;
  const setActiveTab = props?.setActiveTab;

  const { accountInfo, profile } = useSelector((state: any) => state.auth);

  
//   const convertAddressToString = (accountInfo: any) => {
//     const { address = {} } = accountInfo;
//     const { line = "", city = "", state = "", zipcode = "" } = address;

//     if (Object?.values(address)?.length) {
//         return `${line} ${city} ${state} - ${zipcode}`;
//     }

//     return "";
// };


  // const getPrescriptionDetails = async () => {
  //   dispatch(
  //     fetchPrescriptionDetails({
  //       params: { type: "patient", limit: "1", id: patient?.patient_id },
  //     })
  //   );
  // };

  useEffect(() => {
    const enabledViewTabData = tabData?.filter(
      (section: any) => !section?.disableView
    );
    setPrescriptionDetails(enabledViewTabData);
    setDateAndTime(moment(new Date()).format("DD/MM/YYYY, hh:mm A"));

    console.log("tabdata",signedURL)
  }, [tabData]);

  const getMoleculeName = (name: string) => {
    const cleanName = name.replace(/[^\w\s]/g, "");
    const words = cleanName.split(/\s+/);
    return words;
  };

  useEffect(() => {
    const medictionData = tabData?.filter(
      (section: any) => section?.view_type === MEDICATION
    );
    const medictionDataValue = medictionData[0]?.rows[0]?.value;
    medictionDataValue?.map((item: any) => {
      const moleculeName = getMoleculeName(item?.name.split("(")[1]);
      setMoleculeName((prev: any) => [...prev, moleculeName]);
    });
  }, [tabData]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "nearest",
      });
    }
  }, []);

  const HandleDialog = () => {
    setDateAndTime(moment(new Date()).format("DD/MM/YYYY, hh:mm A"));
    setOpenDialog(!openDialog);
  };

  const HandleDialogSave = () => {
    return true;
  };
  const DialogFooterStyles = () => {
    return {
      display: "none",
    };
  };

  const DialogLayoutStyle = () => {
    return {
      width: isMobileScreen ? "350px" : "600px",
      innerHeight: isMobileScreen ? "400px" : "none",
      margin: "0 auto",
      "& .MuiBackdrop-root": {
        pointerEvents: "none",
      },
    };
  };

  const handlePrintPrescription = async () => {
    const response = await dispatch(fetchPrescriptionDetails({
         params:{
          type : "prescription_by_apptId",
          id: id
         }
        
    }))
    if(response?.payload?.data?.data){
      if(isMobileScreen){
        window.open(response?.payload?.data?.data);
      }
      else{
        setPdfUrl(response?.payload?.data?.data);
        setOpenDialog(false);
        setModalOpen(true);
      }
    }
  }

  const handlePrintRx = async (rootElementId: string, fileName: string) => {
    setLoading(true);
    const input = document.getElementById(rootElementId);
    html2PDF(input as HTMLElement, {
      jsPDF: {
        format: "a4",
      },
      imageType: "image/jpeg",
      output: "./pdf/generate.pdf",
      margin: { right: 0, top: 0, bottom: 0, left: 0 },
      success: async (pdf: any) => {
        const base64 = await convertPdfToBase64(pdf);
        const data: any = await sendPrescription(signedURL, base64);
        if (data?.status === 200) {
          const saveRxResponse = await saveRX();
          if (saveRxResponse?.data?.statusCode === 200) {
            // pdf.save(`${fileName}.pdf`);
            setPrescriptionCompleted(true);
            if(printStatus){
              handlePrintPrescription();
            }
            else{
            navigate(ROUTE_APPOINTMENTS);
            }
          } else {
            setLoading(false);
            // pdf.save(`${fileName}.pdf`);
          }
        }
    }
  })
}

  const convertPdfToBase64 = async (pdf: any) => {
    // try {
      const pdfBytes = await pdf.output("arraybuffer");
      const base64 = btoa(
        new Uint8Array(pdfBytes).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      return base64;
    // } catch (error) {
    //   console.error("Error converting PDF to base64:", error);
    //   throw error;
    // }
  };

  const saveRX = async () => {
    const data = {
      id: signedURL?.id,
      file_type: "prescription",
      file_source: "form",
    };
    const saveresult = await APIClient.post(
      API_ROUTE_SAVE_PRESCRIPTION(),
      data
    );
    if (saveresult?.data?.statusCode === 200) {
      const event = {
        appointment_status: "Done",
      };
      await dispatch(updateApponitment({ id: id, body: event }));
      return saveresult;
      setOpenDialog(!openDialog);
    } else {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  }
  return (
    <Box>
      <Box
        ref={ref}
        id="prescription-1"
        sx={{ background: "#fff", border: "1px solid #D9D9D9" }}
      >
        <Box
          className="prescription-header"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "128px",
            background:
              "linear-gradient(63.58deg, #E3F1FD -26.63%, #FFFFFF 100%)",
            padding: "10px 20px 10px 20px",
          }}
        >
          <Box className="clinic-details" width="100%">
            <Box>
              <Typography
                fontSize={isMobileScreen ? "12px" : "20px"}
                fontWeight="700"
                lineHeight="30px"
                color="#0A0666"
              >
                {accountInfo?.clinic_name ? accountInfo?.clinic_name : ""}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="6px">
              <PhoneIcon fill={"#0A0666"} width="16" height="16" />
              <Typography style={prescriptionStyle.header}>
                {accountInfo?.emergency_info ? accountInfo?.emergency_info : ""}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="6px" minWidth="200px">
              <MailIcon fill={"#0A0666"} width="16" height="16" />
              <Typography style={prescriptionStyle.header}>
                {accountInfo?.email ? accountInfo?.email : ""}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="6px">
              <WebsiteIcon />
              <Typography style={prescriptionStyle.header}>
                {accountInfo?.clinic_website ? accountInfo?.clinic_website : ""}
              </Typography>
            </Box>
          </Box>
          {/* <Box className="clinic-logo" textAlign="center">
            <Box
              component="img"
              src={accountInfo?.clinic_logo}
              sx={{ width:isMobileScreen ? "20px" : "100px" }}
            />
          </Box> */}
          <Box className="doctor-details" width="100%" textAlign="end">
            <Typography
              fontSize={isMobileScreen ? "12px" : "20px"}
              fontWeight="700"
              lineHeight="30px"
              color="#0A0666"
            >
              {profile?.name ? `DR.${profile?.name}` : ""}
            </Typography>
            <Typography style={prescriptionStyle.header} textAlign="right">
              {profile?.qualification ? profile?.qualification : ""}
            </Typography>
            <Typography style={prescriptionStyle.header} textAlign="right">
              {profile?.medicalreg_no
                ? `Registration No:${profile?.medicalreg_no}`
                : ""}
            </Typography>
            <Typography style={prescriptionStyle.header} textAlign="right">
              {profile?.phone ? `+91 ${profile?.phone}` : ""}
            </Typography>
          </Box>
        </Box>
        <Box
          className="patient-details"
          display="flex"
          gap={isMobileScreen ? "2px" : "38px"}
          borderBottom="1px solid #0A0666"
          margin={isMobileScreen ? "0px 0px 23px 0px" : "0px 32px 24px 32px"}
          padding="16px 0px"
          justifyContent={isMobileScreen ? "space-between" : undefined}
        >
          <Typography style={prescriptionStyle.patientDetailsHeader}>
            Name : {patient?.name}
          </Typography>
          <Typography style={prescriptionStyle.patientDetailsHeader}>
            Gender :{" "}
            {patient?.gender.charAt(0).toUpperCase() + patient?.gender.slice(1)}
          </Typography>
          <Typography style={prescriptionStyle.patientDetailsHeader}>
            Age : {patient?.age?.no_of_years}
          </Typography>
          <Typography style={prescriptionStyle.patientDetailsHeader}>
            Mobile : {patient?.country_code}-{patient?.phone}
          </Typography>
          <Typography style={prescriptionStyle.patientDetailsHeader}>
            UID : {patient?.patient_hid}
          </Typography>
        </Box>

        {(!prescriptionCompleted && appointmentCancelledorDone) ? (<Box width={"100%"} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography>No prescription available for this appointment</Typography>
          </Box>) : (
            <Box
              className="patient-finding"
              margin={isMobileScreen ? "10px" : "32px"}
            >
              {prescriptionDetails &&
                prescriptionDetails
                  .filter((data: any) => data?.view_type === COMPLAINTS)
                  .map((data: any) => {
                    const complaintData = data?.rows[0].value
                      .map((item: any) => {
                        if (item.name) {
                          return `${item.name}  ${item.since !==null ? `(${item.since})` : ''}`;
                        }
                        return null;
                      })
                      .filter(Boolean)
                      .join(" | ");
                    return (
                      complaintData && (
                        <Box sx={prescriptionStyle.prescriptionContentStyle}>
                          <Box
                            component="img"
                            src="/images/icon_chief_complaints.svg"
                            sx={prescriptionStyle.prescriptionIconStyle}
                          />
                          <Box>
                            <Typography
                              style={prescriptionStyle.patientFindingsHeader}
                            >
                              {data?.header}
                            </Typography>
                            <Typography
                              style={prescriptionStyle.patientFindingsValue}
                            >
                              {complaintData}
                            </Typography>
                          </Box>
                        </Box>
                      )
                    );
                  })}
              {prescriptionDetails &&
                prescriptionDetails
                  .filter((data: any) => data?.view_type === PRESENTING_COMPLAINTS)
                  .map((data: any) => {
                    const complaintData = data?.rows[0].value
                      .map((item: any) => {
                        if (item.name) {
                          return `${item.name}  ${item.values?.length ? `(${item.values?.join(', ')})` : ''} ${item?.comment ? `(Comment: ${item.comment})` : ''}`;
                        }
                        return null;
                      })
                      .filter(Boolean)
                      .join(" | ");
                    return (
                      complaintData && (
                        <Box sx={prescriptionStyle.prescriptionContentStyle}>
                          <Box
                            component="img"
                            src="/images/icon_chief_complaints.svg"
                            sx={prescriptionStyle.prescriptionIconStyle}
                          />
                          <Box>
                            <Typography
                              style={prescriptionStyle.patientFindingsHeader}
                            >
                              {data?.header}
                            </Typography>
                            <Typography
                              style={prescriptionStyle.patientFindingsValue}
                            >
                              {complaintData}
                            </Typography>
                          </Box>
                        </Box>
                      )
                    );
                  })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === PATIENT_HISTORY)
                .map((data: any) => {
                  const patientFilterData = data?.rows.filter(
                    (data: any) => data?.view_type === MEDICAL_HISTORY
                  );
                  const patientHistoryData = patientFilterData[0]?.value
                    .map((item: any) => {
                      if (item?.name) {
                        return `${item?.name}  ${
                          item?.status && `Status(${item?.status})`
                        }`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join("  |  ");
    
                  const mealPref = data?.rows.filter(
                    (data: any) => data?.view_type === MEAL_PREFERENCE
                  )?.map((data:any)=>{
                    return data?.value[0]?.state || ''
                  })
                  .filter(Boolean)
                  ?.join(',');
    
                  const physicalActivity = data?.rows.filter(
                    (data: any) => data?.view_type === PHYSICAL_ACTIVITY
                  )?.map((data:any)=>{
                    return data?.value[0]?.state || ''
                  })
                  .filter(Boolean)
                  ?.join(',');
    
                  const medicalHistoryDiabetes = data?.rows.filter(
                    (data: any) => data?.view_type === MEDICAL_HISTORY_DIABETES
                  )?.map((data:any)=>{
                    return data?.value?.map((elem:any)=>{
                      return elem?.name || ''
                    })?.join(',');
                  })
                  .filter(Boolean)
                  ?.join(',');
    
                  return (
                    (patientHistoryData) && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_patient_history.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {"Patient Medical History"}:
                          </Typography>
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {patientHistoryData}
                          </Typography>
                          {/* <Box display={"flex"} gap={2}>
                            <Typography
                              style={prescriptionStyle.patientFindingsValue}
                            >
                              {`Meal Preference: ${mealPref}`}
                            </Typography>
                            <Typography
                              style={prescriptionStyle.patientFindingsValue}
                            >
                              {`Physical Activity: ${physicalActivity}`}
                            </Typography>
                            <Typography
                              style={prescriptionStyle.patientFindingsValue}
                            >
                              {`Medical History - Diabetes : ${medicalHistoryDiabetes}`}
                            </Typography>
                          </Box> */}
                        </Box>
                        
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === OBG_HISTORY)
                .map((data: any) => {
                  const MenstrualHistoryData = data?.rows.filter(
                    (data: any) => data?.view_type === MENSTRUAL_HISTORY
                  );
                  let MenstrualHistoryComment = '';
                  
        
                  const MenstrualHistoryList = MenstrualHistoryData[0]?.value
                    ?.map((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name === 'comment'){
                          MenstrualHistoryComment = `${item?.name}: ${item?.value?.replace(removeBreaks, "")}`;
                          return null;
                        }
                        return `${item?.name}: ${item?.value}`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join("  |  ");
    
                  const MaritalHistoryData = data?.rows.filter(
                    (data: any) => data?.view_type === MARITAL_HISTORY
                  );
                  let MaritalHistoryComment = '';
        
                  const MaritalHistoryList = MaritalHistoryData[0]?.value
                    ?.map((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name === 'comment'){
                          MaritalHistoryComment = `${item?.name}: ${item?.value?.replace(removeBreaks, "")}`;
                          return null;
                        }
                        if(item?.name == 'married_status'){
                          return `${item?.value}`;
                        }
                        return `${item?.name}: ${item?.value}`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join("  |  ");
    
    
                  const PregnancyHistoryData = data?.rows.filter(
                    (data: any) => data?.view_type === PREGNANCY_HISTORY
                  );
                  const ObstetricFormula: string[] = [];
                  const Abortion: string[] = [];
                  const Ectopic: string[] = [];
                  let CoitalHistory = '';
                  let OtherDetails = ''; 
        
                  PregnancyHistoryData[0]?.value
                    ?.forEach((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name == 'GPLA' || item?.name == 'LCB'){
                          ObstetricFormula.push(`${item?.name}: ${item?.value}`)
                        }
                        else if(item?.name == 'Abortion type'){
                          item?.value?.forEach((elem:any)=>{
                            Abortion.push(
                              `When: ${elem?.when} | Abortion type: ${elem?.abortionType} | Comment: ${elem?.comments}`
                            )
                          })
                        }
                        else if(item?.name == 'Ectopic'){
                          item?.value?.forEach((elem:any)=>{
                            Ectopic.push(
                              `When: ${elem?.when} | Comment: ${elem?.comments}`
                            )
                          })
                        }
                        else if(item?.name == 'coital_history_comment'){
                          CoitalHistory = item?.value?.replace(removeBreaks, "")
                        }
                        else if(item?.name == 'other_details_comment'){
                          OtherDetails = item?.value?.replace(removeBreaks, "")
                        }
                      }
                    })
                  
    
                  return (
                    (!!MenstrualHistoryList || !!MenstrualHistoryComment ||
                      !!MaritalHistoryList || !!MaritalHistoryComment ||
                      !!ObstetricFormula?.length || !!Abortion?.length || !!Ectopic?.length || !!CoitalHistory || !!OtherDetails
                      ) && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_obg_history.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {"OBG History"}:
                          </Typography>
                          {(MenstrualHistoryList || MenstrualHistoryComment) && (
                            <>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'4px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Menstrual history'}
                              </Typography>
                                <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Typography
                                  style={prescriptionStyle.patientFindingsValue}
                                >
                                  {MenstrualHistoryList ? MenstrualHistoryList : ''}
                                </Typography>
                                <Typography
                                  style={prescriptionStyle.patientFindingsValue}
                                >
                                  {MenstrualHistoryComment ? MenstrualHistoryComment : ''}
                                </Typography>
                                
                              </Box>
                              <Divider sx={{my:2}}/>
                            </>
                          )}
                          
                          {(MaritalHistoryList || MaritalHistoryComment) && (
                            <>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'4px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Marital history'}
                              </Typography>
                              <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Typography
                                  style={prescriptionStyle.patientFindingsValue}
                                >
                                  {MaritalHistoryList ? MaritalHistoryList : ''}
                                </Typography>
                                <Typography
                                  style={prescriptionStyle.patientFindingsValue}
                                >
                                  {MaritalHistoryComment ? MaritalHistoryComment : ''}
                                </Typography>
                                
                              </Box>
                              <Divider sx={{my:2}}/>
                            </>
                          )}
    
                          {(ObstetricFormula.length || Abortion.length || Ectopic.length || CoitalHistory || OtherDetails) && (
                            <>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'8px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Pregnancy History'}
                              </Typography>
                              <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Box display={'flex'} gap={1}>
                                  <Typography
                                    style={{
                                      ...prescriptionStyle.patientFindingsValue,
                                      fontWeight:'bold',
                                    }}
                                  >{ObstetricFormula?.length ? `Obstetric Formula - ` : ''}</Typography>
                                    
                                  <Typography>{ObstetricFormula?.length ? `${ObstetricFormula.join("  |  ")}` : ''}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1} alignItems={'start'}>
                                  <Typography
                                    style={{
                                      ...prescriptionStyle.patientFindingsValue,
                                      fontWeight:'bold'
                                    }}
                                  >
                                    {'Abortion - '}
                                  </Typography>
                                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                                    {Abortion?.map((elem:any)=>{
                                      return (
                                        <Typography
                                          style={prescriptionStyle.patientFindingsValue}
                                        >
                                          {`${elem}`}
                                        </Typography>
                                      )
                                    })}
                                  </Box>
                                </Box>
                                <Box display={'flex'} gap={1} alignItems={'start'}>
                                  <Typography
                                    style={{
                                      ...prescriptionStyle.patientFindingsValue,
                                      fontWeight:'bold'
                                    }}
                                  >
                                    {'Ectopic - '}
                                  </Typography>
                                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                                    {Ectopic?.map((elem:any)=>{
                                      return (
                                        <Typography
                                          style={prescriptionStyle.patientFindingsValue}
                                        >
                                          {`${elem}`}
                                        </Typography>
                                      )
                                    })}
                                  </Box>
                                </Box>
    
                                <Box display={'flex'} gap={1}>
                                  <Typography
                                    style={{
                                      ...prescriptionStyle.patientFindingsValue,
                                      fontWeight:'bold',
                                    }}
                                  >{CoitalHistory ? `Coital History - ` : ''}</Typography>
                                    
                                  <Typography>{CoitalHistory ? `${CoitalHistory}` : ''}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1}>
                                  <Typography
                                    style={{
                                      ...prescriptionStyle.patientFindingsValue,
                                      fontWeight:'bold',
                                    }}
                                  >{OtherDetails ? `Other details - ` : ''}</Typography>
                                    
                                  <Typography>{OtherDetails ? `${OtherDetails}` : ''}</Typography>
                                </Box>
                                
                              </Box>
                              <Divider sx={{my:2}}/>
                            </>
                          )}
                        </Box>
                        
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === OBG_NOTES)
                .map((data: any) => {
                  const PhysicalExamData = data?.rows.filter(
                    (data: any) => data?.view_type === PHYSICAL_EXAMINATION
                  );
                  let physicalExamComment = '';
                  
        
                  const PhysicalExamList = PhysicalExamData[0]?.value
                    ?.map((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name === 'comment'){
                          physicalExamComment = `${item?.value?.replace(removeBreaks, "")}`;
                          return null;
                        }
                        return `${item?.name}: ${item?.value}`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join("  |  ");
    
                  const TrimesterNotesData = data?.rows.filter(
                    (data: any) => data?.view_type === TRIMESTER_NOTES
                  );
                  const TrimesterValues: string[] = [];
                  const Vaccination: string[] = [];
                  const Investigation: string[] = [];
                  let TrimesterNotesComment = '';
        
                  const TrimesterNotesList = TrimesterNotesData[0]?.value
                    ?.map((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name === 'comment'){
                          TrimesterNotesComment = `${item?.value?.replace(removeBreaks, "")}`;
                          return null;
                        }
                        else if(item?.name === 'Trimester number' || item?.name === 'FHR' || item?.name === 'Blood group'){
                          TrimesterValues.push(`${item?.name}: ${item?.value}`)
                        }
                        else if(item?.name === 'Vaccination' || item?.name === 'vaccination_comment'){
                          Vaccination.push(`${item?.name === 'vaccination_comment' ? 'Comments' : item?.name}: ${item?.value}`)
                        }
                        else if(item?.name === 'Investigation' || item?.name === 'investigation_comment'){
                          Investigation.push(`${item?.name === 'investigation_comment' ? 'Comments' : item?.name}: ${item?.value}`)
                        }
                        return `${item?.name}: ${item?.value}`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join("  |  ");
    
    
                  const PerAbdomenData = data?.rows.filter(
                    (data: any) => data?.view_type === PER_ABDOMEN
                  );
                  
                  let PerAbdomencomment = '';
        
                  PerAbdomenData[0]?.value
                    ?.forEach((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name == 'comment'){
                          PerAbdomencomment = item?.value?.replace(removeBreaks, "")
                        }
                      }
                    })
    
    
                  const BreastData = data?.rows.filter(
                    (data: any) => data?.view_type === BREAST
                  );
                  
                  const  BreastList: string[] = [];
                  let BreastComment = '';
        
                  BreastData[0]?.value
                    ?.forEach((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name == 'comment'){
                          BreastComment = `Comment: ${item?.value?.replace(removeBreaks, "")}`
                        }
                        else if(item?.name == 'Left side' || item?.name == 'Right side'){
                          BreastList.push(`${item?.name}: ${item?.value?.join(', ')}`)
                        }
                      }
                    })
    
                  const PerVaginumData = data?.rows.filter(
                    (data: any) => data?.view_type === PER_VAGINUM
                  );
                  
                  const  PerVaginumList: string[] = [];
                  let PerVaginumComment = '';
        
                  PerVaginumData[0]?.value
                    ?.forEach((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name == 'comment'){
                          PerVaginumComment = `Comment: ${item?.value?.replace(removeBreaks, "")}`
                        }
                        else if(item?.name == 'Uterus' || item?.name == 'Fornices'){
                          PerVaginumList.push(`${item?.name}: ${item?.value}`)
                        }
                      }
                    })
    
                  const PerSpeculumData = data?.rows.filter(
                    (data: any) => data?.view_type === PER_SPECULUM
                  );
                  
                  let PerSpeculumList: string[] = [];
                  let PerSpeculumComment = '';
        
                  PerSpeculumData[0]?.value
                    ?.forEach((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name == 'comment'){
                          PerSpeculumComment = `Comment: ${item?.value?.replace(removeBreaks, "")}`
                        }
                        else if(item?.name == 'speculum'){
                          PerSpeculumList = item?.value
                        }
                      }
                    })
    
                  const OtherFindingsData = data?.rows.filter(
                    (data: any) => data?.view_type === OTHER_FINDINGS
                  );
                  
                  let OtherFindingsComment = '';
        
                  OtherFindingsData[0]?.value
                    ?.forEach((item: any) => {
                      if (item?.name && item?.value) {
                        if(item?.name == 'comment'){
                          OtherFindingsComment = `${item?.value?.replace(removeBreaks, "")}`
                        }
                      }
                    })
                  
                
                  return (
                    (!!physicalExamComment ||
                      !!TrimesterValues?.length || !!Vaccination?.length || !!Investigation?.length || !!TrimesterNotesComment ||
                      !!PerAbdomencomment ||
                      !!BreastList?.length || !!BreastComment ||
                      !!PerVaginumList?.length || !!PerVaginumComment ||
                      !!PerSpeculumList?.length || !!PerSpeculumComment || 
                      !!OtherFindingsComment
                      ) && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_obg_notes.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {"OBG Notes"}:
                          </Typography>
                          {(physicalExamComment) && (
                            <>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'4px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Physical Examination'}
                              </Typography>
                                <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Typography
                                  style={prescriptionStyle.patientFindingsValue}
                                >
                                  {physicalExamComment ? physicalExamComment : ''}
                                </Typography>
                                
                              </Box>
                              <Divider sx={{my:2}}/>
                            </>
                          )}
                          
                          {(TrimesterValues?.length || Vaccination?.length || Investigation?.length || TrimesterNotesComment) && (
                            <>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'4px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Trimester notes'}
                              </Typography>
                              <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Typography
                                  style={prescriptionStyle.patientFindingsValue}
                                >
                                  {TrimesterValues?.length ? TrimesterValues?.filter(Boolean)?.join("  |  ") : ''}
                                </Typography>
                                <Typography
                                  style={prescriptionStyle.patientFindingsValue}
                                >
                                  {Vaccination?.length ? Vaccination?.filter(Boolean)?.join("  |  ") : ''}
                                </Typography>
                                <Typography
                                  style={prescriptionStyle.patientFindingsValue}
                                >
                                  {Investigation?.length ? Investigation?.filter(Boolean)?.join("  |  ") : ''}
                                </Typography>
                                <Typography
                                  style={prescriptionStyle.patientFindingsValue}
                                >
                                  {TrimesterNotesComment ? TrimesterNotesComment : ''}
                                </Typography>
                                
                              </Box>
                              <Divider sx={{my:2}}/>
                            </>
                          )}
    
                          {(PerAbdomencomment) && (
                            <>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'8px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Per Abdomen'}
                              </Typography>
                              <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Box display={'flex'} gap={1}>
                                  <Typography>{PerAbdomencomment ? `${PerAbdomencomment}` : ''}</Typography>
                                </Box>
                              </Box>
                              <Divider sx={{my:2}}/>
                            </>
                          )}
    
                          {(BreastList?.length || BreastComment) && (
                            <>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'8px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Breast'}
                              </Typography>
                              <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Box display={'flex'} gap={1}>
                                  <Typography>{BreastList?.length ? `${BreastList?.filter(Boolean)?.join("  |  ")}` : ''}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1}>
                                  <Typography>{BreastComment ? `${BreastComment}` : ''}</Typography>
                                </Box>
                              </Box>
                              <Divider sx={{my:2}}/>
                            </>
                          )}
    
                          {(PerVaginumList?.length || PerVaginumComment) && (
                            <>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'8px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Per Vaginum'}
                              </Typography>
                              <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Box display={'flex'} gap={1}>
                                  <Typography>{PerVaginumList?.length ? `${PerVaginumList?.filter(Boolean)?.join("  |  ")}` : ''}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1}>
                                  <Typography>{PerVaginumComment ? `${PerVaginumComment}` : ''}</Typography>
                                </Box>
                              </Box>
                              <Divider sx={{my:2}}/>
                            </>
                          )}
    
                          {(PerSpeculumList?.length || PerSpeculumComment) && (
                            <>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'8px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Per Speculum'}
                              </Typography>
                              <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Box display={'flex'} gap={1}>
                                  <Typography>{PerSpeculumList?.length ? PerSpeculumList?.join(', ') : ''}</Typography>
                                </Box>
                                <Box display={'flex'} gap={1}>
                                  <Typography>{PerSpeculumComment ? `${PerSpeculumComment}` : ''}</Typography>
                                </Box>
                              </Box>
                              <Divider sx={{my:2}}/>
                            </>
                          )}
    
                          {(OtherFindingsComment) && (
                            <Box my={2}>
                              <Typography
                                style={{
                                  ...prescriptionStyle.patientFindingsValue,
                                  marginTop:'4px',
                                  marginBottom:'8px',
                                  fontWeight:'bold'
                                }}
                              >
                                {'Other findings'}
                              </Typography>
                              <Box display={"flex"} flexDirection={'column'} gap={1}>
                                <Box display={'flex'} gap={1}>
                                  <Typography>{OtherFindingsComment ? `${OtherFindingsComment}` : ''}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === KNEE_EXAMINATION)
                .map((data: any) => {
    
                  const leftSide: string[] = [];
                  const rightSide: string[] = [];
    
                  data?.rows[0]?.value
                    ?.forEach((item: any) => {
                      if (item.name) {
                        leftSide.push(`${item?.name} : ${item?.leftSide && `${item?.leftSide}`}`);
                        rightSide.push(`${item?.name} : ${item?.rightSide && `${item?.rightSide}`}`);
                      }
                      return null;
                    })
                    
                  return (
                    (leftSide?.length || rightSide?.length) && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_knee_examination.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {`${'ROM Left Side - '} ${leftSide?.filter(Boolean)?.join(" | ")}`}
                          </Typography>
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {`${'ROM Right Side - '} ${rightSide?.filter(Boolean)?.join(" | ")}`}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === PHYSICAL_FINDING)
                .map((data: any) => {
                  const diagnosticData = data?.rows[0]?.value
                    ?.map((item: any) => {
                      if (item.name) {
                        return `${item?.name} : ${
                          item?.comment && `${item?.comment}`
                        }`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join(" | ");
                  return (
                    diagnosticData && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_physical_findings.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {diagnosticData}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === DIABETES_ASSESSMENT)
                .map((data: any) => {
                  const diagnosticData = data?.rows[0]?.value
                    ?.map((item: any) => {
                      if (item.name) {
                        return `${item?.name} : ${item?.state && `${item?.state}`}  ${
                          item?.comment && `(${item?.comment})`
                        }`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join(" | ");
                  return (
                    diagnosticData && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_diabetes_assessment.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {diagnosticData}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === GENERAL_PHYSICAL_EXAMINATION)
                .map((data: any) => {
                  const tempDiv = document.createElement("div");
                  tempDiv.classList.add(
                    isMobileScreen ? "free-text-mobile" : "free-text"
                  );
    
                  const diagnosticData = data?.rows?.map((item: any) => {
                      if (item.key && item.value && item.key!=='comment') {
                        return `${item?.label}: ${item?.value && `${item?.value}`}`;
                      }
                      if(item.key && item.value && item.key==='comment') {
    
                        const removeBreaks = /<p><br><\/p>/g;
    
                        tempDiv.innerHTML = item?.value?.replace(removeBreaks, "");
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join(" | ");
                  return (
                    diagnosticData && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_general_physical_examination.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {diagnosticData}
                          </Typography>
                          <Typography
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(tempDiv),
                              }}
                          ></Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === INVESTIGATION_MONITORING)
                .map((data: any) => {
                  const diagnosticData = data?.rows[0].value
                    .map((item: any) => {
                      if (item.name) {
                        return `${item?.name} :  ${
                          item?.state && `${item?.state}`
                        }`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join(" | ");
                  return (
                    diagnosticData && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_investigation_monitoring.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {diagnosticData}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === FOOT_EXAM)
                .map((data: any) => {
                  const leftFoot:any = [];
                  const rightFoot:any = [];
                  const nss:any = [];
                  let footscore = '';
    
                  data?.rows?.forEach((item: any) => {
                      if (item.group_title === 'Left Side' && item?.value) {
                        leftFoot.push(`${item?.label} : ${item?.value}`)
                      }else if(item.group_title === 'Right Side' && item?.value){
                        rightFoot.push(`${item?.label} : ${item?.value}`)
                      }else if(item.group_title === 'NSS' && item.key !== 'foot_score' && item?.value){
                        nss.push(`${item?.label} : ${item?.value}`)
                      }else if(item.group_title === 'NSS' && item.key === 'foot_score' && item?.value){
                        footscore = `${'Foot Score'} : ${item?.value}`
                      }
                  })
                  return (
                    (leftFoot?.length || rightFoot?.length || nss?.length || footscore) && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_foot_exam.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box width={'100%'}>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
                          
                          <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography
                              style={prescriptionStyle.patientFindingsValue}
                            >
                              {leftFoot.length ? `Left Side - ${leftFoot.filter(Boolean).join(" | ")}` : ''}
                            </Typography>
    
                            <Typography
                              style={prescriptionStyle.patientFindingsValue}
                            >
                              {rightFoot.length ? `Right Side - ${rightFoot.filter(Boolean).join(" | ")}` : ''}
                            </Typography>
                          </Box>
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {nss.length ? `NSS - ${nss.filter(Boolean).join(" | ")}` : ''}
                          </Typography>
                          
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {footscore ? `${footscore}` : ''}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === SYSTEMIC_EXAMINATION)
                .map((data: any) => {
                  const leftSide:any = [];
                  const rightSide:any = [];
                  const other:any = [];
    
                  data?.rows?.forEach((item: any) => {
                      if (item.group_title === 'Left Side' && item?.value) {
                        leftSide.push(`${item?.label} : ${item?.value}`)
                      }else if(item.group_title === 'Right Side' && item?.value){
                        rightSide.push(`${item?.label} : ${item?.value}`)
                      }else if(item?.value){
                        other.push(`${item?.label} : ${item?.value}`)
                      }
                  })
                  return (
                    (leftSide?.length || rightSide?.length || other?.length) ? (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_systemic_examination.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box width={'100%'}>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
                          
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {leftSide.length ? `Left Side - ${leftSide.filter(Boolean).join(" | ")}` : ''}
                          </Typography>
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {rightSide.length ? `Right Side - ${rightSide.filter(Boolean).join(" | ")}` : ''}
                          </Typography>
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {other.length ? `${other.filter(Boolean).join(" | ")}` : ''}
                          </Typography>
                          
                        </Box>
                      </Box>
                    ) : null
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === DIAGNOSIS)
                .map((data: any) => {
                  const diagnosticData = data?.rows[0].value
                    .map((item: any) => {
                      if (item.name) {
                        return `${item?.name}  ${
                          item?.state && `(${item?.state})`
                        }`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join(" | ");
                  return (
                    diagnosticData && (
                      <Box
                        className="diagnostics"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_diagnosis.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
    
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {diagnosticData}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === EXAMINATION)
                .map((data: any, index: number) => {
                  const examinationData: string = data?.rows[0]?.value[0]?.comment;
                  const tempDiv = document.createElement("div");
                  tempDiv.classList.add(
                    isMobileScreen ? "free-text-mobile" : "free-text"
                  );
                  const removeBreaks = /<p><br><\/p>/g;
                  tempDiv.innerHTML = examinationData.replace(removeBreaks, "");
    
                  return (
                    tempDiv.innerHTML?.length > 0 && (
                      <Box
                        className="examination-findings"
                        marginTop="15px"
                        key={index}
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_ex_findings.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(tempDiv),
                            }}
                          ></Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
    
              {prescriptionDetails
                .filter((data: any) => data?.view_type === MEDICATION)
                .map((data: any) => {
                  return (
                    data?.rows[0]?.value?.length > 0 && (
                      <Box className="patient-medication" marginTop="15px">
                        <Typography style={prescriptionStyle.patientFindingsHeader}>
                          Prescription:
                        </Typography>
                        <Box className="medicine-details-table">
                          <TableContainer sx={{ overflow: "hidden" }}>
                            <Table sx={{ border: "1px solid #D9D9D9" }}>
                              <TableHead sx={{ background: "#F0F7FE" }}>
                                <TableRow>
                                  {prescriptionTableHeader.map((header: any) => {
                                    return (
                                      <TableCell
                                        sx={{
                                          fontSize: isMobileScreen ? "8px" : "14px",
                                          padding: isMobileScreen ? "2px" : "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {header.headerName}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data.rows[0].value.map((item: any) => {
                                  return (
                                    <TableRow
                                      sx={{ borderBottom: "1px solid #D9D9D9" }}
                                    >
                                      <TableCell
                                        sx={{
                                          padding: isMobileScreen ? "0px" : "12px",
                                        }}
                                      >
                                        <Box sx={TableStyle.medicineCell}>
                                          <Box
                                            width={isMobileScreen ? "20px" : "32px"}
                                          >
                                            {item?.name && (
                                              <TabletIcon
                                                width={isMobileScreen ? "20" : "32"}
                                              />
                                            )}
                                          </Box>
                                          <Box
                                            sx={{
                                              fontSize: isMobileScreen
                                                ? "8px"
                                                : "14px",
                                            }}
                                          >
                                            {item?.name}
                                          </Box>
                                        </Box>
                                      </TableCell>
                                      <TableCell sx={TableStyle.cell}>
                                        {item?.dosage}
                                      </TableCell>
                                      <TableCell sx={TableStyle.cell}>
                                        {item?.frequency}
                                      </TableCell>
                                      <TableCell sx={TableStyle.cell}>
                                        {item?.duration}
                                      </TableCell>
                                      <TableCell sx={TableStyle.cell}>
                                        {item?.meal_pref}
                                      </TableCell>
                                      <TableCell sx={TableStyle.cell}>
                                        {item?.start_from &&
                                          dayjs(item?.start_from).format(
                                            "DD/MM/YYYY"
                                          )}
                                      </TableCell>
                                      <TableCell sx={TableStyle.cell}>
                                        {item?.comment}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                ?.filter((data: any) => data?.view_type === INVESTIGATIONS)
                .map((data: any) => {
                  return (
                    data?.rows[0].value?.length > 0 && (
                      <Box className="lab-investigation" marginTop="15px">
                        <Typography style={prescriptionStyle.patientFindingsHeader}>
                          Lab Test:
                        </Typography>
    
                        <Box className="lab-test-details-table">
                          <TableContainer sx={{ overflow: "hidden" }}>
                            <Table sx={{ border: "1px solid #D9D9D9" }}>
                              <TableHead
                                sx={{
                                  background: "#F0F7FE",
                                  borderRadius: "8px",
                                }}
                              >
                                <TableRow>
                                  {LabTestTableHeader.map((data: any) => {
                                    return (
                                      <TableCell
                                        sx={{
                                          fontSize: isMobileScreen ? "8px" : "14px",
                                          padding: isMobileScreen ? "5px" : "16px",
                                          textAlign: "center",
                                          width: "fit-content",
                                        }}
                                      >
                                        {data.headerName}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody sx={{ border: "1px solid #D9D9D9" }}>
                                {data.rows[0].value.map((item: any) => {
                                  return (
                                    <TableRow
                                      sx={{ borderBottom: "1px solid #D9D9D9" }}
                                    >
                                      <TableCell sx={TableStyle.medicalLabCell}>
                                        {item?.name &&
                                        item?.diagnostic_type === "PATHOLOGY" ? (
                                          <Box>
                                          <img
                                            style={{
                                              pointerEvents: "none",
                                              width: isMobileScreen
                                                ? "12px"
                                                : "24.95px",
                                              marginLeft: isMobileScreen
                                                ? "4px"
                                                : undefined,
                                            }}
                                            src="/images/pathology.svg"
                                            alt="pathology"
                                          />
                                          </Box>
                                        ) : item?.diagnostic_type ===
                                          "RADIOLOGY" ? (
                                            <Box>
                                          <img
                                            style={{
                                              pointerEvents: "none",
                                              width: isMobileScreen
                                                ? "12px"
                                                : "24.95px",
                                              marginLeft: isMobileScreen
                                                ? "4px"
                                                : undefined,
                                            }}
                                            src="/images/radiology.svg"
                                            alt="pathology"
                                          />
                                          </Box>
                                        ) : (
                                          <img
                                            style={{
                                              pointerEvents: "none",
                                              width: isMobileScreen
                                                ? "15px"
                                                : "22px",
                                            }}
                                            src="/images/others_diagnostic_type.svg"
                                            alt="others"
                                          />
                                        )}
                                        {item?.name}
                                      </TableCell>
                                      <TableCell sx={TableStyle.cell}>
                                        {item?.test_on}
                                      </TableCell>
                                      <TableCell sx={TableStyle.cell}>
                                        {item?.repeat_on}
                                      </TableCell>
                                      <TableCell
                                        sx={TableStyle.cell}
                                        width="fit-content"
                                      >
                                        {item?.comment}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    )
                  );
                })}
    
              {prescriptionDetails
                .filter((data: any) => data?.view_type === ADVICE)
                .map((data: any) => {
                  const adviceData = data?.rows[0].value
                    .map((item: any) => item?.value)
                    .filter(Boolean)
                    .join(" | ");
                  return (
                    adviceData && (
                      <Box
                        className="advice"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_advice.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
                          {
                            <Typography
                              style={prescriptionStyle.patientFindingsValue}
                            >
                              {adviceData}
                            </Typography>
                          }
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === REFERRAL)
                .map((data: any) => {
                  return (
                    data?.rows[0]?.value?.length > 0 && (
                      <Box
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_referral.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
                          {data?.rows[0]?.value.map((item: any) => {
                            return (
                              <Typography
                                style={prescriptionStyle.patientFindingsValue}
                              >
                                {item?.speciality} | {item?.dr_name}
                              </Typography>
                            );
                          })}
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === ADVICE_NOTES)
                .map((data: any) => {
                  const additionalNoteData = data?.rows[0]?.value[0].value;
                  // .map((item: any) => item?.value)
                  // .filter(Boolean)
                  // .join(" | ");
                  const tempDiv = document.createElement("div");
                  tempDiv.classList.add(
                    isMobileScreen ? "free-text-mobile" : "free-text"
                  );
    
                  const removeBreaks = /<p><br><\/p>/g;
    
                  tempDiv.innerHTML = additionalNoteData.replace(removeBreaks, "");
                  return (
                    tempDiv?.innerHTML?.length > 0 && (
                      <Box
                        className="additional-notes"
                        marginTop="15px"
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_addnl_notes.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
                          {
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(tempDiv),
                              }}
                            ></Typography>
                          }
                        </Box>
                      </Box>
                    )
                  );
                })}
              {prescriptionDetails
                .filter((data: any) => data?.view_type === FOLLOW_UP)
                .map((data: any, index: number) => {
                  const followUpData: string = data?.rows[0]?.value[0]?.comment;
                  const date = data?.rows[0]?.value[0]?.date
                    ? new Date(data.rows[0].value[0].date).toDateString()
                    : "";
                  const tempDiv = document.createElement("div");
                  tempDiv.classList.add(
                    isMobileScreen ? "free-text-mobile" : "free-text"
                  );
                  const removeBreaks = /<p><br><\/p>/g;
                  tempDiv.innerHTML = followUpData && followUpData.replace(removeBreaks, "");
                  return (
                    tempDiv.innerHTML?.length > 0 && (
                      <Box
                        className="follow-up"
                        marginTop="15px"
                        key={index}
                        sx={prescriptionStyle.prescriptionContentStyle}
                      >
                        <Box
                          component="img"
                          src="/images/icon_follow_up.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}:
                          </Typography>
                          {date ? (
                            <Typography
                              style={prescriptionStyle?.patientFindingsValue}
                            >
                              On:
                              <span
                                style={{
                                  fontWeight: 700,
                                  marginLeft: "5px",
                                  fontSize: isMobileScreen ? "12px" : "16px",
                                }}
                              >
                                {moment(date).format("DD/MM/YYYY")}
                              </span>
                            </Typography>
                          ) : (
                            ""
                          )}
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(tempDiv),
                            }}
                          ></Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
            </Box>
          )
        }
        
        {profile?.name && (
          <Box
            height="191px"
            className="doctor-signature"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px 20px 10px 20px",
            }}
          >
            <Box className="doctor-box">
              <Box className="doctor-details" textAlign="right">
                <Typography
                  fontSize={isMobileScreen ? "12px" : "16px"}
                  fontWeight="700"
                  letterSpacing="0.15px"
                >
                  {profile?.name ? `DR.${profile?.name}` : ""}
                </Typography>
                <Typography
                  fontSize={isMobileScreen ? "8px" : "12px"}
                  fontWeight="700"
                  letterSpacing="0.15px"
                >
                  {profile?.qualification ? profile?.qualification : ""}
                </Typography>
                <Typography
                  fontSize={isMobileScreen ? "8px" : "12px"}
                  fontWeight="700"
                  letterSpacing="0.15px"
                >
                  {dateAndTime}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        <Box className="footer-box">
          <Box sx={{ textAlign: "center", paddingBottom:"5px" }}>
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                fontSize:isMobileScreen ? "10px":  "14px",
                fontWeight: "400",
              }}
            >
              *This is an automated generated document and doesn't require
              signature.*
            </Typography>
          </Box>
          <Box
            sx={{
              background: "linear-gradient(64deg, #E3F1FD -26.63%, #FFF 100%)",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap:"8px"
            }}
          >
            <Box component="img" sx={{width:"16px", height:"16px"}} src="/images/icon_location.svg" />
            <Typography
              sx={{
                fontSize:isMobileScreen ? "10px": "13px",
                fontWeight: "400",
                lineHeight: "19.5px",
                letterSpacing: "0.15px",
                color: "#0A0666",
              }}
            >
              {/* {convertAddressToString(accountInfo)} */}
            </Typography>
          </Box>
        </Box>
      </Box>
      {!prescriptionCompleted && (
        <Box
          sx={{
            display: "flex",
            width: isMobileScreen ? "none" : "100%",
            background: "#ffff",
            padding: "15px 25px 15px 15px",
            borderRadius: "8px",
            margin: "12px 0px 12px 0px",
          }}
        >
          <Box>
            {!prescriptionCompleted && !appointmentCancelledorDone ? (
              <Button
                onClick={() => {
                  EndPreview(false);
                  setActiveTab(0);
                }}
                text="Edit"
                variant="outlined"
                styles={{
                  fontWeight: "600",
                  borderWidth: "2px",
                  borderRadius: "8px",
                  "& :hover,": {
                    borderRadius: "8px",
                    borderWidth: "2px !important",
                  },
                }}
              />
            ) : ''}
            
          </Box>
          <Box display="flex" justifyContent="flex-end" gap="20px" width="100%">
            {!isMobileScreen && (
              <Button
                onClick={()=>{
                  setPrintStatus(true);
                  HandleDialog()}}
                text={"End and Print Rx"}
                variant="outlined"
                styles={{ fontWeight: "600" }}
              />
            )}
            <Button
              onClick={HandleDialog}
              text="End and Share Rx"
              variant="contained"
            >
              <img
                style={{ marginLeft: "10px" }}
                src="/images/arrow_right.svg"
                alt="arrow_right"
              />
            </Button>
          </Box>
        </Box>
      )}
      <CustomizedDialogs
        DialogOpen={openDialog}
        onHandleDialog={HandleDialog}
        Layoutstyles={DialogLayoutStyle()}
        footerStyles={DialogFooterStyles()}
        fullWidth={true}
        maxWidth="md"
        onHandleSave={HandleDialogSave}
      >
        <Box
          className="end-consultation"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <EndConsultationIcon />
          <Typography
            fontSize="20px"
            fontWeight="700"
            lineHeight="28px"
            marginTop="10px"
            textAlign="center"
          >
            Are you sure want to end consultation?
          </Typography>
          <Typography
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
            width={isMobileScreen ? "fit-content" : "452px"}
            marginTop="16px"
            textAlign="center"
            color="rgba(0, 0, 0, 0.60)"
          >
            By taking this action, you will no longer be able to make any
            changes to the prescription.
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-end"
            gap="20px"
            marginTop="32px"
          >
            <Button text="Cancel" onClick={HandleDialog} variant="outlined" />
            <LoadingButton
              onClick={() =>
                handlePrintRx("prescription-1", signedURL?.file_name)
              }
              variant="contained"
              loading={loading}
              loadingPosition="center"
              sx={{ textTransform: "none !important" }}
            >
              End Consultation
            </LoadingButton>
          </Box>
        </Box>
      </CustomizedDialogs>
      <PDFModal 
      pdfUrl={pdfUrl}
       modalOpen={modalOpen}
       handleClose={handleClose}
      />
    </Box>
  );
};
export default Prescription;
