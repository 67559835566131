import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APIClient } from "../../shared/Utils/api-client";
import { API_ROUTE_ACCOUNT_DETAILS, API_ROUTE_MY_PATIENT } from "../../shared/routes/route.constants";
// import { API_ROUTE_MY_PATIENT } from "../../shared/routes/route.constants";

const config={
    name:"myPatients"
}

export const fetchAllPatientDetails: any = createAsyncThunk(
  `${config.name}/fetchAllPatientDetails`,
  async (limit: any) => {
    const params = limit?.params
    return await APIClient.get(API_ROUTE_MY_PATIENT(),{ params });
  }
);


export const myPatientSlice = createSlice({
    name:"myPatient",
    initialState:{
        allPatientData : [] as any,
        loader : false as any,
    },
    reducers:{
        setAllPatientData : (state,action)=>{
            state.allPatientData = action.payload
        }
    },
    extraReducers(builder){
        builder.addCase(fetchAllPatientDetails.pending,(state:any)=>{
            state.loader = true
        })
        .addCase(fetchAllPatientDetails.fulfilled,(state:any,action:any)=>{
            state.loader = false
            state.allPatientData = action.payload?.data
        })
        .addCase(fetchAllPatientDetails.rejected,(state:any,action:any)=>{
            state.loader = false
            state.error = action.payload || action.error.message
        })
    }
})

export const {setAllPatientData} = myPatientSlice.actions
export default myPatientSlice.reducer