import React, { useState, useEffect } from 'react';
import { Button, Container, Skeleton, Grid , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Select, MenuItem, FormControl,InputLabel,Typography} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios'; // Assuming you're using axios for API requests
import { APIClient } from "../../../../shared/Utils/api-client";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { NoData } from './RxCommon';
import{ROUTE_ADMIN_NEW_RX_TEMPLATE_FORM,ROUTE_ADMIN_EDIT_RX_TEMPLATE_FORM,ROUTE_ADMIN_EDIT_RX_TEMPLATE_SPECIALITY,ROUTE_ADMIN_EDIT_RX_TEMPLATE_DOCTOR} from "../../../../shared/routes/route.constants";
const useStyles = makeStyles({
  addButton: {
    marginBottom: '20px',
  },
  listItem: {
    marginTop: '20px',
  },
});
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
function RxList() {
  const classes = useStyles();
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [selectedOption, setSelectedOption] = useState('all');
  const [loading, setLoading] = useState(true);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleError = (error) => {
    setErrorOpen(true);
    setErrorMessage(error || 'An error occurred.');
  };
  const getTemplates = async () => {
    const response = await APIClient.get("prescription/get-template", {
      params: {
        type: "all_templates",
        file_type:"template",
        active_account_id : "svaas"
      },
    });
    if (response?.data?.statusCode === 200) {
        setTemplates(response?.data?.data);
        setFilteredTemplates(response?.data?.data);
        setLoading(false);
      console.log(response?.data?.data);
    }
  }

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === 'all') {
      setFilteredTemplates(templates);
    }
    else{
      //console.log(movies.filter(x => x.genres.some(g => filters.includes(g))))

      setFilteredTemplates(templates.filter((template) => template.speciality_map.some(specialities => specialities.speciality_id === event.target.value)));
    }
  };

  const getSpecialities = async () => {
    const response = await APIClient.get("prescription/suggestions", {
      params: {
        type: "speciality",
        active_account_id : "svaas"
      },
    });
    if (response?.data?.statusCode === 200) {
        
        const newObj = (response.data.data).reduce((acc, curr) => {
          acc[curr.id] = curr.name;
          return acc;
      }, {});
      console.log("specialities",newObj);
        setSpecialities(newObj);
      console.log(response?.data?.data);
    }
  }
  useEffect(() => {

    // Fetch data from API
    getTemplates();
    getSpecialities();
   
  }, []);


  const handleDelete = async (id) => {
    setLoading(true);
    
    try{
      const response = await APIClient.delete("prescription/delete-template/"+id+"?active_account_id=svaas", {
     
      });
      console.log("response---",response);
      if (response?.data?.statusCode === 200) {
        getTemplates();
      
      
      }
      // Logic for deleting template with id
      console.log('Delete template with ID:', id);
    } 
    catch(error){
      getTemplates();
      handleError(error);
      console.log("error---",error);
      console.log('Delete template with ID:', id);
    }
  };



  return (
    <Container>
      <Grid container spacing={3} alignContent={"center"} height={"90px"}>
        <Grid item xs={10}>
          <div>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="speciality_filter">Filter By Speciality</InputLabel>
              <Select
                
                id="speciality_filter"
                value={selectedOption}
                onChange={handleChange}
                label="Filter By Speciality"
              >
                <MenuItem key={"blank_key"} value={"all"}>
                    All Templates
                </MenuItem>
                {Object.keys(specialities).map((key) => (
                  <MenuItem key={key} value={key}>
                    {specialities[key]}
                  </MenuItem>
                ))}
                
              </Select>
            </FormControl>
            
          </div>
        </Grid>  
        <Grid item xs={2}>
          <div style={{textAlign:"right"}}>
            <Link to={ROUTE_ADMIN_NEW_RX_TEMPLATE_FORM}>
              <Tooltip title="Add Template">
              <Button  variant="contained" color="primary" className={classes.addButton} > Add Template</Button>
              </Tooltip>
            </Link>  
          
          </div>
        </Grid>  
      </Grid>  
      
        
       
      
      <Grid container spacing={3} style={{height:"calc(100vh - 120px)",overflowY:"auto",marginTop:"0px"}}>
        <Grid item xs={12}>
          {!loading?<>
            
         
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell  style={{textAlign:"right"}}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTemplates&&filteredTemplates.map((template) => (
                    
                    <TableRow key={template.id}>
                      <TableCell>{template.filename}</TableCell>
                      <TableCell>{template.prescription_date}</TableCell>
                      <TableCell style={{textAlign:"right"}}>
                        <Link to={ROUTE_ADMIN_EDIT_RX_TEMPLATE_FORM(template.id)}>
                          <Tooltip title="Edit Template">
                            <IconButton aria-label="Edit Template">
                              <EditIcon/>
                            </IconButton>
                          </Tooltip>
                          
                        </Link>
                        <Tooltip title="Delete Template">
                          <IconButton aria-label="Delete Template"  onClick={() => handleDelete(template.id)}>
                            <DeleteIcon color='error'/>
                          </IconButton>
                        </Tooltip>
                        
                        <Link to={ROUTE_ADMIN_EDIT_RX_TEMPLATE_SPECIALITY(template.id)}>
                          <Tooltip title="Link Speciality">
                            <IconButton aria-label="Link Speciality"  >
                              <AddLinkIcon color='primary'/>
                            </IconButton>
                          </Tooltip>
                        
                        </Link>
                        <Link to={ROUTE_ADMIN_EDIT_RX_TEMPLATE_DOCTOR(template.id)}>
                          <Tooltip title="Link Doctor">
                            <IconButton aria-label="Link Doctor"  >
                              <PersonAddOutlinedIcon color='primary'/>
                            </IconButton>
                          </Tooltip>
                        
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                  
                  
                </TableBody>
              </Table>
            </TableContainer>
            {filteredTemplates.length === 0 && (
                <NoData>
                  No Template Found
                </NoData>
            )}
          </>:<>
            <Skeleton animation='wave'/>
            <Skeleton animation='wave'/>
            <Skeleton animation='wave'/>
            <Skeleton animation='wave'/>
          </>}  
        </Grid>
      </Grid>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000} // Optional: Auto-close after 6 seconds
        onClose={() => setErrorOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Optional: Adjust position
      >
        <MuiAlert onClose={() => setErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
}

export default RxList;
