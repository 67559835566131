import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  createFilterOptions,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { HTMLAttributes, SyntheticEvent, useEffect, useState } from "react";
import CardHeader from "../../utils/CardHeader";
import Card from "../../utils/Card";
import { useDebounce } from "../../../../../shared/Utils/useDebounce";
import {
  fetchSuggestions,
  seperateUrlAndParams,
} from "../../../../../shared/Utils/utils";
import { CARD_SHADOW } from "../../utils/Constants";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { ADVICE, BASE_API_URL } from "../../../../../shared/constants";
import {
  setCopyData,
  setEditRxSecMobile,
} from "../../../../../redux/reducers/prescription.slice";

interface AdviceProps {
  id: string;
  value: string;
}

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "8px",
    paddingLeft: 0,
    border: "none",
  },
});

const hideBorders = {
  "& fieldset": {
    border: "none",
  },
  "&:hover fieldset": {
    border: "solid 1px rgba(0, 0, 0, 0.23)",
  },
  "&.Mui-focused fieldset": {
    border: "solid 1px rgba(0, 0, 0, 0.23)",
  },
};

export default function Advice(props: any) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<AdviceProps[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [valUpdateSearch, setValUpdateSearch] = useState("");
  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);

  const isFocused: boolean = props.isFocused || false;

  const sectionData = props?.sectionData?.[0]?.rows[0];

  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { setTabData } = props;
  const {
    copyData,
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const apiurldata = sectionData?.search?.api;

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(ADVICE));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === ADVICE
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  const updateSearchOptions = (value: any) => {
    if (apiurldata && value) {
      const { url, query_params } = seperateUrlAndParams(apiurldata);
      fetchSuggestions(
        url,
        {
          ...query_params,
          name: value,
        },
        true // use custom base url
      ).then((response) => {
        const responseArr = response?.data?.data?.map((opt: any) => {
          return {
            id: opt.id,
            name: opt.name,
          };
        });
        const optionsArr = responseArr?.map((option: any) => option.name);
        if (!optionsArr.some((opt: any) => opt === value)) {
          setOptions([value, ...optionsArr]);
          setOptionsData([
            {
              id: `custom${Date.now()}`,
              name: value,
            },
            ...responseArr,
          ]);
          return;
        }
        setOptions(optionsArr);
        setOptionsData(responseArr);
      });
    }
  };

  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length !== 0 && searchValue.trim().length >= 3) {
      updateSearchOptions(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (valUpdateSearch.length !== 0) {
      updateSearchOptions(altDebouncedSearch);
    }
  }, [altDebouncedSearch]);

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === ADVICE) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  useEffect(() => {
    if (copyData?.view_type === ADVICE) {
      handleSelectedTemplateData(copyData);
      dispatch(setCopyData([] as any));
    }
  }, [copyData]);

  const addAdvice = (event: SyntheticEvent, value: string | null) => {
    if (!value) {
      return;
    }

    const optionObj: any = optionsData.find(
      (optObj: any) => optObj.name === value
    );

    const newAdvice: AdviceProps = {
      id: optionObj.id,
      value,
    };
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === ADVICE) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: [...row.value, newAdvice],
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
    setSearchValue("");
  };

  const updateAdvice = (event: SyntheticEvent, value: string | null) => {
    if (!value) {
      return;
    }

    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const target =
      (event.currentTarget as HTMLInputElement) ||
      (event.currentTarget as HTMLElement);

    const ariaLabel = target.ariaLabel as string;

    const nameArr = ariaLabel.split("-");

    const key = nameArr[nameArr.length - 1];

    const currId = nameArr.reduce((acc, val) => {
      if (val === key) {
        return acc;
      }
      return acc + "-" + val;
    });
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === ADVICE) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.map((advice: any) => {
                    if (advice?.id?.toString() === currId) {
                      return {
                        ...advice,
                        id: optionObj ? optionObj?.id : currId,
                        value: value,
                      };
                    }
                    return advice;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
  };

  const removeAdvice = (event: SyntheticEvent) => {
    const elem = event.target as HTMLElement;
    const { id } = elem;

    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === ADVICE) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (advice: any) => advice?.id?.toString() !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
  };

  const AdviceCardMobile = (Advice: AdviceProps, index: number) => {
    const { id, value } = Advice;
    return (
      <Card
        styles={{
          boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.08)`,
          padding: 0,
          borderRadius: "8px",
        }}
        key={index + id}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Autocomplete
            disableClearable
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            fullWidth
            id={`${id}-value`}
            aria-label={`${id}-value`}
            options={valUpdateSearch.length === 0 ? [] : options}
            onChange={updateAdvice}
            value={value}
            title={value}
            popupIcon={false}
            clearIcon={false}
            sx={{
              "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                color: "#494E9D",
                fontSize: "16px",
                paddingRight: 0,
              },
              "& .MuiInputBase-root": {
                paddingRight: "0 !important",
              },
            }}
            noOptionsText=""
            renderOption={(props, option, state) => {
              if (!data.some((advice: any) => advice.value === option)) {
                return (
                  <Box
                    component="li"
                    aria-label={`${id}-value`}
                    id={`${id}-value-${option}`}
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            renderInput={(params) => {
              return (
                <TextField
                  name={`${id}-value`}
                  multiline
                  sx={{
                    ...hideBorders,
                  }}
                  onChange={(event: any) => {
                    const val = event.target.value;
                    if (val.length === 0) {
                      setOptions([]);
                    }
                    setValUpdateSearch(val);
                  }}
                  {...params}
                  fullWidth
                  variant="outlined"
                />
              );
            }}
          />
          <IconButton sx={{ maxWidth: "max-content" }}>
            <Box
              id={id}
              aria-label="delete-advice"
              onClick={removeAdvice}
              component="img"
              src="/images/DeleteIcon.svg"
            ></Box>
          </IconButton>
        </Box>
      </Card>
    );
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        {data.map((datum, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
              }}
              key={index}
            >
              {datum.value}
            </Box>
          );
        })}
      </Box>
    );
  };
  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
          }}
          title="Advice"
        >
          <CardHeader headerImg="icon_advice.svg" headerTitle="Advice">
            <></>
          </CardHeader>
          {data.length !== 0 && (
            <TableContainer sx={{ mb: 2, boxShadow: "none" }} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="all-advices">
                <TableBody>
                  {data.map((advice, index) => {
                    const { id, value }: any = advice;
                    return (
                      <TableRow key={`advice-${index}`}>
                        <StyledTableCell>
                          <Autocomplete
                            id={`${id}-value`}
                            filterOptions={(options, { inputValue }) => {
                              return options.filter((option) => true);
                            }}
                            aria-label={`${id}-value`}
                            options={
                              valUpdateSearch.length === 0 ? [] : options
                            }
                            onChange={updateAdvice}
                            value={value}
                            title={value}
                            popupIcon={false}
                            clearIcon={false}
                            // open={
                            //   searchValue.length === 0
                            //     ? false
                            //     : options.length > 0
                            // }
                            noOptionsText=""
                            renderOption={(props, option, state) => {
                              if (
                                !data.some(
                                  (advice: any) => advice.value === option
                                )
                              ) {
                                return (
                                  <Box
                                    component="li"
                                    aria-label={`${id}-value`}
                                    id={`${id}-value-${option}`}
                                    sx={{
                                      backgroundColor: state.selected
                                        ? "#F2F3FF !important"
                                        : "",
                                      "&:hover, &:focus": {
                                        backgroundColor: "#F2F3FF !important",
                                      },
                                    }}
                                    {...props}
                                  >
                                    {!optionsData
                                      .find((opt: any) => opt.name === option)
                                      ?.id?.toString()
                                      .includes("custom")
                                      ? option
                                      : `Add "${option}"`}
                                  </Box>
                                );
                              }
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  name={`${id}-value`}
                                  multiline
                                  onChange={(event: any) => {
                                    const val = event.target.value;
                                    if (val.length === 0) {
                                      setOptions([]);
                                    }
                                    setValUpdateSearch(val);
                                  }}
                                  {...params}
                                  label="Advice"
                                  fullWidth
                                  variant="outlined"
                                />
                              );
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: "32px", padding: "0 !important" }}
                          align="right"
                        >
                          <Tooltip title={"Delete"}>
                            <button
                              id={id}
                              onClick={removeAdvice}
                              style={{
                                outline: "none",
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                style={{ pointerEvents: "none" }}
                                src="/images/minus-in-circle.svg"
                                alt="remove-button"
                              />
                            </button>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Autocomplete
            id="search-for-advice"
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            options={searchValue.length === 0 ? [] : options}
            noOptionsText={""}
            value={""}
            clearOnBlur
            clearOnEscape
            inputValue={searchValue}
            onChange={addAdvice}
            popupIcon={false}
            clearIcon={false}
            open={
              searchValue.length === 0 ? false : options.length > 0 && isFocused
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for advice"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              // const optionObj = JSON.parse(option);
              if (!data.some((advice: any) => advice.value === option)) {
                return (
                  <Box
                    component="li"
                    id={option}
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Autocomplete
            id="search-for-advice"
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            options={searchValue.length === 0 ? [] : options}
            noOptionsText={""}
            value={""}
            clearOnBlur
            clearOnEscape
            inputValue={searchValue}
            onChange={addAdvice}
            popupIcon={false}
            clearIcon={false}
            open={searchValue.length === 0 ? false : options.length > 0}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for advice"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              // const optionObj = JSON.parse(option);
              if (!data.some((advice: any) => advice.value === option)) {
                return (
                  <Box
                    component="li"
                    id={option}
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          <Box marginTop="16px">
            {data.length !== 0 &&
              data.map((advice, index) => {
                return (
                  <Box component="div" id={advice.id} key={index}>
                    {AdviceCardMobile(advice, index)}
                  </Box>
                );
              })}
          </Box>
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
    </>
  );
}
