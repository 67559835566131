import {Box, Typography, useTheme,useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const legends = [
    {
        label:'Hypotension',
        boarderColor:'#0048ff80',
        backgroundColor:'#0048ff1a'
    },
    {
        label:'Normal',
        boarderColor:'#34B3A8',
        backgroundColor:'#34b3a833'
    },
    {
        label:'Elevated',
        boarderColor:'#ff9a6c',
        backgroundColor:'#ff9a6c26'
    },
    {
        label:'Hypertension',
        boarderColor:'#FF9A6C',
        backgroundColor:'#fca10333'
    },
    {
        label:'Crisis',
        boarderColor:'#fb585a',
        backgroundColor:'#fb585a33'
    },
]

const BPChart = () => {

    
      
    // const data = {
    //     labels: ['12/6/23', '15/6/23', '21/7/23', '28/7/23', '5/8/23', '5/8/23', '27/10/23'],
    //     datasets: [
    //       {
    //         label: 'Blood Pressure (mmHg)',
    //         data:[
    //             {x:'12/6/23',y:120,systolic:120,diastolic:62,bpLabel:'normal'},
    //             {x:'15/6/23',y:129,systolic:129,diastolic:89,bpLabel:'elevated'},
    //             {x:'21/7/23',y:139,systolic:139,diastolic:78,bpLabel:'normal'},
    //             {x:'28/7/23',y:123,systolic:123,diastolic:66,bpLabel:'normal'},
    //             {x:'5/8/23',y:149,systolic:149,diastolic:79,bpLabel:'high'},
    //             {x:'5/8/23',y:100,systolic:100,diastolic:90,bpLabel:'normal'},
    //             {x:'27/10/23',y:119,systolic:119,diastolic:100,bpLabel:'normal'},
    //         ],
    //         borderColor: '#086BEB',
    //         backgroundColor: '#086BEB',
    //       },
    //       {
    //         label: 'Blood Pressure (mmHg)',
    //         data:[
    //             {x:'12/6/23',y:-62,systolic:120,diastolic:62,bpLabel:'normal'},
    //             {x:'15/6/23',y:-89,systolic:129,diastolic:89,bpLabel:'elevated'},
    //             {x:'21/7/23',y:-78,systolic:139,diastolic:78,bpLabel:'normal'},
    //             {x:'28/7/23',y:-66,systolic:123,diastolic:66,bpLabel:'normal'},
    //             {x:'5/8/23',y:-79,systolic:149,diastolic:79,bpLabel:'high'},
    //             {x:'5/8/23',y:-90,systolic:100,diastolic:90,bpLabel:'normal'},
    //             {x:'27/10/23',y:-100,systolic:119,diastolic:100,bpLabel:'normal'},
    //         ],
    //         borderColor: '#F493E4',
    //         backgroundColor: '#F493E4',
    //       },
    //     ],
    // };

    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
    

    const topLables = {
        id:'topLabels',
        afterDatasetsDraw(chart,args,pluginOptions){
            const {ctx, scales:{x,y}} = chart;

            chart.data.datasets[0].data.forEach((datapoint,index)=>{
                ctx.font = '12px sans-serif';
                ctx.fillStyle = 'grey';
                ctx.textAlign = 'center';
                ctx.fillText(datapoint?.diastolic,x.getPixelForValue(index),chart.getDatasetMeta(1).data[index].y + 12);
                ctx.fillText(datapoint?.systolic,x.getPixelForValue(index),chart.getDatasetMeta(0).data[index].y - 4);
            })
        },
        
    }

    const [data,setData] = useState({
        labels: [],
        datasets: [
          {
            label: 'Blood Pressure (mmHg)',
            data:[
                // {x:'12/6/23',y:120,systolic:120,diastolic:62,bpLabel:'normal'},
            ],
            borderColor: '#086BEB',
            backgroundColor: '#086BEB',
          },
          {
            label: 'Blood Pressure (mmHg)',
            data:[
                // {x:'12/6/23',y:-62,systolic:120,diastolic:62,bpLabel:'normal'},
            ],
            borderColor: '#F493E4',
            backgroundColor: '#F493E4',
          },
        ],
    })

    const [maxSystolic, setMaxSystolic] = useState(400+50);
    const [maxDiastolic, setMaxDiastolic] = useState(400+50);

    const {
        pastVitalsData : {data:rawData,error,pastVitalsLoader}
    } = useSelector((state) => state.prescription);

    useEffect(()=>{
        if(rawData?.VITALS?.length){
            let rawDataArray = rawData?.VITALS?.find(elem=>elem.name=='bp');

            setMaxSystolic(rawDataArray?.maxSystolic > maxSystolic ? rawDataArray?.maxSystolic : maxSystolic)
            setMaxDiastolic(rawDataArray?.maxDiastolic > maxDiastolic ? rawDataArray?.maxDiastolic : maxDiastolic)
            
            if(rawDataArray?.values){
                let newData = data;
                rawDataArray?.values?.forEach(row=>{
                    let systolic = row?.value?.split('/')[0]
                    let diastolic = row?.value?.split('/')[1]
                    if(systolic && diastolic){
                        newData.labels.push(dayjs(row?.date,'YYYY-MM-DD').format('DD/MM/YYYY'));
                        newData.datasets[0].data.push({
                            x:dayjs(row?.date,'YYYY-MM-DD').format('DD/MM/YYYY'),
                            y:systolic,
                            systolic:systolic,
                            diastolic:diastolic,
                            bpLabel:row?.type
                        })
                        newData.datasets[1].data.push({
                            x:dayjs(row?.date,'YYYY-MM-DD').format('DD/MM/YYYY'),
                            y:+diastolic * -1,
                            systolic:systolic,
                            diastolic:diastolic,
                            bpLabel:row?.type
                        })
                    }
                })
                setData({...newData});
            }
        }
    },[rawData])

    const options = {
        
        responsive: true,
        maintainAspectRatio: isMobileScreen ? false : true,
        plugins: {
            legend: {
                position: 'top',
                display:false
            },
            title: {
                display: false,
                text: 'BMI Chart',
            },
            tooltip: {
                // Disable the on-canvas tooltip
                enabled: false,

                external: function(context) {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = '<table></table>';
                        document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                        const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(getBody);

                        let innerHtml = '<thead>';

                        titleLines.forEach(function(title) {
                            innerHtml += '<tr><th>' + title + '</th></tr>';
                        });
                        innerHtml += '</thead><tbody>';

                        bodyLines.forEach(function(body, i) {
                            const colors = tooltipModel.labelColors[i];
                            let style = 'background:' + colors.backgroundColor;
                            style += '; border-color:' + colors.borderColor;
                            style += '; border-width: 2px';
                            const span = '<span style="' + style + '">' + body + '</span>';
                            innerHtml += '<tr><td>' + span + '</td></tr>';
                        });
                        innerHtml += '</tbody>';

                        let tableRoot = tooltipEl.querySelector('table');
                        tableRoot.style.padding = '8px'
                        tableRoot.style.backgroundColor = '#ffffff'
                        tableRoot.style.boxShadow = '1px 1px 4px #666666'


                        // tableRoot.innerHTML = innerHtml;

                        let bpLabelDotColor;
                        let bpLabelBackgroundColor;
                        switch(context?.tooltip?.dataPoints[0]?.raw?.bpLabel){
                            case 'hypo':
                                bpLabelDotColor = '#0048ff80';
                                bpLabelBackgroundColor = '#0048ff1a';
                                break;
                            case 'normal':
                                bpLabelDotColor = '#34B3A8';
                                bpLabelBackgroundColor = '#34b3a833';
                                break;
                            case 'elevated':
                                bpLabelDotColor = '#ff9a6c';
                                bpLabelBackgroundColor = '#ff9a6c26';
                                break;
                            case 'hyper':
                                bpLabelDotColor = '#FF9A6C';
                                bpLabelBackgroundColor = '#ff9a6c33';
                                break;
                            case 'crisis':
                                bpLabelDotColor = '#fb585a';
                                bpLabelBackgroundColor = '#fb585a33';
                                break;
                            default:
                                bpLabelDotColor = '#34B3A8';
                                bpLabelBackgroundColor = '#34b3a833';
                                break;
                        }

                        tableRoot.innerHTML = `
                            <thead>
                                <tr>
                                    <th>
                                        <div style="display:flex;justify-content:center; align-items:center ; background-color:${bpLabelBackgroundColor}; border-radius:36px; padding: 4px 32px;">
                                            <div style="margin-right:5px; width:6px; height:6px; border-radius:50%; background-color:${bpLabelDotColor};"></div>
                                            <div style="font-size:14px;">${context?.tooltip?.dataPoints[0]?.raw?.bpLabel?.charAt(0)?.toUpperCase() + context?.tooltip?.dataPoints[0]?.raw?.bpLabel?.slice(1)}</div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="display:flex; flex-direction:column; align-items:start;">
                                        <p style="color:#313131;font-size:10px; padding:0px; margin:0px;">Time</p>
                                        <p style="font-size:14px; padding:0px; margin:0px;font-weight:bold;">${context?.tooltip?.dataPoints[0]?.raw?.x}</p>
                                    </td>
                                <tr>
                                <tr>
                                    <td style="display:flex; flex-direction:column; align-items:start;">
                                        <p style="color:#313131;font-size:10px; padding:0px; margin:0px;">Systolic</p>
                                        <p style="font-size:14px; padding:0px; margin:0px;font-weight:bold;">${context?.tooltip?.dataPoints[0]?.raw?.systolic}<span>mmHg</span></p>
                                    </td>
                                <tr>
                                <tr>
                                    <td style="display:flex; flex-direction:column; align-items:start;">
                                        <p style="color:#313131;font-size:10px; padding:0px; margin:0px;">Diastolic</p>
                                        <p style="font-size:14px; padding:0px; margin:0px;font-weight:bold;">${context?.tooltip?.dataPoints[0]?.raw?.diastolic}<span>mmHg</span></p>
                                    </td>
                                <tr>
                            </tbody>
                        `;
                    }

                    const position = context.chart.canvas.getBoundingClientRect();
                    const tooltipX = position.left + window.pageXOffset + tooltipModel.caretX;
                    const chartWidth = context.chart.canvas.clientWidth;
                    const tooltipWidth = tooltipEl.clientWidth;

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    // If the tooltip is off the right side of the chart, position it to the left of the cursor
                    if (tooltipX + tooltipWidth > chartWidth) {
                        tooltipEl.style.left = (tooltipX - tooltipWidth) + 'px';
                    } else {
                        tooltipEl.style.left = tooltipX + 'px';
                    }
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            }
          
        },
        scales:{
            y:{
                title:{
                    display:true, 
                    text:"Blood Pressure (mmHg)",
                    font:{
                        weight:'bold',
                        size: 14
                    },
                },
                min:-1*maxDiastolic,
                max:maxSystolic,
                
                
                stacked:true,
                grid:{
                    color:(context)=>{
                        const zeroLine = context.tick.value;
                        const barColor = zeroLine===0 ? '#666' : '#ccc'
                        return barColor;
                    }
                },
                ticks: {
                    callback: function(val, index) {
                      return val < 0 ? this.getLabelForValue(val)?.replace('-','') : this.getLabelForValue(val);
                    },
                    stepSize: 80,
                },
                offset:true
                
            },
            x:{
                title:{
                    display:true, 
                    text:'Date',
                    font:{
                        weight:'bold',
                        size: 14
                    },
                },
                grid: {
                    display: false
                },
                stacked:true
            }
            
        },
        barThickness: 13
        
    };

    const canvasBackgroundColor = {
        id:'canvasBackgroundColor',
        beforeDraw(chart,args,pluginOptions){
            const {ctx, chartArea: {top,bottom,left,right,width}, scales:{x,y}} = chart;

            
            ctx.save();

            colorBg(-400,-120,'#fb585a26');
            colorBg(180,400,'#fb585a26');

            colorBg(140,180,'#fca10333');
            colorBg(-90,-120,'#fca10333');

            colorBg(120,139,'#ff9a6c26');
            colorBg(-80,-89,'#ff9a6c26');

            colorBg(-61,-80,'#34b3a826');
            colorBg(91,120,'#34b3a826');

            colorBg(-60,90,'#0048ff1a');

            
            

            function colorBg(bracketLow,bracketHigh,color){
                ctx.fillStyle = color
                ctx.fillRect(left,y.getPixelForValue(bracketHigh), width, y.getPixelForValue(bracketLow)-y.getPixelForValue(bracketHigh))
                ctx.restore();
            }
        },
        afterDraw: (chart,args,pluginOptions) => {
            const {ctx, scales:{x,y}} = chart;
            
            let xAxis = chart.scales.x;
            let yAxis = chart.scales.y;
            let maxValue = Math.max(...chart.data.datasets[0].data);
            let minValue = Math.min(...chart.data.datasets[0].data);
            ctx.save();
            ctx.textAlign = 'center';
            ctx.font = `${isMobileScreen ? '12px' : '16px'} Arial`;
            ctx.fontWeight = '500'
            ctx.fillStyle = 'grey';
            ctx.textAlign = 'left';
            ctx.fillText('Systolic', xAxis.left + 10, yAxis.top + 22);
            ctx.fillText('Diastolic', xAxis.left + 10, yAxis.bottom - 18);
            ctx.restore();
          }
    }

    return (
        <Box height={'100%'}  display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box display={'flex'} width={'100%'} justifyContent={'end'} alignItems={'center'} gap={2}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    <Box display={'flex'} width={'13px'} height={'13px'} borderRadius={'50%'} backgroundColor={'#086BEB'}></Box>
                    <Typography>Systolic</Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    <Box display={'flex'} width={'13px'} height={'13px'} borderRadius={'50%'} backgroundColor={'#F493E4'}></Box>
                    <Typography>Diastolic</Typography>
                </Box>
            </Box>
            <Box height={isMobileScreen?'300px' : '100%'} width={'100%'} display={'flex'} >
                <Bar
                    options={options}
                    data={data}
                    plugins={[topLables,canvasBackgroundColor]}
                />
            </Box>
            <Box display={'flex'} gap={2} mt={2} flexWrap={isMobileScreen?'wrap': 'nowrap'} justifyContent={'center'}>
                {legends?.map(elem=>{
                    return (
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                            <Box width={'13px'} height={'13px'} borderRadius={'50%'} border={`1px solid ${elem.boarderColor}`} backgroundColor={elem.backgroundColor}/>
                            <Typography variant='caption'>{elem.label}</Typography>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default BPChart