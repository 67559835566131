import {
  ReactNode,
  SyntheticEvent,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Card from "../../utils/Card";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import FileUploader from "./File-uploader";
import AllDocuments from "./AllDocuments";
import { useSelector } from "react-redux";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import Calendar from "@mui/icons-material/Event";
import dayjs from "dayjs";
import moment from "moment";
import Button from "../../../../../shared/Button/Button";
import { useTheme } from "@mui/material/styles";
import PlusIcon from "../../../../../shared/icons/plusIcon";
import FilterIcon from "../../../../../shared/icons/FilterIcon";
import PDFModal from "../../../../../shared/Pdf-Modal/Pdf-Modal";
import { PATIENT_DOCUMENTS } from "../../../../../shared/constants";
import { MultiInputDateRangeField } from "@mui/x-date-pickers-pro/MultiInputDateRangeField";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import CloseIcon from "@mui/icons-material/Close";

function allyProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

interface TabComponents {
  label: string;
  count: number;
  component: ReactNode;
}
interface MobileFilterState {
  dateValue: Date[];
}

interface FilesProps {
  index: number;
  path: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "14px",
  boxShadow: 24,
  p: 2,
};

const filerData = {
  dateValue: [dayjs(), dayjs()],
  selectClinic: "",
  visitTypeFilter: "",
  statusFilter: "",
  checkedClinic: true,
  checkedVideo: true,
};

export default function PatientDocuments({ isMobileScreen }: any) {
  const theme = useTheme();
  const [tabActive, setTabActive] = useState(0);
  const [openDialog, setOpenDialog] = useState<boolean>();
  const [files, setFiles] = useState<FilesProps[]>([]);
  const TodaysDate = moment().format("YYYY-MM-DD");
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(today.getMonth() - 6);
  const formattedSixMonthsAgo = sixMonthsAgo.toISOString().split("T")[0];
  const [startDate, setStartDate] = useState<any>(formattedSixMonthsAgo);
  const [endDate, setEndDate] = useState<any>(TodaysDate);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [selectedDateFilterValue, setSelectedDateFilterValue] = useState<any>([
    dayjs().subtract(12, "months").startOf("day"),
    dayjs(),
  ]);
  const [openFilter, setOpenFilter] = useState<boolean>();

  const [dateValue, setDateValue] = useState<any>();
  const [rowsCount, setRowsCount] = useState<number>(0);
  const [PreviewURL, setPreviewURL] = useState<string>("");
  const [contentType, setContentType] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [filterButtonDisable, setFilterButtonDisable] = useState(true);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [showFilterCalender, setShowFilterCalender] = useState<boolean>(false);
  const [showFilterDateChangeValue, setShowFilterDateChangeValue] =
    useState<any>([]);
  const handleClose = () => {
    setModalOpen(false);
  };
  const [mobileFilterState, setMobileFilterState] = useReducer(
    (state: any, action: any) => {
      return {
        ...state,
        ...action,
      };
    },
    filerData
  );
  const [addedBy, setaddedBy] = useState<any>();
  const [filterDocuments, setFilterDocuments] = useState<any>();
  const [documentType, setDocumentType] = useState<any>();
  const [openSortingFilter, setOpenSortingFilter] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<any>({ label: "", id: "" });
  const sortingItems = [
    "Newest First",
    "Oldest First",
    "A-Z Ascending",
    "Z-A Descending",
  ];
  const TabPanels: TabComponents[] = [
    {
      label: "ALL DOCUMENTS",
      count: 3,
      component: (
        <AllDocuments
          setPreviewURL={setPreviewURL}
          setContentType={setContentType}
          setModalOpen={setModalOpen}
          searchTerm={searchTerm}
          startDate={startDate}
          endDate={endDate}
          type="all_documents"
        />
      ),
    },
    {
      label: "PRESCRIPTION",
      count: 3,
      component: (
        <AllDocuments
          setPreviewURL={setPreviewURL}
          setContentType={setContentType}
          setModalOpen={setModalOpen}
          searchTerm={searchTerm}
          startDate={startDate}
          endDate={endDate}
          type="prescription"
        />
      ),
    },
    {
      label: "DIAGNOSTICS",
      count: 3,
      component: (
        <AllDocuments
          setPreviewURL={setPreviewURL}
          setContentType={setContentType}
          setModalOpen={setModalOpen}
          searchTerm={searchTerm}
          startDate={startDate}
          endDate={endDate}
          type="diagnostics"
        />
      ),
    },
    {
      label: "DISCHARGE SUMMARY",
      count: 3,
      component: (
        <AllDocuments
          setPreviewURL={setPreviewURL}
          setContentType={setContentType}
          setModalOpen={setModalOpen}
          searchTerm={searchTerm}
          startDate={startDate}
          endDate={endDate}
          type="discharge_summary"
        />
      ),
    },
    {
      label: "OTHERS",
      count: 3,
      component: (
        <AllDocuments
          setPreviewURL={setPreviewURL}
          setContentType={setContentType}
          setModalOpen={setModalOpen}
          searchTerm={searchTerm}
          startDate={startDate}
          endDate={endDate}
          type="other"
        />
      ),
    },
  ];
  const {
    prescription,
    loaders: { fetchingPatientDocuments },
  }: any = useSelector((state: any) => state.prescription);

  const Allrows: any = prescription?.patientDocuments?.data;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabActive(newValue);
  };

  const HandleDialog = () => {
    setTabActive(0);
    setFiles([]);
    setOpenDialog(!openDialog);
  };
  const HandleDialogSave = () => {
    return true;
  };

  const DialogFooterStyles = () => {
    return {
      display: "none",
    };
  };

  const DialogLayoutStyle = () => {
    if (isMobileScreen) {
      return {
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
      };
    } else {
      return {};
    }
  };

  const PaperPropsStyle = () => {
    if (isMobileScreen) {
      return {
        margin: 0,
        width: "100%",
      };
    } else {
      return {};
    }
  };

  const PaperPropsFilterStyle = () => {
    if (isMobileScreen) {
      return {
        margin: 0,
        width: "100%",
      };
    } else {
      return {};
    }
  };

  const DialogContentStyle = () => {
    if (isMobileScreen) {
      return {
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
        "& .MuiDialogContent-root": {
          padding: 0,
        },
      };
    } else {
      return {};
    }
  };

  const FilterDocumentTypes = [
    {
      label: "All Documents",
      id: "all_documents",
    },
    {
      label: "Prescription",
      id: "prescription",
    },
    {
      label: "Diagnostic",
      id: "diagnostics",
    },
    {
      label: "Discharge Summary",
      id: "discharge_summary",
    },
    {
      label: "Others",
      id: "other",
    },
  ];

  const SepartedLengths = (data: any, counts: any) => {
    return data.label === "ALL DOCUMENTS"
      ? counts?.totalCount?.length
      : data.label === "PRESCRIPTION"
      ? counts?.prescriptionCount?.length
      : data.label === "DIAGNOSTICS"
      ? counts?.diagnosticsCount?.length
      : data.label === "DISCHARGE SUMMARY"
      ? counts?.dischargeSummaryCount?.length
      : data.label === "OTHERS"
      ? counts?.othersCount?.length
      : counts?.totalCount?.length;
  };

  const handleDateChange = (date: any) => {
    if (date[0] !== null && date[1] !== null) {
      setStartDate(dayjs(date[0]).format("YYYY-MM-DD"));
      setEndDate(dayjs(date[1]).format("YYYY-MM-DD"));
    } else {
      setStartDate(formattedSixMonthsAgo);
      setEndDate(TodaysDate);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMobileScreen) {
      setSelectedType({ label: "All Documents", id: "all_documents" });
    }
    setSearchTerm(event.target.value);
  };

  const SubTitleStyles = () => {
    return {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.60)",
      letterSpacing: "0.17px",
      lineHeight: "20px",
      marginLeft: isMobileScreen ? "0" : "20px",
      paddingLeft: isMobileScreen ? "20px" : "0",
      borderBottom: isMobileScreen ? "1px solid #eee" : "none",
      marginTop: isMobileScreen ? "-10px" : 0,
      paddingBottom: isMobileScreen ? "10px" : 0,
    };
  };

  const shortcutsItems = [
    {
      label: "Today",
      getValue: () => {
        const today = dayjs();
        return [today, today];
      },
    },
    {
      label: "Yesterday",
      getValue: () => {
        const today = dayjs();
        const yesterday = today.subtract(1, "day");
        return [yesterday, yesterday];
      },
    },
    {
      label: "This Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "Past Month",
      getValue: () => {
        const today = dayjs();
        const pastMonth = today.subtract(1, "month");
        return [pastMonth.startOf("month"), pastMonth.endOf("month")];
      },
    },
    {
      label: "Past 3 Months",
      getValue: () => {
        const today = dayjs();
        const past3Months = today.subtract(3, "month");
        return [past3Months, today];
      },
    },
  ];

  const HandleFilterDialog = () => {
    setOpenFilter(!openFilter);
    setSelectedDateFilterValue([null, null]);
    setDocumentType("");
    setaddedBy("");
    setMobileFilterState({ dateValue: [dayjs(), dayjs()] });
    setFilterButtonDisable(true);
  };

  const footerStyles = () => {
    return {
      display: "flex",
      justifyContent: "space-between",
    };
  };

  const ButtonStyles = () => {
    return {
      width: "170px",
      height: "40px",
      borderRadius: "6px",
    };
  };

  const TypoStyle = () => {
    return {
      color: " #6F6F6F",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "14px",
      padding: "8px",
    };
  };

  const FlexColumn = () => {
    return {
      padding: "16px",
      borderBottom: "1px solid #eeeeee",
    };
  };

  const HandleFilterSave = () => {
    if (!selectedDateFilterValue) {
      return;
    }
    setDateValue(selectedDateFilterValue);
    setShowCalendar(false);
    setSelectedDateFilterValue([dayjs(), dayjs()]);
    handleDateChange(selectedDateFilterValue);
    setOpenFilter(false);
    setFilterDocuments({ documentType: documentType, addedBy: addedBy });
  };
  const handleSortingDialog = () => {
    setOpenSortingFilter(!openSortingFilter);
  };

  const handleSortingFilter = () => {
    setOpenSortingFilter(false);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleRadioChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleFilterDateChange = (value: any) => {
    setSelectedDateFilterValue(value);
    if (selectedDateFilterValue) {
      setFilterButtonDisable(false);
    }
  };

  const handleFilterReset = () => {
    setSelectedDateFilterValue([null, null]);
    setDocumentType("");
    setaddedBy("");
    setMobileFilterState({ dateValue: [dayjs(), dayjs()] });
    setFilterButtonDisable(true);
    handleDateChange([dayjs(), dayjs()]);
    setFilterDocuments({ documentType: documentType, addedBy: addedBy });
  };
  return (
    <TabContext value={tabActive.toString()}>
      <>
        <PDFModal
          From={PATIENT_DOCUMENTS}
          pdfUrl={PreviewURL}
          displayType={contentType}
          handleClose={handleClose}
          modalOpen={modalOpen}
        />
        <Card
          styles={{
            padding: 0,
            margin: 0,
          }}
        >
          <CustomizedDialogs
            isMobileScreen={isMobileScreen}
            Subtitle="PNG, JPG or PDF (max. 3MB)"
            DialogOpen={openDialog}
            headerStyles={{
              color: "#494E9D",
              borderBottom: isMobileScreen ? "0" : "1px solid #eee",
            }}
            subTitleStyles={SubTitleStyles()}
            onHandleDialog={HandleDialog}
            title="Upload documents"
            PaperPropsStyle={PaperPropsStyle()}
            Layoutstyles={DialogLayoutStyle()}
            footerStyles={DialogFooterStyles()}
            footerCancel="Upload another document"
            footerSave="Save"
            fullWidth={isMobileScreen ? false : true}
            maxWidth="md"
            onHandleSave={HandleDialogSave}
          >
            <Card
              styles={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FileUploader
                isMobileScreen={isMobileScreen}
                HandleDialog={HandleDialog}
                setFiles={setFiles}
                files={files}
              />
            </Card>
          </CustomizedDialogs>

          <CustomizedDialogs
            title={openSortingFilter ? "Sort by" : "Filter"}
            headerStyles={{
              color: "#494E9D",
              borderBottom: "1px solid #eee",
              ...(!openSortingFilter && { padding: "16px" }),
            }}
            DialogOpen={openFilter || openSortingFilter}
            onHandleDialog={
              !openSortingFilter ? HandleFilterDialog : handleSortingFilter
            }
            PaperPropsStyle={PaperPropsFilterStyle()}
            Layoutstyles={DialogContentStyle()}
            footerStyles={{
              ...footerStyles(),
              ...(!openSortingFilter && { padding: 2 }),
            }}
            Buttonstyles={ButtonStyles()}
            onHandleSave={HandleFilterSave}
            buttonDisable={filterButtonDisable}
            onHandleReset={handleFilterReset}
            footerSave="Apply"
            footerCancel="Reset"
            fullWidth={isMobileScreen ? false : true}
            maxWidth="md"
          >
            <Box>
              {openFilter && (
                <Box>
                  <Box>
                    <Box sx={FlexColumn()}>
                      <Typography
                        sx={{
                          ...TypoStyle(),
                          padding: 0,
                        }}
                      >
                        Date
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateRangePicker"]}>
                          <MultiInputDateRangeField
                            value={selectedDateFilterValue}
                            defaultValue={[
                              dayjs().subtract(12, "months").startOf("day"),
                              dayjs(),
                            ]}
                            slotProps={{
                              textField: ({ position }) => ({
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Box
                                        component="img"
                                        src={"/images/icon_mobile_calender.svg"}
                                        sx={{
                                          width: "16px",
                                          height: "16px",
                                          padding: "0px",
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                },
                                placeholder:
                                  position === "start" ? "From" : "To",
                              }),
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                // Correct selector syntax
                                height: "40px",
                                width: "119px",
                              },
                              "& .MuiFormControl-root": {
                                width: "unset",
                              },
                              "& .MuiInputBase-input": {
                                padding: "0px",
                                fontSize: "14px",
                              },
                            }}
                            onClick={() => setShowFilterCalender(true)}
                          />
                          {/* <DateRangePicker
                          localeText={{ start: "From", end: "To" }}
                          value={selectedDateFilterValue}
                          onChange={(newValue) => {
                            if (!newValue[0] || !newValue[1]) {
                              return;
                            }
                            setMobileFilterState({ dateValue: newValue });
                            setSelectedDateFilterValue(newValue);
                            setFilterButtonDisable(false)
                          }}
                          slotProps={{
                            textField: {
                              InputProps: {
                                startAdornment: (
                                  <Box
                                    width={{
                                      md: 30,
                                      marginRight: 4,
                                    }}
                                    component="img"
                                    src={"/images/calenderIcon.svg"}
                                    sx={{}}
                                  />
                                ),
                              },
                              onKeyDown: (e) => {
                                e.preventDefault();
                              },
                            },
                          }}
                        /> */}
                        </DemoContainer>
                      </LocalizationProvider>

                      <Box display={"flex"} gap={1} flexWrap={"wrap"} mt={2}>
                        {shortcutsItems.map((elem) => {
                          return (
                            <Box
                              padding={1}
                              border={`1px solid  #EEEEEE`}
                              borderRadius={"8px"}
                              sx={{
                                backgroundColor:
                                  selectedDateFilterValue &&
                                  selectedDateFilterValue[0]?.format(
                                    "DD/MM/YYYY"
                                  ) ===
                                    elem.getValue()[0]?.format("DD/MM/YYYY") &&
                                  selectedDateFilterValue &&
                                  selectedDateFilterValue[1]?.format(
                                    "DD/MM/YYYY"
                                  ) === elem.getValue()[1]?.format("DD/MM/YYYY")
                                    ? "#EEEEF7"
                                    : "#FFFFFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                ":hover": {
                                  backgroundColor: "#EEEEEE",
                                },
                                border:
                                  selectedDateFilterValue &&
                                  selectedDateFilterValue[0]?.format(
                                    "DD/MM/YYYY"
                                  ) ===
                                    elem.getValue()[0]?.format("DD/MM/YYYY") &&
                                  selectedDateFilterValue &&
                                  selectedDateFilterValue[1]?.format(
                                    "DD/MM/YYYY"
                                  ) === elem.getValue()[1]?.format("DD/MM/YYYY")
                                    ? "1px solid #494E9D"
                                    : "0.5px solid #DDD",
                              }}
                              onClick={() => {
                                setMobileFilterState({
                                  dateValue: elem.getValue(),
                                });
                                setSelectedDateFilterValue(elem.getValue());
                                setFilterButtonDisable(false);
                              }}
                            >
                              <Typography
                                fontWeight={600}
                                color={
                                  selectedDateFilterValue &&
                                  selectedDateFilterValue[0]?.format(
                                    "DD/MM/YYYY"
                                  ) ===
                                    elem.getValue()[0]?.format("DD/MM/YYYY") &&
                                  selectedDateFilterValue &&
                                  selectedDateFilterValue[1]?.format(
                                    "DD/MM/YYYY"
                                  ) === elem.getValue()[1].format("DD/MM/YYYY")
                                    ? "#494E9D"
                                    : "#313131"
                                }
                              >
                                {elem.label}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>

                    <Box sx={FlexColumn()}>
                      <Typography
                        sx={{
                          ...TypoStyle(),
                          padding: 0,
                        }}
                      >
                        Type of Documents
                      </Typography>
                      <Box display={"flex"} gap={1} flexWrap={"wrap"} mt={1}>
                        {FilterDocumentTypes.map((elem) => {
                          return (
                            <Box
                              padding={1}
                              border={`1px solid  #EEEEEE`}
                              borderRadius={"8px"}
                              sx={{
                                background:
                                  documentType !== elem.id
                                    ? "#FFFFFF"
                                    : "#EEEEF7",
                                border:
                                  documentType !== elem.id
                                    ? "0.5px solid #DDD"
                                    : "1px solid #494E9D",
                              }}
                              onClick={() => {
                                // setSelectedType(elem);
                                setDocumentType(elem?.id);
                                setFilterButtonDisable(false);
                              }}
                            >
                              <Typography
                                fontWeight={600}
                                sx={{
                                  color:
                                    documentType !== elem?.id
                                      ? "#313131"
                                      : "#494E9D",
                                }}
                              >
                                {elem.label}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>

                    <Box sx={FlexColumn()}>
                      <Typography
                        sx={{
                          ...TypoStyle(),
                          padding: 0,
                        }}
                      >
                        Added by{" "}
                      </Typography>
                      <Box display={"flex"} gap={1} flexWrap={"wrap"} mt={1}>
                        <Box
                          padding={1}
                          border={`1px solid  #EEEEEE`}
                          borderRadius={"8px"}
                          onClick={() => {
                            setaddedBy("Patient");
                            setFilterButtonDisable(false);
                          }}
                          sx={{
                            background:
                              addedBy === "Patient" ? "#EEEEF7" : "#FFFFFF",
                            border:
                              addedBy === "Patient"
                                ? "1px solid #494E9D"
                                : "1px solid #DDD",
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            color={
                              addedBy === "Patient" ? "#494E9D" : "#313131"
                            }
                          >{`Patient`}</Typography>
                        </Box>
                        <Box
                          padding={1}
                          border={`1px solid  #EEEEEE`}
                          borderRadius={"8px"}
                          onClick={() => {
                            setaddedBy("Doctor");
                            setFilterButtonDisable(false);
                          }}
                          sx={{
                            background:
                              addedBy === "Doctor" ? "#EEEEF7" : "#FFFFFF",
                            border:
                              addedBy === "Doctor"
                                ? "1px solid #494E9D"
                                : "1px solid #DDD",
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            color={addedBy === "Doctor" ? "#494E9D" : "#313131"}
                          >{`Doctor`}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {showFilterCalender && (
                    <Modal
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      open={showFilterCalender}
                      onClose={() => {
                        setShowFilterCalender(false);
                      }}
                    >
                      <Box
                        className="filter-main-container"
                        sx={{ background: "#ffff" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #D7D7D7",
                            height: "54px",
                          }}
                        >
                          <Typography
                            className="filter-heading"
                            sx={{
                              fontSize: "20px",
                              fontWeight: "700",
                              color: "rgba(73, 78, 157, 1)",
                              marginLeft: "10px",
                            }}
                          >
                            Select Date Range
                          </Typography>
                          <IconButton
                            onClick={() => setShowFilterCalender(false)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        <Box
                          className="filter-calender"
                          sx={{
                            background: "white",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={[
                                "DateRangeCalendar",
                                "DateRangeCalendar",
                              ]}
                            >
                              <DemoItem>
                                <DateRangeCalendar
                                  calendars={1}
                                  onChange={handleFilterDateChange}
                                />
                              </DemoItem>
                            </DemoContainer>
                          </LocalizationProvider>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            paddingBottom: "16px",
                          }}
                        >
                          <Button
                            onClick={() => setShowFilterCalender(false)}
                            variant="contained"
                            text="Clear"
                            styles={{
                              width: "100px",
                            }}
                          />
                          <Button
                            onClick={() => setShowFilterCalender(false)}
                            variant="contained"
                            text="Apply"
                            styles={{
                              width: "100px",
                            }}
                          />
                        </Box>
                      </Box>
                    </Modal>
                  )}
                </Box>
              )}
              {openSortingFilter && (
                <Box>
                  {sortingItems.map((item: string) => (
                    <Box display="flex" alignItems="center">
                      <Radio {...controlProps(item)} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#313131",
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </CustomizedDialogs>

          {isMobileScreen && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 8px 0 8px",
              }}
            >
              <Box display="flex" gap={1} alignItems={"center"}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    color: "#494E9D",
                  }}
                >
                  All Documents
                </Typography>
                <Badge
                  sx={{
                    width: "30px",
                    height: "20px",
                    display: "flex",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100px",
                    background: "#34B3A8",
                    color: "#FFFFFF",
                  }}
                >
                  {rowsCount ? rowsCount : 0}
                </Badge>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <Box
                  className="filter-icon"
                  sx={{
                    border: `1px solid #C4C4C4`,
                    borderRadius: "4px",
                    width: "40px",
                    height: "40px",
                  }}
                >
                  <IconButton
                    sx={{
                      padding: "8px 0 0 0 ",
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={HandleFilterDialog}
                  >
                    <FilterIcon />
                  </IconButton>
                </Box>
                {/* <IconButton sx={{border: `1px solid #C4C4C4`,
                  borderRadius: "4px",}} onClick={handleSortingDialog}>
                  <Box component="img" src="/images/icon_filter.svg" />
                </IconButton> */}
              </Box>
            </Box>
          )}
          <Box
            className="seacrch-box"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: isMobileScreen ? 0 : 6,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: isMobileScreen ? "71px" : "102px",
                display: "flex",
                alignItems: "center",
                // padding: "0 10px",
                gap: "16px",
              }}
            >
              {" "}
              <Box sx={{width:"100%", marginLeft:"16px"}}>
                <TextField
                  autoComplete="off"
                  id="search-by-name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by name"
                  sx={{
                    width:"100%",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                />
              </Box>
              {!isMobileScreen && (
                <Grid
                  item
                  key={"start-end"}
                  xs={5}
                  style={{ marginTop: "-10px", flex: 1 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["SingleInputDateRangeField"]}>
                      <DateRangePicker
                        sx={{ minWidth: "270px !important" }}
                        label="From-to"
                        slots={{ field: SingleInputDateRangeField }}
                        slotProps={{
                          textField: {
                            InputProps: { endAdornment: <Calendar /> },
                            onClick: (e) => {
                              setShowCalendar(true);
                            },
                            focused: showCalendar,
                            value: dateValue,
                          },
                        }}
                        onChange={() => {
                          // handleDateChange()
                          setShowCalendar(true);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <Modal
                    open={showCalendar}
                    onClose={() => {
                      setShowCalendar(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style2}>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DateRangePicker"]}>
                            <DateRangePicker
                              localeText={{
                                start: "From",
                                end: "To",
                              }}
                              value={selectedDateFilterValue}
                              onChange={(newValue) => {
                                if (!newValue[0] || !newValue[1]) {
                                  return;
                                }
                                setSelectedDateFilterValue(newValue);
                                // getDateValue(newValue);
                              }}
                              slotProps={{
                                textField: {
                                  InputProps: {
                                    startAdornment: (
                                      <Box
                                        width={{
                                          md: 30,
                                          marginRight: 4,
                                        }}
                                        component="img"
                                        src={"/images/calenderIcon.svg"}
                                        sx={{}}
                                      />
                                    ),
                                  },
                                  onKeyDown: (e) => {
                                    e.preventDefault();
                                  },
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Box>

                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        gap={1}
                        mt={2}
                      >
                        {shortcutsItems.map((elem) => {
                          return (
                            <Box
                              sx={{
                                backgroundColor:
                                  selectedDateFilterValue &&
                                  selectedDateFilterValue[0]?.format(
                                    "DD/MM/YYYY"
                                  ) ===
                                    elem.getValue()[0].format("DD/MM/YYYY") &&
                                  selectedDateFilterValue &&
                                  selectedDateFilterValue[1]?.format(
                                    "DD/MM/YYYY"
                                  ) === elem.getValue()[1].format("DD/MM/YYYY")
                                    ? "#3755aa33"
                                    : "#FFFFFF",
                                border: `1px solid ${"#C0C0C0"}`,
                                borderRadius: "10px",
                                padding: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                ":hover": {
                                  backgroundColor: "#EEEEEE",
                                },
                              }}
                              onClick={() => {
                                setSelectedDateFilterValue(elem.getValue());
                              }}
                            >
                              <Typography>{elem.label}</Typography>
                            </Box>
                          );
                        })}
                        <Box display={"flex"} gap={1}>
                          <Box
                            sx={{
                              flex: 1,
                              border: `1px solid ${"#C0C0C0"}`,
                              borderRadius: "10px",
                              padding: "10px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              ":hover": {
                                backgroundColor: "#EEEEEE",
                              },
                            }}
                            onClick={() => {
                              setDateValue(undefined);
                              setShowCalendar(false);
                              setSelectedDateFilterValue([dayjs(), dayjs()]);
                              setStartDate(formattedSixMonthsAgo);
                              setEndDate(TodaysDate);
                            }}
                          >
                            <Typography>{"Reset"}</Typography>
                          </Box>
                          <Box
                            sx={{
                              flex: 1,
                              backgroundColor: selectedDateFilterValue
                                ? theme.palette.primary.main
                                : "#C0C0C0",
                              borderRadius: "10px",
                              padding: "10px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!selectedDateFilterValue) {
                                return;
                              }
                              setDateValue(selectedDateFilterValue);
                              setShowCalendar(false);
                              setSelectedDateFilterValue([dayjs(), dayjs()]);
                              handleDateChange(selectedDateFilterValue);
                            }}
                          >
                            <Typography color={"#FFFFFF"}>{"Apply"}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                </Grid>
              )}
            </Box>

            <Box>
              {!isMobileScreen ? (
                <Grid item key={"Upload"} xs={1}>
                  <Button
                    onClick={HandleDialog}
                    variant="contained"
                    startIcon={<AddRoundedIcon />}
                    text="Upload"
                    styles={{
                      marginRight: "16px",
                    }}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  key={"Upload"}
                  sx={{
                    position: "fixed",
                    bottom: "12%",
                    right: "5%",
                  }}
                >
                  <span
                    onClick={HandleDialog}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#494E9D",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                  >
                    <PlusIcon />
                  </span>
                </Grid>
              )}
            </Box>
          </Box>
        </Card>

        {!isMobileScreen && (
          <>
            {" "}
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              {
                <Tabs value={tabActive} onChange={handleChange}>
                  {TabPanels.map((data: TabComponents, index: number) => {
                    const counts = !searchTerm
                      ? {
                          diagnosticsCount:
                            Allrows &&
                            Allrows?.filter(
                              (item: any) =>
                                item.document_type === "diagnostics"
                            ),
                          prescriptionCount:
                            Allrows &&
                            Allrows?.filter(
                              (item: any) =>
                                item.document_type === "prescription"
                            ),
                          dischargeSummaryCount:
                            Allrows &&
                            Allrows?.filter(
                              (item: any) =>
                                item.document_type === "discharge_summary"
                            ),
                          othersCount:
                            Allrows &&
                            Allrows?.filter(
                              (item: any) => item.document_type === "other"
                            ),
                          totalCount: Allrows && Allrows,
                        }
                      : {
                          diagnosticsCount:
                            Allrows &&
                            Allrows?.filter(
                              (item: any) =>
                                item.document_type === "diagnostics" &&
                                item.file_name
                                  ?.toLowerCase()
                                  .includes(searchTerm?.toLowerCase())
                            ),
                          prescriptionCount:
                            Allrows &&
                            Allrows?.filter(
                              (item: any) =>
                                item.document_type === "prescription" &&
                                item.file_name
                                  ?.toLowerCase()
                                  .includes(searchTerm?.toLowerCase())
                            ),
                          dischargeSummaryCount:
                            Allrows &&
                            Allrows?.filter(
                              (item: any) =>
                                item.document_type === "discharge_summary" &&
                                item.file_name
                                  ?.toLowerCase()
                                  .includes(searchTerm?.toLowerCase())
                            ),
                          othersCount:
                            Allrows &&
                            Allrows?.filter(
                              (item: any) =>
                                item.document_type === "other" &&
                                item.file_name
                                  ?.toLowerCase()
                                  .includes(searchTerm?.toLowerCase())
                            ),
                          totalCount:
                            Allrows &&
                            Allrows?.filter((item: any) =>
                              item.file_name
                                ?.toLowerCase()
                                .includes(searchTerm?.toLowerCase())
                            ),
                        };
                    return (
                      <Tab
                        icon={
                          <Badge
                            sx={{
                              width: "27px",
                              height: "20px",
                              display: "flex",
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "100px",
                              background: "#34B3A8",
                              color: "#FFFFFF",
                            }}
                          >
                            {SepartedLengths(data, counts)}
                          </Badge>
                        }
                        sx={{
                          lineHeight: "24px",
                          fontWeight: "600",
                          fontStyle: "normal",
                          padding: "0px 10px",
                          minHeight: "50px",
                          marginTop: "15px",
                          fontSize: "14px",
                        }}
                        iconPosition="end"
                        label={data.label}
                        {...allyProps(index)}
                      ></Tab>
                    );
                  })}
                </Tabs>
              }
            </Box>
            <Box>
              {TabPanels.map((data: TabComponents, index: number) => (
                <div
                  key={index}
                  role="tabpanel"
                  hidden={tabActive !== index}
                  id={`tabpanel-${index}`}
                >
                  {tabActive === index && (
                    <Typography component="div">{data.component}</Typography>
                  )}
                </div>
              ))}
            </Box>
          </>
        )}

        {isMobileScreen && (
          <>
            <AllDocuments
              type={selectedType.id}
              searchTerm={searchTerm}
              startDate={startDate}
              endDate={endDate}
              addedBy={filterDocuments?.addedBy}
              filterType={filterDocuments?.documentType}
              rowsCount={setRowsCount}
            />
          </>
        )}
      </>
    </TabContext>
  );
}
