import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Skeleton,
  TextField,
  useMediaQuery,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import {
  PATIENT_HISTORY,
  SOCIAL_HISTORY,
} from "../../../../../../shared/constants";

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  resize:none;
  outline: none;
  border:1px solid #CACACA;
`
);

export default function SocialHistory(props: any) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([] as any);

  const sectionData = props?.sectionData && props?.sectionData?.[0];
  const setTabData = props?.setTabData;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const statusMaster = sectionData?.master?.status?.values || [];

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      setLoading(false);
    }
  }, [sectionData]);

  const updateHistory = (event: SyntheticEvent, value: string | null) => {
    const target =
      (event.target as HTMLInputElement) || (event.target as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    if (ariaLabel || inputName) {
      const nameArr = (ariaLabel || inputName).split("-");

      const key = nameArr[nameArr.length - 1];

      const name = nameArr.reduce((acc, val) => {
        if (val === key) {
          return acc;
        }
        return acc + "-" + val;
      });
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === PATIENT_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === SOCIAL_HISTORY) {
                  return {
                    ...row,
                    value: row.value.map((val: any) => {
                      if (val.name === name) {
                        return {
                          ...val,
                          [key]: value,
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
    }
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && (
        <Box sx={{ my: isMobileScreen ? 0 : 3 }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mb: "21px",
              alignItems: "center",
            }}
          >
            {!isMobileScreen && (
              <span style={{ fontWeight: "600", color: "#000" }}>
                Social history
              </span>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: !isMobileScreen ? "row" : "column",
            }}
          >
            {data.map((habit: any, index: any) => {
              const { name, status, comment } = habit;
              return (
                <Box
                  className="main"
                  key={name}
                  sx={{
                    display: "flex",
                    flexDirection: !isMobileScreen ? "row" : "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: `flex`,
                      width: "100%",
                      flexDirection: "column",
                      // minWidth: "250px",
                    }}
                  >
                    <Autocomplete
                      sx={{ mb: 1, width: !isMobileScreen ? "100%" : "60%" }}
                      disableClearable
                      options={statusMaster}
                      filterOptions={(options, { inputValue }) => {
                        return options.filter((option) => true);
                      }}
                      value={status}
                      title={status}
                      clearIcon={false}
                      onChange={updateHistory}
                      renderOption={(props, option, state) => {
                        return (
                          <Box
                            aria-label={`${name}-status`}
                            component="li"
                            sx={{
                              backgroundColor: state.selected
                                ? "#F2F3FF !important"
                                : "",
                              "&:hover, &:focus": {
                                backgroundColor: "#F2F3FF !important",
                              },
                            }}
                            id={`${name}-status-${option}`}
                            {...props}
                          >
                            {option}
                          </Box>
                        );
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            name={`${name}-status`}
                            {...params}
                            label={`${name} Status`}
                            aria-label={`${name}-status`}
                            fullWidth
                            variant="outlined"
                          />
                        );
                      }}
                    />
                    <Textarea
                      title={comment}
                      value={comment}
                      aria-label={`${name}-comment`}
                      name={`${name}-comment`}
                      onChange={(event: any) => {
                        let value = event.target.value;
                        if (value.length > 250) {
                          value = value.substring(0, 250);
                        }
                        setData((prev: any) => {
                          return prev.map((elem: any) => {
                            if (elem.name === name) {
                              return {
                                ...elem,
                                comment: value,
                              };
                            }
                            return elem;
                          });
                        });
                      }}
                      onBlur={(event: any) => {
                        updateHistory(event as any, event.target.value);
                      }}
                      minRows={2.8}
                      placeholder="Comments"
                    />
                  </Box>
                  {index !== data?.length - 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: !isMobileScreen ? "row" : "column",
                        width: !isMobileScreen ? "80px" : "100%",
                        height: !isMobileScreen ? "100%" : "40px",
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: isMobileScreen
                            ? "1px dashed #C2C2C2"
                            : "none",
                          borderLeft: !isMobileScreen
                            ? "0.5px dashed #C2C2C2"
                            : "none",
                          borderWidth: "2px",
                        }}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
}
