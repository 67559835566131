// import accountService from "../../Services/account.settings";

let dataUsers = ["r:dataadmin", "r:dataops", "r:dataqc"];
import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { APIClient } from "./api-client";
import { Buffer } from "buffer";
import { API_ROUTE_PRESCRIPTION_VIEW_URL } from "../routes/route.constants";
let isAdminAndDataUser = (customRole) => {
  let customRoleSet = new Set(customRole.split(","));
  let roleIntersection = dataUsers.filter((d) => customRoleSet.has(d));
  if (customRoleSet.has("r:admin") && roleIntersection.length > 0) {
    return roleIntersection[0];
  }
  return false;
};

export function sendMessageToNativeApp(message) {
  // window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(message)
  window?.webkit?.messageHandlers?.IOS_BRIDGE?.postMessage({
    message: message,
  });
}

export function timeDifreenece(params) {
  // start time and end time
  var startTime = moment().format("'HH:mm:ss a'");
  var endTime = moment(params, "HH:mm:ss a");

  // calculate total duration
  var duration = moment.duration(endTime.diff(startTime));

  // duration in hours
  var hours = parseInt(duration.asHours());

  // duration in minutes
  var minutes = parseInt(duration.asMinutes()) % 60;

  return hours + " hour and " + minutes + " minutes.";
}

export function getAuthUserId() {
  const AUTH_TOKEN = localStorage.getItem("emrToken");
  if (AUTH_TOKEN) {
    return Auth.currentSession().then(
      function (session) {
        if (
          !session ||
          !session.idToken ||
          !session.idToken.payload ||
          !session.idToken.payload.sub
        ) {
          return;
        }
        return session.idToken.payload.sub;
      },
      function (err) {
        console.log(err);
      }
    );
  }
}

const Utils = {
  isAdminAndDataUser,
  getAuthUserId,
};

export default Utils;

// var validation = {
//     isEmailAddress:function(str) {
//         var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
//         return pattern.test(str);  // returns a boolean
//     },
//     isNotEmpty:function (str) {
//         var pattern =/\S+/;
//         return pattern.test(str);  // returns a boolean
//     },
//     isNumber:function(str) {
//         var pattern = /^\d+$/;
//         return pattern.test(str);  // returns a boolean
//     },
//     isSame:function(str1,str2){
//         return str1 === str2;
//     }
// };

export function getDayFormat(date, show) {
  const _date = moment(date);
  //console.log(_date)
  const today = moment().endOf("day");
  const tomorrow = moment().add(1, "day").endOf("day");
  const yesterday = moment().subtract(1, "day").endOf("day");
  const day_before_yesterday = moment().subtract(2, "day").endOf("day");
  // const later = moment().add(2, 'day').endOf('day')
  if (_date < day_before_yesterday) return moment(date).format("dddd");
  if (_date < yesterday) return "Yesterday";
  if (_date < today) return "Today";
  if (_date < tomorrow) return "Tomorrow";
  if (show) {
    return moment(date).format("Do MMM' YYYY");
  } else {
    return moment(date).format("dddd");
  }
}

/**
 * Set Authorization token to header by default
 */
export function updateAuthHeader() {
  const AUTH_TOKEN = localStorage.getItem("emrToken");
  if (AUTH_TOKEN) {
    let cAccountString = localStorage.getItem("cAccount")
      ? "-liveaccountid-" + localStorage.getItem("cAccount")
      : "";
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + AUTH_TOKEN + cAccountString;
  } else {
    axios.defaults.headers.common["Authorization"] = "";
  }
}

export function updateAuthHeaderWhenExpire() {
  const AUTH_TOKEN = localStorage.getItem("emrToken");
  if (AUTH_TOKEN) {
    Auth.currentSession().then(
      function (session) {
        if (!session || !session.idToken || !session.idToken.jwtToken) {
          let cAccountString = localStorage.getItem("cAccount")
            ? "-liveaccountid-" + localStorage.getItem("cAccount")
            : "";
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + AUTH_TOKEN + cAccountString;
          return;
        }
        // localStorage.removeItem("emrToken");
        let cAccountString = localStorage.getItem("cAccount")
          ? "-liveaccountid-" + localStorage.getItem("cAccount")
          : "";

        // localStorage.setItem("emrToken", session.idToken.jwtToken);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + session.idToken.jwtToken + cAccountString;
        // window.location.reload();
      },
      function (err) {
        console.log(err);
      }
    );
  } else {
    axios.defaults.headers.common["Authorization"] = "";
  }
}

export function getToken() {
  const AUTH_TOKEN = localStorage.getItem("emrToken")
    ? localStorage.getItem("emrToken")
    : "";
  return AUTH_TOKEN;
}

export function setTokenAfterHalfHr() {
  return setInterval(() => {
    Auth.currentSession().then(
      function (session) {
        if (!session || !session.idToken || !session.idToken.jwtToken) {
          console.log("nothing happens");
          if (
            window.location.pathname.indexOf("404") !== -1 &&
            window.location.pathname.indexOf("login") !== -1
          ) {
            window.location = "/login";
          }
        } else {
          // localStorage.setItem("emrToken", session.idToken.jwtToken);
          const nativeMode = localStorage.getItem("nativeMode");
          if (nativeMode === "true" || nativeMode === true) {
            try {
              emitNotifyEvent(session.idToken.jwtToken);
            } catch (e) {
              console.log("Notify Not found");
            }
          }
        }
      },
      function (err) {
        console.log(err);
      }
    );
  }, 2100000);
}

function emitNotifyEvent(token) {
  let obj = {
    req: {
      token: token,
    },
    action: "refresh-token",
    path: window.location.href,
  };
  window.external.notify(JSON.stringify(obj));
}

export function throttle(func, limit) {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = null;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

export function isNumericOnly(str) {
  return str !== "" && !/^\d+$/.test(str);
}

export function isValidEmail(email) {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
}

export async function fetchDisplayUrlData(displayUrl) {
  const response = await axios.get(displayUrl);
  if (response?.status === 200) {
    return response?.data;
  }
}

export async function fetchSuggestions(url, params, useCustomBaseURL) {
  return await APIClient.get(
    url,
    {
      params,
    },
    undefined,
    useCustomBaseURL
  );
}

export function seperateUrlAndParams(ur) {
  let query_params = {};
  let url = ur?.split("?")[0];
  let par = ur?.split("?")[1];
  if (par && par.length) {
    par?.split("&").forEach((f) => {
      let val = f.split("=");
      query_params[val[0]] = val[1];
    });
  }

  return { query_params, url };
}

export const fetchAutoCompleteOptions = async (params) => {
  return await axios.get(
    `https://1r311zmywf.execute-api.ap-south-1.amazonaws.com/dev/autocomplete`,
    {
      params: params,
    }
  );
};

export const fileSavePatientDocuments = async (data) => {
  const headers = {
    "Content-Type": data.contentType,
  };

  return axios
    .put(data.displayUrl, new Uint8Array(data.binaryStr), {
      headers,
    })
    .then((response) => response.data);
};

export const debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};
export const sendPrescription = async (signedUrl, base64String) => {
  try {
    const binaryData = Buffer.from(base64String, "base64");
    const response = await axios.put(signedUrl?.pdf_url, binaryData, {
      headers: {
        "Content-Type": "application/pdf",
        "Content-Disposition": `inline; filename="${signedUrl?.file_name}"`,
      },
    });
    if (response?.status === 200) {
      return response;
    }
  } catch (err) {
    return err;
  }
};

export const getTemplateUrl = async (templateParams) => {
  const params = {
    content_type: "application/json",
    ...((templateParams?.templateType === "mini_template" ||
      templateParams?.templateType === "instant_template") && {
      appointment_id: templateParams?.selectedAppointment?.appointment_id,
    }),
    type: templateParams?.templateType,
    ...((templateParams?.templateType === "mini_template" ||
      templateParams?.templateType === "instant_template") && {
      doctor_id: templateParams?.selectedAppointment?.doctor_id,
    }),
    ...((templateParams?.templateType === "mini_template" ||
      templateParams?.templateType === "instant_template") && {
      patient_id: templateParams?.selectedAppointment?.patient?.patient_id,
    }),
    template_id: templateParams?.templateId
      ? templateParams?.templateId
      : templateParams?.selectedTemplate?.template_id,
    ...(templateParams?.templateType === "mini_template" && {
      section_id: templateParams?.data?.view_type,
    }),
  };

  const response = await APIClient.get(API_ROUTE_PRESCRIPTION_VIEW_URL(), {
    params,
  });
  return response;
};
