import { Box, useTheme } from "@mui/material";

const useGetAppointmentTags = (key) => {
  //   console.log(key);
  const theme = useTheme();
  if (key === "Booked") {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.blue.light,
          color: theme.palette.blue.main,
          padding: "0px 10px",
          borderRadius: "20px",
          textAlign: "center",
          display:'flex',
          alignItems:'center',
          justifyContent:"center"
        }}
      >
        <svg width="7" height="6" viewBox="0 0 7 6" style={{marginRight:"5px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="3.49988" cy="3" r="3" fill="#21A6F1"/>
        </svg>
        {"Scheduled "}
      </Box>
    );
  }
  if (key === "Checked-In") {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.blue.light,
          color: theme.palette.blue.main,
          padding: "0px 10px",
          borderRadius: "20px",
          textAlign: "center",
          minWidth:'105px',
          display:'flex',
          alignItems:'center',
          justifyContent:"center"
        }}
      >
       <svg width="7" height="6" viewBox="0 0 7 6" style={{marginRight:"5px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="3.49988" cy="3" r="3" fill="#21A6F1"/>
</svg>  {"Checked-In"}
      </Box>
    );
  }

  if (key === "Consulting") {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.secondary.main,
          padding: "0px 0px",
          borderRadius: "20px",
          textAlign: "center",
          minWidth:'100px',
          display:'flex',
          alignItems:'center',
          justifyContent:"center"
        }}
      >
        <svg width="8" height="8" style={{marginRight:"5px"}} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="3.99994" cy="4" r="3" fill="#12B76A"/>
</svg>
 {"In-Process"}
      </Box>
    );
  }

  if (key === "Done") {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
          padding: "0px 10px",
          borderRadius: "20px",
          textAlign: "center",
          display:'flex',
          alignItems:'center',
          justifyContent:"center"
        }}
      >
       <svg width="8" height="8" viewBox="0 0 8 8" style={{marginRight:"5px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="3.99982" cy="4" r="3" fill="#494F9D"/>
</svg>
  {"Completed"}
      </Box>
    );
  }

  if (key === "Cancelled" || key === "Declined" || key === "No-Show" || key === "Missed") {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.negative.light,
          color: theme.palette.warning.main,
          padding: "0px 10px",
          borderRadius: "20px",
          textAlign: "center",
          display:'flex',
          alignItems:'center',
          justifyContent:"center"
        }}
      >
       <svg width="8" height="8" style={{marginRight:"5px"}} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="4.00012" cy="4" r="3" fill="#FB1D20"/>
</svg>
  {key}
      </Box>
    );
  }

  if (key === "Re-Scheduled") {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.blue.light,
          color: theme.palette.info.main,
          padding: "0px 10px",
          borderRadius: "20px",
          textAlign: "center",
          display:'flex',
          alignItems:'center',
          justifyContent:"center"
        }}
      >
       <svg width="7" height="6" viewBox="0 0 7 6" style={{marginRight:"5px"}} fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="3.49988" cy="3" r="3" fill="#21A6F1"/>
</svg>  {"Rescheduled"}
      </Box>
    );
  }
};

export default useGetAppointmentTags;
