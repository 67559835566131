import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  API_ROUTE_PRESCRIPTIONS_DETAILS,
  API_ROUTE_PRESCRIPTION_PATIENT_DOCUMENTS,
  API_ROUTE_PRESCRIPTION_VIEW_URL,
  API_ROUTE_SAVE_PRESCRIPTION,
  API_ROUTE_GET_PATIENT_TIMELINE,
  API_ROUTE_PRESCRIPTION_SAVE_DOCUMENTS,
  API_ROUTE_PRESCRIPTION_DELETE_DOCUMENT,
  API_ROUTE_TELE_CALL,
  API_ROUTE_FETCH_DOCTOR_SUGGESTIONS,
  API_ROUTE_GET_VITALS_DATA,
} from "../../shared/routes/route.constants";
import { APIClient } from "../../shared/Utils/api-client";


const config = {
  name: "prescription",
};

interface Prescription {
  signedURL: object;
  pastPrescriptions: Array<any>,
  prevPrescriptionDetails: Array<any>,
  prescriptionDetails: Array<any>;
  patientTimeline: Array<any>;
  patientDocuments: Array<any>;
  doctorSuggestions: object;
  rxData: object;
  updateRxData: boolean;
  rePopulateTable: boolean,
  editRxSecMobile: string,
  instantRxData: object;
}
interface Loaders {
  fetchingDoctorTemplates: boolean;
  fetchingDoctorSuggestions: boolean,
  fetchingSignedURL: boolean;
  fetchingPrescriptionDetails: boolean;
  fetchingPatientDocuments: boolean;
  fetchingPatientTimeline: boolean;
  fetchingPatinetTimeline: boolean;
  fileSavePatientDocumentsUrlData: boolean;
  savePrescriptionDocmentDetails: boolean;
}

export const fetchSignedURL: any = createAsyncThunk(
  `${config.name}/fetchSignedURL`,
  async (id: any) => {
    const params = id?.params;
    return await APIClient.get(API_ROUTE_PRESCRIPTION_VIEW_URL(), { params });
  }
);

export const fetchPrescriptionDetails: any = createAsyncThunk(
  `${config.name}/fetchPrescriptionDetails`,
  async (id: any) => {
    const params = id?.params;
    return await APIClient.get(API_ROUTE_PRESCRIPTIONS_DETAILS(), { params });
  }
);

export const fetchPatientDocuments: any = createAsyncThunk(
  `${config.name}/fetchPatientDocuments`,
  async (id: any) => {
    const params = id?.params;
    return await APIClient.get(API_ROUTE_PRESCRIPTION_PATIENT_DOCUMENTS(), {
      params,
    });
  }
);

export const savePrescription: any = createAsyncThunk(
  `${config.name}/savePrescription`,
  async (data: any) => {
    return await APIClient.post(API_ROUTE_SAVE_PRESCRIPTION(), data);
  }
);
export const fetchPatientTimeLine: any = createAsyncThunk(
  `${config.name}/fetchPatientTimeLine`,
  async (id: any) => {
    return await APIClient.get(API_ROUTE_GET_PATIENT_TIMELINE(), {
      params: id?.params,
    });
  }
);

export const savePrescriptionDocuments: any = createAsyncThunk(
  `${config.name}/save-document`,
  async (data: any) => {
    return await APIClient.post(API_ROUTE_PRESCRIPTION_SAVE_DOCUMENTS(), data);
  }
);

export const deletePrescriptionDocument: any = createAsyncThunk(
  `${config.name}/delete-document`,
  async (id: any) => {
    return await APIClient.delete(API_ROUTE_PRESCRIPTION_DELETE_DOCUMENT(id));
  }
);

export const teleCallPatient: any = createAsyncThunk(
  `${config.name}/teleCallPatient`,
  async (data: any) => {
    return await APIClient.post(API_ROUTE_TELE_CALL, data);
  }
);

export const fetchDoctorSuggestions: any = createAsyncThunk(
  `${config.name}/fetchDoctorSuggestions`,
  async () => {
    return await APIClient.get(API_ROUTE_FETCH_DOCTOR_SUGGESTIONS);
  }
);

export const fetchPastVitalsData: any = createAsyncThunk(
  `${config.name}/fetchPastVitalsData`,
  async (params:any) => {
    return await APIClient.get(API_ROUTE_GET_VITALS_DATA(params.patientId,params.accountId,params.limit));
  }
);


//CRUD

export const prescriptionSlice = createSlice({
  name: "prescription",
  initialState: {
    loading: false,
    loaders: {} as Loaders,
    copyData: [] as any,
    showArticleCard: false,
    prescription: {
      updateRxData: false,
      signedURL: {},
      prescriptionDetails: [],
      pastPrescriptions: [],
      prevPrescriptionDetails: [],
      patientTimeline: [],
      patientDocuments: [],
      doctorSuggestions: {},
      rxData: {},
      rePopulateTable: false,
      editRxSecMobile: '',
      instantRxData: {}
    } as Prescription,

    pastVitalsData: {
      shouldShowPastVitals:false as boolean,
      pastVitalsLoader:false as boolean,
      data:[],
      error:null
    },

    error: null,
  },
  reducers: {
    hideError: (state) => {
      state.error = null;
    },
    resetSignedURL: (state) => {
      state.prescription.signedURL = {};
    },
    resetPastPrescriptions: (state) => {
      state.prescription.pastPrescriptions = [];
    },
    setPastPrescriptions: (state, payload) => {
      state.prescription.pastPrescriptions = payload.payload;
    },
    setPrevPrescriptionDetails: (state, payload) => {
      state.prescription.prevPrescriptionDetails = payload.payload
    },
    setCopyData: (state, payload: any) => {
      state.copyData = payload.payload
    },
    setRxData: (state, action: PayloadAction<any[]>) => {
      state.prescription.rxData = action.payload
    },
    setUpdateRxData: (state, action: PayloadAction<boolean>) => {
      state.prescription.updateRxData = action.payload;
    },
    setInstantRxData: (state, payload: any) => {
      state.prescription.instantRxData = payload.payload
    },
    setEditRxSecMobile: (state, action: PayloadAction<string>) => {
      state.prescription.editRxSecMobile = action.payload
    },
    setToggleRepopulateTable: (state, action: PayloadAction<boolean>) => {
      state.prescription.rePopulateTable = action.payload
    },
    setToggleShouldShowPastVitals: (state,action)=>{
      state.pastVitalsData.shouldShowPastVitals = action.payload;
    },
    setShowArtilceCard: (state, action: PayloadAction<boolean>) => {
      state.showArticleCard = action.payload
    }
  },
  extraReducers(builder: any) {
    builder
      .addCase(fetchPastVitalsData.pending, (state: any) => {
        state.pastVitalsData.pastVitalsLoader = true;
      })
      .addCase(fetchPastVitalsData.fulfilled, (state: any, action: any) => {
        state.pastVitalsData.pastVitalsLoader = false;
        state.pastVitalsData.data = action?.payload?.data?.data;
        state.pastVitalsData.error = null;
      })
      .addCase(fetchPastVitalsData.rejected, (state: any, action: any) => {
        state.pastVitalsData.pastVitalsLoader = false;
        state.pastVitalsData.error = action.payload || action.error.message;
      })
      .addCase(fetchSignedURL.pending, (state: any) => {
        state.loaders.fetchingSignedURL = true;
      })
      .addCase(fetchSignedURL.fulfilled, (state: any, action: any) => {
        state.loaders.fetchSignedURL = false;
        state.prescription.signedURL = action?.payload?.data?.data;
      })
      .addCase(fetchSignedURL.rejected, (state: any, action: any) => {
        state.loaders.fetchingSignedURL = false;
        state.prescription.signedURL = {};
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchPrescriptionDetails.pending, (state: any) => {
        state.loaders.fetchingPrescriptionDetails = true;
      })
      .addCase(
        fetchPrescriptionDetails.fulfilled,
        (state: any, action: any) => {
          state.loaders.fetchingPrescriptionDetails = false;
          state.prescription.prescriptionDetails = action.payload?.data?.data;
        }
      )
      .addCase(fetchPrescriptionDetails.rejected, (state: any, action: any) => {
        state.loaders.fetchingPrescriptionDetails = false;
        state.prescription.prescriptionDetails = [];
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchPatientDocuments.pending, (state: any) => {
        state.loaders.fetchingPatientDocuments = true;
      })
      .addCase(fetchPatientDocuments.fulfilled, (state: any, action: any) => {
        state.loaders.fetchingPatientDocuments = false;
        state.prescription.patientDocuments = action.payload?.data;
      })
      .addCase(fetchPatientDocuments.rejected, (state: any, action: any) => {
        state.loaders.fetchingPatientDocuments = false;
        state.prescription.patientDocuments = [];
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchPatientTimeLine.pending, (state: any) => {
        state.loaders.fetchingPatientTimeline = true;
      })
      .addCase(fetchPatientTimeLine.fulfilled, (state: any, action: any) => {
        state.loaders.fetchingPatientTimeline = false;
        state.prescription.patientTimeLine = action.payload?.data?.data;
      })
      .addCase(fetchPatientTimeLine.rejected, (state: any, action: any) => {
        state.loaders.fetchingPatientTimeline = false;
        state.prescription.patientTimeline = [];
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchDoctorSuggestions.pending, (state: any) => {
        state.loaders.fetchingDoctorSuggestions = true;
      })
      .addCase(fetchDoctorSuggestions.fulfilled, (state: any, action: any) => {
        state.loaders.fetchingDoctorSuggestions = false;
        state.prescription.doctorSuggestions = action.payload?.data;
      })
      .addCase(fetchDoctorSuggestions.rejected, (state: any, action: any) => {
        state.loaders.fetchingDoctorSuggestions = false;
        state.prescription.doctorSuggestions = {};
        state.error = action.payload || action.error.message;
      })
      .addCase(savePrescriptionDocuments.pending, (state: any) => {
        state.loaders.savePrescriptionDocmentDetails = true;
      })
      .addCase(
        savePrescriptionDocuments.fulfilled,
        (state: any, action: any) => {
          state.loaders.savePrescriptionDocmentDetails = false;
        }
      )
      .addCase(
        savePrescriptionDocuments.rejected,
        (state: any, action: any) => {
          state.loaders.savePrescriptionDocmentDetails = false;
          state.error = action.payload || action.error.message;
        }
      );
  },
});

export const { hideError, resetSignedURL,setUpdateRxData, setPastPrescriptions, resetPastPrescriptions, setCopyData, setRxData, setInstantRxData, setEditRxSecMobile, setPrevPrescriptionDetails, setToggleRepopulateTable, setShowArtilceCard, setToggleShouldShowPastVitals  } = prescriptionSlice.actions;
export default prescriptionSlice.reducer;
