/* eslint-disable no-unreachable */
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, Grid, Icon, IconButton, InputLabel, Menu, MenuItem, Modal, OutlinedInput, Paper, Popover, Select, Skeleton, Snackbar, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_ADD_SERVICE, ADMIN_AFTERNOON, ADMIN_APPLY, ADMIN_CANCEL, ADMIN_CONSULTATION, ADMIN_CONSULTATION_DETAILS, ADMIN_CONSULTATION_DURATION, ADMIN_COPY_DATE_CAPTION, ADMIN_DAYS, ADMIN_DESCRIPTION, ADMIN_EVENING, ADMIN_FREE_FOLLOW_UP_DURATION, ADMIN_FREE_FOLLOW_UP_NOS, ADMIN_LEAVES_THIS_WEEK, ADMIN_MARK_A_HOLIDAY, ADMIN_MARK_A_LEAVE, ADMIN_MARK_A_LEAVE_CAPTION, ADMIN_MORNING, ADMIN_PRICE, ADMIN_SELECT_DAY_AND_TIME, ADMIN_TYPE, ADMIN_VIEW_ALL_CLINIC, ADMIN_WEEKOFFS } from '../../../../assets/translations/admin';
import CloseIcon from '@mui/icons-material/Close';
import { APIClient } from '../../../../shared/Utils/api-client';
import { API_ROUTE_ADMIN_VALIDATE_SLOTS, API_ROUTE_DOCTOR_DETAILS, API_ROUTE_FETCH_DOCTOR_LIST, API_ROUTE_UPDATE_ONLINEDOC, API_ROUTE_UPDATE_USER, API_ROUTE_USER_DETAILS } from '../../../../shared/routes/route.constants';
import _ from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { fetchAllUsers, resetUsersList } from '../../../../redux/reducers/admin.slice';
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import moment from 'moment';
import dayjs from 'dayjs';

const sections = [
    {
        label:'Clinic Management',
        value:'/admin/clinicmanagement/allclinic'
    },
    {
        label:'User Management',
        value:'/admin/usermanagement'
    },
    {
        label:'Slot Management',
        value:'/admin/slotmanagement'
    },
]

//morning slots
const morning_slot_options = [];
for (let hour = 6; hour <= 11; hour++) {
    for (let minute = 0; minute < 60; minute += 10) {
        let label = `${hour.toString()}:${minute.toString().padStart(2, '0')} am`;
        let value = label;
        if (hour === 12) {
            label = `12:${minute.toString().padStart(2, '0')} pm`;
            value = label;
        }
        morning_slot_options.push({ label, value });
    }
}
// Adding 12:00 pm separately
morning_slot_options.push({ label: '12:00 pm', value: '12:00 pm' });


//afternoon slots
const afternoon_slot_options = []
for (let hour = 12; hour < 18; hour++) {
    for (let minute = 0; minute < 60; minute += 10) {
        let label, value;
        if (hour===12){
            label = `${(hour).toString()}:${minute.toString().padStart(2, '0')} pm`;
            value = `${((hour)).toString()}:${minute.toString().padStart(2, '0')} pm`;
        }
        else if (hour < 18) {
            label = `${(hour % 12).toString()}:${minute.toString().padStart(2, '0')} pm`;
            value = `${((hour) % 12).toString()}:${minute.toString().padStart(2, '0')} pm`;
        } else {
            label = `6:${minute.toString().padStart(2, '0')} pm`;
            value = `6:${minute.toString().padStart(2, '0')} pm`;
        }
        afternoon_slot_options.push({ label, value });
    }
}
// Adding 06:00 pm separately
afternoon_slot_options.push({ label: '6:00 pm', value: '6:00 pm' });

//evening slots
const evening_slot_options = []
for (let hour = 6; hour <= 11; hour++) {
    for (let minute = 0; minute < 60; minute += 10) {
        let label = `${hour.toString()}:${minute.toString().padStart(2, '0')} pm`;
        let value = label;
        if (hour === 12) {
            label = `12:${minute.toString().padStart(2, '0')} am`;
            value = label;
        }
        evening_slot_options.push({ label, value });
    }
}
// Adding 12:00 am separately
evening_slot_options.push({ label: '11:59 pm', value: '11:59 pm' });

const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
]

const initCopyDays = {
    alldays:false,
    monday:false,
    tuesday:false,
    wednesday:false,
    thursday:false,
    friday:false,
    saturday:false,
    sunday:false,
}

const initState = {
    consultation_duration:'',
    duration_type:'Min',
    free_follow_up_number:'',
    free_follow_up_duration:'',
    consultation:'',
    type_of_service:'none',
    description:'',
    type:{
        online:{
            checked:false,
            price:1000,
        },
        in_visit:{
            checked:false,
            price:1000,
        }
    },
    leaves:[],
    online:{
        monday:{
            // morning:[{start:'09:00',end:'10:00'},{start:'11:00',end:'12:00'},],
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        tuesday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        wednesday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        thursday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        friday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        saturday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        sunday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
    },
    offline:{
        monday:{
            // morning:[{start:'09:00',end:'10:00'},{start:'11:00',end:'12:00'},],
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        tuesday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        wednesday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        thursday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        friday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        saturday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
        sunday:{
            morning:[],
            edit_morning:[{start:undefined,end:undefined}],
            afternoon:[],
            edit_afternoon:[{start:undefined,end:undefined}],
            evening:[],
            edit_evening:[{start:undefined,end:undefined}],
            weekoffs:false,
            isEdit:false,
        },
    },
    off_dates:[],
    leaveTime:{
        morning:false,
        afternoon:false,
        evening:false,
        fullday:false
    },
    leaveDate:[dayjs(),dayjs()],
    isLeaveMarked:false,
}

const duration_type_options = [
    {
        label:'min',
        value:'min'
    },
]

const consultation_options = [
    {
        label:'1st Consultation',
        value:'first'
    },
    {
        label:'Follow up',
        value:'followup'
    },
]

const price_options = [300,400,500,600,700,800,900,1000,1100,1200,1300,1400,1500,1600,1700,1800,1900,2000]


const initErrorState = {
    errorField:'',
    errorMsg:'',
    showError:false
}

const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "14px",
    boxShadow: 24,
    p: 2,
  };

const SlotManagement = () => {

  //Hook
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {usersList,loader} = useSelector((state)=>state.admin)

  //State
  const {profile} = useSelector(state=>state.auth)
  const [pathname,setPathname] = useState('');
  const [state,setState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },sessionStorage.getItem('admin_slot_management_data') ? JSON.parse(sessionStorage.getItem('admin_slot_management_data')) : initState)
  const [errorState,setErrorState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },initErrorState)

  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCopyPopper, setOpenCopyPopper] = React.useState(false);

  const [selectedCopyDay, setSelectedCopyDay] = React.useState(null);

  const [copyDays, setCopyDays] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },initCopyDays)

  const [searchParams, setSearchParams] = useSearchParams();
  
  const [firstRender, setFirstRender] = useState(true);

  const [fetchLoader, setFetchLoader] = useState(false);

  const [filteredUserData,setFilteredUserData] = useState({});

  const [loading,setLoading] = useState(false);
  const [loadingOffline,setLoadingOffline] = useState(false);
  const [loadingOnline,setLoadingOnline] = useState(false);

  const [validateSlotsLoader,setValidateSlotsLoader] = useState(false);

  const [scheduledPatientsLoader,setScheduledPatientsLoader] = useState(false);
  const [scheduledPatients, setScheduledPatients] = useState(0);

  const [snackbarState,setSnackbarState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },{
    isSuccess:true,
    open:false,
    message:'Updated',
    color:'green'
  })

  const [docType,setDocType] = useState("online");

  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [leaveLoader, setLeaveLoader] = useState(false);
  const [leaveDate, setLeaveDate] = useState([dayjs(),dayjs()]);

  

  //Effects

  useEffect(()=>{
    
    if(searchParams.get('account_id')!=='' && !usersList.length){
        dispatch(fetchAllUsers({id:searchParams.get('account_id')}))
    }
    return ()=>{
        // dispatch(resetUsersList());
    }
  },[])

  useEffect(()=>{
    if(location.pathname){
        setPathname(location.pathname);
    }
  },[location])

  useEffect(()=>{
    if(!searchParams.get('isEdit') && sessionStorage.getItem('admin_slot_management_data') && firstRender){
        let savedState = JSON.parse(sessionStorage.getItem('admin_slot_management_data'));
        setState(_.cloneDeep(savedState));
        if(savedState.leaveDate?.length){
            setLeaveDate([dayjs(savedState.leaveDate[0],'DD/MM/YYYY'),dayjs(savedState.leaveDate[1],'DD/MM/YYYY')])
        }
        sessionStorage.removeItem('admin_slot_management_data')
        setFirstRender(false);
    }

  },[state])

//   use effect for populating placeholder dropdowns for each day if no slots exist
  useEffect(()=>{
    setState({
        [docType]:{
            ...state[docType],
            monday:{
                ...state[docType].monday,
                edit_morning:!state[docType].monday.morning.length && !state[docType].monday.edit_morning.length ? [{start:undefined,end:undefined}] : state[docType].monday.edit_morning,
                edit_afternoon:!state[docType].monday.afternoon.length && !state[docType].monday.edit_afternoon.length ? [{start:undefined,end:undefined}] : state[docType].monday.edit_afternoon,
                edit_evening:!state[docType].monday.evening.length && !state[docType].monday.edit_evening.length ? [{start:undefined,end:undefined}] : state[docType].monday.edit_evening,
            },
            tuesday:{
                ...state[docType].tuesday,
                edit_morning:!state[docType].tuesday.morning.length && !state[docType].tuesday.edit_morning.length ? [{start:undefined,end:undefined}] : state[docType].tuesday.edit_morning,
                edit_afternoon:!state[docType].tuesday.afternoon.length && !state[docType].tuesday.edit_afternoon.length ? [{start:undefined,end:undefined}] : state[docType].tuesday.edit_afternoon,
                edit_evening:!state[docType].tuesday.evening.length && !state[docType].tuesday.edit_evening.length ? [{start:undefined,end:undefined}] : state[docType].tuesday.edit_evening,
            },
            wednesday:{
                ...state[docType].wednesday,
                edit_morning:!state[docType].wednesday.morning.length && !state[docType].wednesday.edit_morning.length ? [{start:undefined,end:undefined}] : state[docType].wednesday.edit_morning,
                edit_afternoon:!state[docType].wednesday.afternoon.length && !state[docType].wednesday.edit_afternoon.length ? [{start:undefined,end:undefined}] : state[docType].wednesday.edit_afternoon,
                edit_evening:!state[docType].wednesday.evening.length && !state[docType].wednesday.edit_evening.length ? [{start:undefined,end:undefined}] : state[docType].wednesday.edit_evening,
            },
            thursday:{
                ...state[docType].thursday,
                edit_morning:!state[docType].thursday.morning.length && !state[docType].thursday.edit_morning.length ? [{start:undefined,end:undefined}] : state[docType].thursday.edit_morning,
                edit_afternoon:!state[docType].thursday.afternoon.length && !state[docType].thursday.edit_afternoon.length ? [{start:undefined,end:undefined}] : state[docType].thursday.edit_afternoon,
                edit_evening:!state[docType].thursday.evening.length && !state[docType].thursday.edit_evening.length ? [{start:undefined,end:undefined}] : state[docType].thursday.edit_evening,
            },
            friday:{
                ...state[docType].friday,
                edit_morning:!state[docType].friday.morning.length && !state[docType].friday.edit_morning.length ? [{start:undefined,end:undefined}] : state[docType].friday.edit_morning,
                edit_afternoon:!state[docType].friday.afternoon.length && !state[docType].friday.edit_afternoon.length  ? [{start:undefined,end:undefined}] : state[docType].friday.edit_afternoon,
                edit_evening:!state[docType].friday.evening.length && !state[docType].friday.edit_evening.length  ? [{start:undefined,end:undefined}] : state[docType].friday.edit_evening,
            },
            saturday:{
                ...state[docType].saturday,
                edit_morning:!state[docType].saturday.morning.length && !state[docType].saturday.edit_morning.length ? [{start:undefined,end:undefined}] : state[docType].saturday.edit_morning,
                edit_afternoon:!state[docType].saturday.afternoon.length && !state[docType].saturday.edit_afternoon.length ? [{start:undefined,end:undefined}] : state[docType].saturday.edit_afternoon,
                edit_evening:!state[docType].saturday.evening.length && !state[docType].saturday.edit_evening.length ? [{start:undefined,end:undefined}] : state[docType].saturday.edit_evening,
            },
            sunday:{
                ...state[docType].sunday,
                edit_morning:!state[docType].sunday.morning.length && !state[docType].sunday.edit_morning.length ? [{start:undefined,end:undefined}] : state[docType].sunday.edit_morning,
                edit_afternoon:!state[docType].sunday.afternoon.length && !state[docType].sunday.edit_afternoon.length ? [{start:undefined,end:undefined}] : state[docType].sunday.edit_afternoon,
                edit_evening:!state[docType].sunday.evening.length && !state[docType].sunday.edit_evening.length ? [{start:undefined,end:undefined}] : state[docType].sunday.edit_evening,
            }
        }
    });
  },[
        state[docType].monday.morning,
        state[docType].monday.afternoon,
        state[docType].monday.evening,
        state[docType].tuesday.morning,
        state[docType].tuesday.afternoon,
        state[docType].tuesday.evening,
        state[docType].wednesday.morning,
        state[docType].wednesday.afternoon,
        state[docType].wednesday.evening,
        state[docType].thursday.morning,
        state[docType].thursday.afternoon,
        state[docType].thursday.evening,
        state[docType].friday.morning,
        state[docType].friday.afternoon,
        state[docType].friday.evening,
        state[docType].saturday.morning,
        state[docType].saturday.afternoon,
        state[docType].saturday.evening,
        state[docType].sunday.morning,
        state[docType].sunday.afternoon,
        state[docType].sunday.evening,
    ]
  )

  useEffect(()=>{
    if(selectedCopyDay){
        setCopyDays({
            ...initCopyDays,
            [selectedCopyDay]:true
        })
    }
  },[selectedCopyDay])

  useEffect(()=>{
    days.forEach((day)=>{
        if(day!==selectedCopyDay){
            setCopyDays({
                [day]:copyDays.alldays
            })
        }
    })
  },[copyDays.alldays])

  useEffect(()=>{
    
    if(searchParams.has('isEdit') && usersList.length){
        setFetchLoader(true);
        FetchUserDetails(searchParams.get("cognito_user_sub"),searchParams.get("account_id"))
     }
  },[usersList])

  useEffect(()=>{
    if(tabValue===0){
        setDocType('online')
    }
    else{
        setDocType('offline')
    }
  },[tabValue])

  useEffect(()=>{
    findScheduledPatients();
  },[state.leaveDate])

  //Functions

  const handleTabChange = (e,newValue)=>{
    setTabValue(newValue);
  }

  const handleClickCopyPopper = (event, day) => {
    setSelectedCopyDay(day);
    setCopyDays({[day]:true});
    setAnchorEl(event.currentTarget);
    setOpenCopyPopper(true);
  };

  const handleCloseCopyPopper = () => {
    setAnchorEl(null);
    setOpenCopyPopper(false);
    setCopyDays(initCopyDays);

  };

  const handleCopyDays = () => {

    let newState = {}
    days.forEach((day)=>{
        if(copyDays[day]){
            newState[day]={
                weekoffs:false,
                morning:state[docType][selectedCopyDay].morning,
                edit_morning:state[docType][selectedCopyDay].edit_morning,
                afternoon:state[docType][selectedCopyDay].afternoon,
                edit_afternoon:state[docType][selectedCopyDay].edit_afternoon,
                evening:state[docType][selectedCopyDay].evening,
                edit_evening:state[docType][selectedCopyDay].edit_evening,
                isEdit:state[docType][selectedCopyDay].isEdit,
            }
        }
    })

    setState({
        ...state,
        [docType]:{
            ...state[docType],
            ...newState
        }
    })

    handleCloseCopyPopper();
  }

  const handleChangeWeekOff  = (day)=>{
    setState({
        [docType]:{
            ...state[docType],
            [day]:{
                ...state[docType][day],
                isEdit:false,
                weekoffs:!state[docType][day].weekoffs
            }
        }
    })
  }

  const handleClickBack = ()=>{
    if(searchParams.get('isEdit')){
        navigate({
            pathname: "/admin/usermanagement",
            search: createSearchParams({
                isEdit:true,
                account_id: searchParams.get('account_id'),
                account_name: searchParams.get('account_name'),
                user_id: searchParams.get('user_id'),
                cognito_user_sub:searchParams.get('cognito_user_sub'),
            }).toString()
        });
    }else{
        navigate({
            pathname: "/admin/usermanagement",
            search: createSearchParams({
                account_id: searchParams.get('account_id'),
                account_name: searchParams.get('account_name'),
            }).toString()
        });
    }
    
    return;
  }

  const handleSave = async ()=>{

    try {
        setValidateSlotsLoader(true);

    let payload = {
        user_payload: {
            slot_type:{},
            slots:{}
        }, 
        onlinedoc_payload: {
            slot_type:{},
            slots:{}
        },

        is_online: filteredUserData.is_online,
        is_consulting: filteredUserData.is_consulting,
        interval: state.consultation_duration,
        account_id: searchParams.get('account_id'),
        superiority: filteredUserData.superiority,
        day_wise_consult_fee: filteredUserData.day_wise_consult_fee,
        cognito_user_sub: searchParams.get('cognito_user_sub'),
        hide_doctor_from_portal: filteredUserData.hide_doctor_from_portal,

        consultation_duration:state.consultation_duration,
        free_follow_days:state.free_follow_up_number,
        free_follow_duration:state.free_follow_up_duration,
        service_name:state.consultation,
        service_type:state.type_of_service,
        service_description:state.description,
    }

    let counter = 1;

    for(const [day,slots] of Object.entries(state.offline)){
        let morningSlot = state?.offline[day]?.morning;
        let afternoonSlot = state?.offline[day]?.afternoon;
        let eveningSlot = state?.offline[day]?.evening;

        if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || state?.offline[day]?.weekoffs){
            continue;
        }
        else{
            payload.user_payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
            payload.user_payload.slots[`slot${counter}`] = {
                ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
            }
            counter++;
        }

    }

    let counter2 = 1;
            
    for(const [day,slots] of Object.entries(state.online)){
        let morningSlot = state?.online[day]?.morning;
        let afternoonSlot = state?.online[day]?.afternoon;
        let eveningSlot = state?.online[day]?.evening;

        if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || state?.online[day]?.weekoffs){
            continue;
        }
        else{
            payload.onlinedoc_payload.slot_type[`slot${counter2}`] = [day[0].toUpperCase()+day.slice(1)]
            payload.onlinedoc_payload.slots[`slot${counter2}`] = {
                ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
            }
            counter2++;
        }

    }

    await APIClient.post(API_ROUTE_ADMIN_VALIDATE_SLOTS(),payload)
    .then(async (res)=>{
        setValidateSlotsLoader(false);
        if(!searchParams.get('isEdit')){
            sessionStorage.setItem('admin_slot_management_data',JSON.stringify(state))
            navigate({
                pathname: "/admin/usermanagement",
                search: createSearchParams({
                    account_id: searchParams.get('account_id'),
                    account_name: searchParams.get('account_name'),
                }).toString()
            });
            return;
        }else{
    
            
    
            let offDates = [];
    
            if(state.isLeaveMarked){
                let startdate = moment(leaveDate[0].format('DD/MM/YYYY'),'DD/MM/YYYY')
                let enddate = moment(leaveDate[1].format('DD/MM/YYYY'),'DD/MM/YYYY')
        
        
                while(startdate.isSameOrBefore(enddate)){
                    offDates.push({
                        date:startdate.format('YYYY-MM-DD'),
                        ...(!state.leaveTime.fullday && (state.leaveTime.morning || state.leaveTime.afternoon || state.leaveTime.evening) && {slots:Object.keys(state.leaveTime).filter(elem=>state.leaveTime[elem])})
                    });
                    startdate.add(1,'days')
                }
            }
            
    
            if(state.type.online.checked && state.type.in_visit.checked){
                //online and offline doctor
    
                console.log("update online offline doc");
    
                setLoadingOffline(true);
                setLoadingOnline(true);
    
                let payload = {
    
                    slot_type:{},
                    slots:{},
            
                    is_online: filteredUserData.is_online,
                    is_consulting: filteredUserData.is_consulting,
                    interval: state.consultation_duration,
                    account_id: searchParams.get('account_id'),
                    superiority: filteredUserData.superiority,
                    use_third_party: filteredUserData.false,
                    off_dates:[...offDates,...(state?.leaves ? state.leaves : [])],
                    day_wise_consult_fee: filteredUserData.day_wise_consult_fee,
                    cognito_user_sub: searchParams.get('cognito_user_sub'),
                    hide_doctor_from_portal: filteredUserData.hide_doctor_from_portal,
    
                    consultation_duration:state.consultation_duration,
                    free_follow_days:state.free_follow_up_number,
                    free_follow_duration:state.free_follow_up_duration,
                    service_name:state.consultation,
                    service_type:state.type_of_service,
                    service_description:state.description,
                    // leaves:Array.isArray(state.leaves) ?  state.leaves?.join(',') : state.leaves,
    
                    consultation_fee:{
                        online:state.type.online.price,
                        offline:state.type.in_visit.price
                    }
                };
            
                let counter = 1;
            
                for(const [day,slots] of Object.entries(state.offline)){
                    let morningSlot = state?.offline[day]?.morning;
                    let afternoonSlot = state?.offline[day]?.afternoon;
                    let eveningSlot = state?.offline[day]?.evening;
            
                    if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || state?.offline[day]?.weekoffs){
                        continue;
                    }
                    else{
                        payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
                        payload.slots[`slot${counter}`] = {
                            ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                            ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                            ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                        }
                        counter++;
                    }
            
                }

                
    
                await APIClient.put(API_ROUTE_UPDATE_USER(searchParams.get('user_id'),searchParams.get('account_id')),{
                    // slot_type:payload.slot_type,
                    // slots:payload.slots,
                    // account_id: searchParams.get("account_id"),
                    ...payload
                }).then(res=>{
                    setLoadingOffline(false);
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:'Updated successfully',
                        color:'green'
                    })
                }).catch(err=>{
                    console.log("error updating offline slots",err);
                    setLoadingOffline(false);
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:err && typeof err !== 'object' ? err :  'Unable to update',
                        color:'red'
                    })
                    // setErrorState({
                    //     errorField:'',
                    //     errorMsg:'Failed to update offline slots',
                    //     showError:true
                    // })
                })
    
    
                let payloadOnline = {
    
                    slot_type:{},
                    slots:{},
            
                    is_online: filteredUserData.is_online,
                    is_consulting: filteredUserData.is_consulting,
                    interval: state.consultation_duration,
                    account_id: searchParams.get('account_id'),
                    superiority: filteredUserData.superiority,
                    use_third_party: filteredUserData.false,
                    day_wise_consult_fee: filteredUserData.day_wise_consult_fee,
                    cognito_user_sub: searchParams.get('cognito_user_sub'),
                    hide_doctor_from_portal: filteredUserData.hide_doctor_from_portal,
    
                    consultation_duration:state.consultation_duration,
                    free_follow_days:state.free_follow_up_number,
                    free_follow_duration:state.free_follow_up_duration,
                    service_name:state.consultation,
                    service_type:state.type_of_service,
                    service_description:state.description,
                    // leaves:Array.isArray(state.leaves) ?  state.leaves?.join(',') : state.leaves,
    
                    consultation_fee:{
                        online:state.type.online.price,
                        offline:state.type.in_visit.price
                    }
                };
            
                let counter2 = 1;
            
                for(const [day,slots] of Object.entries(state.online)){
                    let morningSlot = state?.online[day]?.morning;
                    let afternoonSlot = state?.online[day]?.afternoon;
                    let eveningSlot = state?.online[day]?.evening;
            
                    if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || state?.online[day]?.weekoffs){
                        continue;
                    }
                    else{
                        payloadOnline.slot_type[`slot${counter2}`] = [day[0].toUpperCase()+day.slice(1)]
                        payloadOnline.slots[`slot${counter2}`] = {
                            ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                            ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                            ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                        }
                        counter2++;
                    }
            
                }
    
    
                await APIClient.put(API_ROUTE_UPDATE_ONLINEDOC(),payloadOnline).then(res=>{
                    setLoadingOnline(false);
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:'Updated successfully',
                        color:'green'
                    })
                }).catch(err=>{
                    setLoadingOnline(false);
                    console.log("error updating online slots",err);
    
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:err && typeof err !== 'object' ? err :  'Unable to update',
                        color:'red'
                    })
                    // setErrorState({
                    //     errorField:'',
                    //     errorMsg:'Failed to update online slots',
                    //     showError:true
                    // })
                })
                
            }
            else if(state.type.in_visit.checked){
                //offline doctor
                console.log("update offline doc")
    
                setLoadingOffline(true);
    
                let payload = {
    
                    slot_type:{},
                    slots:{},
            
                    is_online: filteredUserData.is_online,
                    is_consulting: filteredUserData.is_consulting,
                    interval: state.consultation_duration,
                    account_id: searchParams.get('account_id'),
                    superiority: filteredUserData.superiority,
                    use_third_party: filteredUserData.false,
                    off_dates:[...offDates,...(state?.leaves ? state.leaves : [])],
                    day_wise_consult_fee: filteredUserData.day_wise_consult_fee,
                    cognito_user_sub: searchParams.get('cognito_user_sub'),
                    hide_doctor_from_portal: filteredUserData.hide_doctor_from_portal,
    
                    consultation_duration:state.consultation_duration,
                    free_follow_days:state.free_follow_up_number,
                    free_follow_duration:state.free_follow_up_duration,
                    service_name:state.consultation,
                    service_type:state.type_of_service,
                    service_description:state.description,
                    // leaves:Array.isArray(state.leaves) ?  state.leaves?.join(',') : state.leaves,
    
                    consultation_fee:{
                        online:state.type.online.price,
                        offline:state.type.in_visit.price
                    }
                };
            
                let counter = 1;
            
                for(const [day,slots] of Object.entries(state.offline)){
                    let morningSlot = state?.offline[day]?.morning;
                    let afternoonSlot = state?.offline[day]?.afternoon;
                    let eveningSlot = state?.offline[day]?.evening;
            
                    if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || state?.offline[day]?.weekoffs){
                        continue;
                    }
                    else{
                        payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
                        payload.slots[`slot${counter}`] = {
                            ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                            ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                            ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                        }
                        counter++;
                    }
            
                }
    
                await APIClient.put(API_ROUTE_UPDATE_USER(searchParams.get('user_id'),searchParams.get('account_id')),{
                    // slot_type:payload.slot_type,
                    // slots:payload.slots,
                    // account_id: searchParams.get("account_id"),
                    ...payload
                }).then(res=>{
                    setLoadingOffline(false);
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:'Updated successfully',
                        color:'green'
                    })
                }).catch(err=>{
                    console.log("error updating offline slots",err);
                    setLoadingOffline(false);
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:err && typeof err !== 'object' ? err :  'Unable to update',
                        color:'red'
                    })
                    // setErrorState({
                    //     errorField:'',
                    //     errorMsg:'Failed to update offline slots',
                    //     showError:true
                    // })
                })
    
                
            }else{
                //online doctor
                console.log("update online doc")
    
                setLoadingOnline(true);
    
                let payload = {
    
                    slot_type:{},
                    slots:{},
            
                    is_online: filteredUserData.is_online,
                    is_consulting: filteredUserData.is_consulting,
                    interval: state.consultation_duration,
                    account_id: searchParams.get('account_id'),
                    superiority: filteredUserData.superiority,
                    use_third_party: filteredUserData.false,
                    off_dates:[...offDates,...(state?.leaves ? state.leaves : [])],
                    day_wise_consult_fee: filteredUserData.day_wise_consult_fee,
                    cognito_user_sub: searchParams.get('cognito_user_sub'),
                    hide_doctor_from_portal: filteredUserData.hide_doctor_from_portal,
    
                    consultation_duration:state.consultation_duration,
                    free_follow_days:state.free_follow_up_number,
                    free_follow_duration:state.free_follow_up_duration,
                    service_name:state.consultation,
                    service_type:state.type_of_service,
                    service_description:state.description,
                    // leaves:Array.isArray(state.leaves) ?  state.leaves?.join(',') : state.leaves,
    
                    consultation_fee:{
                        online:state.type.online.price,
                        offline:state.type.in_visit.price
                    }
                };
            
                let counter = 1;
            
                for(const [day,slots] of Object.entries(state.offline)){
                    let morningSlot = state?.offline[day]?.morning;
                    let afternoonSlot = state?.offline[day]?.afternoon;
                    let eveningSlot = state?.offline[day]?.evening;
            
                    if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || state?.offline[day]?.weekoffs){
                        continue;
                    }
                    else{
                        payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
                        payload.slots[`slot${counter}`] = {
                            ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                            ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                            ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                        }
                        counter++;
                    }
            
                }
    
                await APIClient.put(API_ROUTE_UPDATE_USER(searchParams.get('user_id'),searchParams.get('account_id')),{
                    // slot_type:payload.slot_type,
                    // slots:payload.slots,
                    // account_id: searchParams.get("account_id"),
                    ...payload
                }).then(async res=>{
                    
    
                    let payload = {
    
                        slot_type:{},
                        slots:{},
                
                        is_online: filteredUserData.is_online,
                        is_consulting: filteredUserData.is_consulting,
                        interval: state.consultation_duration,
                        account_id: searchParams.get('account_id'),
                        superiority: filteredUserData.superiority,
                        use_third_party: filteredUserData.false,
                        off_dates:[...offDates,...(state?.leaves ? state.leaves : [])],
                        day_wise_consult_fee: filteredUserData.day_wise_consult_fee,
                        cognito_user_sub: searchParams.get('cognito_user_sub'),
                        hide_doctor_from_portal: filteredUserData.hide_doctor_from_portal,
    
                        consultation_duration:state.consultation_duration,
                        free_follow_days:state.free_follow_up_number,
                        free_follow_duration:state.free_follow_up_duration,
                        service_name:state.consultation,
                        service_type:state.type_of_service,
                        service_description:state.description,
                        // leaves:Array.isArray(state.leaves) ?  state.leaves?.join(',') : state.leaves,
    
                        consultation_fee:{
                            online:state.type.online.price,
                            offline:state.type.in_visit.price
                        }
                    };
                
                    let counter = 1;
                
                    for(const [day,slots] of Object.entries(state.online)){
                        let morningSlot = state?.online[day]?.morning;
                        let afternoonSlot = state?.online[day]?.afternoon;
                        let eveningSlot = state?.online[day]?.evening;
                
                        if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || state?.online[day]?.weekoffs){
                            continue;
                        }
                        else{
                            payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
                            payload.slots[`slot${counter}`] = {
                                ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                                ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                                ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                            }
                            counter++;
                        }
                
                    }
                
                    await APIClient.put(API_ROUTE_UPDATE_ONLINEDOC(),payload).then(res=>{
    
                        setLoadingOnline(false);
                        setSnackbarState({
                            isSuccess:true,
                            open:true,
                            message:'Updated successfully',
                            color:'green'
                        })
                    }).catch(err=>{
                        setLoadingOnline(false);
                        console.log("error updating online slots",err);
    
                        setSnackbarState({
                            isSuccess:true,
                            open:true,
                            message:err && typeof err !== 'object' ? err :  'Unable to update',
                            color:'red'
                        })
                        // setErrorState({
                        //     errorField:'',
                        //     errorMsg:'Failed to update online slots',
                        //     showError:true
                        // })
                    })
                }).catch(err=>{
                    console.log("error updating offline slots",err);
                    setLoadingOffline(false);
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:err && typeof err !== 'object' ? err :  'Unable to update',
                        color:'red'
                    })
                    // setErrorState({
                    //     errorField:'',
                    //     errorMsg:'Failed to update offline slots',
                    //     showError:true
                    // })
                })
    
                //---------------
    
                
            }
    
        }
    }).catch(err=>{
        setValidateSlotsLoader(false);
        setSnackbarState({
            isSuccess:true,
            open:true,
            message:err && typeof err !== 'object' ? err : 'Slots are invalid',
            color:'red'
        })
    })

    return;
    } catch (error) {
        console.log(error)
    }
  }

  const FetchUserDetails = async (cognito_user_sub,account_id)=>{

    setFetchLoader(true);

    if(!usersList.length){
        return;
    }

    let filteredUser;
    let result;
    
    filteredUser = usersList.filter((elem)=>{
        return elem.id == searchParams.get('user_id')
    })

    await APIClient.get(API_ROUTE_USER_DETAILS(cognito_user_sub,account_id)).then(async (res)=>{
        result = res.data;
    }).catch(err=>{
        console.log("error fetching user details",err)
        setSnackbarState({
            isSuccess:true,
            open:true,
            message:err && typeof err !== 'object' ? err :  'Unable to fetch details',
            color:'red'
        })
    })
    
    console.log("result",result)
    console.log("filteredUser",filteredUser)

    let isOnline = false;
    let isOffline = false;

    //get role and populate slots
    if(filteredUser.length){

        //for populating offline slots
        if(filteredUser[0]?.slot_type){
            if(Object.keys(filteredUser[0].slot_type)?.length){

                isOffline = true;
                
                let tempObj = {...state.offline};

                for(const [slot_number,days] of Object.entries(filteredUser[0].slot_type)){

                    
                    days.forEach(day=>{
                        let d = day.toLowerCase()
                        tempObj[d] = {
                            ...state.offline[d],
                            morning:filteredUser[0]?.slots[slot_number]?.morning?.starttime && filteredUser[0]?.slots[slot_number]?.morning?.endtime ? [...state.offline[d].morning,{start: filteredUser[0]?.slots[slot_number]?.morning?.starttime, end: filteredUser[0]?.slots[slot_number]?.morning?.endtime}] : [],
                            afternoon:filteredUser[0]?.slots[slot_number]?.afternoon?.starttime && filteredUser[0]?.slots[slot_number]?.afternoon?.endtime ? [...state.offline[d].afternoon,{start: filteredUser[0]?.slots[slot_number]?.afternoon?.starttime, end: filteredUser[0]?.slots[slot_number]?.afternoon?.endtime}] : [],
                            evening:filteredUser[0]?.slots[slot_number]?.evening?.starttime && filteredUser[0]?.slots[slot_number]?.evening?.endtime ? [...state.offline[d].evening,{start: filteredUser[0]?.slots[slot_number]?.evening?.starttime, end: filteredUser[0]?.slots[slot_number]?.evening?.endtime}] : [],
                        } 
                    })
                    
                }
                setState({
                    offline:{
                        ...state.offline,
                        ...tempObj
                    }
                })

            }
        }

        //for populating online slots
        if(result){
            if(result.slot_type && Object.keys(result.slot_type)?.length){
                
                isOnline = true;

                let tempObj = {...state.online};

                for(const [slot_number,days] of Object.entries(result.slot_type)){

                    
        
                    days.forEach(day=>{
                        let d = day.toLowerCase()
                        tempObj[d] = {
                            ...state.online[d],
                            morning:result?.slots[slot_number]?.morning?.starttime && result?.slots[slot_number]?.morning?.endtime ? [...state.online[d].morning,{start: result?.slots[slot_number]?.morning?.starttime, end: result?.slots[slot_number]?.morning?.endtime}] : [],
                            afternoon:result?.slots[slot_number]?.afternoon?.starttime && result?.slots[slot_number]?.afternoon?.endtime ? [...state.online[d].afternoon,{start: result?.slots[slot_number]?.afternoon?.starttime, end: result?.slots[slot_number]?.afternoon?.endtime}] : [],
                            evening:result?.slots[slot_number]?.evening?.starttime && result?.slots[slot_number]?.evening?.endtime ? [...state.online[d].evening,{start: result?.slots[slot_number]?.evening?.starttime, end: result?.slots[slot_number]?.evening?.endtime}] : [],
                        } 
                    })
                    
                }

                setState({
                    online:{
                        ...state.online,
                        ...tempObj
                    }
                })
            }
        }
        

        setFilteredUserData({
            ...(filteredUser[0] ? filteredUser[0] : {}),
            ...(result ? result : {}),
        })

    }

    //populate rest of data for slotstate
    setState({
        consultation_duration:filteredUser[0]?.consultation_duration || result?.consultation_duration,
        duration_type:"min",
        free_follow_up_number:filteredUser[0]?.free_follow_days ||  result?.free_follow_days,
        free_follow_up_duration:filteredUser[0]?.free_follow_duration||  result?.free_follow_duration,
        consultation:filteredUser[0]?.service_name ||  result?.service_name,
        type_of_service:filteredUser[0]?.service_type || result?.service_type,
        description:filteredUser[0]?.service_description || result?.service_description,
        // leaves:filteredUser[0]?.leaves?.split(',') ||  result?.leaves?.split(','),
        leaves:Array.isArray(filteredUser[0]?.off_dates) ? filteredUser[0]?.off_dates : [],
    })

    setState({
        type:{
            online:{
                checked:isOnline,
                price:typeof result?.consultation_fee ==='object' ? result?.consultation_fee?.online || 1000 : 1000,
            },
            in_visit:{
                checked:isOffline,
                price:typeof result?.consultation_fee ==='object' ? result?.consultation_fee?.offline || 1000 : 1000,
            }
        }
    })
    
    setFetchLoader(false);
    
  }

  const handleMarkALeave = async ()=>{
    setState({isLeaveMarked:true});
    setOpenLeaveModal(false)
    
    return;
  }

  const handleRevokeLeave = (index)=>{
    setState({
        leaves:state.leaves.filter((elem,i)=>i!==index)
    })
    return;
  }

  const isPastDate = (time,selectedDate) => {

    if(selectedDate.format('DD/MM/YYYY') !== dayjs().format('DD/MM/YYYY')) return false

    const currDate = selectedDate.format('DD/MM/YYYY') == dayjs().format('DD/MM/YYYY') ? dayjs() : selectedDate;
    const morningTime = dayjs().set('hour',6).set('minute',0).set('second',0);
    const afternoonTime = dayjs().set('hour',12).set('minute',0).set('second',0);
    const eveningTime = dayjs().set('hour',18).set('minute',0).set('second',0);

    switch(time){
        case 'morning':
            return currDate.isAfter(morningTime)
        case 'afternoon':
            return currDate.isAfter(afternoonTime)
        case 'evening':
            return currDate.isAfter(eveningTime)
        default:
            return false;
    }
  }

  const findScheduledPatients = async ()=>{
    setScheduledPatientsLoader(true);

    try {
        await APIClient.get(
            `appointment/appointments/countbyrange/${
              searchParams.get('cognito_user_sub')
            }`,
            {
              params: {
                start_date: leaveDate[0].format("YYYY-MM-DD"),
                end_date: leaveDate[1].format("YYYY-MM-DD"),
                appt_status: "confirmed",
                show_next_appt: true,
                account_id: searchParams.get('account_id'),
                status: "Scheduled",
              },
            },
          ).then((res) => {
            if (res?.status === 200) {
              setScheduledPatients(res?.data?.count_scheduled)
              setScheduledPatientsLoader(false);
            }
          }).catch((err)=>{
            setScheduledPatients('Unable to find ')
            setScheduledPatientsLoader(false);
          })
    } catch (error) {
        console.log("error in fetching scheduled patient count",error)
    }

  }

  const renderUnsavedLeaves = () => {
    let offDates = [];
    
    if(state.isLeaveMarked){
        let startdate = moment(leaveDate[0].format('DD/MM/YYYY'),'DD/MM/YYYY')
        let enddate = moment(leaveDate[1].format('DD/MM/YYYY'),'DD/MM/YYYY')


        while(startdate.isSameOrBefore(enddate)){
            offDates.push({
                date:startdate.format('YYYY-MM-DD'),
                ...(!state.leaveTime.fullday && (state.leaveTime.morning || state.leaveTime.afternoon || state.leaveTime.evening) && {slots:Object.keys(state.leaveTime).filter(elem=>state.leaveTime[elem])})
            });
            startdate.add(1,'days')
        }
    }

    return (
        <>
            { (Array.isArray(offDates) && offDates?.length) ? offDates?.map((l,i)=>{
                return (
                    <Box display={'flex'} gap={1} alignItems={'center'}>
                        <Box
                            component={'img'}
                            src={'/images/calender_icon21.svg'}
                        />
                        <Typography variant='subtitle2' color={theme.palette.white.greyText}>{l?.date ? dayjs(l.date,'YYYY-MM-DD').format('dddd, DD MMMM YYYY') + " " + (l?.slots ? `(${l?.slots?.map(s=>s[0]?.toUpperCase()+s.slice(1))?.join(', ')})` : '') : l?.date}</Typography>
                        {/* <Button variant='outlined' color='red' onClick={()=>{handleRevokeLeave(i)}}><Typography variant='caption'>Revoke</Typography></Button> */}
                    </Box>
                )
            }) : '-'}
        </>
    )
  }

  return (
    <>
      {isMobileScreen ? (
        <>
          
        </>
      ) : (
        <Box height={"100%"} width={"100%"} zIndex={100} position={"absolute"}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                ContentProps={{ sx: { background: snackbarState.isSuccess?snackbarState.color:"red", marginTop: "40px" } }}
                open={snackbarState.isSuccess?snackbarState.open:errorState.showError}
                onClose={() => snackbarState.isSuccess? setSnackbarState({open:false}) : setErrorState({showError:false})}
                autoHideDuration={6000}
                message={snackbarState.isSuccess?snackbarState.message:errorState.errorMsg}
            />


          <Box >
            <Grid container height={{xs:"100%",md:`calc(100vh - 42px)`}} width={{xs:"100%",md:`calc(100vw - 60px)`}} spacing={4}>
              
              {/* Left Panel */}
              <Grid
                item
                height="100%"
                xs={3.3}
                
              >
                    <Box 
                        width={'100%'} 
                        height={'100%'}
                        sx={{
                            // boxShadow: "1px 1px 4px 2px rgba(0,0,0,0.2)",
                            backgroundColor: "#ffffff",
                            borderRadius:'0px 8px 0px 0px'
                        }}
                    >
                        {
                            sections.map((elem)=>{
                                return (
                                    <Box 
                                        key={elem.label}
                                        width={'100%'} 
                                        boxSizing={'border-box'}
                                        paddingX={4} 
                                        paddingY={2} 
                                        display={'flex'} 
                                        justifyContent={'space-between'} 
                                        alignItems={'center'}
                                        onClick={()=>{
                                            // navigate(elem.value)
                                        }}
                                        sx={{
                                            cursor:'pointer'
                                        }}
                                    >
                                        <Typography 
                                            variant='subtitle2' 
                                            color={pathname.includes(elem.value) ? theme.palette.primary.main : 'black'}
                                            fontWeight={pathname.includes(elem.value) ? "bold" : 'regular'}
                                        >
                                            {elem.label}
                                        </Typography>
                                        {
                                            pathname.includes(elem.value) && (
                                                <ArrowForwardIosOutlinedIcon fontSize='small' sx={{color:theme.palette.primary.main}}/>
                                            )
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
              </Grid>


              {/* Right Panel */}
              <Grid
                item
                height="100%"
                width={'100%'}
                xs
                spacing={10}
                style={{overflowX:'auto'}}
              >
                <Grid
                    container
                    height={'100%'}
                    spacing={2}
                    direction={'column'}
                    width={'100%'}
                >
                    <Grid
                        item
                        xs
                        height={'10%'}
                        width={'100%'}
                    >
                        {fetchLoader || loader ? (
                            <Box 
                                width={'100%'} 
                                height={'100%'}
                                sx={{
                                    backgroundColor: "#ffffff",
                                    boxSizing:'border-box',
                                    flex:1,
                                    borderRadius:'8px',
                                    overflow:'hidden',
                                    position:'relative',
                                    padding:3
                                }}
                            >
                                <Skeleton animation={'wave'}/>
                                <Skeleton animation={'wave'}/>
                                <Skeleton animation={'wave'}/>
                                <Skeleton animation={'wave'}/>
                            </Box>
                        ) : (
                            <Box 
                                width={'100%'} 
                                height={'100%'}
                                sx={{
                                    backgroundColor: "#ffffff",
                                    boxSizing:'border-box',
                                    flex:1,
                                    borderRadius:'8px',
                                    overflow:'hidden',
                                    position:'relative'
                                }}
                            > 
                                {/* form */}
                                <Box height={'100%'} width={'100%'} display={'flex'} flexDirection={'column'} sx={{boxSizing:'border-box'}}  overflow={'auto'} paddingX={0} paddingTop={0} paddingBottom={10}>
                                    
                                    <Box width={'100%'} px={2} pt={3} display={'flex'} justifyContent={'space-between'}>
                                        <Box display={'flex'} gap={0} alignItems={'center'}>
                                            <IconButton
                                                onClick={handleClickBack}
                                            >
                                                <ArrowBackIcon/>
                                            </IconButton>
                                            <Typography variant='subtitle1' fontWeight={'bold'}>{searchParams.get('isEdit') ? 'Edit User' : "Go Back"}</Typography>
                                        </Box>

                                        <Box>
                                            <LoadingButton loading={loading || loadingOffline || loadingOnline || validateSlotsLoader} variant='contained' onClick={handleSave} sx={{width:'100px',boxShadow:'none'}}>
                                                {"Save"}
                                            </LoadingButton>
                                        </Box>
                                    </Box>

                                    {/* consultation details */}
                                    <Box width={'100%'} p={3} display={'flex'} flexDirection={'column'} gap={2}>
                                        {/* title */}
                                        <Box display={'flex'} gap={1} alignItems={'center'}>
                                            <Box
                                                component={'img'}
                                                src='/images/consultation_details_icon.svg'
                                            />
                                            <Typography color={theme.palette.primary.main} textTransform={'uppercase'}>{'Consultation Details'}</Typography>
                                        </Box>

                                        {/* inputs */}
                                        <Box display={'flex'} flexDirection={'column'} gap={2} width={"100%"}>
                                            <Box display={'flex'} gap={3} width={"100%"}>
                                                <TextField
                                                    label={ADMIN_CONSULTATION_DURATION['eng']}
                                                    type='text'
                                                    value={state.consultation_duration}
                                                    onChange={(e)=>{
                                                        let val = e.target.value;
                                                        const onlyNums = val.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                        );
                                                        if(onlyNums<0) return;
                                                        setState({consultation_duration:onlyNums})
                                                    }}
                                                    sx={{
                                                        flex:1
                                                    }}
                                                />
                                                <TextField
                                                    disabled={true}
                                                    value={state.duration_type}
                                                    onChange={(e)=>{
                                                        setState({duration_type:e.target.value})
                                                    }}
                                                    sx={{
                                                        flex:1
                                                    }}
                                                />
                                                {/* <FormControl fullWidth sx={{flex:1}}>
                                                    <Select
                                                        value={state.duration_type}
                                                        onChange={(e)=>{
                                                            setState({duration_type:e.target.value})
                                                        }}
                                                        sx={{
                                                            width:'50%'
                                                        }}
                                                    >
                                                        {duration_type_options.map(elem=>{
                                                            return <MenuItem value={elem.value}>{elem.label}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl> */}
                                            </Box>
                                            <Box display={'flex'} gap={3} width={"100%"}>
                                                <TextField
                                                    label={ADMIN_FREE_FOLLOW_UP_NOS['eng']}
                                                    value={state.free_follow_up_duration}
                                                    type='text'
                                                    onChange={(e)=>{
                                                        let val = e.target.value;
                                                        const onlyNums = val.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                        );
                                                        if(onlyNums<0 || onlyNums>5) return;
                                                        
                                                        setState({free_follow_up_duration:onlyNums})
                                                    }}
                                                    sx={{
                                                        flex:1
                                                    }}
                                                />
                                                <TextField
                                                    type='text'
                                                    label={ADMIN_FREE_FOLLOW_UP_DURATION['eng']}
                                                    value={state.free_follow_up_number}
                                                    onChange={(e)=>{
                                                        let val = e.target.value;
                                                        const onlyNums = val.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                        );
                                                        if(onlyNums<0) return;
                                                        setState({free_follow_up_number:onlyNums})
                                                    }}
                                                    sx={{
                                                        flex:1
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>

                                    {/* add service */}
                                    <Box width={'100%'} p={3} pt={0} display={'flex'} flexDirection={'column'} gap={2}>
                                        {/* title */}
                                        <Box display={'flex'} gap={1} alignItems={'center'}>
                                            <Box
                                                component={'img'}
                                                src='/images/add_service_icon.svg'
                                            />
                                            <Typography color={theme.palette.primary.main} textTransform={'uppercase'}>{ADMIN_ADD_SERVICE['eng']}</Typography>
                                        </Box>

                                        {/* inputs */}
                                        <Box display={'flex'} gap={3} width={"100%"}>
                                            <Box display={'flex'} flexDirection={"column"} gap={2} width={"100%"}>
                                                <TextField
                                                    label={'Service'}
                                                    value={state.consultation}
                                                    onChange={(e)=>{
                                                        setState({consultation:e.target.value})
                                                    }}
                                                    sx={{
                                                        flex:1
                                                    }}
                                                />
                                                <FormControl fullWidth sx={{flex:1}}>
                                                    <InputLabel id="consutlation_label">{'Service Type'}</InputLabel>
                                                    <Select
                                                        labelId="consutlation_label"
                                                        value={state.type_of_service}
                                                        label={'Service Type'}
                                                        endAdornment={<Box sx={{cursor:'pointer',display:state.type_of_service!=='none' ? 'flex' : 'none', alignItems:'center'}} onClick={()=>{setState({type_of_service:'none'})}} mr={3}>{<CloseIcon color={theme.palette.white.main}/>}</Box>}
                                                        onChange={(e)=>{
                                                            setState({type_of_service:e.target.value})
                                                        }}
                                                        sx={{
                                                            width:'100%'
                                                        }}
                                                    >
                                                        <MenuItem disabled value={"none"}>Enter the type of service</MenuItem>
                                                        {consultation_options.map(elem=>{
                                                            return <MenuItem value={elem.value}>{elem.label}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    label={ADMIN_DESCRIPTION['eng']}
                                                    value={state.description}
                                                    onChange={(e)=>{
                                                        setState({description:e.target.value})
                                                    }}
                                                    sx={{
                                                        flex:1
                                                    }}
                                                />
                                            </Box>
                                            <Box display={'flex'} gap={1} width={"100%"} alignSelf={'center'}>
                                                
                                                <Box display={'flex'} flexDirection={'column'} gap={1}>
                                                    <Typography>{ADMIN_TYPE['eng']}</Typography>
                                                    <FormGroup sx={{display:'flex', flexDirection:'column', gap:0}}>
                                                        <FormControlLabel sx={{mt:1}} control={<Checkbox size='small' checked={state.type.online.checked} onChange={(e)=>{setState({type:{...state.type,online:{...state.type.online,checked:e.target.checked}}})}}/>} label="Online" />
                                                        <FormControlLabel sx={{mt:3.5}} control={<Checkbox size='small' checked={state.type.in_visit.checked} onChange={(e)=>{setState({type:{...state.type,in_visit:{...state.type.in_visit,checked:e.target.checked}}})}}/>} label="In-Person" />
                                                    </FormGroup>
                                                </Box>
                                                <Box display={'flex'} flexDirection={'column'} gap={1}>
                                                    <Typography>{ADMIN_PRICE['eng']}</Typography>
                                                    {/* <FormControl size="small">
                                                        <Select
                                                            startAdornment={<Typography color={theme.palette.white.greyText} mr={0.5}>Rs </Typography>}
                                                            value={state.type.online.price}
                                                            onChange={(e)=>{}}
                                                        >
                                                            {price_options.map(elem=>{
                                                                return <MenuItem value={elem}><Typography>{elem}</Typography></MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl> */}
                                                    {/* <FormControl size="small">
                                                        <Select
                                                            startAdornment={<Typography color={theme.palette.white.greyText} mr={0.5}>Rs </Typography>}
                                                            value={state.type.in_visit.price}
                                                            onChange={(e)=>{}}
                                                        >
                                                            {price_options.map(elem=>{
                                                                return <MenuItem value={elem}><Typography>{elem}</Typography></MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl> */}
                                                    <TextField
                                                        value={state.type.online.price}
                                                        onChange={(e)=>{
                                                            let val = e.target.value;
                                                            const onlyNums = val.replace(
                                                                /[^0-9]/g,
                                                                ""
                                                            );
                                                            if(onlyNums<0) return;
                                                            setState({type:{...state.type, online:{...state.type.online, price:onlyNums}}})
                                                        }}
                                                        type='text'
                                                        InputProps={{
                                                            startAdornment:<Typography color={theme.palette.white.greyText} mr={0.5}>Rs </Typography>
                                                        }}
                                                    />
                                                    <TextField
                                                        value={state.type.in_visit.price}
                                                        onChange={(e)=>{
                                                            let val = e.target.value;
                                                            const onlyNums = val.replace(
                                                                /[^0-9]/g,
                                                                ""
                                                            );
                                                            if(onlyNums<0) return;
                                                            setState({type:{...state.type, in_visit:{...state.type.in_visit, price:onlyNums}}})
                                                        }}
                                                        type='text'
                                                        InputProps={{
                                                            startAdornment:<Typography color={theme.palette.white.greyText} mr={0.5}>Rs </Typography>
                                                        }}
                                                    />
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>

                                    <Divider/>

                                    {/* slot managment */}
                                    <Box width={'100%'} p={0} display={'flex'} flexDirection={'column'} gap={2}>

                                        <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                                            <Box display={'flex'} gap={1} alignItems={'center'} p={3}>
                                                <Box
                                                    component={'img'}
                                                    src='/images/calender_icon21.svg'
                                                />
                                                <Typography color={theme.palette.primary.main} textTransform={'uppercase'}>{ADMIN_SELECT_DAY_AND_TIME['eng']}</Typography>
                                            </Box>
                                            <Box marginX={3}>
                                                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                                    <Tab label={<Typography variant='subtitle2' fontWeight={'bold'}>ONLINE</Typography>}/>
                                                    <Tab label={<Typography variant='subtitle2' fontWeight={'bold'}>In-Person</Typography>}/>
                                                </Tabs>
                                            </Box>

                                            <Box width={'100%'}>
                                                <Paper elevation={0} sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                        overflowX:'scroll'
                                                    }}
                                                >
                                                    <TableContainer
                                                        sx={{ height: "100%", maxHeight: "100%", flex: 1,overflowX:'auto' }}
                                                    >
                                                    <Table>
                                                        <TableHead sx={{backgroundColor:theme.palette.primary.slotTableBackground}}>
                                                            <TableRow>
                                                                <TableCell align='left' sx={{pl:3}}><Typography variant='subtitle2'  fontWeight={500}>{ADMIN_DAYS['eng']}</Typography></TableCell>
                                                                <TableCell align="left">
                                                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                                                        <Box
                                                                            component={'img'}
                                                                            src='/images/morning_icon.svg'
                                                                        />
                                                                        <Typography variant='subtitle2'  fontWeight={500}>{ADMIN_MORNING['eng']}</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                                                        <Box
                                                                            component={'img'}
                                                                            src='/images/afternoon_icon.svg'
                                                                        />
                                                                        <Typography variant='subtitle2'  fontWeight={500}>{ADMIN_AFTERNOON['eng']}</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <Box display={'flex'} gap={1} alignItems={'center'}>
                                                                        <Box
                                                                            component={'img'}
                                                                            src='/images/evening_icon.svg'
                                                                        />
                                                                        <Typography variant='subtitle2'  fontWeight={500}>{ADMIN_EVENING['eng']}</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align="center" width={'100px'}></TableCell>
                                                                <TableCell align="center"><Typography variant='subtitle2' fontWeight={500}>{ADMIN_WEEKOFFS['eng']}</Typography></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {days.map(day=>{
                                                                return (
                                                                    <TableRow
                                                                        key={day}
                                                                        sx={{ }}
                                                                    >
                                                                        
                                                                        <TableCell sx={{pl:3}}>
                                                                            <Typography color={theme.palette.white.darkerText} textTransform={'capitalize'}>{day}</Typography>
                                                                        </TableCell>

                                                                        {/* is Weekoff true? */}
                                                                        {state[docType][day].weekoffs ? (
                                                                            <TableCell colSpan={3}>
                                                                                <Box display={'flex'} justifyContent={'start'} alignItems={'center'} px={2} height={'40px'} width={'100%'} backgroundColor={theme.palette.negative.light} borderRadius={'4px'}>
                                                                                    <Typography color={theme.palette.white.darkerText}>Weekoff</Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                        ) : (

                                                                            // if not week off and is EDIT
                                                                            state[docType][day].isEdit ? (
                                                                                <>
                                                                                    {/* morning */}
                                                                                    <TableCell align="left" style={{verticalAlign:'top'}}>
                                                                                        <Box display={'flex'} gap={1}>
                                                                                            <Box width={'180px'} display={'flex'} flexDirection={'column'} gap={1}>

                                                                                                {/* render edit slots */}
                                                                                                {state[docType][day].edit_morning.map(((slot,index)=>{
                                                                                                    return (
                                                                                                        <SlotInput 
                                                                                                            time_of_day={'Morning'}
                                                                                                            startTime={slot.start} 
                                                                                                            endTime={slot.end} 
                                                                                                            handleChangeStartTime={(e)=>{
                                                                                                                setState({
                                                                                                                    [docType]:{
                                                                                                                        ...state[docType],
                                                                                                                        [day]:{
                                                                                                                            ...state[docType][day],
                                                                                                                            edit_morning:state[docType][day].edit_morning.map((elem,currIndex)=>{
                                                                                                                                if(currIndex===index){
                                                                                                                                    return {
                                                                                                                                        ...elem,
                                                                                                                                        start:e.target.value
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                return elem;
                                                                                                                            })
                                                                                                                        }

                                                                                                                    }
                                                                                                                })
                                                                                                            }} 
                                                                                                            handleChangeEndTime={(e)=>{
                                                                                                                setState({
                                                                                                                    [docType]:{
                                                                                                                        ...state[docType],
                                                                                                                        [day]:{
                                                                                                                            ...state[docType][day],
                                                                                                                            edit_morning:state[docType][day].edit_morning.map((elem,currIndex)=>{
                                                                                                                                if(currIndex===index){
                                                                                                                                    return {
                                                                                                                                        ...elem,
                                                                                                                                        end:e.target.value
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                return elem;
                                                                                                                            })
                                                                                                                        }

                                                                                                                    }
                                                                                                                })
                                                                                                            }}
                                                                                                        />
                                                                                                    )
                                                                                                }))}
                                                                                            </Box>

                                                                                            {/* render add more edit slot */}
                                                                                            {/* <Box display={'flex'} justifyContent={'center'} alignItems={'start'} mt={'12px'}>
                                                                                                <Box
                                                                                                    component={'img'}
                                                                                                    src='/images/slot_add_icon.svg'
                                                                                                    sx={{
                                                                                                        cursor:'pointer'
                                                                                                    }}
                                                                                                    onClick={()=>{
                                                                                                        setState({
                                                                                                            online:{
                                                                                                                ...state.online,
                                                                                                                [day]:{
                                                                                                                    ...state.online[day],
                                                                                                                    edit_morning:[...state.online[day].edit_morning,{start:undefined,end:undefined}]
                                                                                                                }

                                                                                                            }
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            </Box> */}
                                                                                        </Box>
                                                                                    </TableCell>

                                                                                    {/* afternoon */}
                                                                                    <TableCell align="left" style={{verticalAlign:'top'}}>
                                                                                        <Box display={'flex'} gap={1}>
                                                                                            <Box width={'180px'} display={'flex'} flexDirection={'column'} gap={1}>
                                                                                                {state[docType][day].edit_afternoon.map(((slot,index)=>{
                                                                                                    return (
                                                                                                        <SlotInput 
                                                                                                            time_of_day={'Afternoon'}
                                                                                                            startTime={slot.start} 
                                                                                                            endTime={slot.end} 
                                                                                                            handleChangeStartTime={(e)=>{
                                                                                                                setState({
                                                                                                                    [docType]:{
                                                                                                                        ...state[docType],
                                                                                                                        [day]:{
                                                                                                                            ...state[docType][day],
                                                                                                                            edit_afternoon:state[docType][day].edit_afternoon.map((elem,currIndex)=>{
                                                                                                                                if(currIndex===index){
                                                                                                                                    return {
                                                                                                                                        ...elem,
                                                                                                                                        start:e.target.value
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                return elem;
                                                                                                                            })
                                                                                                                        }

                                                                                                                    }
                                                                                                                })
                                                                                                            }} 
                                                                                                            handleChangeEndTime={(e)=>{
                                                                                                                setState({
                                                                                                                    [docType]:{
                                                                                                                        ...state[docType],
                                                                                                                        [day]:{
                                                                                                                            ...state[docType][day],
                                                                                                                            edit_afternoon:state[docType][day].edit_afternoon.map((elem,currIndex)=>{
                                                                                                                                if(currIndex===index){
                                                                                                                                    return {
                                                                                                                                        ...elem,
                                                                                                                                        end:e.target.value
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                return elem;
                                                                                                                            })
                                                                                                                        }

                                                                                                                    }
                                                                                                                })
                                                                                                            }}/>
                                                                                                    )
                                                                                                }))}
                                                                                            </Box>
                                                                                            <Box display={'flex'} justifyContent={'center'} alignItems={'start'} mt={'12px'}>
                                                                                                {/* <Box
                                                                                                    component={'img'}
                                                                                                    src='/images/slot_add_icon.svg'
                                                                                                    sx={{
                                                                                                        cursor:'pointer'
                                                                                                    }}
                                                                                                    onClick={()=>{
                                                                                                        setState({
                                                                                                            online:{
                                                                                                                ...state.online,
                                                                                                                [day]:{
                                                                                                                    ...state.online[day],
                                                                                                                    edit_afternoon:[...state.online[day].edit_afternoon,{start:undefined,end:undefined}]
                                                                                                                }

                                                                                                            }
                                                                                                        })
                                                                                                    }}
                                                                                                /> */}
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </TableCell>

                                                                                    {/* evening */}
                                                                                    <TableCell align="left" style={{verticalAlign:'top'}}>
                                                                                        <Box display={'flex'} gap={1}>
                                                                                            <Box width={'180px'} display={'flex'} flexDirection={'column'} gap={1}>
                                                                                                {state[docType][day].edit_evening.map(((slot,index)=>{
                                                                                                    return (
                                                                                                        <SlotInput 
                                                                                                            time_of_day={'Evening'}
                                                                                                            startTime={slot.start} 
                                                                                                            endTime={slot.end} 
                                                                                                            handleChangeStartTime={(e)=>{
                                                                                                                setState({
                                                                                                                    [docType]:{
                                                                                                                        ...state[docType],
                                                                                                                        [day]:{
                                                                                                                            ...state[docType][day],
                                                                                                                            edit_evening:state[docType][day].edit_evening.map((elem,currIndex)=>{
                                                                                                                                if(currIndex===index){
                                                                                                                                    return {
                                                                                                                                        ...elem,
                                                                                                                                        start:e.target.value
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                return elem;
                                                                                                                            })
                                                                                                                        }

                                                                                                                    }
                                                                                                                })
                                                                                                            }} 
                                                                                                            handleChangeEndTime={(e)=>{
                                                                                                                setState({
                                                                                                                    [docType]:{
                                                                                                                        ...state[docType],
                                                                                                                        [day]:{
                                                                                                                            ...state[docType][day],
                                                                                                                            edit_evening:state[docType][day].edit_evening.map((elem,currIndex)=>{
                                                                                                                                if(currIndex===index){
                                                                                                                                    return {
                                                                                                                                        ...elem,
                                                                                                                                        end:e.target.value
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                return elem;
                                                                                                                            })
                                                                                                                        }

                                                                                                                    }
                                                                                                                })
                                                                                                            }}/>
                                                                                                    )
                                                                                                }))}
                                                                                            </Box>
                                                                                            <Box display={'flex'} justifyContent={'center'} alignItems={'start'} mt={'12px'}>
                                                                                                {/* <Box
                                                                                                    component={'img'}
                                                                                                    src='/images/slot_add_icon.svg'
                                                                                                    sx={{
                                                                                                        cursor:'pointer'
                                                                                                    }}
                                                                                                    onClick={()=>{
                                                                                                        setState({
                                                                                                            online:{
                                                                                                                ...state.online,
                                                                                                                [day]:{
                                                                                                                    ...state.online[day],
                                                                                                                    edit_evening:[...state.online[day].edit_evening,{start:undefined,end:undefined}]
                                                                                                                }

                                                                                                            }
                                                                                                        })
                                                                                                    }}
                                                                                                /> */}
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <TableCell align="left" style={{verticalAlign:'top'}}>
                                                                                        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={1}>

                                                                                            {/* no slot placeholder for morning*/}
                                                                                            {!state[docType][day].morning.length ? state[docType][day].edit_morning.map((slot,index)=>{
                                                                                                return (
                                                                                                    <SlotInput 
                                                                                                        time_of_day={'Morning'}
                                                                                                        startTime={slot.start} 
                                                                                                        endTime={slot.end} 
                                                                                                        handleChangeStartTime={(e)=>{
                                                                                                            
                                                                                                            setState({
                                                                                                                [docType]:{
                                                                                                                    ...state[docType],
                                                                                                                    [day]:{
                                                                                                                        ...state[docType][day],
                                                                                                                        edit_morning:state[docType][day].edit_morning.map((elem,currIndex)=>{
                                                                                                                            if(currIndex===index){
                                                                                                                                return {
                                                                                                                                    ...elem,
                                                                                                                                    start:e.target.value
                                                                                                                                }
                                                                                                                            }
                                                                                                                            return elem;
                                                                                                                        }),
                                                                                                                        edit_afternoon:state[docType][day].afternoon.length ? state[docType][day].afternoon : [{start:undefined, end:undefined}],
                                                                                                                        edit_evening:state[docType][day].evening.length ? state[docType][day].evening : [{start:undefined, end:undefined}],
                                                                                                                        isEdit:true,
                                                                                                                    }

                                                                                                                }
                                                                                                            })
                                                                                                        }} 
                                                                                                        handleChangeEndTime={(e)=>{
                                                                                                            setState({
                                                                                                                [docType]:{
                                                                                                                    ...state[docType],
                                                                                                                    [day]:{
                                                                                                                        ...state[docType][day],
                                                                                                                        edit_morning:state[docType][day].edit_morning.map((elem,currIndex)=>{
                                                                                                                            if(currIndex===index){
                                                                                                                                return {
                                                                                                                                    ...elem,
                                                                                                                                    end:e.target.value
                                                                                                                                }
                                                                                                                            }
                                                                                                                            return elem;
                                                                                                                        }),
                                                                                                                        edit_afternoon:state[docType][day].afternoon.length ? state[docType][day].afternoon : [{start:undefined, end:undefined}],
                                                                                                                        edit_evening:state[docType][day].evening.length ? state[docType][day].evening : [{start:undefined, end:undefined}],
                                                                                                                        isEdit:true,
                                                                                                                    }

                                                                                                                }
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                )
                                                                                            }) : (
                                                                                                // render slots for morning
                                                                                                state[docType][day].morning.map(((slot,index)=>{
                                                                                                    return (
                                                                                                        <SlotCard 
                                                                                                            slot={slot} 
                                                                                                            time_of_day={'Morning'} 
                                                                                                            handleDelete={()=>{
                                                                                                                setState({
                                                                                                                    [docType]:{
                                                                                                                        ...state[docType],
                                                                                                                        [day]:{
                                                                                                                            ...state[docType][day],
                                                                                                                            morning:state[docType][day].morning.filter((elem,currIndex)=>{
                                                                                                                                return currIndex!==index;
                                                                                                                            }),
                                                                                                                        }
                                                                                                                    }
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                    )
                                                                                                }))
                                                                                            )}
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                    <TableCell align="left" style={{verticalAlign:'top'}}>
                                                                                        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={1}>
                                                                                            {/* no slot placeholder for afternoon*/}
                                                                                            {!state[docType][day].afternoon.length ? state[docType][day].edit_afternoon.map((slot,index)=>{
                                                                                                return (
                                                                                                    <SlotInput 
                                                                                                        time_of_day={'Afternoon'}
                                                                                                        startTime={slot.start} 
                                                                                                        endTime={slot.end} 
                                                                                                        handleChangeStartTime={(e)=>{
                                                                                                            
                                                                                                            setState({
                                                                                                                [docType]:{
                                                                                                                    ...state[docType],
                                                                                                                    [day]:{
                                                                                                                        ...state[docType][day],
                                                                                                                        edit_afternoon:state[docType][day].edit_afternoon.map((elem,currIndex)=>{
                                                                                                                            if(currIndex===index){
                                                                                                                                return {
                                                                                                                                    ...elem,
                                                                                                                                    start:e.target.value
                                                                                                                                }
                                                                                                                            }
                                                                                                                            return elem;
                                                                                                                        }),
                                                                                                                        edit_morning:state[docType][day].morning.length ? state[docType][day].morning : [{start:undefined, end:undefined}],
                                                                                                                        edit_evening:state[docType][day].evening.length ? state[docType][day].evening : [{start:undefined, end:undefined}],
                                                                                                                        isEdit:true,
                                                                                                                    }

                                                                                                                }
                                                                                                            })
                                                                                                        }} 
                                                                                                        handleChangeEndTime={(e)=>{
                                                                                                            setState({
                                                                                                                [docType]:{
                                                                                                                    ...state[docType],
                                                                                                                    [day]:{
                                                                                                                        ...state[docType][day],
                                                                                                                        edit_afternoon:state[docType][day].edit_afternoon.map((elem,currIndex)=>{
                                                                                                                            if(currIndex===index){
                                                                                                                                return {
                                                                                                                                    ...elem,
                                                                                                                                    end:e.target.value
                                                                                                                                }
                                                                                                                            }
                                                                                                                            return elem;
                                                                                                                        }),
                                                                                                                        edit_morning:state[docType][day].morning.length ? state[docType][day].morning : [{start:undefined, end:undefined}],
                                                                                                                        edit_evening:state[docType][day].evening.length ? state[docType][day].evening : [{start:undefined, end:undefined}],
                                                                                                                        isEdit:true,
                                                                                                                    }

                                                                                                                }
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                )
                                                                                            }) : (
                                                                                                // render slots for morning
                                                                                                state[docType][day].afternoon.map(((slot,index)=>{
                                                                                                    return (
                                                                                                        <SlotCard 
                                                                                                            slot={slot} 
                                                                                                            time_of_day={'Afternoon'} 
                                                                                                            handleDelete={()=>{
                                                                                                                setState({
                                                                                                                    [docType]:{
                                                                                                                        ...state[docType],
                                                                                                                        [day]:{
                                                                                                                            ...state[docType][day],
                                                                                                                            afternoon:state[docType][day].afternoon.filter((elem,currIndex)=>{
                                                                                                                                return currIndex!==index;
                                                                                                                            }),
                                                                                                                        }
                                                                                                                    }
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                    )
                                                                                                }))
                                                                                            )}
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                    <TableCell align="left" style={{verticalAlign:'top'}}>
                                                                                        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={1}>
                                                                                            {/* no slot placeholder for evening*/}
                                                                                            {!state[docType][day].evening.length ? state[docType][day].edit_evening.map((slot,index)=>{
                                                                                                return (
                                                                                                    <SlotInput 
                                                                                                        time_of_day={'Evening'}
                                                                                                        startTime={slot.start} 
                                                                                                        endTime={slot.end} 
                                                                                                        handleChangeStartTime={(e)=>{
                                                                                                            
                                                                                                            setState({
                                                                                                                [docType]:{
                                                                                                                    ...state[docType],
                                                                                                                    [day]:{
                                                                                                                        ...state[docType][day],
                                                                                                                        edit_evening:state[docType][day].edit_evening.map((elem,currIndex)=>{
                                                                                                                            if(currIndex===index){
                                                                                                                                return {
                                                                                                                                    ...elem,
                                                                                                                                    start:e.target.value
                                                                                                                                }
                                                                                                                            }
                                                                                                                            return elem;
                                                                                                                        }),
                                                                                                                        edit_morning:state[docType][day].morning.length ? state[docType][day].morning : [{start:undefined, end:undefined}],
                                                                                                                        edit_afternoon:state[docType][day].afternoon.length ? state[docType][day].afternoon : [{start:undefined, end:undefined}],
                                                                                                                        isEdit:true,
                                                                                                                    }

                                                                                                                }
                                                                                                            })
                                                                                                        }} 
                                                                                                        handleChangeEndTime={(e)=>{
                                                                                                            setState({
                                                                                                                [docType]:{
                                                                                                                    ...state[docType],
                                                                                                                    [day]:{
                                                                                                                        ...state[docType][day],
                                                                                                                        edit_evening:state[docType][day].edit_evening.map((elem,currIndex)=>{
                                                                                                                            if(currIndex===index){
                                                                                                                                return {
                                                                                                                                    ...elem,
                                                                                                                                    end:e.target.value
                                                                                                                                }
                                                                                                                            }
                                                                                                                            return elem;
                                                                                                                        }),
                                                                                                                        edit_morning:state[docType][day].morning.length ? state[docType][day].morning : [{start:undefined, end:undefined}],
                                                                                                                        edit_afternoon:state[docType][day].afternoon.length ? state[docType][day].afternoon : [{start:undefined, end:undefined}],
                                                                                                                        isEdit:true,
                                                                                                                    }

                                                                                                                }
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                )
                                                                                            }) : (
                                                                                                // render slots for evening
                                                                                                state[docType][day].evening.map(((slot,index)=>{
                                                                                                    return (
                                                                                                        <SlotCard 
                                                                                                            slot={slot} 
                                                                                                            time_of_day={'Evening'} 
                                                                                                            handleDelete={()=>{
                                                                                                                setState({
                                                                                                                [docType]:{
                                                                                                                        ...state[docType],
                                                                                                                        [day]:{
                                                                                                                            ...state[docType][day],
                                                                                                                            evening:state[docType][day].evening.filter((elem,currIndex)=>{
                                                                                                                                return currIndex!==index;
                                                                                                                            }),
                                                                                                                        }
                                                                                                                    }
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                    )
                                                                                                }))
                                                                                            )}
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                </>
                                                                            )
                                                                        )}
                                                                        
                                                                        <TableCell align="center" width={'100px'}>
                                                                            {!state[docType][day].weekoffs && !state[docType][day].isEdit && (
                                                                                <Box display={'flex'} justifyContent={'center'}>
                                                                                    <Tooltip title="Copy Slots">
                                                                                        <IconButton onClick={(e)=>{handleClickCopyPopper(e,day)}} sx={{p:0.5}}>
                                                                                            <Box component={'img'} src='/images/slot_copy_icon.svg' sx={{cursor:'pointer'}} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Edit Slots">
                                                                                        <IconButton 
                                                                                            onClick={()=>{
                                                                                                setState({
                                                                                                    [docType]:{
                                                                                                        ...state[docType],
                                                                                                        [day]:{
                                                                                                            ...state[docType][day],
                                                                                                            edit_morning:state[docType][day].morning.length ? state[docType][day].morning : state[docType][day].edit_morning,
                                                                                                            edit_afternoon:state[docType][day].afternoon.length ? state[docType][day].afternoon : state[docType][day].edit_afternoon,
                                                                                                            edit_evening:state[docType][day].evening.length ? state[docType][day].evening : state[docType][day].edit_evening,
                                                                                                            isEdit:true,
                                                                                                        }
                                                                                                    }
                                                                                                });
                                                                                            }} 
                                                                                            sx={{p:0.5}}
                                                                                        >
                                                                                            <Box component={'img'} src='/images/slot_edit_icon.svg' sx={{cursor:'pointer'}} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            )}
                                                                            {state[docType][day].isEdit && (
                                                                                <IconButton 
                                                                                    onClick={()=>{
                                                                                        setState({
                                                                                            [docType]:{
                                                                                                ...state[docType],
                                                                                                [day]:{
                                                                                                    ...state[docType][day],
                                                                                                    morning:state[docType][day].edit_morning.filter(elem=>{
                                                                                                        return elem.start && elem.end;
                                                                                                    }),
                                                                                                    afternoon:state[docType][day].edit_afternoon.filter(elem=>{
                                                                                                        return elem.start && elem.end;
                                                                                                    }),
                                                                                                    evening:state[docType][day].edit_evening.filter(elem=>{
                                                                                                        return elem.start && elem.end;
                                                                                                    }),
                                                                                                    edit_morning:[],
                                                                                                    edit_afternoon:[],
                                                                                                    edit_evening:[],
                                                                                                    isEdit:false,
                                                                                                }
                                                                                            }
                                                                                        });
                                                                                    }} 
                                                                                    sx={{p:0.5}}
                                                                                >
                                                                                    <Box component={'img'} src='/images/slot_save_icon.svg' sx={{cursor:'pointer'}} />
                                                                                </IconButton>
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Switch 
                                                                                disabled={state[docType][day].isEdit}
                                                                                value={state[docType][day].weekoffs} 
                                                                                checked={state[docType][day].weekoffs}
                                                                                onClick={()=>{handleChangeWeekOff(day)}}
                                                                            />
                                                                            
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                    </TableContainer>
                                                    <Popover
                                                        id={"copypopper"}
                                                        open={openCopyPopper}
                                                        anchorEl={anchorEl}
                                                        onClose={handleCloseCopyPopper}
                                                        anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                        }}
                                                        elevation={1}
                                                    >
                                                        <Box width={'260px'} display={'flex'} flexDirection={'column'} borderRadius={'8px'}>
                                                            <Box height={'42px'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1} sx={{backgroundColor:theme.palette.primary.light}}>
                                                                <Box component={'img'} width={'16px'} src='/images/slot_copy_icon.svg'/>
                                                                <Typography variant='subtitle2' fontWeight={500} color={theme.palette.primary.main}>{ADMIN_COPY_DATE_CAPTION['eng']}</Typography>
                                                            </Box>
                                                            <Box display={'flex'} flexDirection={'column'} gap={0} px={2}>
                                                                <Box>
                                                                    <FormGroup>
                                                                        <FormControlLabel control={<Checkbox checked={copyDays["alldays"]} onChange={(e)=>{setCopyDays({alldays:e.target.checked})}}/>} label={<Typography color={'black'}>{"All days"}</Typography>} />
                                                                    </FormGroup>
                                                                </Box>
                                                                {days.map(day=>{
                                                                    return (
                                                                        <Box>
                                                                            <FormGroup>
                                                                                <FormControlLabel control={<Checkbox defaultChecked={selectedCopyDay===day} disabled={selectedCopyDay===day} checked={copyDays[day]} onChange={(e)=>{setCopyDays({[day]:e.target.checked})}}/>} label={<Typography color={selectedCopyDay===day ? theme.palette.white.greyText : 'black'} textTransform={'capitalize'}>{day}</Typography>} />
                                                                            </FormGroup>
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Box>
                                                            <Divider/>
                                                            <Box display={'flex'} gap={1} justifyContent={'space-between'} alignItems={'center'} p={2}>
                                                                <Button variant='outlined' sx={{flex:1,py:1}} onClick={handleCloseCopyPopper}>
                                                                    <Typography textTransform={'capitalize'}>{ADMIN_CANCEL['eng']}</Typography>
                                                                </Button>
                                                                <Button variant='contained' sx={{flex:1,py:1}} onClick={handleCopyDays}>
                                                                    <Typography textTransform={'capitalize'}>{ADMIN_APPLY['eng']}</Typography>
                                                                </Button>
                                                            </Box>
                                                        </Box>

                                                    </Popover>
                                                </Paper>
                                            </Box>


                                            {/* mark leaves section */}
                                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} px={3} my={2}>
                                                    <Box>
                                                        <Typography fontWeight={'bold'}>{ADMIN_MARK_A_LEAVE['eng']}</Typography>
                                                        <Typography variant='caption'>{ADMIN_MARK_A_LEAVE_CAPTION['eng']}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Button onClick={()=>{setOpenLeaveModal(true)}} variant='outlined' sx={{p:2}} startIcon={<Box component={'img'} src='/images/markaholiday_icon.svg'/>}>
                                                            <Typography color>{ADMIN_MARK_A_HOLIDAY['eng']}</Typography>
                                                        </Button>
                                                    </Box>
                                            </Box>

                                            {/* leaves this week */}
                                            <Box display={'flex'} flexDirection={'column'} gap={0.5} justifyContent={'space-between'} alignItems={'start'} px={3} my={2}>
                                                    <Box>
                                                        <Typography fontWeight={'bold'}>{ADMIN_LEAVES_THIS_WEEK['eng']}</Typography>
                                                    </Box>
                                                    <Box display={'flex'} gap={4} flexWrap={'wrap'}>
                                                    { (Array.isArray(state.leaves) && state.leaves?.length) ? state.leaves?.map((l,i)=>{
                                                        return (
                                                            <Box display={'flex'} gap={1} alignItems={'center'}>
                                                                <Box
                                                                    component={'img'}
                                                                    src={'/images/calender_icon21.svg'}
                                                                />
                                                                <Typography variant='subtitle2' color={theme.palette.white.greyText}>{l?.date ? dayjs(l.date,'YYYY-MM-DD').format('dddd, DD MMMM YYYY') + " " + (l?.slots ? `(${l?.slots?.map(s=>s[0]?.toUpperCase()+s.slice(1))?.join(', ')})` : '') : l?.date}</Typography>
                                                                <Button variant='outlined' color='red' onClick={()=>{handleRevokeLeave(i)}}><Typography variant='caption'>Revoke</Typography></Button>
                                                            </Box>
                                                        )
                                                        }) : '-'}
                                                    </Box>
                                            </Box>

                                            {/*Selected leaves to apply */}
                                            <Box display={'flex'} flexDirection={'column'} gap={0.5} justifyContent={'space-between'} alignItems={'start'} px={3}>
                                                    <Box>
                                                        <Typography fontWeight={'bold'}>{'Selected unsaved leaves'}</Typography>
                                                    </Box>
                                                    <Box display={'flex'} gap={2} flexWrap={'wrap'}>
                                                   
                                                        {renderUnsavedLeaves()}
                                                    
                                                    </Box>
                                            </Box>
                                        </Box>
                                        
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <Modal
        open={openLeaveModal}
        onClose={() => {
            setOpenLeaveModal(false);
            setState({isLeaveMarked:false});
        }}
      >
        <Box sx={style2}>
            <Box display={"flex"} justifyContent={"space-between"} sx={{mb:1}}>
                <Box display={'flex'} gap={1} alignItems={'center'}>
                    <img
                        style={{ width:30,pointerEvents: "none" }}
                        src="/images/markaholiday_icon.svg"
                        alt="calender"
                    />
                    <Typography fontWeight={500} color={theme.palette.primary.main}>Mark a Leave</Typography>
                </Box>
                <Box>
                    <IconButton
                    onClick={() => {
                        setOpenLeaveModal(false);
                        setState({isLeaveMarked:false});
                    }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <Divider/>
            <Box display={'flex'} flexDirection={'column'} gap={2} my={2}>
                <Box display={'flex'} flexDirection={'column'} gap={0.5}>
                    <Typography color={theme.palette.white.greyText} fontWeight={500}>Date</Typography>
                    <Box display={'flex'} gap={2} alignItems={'center'}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                        >
                            <DemoContainer
                            components={["DateRangePicker"]}
                            >
                            <DateRangePicker
                                disablePast
                                localeText={{
                                    start: "From",
                                    end: "To",
                                }}
                                value={
                                    leaveDate
                                }
                                onChange={(newValue) => {
                                    if (!newValue[0] || !newValue[1]) {
                                        return;
                                    }
                                    setLeaveDate(newValue);
                                    setState({
                                        leaveDate:[
                                            newValue[0]?.format('DD/MM/YYYY'),
                                            newValue[1]?.format('DD/MM/YYYY'),
                                        ]
                                    })
                                }}
                                slotProps={{
                                textField: {
                                    InputProps: {
                                    startAdornment: (
                                        <Box
                                        width={{
                                            md: 30,
                                            marginRight: 4,
                                        }}
                                        component="img"
                                        src={"/images/calenderIcon.svg"}
                                        sx={{}}
                                        />
                                    ),
                                    },
                                    onKeyDown: (e) => {
                                        e.preventDefault();
                                    },
                                },
                                }}
                            />
                            </DemoContainer>
                        </LocalizationProvider>
                        <Typography width={200} color={theme.palette.white.greyText}>Leave for {leaveDate?.length ? leaveDate[1]?.diff(leaveDate[0], 'days')+1 : 'N'} Days</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <FormGroup row={true} sx={{paddingX:0,gap:4}}>
                        <FormControlLabel disabled={state.leaveTime.fullday || isPastDate('morning',leaveDate[0])} control={<Checkbox value={'morning'} checked={state.leaveTime.morning} onChange={(e)=>{setState({leaveTime:{...state.leaveTime,morning:e.target.checked}})}} name={"morning"} color='blue'/>} label={"Morning"} slotProps={{typography:{variant:'subtitle1'}}}/>
                        <FormControlLabel disabled={state.leaveTime.fullday || isPastDate('afternoon',leaveDate[0])} control={<Checkbox value={'afternoon'} checked={state.leaveTime.afternoon} onChange={(e)=>{setState({leaveTime:{...state.leaveTime,afternoon:e.target.checked}})}} name={"afternoon"} color='blue'/>} label={"Afternoon"} slotProps={{typography:{variant:'subtitle1'}}}/>
                        <FormControlLabel disabled={state.leaveTime.fullday || isPastDate('evening',leaveDate[0])} control={<Checkbox value={'evening'} checked={state.leaveTime.evening} onChange={(e)=>{setState({leaveTime:{...state.leaveTime,evening:e.target.checked}})}} name={"evening"} color='blue'/>} label={"Evening"} slotProps={{typography:{variant:'subtitle1'}}}/>
                        <FormControlLabel control={<Checkbox value={'full day'} checked={state.leaveTime.fullday} onChange={(e)=>{
                            if(e.target.checked){
                                setState({leaveTime:{morning:false,afternoon:false,evening:false,fullday:e.target.checked}})
                            }
                            else{
                                setState({leaveTime:{...state.leaveTime,fullday:e.target.checked}})
                            }
                        }} name={"full day"} color='blue'/>} label={"Full Day"} slotProps={{typography:{variant:'subtitle1'}}}/>
                    </FormGroup>

                    <Box sx={{backgroundColor:'#FEFBE4', border:'1px solid #FFBD61',borderRadius:'6px',display:'flex',justifyContent:'start',gap:1,alignItems:'center',py:1,px:3}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21.5984 12.0004C21.5984 9.45431 20.587 7.01251 18.7867 5.21217C16.9863 3.41182 14.5445 2.40039 11.9984 2.40039C9.45236 2.40039 7.01056 3.41182 5.21021 5.21217C3.40986 7.01251 2.39844 9.45431 2.39844 12.0004C2.39844 14.5465 3.40986 16.9883 5.21021 18.7886C7.01056 20.589 9.45236 21.6004 11.9984 21.6004C14.5445 21.6004 16.9863 20.589 18.7867 18.7886C20.587 16.9883 21.5984 14.5465 21.5984 12.0004ZM11.408 10.6924C11.4331 10.5541 11.5059 10.4289 11.6138 10.3388C11.7217 10.2487 11.8578 10.1993 11.9984 10.1993C12.139 10.1993 12.2751 10.2487 12.3831 10.3388C12.491 10.4289 12.5638 10.5541 12.5888 10.6924L12.5984 10.8004V16.2028L12.5888 16.3108C12.5638 16.4491 12.491 16.5743 12.3831 16.6644C12.2751 16.7545 12.139 16.8039 11.9984 16.8039C11.8578 16.8039 11.7217 16.7545 11.6138 16.6644C11.5059 16.5743 11.4331 16.4491 11.408 16.3108L11.3984 16.2028V10.8004L11.408 10.6924ZM11.0984 8.10039C11.0984 7.86169 11.1933 7.63278 11.362 7.46399C11.5308 7.29521 11.7597 7.20039 11.9984 7.20039C12.2371 7.20039 12.466 7.29521 12.6348 7.46399C12.8036 7.63278 12.8984 7.86169 12.8984 8.10039C12.8984 8.33908 12.8036 8.568 12.6348 8.73679C12.466 8.90557 12.2371 9.00039 11.9984 9.00039C11.7597 9.00039 11.5308 8.90557 11.362 8.73679C11.1933 8.568 11.0984 8.33908 11.0984 8.10039Z" fill="#FFBD61"/>
                        </svg>

                        <Typography color={'#838383'} fontSize={'12px'}>Total Scheduled Appointments: </Typography>

                        {scheduledPatientsLoader ? (
                            <CircularProgress size="1rem"/>
                        ) : (
                            <Typography color={'#838383'} fontSize={'12px'} fontWeight={'bold'}>{`${scheduledPatients} Patients`}</Typography>
                        )}

                    </Box>

                    <Typography color={theme.palette.white.greyText} px={3}>It is mandatory to cancel or reschedule your appointments before marking this leave</Typography>
                </Box>

            </Box>
            <Divider/>
            <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={2}>
              <Button variant='outlined' onClick={()=>{setOpenLeaveModal(false); setState({isLeaveMarked:false})}} sx={{width:'50%',height:50}}>
                Cancel
              </Button>
              <LoadingButton disabled={!state.leaveTime.fullday && !state.leaveTime.morning && !state.leaveTime.afternoon && !state.leaveTime.evening} loading={leaveLoader} variant='contained' onClick={handleMarkALeave} sx={{width:'50%',height:50}}>
                <Typography startAdornment={<CalendarMonthOutlinedIcon fontSize='small'/>}>Mark a Leave</Typography>
              </LoadingButton>
            </Box>
        </Box>
      </Modal>
    </>
  )
}

const SlotCard = ({
    slot,
    time_of_day,
    handleDelete,
})=>{
    const theme = useTheme();

    const getAMPM = (time)=>{
        // switch (time_of_day){
        //     case 'Morning':
        //         if(time!=='12:00'){
        //             return 'am';
        //         }
        //         return 'pm';
            
        //     case 'Afternoon':
        //         return 'pm';
            
        //     case 'Evening':
        //         if(time!=='12:00'){
        //             return 'pm';
        //         }
        //         return 'am'
        //     default:
        //         return '';
        // }
        return '';
    }
    

    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} px={1} width={'100%'} height={'40px'} overflow={"auto"} backgroundColor={theme.palette.white.medium} borderRadius={'4px'} borderLeft={`4px solid ${theme.palette.primary.main}`}>
           <Box display={'flex'} flexDirection={'column'} alignItems={'start'} justifyContent={'center'}>
                <Typography variant='caption'>{time_of_day}</Typography>
                <Typography variant='caption'>{`${slot.start} ${getAMPM(slot.start)} - ${slot.end} ${getAMPM(slot.end)}`}</Typography>
           </Box>
           <Box display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={()=>{handleDelete()}} sx={{cursor:'pointer'}}>
                <CloseIcon fontSize='small'/>
           </Box>
        </Box>
    )
}

const SlotInput = ({
    startTime,
    endTime,
    handleChangeStartTime,
    handleChangeEndTime,
    time_of_day
}) => {
    const theme = useTheme();

    const getStartTimeIndex = (time_of_day) => {
        switch(time_of_day){
            case 'Morning':
                return morning_slot_options?.findIndex((elem)=>startTime==elem.value) !== -1 ? morning_slot_options?.findIndex((elem)=>startTime==elem.value) : 0;
            case 'Afternoon':
                return afternoon_slot_options?.findIndex((elem)=>startTime==elem.value) !== -1 ? afternoon_slot_options?.findIndex((elem)=>startTime==elem.value) : 0;
            case 'Evening':
                return evening_slot_options?.findIndex((elem)=>startTime==elem.value) !== -1 ? evening_slot_options?.findIndex((elem)=>startTime==elem.value) : 0;
            default:
                return 0;
        }
    }
    const getEndTimeIndex = (time_of_day) => {
        switch(time_of_day){
            case 'Morning':
                return morning_slot_options?.findIndex((elem)=>endTime==elem.value) !== -1 ? morning_slot_options?.findIndex((elem)=>endTime==elem.value) : morning_slot_options.length-1;
            case 'Afternoon':
                return afternoon_slot_options?.findIndex((elem)=>endTime==elem.value) !== -1 ? afternoon_slot_options?.findIndex((elem)=>endTime==elem.value) : afternoon_slot_options.length-1;
            case 'Evening':
                return evening_slot_options?.findIndex((elem)=>endTime==elem.value) !== -1 ? evening_slot_options?.findIndex((elem)=>endTime==elem.value) : evening_slot_options.length-1;
            default:
                return 0;
        }
    }

    return (
        <Box display={'flex'} gap={1} alignItems={'center'} width={'100%'} height={'40px'}>
            <FormControl sx={{ m: 0, width: 80, padding: "0px" }}>
                <Select
                value={startTime || '-- --'}
                onChange={handleChangeStartTime}
                input={
                    <OutlinedInput
                        className="nopadding-select-mui"
                        sx={{ padding: "0px",borderRight:`1px solid primary` }}
                    />
                }
                sx={{
                    padding: "1px",
                    backgroundColor: theme.palette.white.main,
                    borderRadius: "8px",
                    paddingRight:'14px'
                }}
                >
                    <MenuItem disabled value={"-- --"}><Typography color={theme.palette.white.greyText}>{`-- --`}</Typography></MenuItem>
                    {time_of_day === 'Morning' && (
                        
                        morning_slot_options?.slice(0,getEndTimeIndex(time_of_day))?.map(elem=>{
                            return (
                                <MenuItem key={elem.value} value={elem.value}>
                                    {elem.label}
                                </MenuItem>
                            )
                        })
                        
                    )}
                    {time_of_day === 'Afternoon' && (
                        
                        afternoon_slot_options?.slice(0,getEndTimeIndex(time_of_day))?.map(elem=>{
                            return (
                                <MenuItem key={elem.value} value={elem.value}>
                                    {elem.label}
                                </MenuItem>
                            )
                        })
                        
                    )}
                    {time_of_day === 'Evening' && (
                        
                        evening_slot_options?.slice(0,getEndTimeIndex(time_of_day))?.map(elem=>{
                            return (
                                <MenuItem key={elem.value} value={elem.value}>
                                    {elem.label}
                                </MenuItem>
                            )
                        })
                        
                    )}
                    
                </Select>
            </FormControl>

            <Typography>-</Typography>

            <FormControl sx={{ m: 0, width: 80, padding: "0px" }} disabled={!startTime}>
                <Select
                value={endTime || "-- --"}
                onChange={handleChangeEndTime}
                input={
                    <OutlinedInput
                        className="nopadding-select-mui"
                        sx={{ padding: "0px" }}
                    />
                }
                sx={{
                    padding: "1px",
                    backgroundColor: theme.palette.white.main,
                    borderRadius: "8px",
                    paddingRight:'14px'
                }}
                >
                    <MenuItem disabled value={"-- --"}><Typography color={theme.palette.white.greyText}>{`-- --`}</Typography></MenuItem>
                    {time_of_day === 'Morning' && (
                        
                        morning_slot_options?.slice(getStartTimeIndex(time_of_day)+1)?.map(elem=>{
                            return (
                                <MenuItem key={elem.value} value={elem.value}>
                                    {elem.label}
                                </MenuItem>
                            )
                        })
                        
                    )}
                    {time_of_day === 'Afternoon' && (
                        
                        afternoon_slot_options?.slice(getStartTimeIndex(time_of_day)+1)?.map(elem=>{
                            return (
                                <MenuItem key={elem.value} value={elem.value}>
                                    {elem.label}
                                </MenuItem>
                            )
                        })
                        
                    )}
                    {time_of_day === 'Evening' && (
                        
                        evening_slot_options?.slice(getStartTimeIndex(time_of_day)+1)?.map(elem=>{
                            return (
                                <MenuItem key={elem.value} value={elem.value}>
                                    {elem.label}
                                </MenuItem>
                            )
                        })
                        
                    )}
                </Select>
            </FormControl>

        </Box>
    )

}

export default SlotManagement