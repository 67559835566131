import React, { ReactNode, useEffect, useLayoutEffect } from "react";
import { Grid, Box, Stack, Typography, IconButton, useTheme } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useDispatch } from "react-redux";
import { toggleHelper } from "../../redux/reducers/auth.slice";
import { useNavigate } from "react-router-dom";


const LoginLayout = ({ children, setState }) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Grid container width='100vw' height={'100vh'} direction={'row'} justifyContent="center" alignItems="center">
            <Grid item xs={4} md={5} display={{xs:'none', md:"block"}} height={{xs:'auto',md:'100vh'}} width={{xs:'100vw'}} sx={{background:`linear-gradient(to bottom, #494E9D, #10145C)`}}>
                <Box
                    display="flex"
                    flexDirection="column"
                    height='100%'
                    alignItems="center"
                    justifyContent="center"
                    padding={{xs:2,md:0}}
                >
                    <Box
                        component="img"
                        width={{xs:100, sm:200, md:400}}
                        marginBottom={4}
                        marginRight={10}
                        alt="Login Image"
                        src="/images/loginImage.svg"
                    />
                    <Box component="div" paddingX={{xs:1, md:4, lg:18}}>
                        <Typography fontWeight="Medium" textAlign="center" color={theme.palette.white.main} fontSize={24} marginBottom={2}>Digitally Empowered Healthcare <br/> Elevate Patient Care</Typography>
                        <Typography fontWeight="Medium" textAlign="center" color={theme.palette.white.main} fontSize={14}>Revolutionize patient interactions, streamline records,<br/> and transform consultations. Experience healthcare reimagined.</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={7} height={'100vh'} overflow={{xs:'auto',md:'none'}} display='flex' justifyContent='center' alignItems='center' p={{xs:2, md:14}} position="relative" sx={{background:`linear-gradient(to right,${theme.palette.primary.loginBackground2},${theme.palette.primary.loginBackground})`}}>
                <Box position="absolute" display={{xs:'none',md:'block'}} width={300} height={100} top={0} left="40%" sx={{backgroundImage:`url(/images/grid_pattern_login.svg)`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}/>
                <Box position="absolute" display={{xs:'none',md:'block'}}  width={300} height={100} bottom={0} left={0} sx={{backgroundImage:`url(/images/grid_pattern_login.svg)`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}/>
                
                <Stack width='100%' height={'100%'} paddingRight={{xs:0,md:4}} paddingTop={{xs:16,md:0}}>
                    {children}
                </Stack>
            </Grid>
            <Box position="absolute" top={0} right={0} display="flex" gap={1} padding={2}>
                <IconButton>
                    <HelpOutlineOutlinedIcon  onClick={()=>{dispatch(toggleHelper())}} />
                </IconButton>
                {/* <IconButton>
                    <SettingsOutlinedIcon/>
                </IconButton> */}
            </Box>
        </Grid>
    )
}

export default LoginLayout