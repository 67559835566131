import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Skeleton,
  TextField,
  useMediaQuery,
  createFilterOptions,
  useTheme,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { SyntheticEvent, useEffect, useReducer, useState } from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import {
    MEAL_PREFERENCE,
  MEDICATION_HISTORY,
  PATIENT_HISTORY,
  SOCIAL_HISTORY,
} from "../../../../../../shared/constants";
import ReactQuill from "react-quill";

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  resize:none;
  outline: none;
  border:1px solid #CACACA;
`
);

const EditorModules = {
  toolbar: [
    ["bold", "italic", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const initState = {
  details: '',
}

export default function MedicationHistory(props: any) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([] as any);

  const sectionData = props?.sectionData && props?.sectionData?.[0];
  const setTabData = props?.setTabData;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
        ...state,
        ...action
    }
  },initState)

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const statusMaster = sectionData?.master?.status?.values || [];

  useEffect(() => {
    if (sectionData) {
      setData(sectionData);
      const data = sectionData?.value;
      const comment = data?.find((elem:any)=>elem?.name === 'comment')

      setState({
        details : comment? comment?.value : ''
      })
    }
    setLoading(false);
  }, [sectionData]);

  useEffect(()=>{
    console.log("data",data)
  },[data])

  const updateHistory = () => {
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === PATIENT_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === MEDICATION_HISTORY) {
                  return {
                    ...row,
                    value: [{
                        name:'comment',
                        value:state.details
                    }]
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && (
        <Box sx={{ my: isMobileScreen ? 0 : 3 }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mb: "21px",
              alignItems: "center",
            }}
          >
            {!isMobileScreen && (
              <span style={{ fontWeight: "600", color: "#000" }}>
                Medication History
              </span>
              
            )}

            <FormControlLabel
              sx={{
                ".css-1960bxs-MuiTypography-root": {
                  color: "#959595",
                },
              }}
              control={
                <Checkbox
                  onClick={() => {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === PATIENT_HISTORY) {
                          return {
                            ...section,
                            rows: section.rows.map((row: any) => {
                              if (row.view_type === MEDICATION_HISTORY) {
                                return {
                                  ...row,
                                  disable_value: !sectionData?.disable_value,
                                  value: [],
                                };
                              }
                              return row;
                            }),
                          };
                        }
                        return section;
                      });
                    });
                  }}
                  checked={sectionData?.disable_value}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
              }
              label="No known significant medication history"
            />
          </Box>
          {!sectionData?.disable_value && (
            <Box
                sx={{
                display: "flex",
                gap:2,
                justifyContent:'center',
                alignItems:'center',
                flexDirection: !isMobileScreen ? "row" : "column",
                }}
            >
                <Box sx={{flex:1,width:'100%'}}>
                  <ReactQuill
                      id="rx-examination-findings"
                      theme="snow"
                      placeholder="Enter details"
                      modules={EditorModules}
                      value={state.details}
                      onChange={(content, _delta, _source, editor) => {
                          let newComment = content;
                          if (editor.getLength() <= 1) {
                              newComment = "";
                          }
                          if (editor.getLength() > 1500) {
                              newComment = newComment.substring(0, 1500);
                          }
                          setState({
                              details:newComment
                          })
                      }}
                      onBlur={(e:any)=>{
                          updateHistory();
                      }}
                      style={{ color: "#000000" }}
                  />
                </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
