import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card from "../../utils/Card";
import CardHeader from "../../utils/CardHeader";

import { styled } from "@mui/system";
import { CARD_SHADOW, VITALS_VALIDATIONS } from "../../utils/Constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { VITALS } from "../../../../../shared/constants";
import { setEditRxSecMobile, setToggleShouldShowPastVitals } from "../../../../../redux/reducers/prescription.slice";

const BPInnerTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    maxWidth: 50,
    maxHeight: 24,
    padding: 0,
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& fieldset": {
      padding: 0,
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiOutlinedInput-root": {
        width: 77,
      },
    },
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    maxWidth: 204,
    height: 56,
  },
  "& .MuiFormHelperText-root": {
    margin: 0,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      maxWidth: "100%",
      width: "100%",
    },
  },
}));
const BPCustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    maxWidth: 204,
    height: 56,
    padding: "0 3px 0 3px",
  },
  "& .MuiFormHelperText-root": {
    margin: 0,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      maxWidth: "100%",
      width: "100%",
    },
  },
}));

export default function Vitals(props: any) {
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [systolicValue, setSystolicValue] = useState("" as any);
  const [diastolicValue, setDiastolicValue] = useState("" as any);
  const [validationErrors, setValidationErrors] = useState({}) as any;

  const dispatch = useDispatch();

  const isFocused: boolean = props.isFocused || false;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const sectionData = props?.sectionData?.[0];
  const { setTabData } = props;
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;

  const {
    prescription: { pastPrescriptions, prevPrescriptionDetails, instantRxData },
  } = useSelector((state: any) => state.prescription);

  useEffect(() => {
    if (sectionData) {
      const existingRows = sectionData.rows || [];
      const updatedTableRows = existingRows;
      let _systolicValue = "";
      let _diastolicValue = "";
      if (sectionData && sectionData.rows) {
        for (const row of sectionData.rows) {
          if (row.label === "BP") {
            const bpValues = row?.value?.split("/");
            if (bpValues) {
              _systolicValue = bpValues[0] || "";
              _diastolicValue = bpValues[1] || "";
            }
            break;
          }
        }
        setSystolicValue(_systolicValue);
        setDiastolicValue(_diastolicValue);
      }
      setLoading(false);
      setTableRows(updatedTableRows);
    }
    if (sectionData?.isExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(VITALS));
    }
  }, [sectionData]);

  const updateVitalsRows = (
    section: any,
    inputKey: any,
    numericValue: any,
    isInputValid: any
  ) => {
    // Ensure numericValue, systolicValue, and diastolicValue are not NaN
    const sanitizedNumericValue = isNaN(numericValue) ? "" : numericValue;
    const sanitizedSystolicValue = isNaN(systolicValue) ? "" : systolicValue;
    const sanitizedDiastolicValue = isNaN(diastolicValue) ? "" : diastolicValue;

    return section.rows.map((row: any) => {
      if (row.key === "bp") {
        let newValue;
        newValue = `${
          inputKey === "Systolic"
            ? isNaN(numericValue)
              ? ""
              : numericValue
            : systolicValue
        }/${
          inputKey === "Diastolic"
            ? isNaN(numericValue)
              ? ""
              : numericValue
            : diastolicValue
        }`;

        newValue = newValue.length === 1 ? "" : newValue;

        return {
          ...row,
          value: newValue,
        };
      }
      else if(row.key === 'bmi'){
        return {
          ...row,
          value: calculateBMI(),
        };
      }
      else if (row.key === inputKey) {
        return {
          ...row,
          value: isInputValid && !isNaN(numericValue) ? numericValue : "",
        };
      }
      return row;
    });
  };

  const validateData = (event: any) => {
    const inputKey = event.target.id;
    const inputValue = event.target.value;
    const numericValue = parseFloat(inputValue) as any;

    // Find the validation rule for the current input
    const validationRule = VITALS_VALIDATIONS.find(
      (rule) => rule.key === inputKey
    ) as any;

    const isInputValid =
      !isNaN(numericValue) &&
      validationRule &&
      numericValue >= validationRule.min &&
      ((numericValue <= validationRule.max) as any);

    // Update the state based on the input key
    switch (inputKey) {
      case "Systolic":
        setSystolicValue(isInputValid ? numericValue : "");
        break;
      case "Diastolic":
        setDiastolicValue(isInputValid ? numericValue : "");
        break;
    }

    // Check if Systolic or Diastolic values are invalid
    if (inputKey === "Systolic" || inputKey === "Diastolic") {
      const systolicRule = VITALS_VALIDATIONS.find(
        (rule) => rule.key === "Systolic"
      ) as any;
      const diastolicRule = VITALS_VALIDATIONS.find(
        (rule) => rule.key === "Diastolic"
      ) as any;

      const isSystolicValid =
        systolicValue === null ||
        (systolicValue >= systolicRule.min &&
          systolicValue <= systolicRule.max);

      const isDiastolicValid =
        diastolicValue === null ||
        (diastolicValue >= diastolicRule.min &&
          diastolicValue <= diastolicRule.max);
      const isSystolicLessThanDiastolic = Number(systolicValue) < Number(diastolicValue);

      if (
        isSystolicLessThanDiastolic &&
        systolicValue !== "" &&
        diastolicValue !== ""
      ) {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          ["bp"]: `Systolic value should be greater than Diastolic value`,
        }));
        return;
      } else if (!isSystolicValid && systolicValue !== "") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          ["bp"]: `${inputKey} should be between ${validationRule.min} and ${validationRule.max}`,
        }));
        return;
      } else if (!isDiastolicValid && diastolicValue !== "") {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          ["bp"]: `${inputKey} should be between ${validationRule.min} and ${validationRule.max}`,
        }));
        return;
      } else {
        setValidationErrors((prevErrors: any) => ({
          ...prevErrors,
          ["bp"]: "",
        }));
      }
    } else if (!isInputValid && inputValue !== "") {
      // Handle invalid input for other vitals
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [inputKey]: `Value should be between ${validationRule.min} and ${validationRule.max}`,
      }));
    } else {
      // Clear error for valid input
      setValidationErrors((prevErrors: any) => ({
        ...prevErrors,
        [inputKey]: "",
      }));
    }

    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === VITALS) {
          return {
            ...section,
            rows: updateVitalsRows(
              section,
              inputKey,
              numericValue,
              isInputValid
            ),
          };
        }
        return section;
      });
    });

    localStorage.setItem("errors", JSON.stringify(validationErrors));
  };

  const handleInputChange = (event: any) => {
    const validationRule = VITALS_VALIDATIONS.find(
      (rule) => rule.key === event.target.id
    ) as any;

    if (validationRule?.isFloat) {
      if (
        !/^\d*\.?\d*$/.test(event.target.value) ||
        event.target.value.length > 6
      ) {
        return;
      }
    } else {
      if (
        !/^[1-9][0-9]*$|^$/.test(event.target.value) ||
        event.target.value.length > 3
      ) {
        return;
      }
    }

    const inputKey = event.target.id;
    const inputValue = event.target.value;

    if (inputKey === "weight" && inputValue > 999) {
      return;
    }
    if (inputKey === "temperature" && inputValue > 999) {
      return;
    }

    const updatedRows: any = {};

    if (inputKey === "Systolic") {
      setSystolicValue(inputValue);
    } else if (inputKey === "Diastolic") {
      setDiastolicValue(inputValue);
    }

    const bpValue = `${inputKey === "Systolic" ? inputValue : systolicValue}/${
      inputKey === "Diastolic" ? inputValue : diastolicValue
    }`;

    updatedRows["bp"] = bpValue;

    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === VITALS) {
          return {
            ...section,
            rows: section.rows.map((row: any) => {
              if (
                row["key"] === "bmi" &&
                (row["value"] === "" || row["value"] === null)
              ) {
                // row["value"] = calculateBMI();
                return {
                  ...row,
                  value: calculateBMI(),
                };
              }
              if (row.key === "bp") {
                // Create a new object instead of modifying the existing one
                return {
                  ...row,
                  value: bpValue,
                };
              } else {
                // Create a new object instead of modifying the existing one
                const updatedRow = {
                  ...row,
                  value: row?.key === inputKey ? inputValue : row?.value,
                };
                updatedRows[row.key] = updatedRow.value;
                return updatedRow;
              }
            }),
          };
        }
        return section;
      });
    });
  };

  const calculateBMI = () => {
    let heightValue: any, weightValue: any, BMI: any;

    const height: any = tableRows.filter((row) => row.label === "Height");
    const weight: any = tableRows.filter((row) => row.label === "Weight");

    if (height[0] && weight[0]) {
      heightValue = height[0]?.value;
      weightValue = weight[0]?.value;

      BMI = (weightValue / (((heightValue / 100) * heightValue) / 100)).toFixed(
        2
      );
      if (!(+BMI >= 8 && +BMI <= 150)) BMI = "";
    } else {
      BMI = "";
    }
    return BMI;
  };

  const RenderPastVitalData = (vitalKey: string) => {
    const vitalData = prevPrescriptionDetails?.filter(
      (row: any) => row.view_type === VITALS
    );
    const vital = vitalData[0]?.rows?.filter(
      (field: any) => field.key === vitalKey
    );

    const lastDate: any = prevPrescriptionDetails?.filter(
      (row: any) => row.past_prescriptions
    )[0]?.past_prescriptions?.prescription_date;

    if (
      vitalData?.length !== 0 &&
      vital[0] &&
      vital[0].value &&
      vital[0].value !== "" &&
      lastDate
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: 204,
          }}
        >
          <span
            style={{
              fontSize: "12px",
              color: "#000000",
              opacity: "60%",
            }}
          >
            {`LR: ${vital[0].value} ${vital[0].unit}`}
          </span>
          <span
            style={{
              fontSize: "12px",
              color: "#000000",
              opacity: "60%",
            }}
          >
            {`${dayjs(lastDate).format("DD MMM'YY")}`}
          </span>
        </Box>
      );
    }
    return <></>;
  };

  const RenderInputGrid = () => {
    return (
      <Grid
        container
        wrap="wrap"
        sx={{ width: "100%" }}
        spacing={isMobileScreen ? 0.5 : 2}
        gap={2}
      >
        {isMobileScreen && (
          <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} position={'relative'}>
            <Button
              sx={{
                fontSize: "14px",
                color: "#494E9D",
                fontWeight: "500",
                letterSpacing: "0.4px",
                textTransform: "uppercase",
                position:'absolute',
                top:'-56px'
              }}
              onClick={handleClickViewPastVitalsData}
            >
              VIEW PAST VITALS DATA
            </Button>
          </Box>
        )}
        
        {!loading &&
          tableRows?.filter(elem=>!elem.group_title)?.map((elem: any) => {
            return (
              <Grid item key={elem?.key} md={2.78} sm={4} xs={5.7}>
                {elem?.label !== "BP" ? (
                  <CustomTextField
                    onChange={handleInputChange}
                    onBlur={validateData}
                    id={elem.key}
                    aria-label={elem.label}
                    sx={{
                      backgroundColor:
                        elem?.label !== "BMI" ? "unset" : "#f0f0f0",
                    }}
                    type="number"
                    disabled={elem?.label !== "BMI" ? false : true}
                    value={elem?.label !== "BMI" ? elem?.value : calculateBMI()}
                    autoComplete="off"
                    error={validationErrors[elem.key] ? true : false} // Set error prop based on validationErrors
                    helperText={validationErrors[elem.key]} // Show error message
                    InputProps={{
                      // readOnly: elem?.label !== "BMI" ? false : true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {elem?.unit}
                        </InputAdornment>
                      ),
                    }}
                    label={elem?.label}
                    placeholder={elem?.label}
                    variant="outlined"
                  />
                ) : (
                  // ...
                  <BPCustomTextField
                    // onChange={handleInputChange}
                    id="bp"
                    value={`${systolicValue}/${diastolicValue}`} // Display both systolic and diastolic values
                    autoComplete="off"
                    error={validationErrors["bp"] ? true : false}
                    helperText={validationErrors["bp"]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BPInnerTextField
                            onBlur={validateData}
                            autoComplete="off"
                            placeholder="SBP"
                            id="Systolic"
                            onChange={(event: any) => handleInputChange(event)}
                            value={systolicValue}
                            type="number"
                          />
                          <span
                            style={{
                              fontWeight: 400,
                              color: "#000000",
                              fontSize: "20px",
                            }}
                          >
                            /
                          </span>
                          <BPInnerTextField
                            sx={{ marginLeft: "10px" }}
                            onBlur={validateData}
                            autoComplete="off"
                            id="Diastolic"
                            placeholder="DBP"
                            type="number"
                            onChange={(event: any) => handleInputChange(event)}
                            value={diastolicValue}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {elem?.unit}
                        </InputAdornment>
                      ),
                    }}
                    aria-label={elem.label}
                    label={elem?.label}
                    placeholder={elem?.label}
                    sx={{
                      "& #bp": {
                        visibility: "hidden",
                        width: "0px",
                      },
                    }}
                    variant="outlined"
                  />

                  // ...
                )}
                {!validationErrors[elem.key] &&
                  prevPrescriptionDetails?.length !== 0 &&
                  RenderPastVitalData(elem.key)}
              </Grid>
            );
          })}

        {(!loading && tableRows?.filter(elem=>elem.group_title=='Blood Sugar Monitoring')?.length) ? (
          <Box display={'flex'} flexDirection={'column'} gap={isMobileScreen ? 0.5 : 2} px={isMobileScreen ? '4px' : '16px'} sx={{width:'100%'}}>
            <Divider light/>
            <Typography my={isMobileScreen ? 2 : 0} fontWeight={'bold'}>{'Blood Sugar Monitoring'}</Typography>
            <Grid
              container
              wrap="wrap"
              sx={{ width: "100%" }}
              spacing={isMobileScreen ? 0.5 : 2}
              gap={isMobileScreen ? 1.5 : 2}
            >
              {tableRows?.filter(elem=>elem.group_title=='Blood Sugar Monitoring')?.map((elem: any) => {
              return (
                <Grid item key={elem?.key} md={2.78} sm={4} xs={5.7}>
                  <CustomTextField
                    onChange={handleInputChange}
                    onBlur={validateData}
                    id={elem.key}
                    aria-label={elem.label}
                    sx={{
                      backgroundColor:
                        elem?.label !== "BMI" ? "unset" : "#f0f0f0",
                    }}
                    type="number"
                    disabled={elem?.label !== "BMI" ? false : true}
                    value={elem?.label !== "BMI" ? elem?.value : calculateBMI()}
                    autoComplete="off"
                    error={validationErrors[elem.key] ? true : false} // Set error prop based on validationErrors
                    helperText={validationErrors[elem.key]} // Show error message
                    InputProps={{
                      // readOnly: elem?.label !== "BMI" ? false : true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {elem?.unit}
                        </InputAdornment>
                      ),
                    }}
                    label={elem?.label}
                    placeholder={elem?.label}
                    variant="outlined"
                  />
                </Grid>
              )
            })}
            </Grid>
          </Box>
        ) : <></>}

        {(!loading && tableRows?.filter(elem=>elem.group_title=='Anthropometric Assessment')?.length) ? (
          <Box display={'flex'} flexDirection={'column'}gap={isMobileScreen ? 0.5 : 2} px={isMobileScreen ? '4px' : '16px'} sx={{width:'100%'}}>
            <Divider light/>
            <Typography my={isMobileScreen ? 2 : 0} fontWeight={'bold'}>{'Anthropometric Assessment'}</Typography>
            <Grid
              container
              wrap="wrap"
              sx={{ width: "100%" }}
              spacing={isMobileScreen ? 0.5 : 2}
              gap={isMobileScreen ? 1.5 : 2}
            >
              {tableRows?.filter(elem=>elem.group_title=='Anthropometric Assessment')?.map((elem: any) => {
              return (
                <Grid item key={elem?.key} md={2.78} sm={4} xs={5.7}>
                  <CustomTextField
                    onChange={handleInputChange}
                    onBlur={validateData}
                    id={elem.key}
                    aria-label={elem.label}
                    sx={{
                      backgroundColor:
                        elem?.label !== "BMI" ? "unset" : "#f0f0f0",
                    }}
                    type="number"
                    disabled={elem?.label !== "BMI" && elem?.label !== "WHR" ? false : true}
                    value={elem?.label !== "BMI" ? elem?.value : calculateBMI()}
                    autoComplete="off"
                    error={validationErrors[elem.key] ? true : false} // Set error prop based on validationErrors
                    helperText={validationErrors[elem.key]} // Show error message
                    InputProps={{
                      // readOnly: elem?.label !== "BMI" ? false : true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {elem?.unit}
                        </InputAdornment>
                      ),
                    }}
                    label={elem?.label}
                    placeholder={elem?.label}
                    variant="outlined"
                  />
                </Grid>
              )
            })}
            </Grid>
          </Box>
        ) : <></>}

      </Grid>
    );
  };

  const RenderReadOnly = () => {
    return (
      <Grid
        className="vital"
        container
        wrap="wrap"
        sx={{ width: "100%" }}
        gap={2}
      >
        {tableRows.map((elem) => {
          return (
            <Grid item key={elem?.key} xs={4} sx={{ maxWidth: "100%" }}>
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: "4px",
                    lineHeight: "14px",
                  }}
                >
                  {elem.label}:
                </span>
                {elem?.value?.length !== 0 && (
                  <span
                    style={{
                      color: "#6F6F6F",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "12px",
                    }}
                  >
                    {`${elem.label !== "BMI" ? elem.value : calculateBMI()}`}
                    &nbsp;{elem.unit}
                  </span>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const handleClickViewPastVitalsData = () => {
    dispatch(setToggleShouldShowPastVitals(true));
  }

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {/* Desktop view */}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
          }}
          title="Vitals"
          isReadOnly={props?.isReadOnly}
        >
          <CardHeader headerImg="icon_vitals.svg" headerTitle="Vitals">
            {/* {pastPrescriptions?.length !== 0 && (
              <Button
                sx={{
                  fontSize: "14px",
                  color: "#494E9D",
                  fontWeight: "500",
                  letterSpacing: "0.4px",
                  textTransform: "uppercase",
                }}
              >
                VIEW PAST VITALS DATA
              </Button>
            )} */}
            <Button
                sx={{
                  fontSize: "14px",
                  color: "#494E9D",
                  fontWeight: "500",
                  letterSpacing: "0.4px",
                  textTransform: "uppercase",
                }}
                onClick={handleClickViewPastVitalsData}
              >
                VIEW PAST VITALS DATA
              </Button>
            <></>
          </CardHeader>
          {RenderInputGrid()}
        </Card>
      )}
      {/* Mobile view  */}
      {!loading && isMobileScreen && editSection && RenderInputGrid()}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
    </>
  );
}
