/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
  Tooltip,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
} from "@mui/material";
import Card from "../../../utils/Card";
import CardHeader, { renderHeaderButtons } from "../../../utils/CardHeader";
import { FocusEvent, FocusEventHandler, SyntheticEvent, useEffect, useReducer, useState } from "react";

import { CARD_SHADOW } from "../../../utils/Constants";
import { styled } from "@mui/system";
import Slider from "react-slick";
import {
  ALLERGIES,
  FAMILY_HISTORY,
  MARITAL_HISTORY,
  MEAL_PREFERENCE,
  MEDICAL_HISTORY,
  MEDICAL_HISTORY_DIABETES,
  MENSTRUAL_HISTORY,
  OBG_HISTORY,
  PHYSICAL_ACTIVITY,
  PREGNANCY_HISTORY,
  SOCIAL_HISTORY,
  SURGICAL_HISTORY,
} from "../../../../../../shared/constants";
import { Next, Prev } from "../patienthistory/utils/arrows";
import { isPatientHistoryEmpty } from "../patienthistory/utils/shared";
import { AnyIfEmpty, useDispatch, useSelector } from "react-redux";
import {
  setEditRxSecMobile,
  setPrevPrescriptionDetails,
  setToggleRepopulateTable,
} from "../../../../../../redux/reducers/prescription.slice";
import { TabComponent, allyProps } from "../../../RightPanel";
import Gravida from "../obstetrichistory/Gravida";
import Abortion from "../obstetrichistory/Abortion";
import Ectopic from "../obstetrichistory/Ectopic";
import { DateField, DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import MaritalHistory from "./MaritalHistory";
import PregnancyHistory from "./PregnancyHistory";
import MenstrualHistory from "./MenstrualHistory";
import TemplateCard from "../../../utils/TemplateCard";

const patientHistoryProps = {
  ["Marital History"]: false,
  ["Pregnancy History"]: false,
};
const historyPropsMobile = {
  ["Menstrual History"]: false,
  ["Marital History"]: false,
  ["Pregnancy History"]: false,
};

const EditorModules = {
    toolbar: [
        ["bold", "italic", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
        matchVisual: false,
    },
};

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "16px",
    paddingLeft: 0,
    border: "none",
  },
});

const StyledChip = styled(Chip)({
  color: "#494E9D",
  backgroundColor: "#F2F3FF",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "18px",
  borderRadius: "100px",
  letterSpacing: "0.16px",
  marginRight: "13px",
  cursor: "pointer",
  userSelect: "none",
});

const EditButton = styled(Button)({
  width: 93,
  height: 32,
  border: "1px solid #494E9D",
  borderRadius: 4,
  color: "#494E9D",
  textTransform: "none",
});

const EmptyState: any = {
  MENSTRUAL_HISTORY: "No known significant menstrual history",
  MARITAL_HISTORY: "No known significant marital history",
  PREGNANCY_HISTORY: "No known significant pregnancy history",
};

const initState = {
    lmp:"",
    ageOfMenarche:'',
    daysOfBleeding:'',
    frequencyOfCycle:'',
    ageOfMenopause:'',
    addEDD:false,
    edd:'',
    eddScan:'',
    comments:'',
}

export default function OBGHistory(props: any) {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [showHistory, setShowHistory] = useState(patientHistoryProps);
  const [showHistoryMobile, setShowHistoryMobile] = useState(historyPropsMobile);
  const [toEdit, setEdit] = useState<boolean>();
  const [tabActive, setTabActive] = useState<number>(0);

  const sectionData = props.sectionData[0];
  const setTabData = props.setTabData;

  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isFocused: boolean = props.isFocused || false;
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>(false);
  const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
    useState<boolean>(false);

  const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
  const searchExistingTemplateMobile =
    props?.sectionData?.[0]?.searchExistingTemplateMobile;

  const {
    prescription: { prevPrescriptionDetails, rePopulateTable },
  } = useSelector((state: any) => state.prescription);

  const prevPatientHistory = prevPrescriptionDetails?.find(
    (section: any) => section.view_type === OBG_HISTORY
  );

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
      ...state,
      ...action
    }
  },initState)

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };


  useEffect(() => {
    if (sectionData) {

        let data = sectionData?.rows?.find((elem:any)=>elem.view_type === MENSTRUAL_HISTORY)?.value;
        setState({
            lmp:data?.find((elem:any)=>elem?.name === 'LMP')?.value ? dayjs(data?.find((elem:any)=>elem?.name === 'LMP')?.value,'DD/MM/YYYY') : '',
            ageOfMenarche:data?.find((elem:any)=>elem?.name === 'Age of menarche')?.value,
            daysOfBleeding:data?.find((elem:any)=>elem?.name === 'Days of bleeding')?.value,
            frequencyOfCycle:data?.find((elem:any)=>elem?.name === 'Frequency of cycle')?.value,
            ageOfMenopause:data?.find((elem:any)=>elem?.name === 'Age of menopause')?.value,
            addEDD:(!!data?.find((elem:any)=>elem?.name === 'EDD')?.value || !!data?.find((elem:any)=>elem?.name === 'EDD Scan')?.value),
            edd:dayjs(data?.find((elem:any)=>elem?.name === 'EDD')?.value,'DD/MM/YYYY'),
            eddScan:dayjs(data?.find((elem:any)=>elem?.name === 'EDD Scan')?.value,'DD/MM/YYYY'),
            comments:data?.find((elem:any)=>elem?.name === 'comment')?.value,
        })


      if (isSectionEmpty && prevPatientHistory && rePopulateTable) {
        if (!isPatientHistoryEmpty(prevPatientHistory)) {
          setTabData((prev: any) => {
            return prev.map((section: any) => {
              if (section.view_type === OBG_HISTORY) {
                return { ...prevPatientHistory };
              }
              return { ...section };
            });
          });
          dispatch(setToggleRepopulateTable(false));
        }
      } else {
        dispatch(setToggleRepopulateTable(false));
        setData(sectionData);
      }

      rePopulateTable && dispatch(setToggleRepopulateTable(false));

      if (isSectionEmpty && !isFocused) {
        setShowHistory(() => {
          return {
            ["Marital History"]: false,
            ["Pregnancy History"]: false,
          };
        });
      }
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (!isFocused) {
      setEdit(false);
    }
  }, [isFocused]);

  useEffect(()=>{
    if (!state.addEDD){
      setState({
        edd:'',
        eddScan:'',
      })
    }
  },[state.addEDD])

  useEffect(() => {
    if (
      isSectionEmpty &&
      !data?.rows?.some((row: any) => row.disable_value === true) &&
      !rePopulateTable
    ) {
      setEdit(true);
    }
  }, [isSectionEmpty]);

  useEffect(() => {
    if (
      data &&
      isPatientHistoryEmpty(data) &&
      !data?.rows?.some((row: any) => row.disable_value === true) &&
      !rePopulateTable
    ) {
      setEdit(true);
    }
  }, [toEdit]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(OBG_HISTORY));
    }
  }, [isAccExpanded]);

  const sliderSetting = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: (
      <Prev
        customStyles={{
          zIndex: "20",
          width: 24,
          height: 24,
          position: "absolute",
          left: "101%",
        }}
      />
    ),
    nextArrow: (
      <Next
        customStyles={{
          width: 24,
          height: 24,
          position: "absolute",
          right: "-55px",
        }}
      />
    ),
  };

  const sliderMobileSetting = {
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: (
      <Prev
        customStyles={{
          zIndex: "20",
          width: 20,
          height: 20,
          position: "absolute",
          left: "88%",
          top: "-10px",
        }}
      />
    ),
    nextArrow: (
      <Next
        customStyles={{
          width: 20,
          height: 20,
          position: "absolute",
          right: "-12px",
          top: "-10px",
        }}
      />
    ),
  };

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                return { ...row, value: item?.rows[index]?.value };
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const isFieldDisabled = () => {
    const disabledFields = data?.rows?.filter(
      (row: any) => row.disable_value === true
    );

    if (disabledFields?.length === 0) return ShowEditableContent();

    const viewTypes = disabledFields?.map((row: any) => row.view_type);

    return (
      <Box
        sx={{
          color: "#959595",
          fontWeight: 400,
          lineHeight: "132%",
        }}
        component="span"
      >
        {viewTypes?.map((view: any) => EmptyState[view]).join(", ")}
      </Box>
    );
  };

  const renderComplaints = (view_type: string, value: any) => {
    const EmptyValueLabel = () => {
      return (
        <StyledTableCell
          sx={{
            display: "flex",
            flexDirection: "column",
            borderBottom: isMobileScreen ? "none" : "1px solid #eee",
            padding: isMobileScreen ? "8px 8px 8px 0px" : "16px",
            width: "100%",
          }}
          key={view_type}

        >
          <Box
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "132%",
              marginBottom: "5px",
            }}
            component="span"
          >
            {EmptyState[view_type]}
          </Box>
        </StyledTableCell>
      );
    };

    if (value && value?.value) {
      return value?.map((item: any) => {
        let { name, value } = item;
        let label;
        let icons:any[] = [];

        switch (view_type) {
          case MENSTRUAL_HISTORY:
            label = `${value}`;
            break;
          case MARITAL_HISTORY:
            label = `${value}`;
            break;
          case PREGNANCY_HISTORY:
            label = `${value}`;
            break;
        }

        const content = (
          <>
            <Box
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "132%",
                marginBottom: "5px",
              }}
              component="span"
            >
              {name}
            </Box>
            {label && (
              <Box
                sx={{
                  color: "#878787",
                  fontWeight: 400,
                  lineHeight: "132%",
                }}
                component="span"
              >
                {label}
              </Box>
            )}
          </>
        );

        return (
          <>
            <StyledTableCell
              className="histories"
              sx={{
                display: "flex",
                flexDirection: "column",
                borderBottom: isMobileScreen ? "none" : "1px solid #eee",
                padding: isMobileScreen ? "8px 8px 8px 0px" : "16px",
                maxWidth:  !isMobileScreen ? "200px" : undefined
              }}
              key={name}
            >
              {Array.isArray(icons) && icons.length !== 0 ? (
                // <Box
                //   className="SociakHistory"
                //   sx={{ display: "flex", alignItems: "center" }}
                // >
                //   <span style={{ marginRight: "9px" }}>
                //     <img
                //       src={`/images/${
                //         icons.filter((iName: any) => iName === name)[0]
                //       }.svg`}
                //     />
                //   </span>
                //   <Box
                //     className="SociakHistory"
                //     sx={{
                //       display: "flex",
                //       flexDirection: "column",
                //       justifyContent: isMobileScreen
                //         ? "space-between"
                //         : "center",
                //     }}
                //   >
                //     {content}
                //   </Box>
                // </Box>
                <></>
              ) : (
                content
              )}
            </StyledTableCell>
          </>
        );
      });
    }

    return <EmptyValueLabel />
  };

  const getValueContainerWidth = () => {
    const tableRow: any = document.querySelector(
      "#preview-medical-histories tr"
    );
    const labelCell: any = tableRow?.querySelector(`td`);
    const width: any = tableRow?.clientWidth - labelCell?.clientWidth;

    return width;
  };

  const ShowTableView = () => {
    return (
      <Box>
        <TableContainer
          sx={{
            boxShadow: "none",
            overflow: "hidden",
          }}
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-label="preview-medical-histories"
            id="preview-medical-histories"
          >
            <TableBody>
              {sectionData?.rows?.map((pHistory: any) => {
                const { header, view_type, value } = pHistory;
                return (
                  <>
                    <TableRow
                      key={view_type}
                      sx={{ borderBottom: "1px solid #E6E6E6" }}
                    >
                      <StyledTableCell id={header} align="left">
                        <StyledChip
                          clickable={false}
                          sx={{
                            width: 157,
                            height: 35,
                            borderRadius: 24,
                            fontWeight: 600,
                            lineHeight: "19px",
                            letterSpacing: "-0.28px",
                            cursor: "auto",
                          }}
                          label={header}
                        />
                      </StyledTableCell>
                      {Array.isArray(value) ? (
                        <Box
                          key={view_type + " values"}
                          sx={{
                            width: !isNaN(getValueContainerWidth())
                              ? getValueContainerWidth() - 50
                              : 650,
                            maxWidth: getValueContainerWidth(),
                          }}
                        >
                          <Slider {...sliderSetting}>
                            {renderComplaints(view_type, value)}
                          </Slider>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          {renderComplaints(view_type, value)}
                        </Box>
                      )}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const validateInput = (value:any,type:string)=>{
    switch(type){
        case 'ageOfMenarche':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'daysOfBleeding':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'frequencyOfCycle':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'ageOfMenopause':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'gravida':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'para':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'live':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'abortion':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
  }
  
  const updateHistory = (event?:SyntheticEvent, value?: string | null, forKey?:string)=>{
    
    const target =
      (event?.target as HTMLInputElement) ||
      (event?.target as HTMLElement);

    const ariaLabel = target?.ariaLabel as string;
    const inputName = target?.name as string;

    const key = forKey || inputName;

    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === OBG_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === MENSTRUAL_HISTORY) {
                  return {
                    ...row,
                    value: row?.value?.map((val: any) => {
                      if (val?.name === key) {
                        return {
                          ...val,
                          value: value,
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
    return;
  }

  const ShowEditableContent = () => {
    const MenstrualHistoryRef = (
      <MenstrualHistory
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === MENSTRUAL_HISTORY
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    const MaritalHistoryRef = (
      <MaritalHistory
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === MARITAL_HISTORY
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    const PregnancyHistoryRef = (
      <PregnancyHistory
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === PREGNANCY_HISTORY
        )}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    );
    
    if (!isMobileScreen) {
      return (
        <>

          <Box display={'flex'} flexDirection={'column'} gap={2} mb={2}>
            
            <span style={{ fontWeight: "600", color: "#000" }}>
                Menstrual history
            </span>

            <Box display={'flex'} gap={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="LMP"
                        format="DD/MM/YYYY"
                        value={state.lmp}
                        onChange={(newValue:any) => {
                            setState({lmp:newValue})
                            let date = dayjs(newValue)?.format('DD/MM/YYYY');
                            updateHistory(undefined,date!='Invalid Date' ? date : '','LMP')
                        }}
                        slotProps={{
                          textField: {
                              error: false,
                              onKeyDown:(e:any)=>{
                                  e.preventDefault();
                              },
                              placeholder: "Select a date",
                              inputProps: {
                                  placeholder: "Select a date"
                              }
                          },
                        }}
                        
                        
                    />
                </LocalizationProvider>
                <TextField
                    name={`Age of menarche`}
                    label={'Age of menarche'}
                    value={state.ageOfMenarche}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'ageOfMenarche')){
                            setState({
                                ageOfMenarche:e.target.value
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                    
                />
                <TextField
                    name={`Days of bleeding`}
                    label={'Days of bleeding'}
                    value={state.daysOfBleeding}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'daysOfBleeding')){
                            setState({
                                daysOfBleeding:e.target.value
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                    
                />
                <TextField
                    name={`Frequency of cycle`}
                    label={'Frequency of cycle'}
                    value={state.frequencyOfCycle}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'frequencyOfCycle')){
                            setState({
                                frequencyOfCycle:e.target.value
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                    
                />
                <TextField
                    name={`Age of menopause`}
                    label={'Age of menopause'}
                    value={state.ageOfMenopause}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'ageOfMenopause')){
                            setState({
                                ageOfMenopause:e.target.value
                            })
                        }
                    }}
                    onBlur={(event: any) => {
                        updateHistory(event, event.target.value);
                    }}
                />
            </Box>
            <Box display={'flex'} justifyContent={'start'}>
                <FormControlLabel control={    
                    <Checkbox
                        checked={state.addEDD} 
                        onClick={(e:any)=>{
                            setState({addEDD:!state.addEDD});
                        }} 
                
                    />} label={"Add EDD"} 
                />
            </Box>

            {state.addEDD && (
                <Box display={'flex'} justifyContent={'start'} gap={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="EDD"
                            value={state.edd}
                            format="DD/MM/YYYY"
                            onChange={(newValue:any) => {
                                setState({edd:newValue})
                                let date = dayjs(newValue)?.format('DD/MM/YYYY');
                                updateHistory(undefined,date!='Invalid Date' ? date : '','EDD')
                            }}
                            slotProps={{
                                textField: {
                                    error: false,
                                    onKeyDown:(e:any)=>{
                                        e.preventDefault();
                                    }
                                },
                            }}
                            
                        />
                        
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="EDD Scan"
                            value={state.eddScan}
                            format="DD/MM/YYYY"
                            onChange={(newValue:any) => {
                                setState({eddScan:newValue})
                                let date = dayjs(newValue)?.format('DD/MM/YYYY');
                                updateHistory(undefined,date!='Invalid Date' ? date : '','EDD Scan')
                            }}
                            slotProps={{
                                textField: {
                                    error: false,
                                    onKeyDown:(e:any)=>{
                                        e.preventDefault();
                                    }
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            )}

            <ReactQuill
                id="rx-examination-findings"
                theme="snow"
                placeholder="Enter Comments"
                modules={EditorModules}
                value={state.comments}
                onChange={(content, _delta, _source, editor) => {
                    let newComment = content;
                    if (editor.getLength() <= 1) {
                        newComment = "";
                    }
                    if (editor.getLength() > 1500) {
                        newComment = newComment.substring(0, 1500);
                    }
                    setState({
                        comments:newComment
                    })
                    // setTabData((prev: any) => {
                //     return prev.map((section: any) => {
                //     if (section.view_type === MENSTRUAL_HISTORY) {
                //         return {
                //         ...section,
                //         rows: section.rows.map((row: any, index: number) => {
                //             if (index === 0) {
                //             return {
                //                 ...row,
                //                 value: row.value.map((val: any, index: number) => {
                //                 if (index === 0) {
                //                     return {
                //                     ...val,
                //                     comments: newComment ? newComment : "",
                //                     };
                //                 }
                //                 return val;
                //                 }),
                //             };
                //             }
                //             return row;
                //         }),
                //         };
                //     }
                //     return section;
                //     });
                // });
                }}
                onBlur={(e:any)=>{
                    updateHistory(e,state.comments,'comment');
                }}
                style={{ color: "#000000" }}
            />  

          </Box>


          {showHistory["Marital History"] && MaritalHistoryRef}
          {showHistory["Pregnancy History"] && PregnancyHistoryRef}

          <>
            {Object.values(showHistory).includes(false) && (
              <>
                <Divider sx={{ mx: 0, mb: 2 }} variant="middle" />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      marginRight: "16px",
                      color: "#000000",
                      opacity: "87%",
                    }}
                  >
                    Add
                  </span>
                  <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                    {Object.entries(showHistory).map((arr) => {
                      const [key, value] = arr;
                      if (!value) {
                        return (
                          <Box
                            sx={{ mr: 2, cursor: "pointer" }}
                            onClick={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              const ariaLabel = target.ariaLabel as string;
                              setShowHistory({
                                ...showHistory,
                                [ariaLabel]: true,
                              });
                            }}
                            aria-label={key}
                            key={key}
                          >
                            <StyledChip
                              sx={{ pointerEvents: "none" }}
                              avatar={
                                <Avatar alt="" src="/images/icon_add.svg" />
                              }
                              label={key}
                            />
                          </Box>
                        );
                      }
                    })}
                  </Box>
                  
                </Box>
              </>
            )}
          </>
        </>
      );
    }
    return (
      <>
        <TabComponent tabActive={tabActive} index={0}>
          {MenstrualHistoryRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={1}>
          {MaritalHistoryRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={2}>
          {PregnancyHistoryRef}
        </TabComponent>
      </>
    );
  };

  const RenderReadOnly = () => {
    return (
      <Box>
        {data?.rows?.map((item: any) => {
          return (
            <Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "rgba(73, 78, 157, 1)",
                  }}
                >
                  {item?.header}
                </Typography>
              </Box>
              {item?.value?.length > 3 ? (
                <Box className="slider-container">
                  <Slider {...sliderMobileSetting}>
                    {renderComplaints(item?.view_type, item?.value)}
                  </Slider>
                </Box>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {renderComplaints(item?.view_type, item?.value)}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  const RenderData = () => {
    if (!isPatientHistoryEmpty(sectionData)) {
      return ShowTableView();
    }
    return isFieldDisabled();
  };

  const handleButtonClick = async (imageName: string) => {
    if (imageName === "icon_save.svg") {
      if (isMobileScreen) {
        // handleButtonClickMiniTMobile(imageName); // review-code
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === OBG_HISTORY) {
              return {
                ...section,
                openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                searchExistingTemplateMobile: false,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenSaveTemplate(!openSaveTemplate);
      setOpenUpdateExistingTemplate(false);
    } else if (imageName === "icon_add_page.svg") {
      if (isMobileScreen) {
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === OBG_HISTORY) {
              return {
                ...section,
                openSaveTemplateMobile: false,
                searchExistingTemplateMobile:
                  !section.searchExistingTemplateMobile,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenUpdateExistingTemplate(!serachExistingTemplate);
      setOpenSaveTemplate(false);
    }
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position:'relative'
          }}
          title="OBG History"
          isReadOnly={props?.isReadOnly}
        >
          <CardHeader
            headerImg="icon_obg_history.svg"
            headerTitle="OBG History"
          >
              <>
                {renderHeaderButtons(
                  ["icon_save.svg", "icon_add_page.svg"],
                  handleButtonClick
                )}
              </>
          </CardHeader>
          {ShowEditableContent()}
          {openSaveTemplate && (
            <Box position="absolute" top="68px" right="79px" zIndex="1">
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {serachExistingTemplate && (
            <Box position="absolute" top="68px" right="39px" zIndex="1">
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
      {!loading && isMobileScreen && editSection && (
        <>
          <Divider sx={{ mx: 0 }} variant="middle" />
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                background: "transparent",
              }}
            >
              <Tabs
                value={tabActive}
                onChange={(_e, newValue) => {
                  setTabActive(newValue);
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                {Object.entries(showHistoryMobile).map((arr, index) => {
                  const [key] = arr;
                  return <Tab label={key} {...allyProps(index)} />;
                })}
              </Tabs>
            </Box>
            {ShowEditableContent()}
          </Box>
          {openSaveTemplateMobile && (
            <Box sx={SaveTemplateStyle}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {searchExistingTemplateMobile && (
            <Box sx={AddMiniTemplateStyle}>
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}
