import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  HTMLAttributes,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { getDurationOptions } from "../../../../../shared/Utils/utilities";
import CardHeader, { renderHeaderButtons } from "../../utils/CardHeader";
import Card from "../../utils/Card";
import styled from "@emotion/styled";
import { useDebounce } from "../../../../../shared/Utils/useDebounce";
import {
  fetchSuggestions,
  seperateUrlAndParams,
} from "../../../../../shared/Utils/utils";
import { CARD_SHADOW } from "../../utils/Constants";
import { INVESTIGATIONS } from "../../../../../shared/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setCopyData,
  setEditRxSecMobile,
} from "../../../../../redux/reducers/prescription.slice";
import DoctorSuggestions from "../../utils/DoctorSuggestions";
import TemplateCard from "../../utils/TemplateCard";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import CloseIcon from "@mui/icons-material/Close";

interface InvestigationProps {
  id: string;
  name: string;
  test_on: string;
  repeat_on: string;
  comment: string;
  diagnostic_type: string;
}

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "8px",
    paddingLeft: 0,
    border: "none",
  },
});

const TableHeaders = [
  "",
  "Lab Test",
  "Test On",
  "Repeat On",
  "Special Instructions",
];

const CustomDropDownPaper = styled(Paper)({
  minWidth: 900,
});

const CustomTextarea = styled(BaseTextareaAutosize)(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  fontFamily: "Roboto, sans-serif",
  fontSize: "16px",
  fontWeight: 400,
  padding: "12px",
  lineHeight: "150%",
  letterSpacing: "0.15px",
  border: `2px solid rgba(0, 0, 0, 0.23)`,
  borderRadius: "4px",
  "&:hover": {
    borderColor: "#494E9D",
  },
  "&:focus-visible": {
    border: "2px solid #494E9D",
    outline: "#494E9D 2px",
  },
}));

export default function LabInvestigation(props: any) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [durationOptions, setDurationOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<InvestigationProps[]>([]);
  const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>();
  const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
    useState<boolean>();

  const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
  const searchExistingTemplateMobile =
    props?.sectionData?.[0]?.searchExistingTemplateMobile;

  const [searchValue, setSearchValue] = useState("");
  const [valUpdateSearch, setValUpdateSearch] = useState("");
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {} as InvestigationProps,
  });

  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);

  const isFocused: boolean = props.isFocused || false;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const sectionData = props?.sectionData?.[0]?.rows[0];
  const setTabData = props?.setTabData;
  const {
    copyData,
    prescription: { instantRxData, doctorSuggestions },
  } = useSelector((state: any) => state.prescription);

  const MiniTemplateStyle = {
    position: !isMobileScreen ? "absolute" : "none",
    top: "68px",
    right: "79px",
    zIndex: 1,
    height: "0px",
  };

  const AddMiniTemplateStyle = {
    position: !isMobileScreen ? "absolute" : "none",
    top: "68px",
    right: "39px",
    zIndex: 1,
    height: "0px",
  };

  const AutoCompleteStyle = {
      "& .MuiInputBase-input":{
        fontSize: "14px",
      },
      "& .MuiInputBase-root":{
          padding: "0px !important",
      }
  }

  const autoref = useRef<HTMLDivElement>(null);

  const apiurldata = sectionData?.search?.api;

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(INVESTIGATIONS));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === INVESTIGATIONS
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  const updateSearchOptions = (value: any) => {
    if (apiurldata && value) {
      const { url, query_params } = seperateUrlAndParams(apiurldata);
      fetchSuggestions(
        url,
        {
          ...query_params,
          name: value,
        },
        true // use custom base url
      ).then((response: any) => {
        const responseArr = response?.data?.data;
        const optionsArr = responseArr?.map((option: any) => option.name);
        if (!optionsArr.some((opt: any) => opt === value)) {
          setOptions([value, ...optionsArr]);
          const diagnosticType = responseArr.find(
            (val: any) => val.name === value
          )?.diagnostic_type;
          setOptionsData([
            {
              id: `custom${Date.now()}`,
              name: value,
              diagnostic_type: diagnosticType ? diagnosticType : "",
              type: "",
            },
            ...responseArr,
          ]);
          return;
        }
        setOptions(optionsArr);
        setOptionsData(responseArr);
      });
    }
  };
  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length !== 0 && searchValue.trim().length >= 3) {
      updateSearchOptions(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (valUpdateSearch.length !== 0) {
      updateSearchOptions(altDebouncedSearch);
    }
  }, [altDebouncedSearch]);

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === INVESTIGATIONS) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  useEffect(() => {
    if (copyData?.view_type === INVESTIGATIONS) {
      handleSelectedTemplateData(copyData);
      dispatch(setCopyData([] as any));
    }
  }, [copyData]);

  const assignAddLabInvestigationMobile = (value: string) => {
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;

    const diagnosticTypeSuggestion = doctorSuggestions.investigations.find(
      (suggestion: any) => suggestion.name === value
    )?.diagnostic_type;

    const diagnostic_type = optionObj?.diagnostic_type
      ? optionObj.diagnostic_type
      : diagnosticTypeSuggestion
      ? diagnosticTypeSuggestion
      : "";

    if (!value) return;
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        id,
        name,
        diagnostic_type,
        test_on: "",
        repeat_on: "",
        comment: "",
      },
    });
  };

  const doctorSuggestionProps = {
    sKey: "investigations",
    sHeading: "Suggested investigation",
    headerImg: isMobileScreen
      ? "/images/icon_mobile_resp_suggestion.svg"
      : "/images/icon_dummy.svg",
    handleAdd: (event: any) => {
      const currentTarget = event?.currentTarget;
      const suggestion = currentTarget?.attributes["data-key"]?.nodeValue;
      const clickAction = currentTarget?.attributes["data-action"]?.nodeValue;

      if (
        !data.some((investigation: any) => investigation.name === suggestion)
      ) {
        switch (clickAction) {
          case "ADD":
            if (!isMobileScreen) {
              addInvestigation(null as any, suggestion);
            } else {
              assignAddLabInvestigationMobile(suggestion);
            }
            break;
          case "UPDATE":
            updateInvestigation(event as any, suggestion);
            break;
          default:
            break;
        }
      }
      if (autoref.current) {
        autoref.current.blur();
      }
    },
  };

  const addInvestigation = (event: SyntheticEvent, value: string | null) => {
    if (!value) {
      return;
    }

    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;

    const diagnosticTypeSuggestion = doctorSuggestions?.investigations?.find(
      (suggestion: any) => suggestion.name === value
    )?.diagnostic_type;

    const diagnostic_type = optionObj?.diagnostic_type
      ? optionObj.diagnostic_type
      : diagnosticTypeSuggestion
      ? diagnosticTypeSuggestion
      : "";

    const newLabInvestigation: InvestigationProps = {
      id,
      name,
      diagnostic_type,
      test_on: "",
      repeat_on: "",
      comment: "",
    };
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === INVESTIGATIONS) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: [...row.value, newLabInvestigation],
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
    setSearchValue("");
  };

  const updateInvestigation = (event: SyntheticEvent, value: string | null) => {
    let newValue: any,
      newId: any,
      other: any = {};

    const target =
      (event.currentTarget as HTMLInputElement) ||
      (event.currentTarget as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName).split("-");

    const key = nameArr[nameArr.length - 1];

    const currId = nameArr.reduce((acc, val) => {
      if (val === key) {
        return acc;
      }
      return acc + "-" + val;
    });

    if (key === "name") {
      const optionObj = optionsData.find(
        (optObj: any) => optObj.name === value
      );
      const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
      const name = optionObj?.name ? optionObj.name : value;
      const diagnostic_type = optionObj?.diagnostic_type
        ? optionObj.diagnostic_type
        : "";
      newValue = name;
      other = { diagnostic_type };
      newId = id;
    } else {
      (newId = currId), (newValue = value);
    }

    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === INVESTIGATIONS) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.map((val: any) => {
                    if (val?.id?.toString() === currId) {
                      return {
                        ...val,
                        id: newId,
                        [key]: newValue,
                        ...other,
                      };
                    }
                    return val;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setDurationOptions([]);
  };

  const removeInvestigation = (event: SyntheticEvent) => {
    const elem = event.currentTarget as HTMLElement;
    const { id } = elem;
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === INVESTIGATIONS) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (investigation: any) => investigation?.id?.toString() !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
  };

  const updateDurationOptions = (event: SyntheticEvent<Element, Event>) => {
    const elem = event.target as HTMLInputElement;
    const { value } = elem;

    if (!/^[1-9][0-9]*$/.test(value) || value === "") {
      setDurationOptions([]);
      return;
    }

    const optionArr = getDurationOptions(value,true);

    if (optionArr) {
      const mappedOptionArr = optionArr.map((opt) => `After ` + opt);
      setDurationOptions(["Today", "Same day", ...mappedOptionArr]);
    }
  };
  const handleButtonClick = async (imageName: string) => {
    if (imageName === "icon_save.svg") {
      if (isMobileScreen) {
        // handleButtonClickMiniTMobile(imageName); // review-code
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === INVESTIGATIONS) {
              return {
                ...section,
                openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                searchExistingTemplateMobile: false,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenSaveTemplate(!openSaveTemplate);
      setOpenUpdateExistingTemplate(false);
    } else if (imageName === "icon_add_page.svg") {
      if (isMobileScreen) {
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === INVESTIGATIONS) {
              return {
                ...section,
                openSaveTemplateMobile: false,
                searchExistingTemplateMobile:
                  !section.searchExistingTemplateMobile,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenUpdateExistingTemplate(!serachExistingTemplate);
      setOpenSaveTemplate(false);
    }
  };

  const handleFocus = () => {
    setDurationOptions([]);
  };

  const handleDialogState = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
      // content :
    });
  };

  const dialogProps = {
    DialogOpen: dialog.open ? dialog.open : false,
    onHandleDialog: handleDialogState,
    headerStyles: { display: "none" },
    PaperPropsStyle: {
      margin: 0,
      width: "100%",
    },
    Layoutstyles: {
      "& .MuiDialog-container": {
        alignItems: "flex-end",
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
      },
    },
    footerStyles: {
      display: "none",
    },
    CloseButtonStyles:{
      display: "none",
    },
    onHandleSave: () => {
      // not required, custom save option is there
      return;
    },
  };

  const LabInvestigationCardMobile = (
    Investigation: InvestigationProps,
    index: number
  ) => {
    const { id, name, test_on, repeat_on, comment } = Investigation;
    return (
      <Card
        styles={{
          boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.08)`,
          padding: 0,
          borderRadius: "8px",
        }}
        key={index + id}
      >
        <Box id={id}>
          {/* Card Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <Typography
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              color="#494E9D"
            >
              {name}
            </Typography>
            <IconButton>
              <Box
                id={id}
                aria-label="delete-investigation"
                onClick={removeInvestigation}
                component="img"
                src="/images/DeleteIcon.svg"
              ></Box>
            </IconButton>
          </Box>
          <Divider />
          {/* Card Contents */}
          <Box padding="12px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginRight: "25%",
                }}
              >
                <Box
                  component="img"
                  marginRight="4px"
                  src="/images/ClockIcon.svg"
                ></Box>
                <Box>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="100%"
                    marginBottom="8px"
                    color="rgba(0, 0, 0, 0.87)"
                  >
                    Test on
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="100%"
                    color="#6F6F6F"
                  >
                    {test_on}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Box
                  component="img"
                  marginRight="4px"
                  src="/images/mobile_tick_icon.svg"
                ></Box>
                <Box>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="100%"
                    marginBottom="8px"
                    color="rgba(0, 0, 0, 0.87)"
                  >
                    Repeat on
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="100%"
                    color="#6F6F6F"
                  >
                    {repeat_on}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Box
                component="img"
                marginRight="4px"
                src="/images/SpecInstrIcon.svg"
              ></Box>
              <Box>
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.87)"
                >
                  Special Instructions
                </Typography>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="160%"
                  color="#6F6F6F"
                >
                  {comment}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    );
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        {data.map((datum, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
              }}
              key={index}
            >
              {datum.name}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Lab Investigation"
        >
          <CardHeader
            headerImg="icon_lab_inv.svg"
            headerTitle="Lab Investigation"
          >
            {renderHeaderButtons(
              ["icon_save.svg", "icon_add_page.svg"],
              handleButtonClick
            )}
          </CardHeader>

          {data.length !== 0 && (
            <TableContainer sx={{ mb: 2, boxShadow: "none" }} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="all-family-histories">
                <TableHead>
                  <TableRow
                    sx={{
                      background: "#f2f3ff",
                      color: "#00000",
                    }}
                  >
                    {TableHeaders.map((header) => {
                      return (
                        <TableCell
                          sx={{
                            fontWeight: 700,
                            opacity: 1,
                            lineHeight: "24px",
                          }}
                          key={header}
                        >
                          {header}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((medication, index) => {
                    const {
                      id,
                      name,
                      test_on,
                      repeat_on,
                      comment,
                      diagnostic_type,
                    } = medication;

                    return (
                      <TableRow key={`medicaction-${index}`} sx={{verticalAlign:"top"}}>
                        <StyledTableCell sx={{ width: 50 }}>
                          {diagnostic_type === "PATHOLOGY" ? (
                            <img
                              style={{ pointerEvents: "none" }}
                              src="/images/pathology.svg"
                              alt="pathology"
                            />
                          ) : diagnostic_type === "RADIOLOGY" ? (
                            <img
                              style={{ pointerEvents: "none" }}
                              src="/images/radiology.svg"
                              alt="pathology"
                            />
                          ) : (
                            <img
                              style={{ pointerEvents: "none" }}
                              src="/images/others_diagnostic_type.svg"
                              alt="others"
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Autocomplete
                            id={`${id}-name`}
                            aria-label={`${id}-name`}
                            filterOptions={(options, { inputValue }) => {
                              return options.filter((option) => true);
                            }}
                            options={
                              valUpdateSearch.length === 0 ? [] : options
                            }
                            disableClearable
                            value={name}
                            clearOnBlur
                            clearOnEscape
                            popupIcon={false}
                            clearIcon={false}
                            onChange={updateInvestigation}
                            PaperComponent={({ children }) => (
                              <CustomDropDownPaper>
                                {children}
                              </CustomDropDownPaper>
                            )}
                            sx={AutoCompleteStyle}
                            noOptionsText={
                              <DoctorSuggestions
                                clickSource={`${id}-name`}
                                clickAction="UPDATE"
                                {...doctorSuggestionProps}
                              />
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  inputRef={autoref}
                                  multiline
                                  onChange={(event: any) => {
                                    if (event.target.value === "") {
                                      setOptions([]);
                                    }
                                    setValUpdateSearch(event.target.value);
                                  }}
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                />
                              );
                            }}
                            renderOption={(
                              props: HTMLAttributes<HTMLLIElement>,
                              option: string,
                              state
                            ) => {
                              // if (
                              //   !data.some(
                              //     (investigation: any) =>
                              //       investigation.name === option
                              //   )
                              // ) {
                                return (
                                  <Box
                                    component="li"
                                    aria-label={`${id}-name`}
                                    sx={{
                                      backgroundColor: state.selected
                                        ? "#F2F3FF !important"
                                        : "",
                                      "&:hover, &:focus": {
                                        backgroundColor: "#F2F3FF !important",
                                      },
                                    }}
                                    {...props}
                                  >
                                    {!optionsData
                                      .find((opt: any) => opt.name === option)
                                      ?.id?.toString()
                                      .includes("custom")
                                      ? option
                                      : `Add "${option}"`}
                                  </Box>
                                );
                              // }
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Autocomplete
                            // disableClearable
                            filterOptions={(options, { inputValue }) => {
                              return options.filter(
                                (option) =>
                                  option
                                    .replace("-", " ")
                                    .toLowerCase()
                                    .includes(
                                      inputValue
                                        .trim()
                                        .replace("-", " ")
                                        .toLowerCase()
                                    ) || option === "Today"
                              );
                            }}
                            id={`${id}-test_on`}
                            aria-label={`${id}-test_on`}
                            options={durationOptions}
                            popupIcon={false}
                            clearIcon={false}
                            noOptionsText=""
                            onChange={updateInvestigation}
                            title={test_on}
                            value={test_on}
                            sx={AutoCompleteStyle}
                            renderOption={(props, option, state) => {
                              return (
                                <Box
                                  aria-label={`${id}-test_on`}
                                  component="li"
                                  sx={{
                                    backgroundColor: state.selected
                                      ? "#F2F3FF !important"
                                      : "",
                                    "&:hover, &:focus": {
                                      backgroundColor: "#F2F3FF !important",
                                    },
                                  }}
                                  id={`${id}-test_on-${option}`}
                                  {...props}
                                >
                                  {option}
                                </Box>
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  sx={
                                    {
                                      // ...(test_on && test_on !== ""
                                      //   ? hideBorders
                                      //   : {}),
                                    }
                                  }
                                  name={`${id}-test_on`}
                                  placeholder="Eg: 1 day"
                                  onChange={updateDurationOptions}
                                  onFocus={handleFocus}
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                />
                              );
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Autocomplete
                            // disableClearable
                            filterOptions={(options, { inputValue }) => {
                              return options.filter(
                                (option) =>
                                  option
                                    .replace("-", " ")
                                    .toLowerCase()
                                    .includes(
                                      inputValue
                                        .trim()
                                        .replace("-", " ")
                                        .toLowerCase()
                                    ) || option === "Same day"
                              );
                            }}
                            id={`${id}-repeat_on`}
                            aria-label={`${id}-repeat_on`}
                            options={durationOptions}
                            onChange={updateInvestigation}
                            sx={AutoCompleteStyle}
                            value={repeat_on}
                            // title={repeat_on}
                            popupIcon={false}
                            clearIcon={false}
                            noOptionsText=""
                            renderOption={(props, option, state) => {
                              return (
                                <Box
                                  aria-label={`${id}-repeat_on`}
                                  component="li"
                                  sx={{
                                    backgroundColor: state.selected
                                      ? "#F2F3FF !important"
                                      : "",
                                    "&:hover, &:focus": {
                                      backgroundColor: "#F2F3FF !important",
                                    },
                                  }}
                                  id={`${id}-repeat_on-${option}`}
                                  {...props}
                                >
                                  {option}
                                </Box>
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  // sx={
                                  //   {
                                  //     // ...(repeat_on && repeat_on !== ""
                                  //     //   ? hideBorders
                                  //     //   : {}),
                                  //   }
                                  // }
                                  name={`${id}-repeat_on`}
                                  placeholder="Eg: 1 day"
                                  onChange={updateDurationOptions}
                                  onFocus={handleFocus}
                                  sx={AutoCompleteStyle}
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                />
                              );
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            title={comment}
                            value={comment}
                            multiline
                            fullWidth
                            id={`${id}-comment`}
                            aria-label={`${id}-comment`}
                            name={`${id}-comment`}
                            variant="outlined"
                            onChange={(event: any) => {
                              let value = event.target.value;
                              if (value.length > 400) {
                                value = value.substring(0, 400);
                              }
                              setData((prev: any) => {
                                return prev.map((elem: any) => {
                                  if (elem.id.toString() === id.toString()) {
                                    return {
                                      ...elem,
                                      comment: value,
                                    };
                                  }
                                  return elem;
                                });
                              });
                            }}
                            sx={{
                              "& .MuiInputBase-input":{
                                fontSize: "14px",
                              },
                              "& .MuiInputBase-root":{
                                  padding: "5px !important",
                              }
                            }}
                            onBlur={(event: any) => {
                              updateInvestigation(
                                event as any,
                                event.target.value
                              );
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: "32px", padding: "0 !important" }}
                          align="right"
                        >
                          <Tooltip title={"Delete"}>
                            <button
                              id={id}
                              onClick={removeInvestigation}
                              style={{
                                outline: "none",
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                style={{ pointerEvents: "none" }}
                                src="/images/minus-in-circle.svg"
                                alt="remove-button"
                              />
                            </button>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Autocomplete
            id="Search for lab tests/radiology"
            options={searchValue.length === 0 ? [] : options}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            disableClearable
            value={""}
            clearOnBlur
            clearOnEscape
            inputValue={searchValue}
            popupIcon={false}
            clearIcon={false}
            onChange={addInvestigation}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
            }
            renderInput={(params) => {
              return (
                <TextField
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for lab tests/Radiology"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (
                !data.some(
                  (investigation: any) => investigation.name === option
                )
              ) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplate && (
            <Box position="absolute" top="68px" right="79px" zIndex="1">
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {serachExistingTemplate && (
            <Box position="absolute" top="68px" right="39px" zIndex="1">
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Autocomplete
            id="Search for lab tests/radiology"
            options={searchValue.length === 0 ? [] : options}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            disableClearable
            value={""}
            clearOnBlur
            clearOnEscape
            inputValue={searchValue}
            popupIcon={false}
            clearIcon={false}
            onChange={(event, value) => {
              assignAddLabInvestigationMobile(value);
            }}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
            }
            renderInput={(params) => {
              return (
                <TextField
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for lab tests/Radiology"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (
                !data.some(
                  (investigation: any) => investigation.name === option
                )
              ) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplateMobile && (
            <Box sx={MiniTemplateStyle}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {searchExistingTemplateMobile && (
            <Box sx={AddMiniTemplateStyle}>
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
          <Box marginTop="16px">
            {data.length !== 0 &&
              data.map((investigation, index) => {
                return (
                  <Box
                    component="div"
                    id={investigation.id}
                    onClick={(_event: any) => {
                      if (_event.target.ariaLabel === "delete-investigation") {
                        return;
                      }
                      setDialog({
                        ...dialog,
                        open: true,
                        mode: "UPDATE",
                        content: investigation,
                      });
                    }}
                    key={index}
                  >
                    {LabInvestigationCardMobile(investigation, index)}
                  </Box>
                );
              })}
          </Box>
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
      {isMobileScreen && (
        <CustomizedDialogs {...(dialogProps as any)}>
          <Box margin="12px">
            <Box className="header" display="flex">
              <Box
                component="img"
                sx={{ marginRight: 1.5 }}
                src="/images/icon_lab_inv.svg"
              />
              <Box className="text-content" sx={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <Box>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  Lab Test
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="100%"
                  color="#494E9D"
                >
                  {dialog.content.name}
                </Typography>
                </Box>
                <Box onClick={handleDialogState}>
                  <CloseIcon sx={{ color: theme.palette.grey[500] }} />
                </Box>
              </Box>
            </Box>
            <Divider sx={{ margin: "16px 0" }} />
            <Box className="content" marginBottom="24px">
              <Box
                display="flex"
                justifyContent="space-between"
                marginBottom="16px"
                width="100%"
                gap="8px"
              >
                <Autocomplete
                  fullWidth
                  // disableClearable
                  filterOptions={(options, { inputValue }) => {
                    return options.filter(
                      (option) =>
                        option
                          .replace("-", " ")
                          .toLowerCase()
                          .includes(
                            inputValue.trim().replace("-", " ").toLowerCase()
                          ) || option === "Today"
                    );
                  }}
                  options={durationOptions}
                  clearIcon={false}
                  noOptionsText=""
                  onChange={(event, value) => {
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        test_on: value,
                      },
                    });
                  }}
                  value={dialog.content.test_on}
                  renderOption={(props, option, state) => {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {option}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        onChange={updateDurationOptions}
                        onFocus={handleFocus}
                        {...params}
                        fullWidth
                        label="Test on"
                        variant="outlined"
                        placeholder="Eg: 1 day"
                      />
                    );
                  }}
                />
                <Autocomplete
                  fullWidth
                  // disableClearable
                  filterOptions={(options, { inputValue }) => {
                    return options.filter(
                      (option) =>
                        option
                          .replace("-", " ")
                          .toLowerCase()
                          .includes(
                            inputValue.trim().replace("-", " ").toLowerCase()
                          ) || option === "Same day"
                    );
                  }}
                  options={durationOptions}
                  onChange={(event, value) => {
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        repeat_on: value,
                      },
                    });
                  }}
                  value={dialog.content.repeat_on}
                  clearIcon={false}
                  noOptionsText=""
                  renderOption={(props, option, state) => {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {option}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        onChange={updateDurationOptions}
                        onFocus={handleFocus}
                        {...params}
                        label="Repeat on"
                        fullWidth
                        variant="outlined"
                        placeholder="Eg: 1 day"
                      />
                    );
                  }}
                />
              </Box>
              <TextField
                sx={{ width: "100%" }}
                multiline
                label="Special Instructions"
                value={dialog.content.comment}
                maxRows={4}
                onChange={(event: any) => {
                  let newValue = event.target.value;
                  
                  if (newValue.length > 250) {
                    newValue = newValue.substring(0, 250);
                  }
                  setDialog({
                    ...dialog,
                    content: {
                      ...dialog.content,
                      comment: newValue,
                    },
                  });
                }}
                minRows={2}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none !important",
                  borderRadius: "6px",
                }}
                fullWidth
                onClick={() => {
                  if (dialog.mode === "ADD" && dialog.content.id) {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === INVESTIGATIONS) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: [...row.value, dialog.content],
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setSearchValue("");
                  }
                  if (dialog.mode === "UPDATE" && dialog.content.id) {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === INVESTIGATIONS) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: row.value.map((val: any) => {
                                      if (val.id === dialog.content.id) {
                                        return {
                                          ...dialog.content,
                                        };
                                      }
                                      return val;
                                    }),
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setDurationOptions([]);
                  }
                }}
              >
                {dialog.mode === "ADD" ? `Add` : `Update`} Lab Investigation
              </Button>
            </Box>
          </Box>
        </CustomizedDialogs>
      )}
    </>
  );
}
