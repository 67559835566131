import { useState, useEffect } from "react";

export function useDebounce<T>(func: T, delay = 300) {
  const [debouncedFunc, setDebouncedFunc] = useState<T>(func);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFunc(func);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [func, delay]);

  return debouncedFunc;
}
