import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CustomizedDialogs from "../../../shared/Dialog/Dialog";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { setShowArtilceCard } from "../../../redux/reducers/prescription.slice";
import moment from "moment";

const articleData = [
  {
    image: "/images/gastro-1.jpg",
    readDate: "2 min Read | 01 May ",
    Header:
      "Efficacy of VOS in showing reduced risk of recurrent Clostridioides difficile infection",
    Short_Summary:
      "According to a recent study, VOS, an oral microbiota therapeutic, reduced the risk of recurrent Clostridioides difficile infection (rCDI) at week 8. This study was published in the journal, Clinical infectious diseases.The ECOSPOR III trial was a phase 3, double-blind, placebo-controlled, randomized trial that included 182 adult participants with rCDI who were randomized to receive 4 capsules daily for 3 days of VOS or placebo following standard-of-care antibiotics. This post hoc analysis assessed the rate of rCDI through week 8, including Charlson comorbidity index (CCI) score category, baseline creatinine clearance, exposure to non-CDI-targeted antibiotics after dosing, number of CDI episodes, and acid-suppressing medication use at baseline.At the end of 8 weeks, comorbidities were common with a mean overall baseline age-adjusted CCI score of 4.1. However, the VOS-treated participants had a lower relative risk of recurrence across all subgroups analyzed. Hence, it can be concluded that VOS may reduce the risk of rCDI regardless of baseline characteristics, concomitant medications, or comorbidities.",
    Article_URL:
      "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
  },
  {
    image: "/images/gastro-2.jpg",
    readDate: "3 min Read | 30 April ",
    Header: "Innovative Study on Gastrointestinal Disorders",
    Short_Summary:
      "A recent study found that TC-325 hemostasis powder achieved greater immediate hemostasis rates and lower 30-day rebleeding rates when compared to contemporary standard endoscopic treatment (SET). This study’s results were published in the journal, Gastroenterology.This study was a multicenter, randomized controlled trial that was patient-and outcome assessor-blinded. Patients who presented with active bleeding from an upper or lower GI lesion were randomly allocated to receive either TC-325 alone or standard endoscopic treatment (SET). A total of 106 patients made it in the study population (55 in TC-325, 51 in SET), with 1 exclusion in TC- 325 and 5 in SET. Primary outcome of the study was 30-day rebleeding while the secondary objectives were immediate hemostasis and other clinically relevant endpoints.It was observed that the 30-day rebleeding was significantly lower in the TC-325. The immediate hemostasis rates were 100% and 68.6% in the TC-325 group and SET group, respectively. The other secondary outcomes were not different between the two groups. From these results, it may be concluded that when compared to the contemporary SET, TC-325 hemostatic powder results in greater immediate hemostasis rates followed by lower 30-day rebleeding rates.",
    Article_URL:
      "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
  },
  {
    image: "/images/gastro-3.png",
    readDate: "2 min Read | 25 April ",
    Header:
      "Comparison of radiofrequency ablation with hybrid argon plasma coagulation for eradicating Barrett's esophagus",
    Short_Summary:
      "A recent study suggests that hybrid argon plasma coagulation (H-APC) has slightly better outcomes than radiofrequency ablation (RFA) for eradicating Barrett’s esophagus following endoscopic resection. This study was published in the journal, Surgical Endoscopy.This prospective, randomized trial included 101 patients who were randomly assigned to either H-APC (n=54) or RFA (n=47). For the H-APC technique, 60W was applied while RFA consisted of a 90° focal catheter and a simplified protocol of 12 J/cm2 × 3 or with a Halo 360° balloon and 10 J/cm2/cleaning/10 J/cm2. Eradication rates as well as adverse events were reported. Follow-up examinations were done after 3, 6, 12 and 24 months. The median follow-up period for the short-term was 6.0 months while it was 21 months for long term. Overall, 211 ablations were performed.It was observed that eradication rates after long-term follow-up were 74.2% and 82.9% in the RFA and H-APC groups. The mean score of post-interventional pain was significantly higher at 4.56/10 and duration of 7.54 days while it was 2.07/10 over 3.59 days in the H-APC group. Stenosis that required intervention was reported in 3.7% of patients in the H-APC arm and 14.9% of those in the RFA arm.From the above results, it can be concluded that although both ablation techniques give good results with respect to the eradication rates, H-APC group showed slightly better outcome. Even the severity and duration of pain were significantly greater for the RFA group than the H-APC group.",
    Article_URL:
      "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
  },
  {
    image: "/images/gastro-4.jpg",
    readDate: "1 min Read | 20 April",
    Header:
      "Efficacy and safety of thalidomide for the treatment of recurrent bleeding due to small-intestinal angiodysplasia",
    Short_Summary:
      "A recent study suggests that thalidomide is safe and efficacious for use in treating recurrent bleeding due to small-intestinal angiodysplasia (SIA). This study’s results were published in The New England Journal of Medicine.This multicenter, randomized, double-blind, placebo-controlled trial included 150 patients with at least four episodes of recurrent bleeding during the previous year due to SIA. They were randomized to receive either 100 mg (n=51) or 50 mg (n=49) of oral thalidomide or placebo (n=50) for four months. The patients were followed up for at least one year after the treatment period. The primary endpoint of the study was effective response, marked by reduction of at least 50% of bleeding episodes after thalidomide treatment.At the end of the treatment period, it was seen that 68.6%, 51.0%, and 16.0% of the patients in the 100-mg thalidomide group, 50-mg thalidomide group, and placebo group, respectively showed effective response. From the above results, it can be concluded that treatment with thalidomide may result in a reduction in bleeding in patients with recurrent bleeding due to SIA.",
    Article_URL:
      "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
  },
];

const Articles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [cardData, setCardData] = useState<any>();
  const [like, setLike] = useState(new Array(articleData.length).fill(false));
  const currentDate = new Date();
  const { showArticleCard } = useSelector((state: any) => state.prescription);
  const { accountInfo, profile } = useSelector((state: any) => state.auth);

  const dynamicArticleCard = [
    {
      image: "/images/gastro-9.png",
      bannerImage: 1,
      readDate: `2 min Read | ${moment(new Date()).format("DD MMMM")}`,
      Header:
        "Comparative Efficacy of Rabeprazole vs. Pantoprazole in Nocturnal Acid Control",
      Short_Summary:
        "Study shows Rabeprazole superior to Pantoprazole in controlling nocturnal gastric acid with fewer side effects.",
      Article_URL:
        "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
      Long_Summary: `<p>Hi Dr. ${profile?.name},,</p><p>A recent study published in <em>Gut and Liver</em> compares the efficacy of Rabeprazole and Pantoprazole in controlling nocturnal gastric acid output for patients with gastroesophageal reflux disease (GERD). The study's findings are summarized below:</p><h3 style='color: #2e6da4;'>Key Findings:</h3><ul><li><strong style='color: black;'>Greater Efficacy:</strong> Rabeprazole maintained higher intragastric pH levels than Pantoprazole over the first 8.3 hours of therapy (<em>p</em>＜0.05).</li><li><strong style='color: black;'>Superior Acid Suppression:</strong> Rabeprazole inhibited gastric acid output for a longer duration compared to Pantoprazole.</li><li><strong style='color: black;'>Similar Tolerability:</strong> Both drugs exhibited similar side effects, including headache and nausea.</li><li><strong style='color: black;'>Practical Application:</strong> Rapid conversion to the active form may make Rabeprazole a better option for managing nocturnal GERD symptoms.</li><li><strong style='color: black;'>Limitations:</strong> The small sample size and short study duration may limit the generalizability of these results.</li></ul><h3 style='color: #2e6da4;'>Conclusion:</h3><p>Given its superior performance in controlling nocturnal gastric acidity, Rabeprazole can be considered a promising option for patients with GERD.</p><p>For further details, you can access the full article <a href='article_url_placeholder' style='color: #337ab7; text-decoration: none;'>here</a>.</p>`,
    },
    {
      image: "/images/test-1.png",
      bannerImage: 2,
      readDate: `2 min Read | ${moment(new Date()).format("DD MMMM")}`,
      Header:
        "Comparative Study of Tegoprazan and Esomeprazole on Nighttime GERD Symptoms",
      Short_Summary:
        "Study compares Tegoprazan and Esomeprazole for nighttime GERD, focusing on symptom relief and sleep quality improvement.",
      Article_URL:
        "https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104",
      Long_Summary: `<p>Hi Dr. ${profile?.name},</p> <p>This randomized, double-blind multicenter trial compared <strong>Tegoprazan</strong> and <strong>Esomeprazole</strong> in treating nighttime heartburn and sleep disturbances due to gastroesophageal reflux disease (GERD).</p><p><strong>Key Findings:</strong></p><ul><li><strong>Rapid Relief:</strong> Tegoprazan provided quicker relief from nighttime heartburn than Esomeprazole, though the difference was not statistically significant.</li><li><strong>Distinct Mechanism:</strong> As a potassium-competitive acid blocker (P-CAB), Tegoprazan offers a unique approach to acid suppression.</li><li><strong>Improved Efficacy:</strong> Patients taking Tegoprazan showed a trend toward greater improvement in sleep quality.</li><li><strong>Safety:</strong> Both Tegoprazan and Esomeprazole were well-tolerated with similar safety profiles.</li></ul><p><strong>Conclusion:</strong> The study indicates Tegoprazan's potential for rapid relief and improved sleep in GERD patients. Further research should confirm these benefits and evaluate its long-term safety.</p><p>For more information, access the article <a href='
        https://www.jnmjournal.org/journal/view.html?doi=10.5056/jnm22104'>here</a>.</p>`,
    },
  ];

  const handleModalClose = () => {
    setOpenModal(false);
  };
  const DialogFooterStyles = () => {
    return {
      display: "flex",
      padding: "14px",
    };
  };
  const Buttonstyles = () => {
    return {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      width: "100%",
      borderRadius: "8px",
    };
  };
  const handleReadMore = () => {
    const confirmation = window.confirm(
      "You are leaving the site. Do you want to continue?"
    );
    if (confirmation) {
      window.open(cardData?.Article_URL, "_blank");
    }
  };

  const handleLike = (index: any) => {
    const updatedLikes = [...like];
    updatedLikes[index] = !updatedLikes[index];
    setLike(updatedLikes);
  };
  return (
    <Box
      sx={{
        background: "#ffff",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          top: "18px",
        }}
      >
        <Box>
          <IconButton
            onClick={() => {
              dispatch(setShowArtilceCard(false));
              navigate("/appointments");
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </Box>
        <Box sx={{ paddingRight: "32px" }}>
          <Typography sx={{ color: "grey" }}>
            Showing Articles-{showArticleCard ? "6" : "4"}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          flexWrap: "wrap",
          margin: "20px 10px 20px -7px",
        }}
      >
        {showArticleCard &&
          dynamicArticleCard.map((item, index) => (
            <Box
              sx={{
                display: "inline-flex",
                gap: "5px",
                alignItems: "center",
                flexWrap: "wrap",
                margin: "20px 10px 20px 5px",
              }}
            >
              <Box
                className="main-card"
                sx={{
                  boxShadow: "0 5px 10px rgba(107, 86, 151, .28)",
                  background: "#ffff",
                  borderRadius: "8px",
                  width: "380px",
                }}
              >
                <Box
                  className="card-content-section"
                  padding="10px"
                  position="relative"
                >
                  <Box
                    sx={{
                      borderRadius: "100px",
                      background: "#D32F2F",
                      width: "50px",
                      height: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      top: "18px",
                      right: "16px",
                    }}
                  >
                    <Typography sx={{ color: "#ffff" }}>New</Typography>
                  </Box>
                  <Box className="card-image">
                    <Box
                      component="img"
                      src={item?.image}
                      width="100%"
                      height="200px"
                      sx={{ borderRadius: "10px 10px 0px 0px" }}
                    />
                  </Box>
                  <Box
                    className="read-date-section"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography color="grey">{item?.readDate}</Typography>
                    <IconButton onClick={() => handleLike(index)}>
                      {like[index] ? (
                        <FavoriteIcon style={{ color: "red" }} />
                      ) : (
                        <FavoriteBorderIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Box
                    className="main-heading"
                    marginTop="10px"
                    onClick={() => {
                      setOpenModal(true);
                      setCardData(item);
                      navigate("/articles/details", {
                        state: {
                          cardData: item,
                        },
                      });
                    }}
                  >
                    <Typography
                      fontSize="24px"
                      fontWeight="700"
                      lineHeight="30px"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2, // Limit to four lines
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        "&:hover": {
                          textDecoration: "underline",
                          cursor: "pointer",
                        },
                      }}
                    >
                      {item?.Header}
                    </Typography>
                  </Box>
                  <Box
                    className="sub-heading"
                    sx={{ width: "300px", marginTop: "10px" }}
                  >
                    <Typography
                      fontSize="12px"
                      color="grey"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 4, // Limit to four lines
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      }}
                    >
                      {item?.Short_Summary}
                    </Typography>
                  </Box>
                  <Box
                    className="read-more-section"
                    marginTop="20px"
                    display="flex"
                    alignItems="center"
                    gap="2px"
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                        color: "#5225B5",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      setOpenModal(true);
                      setCardData(item);
                      navigate("/articles/details", {
                        state: {
                          cardData: item,
                        },
                      });
                    }}
                  >
                    <Typography color="#5225B5" fontSize="16px">
                      Read More
                    </Typography>

                    <ArrowForwardIcon style={{ color: "#5225B5" }} />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        {articleData.map((item: any, index: any) => (
          <Box
            className="main-card"
            sx={{
              boxShadow: "0 5px 10px rgba(107, 86, 151, .28)",
              background: "#ffff",
              borderRadius: "8px",
              width: "380px",
              margin: "20px 10px 20px 5px",
            }}
          >
            <Box className="card-content-section" padding="10px">
              <Box className="card-image">
                <Box
                  component="img"
                  src={item?.image}
                  width="100%"
                  sx={{ borderRadius: "10px 10px 0px 0px" }}
                />
              </Box>
              <Box
                className="read-date-section"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="grey">{item?.readDate}</Typography>
                <IconButton onClick={() => handleLike(index)}>
                  {like[index] ? (
                    <FavoriteIcon style={{ color: "red" }} />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>
              </Box>
              <Box
                className="main-heading"
                marginTop="10px"
                onClick={() => {
                  setOpenModal(true);
                  setCardData(item);
                  navigate("/articles/details", {
                    state: {
                      cardData: item,
                    },
                  });
                }}
              >
                <Typography
                  fontSize="24px"
                  fontWeight="700"
                  lineHeight="30px"
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2, // Limit to four lines
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    "&:hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                >
                  {item?.Header}
                </Typography>
              </Box>
              <Box
                className="sub-heading"
                sx={{ width: "300px", marginTop: "10px" }}
              >
                <Typography
                  fontSize="12px"
                  color="grey"
                  sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: 4, // Limit to four lines
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {item?.Short_Summary}
                </Typography>
              </Box>
              <Box
                className="read-more-section"
                marginTop="20px"
                display="flex"
                alignItems="center"
                gap="2px"
                sx={{
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#5225B5",
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  setOpenModal(true);
                  setCardData(item);
                  navigate("/articles/details", {
                    state: {
                      cardData: item,
                    },
                  });
                }}
              >
                <Typography color="#5225B5" fontSize="16px">
                  Read More
                </Typography>

                <ArrowForwardIcon style={{ color: "#5225B5" }} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Articles;

export const ShowArticle = ({ htmlContent }: any) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
