/* eslint-disable no-unreachable */
import { Autocomplete, Badge, Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Icon, IconButton, InputLabel, List, ListItem, ListItemText, Menu, MenuItem, Modal, OutlinedInput, Select, Skeleton, Snackbar, Tab, Tabs, TextField, Typography, chipClasses, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ADMIN_ADD, ADMIN_ADMIN, ADMIN_ALL_USERS, ADMIN_CANCEL, ADMIN_DELETE, ADMIN_DOCTORS, ADMIN_EDIT, ADMIN_EMAIL, ADMIN_FEATURES, ADMIN_FIRST_NAME, ADMIN_HOME, ADMIN_NEW_USER, ADMIN_NURSE, ADMIN_PHONE_NUMBER, ADMIN_ROLE, ADMIN_SEARCH_BY_NAME, ADMIN_SEARCH_DOCTOR, ADMIN_SELECT_ROLE, ADMIN_SUPER_ADMIN, ADMIN_TAB_ADD_NEW_USER, ADMIN_TAB_EDIT_USER, ADMIN_TAB_MANAGE_EXISTING_USERS, ADMIN_TAG_USER_TO_DOCTOR } from '../../../../assets/translations/admin';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { SearchInput } from '../../appointment/SearchInput';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import UserAddedModal from './UserAddedModal';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '../../../../shared/icons/AddIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllClinics, fetchAllUsers, fetchDoctorList, resetUsersList } from '../../../../redux/reducers/admin.slice';
import{  API_ROUTE_ADMIN_RESET_PASSWORD, ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC, ROUTE_ADMIN_SINGLE_DOCTOR_VIEW} from '../../../../shared/routes/route.constants';
import CancelIcon from '@mui/icons-material/Cancel';
import { APIClient } from '../../../../shared/Utils/api-client';
import { API_ROUTE_ADD_USER, API_ROUTE_DELETE_USER, API_ROUTE_FETCH_DOCTOR_LIST, API_ROUTE_FETCH_USERS_LIST, API_ROUTE_SEARCH_CLINIC, API_ROUTE_UPDATE_ONLINEDOC, API_ROUTE_UPDATE_USER, API_ROUTE_USER_DETAILS, ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC, ROUTE_ADMIN_SLOT_MANAGEMENT } from '../../../../shared/routes/route.constants';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';
import dayjs from 'dayjs';
import { getFeatureValue, useAuthorization } from '../../../../shared/Utils/useAuthorization';
import CloseIcon from '@mui/icons-material/Close';
import { isValidEmail } from '../../../../shared/Utils/utils';
import { debounce } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const sections = [
    {
        label:'Clinic Management',
        value:'/admin/clinicmanagement/allclinic'
    },
    {
        label:'User Management',
        value:'/admin/usermanagement'
    },
    {
        label:'Slot Management',
        value:'/admin/slotmanagement'
    },
]

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:"14px",
    p: 2,
    
  };
const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:"14px",
    p: 2,
    
  };
  const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 280,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:"14px",
    p: 2,
  };

const tabStyle = {
    fontSize:'12px',
    padding:'0px 60px'
}
const usertabStyle = {
    fontSize:'12px',
    padding:'0px 3%'
}
const tabStyle2 = {
    fontSize:'12px',
    padding:'0px 8%'
}

const initState = {
    fullname:'',
    phoneNumber:'',
    email:'',
    role:[],
    features:{
        staff:{
            'Prescription':false,
        },
        doctor:{
            'Enable Auto Approve':false,
            'KPI Dashboard':false,
        },
        admin:{
            'Template Builder':false,
            // "Clinic Creation Rights":false,
            // "Clinic Updation Rights":false,
            'User Creation Rights':false,
            'User Updation Rights':false,
        }
    },
    featureList:{},
    roleOptions:[
        {label:"Doctor",value:'doctor'},
        {label:"Admin",value:'admin'},
        {label:"Staff",value:'staff'},

    ],
    taggedDoctors:[],

    featureValues:{
        "All Prescription":"f:allpres",
        "Only Vitals":"f:onlyvitals",
        "Template Builder":"f:tempbuilder",
        "User Creation Rights":"f:usercreationrights",
        "User Updation Rights":"f:userupdaterights",
        "Clinic Creation Rights":"f:cliniccreationrights",
        "Clinic Updation Rights":"f:clinicupdationrights",
        "Dashboard":"f:dashboard",
        "Invoice":"f:invc",
        "Clinics":"f:clinics",
        "Registration":"f:registration",
        "Appointment Module":"f:appmodule",
        "Prescription":"f:presc",
        "Record Vitals":"f:recvitals",
        "Print Prescription":"f:printpresc",
        "SMS":"f:sms",
        "OCR":"f:ocr",
        "Templates":"f:tmpl",
        "Slot Management":"f:slotmanagement",
        "Enable Auto Approve":"f:enableautoapprove",
        "KPI Dashboard":"f:kpi"
    }
}

const initSlotManagementState = {
    consultation_duration:'',
    duration_type:'Min',
    free_follow_up_number:'',
    free_follow_up_duration:'',
    consultation:'',
    type_of_service:'none',
    description:'',
    type:{
        online:{
            checked:false,
            price:"",
        },
        in_visit:{
            checked:false,
            price:"",
        }
    },
    leaves:[],
    online:{
        monday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        tuesday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        wednesday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        thursday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        friday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        saturday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        sunday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
    },
    offline:{
        monday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        tuesday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        wednesday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        thursday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        friday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        saturday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
        sunday:{
            morning:[],
            edit_morning:[],
            afternoon:[],
            edit_afternoon:[],
            evening:[],
            edit_evening:[],
            weekoffs:false,
            isEdit:false,
        },
    },
    off_dates:[],
    leaveTime:{
        morning:false,
        afternoon:false,
        evening:false,
        fullday:false
    },
    leaveDate:[dayjs().format('DD/MM/YYYY'),dayjs().format('DD/MM/YYYY')],
    isLeaveMarked:false,
}

const initErrorState = {
    errorField:'',
    errorMsg:'',
    showError:false
}

const userCountState = {
    allusers:0,
    doctors:0,
    admin:0,
    superadmin:0,
    staff:0,
}
const userListState = {
    allusers:[],
    doctors:[],
    admin:[],
    superadmin:[],
    staff:[],
}

const noUserStateInit = {
    headerText:'',
    subText:'',
    buttonText:'',
    buttonOnClick:()=>{return;},
    isSearch:false,
}

const UserManagment = () => {

  //Hooks
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  //States
  const {usersList,doctorList,loader,usersloader,clinicList} = useSelector((state)=>state.admin)
  const [pathname,setPathname] = useState('');
  const [tabValue,setTabValue] = useState(0);
  const [userTabValue,setUserTabValue] = useState(0);
  const [userAddedModalOpen,setUserAddedModalOpen] = useState(false);
  const [accountId,setAccountId] = useState('');
  const [modalData,setModalData] = useState({
    headerText:'User Added',
    subText:<Typography variant='subtitle2'><Typography variant='subtitle2' fontWeight={'bold'}></Typography></Typography>
  });

  const [usersLoader,setUsersLoader] = useState(false);

  const { profile } = useSelector((state) => state.auth);

  const [clinicSearchList,setClinicSearchList] = useState([]);
  const [clinicSearchResultsStatus,setClinicSearchResultsStatus] = useState('')

  const [state, setState] = useReducer((state,action)=>{
    return{
        ...state,
        ...action
    }
  },initState)

  const [slotState, setSlotState] = useReducer((state,action)=>{
    return{
        ...state,
        ...action
    }
  },initSlotManagementState)

  const [userCount,setUserCount] = useReducer((state,action)=>{
    return{
        ...state,
        ...action
    }
  },userCountState)

  const [userList,setUserList] = useReducer((state,action)=>{
    return{
        ...state,
        ...action
    }
  },userListState)

  const [noUserState,setNoUserState] = useReducer((state,action)=>{
    return{
        ...state,
        ...action
    }
  },noUserStateInit)

  const [errorState,setErrorState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },initErrorState)

  const [shouldShowNoUser,setShouldShowNoUser] = useState(false);

  const [isLoading,setIsLoading] = useState(false);

  const [fetchDoctorLoader, setFetchDoctorLoader] = useState(true);
  const [fetchUsersLoader, setFetchUsersLoader] = useState(true);

  const [snackbarState,setSnackbarState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },{
    isSuccess:true,
    open:false,
    message:'Updated',
    color:'green'
  })

  const [filteredUserData,setFilteredUserData] = useState({});

  const [featuresValueToKeyMap,setFeaturesValueToKeyMap] = useState({});
  
  const [searchValue, setSearchValue] = useState('');

  const [isCopyUser, setIsCopyUser] = useState(false);
  const [selectedAccountCopyUser,setSelectedAccountCopyUser] = useState({account_id:''});
  const [selectedUserCopyUser,setSelectedUserCopyUser] = useState({});
  const [optionsAccountCopyUser,setOptionsAccountCopyUser] = useState([]);
  const [optionsUsersCopyUser,setOptionsUsersCopyUser] = useState([]);
  const [fetchCopyUsersLoader,setFetchCopyUsersLoader] = useState(false);

  const [accountSearchValue,setAccountSearchValue] = useState("");
  const [userSearchValue,setUserSearchValue] = useState("");

  const userFeatures = useAuthorization();

  //Effects

  useEffect(()=>{
    if(searchParams.has('isEdit') && usersList.length && !loader){
        FetchUserDetails(searchParams.get("cognito_user_sub"),searchParams.get("account_id"))
    }
  },[usersList,doctorList,loader])

  useEffect(()=>{
    let map = {}
    for(const [key,value] of Object.entries(state.featureValues)){
        map[value.replace('f:','')] = key;
    }
    setFeaturesValueToKeyMap(map);
  },[state.featureValues])

  useEffect(()=>{
    if(location.pathname){
        setPathname(location.pathname);
    }
  },[location])

  useEffect(()=>{
    let value = shouldShowNoUserFunc()
    setShouldShowNoUser(value) 
  },[userTabValue,userList,userCount])

  useEffect(()=>{

    if(!searchParams.has('isEdit')){
        setFetchDoctorLoader(false);
        setFetchUsersLoader(false);
    }

    if (searchParams.has('show_users')) {
        setTabValue(1);
        searchParams.delete('show_users');
        setSearchParams(searchParams);
    }

    if(searchParams.get("show_users")==="true"){
        setTabValue(1);
    }
    if(searchParams.get("account_id")){
        setAccountId(searchParams.get("account_id"));
    }

    if(sessionStorage.getItem('admin_add_user_data')){
        let savedState = JSON.parse(sessionStorage.getItem('admin_add_user_data'));
        setState({...savedState});
        sessionStorage.removeItem('admin_add_user_data')
    }
    if(sessionStorage.getItem('admin_slot_management_data')){
        let savedState = JSON.parse(sessionStorage.getItem('admin_slot_management_data'));
        setSlotState({...savedState});
        sessionStorage.removeItem('admin_slot_management_data')
    }

    // FetchAllClinics(100,undefined);


    
  },[])

  useEffect(()=>{
    if(accountId!==''){
        // if(!usersList.length){
            
        // }
        dispatch(fetchAllUsers({id:accountId,isActive:false}))
        dispatch(fetchDoctorList({role:'doctor',account_id:accountId}))
    }
  },[accountId])

  useEffect(()=>{
    FilterUserData();
    // return ()=>{
    //     dispatch(resetUsersList());
    // }
  },[usersList])

  useEffect(()=>{
    if(accountSearchValue?.length === 0){
        setClinicSearchList([]);
        setClinicSearchResultsStatus("");
    }
  },[accountSearchValue])

  useEffect(()=>{
    if(searchValue.length >= 3){
        let allusersList = usersList?.filter((elem)=>elem.name && elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase()))
        let doctorsList = usersList?.filter((elem)=>elem.role.includes('doctor') && elem.name && elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase()))
        let adminList = usersList?.filter((elem)=>elem.role.includes('admin') && !elem.role.includes('superadmin') && elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase()))
        let superadminList = usersList?.filter((elem)=>elem.role.includes('superadmin') && elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase()))
        let nurseList = usersList?.filter((elem)=>elem.role.includes('staff') && elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase()))

        setUserCount({
            allusers:allusersList.length,
            doctors:doctorsList.length,
            admin:adminList.length,
            superadmin:superadminList.length,
            staff:nurseList.length,
        })
    }else{
        let allusersList = usersList
        let doctorsList = usersList?.filter((elem)=>elem.role.includes('doctor'))
        let adminList = usersList?.filter((elem)=>elem.role.includes('admin'))
        let superadminList = usersList?.filter((elem)=>elem.role.includes('superadmin'))
        let nurseList = usersList?.filter((elem)=>elem.role.includes('staff'))

        setUserCount({
            allusers:allusersList.length,
            doctors:doctorsList.length,
            admin:adminList.length,
            superadmin:superadminList.length,
            staff:nurseList.length,
        })
    }
  },[searchValue])

  useEffect(()=>{
    if(selectedAccountCopyUser?.account_id){
        setSelectedUserCopyUser({});
        setOptionsUsersCopyUser([]);
        FetchUserOptions(selectedAccountCopyUser.account_id)
    }
  },[selectedAccountCopyUser])

  useEffect(()=>{
    if(selectedUserCopyUser.cognito_user_sub){
        FetchUserDetails(selectedUserCopyUser?.cognito_user_sub,selectedUserCopyUser?.account_id)
    }
  },[selectedUserCopyUser])

  useEffect(()=>{
    if(profile?.role?.includes('superadmin')){
        setState({
            roleOptions: [
                ...state.roleOptions,
                {
                    label:"Super Admin",
                    value:'superadmin'
                }
            ]
        })
        
    }
  },[profile])

  //Functions

  const handleSearchClinic = useCallback(debounce(async (account_id) => {
    await APIClient.get(API_ROUTE_SEARCH_CLINIC(100,account_id)).then((res)=>{
        if(res?.data?.rows?.length){
            // setRows(res?.data?.rows);
            // setResultsCount(res?.data?.count)
            setClinicSearchList(res?.data?.rows)
        }else{
            setClinicSearchResultsStatus('No Results')
        }
        
    }).catch((err)=>{
        setErrorState({
            errorField:'search',
            errorMsg:err || 'Unable to Search',
            showError:true
        })
    })
  },400),[])

  const ResetAllFields = ()=>{
    setState(initState)
    setSlotState(initSlotManagementState)
  }

  const FetchAllClinics = async (limit,isPrev,isLast) => {
    const response = await dispatch(fetchAllClinics({
        params:{
            limit:limit,
        }
    }));
  }

  const FetchAccountOptions = async ()=>{
    await APIClient.get(API_ROUTE_SEARCH_CLINIC(10,accountSearchValue)).then(res=>{
        console.log("res account options",res);
        setOptionsAccountCopyUser(res?.data?.rows);
    }).catch(err=>{
        console.log("error in fetching account options",err);
        setSnackbarState({
            isSuccess:true,
            open:true,
            message:err|| 'Unable to fetch accounts',
            color:'red'
        })
    })
  }
  const FetchUserOptions = async (account_id)=>{
    setFetchCopyUsersLoader(true);
    await APIClient.get(API_ROUTE_FETCH_USERS_LIST(account_id,false)).then(res=>{
        console.log("res account options",res?.data?.rows);
        setOptionsUsersCopyUser(res?.data?.rows);
        setFetchCopyUsersLoader(false);
    }).catch(err=>{
        console.log("error in fetching user options",err);
        setSnackbarState({
            isSuccess:true,
            open:true,
            message:err|| 'Unable to fetch users',
            color:'red'
        })
        setFetchCopyUsersLoader(false);
    })
  }

  const handleSearch = (e)=>{
    let val = e.target.value;
    setSearchValue(val);
  }

  const handleAddUser = async ()=>{

    try {
        //validation
        if(!state.fullname){
            setErrorState({
                errorField:'name',
                errorMsg:'Please enter name'
            })
            return;
        }else if(!state.phoneNumber || state.phoneNumber?.length !== 10){
            setErrorState({
                errorField:'phone number',
                errorMsg:'Please enter phone number'
            })
            return;
        }else if(state.role?.length===0){
            setErrorState({
                errorField:'role',
                errorMsg:'Please enter role'
            })
            return;
        }else if(state.email && !isValidEmail(state?.email)){
            setErrorState({
                errorField:'email',
                errorMsg:'Please enter a valid email'
            })
            return;
        }

        let copyUserPayload = {}

        if(isCopyUser){
            copyUserPayload.copyuser = true;
            copyUserPayload.origin_account_id = selectedUserCopyUser?.account_id
        }

        const creationRight = userFeatures.includes(getFeatureValue('USER_CREATION'));
        const updationRight = userFeatures.includes(getFeatureValue('USER_UPDATION'));

        

        setIsLoading(true);

        

        const featuresList = []
        if(state.featureList){
            for(const [key,value] of Object.entries(state.featureList)){
                if(value===true){
                    featuresList.push(`${state.featureValues[key]}`);
                }
            }
        }
        

        if(searchParams.get('isEdit')){

            if(!updationRight){
                setSnackbarState({
                    isSuccess:true,
                    open:true,
                    message:'You do not have permission',
                    color:'red'
                })
                setIsLoading(false);
                return;
            }

            await APIClient.put(API_ROUTE_UPDATE_USER(searchParams.get('user_id'),searchParams.get('account_id')),
            {
                name: state.fullname,
                site: filteredUserData.site,
                cognito_username: filteredUserData.cognito_username,
                phone: state.phonenumber,
                department: filteredUserData.department,
                superiority: filteredUserData.superiority,
                medicalreg_no: filteredUserData.medicalreg_no,
                env: filteredUserData.env,
                email: state.email,
                role: state.role?.map(elem=>{return `r:${elem}`})?.join(','),
                editableinfo: filteredUserData.editableinfo,
                features: featuresList.join(','),
                profile_pic: filteredUserData.profile_pic,
                account_id: searchParams.get("account_id"),
                doctor_id: state.role.includes('staff') ? state.taggedDoctors?.map(elem=>{return {cognito_user_sub:elem.cognito_user_sub,name:elem.name}}) : filteredUserData.doctor_id,
                branch: filteredUserData.branch,
                address: {
                    country: filteredUserData?.address?.country,
                    state: filteredUserData?.address?.state,
                    city: filteredUserData?.address?.city,
                    zipcode: filteredUserData?.address?.zipcode,
                    line1: filteredUserData?.address?.line1,
                    line2: filteredUserData?.address?.line2
                },
                multi_consultation_fee: filteredUserData.multi_consultation_fee,
                age: filteredUserData.age,
                speciality: filteredUserData.speciality,
                original_account_id: searchParams.get("account_id"),
                is_original_user: filteredUserData.is_original_user,
                account_list: [
                    searchParams.get("account_id")
                ],
                consultation_duration:slotState.consultation_duration,
                free_follow_days:slotState.free_follow_up_number,
                free_follow_duration:slotState.free_follow_up_duration,
                service_name:slotState.consultation,
                service_type:slotState.type_of_service,
                service_description:slotState.description,
                leaves:Array.isArray(slotState.leaves) ? slotState.leaves?.join(',') : '',

                consultation_fee:{
                    online:slotState.type.online.price,
                    offline:slotState.type.in_visit.price
                }

            }

            ).then((res)=>{

                setSnackbarState({
                    isSuccess:true,
                    open:true,
                    message:'Updated successfully',
                    color:'green'
                })

                setIsLoading(false);
                
                return;
            }).catch(err=>{
                setSnackbarState({
                    isSuccess:true,
                    open:true,
                    message:err || 'Unable to add user',
                    color:'red'
                })
                setIsLoading(false)
                return;
            })

            
        }else{
            let new_user_profile_data=sessionStorage.getItem("new_user_profile_data")?JSON.parse(sessionStorage.getItem("new_user_profile_data")):{};

            if(!creationRight){
                setSnackbarState({
                    isSuccess:true,
                    open:true,
                    message:'You do not have permission',
                    color:'red'
                })
                setIsLoading(false);
                return;
            }

            let offDates = [];

            if(slotState.isLeaveMarked){
                let startdate = moment(slotState.leaveDate[0],'DD/MM/YYYY');
                let enddate = moment(slotState.leaveDate[1],'DD/MM/YYYY');
                while(startdate.isSameOrBefore(enddate)){
                    offDates.push({
                        date:startdate.format('YYYY-MM-DD'),
                        ...(!slotState.leaveTime.fullday && (slotState.leaveTime.morning || slotState.leaveTime.afternoon || slotState.leaveTime.evening) && {slots:Object.keys(slotState.leaveTime).filter(elem=>slotState.leaveTime[elem])})
                    });
                    startdate.add(1,'days')
                }
            }
            

            if(slotState.type.online.checked && slotState.type.in_visit.checked){
                //online and offline doctor

                console.log("create online offline doc");

                let payload = {
                    slot_type:{},
                    slots:{},
                    consultation_duration:slotState.consultation_duration,
                    interval: slotState.consultation_duration,
                    free_follow_days:slotState.free_follow_up_number,
                    free_follow_duration:slotState.free_follow_up_duration,
                    service_name:slotState.consultation,
                    service_type:slotState.type_of_service,
                    service_description:slotState.description,
                    leaves:Array.isArray(slotState.leaves) ? slotState.leaves?.join(',') : '',

                    consultation_fee:{
                        online:slotState.type.online.price,
                        offline:slotState.type.in_visit.price
                    },

                    off_dates:[...offDates,...(slotState?.leaves ? slotState.leaves : [])],
                }
               
                let counter = 1;

                for(const [day,slots] of Object.entries(slotState.offline)){
                    let morningSlot = slotState?.offline[day]?.morning;
                    let afternoonSlot = slotState?.offline[day]?.afternoon;
                    let eveningSlot = slotState?.offline[day]?.evening;

                    if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || slotState?.offline[day]?.weekoffs){
                        continue;
                    }
                    else{
                        payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
                        payload.slots[`slot${counter}`] = {
                            ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                            ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                            ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                        }
                        counter++;
                    }

                }
                
                await APIClient.post(API_ROUTE_ADD_USER(),
                {
                    name: state.fullname,
                    site: "",
                    cognito_username: "+91"+state.phoneNumber,
                    phone: "+91"+state.phoneNumber,
                    department: "",
                    superiority: "",
                    medicalreg_no: "",
                    env: "e:dev",
                    email: state.email,
                    role: state.role?.map(elem=>{return `r:${elem}`})?.join(','),
                    editableinfo: "",
                    features: featuresList.join(','),
                    profile_pic: "",
                    account_id: searchParams.get("account_id"),
                    doctor_id: [],
                    branch: "",
                    address: {
                        country: "india",
                        state: "",
                        city: "",
                        zipcode: "",
                        line1: "",
                        line2: ""
                    },
                    multi_consultation_fee: 1,
                    age: "",
                    speciality: "",
                    original_account_id: searchParams.get("account_id"),
                    is_original_user: true,
                    account_list: [
                        searchParams.get("account_id")
                    ],
                    
                    ...payload,
                    ...copyUserPayload,
                    
                }
                ).then(async (res)=>{

                    if(state.role.includes('doctor')){
                    
                        let payload = {

                            slot_type:{},
                            slots:{},

                            is_online: true,
                            is_consulting: true,
                            interval: slotState.consultation_duration,
                            account_id: searchParams.get('account_id'),
                            superiority: '',
                            use_third_party: '',
                            
                            day_wise_consult_fee: '',
                            cognito_user_sub: res?.data?.cognito_user_sub,
                            hide_doctor_from_portal: '',

                            consultation_duration:slotState.consultation_duration,
                            free_follow_days:slotState.free_follow_up_number,
                            free_follow_duration:slotState.free_follow_up_duration,
                            service_name:slotState.consultation,
                            service_type:slotState.type_of_service,
                            service_description:slotState.description,
                            leaves:Array.isArray(slotState.leaves) ? slotState.leaves?.join(',') : '',

                            consultation_fee:{
                                online:slotState.type.online.price,
                                offline:slotState.type.in_visit.price
                            },
                            ...new_user_profile_data
                        };

                        let counter = 1;

                        for(const [day,slots] of Object.entries(slotState.online)){
                            let morningSlot = slotState?.online[day]?.morning;
                            let afternoonSlot = slotState?.online[day]?.afternoon;
                            let eveningSlot = slotState?.online[day]?.evening;

                            if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || slotState?.online[day]?.weekoffs){
                                continue;
                            }
                            else{
                                payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
                                payload.slots[`slot${counter}`] = {
                                    ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                                    ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                                    ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                                }
                                counter++;
                            }
                        }

                        await APIClient.put(API_ROUTE_UPDATE_ONLINEDOC(),payload).then(resdoc=>{
                            sessionStorage.removeItem('new_user_profile_data');
                            setIsLoading(false);
                            setModalData({
                                isSuccess:false,
                                headerText:'User Added',
                                subText:<Typography variant='subtitle2'>{resdoc?.name || "Doctor"} is added successfully on <Typography variant='subtitle2' fontWeight={'bold'}>{`"${moment().format('DD MMM YY')} at ${moment().format("hh:mm A")}"`}</Typography></Typography>,
                                actionButtonText:'',
                                actionButtonOnClick:'',
                                actionButtonOnClickNewUsers:()=>{
                                    handleAddNewUser();
                                },
                                actionButtonOnClickNewClinics:()=>{
                                    navigate(ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC)
                                }
                            })
                            setUserAddedModalOpen(true);
                        }).catch(err=>{
                            setIsLoading(false);
                            setSnackbarState({
                                isSuccess:true,
                                open:true,
                                message:err || 'Unable to add slots',
                                color:'red'
                            })
                            return;
                        })

                        
                    }else{
                        setModalData({
                            isSuccess:false,
                            headerText:'User Added',
                            subText:<Typography variant='subtitle2'>{res?.name || "User"} is added successfully on <Typography variant='subtitle2' fontWeight={'bold'}>{`"${moment().format('DD MMM YY')} at ${moment().format("hh:mm A")}"`}</Typography></Typography>,
                            actionButtonText:'',
                            actionButtonOnClick:'',
                            actionButtonOnClickNewUsers:()=>{
                                handleAddNewUser();
                            },
                            actionButtonOnClickNewClinics:()=>{
                                navigate(ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC)
                            }
                        })
                        setUserAddedModalOpen(true);
            
                        setIsLoading(false);
                    }

                    
                    
                    return;
                }).catch(err=>{
                    setIsLoading(false);
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:err || 'Unable to add user',
                        color:'red'
                    })
                    return;
                })
            }
            else if(slotState.type.in_visit.checked){
                //offline doctor
                console.log("create offline doc")

                let payload = {
                    slot_type:{},
                    slots:{},
                    consultation_duration:slotState.consultation_duration,
                    interval: slotState.consultation_duration,
                    free_follow_days:slotState.free_follow_up_number,
                    free_follow_duration:slotState.free_follow_up_duration,
                    service_name:slotState.consultation,
                    service_type:slotState.type_of_service,
                    service_description:slotState.description,
                    leaves:Array.isArray(slotState.leaves) ? slotState.leaves?.join(',') : '',

                    consultation_fee:{
                        online:slotState.type.online.price,
                        offline:slotState.type.in_visit.price
                    },
                    off_dates:[...offDates,...(slotState?.leaves ? slotState.leaves : [])],

                    
                };

                let counter = 1;

                for(const [day,slots] of Object.entries(slotState.offline)){
                    let morningSlot = slotState?.offline[day]?.morning;
                    let afternoonSlot = slotState?.offline[day]?.afternoon;
                    let eveningSlot = slotState?.offline[day]?.evening;

                    if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || slotState?.offline[day]?.weekoffs){
                        continue;
                    }
                    else{
                        payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
                        payload.slots[`slot${counter}`] = {
                            ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                            ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                            ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                        }
                        counter++;
                    }

                }

                await APIClient.post(API_ROUTE_ADD_USER(),
                {
                    name: state.fullname,
                    site: "",
                    cognito_username: "+91"+state.phoneNumber,
                    copyuser: false,
                    phone: "+91"+state.phoneNumber,
                    department: "",
                    superiority: "",
                    medicalreg_no: "",
                    env: "e:dev",
                    email: state.email,
                    role: state.role?.map(elem=>{return `r:${elem}`})?.join(','),
                    editableinfo: "",
                    features: featuresList.join(','),
                    profile_pic: "",
                    gender:'',
                    account_id: searchParams.get("account_id"),
                    doctor_id: [],
                    branch: "",
                    address: {
                        country: "india",
                        state: "",
                        city: "",
                        zipcode: "",
                        line1: "",
                        line2: ""
                    },
                    multi_consultation_fee: 1,
                    age: "",
                    speciality: "",
                    original_account_id: searchParams.get("account_id"),
                    is_original_user: true,
                    account_list: [
                        searchParams.get("account_id")
                    ],
                    ...payload,
                    ...copyUserPayload
                }
                
                ).then(async (res)=>{
                    if(state.role.includes('doctor')){
                    
                        let payload = {

                            slot_type:{},
                            slots:{},

                            is_online: true,
                            is_consulting: true,
                            interval: slotState.consultation_duration,
                            account_id: searchParams.get('account_id'),
                            superiority: '',
                            use_third_party: '',
                            day_wise_consult_fee: '',
                            cognito_user_sub: res?.data?.cognito_user_sub,
                            hide_doctor_from_portal: '',

                            consultation_duration:slotState.consultation_duration,
                            free_follow_days:slotState.free_follow_up_number,
                            free_follow_duration:slotState.free_follow_up_duration,
                            service_name:slotState.consultation,
                            service_type:slotState.type_of_service,
                            service_description:slotState.description,
                            leaves:Array.isArray(slotState.leaves) ? slotState.leaves?.join(',') : '',

                            consultation_fee:{
                                online:slotState.type.online.price,
                                offline:slotState.type.in_visit.price
                            },
                            ...new_user_profile_data
                        };

                        // let counter = 1;

                        // for(const [day,slots] of Object.entries(slotState.online)){
                        //     let morningSlot = slotState.online[day].morning;
                        //     let afternoonSlot = slotState.online[day].afternoon;
                        //     let eveningSlot = slotState.online[day].evening;

                        //     if(!morningSlot.length && !afternoonSlot.length && !eveningSlot.length){
                        //         continue;
                        //     }
                        //     else{
                        //         payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
                        //         payload.slots[`slot${counter}`] = {
                        //             ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                        //             ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                        //             ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                        //         }
                        //         counter++;
                        //     }

                        // }

                        await APIClient.put(API_ROUTE_UPDATE_ONLINEDOC(),payload).then(resdoc=>{
                            setIsLoading(false);
                            sessionStorage.removeItem('new_user_profile_data');
                            setModalData({
                                isSuccess:false,
                                headerText:'User Added',
                                subText:<Typography variant='subtitle2'>{resdoc?.name || "Doctor"} is added successfully on <Typography variant='subtitle2' fontWeight={'bold'}>{`"${moment().format('DD MMM YY')} at ${moment().format("hh:mm A")}"`}</Typography></Typography>,
                                actionButtonText:'',
                                actionButtonOnClick:'',
                                actionButtonOnClickNewUsers:()=>{
                                    handleAddNewUser();
                                },
                                actionButtonOnClickNewClinics:()=>{
                                    navigate(ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC)
                                }
                            })
                            setUserAddedModalOpen(true);
                        }).catch(err=>{
                            setIsLoading(false);
                            setSnackbarState({
                                isSuccess:true,
                                open:true,
                                message:err || 'Unable to add user',
                                color:'red'
                            })
                            return;
                        })

                        
                    }else{
                        setModalData({
                            isSuccess:false,
                            headerText:'User Added',
                            subText:<Typography variant='subtitle2'>{res?.name || "User"} is added successfully on <Typography variant='subtitle2' fontWeight={'bold'}>{`"${moment().format('DD MMM YY')} at ${moment().format("hh:mm A")}"`}</Typography></Typography>,
                            actionButtonText:'',
                            actionButtonOnClick:'',
                            actionButtonOnClickNewUsers:()=>{
                                handleAddNewUser();
                            },
                            actionButtonOnClickNewClinics:()=>{
                                navigate(ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC)
                            }
                        })
                        setUserAddedModalOpen(true);
            
                        setIsLoading(false);
                    }
                    
                }).catch(err=>{
                    setIsLoading(false);
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:err || 'Unable to add user',
                        color:'red'
                    })
                    return;
                })
            }else{
                //online doctor
                console.log("create online doc")

                await APIClient.post(API_ROUTE_ADD_USER(),
                {
                    name: state.fullname,
                    site: "",
                    cognito_username: "+91"+state.phoneNumber,
                    phone: "+91"+state.phoneNumber,
                    department: "",
                    superiority: "",
                    medicalreg_no: "",
                    env: "e:dev",
                    email: state.email,
                    role: state.role?.map(elem=>{return `r:${elem}`})?.join(','),
                    editableinfo: "",
                    features: featuresList.join(','),
                    profile_pic: "",
                    account_id: searchParams.get("account_id"),
                    doctor_id: state.role.includes('staff') ? state.taggedDoctors?.map(elem=>{return {cognito_user_sub:elem.cognito_user_sub,name:elem.name}}) : [],
                    branch: "",
                    address: {
                        country: "india",
                        state: "",
                        city: "",
                        zipcode: "",
                        line1: "",
                        line2: ""
                    },
                    multi_consultation_fee: 1,
                    age: "",
                    speciality: "",
                    original_account_id: searchParams.get("account_id"),
                    is_original_user: true,
                    account_list: [
                        searchParams.get("account_id")
                    ],
                    consultation_duration:slotState.consultation_duration,
                    interval: slotState.consultation_duration,
                    free_follow_days:slotState.free_follow_up_number,
                    free_follow_duration:slotState.free_follow_up_duration,
                    service_name:slotState.consultation,
                    service_type:slotState.type_of_service,
                    service_description:slotState.description,
                    leaves:Array.isArray(slotState.leaves) ? slotState.leaves?.join(',') : '',

                    consultation_fee:{
                        online:slotState.type.online.price,
                        offline:slotState.type.in_visit.price
                    },

                    off_dates:[...offDates,...(slotState?.leaves ? slotState.leaves : [])],

                    ...copyUserPayload
                }
                ).then(async (res)=>{

                    if(state.role.includes('doctor')){
                    
                        let payload = {

                            slot_type:{},
                            slots:{},

                            is_online: true,
                            is_consulting: true,
                            interval: slotState.consultation_duration,
                            account_id: searchParams.get('account_id'),
                            superiority: '',
                            use_third_party: '',
                            day_wise_consult_fee: '',
                            cognito_user_sub: res?.data?.cognito_user_sub,
                            hide_doctor_from_portal: '',

                            consultation_duration:slotState.consultation_duration,
                            free_follow_days:slotState.free_follow_up_number,
                            free_follow_duration:slotState.free_follow_up_duration,
                            service_name:slotState.consultation,
                            service_type:slotState.type_of_service,
                            service_description:slotState.description,
                            leaves:Array.isArray(slotState.leaves) ? slotState.leaves?.join(',') : '',

                            consultation_fee:{
                                online:slotState.type.online.price,
                                offline:slotState.type.in_visit.price
                            },
                            ...new_user_profile_data
                        };

                        let counter = 1;

                        for(const [day,slots] of Object.entries(slotState.online)){
                            let morningSlot = slotState?.online[day]?.morning;
                            let afternoonSlot = slotState?.online[day]?.afternoon;
                            let eveningSlot = slotState?.online[day]?.evening;

                            if((!morningSlot?.length && !afternoonSlot?.length && !eveningSlot?.length) || slotState?.online[day]?.weekoffs){
                                continue;
                            }
                            else{
                                payload.slot_type[`slot${counter}`] = [day[0].toUpperCase()+day.slice(1)]
                                payload.slots[`slot${counter}`] = {
                                    ...(morningSlot.length ? {morning:{starttime:slots.morning[0].start,endtime:slots.morning[0].end}}:{}),
                                    ...(afternoonSlot.length ? {afternoon:{starttime:slots.afternoon[0].start,endtime:slots.afternoon[0].end}}:{}),
                                    ...(eveningSlot.length ? {evening:{starttime:slots.evening[0].start,endtime:slots.evening[0].end}}:{}),
                                }
                                counter++;
                            }

                        }

                        await APIClient.put(API_ROUTE_UPDATE_ONLINEDOC(),payload).then(resdoc=>{
                            setIsLoading(false);
                            sessionStorage.removeItem('new_user_profile_data');
                            setModalData({
                                isSuccess:false,
                                headerText:'User Added',
                                subText:<Typography variant='subtitle2'>{resdoc?.name || "Doctor"} is added successfully on <Typography variant='subtitle2' fontWeight={'bold'}>{`"${moment().format('DD MMM YY')} at ${moment().format("hh:mm A")}"`}</Typography></Typography>,
                                actionButtonText:'',
                                actionButtonOnClick:'',
                                actionButtonOnClickNewUsers:()=>{
                                    handleAddNewUser();
                                },
                                actionButtonOnClickNewClinics:()=>{
                                    navigate(ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC)
                                }
                            })
                            setUserAddedModalOpen(true);
                        }).catch(err=>{
                            setIsLoading(false);
                            setSnackbarState({
                                isSuccess:true,
                                open:true,
                                message:err || 'Unable to add user',
                                color:'red'
                            })
                            return;
                        })

                        
                    }else{
                        setModalData({
                            isSuccess:false,
                            headerText:'User Added',
                            subText:<Typography variant='subtitle2'>{res?.name || "User"} is added successfully on <Typography variant='subtitle2' fontWeight={'bold'}>{`"${moment().format('DD MMM YY')} at ${moment().format("hh:mm A")}"`}</Typography></Typography>,
                            actionButtonText:'',
                            actionButtonOnClick:'',
                            actionButtonOnClickNewUsers:()=>{
                                handleAddNewUser();
                            },
                            actionButtonOnClickNewClinics:()=>{
                                navigate(ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC)
                            }
                        })
                        setUserAddedModalOpen(true);
            
                        setIsLoading(false);
                    }

                    
                    
                    return;
                }).catch(err=>{
                    setIsLoading(false);
                    setSnackbarState({
                        isSuccess:true,
                        open:true,
                        message:err|| 'Unable to add user',
                        color:'red'
                    })
                    return;
                })
            }

        }

        return;
    } catch (error) {
        setIsLoading(false);
        setSnackbarState({
            isSuccess:true,
            open:true,
            message:'An error occured',
            color:'red'
        })
    }

    
  }

  const handleCancel = ()=>{
    navigate('/admin/clinicmanagement/allclinic')
    return;
  }

  const handleTabChange = (e,newValue)=>{
    if(newValue === 0){
        sessionStorage.removeItem('new_user_profile_data');
    }
    setTabValue(newValue)
  }

  const handleUserTabChange = (e,newValue)=>{
    setUserTabValue(newValue)
  }

  const handleAddNewUser = ()=>{
    navigate({
        pathname: "/admin/usermanagement",
        search: createSearchParams({
            account_id: searchParams.get("account_id"),
            account_name: searchParams.get("account_name"),
        }).toString()
    });
    navigate(0)
    return;
  }

  const handleClearRoleInput = ()=>{
    
    setState({role:[],taggedDoctors:[],features:initState.features,featureList:{}})
    return;
  }

  const handleChangeFeature = (e)=>{
    setState({
        featureList:{
            ...state.featureList,
            [e.target.name]: e.target.checked
        }
    })
  }

  const handleChangeTaggedDoctors = (e)=>{
    let val = e.target.value;
    setState({taggedDoctors:val})
    return;
  }

  const shouldShowNoUserFunc = ()=>{

    
    switch (userTabValue){
        case 0: {
            if(userCount.allusers===0){
                setNoUserState({
                    headerText:searchValue.length >= 3 ? "We couldn't find anything matching your search." : 'No Users Added Yet!',
                    subText:searchValue.length >= 3 ? 'Try again with a different term' : 'Start adding your Users and it will show up here',
                    buttonText:searchValue.length >= 3 ? "" : 'New User',
                    buttonOnClick:()=>{setTabValue(0)},
                    isSearch:searchValue.length >= 3
                })
            }
            return userCount.allusers===0
        }
        case 1:{
            if(userCount.doctors===0){
                setNoUserState({
                    headerText:searchValue.length >= 3 ? "We couldn't find anything matching your search." : 'No Doctors Added Yet!',
                    subText:searchValue.length >= 3 ? 'Try again with a different term' : 'Start adding your Doctors and it will show up here',
                    buttonText:searchValue.length >= 3 ? "" : 'New Doctor',
                    buttonOnClick:()=>{setTabValue(0)},
                    isSearch:searchValue.length >= 3
                })
            }
            return userCount.doctors===0
        } 
        case 2: {
            if(userCount.admin===0){
                setNoUserState({
                    headerText:searchValue.length >= 3 ? "We couldn't find anything matching your search." : 'No Admins Added Yet!',
                    subText:searchValue.length >= 3 ? 'Try again with a different term' : 'Start adding your Admins and it will show up here',
                    buttonText:searchValue.length >= 3 ? "" : 'New Admin',
                    buttonOnClick:()=>{setTabValue(0)},
                    isSearch:searchValue.length >= 3
                })
            }
            return userCount.admin===0
        } 
        case 3: {
            if(userCount.staff===0){
                setNoUserState({
                    headerText:searchValue.length >= 3 ? "We couldn't find anything matching your search." : 'No Staff Added Yet!',
                    subText:searchValue.length >= 3 ? 'Try again with a different term' : 'Start adding your Staff and it will show up here',
                    buttonText:searchValue.length >= 3 ? "" : 'New Staff',
                    buttonOnClick:()=>{setTabValue(0)},
                    isSearch:searchValue.length >= 3
                })
            }
            return userCount.staff===0
        }
        case 4: {
            if(userCount.superadmin===0){
                setNoUserState({
                    headerText:searchValue.length >= 3 ? "We couldn't find anything matching your search." : 'No Super Admin Added Yet!',
                    subText:searchValue.length >= 3 ? 'Try again with a different term' : 'Start adding your Super Admin and it will show up here',
                    buttonText:searchValue.length >= 3 ? "" : 'New Super Admin',
                    buttonOnClick:()=>{setTabValue(0)},
                    isSearch:searchValue.length >= 3
                })
            }
            return userCount.superadmin===0
        }
        default: false
    }
  }

  const ShowUserCard = ()=>{
    switch (userTabValue){
        case 0: {

            // setUserCount({
            //     allusers:userList?.allusers?.filter(elem=>{
            //         if(searchValue && searchValue?.length>=3){
            //             return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
            //         }
            //         return true;
            //     }).length,
            // })

            return (
                <>
                    {userList?.allusers?.filter(elem=>{
                        if(searchValue && searchValue?.length>=3){
                            return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
                        }
                        return true;
                    })?.map(elem=>{
                        return <UserCard data={elem} tag={GetUserRole(elem?.role)} features={
                            elem?.features?.split(',')?.length ? (
                                elem?.features?.split(',')?.reduce((acc,elem)=>{
                                    if(!featuresValueToKeyMap[elem.replace('f:','')]) return acc;
                                    return [...acc,featuresValueToKeyMap[elem.replace('f:','')]];
                                },[])
                            ) : (
                                []
                            )
                        }/>
                    })}
                </>
            )
        }
        case 1:{

            // setUserCount({
            //     doctors:userList?.doctors?.filter(elem=>{
            //         if(searchValue && searchValue?.length>=3){
            //             return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
            //         }
            //         return true;
            //     }).length,
            // })

            return (
                <>
                    {userList?.doctors?.filter(elem=>{
                        if(searchValue && searchValue?.length>=3){
                            return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
                        }
                        return true;
                    })?.map(elem=>{
                        return <UserCard data={elem} tag={"Doctor"} features={
                            elem?.features?.split(',')?.length ? (
                                elem?.features?.split(',')?.reduce((acc,elem)=>{
                                    if(!featuresValueToKeyMap[elem.replace('f:','')]) return acc;
                                    return [...acc,featuresValueToKeyMap[elem.replace('f:','')]];
                                },[])
                            ) : (
                                []
                            )
                        }/>
                    })}
                </>
            )
        }
        case 2: {

            // setUserCount({
            //     admin:userList?.admin?.filter(elem=>{
            //         if(searchValue && searchValue?.length>=3){
            //             return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
            //         }
            //         return true;
            //     }).length,
            // })

            return (
                <>
                    {userList?.admin?.filter(elem=>{
                        if(searchValue && searchValue?.length>=3){
                            return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
                        }
                        return true;
                    })?.map(elem=>{
                        return <UserCard data={elem} tag={"Admin"} features={
                            elem?.features?.split(',')?.length ? (
                                elem?.features?.split(',')?.reduce((acc,elem)=>{
                                    if(!featuresValueToKeyMap[elem.replace('f:','')]) return acc;
                                    return [...acc,featuresValueToKeyMap[elem.replace('f:','')]];
                                },[])
                            ) : (
                                []
                            )
                        }/>
                    })}
                </>
            )
        }
        case 3: {

            // setUserCount({
            //     staff:userList?.staff?.filter(elem=>{
            //         if(searchValue && searchValue?.length>=3){
            //             return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
            //         }
            //         return true;
            //     }).length,
            // })

            return (
                <>
                    {userList?.staff?.filter(elem=>{
                        if(searchValue && searchValue?.length>=3){
                            return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
                        }
                        return true;
                    })?.map(elem=>{
                        return <UserCard data={elem} tag={"Staff"} features={
                            elem?.features?.split(',')?.length ? (
                                elem?.features?.split(',')?.reduce((acc,elem)=>{
                                    if(!featuresValueToKeyMap[elem.replace('f:','')]) return acc;
                                    return [...acc,featuresValueToKeyMap[elem.replace('f:','')]];
                                },[])
                            ) : (
                                []
                            )
                        }/>
                    })}
                </>
            )
        }
        case 4: {

            // setUserCount({
            //     superadmin:userList?.superadmin?.filter(elem=>{
            //         if(searchValue && searchValue?.length>=3){
            //             return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
            //         }
            //         return true;
            //     }).length,
            // })

            return (
                <>
                    {userList?.superadmin?.filter(elem=>{
                        if(searchValue && searchValue?.length>=3){
                            return elem?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
                        }
                        return true;
                    })?.map(elem=>{
                        return <UserCard data={elem} tag={"Super Admin"} features={
                            elem?.features?.split(',')?.length ? (
                                elem?.features?.split(',')?.reduce((acc,elem)=>{
                                    if(!featuresValueToKeyMap[elem.replace('f:','')]) return acc;
                                    return [...acc,featuresValueToKeyMap[elem.replace('f:','')]];
                                },[])
                            ) : (
                                []
                            )
                        }/>
                    })}
                </>
            )
        }
        default: return <></>
    }
  }

  const FilterUserData = ()=>{

    setUsersLoader(true);

    let allusersList = usersList
    let doctorsList = usersList?.filter((elem)=>elem.role.includes('doctor'))
    let adminList = usersList?.filter((elem)=>elem.role.includes('admin') && !elem.role.includes('superadmin'))
    let superadminList = usersList?.filter((elem)=>elem.role.includes('superadmin'))
    let nurseList = usersList?.filter((elem)=>elem.role.includes('staff'))

    setUserCount({
        allusers:allusersList.length,
        doctors:doctorsList.length,
        admin:adminList.length,
        superadmin:superadminList.length,
        staff:nurseList.length,
    })

    setUserList({
        allusers:allusersList,
        doctors:doctorsList,
        admin:adminList,
        superadmin:superadminList,
        staff:nurseList,
    })

    setUsersLoader(false);
  }

  const handleClickHome = ()=>{
    navigate('/admin/clinicmanagement/allclinic')
    return;
  }

  const handleClickNewUser = ()=>{
    sessionStorage.removeItem('new_user_profile_data');
    navigate({
        pathname: "/admin/usermanagement",
        search: createSearchParams({
            account_id: searchParams.get("account_id"),
            account_name: searchParams.get("account_name"),
        }).toString()
    });
    navigate(0)
    return;
  }

  const handleDeleteTaggedDoctorChip = (value)=>{
    let list = state.taggedDoctors.filter(elem=>elem.id!==value.id)
    setState({taggedDoctors:list});
    return;
  }

  const handleClickNavigateSlotManagement = ()=>{
    

    if(searchParams.get('isEdit')){
        navigate({
            pathname: ROUTE_ADMIN_SLOT_MANAGEMENT,
            search: createSearchParams({
                isEdit:true,
                account_id: searchParams.get('account_id'),
                account_name: searchParams.get('account_name'),
                user_id: searchParams.get('user_id'),
                cognito_user_sub:searchParams.get('cognito_user_sub')
            }).toString()
        });
    }else{
        sessionStorage.setItem('admin_add_user_data',JSON.stringify(state))
        sessionStorage.setItem('admin_slot_management_data',JSON.stringify(slotState))

        navigate({
            pathname: ROUTE_ADMIN_SLOT_MANAGEMENT,
            search: createSearchParams({
                account_id: searchParams.get('account_id'),
                account_name: searchParams.get('account_name'),
            }).toString()
        });
    }

    
    return;
  }

  const handleClickNavigateDoctorProfile = ()=>{
    let userid = searchParams.get('cognito_user_sub') ? searchParams.get('cognito_user_sub') : "newuser";
    if(searchParams.get('isEdit')){
        navigate({
            pathname: `${ROUTE_ADMIN_SINGLE_DOCTOR_VIEW(searchParams.get('account_id'),userid)}/`,
            search: createSearchParams({
                isEdit:true,
                account_id: searchParams.get('account_id'),
                account_name: searchParams.get('account_name'),
                user_id: searchParams.get('user_id'),
                cognito_user_sub:searchParams.get('cognito_user_sub')
            }).toString()
        });
    }else{
        sessionStorage.setItem('admin_add_user_data',JSON.stringify(state))
        sessionStorage.setItem('admin_slot_management_data',JSON.stringify(slotState))

        navigate({
            pathname: `${ROUTE_ADMIN_SINGLE_DOCTOR_VIEW(searchParams.get('account_id'),userid)}/`,
            search: createSearchParams({
                account_id: searchParams.get('account_id'),
                account_name: searchParams.get('account_name'),
            }).toString()
        });
    }

    
    return;
    //console.log("cognito_user_sub",searchParams.get('cognito_user_sub'));
    //let userid = searchParams.get('cognito_user_sub') ? searchParams.get('cognito_user_sub') : "newuser";
    //console.log("userid",userid);
    navigate(`${ROUTE_ADMIN_SINGLE_DOCTOR_VIEW(searchParams.get('account_id'),userid)}/`);
    return;
  }

  const FetchUserDetails = async (cognito_user_sub,account_id)=>{

    setFetchDoctorLoader(true);
    setFetchUsersLoader(true);

    if(!usersList.length && !isCopyUser){
        return;
    }

    let filteredUser;
    let result;
    
    if(isCopyUser){
        filteredUser = [selectedUserCopyUser]
    }else{
        filteredUser = usersList.filter((elem)=>{
            return elem.id == searchParams.get('user_id')
        })
    }
    

    await APIClient.get(API_ROUTE_USER_DETAILS(cognito_user_sub,account_id)).then(async (res)=>{
        result = res.data;
        setFetchDoctorLoader(false);
    }).catch(err=>{
        console.log("error fetching user details",err)
        setSnackbarState({
            isSuccess:true,
            open:filteredUser[0]?.role?.includes('doctor') ? true : false,
            message:err|| 'Unable to fetch details',
            color:'red'
        })
        setFetchDoctorLoader(false);
    })
    
    console.log("result",result)
    console.log("filteredUser",filteredUser)

    let isOnline = false;
    let isOffline = false;
    

    //get role and populate slots
    if(filteredUser.length){

        let revFeaturesMap = {};
        for(const [key,value] of Object.entries(state.featureValues)){
            revFeaturesMap[value] = key;
        }

        let featuresList = {}
        filteredUser[0]?.features?.split(',')?.forEach(elem=>{
            if(revFeaturesMap[elem]){
                featuresList[revFeaturesMap[elem]] = true;
            }
        })

        let fl = {};
        GetUserRole(filteredUser[0]?.role,true)?.split(',')?.forEach(elem=>{
            if(state.features[elem]){
                fl = {
                    ...fl,
                    ...state.features[elem],
                }
            }
        })
        setState({
            featureList:{
                ...fl,
                ...featuresList
            }
        })

        // setState({
        //     features:{
        //         ...state.features,
        //         [GetUserRole(filteredUser[0]?.role,true)]:{
        //             ...state.features[GetUserRole(filteredUser[0]?.role,true)],
        //             ...featuresList
        //         }
        //     }
        // })

        //for populating offline slots
        if(filteredUser[0]?.slot_type){
            if(Object.keys(filteredUser[0].slot_type)?.length){

                isOffline = true;
                
                let tempObj = {...slotState.offline};

                for(const [slot_number,days] of Object.entries(filteredUser[0].slot_type)){

                    
        
                    days.forEach(day=>{
                        let d = day.toLowerCase()
                        tempObj[d] = {
                            ...slotState.offline[d],
                            morning:filteredUser[0]?.slots[slot_number]?.morning?.starttime && filteredUser[0]?.slots[slot_number]?.morning?.endtime ? [...slotState.offline[d].morning,{start: filteredUser[0]?.slots[slot_number]?.morning?.starttime, end: filteredUser[0]?.slots[slot_number]?.morning?.endtime}] : [],
                            afternoon:filteredUser[0]?.slots[slot_number]?.afternoon?.starttime && filteredUser[0]?.slots[slot_number]?.afternoon?.endtime ? [...slotState.offline[d].afternoon,{start: filteredUser[0]?.slots[slot_number]?.afternoon?.starttime, end: filteredUser[0]?.slots[slot_number]?.afternoon?.endtime}] : [],
                            evening:filteredUser[0]?.slots[slot_number]?.evening?.starttime && filteredUser[0]?.slots[slot_number]?.evening?.endtime ? [...slotState.offline[d].evening,{start: filteredUser[0]?.slots[slot_number]?.evening?.starttime, end: filteredUser[0]?.slots[slot_number]?.evening?.endtime}] : [],
                        } 
                    })
                    
                }
                setSlotState({
                    offline:{
                        ...slotState.offline,
                        ...tempObj
                    }
                })

            }
        }

        //for populating online slots
        if(result){
            if(result.slot_type && Object.keys(result.slot_type)?.length){
                
                isOnline = true;

                let tempObj = {...slotState.online};

                for(const [slot_number,days] of Object.entries(result.slot_type)){

                    
        
                    days.forEach(day=>{
                        let d = day.toLowerCase()
                        tempObj[d] = {
                            ...slotState.online[d],
                            morning:result?.slots[slot_number]?.morning?.starttime && result?.slots[slot_number]?.morning?.endtime ? [...slotState.online[d].morning,{start: result?.slots[slot_number]?.morning?.starttime, end: result?.slots[slot_number]?.morning?.endtime}] : [],
                            afternoon:result?.slots[slot_number]?.afternoon?.starttime && result?.slots[slot_number]?.afternoon?.endtime ? [...slotState.online[d].afternoon,{start: result?.slots[slot_number]?.afternoon?.starttime, end: result?.slots[slot_number]?.afternoon?.endtime}] : [],
                            evening:result?.slots[slot_number]?.evening?.starttime && result?.slots[slot_number]?.evening?.endtime ? [...slotState.online[d].evening,{start: result?.slots[slot_number]?.evening?.starttime, end: result?.slots[slot_number]?.evening?.endtime}] : [],
                        } 
                    })
                    
                }

                setSlotState({
                    online:{
                        ...slotState.online,
                        ...tempObj
                    }
                })
            }
        }
        

        setFilteredUserData({
            ...(filteredUser[0] ? filteredUser[0] : {}),
            ...(result ? result : {}),
        })

        if(filteredUser[0]?.role.includes('staff')){
            let doctors = filteredUser[0]?.doctor_id?.map(taggedDoc=>{
                let filteredDoc = doctorList?.filter(elem=>{
                    return elem.cognito_user_sub === taggedDoc.cognito_user_sub;
                })
                return filteredDoc[0];
            })

            setState({
                taggedDoctors:doctors
            })
        }
        
        
    }

    setState({
        fullname:filteredUser.length ? filteredUser[0]?.name : '',
        phoneNumber:filteredUser.length ? filteredUser[0]?.phone : '',
        email:filteredUser.length ? filteredUser[0]?.email : '',
        role:filteredUser.length ? GetUserRole(filteredUser[0]?.role,true)?.split(',') : '',
    })

    

    
    //populate rest of data for slotstate
    setSlotState({
        consultation_duration:filteredUser[0]?.consultation_duration || result?.consultation_duration || '',
        duration_type:"min",
        free_follow_up_number:filteredUser[0]?.free_follow_days ||  result?.free_follow_days || '',
        free_follow_up_duration:filteredUser[0]?.free_follow_duration||  result?.free_follow_durations || '',
        consultation:filteredUser[0]?.service_name ||  result?.service_names || '',
        type_of_service:filteredUser[0]?.service_type || result?.service_types || '',
        description:filteredUser[0]?.service_description || result?.service_descriptions || '',
        // leaves:filteredUser[0]?.leaves?.split(',') ||  result?.leaves?.split(','),
        leaves: filteredUser[0]?.leaves ||  result?.leaves,
    })

    setSlotState({
        type:{
            online:{
                checked:isOnline,
                price:typeof result?.consultation_fee ==='object' ? result?.consultation_fee?.online || 1000 : 1000,
            },
            in_visit:{
                checked:isOffline,
                price:typeof result?.consultation_fee ==='object' ? result?.consultation_fee?.offline || 1000 : 1000,
            }
        }
    })

    setFetchUsersLoader(false);
  }

  const GetUserRole = (role,getValue=false)=>{
    // let res = state.roleOptions.find((elem)=>role.includes(elem.value))
    let res = {
        value:role?.replaceAll('r:',""),
        label:role?.split(",")?.filter(elem=>{return elem?.includes('r:') && !elem?.includes('f:') && !elem?.includes('e:')})?.join(',')?.replaceAll('r:',"")?.toUpperCase()
    };
    console.log("role",res);
    if(getValue) return res.value;
    return res?.label ? res.label : 'User';
  }

  const handleClickCopyUser = ()=>{
    setIsCopyUser((prev)=>!prev)
  }

  const handleClickBack = ()=>{
    navigate({
        pathname: ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC
    });
  }


  return (
    <>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            ContentProps={{ sx: { background: snackbarState.isSuccess?snackbarState.color:"red", marginTop: "40px" } }}
            open={snackbarState.isSuccess?snackbarState.open:errorState.showError}
            onClose={() => snackbarState.isSuccess? setSnackbarState({open:false}) : setErrorState({showError:false})}
            autoHideDuration={6000}
            message={snackbarState.isSuccess?snackbarState.message:errorState.errorMsg}
        />
      {isMobileScreen ? (
        <>
          
        </>
      ) : (
        <Box height={"100%"} width={"100%"} zIndex={100} position={"absolute"}>
          
          <Box >
            <Grid container height={{xs:"100%",md:`calc(100vh - 42px)`}} width={{xs:"100%",md:`calc(100vw - 60px)`}} spacing={4}>
              
              {/* left panel */}
              <Grid
                item
                height="100%"
                xs={3.3}
                
              >
                <Box 
                    width={'100%'} 
                    height={'100%'}
                    sx={{
                        // boxShadow: "1px 1px 4px 2px rgba(0,0,0,0.2)",
                        backgroundColor: "#ffffff",
                        borderRadius:'0px 8px 0px 0px'
                    }}
                >
                    {
                        sections.map((elem)=>{
                            return (
                                <Box 
                                    key={elem.label}
                                    width={'100%'} 
                                    boxSizing={'border-box'}
                                    paddingX={4} 
                                    paddingY={2} 
                                    display={'flex'} 
                                    justifyContent={'space-between'} 
                                    alignItems={'center'}
                                    onClick={()=>{
                                        // navigate(elem.value)
                                    }}
                                    sx={{
                                        cursor:'pointer'
                                    }}
                                >
                                    <Typography 
                                        variant='subtitle2' 
                                        color={pathname.includes(elem.value) ? theme.palette.primary.main : 'black'}
                                        fontWeight={pathname.includes(elem.value) ? "bold" : 'regular'}
                                    >
                                        {elem.label}
                                    </Typography>
                                    {
                                        pathname.includes(elem.value) && (
                                            <ArrowForwardIosOutlinedIcon fontSize='small' sx={{color:theme.palette.primary.main}}/>
                                        )
                                    }
                                </Box>
                            )
                        })
                    }
                </Box>
              </Grid>

              {/* right panel */}
              <Grid
                item
                height="100%"
                xs
                spacing={10}
              >
                
                
                <Grid
                    container
                    height={'100%'}
                    spacing={2}
                    direction={'column'}
                >
                    {fetchDoctorLoader || fetchUsersLoader || loader || usersloader ? (
                        <>
                            <Grid
                                item
                                xs
                            >
                                <Box 
                                    width={'100%'} 
                                    height={'100%'}
                                    sx={{
                                        // boxShadow: "1px 1px 2px 2px rgba(0,0,0,0.2)",
                                        backgroundColor: "#ffffff",
                                        boxSizing:'border-box',
                                        flex:1,
                                        borderRadius:'8px',
                                        // marginX:'50px'
                                        padding:2
                                    }}
                                >
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                    <Skeleton animation={'wave'}/>
                                </Box>
                            </Grid>
                            
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                xs

                            >

                                <Box 
                                    width={'100%'} 
                                    height={'100%'}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        boxSizing:'border-box',
                                        flex:1,
                                        borderRadius:'8px'
                                    }}
                                >
                                    <Box height={'100%'} display={'flex'} flexDirection={'column'}>
                                        
                                        <Box width={'100%'} px={2} pt={3} display={'flex'} justifyContent={'space-between'}>
                                            <Box display={'flex'} gap={0} alignItems={'center'}>
                                                <IconButton
                                                    onClick={handleClickBack}
                                                >
                                                    <ArrowBackIcon/>
                                                </IconButton>
                                                <Typography variant='subtitle1' fontWeight={'bold'}>{searchParams.get('account_name')}</Typography>
                                            </Box>
                                        </Box>

                                        <Box position={'relative'} width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingX={2}>
                                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                                <Tab 
                                                    label={searchParams.has("isEdit") ? ADMIN_TAB_EDIT_USER['eng'] : ADMIN_TAB_ADD_NEW_USER['eng'] } 
                                                    // disabled={searchParams.has("isEdit") ? !userFeatures.includes(getFeatureValue('USER_UPDATION')) : !userFeatures.includes(getFeatureValue('USER_CREATION'))} 
                                                    style={tabStyle}
                                                />
                                                <Tab label={ADMIN_TAB_MANAGE_EXISTING_USERS['eng']} style={tabStyle}/>
                                            </Tabs>
                                            <Box hidden={tabValue !== 0}>
                                                {/* {searchParams.has("isEdit") && (
                                                    <Button
                                                        variant='contained'
                                                        startIcon={<AddOutlinedIcon/>}
                                                        sx={{
                                                            marginTop:'14px'
                                                        }}
                                                        onClick={handleAddNewUser}
                                                        
                                                    >
                                                        <Typography textTransform={'none'} variant='subtitle2'>{ADMIN_NEW_USER['eng']}</Typography>
                                                    </Button>
                                                )} */}
                                                
                                            </Box>
                                            <Box hidden={tabValue !== 1} position={'absolute'} right={14} top={10}>
                                                <TextField
                                                    placeholder={ADMIN_SEARCH_BY_NAME['eng']}
                                                    InputProps={{
                                                        startAdornment:<SearchIcon/>,
                                                        autoComplete:'off'
                                                    }}
                                                    inputProps={{
                                                        autoComplete: 'off'
                                                    }}
                                                    value={searchValue}
                                                    onChange={handleSearch}

                                                />
                                            </Box>
                                            
                                        </Box>

                                        <Box height={'calc(100vh - 340px)'} flex={1} overflow={'auto'}>
                                            {/* add new user */}
                                            <div
                                                role="tabpanel"
                                                hidden={tabValue !== 0}
                                                id={`simple-tabpanel-${0}`}
                                                aria-labelledby={`simple-tab-${0}`}
                                            >
                                                {tabValue === 0 && (
                                                    <Box height={'calc(100vh - 300px)'} display={'flex'} flexDirection={'column'} gap={2} sx={{ p: 3,overflow:'auto' }}>
                                                        <Box display={'flex'} gap={4}>
                                                            <Box display={'flex'} flexDirection={'column'} gap={2} flex={1}>
                                                                
                                                                {isCopyUser && (
                                                                    <Box display={'flex'} flexDirection={'column'} gap={2} mb={2}>
                                                                        <Typography variant='subtitle1'>Copy from: </Typography>
                                                                        <Autocomplete
                                                                            value={selectedAccountCopyUser}
                                                                            onChange={(event, newValue) => {
                                                                                setSelectedAccountCopyUser(newValue);
                                                                            }}
                                                                            inputValue={accountSearchValue}
                                                                            onInputChange={(event, newInputValue,reason) => {
                                                                                if(reason==='clear'){
                                                                                    ResetAllFields()
                                                                                    setSelectedUserCopyUser({});
                                                                                    setOptionsUsersCopyUser([]);
                                                                                }
                                                                                setAccountSearchValue(newInputValue);
                                                                                handleSearchClinic(newInputValue)
                                                                            }}
                                                                            
                                                                            getOptionLabel={(option) => option["account_name"] || ''}
                                                                            filterOptions={(options, { inputValue }) => {
                                                                                return options.filter((option) => option?.account_name?.toLowerCase()?.includes(inputValue?.trim()?.toLowerCase()));
                                                                            }}
                                                                            noOptionsText={clinicSearchResultsStatus ? clinicSearchResultsStatus : 'Type to search clinic...'}
                                                                            renderOption={(props, option, state) => {
                                                                                return <Box
                                                                                    component="li"
                                                                                    sx={{
                                                                                    backgroundColor: state.selected
                                                                                        ? "#F2F3FF !important"
                                                                                        : "",
                                                                                    "&:hover, &:focus": {
                                                                                        backgroundColor: "#F2F3FF !important",
                                                                                    },
                                                                                    }}
                                                                                    {...props}
                                                                                >
                                                                                    {option.account_name}
                                                                                </Box>
                                                                            }}
                                                                            id="controllable-states-account"
                                                                            options={clinicSearchList}
                                                                            renderInput={(params) => <TextField {...params} label="Select Account" />}
                                                                        />

                                                                        <Autocomplete
                                                                            disabled={!selectedAccountCopyUser?.account_id}
                                                                            value={selectedUserCopyUser}
                                                                            onChange={(event, newValue) => {
                                                                                setSelectedUserCopyUser({...newValue});
                                                                            }}
                                                                            inputValue={userSearchValue}
                                                                            onInputChange={(event, newInputValue,reason) => {
                                                                                if(reason==='clear'){
                                                                                    ResetAllFields();
                                                                                }
                                                                                setUserSearchValue(newInputValue);
                                                                            }}
                                                                            noOptionsText={!fetchCopyUsersLoader ? "No Users Found" : <CircularProgress size={'20px'}/>}
                                                                            getOptionLabel={(option) => option["name"] || ''}
                                                                            filterOptions={(options, { inputValue }) => {
                                                                                return options.filter((option) => option?.name?.toLowerCase()?.includes(inputValue?.trim()?.toLowerCase()));
                                                                            }}
                                                                            renderOption={(props, option, state) => {
                                                                                return <Box
                                                                                    component="li"
                                                                                    sx={{
                                                                                    backgroundColor: state.selected
                                                                                        ? "#F2F3FF !important"
                                                                                        : "",
                                                                                    "&:hover, &:focus": {
                                                                                        backgroundColor: "#F2F3FF !important",
                                                                                    },
                                                                                    }}
                                                                                    {...props}
                                                                                >
                                                                                    {option.name}
                                                                                </Box>
                                                                            }}
                                                                            id="controllable-states-account"
                                                                            options={optionsUsersCopyUser}
                                                                            renderInput={(params) => <TextField {...params} label="Select User" />}
                                                                        />

                                                                        <Divider sx={{mt:2}}/>
                                                                    </Box>
                                                                )}
                                                                
                                                                <TextField
                                                                    label={"Full Name"}
                                                                    type={"text"}
                                                                    required={true}
                                                                    disabled={selectedUserCopyUser.cognito_user_sub}
                                                                    value={state.fullname}
                                                                    onChange={(e) => {
                                                                        const regex = /^[A-Za-z ]*$/;
                                                                        if (regex.test(e.target.value)) {
                                                                            setState({ fullname: e.target.value });
                                                                            if (errorState.errorField === "name") {
                                                                                setErrorState({errorField:''});
                                                                            }
                                                                        } else {
                                                                            return;
                                                                        }
                                                                    }}
                                                                    helperText={
                                                                        errorState.errorField === "name" && errorState.errorMsg
                                                                    }
                                                                    error={errorState.errorField === "name"}
                                                                />

                                                                <TextField
                                                                    label={ADMIN_PHONE_NUMBER['eng']}
                                                                    autoComplete="off"
                                                                    // type={"number"}
                                                                    inputProps={{
                                                                    min: "10", // Minimum value
                                                                    max: "10", // Maximum value
                                                                    }}
                                                                    required={true}
                                                                    disabled={selectedUserCopyUser.cognito_user_sub}
                                                                    value={state.phoneNumber}
                                                                    onChange={(e) => {
                                                                        const onlyNums = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                        if (onlyNums.length > 10) {
                                                                            return;
                                                                        } else {
                                                                            setState({ phoneNumber: onlyNums });
                                                                            if (errorState.errorField === "phone number") {
                                                                                setErrorState({errorField:''});
                                                                            }
                                                                        }
                                                                    }}
                                                                    helperText={
                                                                        errorState.errorField === "phone number" && errorState.errorMsg
                                                                    }
                                                                    error={errorState.errorField === "phone number"}
                                                                />
                                                                <SearchInput
                                                                    label={ADMIN_EMAIL['eng']}
                                                                    autoComplete="off"
                                                                    isSearchable={false}
                                                                    value={state.email}
                                                                    setValue={(val) => {
                                                                        setState({ email: val });
                                                                        if (errorState.errorField === "email") {
                                                                            setErrorState({errorField:''});
                                                                        }
                                                                    }}
                                                                    helperText={errorState.errorField === "email" && errorState.errorMsg}
                                                                    error={errorState.errorField === "email"}
                                                                />

                                                                <FormControl fullWidth error={errorState.errorField === "role"} required disabled={selectedUserCopyUser.cognito_user_sub}>
                                                                    <InputLabel id="demo-simple-select-label">{ADMIN_ROLE['eng']}</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        multiple
                                                                        value={state.role}
                                                                        label={ADMIN_ROLE['eng']}
                                                                        onChange={(e)=>{
                                                                            let value = e.target.value;
                                                                            if(value.includes('superadmin') && state.role.includes('admin')) return;
                                                                            if(value.includes('admin') && state.role.includes('superadmin')) return;
                                                                            if(value.includes('superadmin') && state.role.includes('staff')) return;
                                                                            if(value.includes('staff') && state.role.includes('superadmin')) return;
                                                                            if(value.includes('superadmin') && state.role.includes('doctor')) return;
                                                                            if(value.includes('doctor') && state.role.includes('superadmin')) return;
                                                                            if(value.includes('doctor') && state.role.includes('staff'))return;
                                                                            if(value.includes('staff') && state.role.includes('doctor'))return;

                                                                            setState({featureList: value?.reduce((acc,curr)=>{
                                                                                return acc = {
                                                                                    ...acc,
                                                                                    ...state.features[curr]
                                                                                }
                                                                            },{})})
                                                                            
                                                                            setState({role:value})}
                                                                        }
                                                                        endAdornment={true && !selectedUserCopyUser.cognito_user_sub && <Box mr={2} display={'flex'} alignItems={'center'} onClick={handleClearRoleInput}><CloseOutlinedIcon sx={{color:theme.palette.white.greyText,cursor:'pointer'}}/></Box>}
                                                                    >
                                                                        {state.roleOptions?.map(elem=>{
                                                                            return (
                                                                                <MenuItem value={elem.value}>{elem.label}</MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                    {errorState.errorField === "role" && <FormHelperText>{errorState.errorMsg}</FormHelperText>}
                                                                </FormControl>

                                                                {state.role.includes("staff") && (
                                                                    <Box>
                                                                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                                                            <Tab label={ADMIN_TAG_USER_TO_DOCTOR['eng']} style={tabStyle2}/>
                                                                        </Tabs>
                                                                        <Box hidden={tabValue !== 0}>
                                                                            <FormControl sx={{ my: 2, width: 300 }}>
                                                                                <InputLabel id="demo-multiple-checkbox-label">{ADMIN_SEARCH_DOCTOR['eng']}</InputLabel>
                                                                                <Select
                                                                                labelId="demo-multiple-checkbox-label"
                                                                                multiple
                                                                                value={state.taggedDoctors}
                                                                                onChange={handleChangeTaggedDoctors}
                                                                                input={<OutlinedInput label={ADMIN_SEARCH_DOCTOR['eng']}/>}
                                                                                renderValue={(selected) => (
                                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                    {console.log("selected",selected)}
                                                                                    {selected?.map((value) => (
                                                                                        <Chip 
                                                                                            label={value?.name}
                                                                                            onDelete={(e)=>{handleDeleteTaggedDoctorChip(value);}} 
                                                                                            deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} sx={{ "&&": { color: theme.palette.primary.main } }}/>}
                                                                                            sx={{
                                                                                                backgroundColor:theme.palette.primary.light,
                                                                                                color:theme.palette.primary.main,
                                                                                            }}
                                                                                        />
                                                                                    ))}
                                                                                    </Box>
                                                                                )}
                                                                                >
                                                                                {doctorList.map((elem) => (
                                                                                    <MenuItem key={elem.id} value={elem} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                                        <ListItemText primary={elem.name} />
                                                                                        <Checkbox checked={state.taggedDoctors.map(x=>x?.id).indexOf(elem?.id) > -1} />
                                                                                    </MenuItem>
                                                                                ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Box>
                                                                )}

                                                            </Box>
                                                            {!searchParams.has('isEdit') && (
                                                                <Box height={'30px'} display={"flex"} alignItems={'center'} gap={1} mt={1.5} sx={{cursor:'pointer'}} onClick={()=>{handleClickCopyUser()}}>
                                                                    <Typography variant='subtitle1'>{'Copy User'}</Typography>
                                                                    <ContentCopyIcon fontSize='small' color={"primary"}/>
                                                                </Box>
                                                            )}
                                                            
                                                            <Box display={'flex'} flexDirection={'column'} flex={1}>
                                                                <Typography variant='subtitle1'>{ADMIN_FEATURES['eng']}</Typography>
                                                                <FormGroup sx={{paddingX:1}}>
                                                                    {Object.keys(state.featureList)?.length!==0 && Object.keys(state.featureList).map(elem=>{
                                                                        return(
                                                                            <FormControlLabel disabled={selectedUserCopyUser.cognito_user_sub} control={<Checkbox value={state.featureList[elem]} checked={state.featureList[elem]} onChange={handleChangeFeature} name={elem} color='blue'/>} label={elem} slotProps={{typography:{variant:'subtitle1'}}}/>
                                                                        )
                                                                    })}
                                                                    {Object.keys(state.featureList)?.length===0 && <Typography variant='caption' color={theme.palette.white.greyText}>{ADMIN_SELECT_ROLE['eng']}</Typography>}
                                                                </FormGroup>
                                                            </Box>
                                                        </Box>
                                                        {state.role.includes("doctor") && !selectedUserCopyUser.cognito_user_sub && (
                                                            <Box display={(userFeatures.includes(getFeatureValue('USER_CREATION')) || userFeatures.includes(getFeatureValue('USER_UPDATION'))) ? 'flex' : 'none'} flexDirection={'column'} gap={1}>
                                                                <Divider/>
                                                                <Typography mt={1}>Redirects to</Typography>
                                                                <Box display={'flex'} gap={6}>
                                                                    <Box display={'flex'} gap={0.5} alignItems={'center'} sx={{cursor:'pointer'}} onClick={handleClickNavigateSlotManagement}>
                                                                        <Box
                                                                            component={'img'}
                                                                            src={'/images/slotmanagementicon.svg'}
                                                                        />
                                                                        <Typography variant='subtitle2' color={'primary'}>Slot Management</Typography>
                                                                        <Box
                                                                            component={'img'}
                                                                            src={'/images/rightarrow.svg'}
                                                                        />
                                                                    </Box>
                                                                    <Box display={'flex'} gap={0.5} alignItems={'center'} sx={{cursor:'pointer'}} onClick={handleClickNavigateDoctorProfile}>
                                                                        <Box
                                                                            component={'img'}
                                                                            src={'/images/doctorprofileicon.svg'}
                                                                        />
                                                                        <Typography variant='subtitle2' color={'primary'}>Doctor Profile</Typography>
                                                                        <Box
                                                                            component={'img'}
                                                                            src={'/images/rightarrow.svg'}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                )}
                                            </div>

                                            {/* manage existing users */}
                                            <div
                                                role="tabpanel"
                                                hidden={tabValue !== 1}
                                                id={`simple-tabpanel-${1}`}
                                                aria-labelledby={`simple-tab-${1}`}
                                                style={{height:'100%'}}
                                            >
                                                {usersloader ? (
                                                    <Box padding={4}>
                                                        <Skeleton animation={'wave'}/>
                                                        <Skeleton animation={'wave'}/>
                                                        <Skeleton animation={'wave'}/>
                                                        <Skeleton animation={'wave'}/>
                                                        <Skeleton animation={'wave'}/>
                                                        <Skeleton animation={'wave'}/>
                                                    </Box>
                                                ) : (
                                                    tabValue === 1 && (
                                                        <Box sx={{ p: 3 }} mt={0.5} height={'calc(100vh - 280px)'} display={'flex'} flexDirection={'column'}>
                                                            <Tabs value={userTabValue} onChange={handleUserTabChange}>
                                                                <Tab 
                                                                    label={
                                                                        <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'}>
                                                                            <Typography variant='caption'>{ADMIN_ALL_USERS['eng']}</Typography>
                                                                            <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'} width={25} height={25} borderRadius={'50%'} backgroundColor={theme.palette.red.medium}>
                                                                                <Typography lineHeight={0} variant='caption' color={theme.palette.white.main}>{userCount.allusers?.toString()}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    } 
                                                                    style={usertabStyle}
                                                                />
                                                                <Tab 
                                                                    label={
                                                                        <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'}>
                                                                            <Typography variant='caption'>{ADMIN_DOCTORS['eng']}</Typography>
                                                                            <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'} width={25} height={25} borderRadius={'50%'} backgroundColor={theme.palette.red.medium}>
                                                                                <Typography lineHeight={0} variant='caption' color={theme.palette.white.main}>{userCount.doctors?.toString()}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    style={usertabStyle}
                                                                />
                                                                <Tab 
                                                                    label={
                                                                        <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'}>
                                                                            <Typography variant='caption'>{ADMIN_ADMIN['eng']}</Typography>
                                                                            <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'} width={25} height={25} borderRadius={'50%'} backgroundColor={theme.palette.red.medium}>
                                                                                <Typography lineHeight={0} variant='caption' color={theme.palette.white.main}>{userCount.admin?.toString()}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    style={usertabStyle}
                                                                />
                                                                {/* <Tab 
                                                                    label={
                                                                        <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'}>
                                                                            <Typography variant='caption'>{ADMIN_SUPER_ADMIN['eng']}</Typography>
                                                                            <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'} width={25} height={25} borderRadius={'50%'} backgroundColor={theme.palette.red.medium}>
                                                                                <Typography lineHeight={0} variant='caption' color={theme.palette.white.main}>{userCount.superadmin?.toString()}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    style={usertabStyle}
                                                                /> */}
                                                                <Tab 
                                                                    label={
                                                                        <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'}>
                                                                            <Typography variant='caption'>{'STAFF'}</Typography>
                                                                            <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'} width={25} height={25} borderRadius={'50%'} backgroundColor={theme.palette.red.medium}>
                                                                                <Typography lineHeight={0} variant='caption' color={theme.palette.white.main}>{userCount.staff?.toString()}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    style={usertabStyle}
                                                                />
                                                                <Tab 
                                                                    label={
                                                                        <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'}>
                                                                            <Typography variant='caption'>{'SUPER ADMIN'}</Typography>
                                                                            <Box display={'flex'} gap={1} justifyContent={'center'} alignItems={'center'} width={25} height={25} borderRadius={'50%'} backgroundColor={theme.palette.red.medium}>
                                                                                <Typography lineHeight={0} variant='caption' color={theme.palette.white.main}>{userCount.superadmin?.toString()}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    style={usertabStyle}
                                                                />
                                                            </Tabs>
                                                            <Box height={'100%'} width={'100%'} display={shouldShowNoUser?"block":'none'}>
                                                                {loader || usersLoader ? (
                                                                    <>
                                                                        <Skeleton animation="wave"/>
                                                                        <Skeleton animation="wave"/>
                                                                        <Skeleton animation="wave"/>
                                                                    </>
                                                                ) : (
                                                                    <NoUser
                                                                        headerText={noUserState.headerText}
                                                                        subText={noUserState.subText}
                                                                        buttonText={noUserState.buttonText}
                                                                        buttonOnClick={noUserState.buttonOnClick}
                                                                        isSearch={noUserState.isSearch}

                                                                    />
                                                                )}
                                                            </Box>
                                                            <Box height={'100%'} display={!shouldShowNoUser?"block":'none'} flexDirection={'column'} justifyContent={'space-between'} flex={1} gap={2} width={'100%'} mt={3}>
                                                                
                                                                <Box height={'calc(100% - 50px)'} display={'flex'} flex={1} gap={2} flexWrap={'wrap'} overflow={'auto'}>
                                                                    {loader ? <>
                                                                        <Skeleton animation={'wave'}/>
                                                                        <Skeleton animation={'wave'}/>
                                                                        <Skeleton animation={'wave'}/>
                                                                    </> : ShowUserCard()
                                                                    }
                                                                </Box>
                                                                
                                                                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} mt={2}>
                                                                    <Button variant='outlined' onClick={handleClickHome}>
                                                                        <Typography variant='caption' color={theme.palette.white.greyText}>{ADMIN_HOME['eng']}</Typography>
                                                                    </Button>
                                                                    <Button variant='contained' onClick={handleClickNewUser} startIcon={<AddOutlinedIcon/>} sx={{display:userFeatures.includes(getFeatureValue('USER_CREATION')) ? 'flex' : 'none'}}>
                                                                        <Typography variant='caption' >{ADMIN_NEW_USER['eng']}</Typography>
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                )}
                                                
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            {/* Bottom Buttons */}
                            <Grid
                                item
                                xs={1.5}
                                hidden={tabValue !== 0}
                            >
                                <Box 
                                    width={'100%'} 
                                    height={'100%'}
                                    sx={{
                                        // boxShadow: "1px 1px 2px 2px rgba(0,0,0,0.2)",
                                        backgroundColor: "#ffffff",
                                        boxSizing:'border-box',
                                        flex:1,
                                        borderRadius:'8px'
                                        // marginX:'50px'
                                    }}
                                >
                                    <Box height={'100%'} display={'flex'} justifyContent={'space-between'} paddingX={4} alignItems={'center'}>
                                        <Button
                                            variant='outlined'
                                            onClick={handleCancel}
                                        >
                                            <Typography textTransform={'none'}>{ADMIN_CANCEL['eng']}</Typography>
                                        </Button>
                                        <LoadingButton
                                            variant='contained'
                                            loading={isLoading}
                                            sx={{
                                                paddingX:5
                                            }}
                                            onClick={handleAddUser}
                                            disabled={!(state.fullname && state.phoneNumber && state.role?.length)}
                                        >
                                            <Typography>{searchParams.get('isEdit') ? "Submit" : ADMIN_ADD['eng']}</Typography>
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          
        </Box>
        
      )}
      <UserAddedModal
            open={userAddedModalOpen}
            setOpen={setUserAddedModalOpen}
            isSuccess={modalData.isSuccess}
            headerText={modalData.headerText}
            subText={modalData.subText}
            actionButtonText={modalData.actionButtonText}
            actionButtonOnClick={modalData.actionButtonOnClick}
            role={state.role}
            actionButtonOnClickNewClinics={modalData.actionButtonOnClickNewClinics}
            actionButtonOnClickNewUsers={modalData.actionButtonOnClickNewUsers}
        />
    </>
  )
}

const NoUser = ({headerText,subText,buttonText,buttonOnClick,isSearch})=>{

    return (
        <Box height={'100%'} width={'100%'} display={'flex'} gap={2} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
             {!isSearch && (
                <Box
                    width={{ md: 80 }}
                    component="img"
                    alt="no user"
                    src="/images/userAddedModal.svg"
                />
             )}
             
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Typography variant='h6' fontWeight={'bold'}>{headerText}</Typography>
                <Typography variant='caption'>{subText}</Typography>
            </Box>
            {!isSearch && (
                <Button
                    variant='contained'
                    startIcon={<AddOutlinedIcon/>}
                    onClick={buttonOnClick}
                >
                    <Typography variant='caption'>{buttonText}</Typography>
                </Button>
            )}
            
        </Box>
    )
}

const UserCard = ({data,tag,features})=>{

    const tags = features

    const theme = useTheme();

    const [searchParams, setSearchParams] = useSearchParams();

    const { profile } = useSelector((state) => state.auth);

    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const [openDeleteModal,setOpenDeleteModal] = useState(false);

    const [openResetPasswordModal,setOpenResetPasswordModal] = useState(false);

    const [deleteLoader, setDeleteLoader] = useState(false);
    const [loadingResetPassword, setLoadingResetPassword] = useState(false);

    const [newPassword,setNewPassword] = useState('');
    const [confirmNewPassword,setconfirmNewPassword] = useState('');

    const [errorState,setErrorState] = useReducer((state,action)=>{
        return {
            ...state,
            ...action
        }
      },initErrorState)

    const userFeatures = useAuthorization();

    useEffect(()=>{
        setNewPassword('');
        setconfirmNewPassword('');
    },[openResetPasswordModal])

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        navigate({
            pathname: "/admin/usermanagement",
            search: createSearchParams({
                isEdit:true,
                account_id: data.account_id,
                account_name:searchParams.get('account_name'),
                user_id: data.id,
                cognito_user_sub:data.cognito_user_sub
            }).toString()
        });
        navigate(0);
        console.log("data",data);
        return;
    }

    const handleDelete = (e)=>{
        setOpenDeleteModal(true);
        return;
    }
    const handleDeleteUser = async () => {

        setDeleteLoader(true);

        await APIClient.delete(API_ROUTE_DELETE_USER(data?.id,data?.account_id))
        .then(res=>{
            setDeleteLoader(false)
            setOpenDeleteModal(false);
            navigate({
                pathname: "/admin/usermanagement",
                search: createSearchParams({
                    account_id: searchParams.get('account_id'),
                    account_name: searchParams.get("account_name"),
                    show_users:true
                }).toString()
            });
            navigate(0);
        }).catch(err=>{
            setDeleteLoader(false);
            setOpenDeleteModal(false);
            setErrorState({
                errorField:'delete clinic',
                errorMsg:err || 'Unable to delete user',
                showError:true
            })
        })

        return;
    }

    const handleEnable = async () => {
        setDeleteLoader(true);
        await APIClient.put(API_ROUTE_UPDATE_USER(data?.id,data?.account_id),{
            is_active:true
        })
        .then(res=>{
            setDeleteLoader(false)
            setOpenDeleteModal(false);
            navigate({
                pathname: "/admin/usermanagement",
                search: createSearchParams({
                    account_id: searchParams.get('account_id'),
                    account_name: searchParams.get("account_name"),
                    show_users:true
                }).toString()
            });
            navigate(0);
        }).catch(err=>{
            setDeleteLoader(false);
            setOpenDeleteModal(false);
            setErrorState({
                errorField:'enable user',
                errorMsg:err || 'Unable to Enable user',
                showError:true
            })
        })
    }

    const handleOpenEnable = () => {
        setOpenDeleteModal(true);
        return;
    }

    const handleOpenResetPassword = () => {
        handleCloseMenu()
        setOpenResetPasswordModal(true);
    }

    const handleResetPassword = async () => {

        setLoadingResetPassword(true);

        try {
            if(searchParams.get('account_id') && data?.cognito_username && PasswordValidation()){
                await APIClient.put(API_ROUTE_ADMIN_RESET_PASSWORD(),{
                    "account_id":searchParams.get('account_id'),
                    "username":data?.cognito_username,
                    "password":newPassword,
                }).then(res=>{
                    setLoadingResetPassword(false);
                    setOpenResetPasswordModal(false);
                }).catch(err=>{
                    setLoadingResetPassword(false);
                    setOpenResetPasswordModal(false);
                    setErrorState({
                        showError:true,
                        errorMsg:err || "Unable to reset password"
                    })
                })
            }
            else{
                setLoadingResetPassword(false);
            }
            
        } catch (error) {
            setLoadingResetPassword(false);
        }
    }

    const PasswordValidation = ()=>{
        if(newPassword !== confirmNewPassword){
            setErrorState({
                showError:true,
                errorMsg:'New Password different from Confirm Password'
            })
            return false
        }else if(newPassword.length < 8){
            setErrorState({
                showError:true,
                errorMsg:'Password length should be 8 or more characters'
            })
            return false
        }

        return true
    }


    return (
        <Box key={data?.id} display={'flex'} flexDirection={'column'} width={'204px'} borderRadius={'8px'} border={`1px solid ${theme.palette.white.mobileLight}`}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'fit-content'} paddingX={2} height={'24px'} borderRadius={'0px 0px 20px 0px'} backgroundColor={theme.palette.primary.light}>
                    <Typography variant='caption' fontWeight={'bold'} color={theme.palette.primary.main}>{tag || 'User'}</Typography>
                </Box>

                <IconButton onClick={handleClickMenu} sx={{display:userFeatures.includes(getFeatureValue('USER_UPDATION'))?"flex":"none"}}>
                    <MoreVertIcon fontSize='small'/>
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem sx={{display:data?.is_active ? 'block' : 'none'}} onClick={handleEdit}><Typography fontWeight={'bold'}>{ADMIN_EDIT['eng']}</Typography></MenuItem>
                    <MenuItem sx={{display:data?.is_active ? 'block' : 'none'}} onClick={handleOpenResetPassword}><Typography fontWeight={'bold'}>Reset Password</Typography></MenuItem>
                    <MenuItem onClick={data?.is_active ? handleDelete : handleOpenEnable}><Typography fontWeight={'bold'}>{data?.is_active ? 'Disable' : 'Enable'}</Typography></MenuItem>
                </Menu>
            </Box>

            <Box display={'flex'} justifyContent={'center'}>
                <Box
                component="img"
                src={data?.profile_pic || '/images/userimg.svg'}
                sx={{ width: "80px",height:'80px', borderRadius:'50%',backgroundColor:theme.palette.white.medium }}
                />
            </Box>

            <Box display={'flex'} gap={0.5} justifyContent={'center'} alignItems={'center'} mt={1}>
                <Typography variant='subtitle2' fontWeight={'bold'}>{data?.name}</Typography>
                <IconButton sx={{padding:'0'}}>
                    <InfoIcon color='primary' fontSize={'small'}/>
                </IconButton>
            </Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Typography variant='subtitle2' color={theme.palette.white.greyText} fontWeight={'bold'}>{data?.speciality}</Typography>
                <Typography variant='subtitle2' color={theme.palette.primary.main} fontWeight={'bold'}>{data?.phone}</Typography>
                <Typography variant='subtitle2' color={theme.palette.white.greyText} fontWeight={'bold'}>{data?.email}</Typography>
                {!data?.is_active && (
                    <Typography variant='subtitle2' color={theme.palette.white.main} sx={{backgroundColor:theme.palette.red.main,borderRadius:'14px',paddingX:'10px'}} fontWeight={'bold'}>Disabled</Typography>
                )}
            </Box>
            <Divider sx={{my:1}}/>
            <Box width={'100%'} display={'flex'} justifyContent={'center'} flexWrap={'wrap'} gap={1} mt={1} mb={2}>
                {tags.map(elem=>{
                    return (
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} paddingX={1} paddingY={0.5} backgroundColor={theme.palette.primary.lighter} borderRadius={'8px'} overflow={'auto'}>
                            <Typography variant='caption' color={theme.palette.white.greyText}>{elem}</Typography>
                        </Box>
                    )
                })}
            </Box>
            <Modal
                open={openDeleteModal}
                onClose={() => {
                    setOpenDeleteModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={isMobileScreen ? styleMobile : style}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                >
                    <Box
                    maxHeight={300}
                    overflow={"auto"}
                    justifyContent={"center"}
                    alignItems={"start"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={0}
                    my={1}
                    >
                    <Typography variant="h6" fontWeight={600} textAlign={"left"}>
                        {`${data?.is_active ? 'Disable' : 'Enable'} ${data?.name}`}
                    </Typography>
                    <Typography variant="subtitle1" textAlign={"center"}>
                        {`Are you sure you want to ${data?.is_active ? 'disable' : 'enable'} this user?`}
                    </Typography>
                    <Box display={'flex'} width={'100%'} gap={1} sx={{mt:2}} justifyContent={'end'}>
                        <Button variant="outlined" sx={{flex:1}} onClick={()=>{setOpenDeleteModal(false)}}>
                            Cancel
                        </Button>
                        <LoadingButton loading={deleteLoader} variant="contained" sx={{flex:1}} color={data?.is_active ? 'red' : "primary"} onClick={data?.is_active ? handleDeleteUser : handleEnable}>
                            <Typography color={theme.palette.white.main}>{data?.is_active ? "Disable" : "Enable"}</Typography>
                        </LoadingButton>
                        
                    </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openResetPasswordModal}
                onClose={() => {
                    setOpenResetPasswordModal(false);
                }}
                aria-labelledby="modal-modal-title-resetPassword"
                aria-describedby="modal-modal-description-resetPassword-desc"
            >
                <Box
                    sx={isMobileScreen ? styleMobile : style2}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                >

                    <Box display={"flex"} justifyContent={"space-between"} sx={{mb:1}}>
                        <Box display={'flex'} gap={1} alignItems={'center'}>
                            <Typography fontWeight={500} color={theme.palette.primary.main}>Reset Password</Typography>
                        </Box>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    setOpenResetPasswordModal(false);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box display={'flex'} flex={1} gap={2}>
                        <Box display={'flex'} flexDirection={'column'} gap={2} flex={1}>
                            <TextField 
                                label={'New Password'} 
                                value={newPassword} 
                                onChange={(e)=>{setNewPassword(e.target.value)}}
                                inputProps={{
                                    type:'text',
                                    autoComplete: 'new-password',
                                }}
                                sx={{
                                    '& input': {
                                        textSecurity: 'disc',
                                        '-moz-text-security': 'disc',
                                        '-webkit-text-security': 'disc',
                                    },
                                }}
                            />
                            <TextField
                                label={'Confirm New Password'} 
                                value={confirmNewPassword} 
                                onChange={(e)=>{setconfirmNewPassword(e.target.value)}}
                                inputProps={{
                                    type:'text',
                                    autoComplete: 'new-password',
                                }}
                                sx={{
                                    '& input': {
                                        textSecurity: 'disc',
                                        '-moz-text-security': 'disc',
                                        '-webkit-text-security': 'disc',
                                    },
                                }}
                            />
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} flex={1}>
                            <Typography>Password Policy</Typography>
                            <ul style={{marginTop:1}}>
                                <li>
                                    <Typography>At least 1 Uppercase</Typography>
                                </li>
                                <li>
                                    <Typography>At least 1 Lowercase</Typography>
                                </li>
                                <li>
                                    <Typography>At least 1 Number</Typography>
                                </li>
                                <li>
                                    <Typography>At least 1 Special Character</Typography>
                                </li>
                                <li>
                                    <Typography>At least 8 Characters</Typography>
                                </li>
                            </ul>
                        </Box>
                    </Box>

                    <Box display={'flex'} gap={2}>
                        <Button onClick={() => {setOpenResetPasswordModal(false)}} variant='outlined' sx={{flex:1}}>Cancel</Button>
                        <LoadingButton disabled={!newPassword || !confirmNewPassword} loading={loadingResetPassword} variant='contained' onClick={handleResetPassword} sx={{flex:1}}>Update</LoadingButton>
                    </Box>

                </Box>

            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                ContentProps={{ sx: { background: "red", marginTop: "40px" } }}
                open={errorState.showError}
                onClose={() => setErrorState({showError:false})}
                autoHideDuration={6000}
                message={errorState.errorMsg}
            />
        </Box>
    )
}

export default UserManagment