import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Tooltip,
    createFilterOptions,
    Typography,
    tableCellClasses,
    useMediaQuery,
    useTheme,
    TableHead,
    FormControl,
    RadioGroup,
    Radio,
    Stack,
  } from "@mui/material";
  import {
    HTMLAttributes,
    SyntheticEvent,
    useEffect,
    useRef,
    useState,
  } from "react";
  import { getDurationOptions } from "../../../../../shared/Utils/utilities";
  import {
    fetchSuggestions,
    seperateUrlAndParams,
  } from "../../../../../shared/Utils/utils";
  import { useDebounce } from "../../../../../shared/Utils/useDebounce";
  import { styled } from "@mui/system";
  import {
    BASE_API_URL,
    KNEE_EXAMINATION,
    MEDICAL_HISTORY,
    MEDICAL_HISTORY_DIABETES,
    PATIENT_HISTORY,
  } from "../../../../../shared/constants";
  import DoctorSuggestions from "../../utils/DoctorSuggestions";
  import { useDispatch, useSelector } from "react-redux";
  import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
  import Card from "../../utils/Card";
import { setInstantRxData } from "../../../../../redux/reducers/prescription.slice";
import CardHeader from "../../utils/CardHeader";
import TemplateCard from "../../utils/TemplateCard";
import { CARD_SHADOW } from "../../utils/Constants";
import { withStyles } from "@mui/styles";
import MuiTableHead from "@material-ui/core/TableHead";
  
  interface KneeExaminationProps {
    id:string,
    name:string,
    leftSide: string,
    rightSide: string
  }
  
  const StyledTableCell = styled(TableCell)({
    [`&.${tableCellClasses.body}`]: {
      padding: '8px !important',
      border: "none",
    },
  });
  
  const CustomDropDownPaper = styled(Paper)({
    minWidth: 900,
  });

  
  
  
  export default function KneeExamination(props: any) {
    const [options, setOptions] = useState<string[]>([]);
    const [optionsData, setOptionsData] = useState<any[]>([]);
    const [historyDurationOptions, setHistoryDurationOptions] = useState<
      string[]
    >([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<KneeExaminationProps[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [valUpdateSearch, setValUpdateSearch] = useState("");
    const [dialog, setDialog] = useState<any>({
      open: false,
      content: {
        leftSide:'',
        rightSide:'',
      } as KneeExaminationProps,
    });

    const StyledTableHead = withStyles(theme => ({
        root: {
          borderBottom:0,
          border:0,
          p:0
        }
      }))(MuiTableHead);

    const dispatch = useDispatch();
  
    const debouncedSearch = useDebounce(searchValue);
    const altDebouncedSearch = useDebounce(valUpdateSearch);
  
    const sectionData = props?.sectionData && props?.sectionData?.[0];
    const setTabData = props?.setTabData;
  
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  
    const {
      prescription: { instantRxData },
    } = useSelector((state: any) => state.prescription);
  
    const autoref = useRef<HTMLDivElement>(null);
  
    const apiurldata = sectionData?.rows[0]?.search?.api;

    const isFocused: boolean = props.isFocused || false;

    const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>();
    const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
      useState<boolean>();
    const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
    const searchExistingTemplateMobile =
      props?.sectionData?.[0]?.searchExistingTemplateMobile;

    const MiniTemplateStyle = {
      position: !isMobileScreen ? "absolute" : "none",
      top: "68px",
      right: "79px",
      zIndex: 1,
      height: "0px",
    };
  
    const AddMiniTemplateStyle = {
      position: !isMobileScreen ? "absolute" : "none",
      top: "68px",
      right: "39px",
      zIndex: 1,
      height: "0px",
    };

    const editSection = props?.editSection || false;
  
    useEffect(() => {
      if (sectionData) {
        setData(sectionData.rows[0].value);
        setLoading(false);
      }
    }, [sectionData]);

    useEffect(()=>{
        console.log("data",data)
    },[data])
  

    useEffect(() => {
      if (searchValue.length === 0) {
        setOptions([]);
      }
    }, [searchValue]);
  
    useEffect(() => {
      if (searchValue.length !== 0 && searchValue.trim().length >= 3) {
        updateSearchOptions(debouncedSearch);
      }
    }, [debouncedSearch]);
  
    useEffect(() => {
      if (valUpdateSearch.length !== 0) {
        updateSearchOptions(altDebouncedSearch);
      }
    }, [altDebouncedSearch]);

  
    const handleDialogState = () => {
      setDialog({
        ...dialog,
        open: !dialog.open,
      });
    };
  
    const dialogProps = {
      DialogOpen: dialog.open ? dialog.open : false,
      onHandleDialog: handleDialogState,
      headerStyles: { display: "none" },
      PaperPropsStyle: {
        margin: 0,
        width: "100%",
      },
      Layoutstyles: {
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
        "& .MuiDialogContent-root": {
          padding: "0px",
        },
      },
      footerStyles: {
        display: "none",
      },
      onHandleSave: () => {
        // not required, custom save option is there
        return;
      },
    };
  
    const assignAddHistoryMobile = (value: string) => {
      const optionObj = optionsData.find((optObj: any) => optObj.name === value);
  
      const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
      const name = optionObj?.name ? optionObj.name : value;
      if (!value) return;
      setDialog({
        ...dialog,
        open: true,
        mode: "ADD",
        content: {
          id,
          name,
          leftSide:'',
          rightSide:'',
        },
      });
    };
  
    const doctorSuggestionProps = {
      sKey: "complaints",
      sHeading: "Suggested conditions",
      headerImg: isMobileScreen
        ? "/images/icon_mobile_resp_suggestion.svg"
        : "/images/icon_dummy.svg",
      handleAdd: (event: any) => {
        const currentTarget = event?.currentTarget;
        const suggestion = currentTarget?.attributes["data-key"]?.nodeValue;
        const clickAction = currentTarget?.attributes["data-action"]?.nodeValue;
  
        if (!data?.some((history: any) => history.name === suggestion)) {
          switch (clickAction) {
            case "ADD":
              if (isMobileScreen) {
                assignAddHistoryMobile(suggestion);
              } else {
                addComplaint(null as any, suggestion);
              }
              break;
            case "UPDATE":
                updateComplaint(event as any, suggestion);
              break;
            default:
              return;
          }
        }
        if (autoref.current) {
          autoref.current.blur();
        }
      },
    };
  
    const addComplaint = async (
        event: SyntheticEvent<Element, Event>,
        value: string | null
        ) => {
        const optionObj = optionsData.find((optObj: any) => optObj.name === value);

        const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
        const name = optionObj?.name ? optionObj.name : value;
        if (!value) {
            return;
        }

        const newComplaint: KneeExaminationProps = {
            id,
            name,
            leftSide: "",
            rightSide: ""
        };
        setTabData((prev: any) => {
            return prev.map((section: any) => {
            if (section.view_type === KNEE_EXAMINATION) {
                return {
                ...section,
                rows: section.rows.map((row: any, index: number) => {
                    if (index === 0) {
                    return {
                        ...row,
                        value: [...row.value, newComplaint],
                    };
                    }
                    return row;
                }),
                };
            }
            return section;
            });
        });
        setOptions([]);
        setSearchValue("");
    };

    const updateComplaint = (event: SyntheticEvent, value: string | null) => {
    let newValue: any, newId: any;

    const target =
        (event.target as HTMLInputElement) ||
        (event.target as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName)?.split("-");
    
    const key = nameArr[nameArr.length - 1];

    

    const currId = nameArr.reduce((acc, val) => {
        if (val === key) {
            return acc;
        }
        return acc + "-" + val;
    });

    console.log("currId",currId);

    if (key === "name") {
        const optionObj = optionsData.find(
            (optObj: any) => optObj.name === value
        );
        const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
        const name = optionObj?.name ? optionObj.name : value;
        newValue = name;
        newId = id;
    } else {
        (newId = currId), (newValue = value);
    }
    setTabData((prev: any) => {
        
        return prev.map((section: any) => {
            if (section.view_type === KNEE_EXAMINATION) {
                return {
                    ...section,
                    rows: section.rows.map((row: any, index: number) => {
                        if (index === 0) {
                            return {
                                ...row,
                                value: row.value.map((complaint: any) => {
                                if (complaint?.id?.toString() === currId) {
                                    return {
                                    ...complaint,
                                    id: newId,
                                    [key]: newValue,
                                    };
                                }
                                return complaint;
                                }),
                            };
                        }
                        return row;
                    }),
                };
            }
            return section;
        });
    });
        setOptions([]);
    };

    const updateRadioButtons = (id:any,key:any,value:any)=>{
        setTabData((prev: any) => {
        
            return prev.map((section: any) => {
                if (section.view_type === KNEE_EXAMINATION) {
                    return {
                        ...section,
                        rows: section.rows.map((row: any, index: number) => {
                            if (index === 0) {
                                return {
                                    ...row,
                                    value: row.value.map((complaint: any) => {
                                    if (complaint?.id?.toString() === id) {
                                        return {
                                        ...complaint,
                                        id: id,
                                        [key]: value,
                                        };
                                    }
                                    return complaint;
                                    }),
                                };
                            }
                            return row;
                        }),
                    };
                }
                return section;
            });
        });
    }

    const removeComplaint = (event: SyntheticEvent<Element, Event>) => {
        const elem = event.target as HTMLElement;
        const { id } = elem;
        setTabData((prev: any) => {
            return prev.map((section: any) => {
            if (section.view_type === KNEE_EXAMINATION) {
                return {
                ...section,
                rows: section.rows.map((row: any, index: number) => {
                    if (index === 0) {
                    return {
                        ...row,
                        value: row.value.filter(
                        (complaint: any) => complaint?.id?.toString() !== id
                        ),
                    };
                    }
                    return row;
                }),
                };
            }
            return section;
            });
        });
    };

    const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
        setTabData((prev: any) => {
        return prev.map((section: any) => {
            if (section.view_type === KNEE_EXAMINATION) {
            return {
                ...section,
                rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                    return { ...row, value: item?.rows[0]?.value };
                } else {
                    return row;
                }
                }),
            };
            } else {
            return section;
            }
        });
        });
    }
    dispatch(setInstantRxData([] as any));
    };

    const updateSearchOptions = (value: any) => {
    const trimmedValue = value.trim();
    if (apiurldata && trimmedValue) {
        const { url, query_params } = seperateUrlAndParams(apiurldata);
        fetchSuggestions(
        url,
        {
            ...query_params,
            name: value,
        },
        true // use custom base url
        ).then((response) => {
        const responseArr = response?.data?.data;
        const optionsArr = responseArr?.map((option: any) => option.name);
        if (!optionsArr.some((opt: any) => opt === trimmedValue)) {
            setOptions([trimmedValue, ...optionsArr]);
            setOptionsData([
            {
                id: `custom${Date.now()}`,
                name: trimmedValue,
            },
            ...responseArr,
            ]);
            return;
        }
        setOptions(optionsArr);
        setOptionsData(responseArr);
        });
    }
    };
  
    const HistoryCardMobile = (History: KneeExaminationProps, index: number) => {
      const { id, name } = History;
      return (
        <Card
          styles={{
            boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.08)`,
            padding: 0,
            borderRadius: "8px",
          }}
          key={index + id}
        >
          <Box id={id}>
            {/* Card Header */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "12px",
              }}
            >
              <Typography
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                color="#494E9D"
              >
                {name}
              </Typography>
              <IconButton>
                <Box
                  id={id}
                  aria-label="delete-medical-history"
                  onClick={removeComplaint}
                  component="img"
                  src="/images/DeleteIcon.svg"
                ></Box>
              </IconButton>
            </Box>
            <Divider />
            {/* Card Contents */}
            <Box padding="12px">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginRight: "25%",
                  }}
                >
                  <Box
                    component="img"
                    marginRight="4px"
                    src="/images/rollingIcon.svg"
                  ></Box>
                  <Box>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="100%"
                      marginBottom="8px"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      Status
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="100%"
                      color="#6F6F6F"
                    >
                      {status}
                    </Typography>
                  </Box>
                </Box>
                
              </Box>
            </Box>
          </Box>
        </Card>
      );
    };

    const handleButtonClick = async (imageName: string) => {
      if (imageName === "icon_save.svg") {
        if (isMobileScreen) {
          // handleButtonClickMiniTMobile(imageName); // review-code
          setTabData((prev: any) => {
            return prev.map((section: any) => {
              if (section.view_type === KNEE_EXAMINATION) {
                return {
                  ...section,
                  openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                  searchExistingTemplateMobile: false,
                };
              } else {
                return section;
              }
            });
          });
          return;
        }
        setOpenSaveTemplate(!openSaveTemplate);
        setOpenUpdateExistingTemplate(false);
      } else if (imageName === "icon_add_page.svg") {
        if (isMobileScreen) {
          setTabData((prev: any) => {
            return prev.map((section: any) => {
              if (section.view_type === KNEE_EXAMINATION) {
                return {
                  ...section,
                  openSaveTemplateMobile: false,
                  searchExistingTemplateMobile:
                    !section.searchExistingTemplateMobile,
                };
              } else {
                return section;
              }
            });
          });
          return;
        }
        setOpenUpdateExistingTemplate(!serachExistingTemplate);
        setOpenSaveTemplate(false);
      }
    };

    const assignAddComplaintMobile = (value: string) => {
      const optionObj = optionsData.find((optObj: any) => optObj.name === value);
  
      const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
      const name = optionObj?.name ? optionObj.name : value;
      if (!value) return;
      setDialog({
        ...dialog,
        open: true,
        mode: "ADD",
        content: {
          id,
          name,
          leftSide:'',
          rightSide:'',
        },
      });
    };

    const ComplaintCardMobile = (Referral: any, index: number) => {
      const { id, name, since, severity, leftSide, rightSide } = Referral;
      return (
        <Card
          styles={{
            borderRadius: "8px",
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
            padding: "0px",
          }}
          key={index}
        >
          <Box
            className="card-header"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "12px",
            }}
          >
            <Typography
              fontSize="16px"
              fontWeight="700"
              lineHeight="16px"
              color="#494E9D"
            >
              {name}
            </Typography>
            <IconButton>
              <Box
                id={id}
                aria-label="delete-complaint"
                onClick={removeComplaint}
                component="img"
                src="/images/DeleteIcon.svg"
              ></Box>
            </IconButton>
          </Box>
          <Divider />
          <Stack sx={{ padding: "12px" }} spacing={2}>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Box
                component="img"
                marginRight="4px"
                src="/images/ClockIcon.svg"
              ></Box>
              <Box>
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.87)"
                >
                  Pain
                </Typography>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="160%"
                  color="#6F6F6F"
                >
                  {`${leftSide ? "Left side " + leftSide : ''} |  ${rightSide ? "Right side " + rightSide : ''}`}
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Card>
      );
    };
  
    const RenderReadOnly = () => {
      return (
        <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
          {data.map((datum, index) => {
            return (
              <Box
                component="li"
                sx={{
                  color: "#6F6F6F",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "100%",
                  marginBottom: "12px",
                  fontFamily: "Roboto, sans-serif",
                }}
                key={index}
              >
                {datum.name}
              </Box>
            );
          })}
        </Box>
      );
    };
  
    return (
        <>
          {loading && (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          )}
          {!loading && !isMobileScreen && (
            <Card
              styles={{
                boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
                position: "relative",
              }}
              title="Knee Examination"
              isReadOnly={props?.isReadOnly}
            >
              <CardHeader
                headerImg="icon_knee_examination.svg"
                headerTitle="Knee Examination"
              >
                {/* {renderHeaderButtons(
                  ["icon_save.svg", "icon_add_page.svg"],
                  handleButtonClick
                )} */}
                <></>
              </CardHeader>
              {data?.length !== 0 && (
                <TableContainer sx={{ mb: 2, boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="all-compaints">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" sx={{p:'8px'}}>
                                <Typography fontWeight={'bold'}>ROM</Typography>
                            </StyledTableCell>

                            <StyledTableCell align="left" sx={{p:'8px'}}>
                                <Typography fontWeight={'bold'}>Left Side</Typography>
                            </StyledTableCell>

                            <StyledTableCell align="left" sx={{p:'8px'}}>
                                <Typography fontWeight={'bold'}>Right Side</Typography>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((complaint, index) => {
                        const { id, name, leftSide, rightSide } = complaint;
                        return (
                          <TableRow key={`complaint-${index}-${name}`}>
                            <StyledTableCell align="left">
                              <Typography>{name}</Typography>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                                <FormControl>
                                        <RadioGroup 
                                            row 
                                            sx={{gap:2}}
                                            value={leftSide || ''}
                                        >
                                            {sectionData?.rows[0]?.master?.state?.values?.map((elem:any)=>{
                                                return (
                                                    <FormControlLabel
                                                        id={`${id}-leftSide`}
                                                        name={`${id}-leftSide`}
                                                        value={elem}
                                                        control={<Radio
                                                        
                                                            inputProps={
                                                                {
                                                                onBlur:(event: any) => {
                                                                    updateComplaint(event as any,elem);
                                                                    // updateRadioButtons(id,'leftSide',elem)
                                                                },
                                                                name:`${id}-leftSide`,
                                                                id:`${id}-leftSide`,
                                                                value:leftSide,
                                                                onClick:(e:any)=>{
                                                                    const checked = e.target.checked;
                                                                    if(checked){
                                                                        // setRadio(elem);
                                                                        setData((prev)=>{
                                                                            return (
                                                                                prev.map(el=>{
                                                                                    if(el.id==id){
                                                                                        return {
                                                                                            ...el,
                                                                                            leftSide:elem
                                                                                        }
                                                                                    }
                                                                                    return el;
                                                                                })
                                                                            )
                                                                        })
                                                                    }
                                                                }
                                                                }
                                                            }
                                                            
                                                            onBlur={(event: any) => {
                                                                updateComplaint(event as any,elem);
                                                                // updateRadioButtons(id,'leftSide',elem)
                                                            }}
                                                        />} 
                                                        label={elem} 
                                                    />
                                                )
                                            })}
                                        </RadioGroup>
                                </FormControl>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                                <FormControl>
                                    <RadioGroup 
                                        row 
                                        sx={{gap:2}}
                                        value={rightSide || ''}
                                    >
                                        {sectionData?.rows[0]?.master?.state?.values?.map((elem:any)=>{
                                            return (
                                                <FormControlLabel
                                                    id={`${id}-rightSide`}
                                                    name={`${id}-rightSide`}
                                                    value={elem}
                                                    control={<Radio
                                                    
                                                        inputProps={
                                                            {
                                                            onBlur:(event: any) => {
                                                                updateComplaint(event as any,elem);
                                                                // updateRadioButtons(id,'rightSide',elem)
                                                            },
                                                            name:`${id}-rightSide`,
                                                            id:`${id}-rightSide`,
                                                            value:leftSide,
                                                            onClick:(e:any)=>{
                                                                const checked = e.target.checked;
                                                                if(checked){
                                                                    // setRadio(elem);
                                                                    setData((prev)=>{
                                                                        return (
                                                                            prev.map(el=>{
                                                                                if(el.id==id){
                                                                                    return {
                                                                                        ...el,
                                                                                        rightSide:elem
                                                                                    }
                                                                                }
                                                                                return el;
                                                                            })
                                                                        )
                                                                    })
                                                                }
                                                            }
                                                            }
                                                        }
                                                        
                                                        onBlur={(event: any) => {
                                                            updateComplaint(event as any,elem);
                                                            // updateRadioButtons(id,'rightSide',elem)
                                                        }}
                                                    />} 
                                                    label={elem} 
                                                />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </StyledTableCell>

                            <StyledTableCell
                              sx={{ width: "32px", padding: "0 !important" }}
                              align="right"
                            >
                              <Tooltip title={"Delete"}>
                                <button
                                  id={id}
                                  onClick={removeComplaint}
                                  style={{
                                    outline: "none",
                                    border: "none",
                                    background: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    style={{ pointerEvents: "none" }}
                                    src="/images/minus-in-circle.svg"
                                    alt="remove-button"
                                  />
                                </button>
                              </Tooltip>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <Autocomplete
                id="search-for-symptoms"
                options={searchValue.length === 0 ? [] : options}
                disableClearable
                // loading={true}
                value={""}
                clearOnBlur
                clearOnEscape
                popupIcon={false}
                clearIcon={false}
                inputValue={searchValue}
                onChange={addComplaint}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) => true);
                }}
                noOptionsText={
                  <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputRef={autoref}
                      value={null}
                      onChange={(event: any) => {
                        if (event.target.value === "") {
                          setOptions([]);
                        }
                        setSearchValue(event.target.value);
                      }}
                      InputProps={{
                        value: null,
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src="/images/icon_search.svg" alt="search_icon" />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search for conditions"
                      fullWidth
                      variant="outlined"
                    />
                  );
                }}
                renderOption={(
                  props: HTMLAttributes<HTMLLIElement>,
                  option: string,
                  state
                ) => {
                  if (!data.some((complaint: any) => complaint.name === option)) {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {!optionsData
                          .find((opt: any) => opt.name === option)
                          ?.id?.toString()
                          .includes("custom")
                          ? option
                          : `Add "${option}"`}
                      </Box>
                    );
                  }
                }}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                    ],
                  },
                }}
              />
              {openSaveTemplate && (
                <Box sx={MiniTemplateStyle}>
                  <TemplateCard
                    templateName="save-template"
                    handleCloseButton={handleButtonClick}
                    data={props.sectionData[0]}
                  />
                </Box>
              )}
              {serachExistingTemplate && (
                <Box position="absolute" top="68px" right="39px" zIndex="1">
                  <TemplateCard
                    handleCloseButton={handleButtonClick}
                    templateName="search-template"
                    data={props.sectionData[0]}
                    handleSelectedTemplateData={handleSelectedTemplateData}
                  />
                </Box>
              )}
            </Card>
          )}
          {!loading && isMobileScreen && editSection && (
            <>
              
              <Autocomplete
                id="search-for-symptoms"
                options={searchValue.length === 0 ? [] : options}
                disableClearable
                // loading={true}
                value={""}
                clearOnBlur
                clearOnEscape
                popupIcon={false}
                clearIcon={false}
                inputValue={searchValue}
                onChange={(_event, value) => {
                  assignAddComplaintMobile(value);
                }}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) => true);
                }}
                noOptionsText={
                  <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputRef={autoref}
                      value={null}
                      onChange={(event: any) => {
                        if (event.target.value === "") {
                          setOptions([]);
                        }
                        setSearchValue(event.target.value);
                      }}
                      InputProps={{
                        value: null,
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src="/images/icon_search.svg" alt="search_icon" />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search for conditions"
                      fullWidth
                      variant="outlined"
                    />
                  );
                }}
                renderOption={(
                  props: HTMLAttributes<HTMLLIElement>,
                  option: string,
                  state
                ) => {
                  if (!data.some((complaint: any) => complaint.name === option)) {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {!optionsData
                          .find((opt: any) => opt.name === option)
                          ?.id?.toString()
                          .includes("custom")
                          ? option
                          : `Add "${option}"`}
                      </Box>
                    );
                  }
                }}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                    ],
                  },
                }}
              />
              {openSaveTemplateMobile && (
                <Box sx={MiniTemplateStyle}>
                  <TemplateCard
                    templateName="save-template"
                    handleCloseButton={handleButtonClick}
                    data={props.sectionData[0]}
                  />
                </Box>
              )}
              {searchExistingTemplateMobile && (
                <Box sx={AddMiniTemplateStyle}>
                  <TemplateCard
                    handleCloseButton={handleButtonClick}
                    templateName="search-template"
                    data={props.sectionData[0]}
                    handleSelectedTemplateData={handleSelectedTemplateData}
                  />
                </Box>
              )}
              <Box marginTop="16px">
                {data.length !== 0 &&
                  data.map((complaint, index) => {
                    return (
                      <Box
                        component="div"
                        id={complaint.id}
                        onClick={(_event: any) => {
                          if (_event.target.ariaLabel === "delete-complaint") {
                            return;
                          }
                          setDialog({
                            ...dialog,
                            open: true,
                            mode: "UPDATE",
                            content: complaint,
                          });
                        }}
                        key={index}
                      >
                        {ComplaintCardMobile(complaint, index)}
                      </Box>
                    );
                  })}
              </Box>
            </>
          )}
          {!loading && isMobileScreen && !editSection && RenderReadOnly()}
          {isMobileScreen && (
            <CustomizedDialogs {...(dialogProps as any)}>
              <Box margin="12px">
                <Box className="header" display="flex">
                  <Box
                    component="img"
                    sx={{ marginRight: 1.5 }}
                    src="/images/icon_knee_examination.svg"
                  />
                  <Box className="text-content" width="80%">
                    <Typography
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="100%"
                      marginBottom="8px"
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      Knee Examination
                    </Typography>
                    <Typography
                      fontSize="20px"
                      fontWeight="700"
                      lineHeight="100%"
                      color="#494E9D"
                    >
                      {dialog.content.name}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ margin: "16px 0" }} />
                <Box className="content" marginBottom="24px">
                  <Box display="flex" flexDirection={"column"} marginBottom="16px" width="100%" gap="8px">
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography>Left Side</Typography>
                      <FormControl>
                              <RadioGroup 
                                  row 
                                  sx={{gap:2}}
                                  value={dialog.content.leftSide || ''}
                              >
                                  {sectionData?.rows[0]?.master?.state?.values?.map((elem:any)=>{
                                      return (
                                          <FormControlLabel
                                              id={`${dialog.content.id}-leftSide`}
                                              name={`${dialog.content.id}-leftSide`}
                                              value={elem}
                                              control={<Radio
                                              
                                                  inputProps={
                                                      {
                                                      
                                                      name:`${dialog.content.id}-leftSide`,
                                                      id:`${dialog.content.id}-leftSide`,
                                                      value:dialog.content.leftSide,
                                                      onClick:(e:any)=>{
                                                          const checked = e.target.checked;
                                                          if(checked){
                                                              setDialog({
                                                                ...dialog,
                                                                content: {
                                                                  ...dialog.content,
                                                                  leftSide: elem
                                                                },
                                                              });
                                                          }
                                                      }
                                                      }
                                                  }
                                                  
                                                  
                                              />} 
                                              label={elem} 
                                          />
                                      )
                                  })}
                              </RadioGroup>
                      </FormControl>
                    </Box>
                    
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography>Right Side</Typography>
                      <FormControl>
                          <RadioGroup 
                              row 
                              sx={{gap:2}}
                              value={dialog.content.rightSide || ''}
                          >
                              {sectionData?.rows[0]?.master?.state?.values?.map((elem:any)=>{
                                  return (
                                      <FormControlLabel
                                          id={`${dialog.content.id}-rightSide`}
                                          name={`${dialog.content.id}-rightSide`}
                                          value={elem}
                                          control={<Radio
                                          
                                              inputProps={
                                                  {
                                                  name:`${dialog.content.id}-rightSide`,
                                                  id:`${dialog.content.id}-rightSide`,
                                                  value:dialog.content.leftSide,
                                                  onClick:(e:any)=>{
                                                      const checked = e.target.checked;
                                                      if(checked){
                                                        setDialog({
                                                          ...dialog,
                                                          content: {
                                                            ...dialog.content,
                                                            rightSide: elem
                                                          },
                                                        });
                                                      }
                                                  }
                                                  }
                                              }
                                              
                                              
                                          />} 
                                          label={elem} 
                                      />
                                  )
                              })}
                          </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                  
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none !important",
                      borderRadius: "6px",
                    }}
                    fullWidth
                    onClick={() => {
                      if (dialog.mode === "ADD" && dialog.content.id) {
                        setTabData((prev: any) => {
                          return prev.map((section: any) => {
                            if (section.view_type === KNEE_EXAMINATION) {
                              return {
                                ...section,
                                rows: section.rows.map(
                                  (row: any, index: number) => {
                                    if (index === 0) {
                                      return {
                                        ...row,
                                        value: [...row.value, dialog.content],
                                      };
                                    }
                                    return row;
                                  }
                                ),
                              };
                            }
                            return section;
                          });
                        });
                        setDialog({
                          ...dialog,
                          open: false,
                          content: {},
                        });
                        setOptions([]);
                        setSearchValue("");
                      }
                      if (dialog.mode === "UPDATE" && dialog.content.id) {
                        setTabData((prev: any) => {
                          return prev.map((section: any) => {
                            if (section.view_type === KNEE_EXAMINATION) {
                              return {
                                ...section,
                                rows: section.rows.map(
                                  (row: any, index: number) => {
                                    if (index === 0) {
                                      return {
                                        ...row,
                                        value: row.value.map((val: any) => {
                                          if (val.id === dialog.content.id) {
                                            return {
                                              ...dialog.content,
                                            };
                                          }
                                          return val;
                                        }),
                                      };
                                    }
                                    return row;
                                  }
                                ),
                              };
                            }
                            return section;
                          });
                        });
                        setDialog({
                          ...dialog,
                          open: false,
                          content: {},
                        });
                        setOptions([]);
                      }
                    }}
                  >
                    {dialog.mode === "ADD" ? `Add` : `Update`} Knee Examination
                  </Button>
                </Box>
              </Box>
            </CustomizedDialogs>
          )}
        </>
      );
  }
  