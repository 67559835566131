// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'ap-south-1:d67a44b0-f143-4b62-8ead-7082502ed4c9',
    'aws_cognito_region': 'ap-south-1',
    'aws_mandatory_sign_in': 'disable',
    // 'aws_mobile_analytics_app_id': '5ac33a4afc7b4c5080bdd174139afd69',
    // 'aws_mobile_analytics_app_region': 'us-east-1',
    //'aws_project_id': '79c948db-d1ec-4284-9373-589f57221b78',
    //'aws_project_name': 'thb-emr-live',
    'aws_project_region': 'ap-south-1',
    'aws_resource_name_prefix': 'emr_pool',
    'aws_sign_in_enabled': 'disable',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'emr-userfiles',
    'aws_user_files_s3_bucket_region': 'ap-south-1',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'ap-south-1_UOP26uPYa',
    'aws_user_pools_mfa_type': 'OPTIONAL',
    'aws_user_pools_web_client_id': '6q4haiq0eqbod6ebs9ea53fvab'
}

export default awsmobile;

// 'aws_mandatory_sign_in': 'enable',
// 'aws_user_files_s3_bucket': 'thbemrhub-userfiles-mobilehub-1809258666',
//     'aws_user_files_s3_bucket_region': 'ap-south-1',
// 'aws_user_files': 'enable',