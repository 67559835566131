import {
    Box,
    // Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
  } from "@mui/material";
  import moment from "moment";
  import { useTheme } from "@mui/material/styles";
  import { useEffect, useRef, useState } from "react";
  import {
    fetchDisplayUrlData,
    sendPrescription,
  } from "../../../../shared/Utils/utils";
  import DOMPurify from "dompurify";
  import "../../../../shared/layouts/style.css";
  
  import {
    ADVICE,
    ADVICE_NOTES,
    COMPLAINTS,
    DIABETES_ASSESSMENT,
    DIAGNOSIS,
    EXAMINATION,
    FOLLOW_UP,
    FOOT_EXAM,
    GENERAL_PHYSICAL_EXAMINATION,
    INVESTIGATIONS,
    INVESTIGATION_MONITORING,
    MEDICAL_HISTORY,
    MEDICATION,
    PATIENT_HISTORY,
    REFERRAL,
    SYSTEMIC_EXAMINATION,
  } from "../../../../shared/constants";
  import TabletIcon from "../../../../shared/icons/TabletIcon";
  import dayjs from "dayjs";
  import { useSearchParams } from "react-router-dom";
import { APIClient } from "../../../../shared/Utils/api-client";
import axios from "axios";
  
  const OpenPrescription = (props: any) => {
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [searchParams, setSearchParams] = useSearchParams();
    
    function getQueryParams() {
      const params = new URLSearchParams(window.location.search);
      return {
        showVideo: params.get("showVideo"),
      };
    }
    const prescriptionTableHeader = [
      {
        headerName: "Medicine Name",
      },
      {
        headerName: "Qty/Dose",
      },
      {
        headerName: "Frequency",
      },
      {
        headerName: "Duration",
      },
      {
        headerName: "Meal Pref",
      },
      {
        headerName: "Start From",
      },
      {
        headerName: "Special Instruction",
      },
    ];
    const LabTestTableHeader = [
      {
        headerName: "Lab Test",
      },
      {
        headerName: "Test on",
      },
      {
        headerName: "Repeat on",
      },
      {
        headerName: "Remarks",
      },
    ];
    
    const [prescriptionDetails, setPrescriptionDetails] = useState([]);
    
    const TableStyle = {
      cell: {
        borderLeft: "1px solid #D9D9D9",
        fontSize: isMobileScreen ? "10px" : "14px",
        padding: isMobileScreen ? "0px" : "16px",
        textAlign: "center",
      },
      medicalLabCell: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        borderBottom: "0px",
        padding: isMobileScreen ? "0px" : "16px",
        fontSize: isMobileScreen ? "10px" : "14px",
      },
      medicineCell: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        borderBottom: "0px",
      },
    };
    const prescriptionStyle = {
      header: {
        fontSize: isMobileScreen ? "9px" : "12px",
        fontWeight: "400",
        lineHeight: "18px",
        color: "#0A0666",
      },
      patientDetailsHeader: {
        fontSize: isMobileScreen ? "8px" : "12px",
        fontWeight: "400",
        lineHeight: "16.41px",
      },
      patientFindingsHeader: {
        fontSize: isMobileScreen ? "12px" : "16px",
        fontWeight: "600",
        lineHeight: "24px",
        color: "#0A0666",
      },
      patientFindingsValue: {
        fontSize: isMobileScreen ? "12px" : "16px",
        fontWeight: "400",
        lineHeight: "24px",
      },
      prescriptionContentStyle: {
        display: "flex",
        gap: "10px",
      },
      prescriptionIconStyle: {
        display: "inline-flex",
        justifyContent: "flex-start",
        height: isMobileScreen ? "27px" : "100%",
      },
    };
  
  
    const tabData = props?.TabData;
    
    useEffect(() => {
    //   const enabledViewTabData = tabData?.filter(
    //     (section: any) => !section?.disableView
    //   );
    //   setPrescriptionDetails(enabledViewTabData);
    //   console.log("enabledViewTabData",enabledViewTabData)
    //   setDateAndTime(moment(new Date()).format("DD/MM/YYYY, hh:mm A"));
    }, [tabData]);
  
    const getMoleculeName = (name: string) => {
      const cleanName = name.replace(/[^\w\s]/g, "");
      const words = cleanName.split(/\s+/);
      return words;
    };
  
    useEffect(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
      }
      FetchPrescriptionDisplayURL()
    }, []);
  


    const FetchPrescriptionDisplayURL = async ()=>{
        await APIClient.get('prescription/get-signed-url',{
            params: {
                type:'prescription',
                appointment_id:searchParams.get('appointment_id'),
                doctor_id:searchParams.get('doctor_id'),
                patient_id:searchParams.get('patient_id'),
                template_id:searchParams.get('template_id'),
                thb_appt_id:searchParams.get('thb_appt_id'),
                doctor_name:searchParams.get('doctor_name'),
                multi:'true',

            },
        }).then((res:any)=>{
            const display_url = res?.data?.data?.display_url
            if(display_url){
                FetchDisplayURL(display_url)
            }
        }).catch((err:any)=>{
            console.log("err",err)
        })
    }

    const FetchDisplayURL = async (display_url:any)=>{
        await axios.get(display_url).then((res:any)=>{
            const data = res?.data;
            if(data?.sections?.length){
                setPrescriptionDetails(data.sections);
            }
        }).catch((err:any)=>{
            console.log('err dis url',err)
        })
    }
    
  
    
    return (
      <Box sx={{width:'100vw',height:'100vh',overflow:'scroll' }}>
        <Box
          ref={ref}
          id="prescription-1"
          sx={{ 
            background: "#fff", 
            // border: "1px solid #D9D9D9"
          }}
        >
          <Box
            className="patient-finding"
            margin={isMobileScreen ? "10px" : "32px"}
          >
            {prescriptionDetails &&
              prescriptionDetails
                .filter((data: any) => data?.view_type === COMPLAINTS)
                .map((data: any) => {
                  const complaintData = data?.rows[0].value
                    .map((item: any) => {
                      if (item.name) {
                        return `${item.name}  ${item.since && `(${item.since})`}`;
                      }
                      return null;
                    })
                    .filter(Boolean)
                    .join(" | ");
                  return (
                    complaintData && (
                      <Box sx={prescriptionStyle.prescriptionContentStyle}>
                        <Box
                          component="img"
                          src="/images/icon_chief_complaints.svg"
                          sx={prescriptionStyle.prescriptionIconStyle}
                        />
                        <Box>
                          <Typography
                            style={prescriptionStyle.patientFindingsHeader}
                          >
                            {data?.header}
                          </Typography>
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {complaintData}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  );
                })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === PATIENT_HISTORY)
              .map((data: any) => {
                const patientFilterData = data?.rows.filter(
                  (data: any) => data?.view_type === MEDICAL_HISTORY
                );
                const patientHistoryData = patientFilterData[0]?.value
                  .map((item: any) => {
                    if (item?.name) {
                      return `${item?.name}  ${
                        item?.status && `Status(${item?.status})`
                      }`;
                    }
                    return null;
                  })
                  .filter(Boolean)
                  .join("  |  ");
                return (
                  patientHistoryData && (
                    <Box
                      className="diagnostics"
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_patient_history.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {"Patient Medical History"}:
                        </Typography>
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {patientHistoryData}
                        </Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === DIABETES_ASSESSMENT)
              .map((data: any) => {
                const diagnosticData = data?.rows[0]?.value
                  ?.map((item: any) => {
                    if (item.name) {
                      return `${item?.name} : ${item?.state && `${item?.state}`}  ${
                        item?.comment && `(${item?.comment})`
                      }`;
                    }
                    return null;
                  })
                  .filter(Boolean)
                  .join(" | ");
                return (
                  diagnosticData && (
                    <Box
                      className="diagnostics"
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_diabetes_assessment.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
  
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {diagnosticData}
                        </Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === GENERAL_PHYSICAL_EXAMINATION)
              .map((data: any) => {
                const tempDiv = document.createElement("div");
                tempDiv.classList.add(
                  isMobileScreen ? "free-text-mobile" : "free-text"
                );
  
                const diagnosticData = data?.rows?.map((item: any) => {
                    if (item.key && item.key!=='comment') {
                      return `${item?.label}: ${item?.value && `${item?.value}`}`;
                    }
                    if(item.key && item.key==='comment') {
  
                      const removeBreaks = /<p><br><\/p>/g;
  
                      tempDiv.innerHTML = item?.value?.replace(removeBreaks, "");
                    }
                    return null;
                  })
                  .filter(Boolean)
                  .join(" | ");
                return (
                  diagnosticData && (
                    <Box
                      className="diagnostics"
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_general_physical_examination.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
  
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {diagnosticData}
                        </Typography>
                        <Typography
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(tempDiv),
                            }}
                        ></Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === INVESTIGATION_MONITORING)
              .map((data: any) => {
                const diagnosticData = data?.rows[0].value
                  .map((item: any) => {
                    if (item.name) {
                      return `${item?.name} :  ${
                        item?.state && `${item?.state}`
                      }`;
                    }
                    return null;
                  })
                  .filter(Boolean)
                  .join(" | ");
                return (
                  diagnosticData && (
                    <Box
                      className="diagnostics"
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_investigation_monitoring.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
  
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {diagnosticData}
                        </Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === FOOT_EXAM)
              .map((data: any) => {
                const leftFoot:any = [];
                const rightFoot:any = [];
                const nss:any = [];
                let footscore = '';
  
                data?.rows?.forEach((item: any) => {
                    if (item.group_title === 'Left Side') {
                      leftFoot.push(`${item?.label} : ${item?.value}`)
                    }else if(item.group_title === 'Right Side'){
                      rightFoot.push(`${item?.label} : ${item?.value}`)
                    }else if(item.group_title === 'NSS' && item.key !== 'foot_score'){
                      nss.push(`${item?.label} : ${item?.value}`)
                    }else if(item.group_title === 'NSS' && item.key === 'foot_score'){
                      footscore = `${'Foot Score'} : ${item?.value}`
                    }
                })
                return (
                  (leftFoot?.length || rightFoot?.length || nss?.length) && (
                    <Box
                      className="diagnostics"
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_foot_exam.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box width={'100%'}>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
                        
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {`Left Side - ${leftFoot.filter(Boolean).join(" | ")}`}
                          </Typography>
  
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {`Right Side - ${rightFoot.filter(Boolean).join(" | ")}`}
                          </Typography>
                        </Box>
  
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {`NSS - ${nss.filter(Boolean).join(" | ")}`}
                        </Typography>
                        
  
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {`${footscore}`}
                        </Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === SYSTEMIC_EXAMINATION)
              .map((data: any) => {
                const leftSide:any = [];
                const rightSide:any = [];
                const other:any = [];
  
                data?.rows?.forEach((item: any) => {
                    if (item.group_title === 'Left Side') {
                      leftSide.push(`${item?.label} : ${item?.value}`)
                    }else if(item.group_title === 'Right Side'){
                      rightSide.push(`${item?.label} : ${item?.value}`)
                    }else{
                      other.push(`${item?.label} : ${item?.value}`)
                    }
                })
                return (
                  (leftSide?.length || rightSide?.length || other?.length) && (
                    <Box
                      className="diagnostics"
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_systemic_examination.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box width={'100%'}>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
                        
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {`Left Side - ${leftSide.filter(Boolean).join(" | ")}`}
                        </Typography>
  
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {`Right Side - ${rightSide.filter(Boolean).join(" | ")}`}
                        </Typography>
  
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {`${other.filter(Boolean).join(" | ")}`}
                        </Typography>
                        
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === DIAGNOSIS)
              .map((data: any) => {
                const diagnosticData = data?.rows[0].value
                  .map((item: any) => {
                    if (item.name) {
                      return `${item?.name}  ${
                        item?.state && `(${item?.state})`
                      }`;
                    }
                    return null;
                  })
                  .filter(Boolean)
                  .join(" | ");
                return (
                  diagnosticData && (
                    <Box
                      className="diagnostics"
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_diagnosis.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
  
                        <Typography
                          style={prescriptionStyle.patientFindingsValue}
                        >
                          {diagnosticData}
                        </Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === EXAMINATION)
              .map((data: any, index: number) => {
                const examinationData: string = data?.rows[0]?.value[0]?.comment;
                const tempDiv = document.createElement("div");
                tempDiv.classList.add(
                  isMobileScreen ? "free-text-mobile" : "free-text"
                );
                const removeBreaks = /<p><br><\/p>/g;
                tempDiv.innerHTML = examinationData.replace(removeBreaks, "");
  
                return (
                  tempDiv.innerHTML?.length > 0 && (
                    <Box
                      className="examination-findings"
                      marginTop="15px"
                      key={index}
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_ex_findings.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(tempDiv),
                          }}
                        ></Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
  
            {prescriptionDetails
              .filter((data: any) => data?.view_type === MEDICATION)
              .map((data: any) => {
                return (
                  data?.rows[0]?.value?.length > 0 && (
                    <Box className="patient-medication" marginTop="15px">
                      <Typography style={prescriptionStyle.patientFindingsHeader}>
                        Prescription:
                      </Typography>
                      <Box className="medicine-details-table">
                        <TableContainer sx={{ overflow: "hidden" }}>
                          <Table sx={{ border: "1px solid #D9D9D9" }}>
                            <TableHead sx={{ background: "#F0F7FE" }}>
                              <TableRow>
                                {prescriptionTableHeader.map((header: any) => {
                                  return (
                                    <TableCell
                                      sx={{
                                        fontSize: isMobileScreen ? "8px" : "14px",
                                        padding: isMobileScreen ? "2px" : "8px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {header.headerName}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.rows[0].value.map((item: any) => {
                                return (
                                  <TableRow
                                    sx={{ borderBottom: "1px solid #D9D9D9" }}
                                  >
                                    <TableCell
                                      sx={{
                                        padding: isMobileScreen ? "0px" : "12px",
                                      }}
                                    >
                                      <Box sx={TableStyle.medicineCell}>
                                        <Box
                                          width={isMobileScreen ? "20px" : "32px"}
                                        >
                                          {item?.name && (
                                            <TabletIcon
                                              width={isMobileScreen ? "20" : "32"}
                                            />
                                          )}
                                        </Box>
                                        <Box
                                          sx={{
                                            fontSize: isMobileScreen
                                              ? "8px"
                                              : "14px",
                                          }}
                                        >
                                          {item?.name}
                                        </Box>
                                      </Box>
                                    </TableCell>
                                    <TableCell sx={TableStyle.cell}>
                                      {item?.dosage}
                                    </TableCell>
                                    <TableCell sx={TableStyle.cell}>
                                      {item?.frequency}
                                    </TableCell>
                                    <TableCell sx={TableStyle.cell}>
                                      {item?.duration}
                                    </TableCell>
                                    <TableCell sx={TableStyle.cell}>
                                      {item?.meal_pref}
                                    </TableCell>
                                    <TableCell sx={TableStyle.cell}>
                                      {item?.start_from &&
                                        dayjs(item?.start_from).format(
                                          "DD/MM/YYYY"
                                        )}
                                    </TableCell>
                                    <TableCell sx={TableStyle.cell}>
                                      {item?.comment}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              ?.filter((data: any) => data?.view_type === INVESTIGATIONS)
              .map((data: any) => {
                return (
                  data?.rows[0].value?.length > 0 && (
                    <Box className="lab-investigation" marginTop="15px">
                      <Typography style={prescriptionStyle.patientFindingsHeader}>
                        Lab Test:
                      </Typography>
  
                      <Box className="lab-test-details-table">
                        <TableContainer sx={{ overflow: "hidden" }}>
                          <Table sx={{ border: "1px solid #D9D9D9" }}>
                            <TableHead
                              sx={{
                                background: "#F0F7FE",
                                borderRadius: "8px",
                              }}
                            >
                              <TableRow>
                                {LabTestTableHeader.map((data: any) => {
                                  return (
                                    <TableCell
                                      sx={{
                                        fontSize: isMobileScreen ? "8px" : "14px",
                                        padding: isMobileScreen ? "5px" : "16px",
                                        textAlign: "center",
                                        width: "fit-content",
                                      }}
                                    >
                                      {data.headerName}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{ border: "1px solid #D9D9D9" }}>
                              {data.rows[0].value.map((item: any) => {
                                return (
                                  <TableRow
                                    sx={{ borderBottom: "1px solid #D9D9D9" }}
                                  >
                                    <TableCell sx={TableStyle.medicalLabCell}>
                                      {item?.name &&
                                      item?.diagnostic_type === "PATHOLOGY" ? (
                                        <img
                                          style={{
                                            pointerEvents: "none",
                                            width: isMobileScreen
                                              ? "12px"
                                              : "32px",
                                            marginLeft: isMobileScreen
                                              ? "4px"
                                              : undefined,
                                          }}
                                          src="/images/pathology.svg"
                                          alt="pathology"
                                        />
                                      ) : item?.diagnostic_type ===
                                        "RADIOLOGY" ? (
                                        <img
                                          style={{
                                            pointerEvents: "none",
                                            width: isMobileScreen
                                              ? "12px"
                                              : "32px",
                                            marginLeft: isMobileScreen
                                              ? "4px"
                                              : undefined,
                                          }}
                                          src="/images/radiology.svg"
                                          alt="pathology"
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            pointerEvents: "none",
                                            width: isMobileScreen
                                              ? "15px"
                                              : "22px",
                                          }}
                                          src="/images/others_diagnostic_type.svg"
                                          alt="others"
                                        />
                                      )}
                                      {item?.name}
                                    </TableCell>
                                    <TableCell sx={TableStyle.cell}>
                                      {item?.test_on}
                                    </TableCell>
                                    <TableCell sx={TableStyle.cell}>
                                      {item?.repeat_on}
                                    </TableCell>
                                    <TableCell
                                      sx={TableStyle.cell}
                                      width="fit-content"
                                    >
                                      {item?.comment}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                  )
                );
              })}
  
            {prescriptionDetails
              .filter((data: any) => data?.view_type === ADVICE)
              .map((data: any) => {
                const adviceData = data?.rows[0].value
                  .map((item: any) => item?.value)
                  .filter(Boolean)
                  .join(" | ");
                return (
                  adviceData && (
                    <Box
                      className="advice"
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_advice.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
                        {
                          <Typography
                            style={prescriptionStyle.patientFindingsValue}
                          >
                            {adviceData}
                          </Typography>
                        }
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === REFERRAL)
              .map((data: any) => {
                return (
                  data?.rows[0]?.value?.length > 0 && (
                    <Box
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_referral.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
                        {data?.rows[0]?.value.map((item: any) => {
                          return (
                            <Typography
                              style={prescriptionStyle.patientFindingsValue}
                            >
                              {item?.speciality} | {item?.dr_name}
                            </Typography>
                          );
                        })}
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === ADVICE_NOTES)
              .map((data: any) => {
                const additionalNoteData = data?.rows[0]?.value[0].value;
                // .map((item: any) => item?.value)
                // .filter(Boolean)
                // .join(" | ");
                const tempDiv = document.createElement("div");
                tempDiv.classList.add(
                  isMobileScreen ? "free-text-mobile" : "free-text"
                );
  
                const removeBreaks = /<p><br><\/p>/g;
  
                tempDiv.innerHTML = additionalNoteData.replace(removeBreaks, "");
                return (
                  tempDiv?.innerHTML?.length > 0 && (
                    <Box
                      className="additional-notes"
                      marginTop="15px"
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_addnl_notes.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
                        {
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(tempDiv),
                            }}
                          ></Typography>
                        }
                      </Box>
                    </Box>
                  )
                );
              })}
            {prescriptionDetails
              .filter((data: any) => data?.view_type === FOLLOW_UP)
              .map((data: any, index: number) => {
                const followUpData: string = data?.rows[0]?.value[0]?.comment;
                const date = data?.rows[0]?.value[0]?.date
                  ? new Date(data.rows[0].value[0].date).toDateString()
                  : "";
                const tempDiv = document.createElement("div");
                tempDiv.classList.add(
                  isMobileScreen ? "free-text-mobile" : "free-text"
                );
                const removeBreaks = /<p><br><\/p>/g;
                tempDiv.innerHTML = followUpData.replace(removeBreaks, "");
                return (
                  tempDiv.innerHTML?.length > 0 && (
                    <Box
                      className="follow-up"
                      marginTop="15px"
                      key={index}
                      sx={prescriptionStyle.prescriptionContentStyle}
                    >
                      <Box
                        component="img"
                        src="/images/icon_follow_up.svg"
                        sx={prescriptionStyle.prescriptionIconStyle}
                      />
                      <Box>
                        <Typography
                          style={prescriptionStyle.patientFindingsHeader}
                        >
                          {data?.header}:
                        </Typography>
                        {date ? (
                          <Typography
                            style={prescriptionStyle?.patientFindingsValue}
                          >
                            On:
                            <span
                              style={{
                                fontWeight: 700,
                                marginLeft: "5px",
                                fontSize: isMobileScreen ? "12px" : "16px",
                              }}
                            >
                              {moment(date).format("DD/MM/YYYY")}
                            </span>
                          </Typography>
                        ) : (
                          ""
                        )}
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(tempDiv),
                          }}
                        ></Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
          </Box>
        </Box>
        
      </Box>
    );
  };
  export default OpenPrescription;
  