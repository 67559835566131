const DischargeSummaryIcon = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="32"
          height="32"
          rx="16"
          fill="#8A63D2"
          fill-opacity="0.1"
        />
        <path d="M9 7L22 7.5L23 11.5V24L16.5 25H9V7Z" fill="#8A63D2" />
        <path
          d="M19.9308 11.5315V10.4601C19.9308 10.3619 19.8504 10.2815 19.7522 10.2815H11.1808C11.0826 10.2815 11.0022 10.3619 11.0022 10.4601V11.5315C11.0022 11.6297 11.0826 11.7101 11.1808 11.7101H19.7522C19.8504 11.7101 19.9308 11.6297 19.9308 11.5315ZM11.1808 13.4958C11.0826 13.4958 11.0022 13.5762 11.0022 13.6744V14.7458C11.0022 14.844 11.0826 14.9244 11.1808 14.9244H15.2879C15.3862 14.9244 15.4665 14.844 15.4665 14.7458V13.6744C15.4665 13.5762 15.3862 13.4958 15.2879 13.4958H11.1808ZM19.5737 16.0851C16.9107 16.0851 14.7522 18.2436 14.7522 20.9065C14.7522 23.5695 16.9107 25.728 19.5737 25.728C22.2366 25.728 24.3951 23.5695 24.3951 20.9065C24.3951 18.2436 22.2366 16.0851 19.5737 16.0851ZM21.9732 23.3061C21.3326 23.9467 20.4799 24.2994 19.5737 24.2994C18.6674 24.2994 17.8147 23.9467 17.1741 23.3061C16.5335 22.6655 16.1808 21.8128 16.1808 20.9065C16.1808 20.0003 16.5335 19.1476 17.1741 18.507C17.8147 17.8664 18.6674 17.5137 19.5737 17.5137C20.4799 17.5137 21.3326 17.8664 21.9732 18.507C22.6138 19.1476 22.9665 20.0003 22.9665 20.9065C22.9665 21.8128 22.6138 22.6655 21.9732 23.3061ZM21.5603 19.2101H20.5714C20.5134 19.2101 20.4598 19.2369 20.4263 19.2838L19.0089 21.2436L18.4933 20.5315C18.4768 20.5085 18.455 20.4898 18.4297 20.477C18.4045 20.4642 18.3765 20.4576 18.3482 20.4579H17.3638C17.2188 20.4579 17.1339 20.623 17.2188 20.7414L18.8661 23.0204C18.9375 23.1186 19.0826 23.1186 19.154 23.0204L21.7031 19.4936C21.7902 19.3753 21.7054 19.2101 21.5603 19.2101ZM15.6284 23.5851H9.21652V7.87081H21.7165V15.5494C21.7031 16.7575 22.002 19.3916 22.002 17.5L23.3237 18.1331C23.4219 18.1331 23.3237 15.6476 23.3237 15.5494V6.97796C23.3237 6.58287 23.0045 6.26367 22.6094 6.26367H8.32366C7.92857 6.26367 7.60938 6.58287 7.60938 6.97796V24.478C7.60938 24.873 7.92857 25.1922 8.32366 25.1922H14.3951C14.4933 25.1922 18.9361 25.184 17.3638 25.1929L16.502 23.5C16.502 23.4018 15.7266 23.5851 15.6284 23.5851Z"
          fill="#EBE7F2"
        />
      </svg>
    </>
  );
};
export default DischargeSummaryIcon;
