import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  FormControl,
  InputLabel,
  Menu,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "../../../shared/icons/SettingsIcon";
import LeftArrowIcon from "../../../shared/icons/LeftArrowIcon";
import VideoIcon from "../../../shared/icons/VideoIcon";
import PhoneIcon from "../../../shared/icons/PhoneIcon";
import { useDispatch, useSelector } from "react-redux";
import Card from "./utils/Card";
import { ROUTE_APPOINTMENTS } from "../../../shared/routes/route.constants";
import { useNavigate } from "react-router-dom";
import { teleCallPatient } from "../../../redux/reducers/prescription.slice";
import { SyntheticEvent, useState } from "react";
import { LoadingButton } from "@mui/lab";
import ModalComponent from "../appointment/Modal";
import InstantRx from "./utils/InstantRx";
import RxArrowIcon from "../../../shared/icons/RxArrowIcon";
import TemplateConfigurator from "./utils/TemplateConfigurator";
import { evaluateParams } from "./utils/Utilities";

const Header = ({
  setVideo,
  selectedAppointmentProp,
  activeTab,
  backbutton,
  setActiveTab
}: any) => {
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState("");
  const [showInstantRx, setShowInstantRx] = useState<boolean>(false);
  const [tempConfigModalOpen, setTempConfigModalOpen] =
    useState<boolean>(false);
  const [rotateValue, setRotateValue] = useState<number>(0);
  const [modalData, setModalData] = useState({
    isSuccess: true,
    headerText: "",
    subText: "",
    actionButtonText: "",
    actionButtonOnClick: () => {
      return;
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [hide, setHide] = useState(false);
  const open = Boolean(anchorEl);
  const { selectedAppointment } = useSelector(
    (state: any) => state.appointments
  );
  const {
    prescription: { rxData },
  } = useSelector((state: any) => state.prescription);

  const BackToAppointments = () => {
    // dispatch(resetSignedURL());
    navigate(ROUTE_APPOINTMENTS);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setRotateValue(180);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const teleCall = async () => {
    setLoading(true);
    const api = await dispatch(
      teleCallPatient({
        patient: {
          email: selectedAppointment?.patient?.email,
          name: selectedAppointment?.patient?.name,
          phone: selectedAppointment?.patient?.phone,
        },
        doctor: {
          doctor_name: selectedAppointment?.doctor_name,
          phone: selectedAppointment?.doctor_info?.phone,
        },
      })
    );

    if (api?.payload?.status === 200) {
      setModalOpen(true);
      setLoading(false);
      setModalOpen(true);
      setModalData({
        isSuccess: true,
        headerText: "Call Placed Successfully",
        subText: "",
        actionButtonText: "Close",
        actionButtonOnClick: () => {
          setModalOpen(false);
        },
      });
    } else {
      setLoading(false);
      setModalOpen(true);
      setModalData({
        isSuccess: false,
        headerText: "Call Failed",
        subText: "",
        actionButtonText: "Close",
        actionButtonOnClick: () => {
          setModalOpen(false);
        },
      });
    }
  };
  const handleMenuClick = (e: any) => {
    setType(e.currentTarget.id);
    setShowInstantRx(true);
    setAnchorEl(null);
  };

  const HeaderContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  };
  const HeaderLayout = {
    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    background: "#FFF",
    padding: "12px",
  };
  const HeaderMobileContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
    gap: "10px",
  };

  const HeaderFooter = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const SettingStyleMobile = {
    maxWidth: "40px",
    minWidth: "40px",
    minHeight: "40px",
    maxHeight: "40px",
    padding: "4px",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "none",
  };
  const SettingStyle = {
    maxWidth: "40px",
    minWidth: "40px",
    minHeight: "40px",
    maxHeight: "40px",
    padding: "4px",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "none",
  };
  const Rotate180 = {
    transform: "rotate(180deg)",
  };
  const Rotate0 = {
    transform: "rotate(0deg)",
  };

  const handleHide = () => {
    setHide(!hide);
  };

  return (
    <Card
      styles={{
        paddingTop: isMobileScreen ? "16px" : "24px",
        marginBottom: isMobileScreen ? 0 : "0px",
        paddingBottom: "12px",
        position: "relative",
        zIndex: 1,
      }}
    >
      <Box
        sx={isMobileScreen ? HeaderLayout : {}}
        maxHeight={isMobileScreen ? "fit-content" : "98px"}
        margin={0}
      >
        <Box sx={isMobileScreen ? HeaderMobileContainer : HeaderContainer}>
          <Box
            display={"flex"}
            flexDirection={isMobileScreen ? "column" : "row"}
            gap={isMobileScreen ? "12px" : 8}
            justifyContent={"space-between"}
            borderBottom={isMobileScreen ? "1px solid #EEE" : "none"}
          >
            <Box
              display={"flex"}
              justifyContent={isMobileScreen ? "none" : "center"}
              alignItems={"center"}
              gap={isMobileScreen ? "24px" : 5}
            >
              {!isMobileScreen && (
                <Tooltip title={"Go to Appointments"}>
                  <Button
                    variant="outlined"
                    disableFocusRipple
                    sx={{
                      maxWidth: "42px",
                      minWidth: "42px",
                      minHeight: "42px",
                      maxHeight: "42px",
                      padding: "4px",
                      backgroundColor: "none",
                    }}
                    onClick={BackToAppointments}
                  >
                    <Box
                      width={{ md: 20 }}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <LeftArrowIcon />
                    </Box>
                  </Button>
                </Tooltip>
              )}
              {!isMobileScreen && (
                <Box display="flex" gap={4}>
                  <Stack direction="row" spacing={2}>
                    {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                    <Avatar>
                      {`${selectedAppointment?.patient?.name}`.charAt(0)}
                    </Avatar>
                  </Stack>
                </Box>
              )}
              <Box display="flex" gap={4}>
                <Box
                  display={"flex"}
                  flexDirection={isMobileScreen ? "row" : "column"}
                  justifyContent={"space-between"}
                  sx={{
                    gap: isMobileScreen ? "12px" : "4px",
                  }}
                >
                  {isMobileScreen && evaluateParams(backbutton) && (
                    <>
                      <Box
                        width={{ md: 20 }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        onClick={BackToAppointments}
                      >
                        <LeftArrowIcon />
                      </Box>
                    </>
                  )}
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        minWidth: "max-content",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      fontSize="16px"
                      fontWeight={400}
                      color="#000"
                    >
                      {selectedAppointment?.patient?.name}
                    </Typography>
                  </Box>
                  {!isMobileScreen && (
                    <Typography fontSize="14px" fontWeight={400} color="#000">
                      {` ${
                        selectedAppointment?.patient?.gender === "female"
                          ? "F"
                          : selectedAppointment?.patient?.gender === "male" ||
                            selectedAppointment?.patient?.gender === "Male"
                          ? "M"
                          : "O"
                      },
                ${selectedAppointment?.patient?.age?.no_of_years}y${" "}
                ${
                  selectedAppointment?.patient?.age?.no_of_months > 0
                    ? `${selectedAppointment?.patient?.age?.no_of_months}m`
                    : ""
                }
                `}
                    </Typography>
                  )}
                </Box>
              </Box>

              {isMobileScreen && (
                <Box
                  onClick={handleHide}
                  display={"flex"}
                  width="100%"
                  alignItems={"center"}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography fontSize="14px" fontWeight={400} color="#000">
                      {` ${
                        selectedAppointment?.patient?.gender === "female"
                          ? isMobileScreen
                            ? "F"
                            : "F"
                          : selectedAppointment?.patient?.gender === "male" ||
                            selectedAppointment?.patient?.gender === "Male"
                          ? isMobileScreen
                            ? "M"
                            : "M"
                          : isMobileScreen
                          ? "Others"
                          : "O"
                      },
                ${selectedAppointment?.patient?.age?.no_of_years}y${" "}
                ${
                  selectedAppointment?.patient?.age?.no_of_months > 0
                    ? `${selectedAppointment?.patient?.age?.no_of_months}m`
                    : ""
                }
                `}
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip title={"View more"}>
                      <img
                        style={hide ? Rotate180 : Rotate0}
                        src="/images/arrow_down.svg"
                        alt="arrow_expand"
                      />
                    </Tooltip>
                  </Box>
                </Box>
              )}
            </Box>

            <Box
              display={isMobileScreen ? "" : "flex"}
              gap={isMobileScreen ? "" : 2}
              justifyContent={isMobileScreen ? "center" : ""}
            >
              {isMobileScreen && hide && (
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  marginLeft="30px"
                  gap="24px"
                  paddingBottom={isMobileScreen ? "12px" : undefined}
                >
                  {selectedAppointment?.patient?.patient_hid && (
                    <Typography fontSize="14px" fontWeight={400} color="#000">
                      {`UID: ${selectedAppointment?.patient?.patient_hid}`}
                    </Typography>
                  )}
                  <Typography fontSize="14px" fontWeight={400} color="#000">
                    {`${selectedAppointment?.patient?.country_code}-${selectedAppointment?.patient?.phone}`}
                  </Typography>
                </Box>
              )}
              {!isMobileScreen && (
                <Box
                  display={"flex"}
                  flexDirection={isMobileScreen ? "row" : "column"}
                  gap={isMobileScreen ? 3 : 1}
                >
                  {selectedAppointment?.patient?.patient_hid && (
                    <Typography fontSize="14px" fontWeight={400} color="#000">
                      {`UID: ${selectedAppointment?.patient?.patient_hid}`}
                    </Typography>
                  )}
                  <Typography fontSize="14px" fontWeight={400} color="#000">
                    {`${selectedAppointment?.patient?.country_code}-${selectedAppointment?.patient?.phone}`}
                  </Typography>
                </Box>
              )}
              {!isMobileScreen && (
                <Box display={"flex"} gap={2}>
                  <Tooltip title={"Start audio call with patient "}>
                    <LoadingButton
                      loading={loading}
                      onClick={() => teleCall()}
                      variant="outlined"
                      disableFocusRipple
                      sx={{
                        minWidth: "50px",
                        minHeight: "54px",
                        padding: "4px",
                        backgroundColor: "#EDEEF6",
                        border: "none",
                      }}
                    >
                      <Box
                        width={{ md: 20 }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <PhoneIcon />
                      </Box>
                    </LoadingButton>
                  </Tooltip>
                  {selectedAppointment?.doctor_video_link &&
                    selectedAppointment?.appointment_status !== "Done" && (
                      <Tooltip title={"Start video call with patient "}>
                        <Button
                          variant="outlined"
                          disableFocusRipple
                          sx={{
                            minWidth: "50px",
                            minHeight: "54px",
                            padding: "4px",
                            backgroundColor: "#EDEEF6",
                            border: "none",
                          }}
                        >
                          <Box
                            width={{ md: 20 }}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            onClick={() => setVideo()}
                          >
                            <VideoIcon />
                          </Box>
                        </Button>
                      </Tooltip>
                    )}
                </Box>
              )}
            </Box>
          </Box>

          <Box display={"flex"} gap={2} sx={isMobileScreen ? HeaderFooter : {}}>
            {isMobileScreen && (
              <Box display={"flex"} gap={2}>
                <Tooltip title={"Start audio call with patient "}>
                  <LoadingButton
                    loading={loading}
                    onClick={() => teleCall()}
                    variant="outlined"
                    disableFocusRipple
                    sx={{
                      minWidth: "40px",
                      minHeight: "40px",
                      padding: "4px",
                      backgroundColor: "#EDEEF6",
                      border: "none",
                    }}
                  >
                    <Box
                      width={{ md: 20 }}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <PhoneIcon />
                    </Box>
                  </LoadingButton>
                </Tooltip>
                {selectedAppointment?.doctor_video_link &&
                  selectedAppointment?.appointment_status !== "Done" && (
                    <Tooltip title={"Start video call with patient "}>
                      <Button
                        variant="outlined"
                        disableFocusRipple
                        sx={{
                          minWidth: "40px",
                          minHeight: "40px",
                          padding: "4px",
                          backgroundColor: "#EDEEF6",
                          border: "none",
                        }}
                        onClick={()=>{
                          setActiveTab(1)
                        }}
                      >
                        <Box
                          width={{ md: 20 }}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          onClick={() => setVideo()}
                        >
                          <VideoIcon />
                        </Box>
                      </Button>
                    </Tooltip>
                  )}
              </Box>
            )}
            <Box display={"flex"} gap={2}>
              {activeTab === 0 &&
                selectedAppointment?.appointment_status !== "Done" && (
                  <Tooltip
                    title={"Create New or Choose From an Instant prescription"}
                  >
                    <Button
                      onClick={handleClick}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      sx={{
                        width: isMobileScreen ? "130px" : "100%",
                        height: isMobileScreen ? "40px" : "100%",
                        border: "1px solid #494E9D",
                        background: "#ffff",
                        textTransform: "none !important",
                      }}
                      endIcon={<RxArrowIcon rotate={rotateValue} />}
                    >
                      Instant Rx
                    </Button>
                  </Tooltip>
                )}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
               
              >
                <MenuItem
                  id="create-instant"
                  onClick={(e) => handleMenuClick(e)}
                >
                  Create Instant Prescription
                </MenuItem>
                <MenuItem id="use-instant" onClick={(e) => handleMenuClick(e)}>
                  Use Instant Prescription
                </MenuItem>
              </Menu>
              {activeTab === 0 &&
                selectedAppointment?.appointment_status !== "Done" && (
                  <Button
                    onClick={() => setTempConfigModalOpen(true)}
                    variant="outlined"
                    disableFocusRipple
                    sx={isMobileScreen ? SettingStyleMobile : SettingStyle}
                  >
                    <Tooltip
                      title={"Configure the components of your Rx template"}
                    >
                      <Box
                        width={{ md: 20 }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <SettingsIcon />
                      </Box>
                    </Tooltip>
                  </Button>
                )}
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalComponent
        open={modalOpen}
        setOpen={setModalOpen}
        isSuccess={modalData.isSuccess}
        headerModal={true}
        headerText={modalData.headerText}
        subText={modalData.subText}
        actionButtonText={modalData.actionButtonText}
        actionButtonOnClick={modalData.actionButtonOnClick}
      />
      {showInstantRx && (
        <InstantRx
          setShowInstantRx={setShowInstantRx}
          showInstantRx={showInstantRx}
          type={type}
        />
      )}

      {/* Template Configurator Modal */}
      {tempConfigModalOpen && (
        <TemplateConfigurator
          modalState={tempConfigModalOpen}
          toggleModalState={setTempConfigModalOpen}
        />
      )}
    </Card>
  );
};

export default Header;
