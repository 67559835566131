interface MailIconProps{
    width?: string;
    height?: string;
    fill?: string;
}
const MailIcon = ({width, height, fill}: MailIconProps) => {
  return (
    <>
      <svg
        width={width ? width : "16"}
        height={height ? height : "16"}
        viewBox="0 0 16 16"
        fill={fill ? fill :"none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.332 2.66663H2.66536C1.93203 2.66663 1.3387 3.26663 1.3387 3.99996L1.33203 12C1.33203 12.7333 1.93203 13.3333 2.66536 13.3333H13.332C14.0654 13.3333 14.6654 12.7333 14.6654 12V3.99996C14.6654 3.26663 14.0654 2.66663 13.332 2.66663ZM13.332 5.33329L7.9987 8.66663L2.66536 5.33329V3.99996L7.9987 7.33329L13.332 3.99996V5.33329Z"
          fill="#0A0666"
        />
      </svg>
    </>
  );
};
export default MailIcon;
