/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Skeleton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import Card from '../../../utils/Card'
import { CARD_SHADOW } from '../../../utils/Constants'
import CardHeader from '../../../utils/CardHeader'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'


interface GravidaProps {
    sex: string;
    delivery: string;
    birthWeight: string;
    age: string;
    comments: string;
    advice: string;
  }
  
  const EditorModules = {
    toolbar: [
      ["bold", "italic", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  
  const initState = {
    sex: 'male',
    delivery: 'normal',
    birthWeight: '',
    age: '',
    comments: '',
    advice: '',
  }

const Gravida = (props:any) => {

    const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isFocused: boolean = props.isFocused || false;
  const sectionData = props?.sectionData?.[0]?.rows[0];

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
        ...state,
        ...action
    }
},initState)

  const { setTabData } = props;
//   const {
//     prescription: { instantRxData },
//   } = useSelector((state: any) => state.prescription);

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };


  useEffect(()=>{
    console.log("state",state)
  },[state])

  useEffect(() => {
    if (sectionData && sectionData.value[0]) {
      // setFindingsValue(findings);
      setLoading(false);
    }
  }, [sectionData]);


  const renderHeaderButtons = ()=>{

    const buttons = [
        {
            name:'Add',
            icon:'icon_add_rx.svg',
            onClick:()=>{return}
        },
        {
            name:'Copy',
            icon:'icon_copy_rx.svg',
            onClick:()=>{return}
        },
        {
            name:'Delete',
            icon:'icon_delete_rx.svg',
            onClick:()=>{return}
        },
    ]

    

    return (
        <>
            {buttons.map((elem,index)=>{
                return (
                    <button
                        key={elem.name}
                        style={{
                            outline: "none",
                            background: "none",
                            backgroundColor:theme.palette.primary.light,
                            padding: 8,
                            // marginRight: index !== imgs.length - 1 ? 16 : 0,
                            border: 0,
                            borderRadius:'50%',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                    >
                        <Tooltip
                            title={elem.name}
                        >
                            <img
                                onClick={elem.onClick}
                                src={`/images/${elem.icon}`}
                                alt={elem.icon}
                            />
                        </Tooltip>
                    </button>
                )
            })}
            
        </>
    )
    
  }

  const validateInput = (value:any,type:string)=>{
    switch(type){
        case 'birthWeight':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'age':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'comments':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
  }



  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Gravida"
        >
          <CardHeader
            headerImg=""
            headerTitle="Gravida"
          >
            <Box display={'flex'} gap={1}>
                {renderHeaderButtons()}
            </Box>
            
          </CardHeader>

          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <Box flex={1} display={'flex'} flexDirection={'column'} gap={1}>

                    <Box display={'flex'} gap={4} justifyContent={'start'} alignItems={'center'} sx={{flex:1}}>
                        <Typography>Sex</Typography>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="sex"
                            row
                            value={state.sex}
                            onClick={(e:any)=>{
                                if(e?.target?.value){
                                  setState({sex:e.target.value})
                                }
                            }}
                        >
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                        </RadioGroup>
                    </Box>

                    <Box display={'flex'} gap={1}>
                        <TextField
                            label={'Birth Weight'}
                            value={state.birthWeight}
                            onChange={(e)=>{
                                if(validateInput(e.target.value,'birthWeight')){
                                    setState({
                                        birthWeight:e.target.value
                                    })
                                }
                            }}
                            sx={{width:'200px'}}
                        />
                        
                        <TextField
                            label={'Age'}
                            value={state.age}
                            onChange={(e)=>{
                                if(validateInput(e.target.value,'age')){
                                    setState({
                                        age:e.target.value
                                    })
                                }
                            }}
                            sx={{
                                flex:1
                            }}
                        />
                    </Box>
                    
                    
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Box flex={1} display={'flex'} flexDirection={'column'} gap={1}>

                    <Box display={'flex'} gap={4} justifyContent={'start'} alignItems={'center'} sx={{flex:1}}>
                        <Typography>Delivery</Typography>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="delivery"
                            row
                            value={state.delivery}
                            onClick={(e:any)=>{
                                if(e?.target?.value){
                                  setState({delivery:e.target.value})
                                }
                            }}
                        >
                            <FormControlLabel value="normal" control={<Radio />} label="Normal" />
                            <FormControlLabel value="lscs" control={<Radio />} label="LSCS" />
                        </RadioGroup>
                    </Box>

                    

                    <Box display={'flex'} gap={1} flex={1}>
                        <TextField
                            label={'Comments'}
                            value={state.comments}
                            onChange={(e)=>{
                                if(validateInput(e.target.value,'comments')){
                                    setState({
                                        comments:e.target.value
                                    })
                                }
                            }}
                            sx={{flex:1}}
                        />
                    </Box>
                    
                    
                </Box>
            </Box>
            <ReactQuill
                id="rx-examination-findings"
                theme="snow"
                placeholder="Enter some advice for the patient"
                modules={EditorModules}
                value={state.advice}
                onChange={(content, _delta, _source, editor) => {
                    let newComment = content;
                    if (editor.getLength() <= 1) {
                        newComment = "";
                    }
                    if (editor.getLength() > 1500) {
                        newComment = newComment.substring(0, 1500);
                    }
                    setState({
                        advice:newComment
                    })
                    // setTabData((prev: any) => {
                //     return prev.map((section: any) => {
                //     if (section.view_type === MENSTRUAL_HISTORY) {
                //         return {
                //         ...section,
                //         rows: section.rows.map((row: any, index: number) => {
                //             if (index === 0) {
                //             return {
                //                 ...row,
                //                 value: row.value.map((val: any, index: number) => {
                //                 if (index === 0) {
                //                     return {
                //                     ...val,
                //                     comments: newComment ? newComment : "",
                //                     };
                //                 }
                //                 return val;
                //                 }),
                //             };
                //             }
                //             return row;
                //         }),
                //         };
                //     }
                //     return section;
                //     });
                // });
                }}
                style={{ color: "#000000" }}
            />
          </Box>
          

          
        </Card>
      )}
      
    </>
  )
}

export default Gravida