import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";

import IconButton from "@mui/material/IconButton";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ROUTE_ADMIN,ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC, ROUTE_LOGIN, ROUTE_NEW_APPOINTMENT, ROUTE_SUPER_ADMIN_INIT,ROUTE_ADMIN_NEW_RX_TEMPLATE_FORM, ROUTE_APPOINTMENTS, ROUTE_MY_PATIENTS, ROUTE_ADMIN_LIST_RX_TEMPLATES, ROUTE_ADMIN_RX_TEMPLATES,ROUTE_ALL_INVOICES, ROUTE_KPI, ROUTE_ADMIN_CLINICAL_MATRIX } from "../routes/route.constants";

import { useLocation, useNavigate } from "react-router-dom";
import { resetAppOnLogout } from "../Utils/utils";

import { useDispatch, useSelector } from "react-redux";
import {
  hideError,
  resetAppointments,
} from "../../redux/reducers/appointment.slice";
import { fetchProfile, logout, resetAccountInfo } from "../../redux/reducers/auth.slice";
import { toggleShowMobileSearch } from "../../redux/reducers/appointment.slice";
// import { Divider, Snackbar, Typography } from "@mui/material";
import LoadingBar from "react-top-loading-bar";
import { Drawer, Tooltip, Typography, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Auth } from "aws-amplify";
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { getFeatureValue, useAuthorization } from "../Utils/useAuthorization";

const drawerWidth = 60;

export default function DashboardLayout({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [value, setValue] = React.useState("appointments");
  const { error } = useSelector((state) => state.appointments);

  const { profile } = useSelector((state) => state.auth);

  const [loadingProgress, setLoadingProgress] = React.useState(50);
  const { updateLoader } = useSelector((state) => state.appointments);

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [pathname,setPathname] = React.useState('');
  const [showProfileError, setShowProfileError] = React.useState();

  const userFeatures = useAuthorization();


  // if any rx section is being edited in the mobile resp. mode hide tabs using below var
  const {
    prescription: { rxData, editRxSecMobile },
  } = useSelector((state) => state.prescription);

  const viewTypes = Array.isArray(rxData)
    ? rxData?.map((sec) => sec.view_type)
    : [];

  React.useEffect(()=>{
    if(location.pathname){
        setPathname(location.pathname);
    }
  },[location])

  React.useEffect(()=>{
    if(location.pathname === "/appointments"){
      setValue("appointments")
    }
  },[location])

  React.useEffect(() => {
    setLoadingProgress(updateLoader ? 50 : 100);
  }, [updateLoader]);

  React.useEffect(() => {
    if (profile === null && localStorage.getItem('emrToken')) {
      fetchProfileData();
    }
  }, [profile]);
  const fetchProfileData = async () => {
    const response = await dispatch(fetchProfile());
    setShowProfileError(response?.error?.message);
    // if (response?.payload?.status === undefined) {
    //   localStorage.removeItem("emrToken");
    //   localStorage.clear();
    //   dispatch(resetAppointments());
    //   dispatch(logout());
    //   window.location.href = `${ROUTE_LOGIN}`;
    // }
  };

  React.useEffect(() => {
    const handleMessage = (event) => {
      // Check the origin for security reasons
      if (event.origin !== window.location.origin) {
        return;
      }

      // // Process the message
      // console.log("Received message from iframe:", event.data);
      if (event?.data?.type === "unAuth") {
        logoutFunction();
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      dispatch(resetAppointments());
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  React.useEffect(() => {
    if (isMobileScreen) {
      if (document.body) {
        document.body.style.overflow = "auto";
      }
    } else {
      if (document.body) {
        document.body.style.overflow = "hidden";
      }
    }
  }, [isMobileScreen]);

  const logoutFunction = async () => {
    // persistor.pause();
    // persistor.flush().then(() => {
    //   return persistor.purge();
    // });
    try{
      await Auth.signOut({ global: true }).then(res=>{
        localStorage.removeItem("emrToken");
        localStorage.clear();
        dispatch(resetAppointments());
        dispatch(logout());

        window.location.href = `${ROUTE_LOGIN}`;
      })
    }catch(err){
      console.log("signout error",err);

      localStorage.removeItem("emrToken");
      localStorage.clear();
      dispatch(resetAppointments());
      dispatch(logout());
      window.location.href = `${ROUTE_LOGIN}`;
    }

    
  };

  const navLinks = [
    {
      icon: "/images/navIcon1.svg",
      label: "Appointments",
      value:ROUTE_APPOINTMENTS,
      href: ROUTE_APPOINTMENTS,
      disabled: !(profile?.role?.includes('doctor') || profile?.role?.includes('staff')),
    },
    {
      icon: "/images/navIcon2.svg",
      label: "Add Appointment",
      value:ROUTE_NEW_APPOINTMENT,
      href: ROUTE_NEW_APPOINTMENT,
      disabled: !(profile?.role?.includes('doctor') || profile?.role?.includes('staff')) || !userFeatures.includes(getFeatureValue('CREATE_APPOINTMENT')),
    },
    {
      icon: "/images/navIcon3.svg",
      label: "KPI Dashboard",
      href: ROUTE_ADMIN_CLINICAL_MATRIX,
      value:ROUTE_KPI,
      disabled: !userFeatures.includes(getFeatureValue('KPI_Dashboard')),
    },
    {
      icon: "/images/navIcon4.svg",
      label: "Invoice",
      value:'/invoice',
      href: ROUTE_ALL_INVOICES,
      disabled: !(profile?.role?.includes('doctor') || profile?.role?.includes('staff')),
    },
    {
      icon: "/images/navIcon5.svg",
      label: "Admin",
      value:"/admin",
      href: ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC,
      disabled: !profile?.role?.includes('admin'),
    },
    {
      icon: "/images/navIcon6.svg",
      label: "Template Builder",
      value:ROUTE_ADMIN_LIST_RX_TEMPLATES,
      href: ROUTE_ADMIN_LIST_RX_TEMPLATES,
      disabled: !profile?.role?.includes('admin') || !userFeatures.includes(getFeatureValue('TEMPLATE_BUILDER')),
    },
  ];

  const settingsLinks = [
    {
      icon: "/images/settingsIcon.svg",
      label: "Settings",
      href: "",
      disabled: true,
    },
    {
      icon: "/images/signoutIcon.svg",
      label: "Sign Out",
      href: ROUTE_LOGIN,
      onClick: () => logoutFunction(),
      disabled: false,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 'mypatients') {
      navigate(ROUTE_MY_PATIENTS)
    }
    if(newValue === 'appointments') {
      navigate(ROUTE_APPOINTMENTS)
      dispatch(resetAccountInfo())
    }
  };

  React.useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideError());
      }, 6000);
    }
  }, [error]);

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // backgroundColor: theme.palette.primary.main,
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: theme.palette.primary.main,
    border: "none",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const toggleDrawer = (val) => {
    setDrawerOpen(val);
  };

  // const DrawerHeader = styled("div")(({ theme }) => ({
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "flex-end",
  //   padding: theme.spacing(0, 1),
  //   // necessary for content to be below app bar
  //   ...theme.mixins.toolbar,
  // }));

  const MyAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    color: "black",
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const MyDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  return (
    <>
      {isMobileScreen ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
            height: "100%",
            width: "100vw",
          }}
        >
         {showProfileError && <Box>{showProfileError}</Box>}
          {!location.pathname.includes("prescription") && (
            <Box>
              <AppBar
                position="fixed"
                elevation={4}
                sx={{
                  backgroundColor: theme.palette.white.main,
                  color: theme.palette.primary.main,
                }}
              >
                <Toolbar>
                  <Tooltip title={"Home"}>
                    <Box
                      width={{ xs: 36 }}
                      component="img"
                      alt="logo"
                      src="/images/mobiledashboardlogo.svg"
                    />
                  </Tooltip>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ ml: 1, flexGrow: 1 }}
                  >
                    {profile && profile.name && `${!profile?.name?.includes('Dr ') ? 'Dr' : ''} ${profile?.name}`}
                  </Typography>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 0 }}
                    onClick={() => {
                      dispatch(toggleShowMobileSearch());
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => toggleDrawer(!drawerOpen)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Drawer
                anchor={"right"}
                open={drawerOpen}
                onClose={() => toggleDrawer(false)}
                PaperProps={{
                  sx: {
                    backgroundColor: `${theme.palette.primary.main}!important`,
                  },
                }}
              >
                <List sx={{}}>
                  {navLinks.map((elem, index) => {
                    return (
                      <Tooltip title={elem.label}>
                        <ListItem
                          key={elem.icon}
                          disablePadding
                          sx={{ padding: "2px 0px", display: "block" }}
                        >
                          <ListItemButton
                            disabled={elem?.disabled}
                            onClick={() => {
                              navigate(elem.href);
                              toggleDrawer(false)
                            }}
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: 2.5,
                              borderRadius: "0px 20px 0px 0px",
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                width={{ md: 25 }}
                                component="img"
                                alt={elem.label}
                                src={elem.icon}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={elem.label}
                              sx={{ 
                                // opacity: open ? 1 : 0,
                                color:theme.palette.white.main,
                                paddingX:2
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    );
                  })}
                </List>

                <List sx={{ p: 0, marginTop: "auto" }}>
                  {settingsLinks.map((elem, index) => (
                    <Tooltip title={elem.label}>
                      <ListItem
                        key={elem.icon}
                        disablePadding
                        sx={{ padding: "2px 0px", display: "block" }}
                      >
                        <ListItemButton
                          disabled={elem?.disabled}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                            borderRadius: "0px 20px 0px 0px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              width={{ md: 25 }}
                              component="img"
                              alt={elem.label}
                              src={elem.icon}
                              onClick={elem.onClick}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={elem.label}
                            sx={{ 
                              // opacity: open ? 1 : 0,
                              color:theme.palette.white.main,
                              paddingX:2
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                  ))}
                </List>
              </Drawer>
            </Box>
          )}
          <Box
            position={"relative"}
            top={!location.pathname.includes("prescription") ? 60 : 0}
            overflow={"auto"}
          >
            <Box
              className="mobile-drawer-content"
              sx={{ borderBottom: `1px solid ${theme.palette.white.medium}` }}
            >
              {!location.pathname.includes("prescription") && (
                <Tabs value={value} onChange={handleChange}>
                  <Tab
                    sx={{ width: 1 / 3, fontSize: 14, p: 1 }}
                    value="mypatients"
                    disabled={!userFeatures.includes(getFeatureValue('MY_PATIENTS'))}
                    label={
                      <Tooltip title="View Patients">
                        <span>MY PATIENTS</span>
                      </Tooltip>
                    }
                  />
                  <Tab
                    sx={{ width: 1 / 3, fontSize: 14, p: 1 }}
                    value="appointments"
                    label={
                      <Tooltip title="My Appointments">
                        <span>APPOINTMENTS</span>
                      </Tooltip>
                    }
                  />
                  <Tab
                    sx={{ width: 1 / 3, fontSize: 14, p: 1 }}
                    value="clinics"
                    disabled
                    label={
                      <Tooltip title="Clinics">
                        <span>CLINICS</span>
                      </Tooltip>
                    }
                  />
                </Tabs>
              )}
            </Box>

            <Box sx={{ height: "100%", p: 0, overflow: "auto" }}>
              {children}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="main-tabs" sx={{ display: "flex" }}>
          <CssBaseline />
          <LoadingBar
            color={theme.palette.primary.main}
            progress={loadingProgress}
            onLoaderFinished={() => setLoadingProgress(0)}
          />
          {(location.pathname.includes(ROUTE_APPOINTMENTS) ||
            location.pathname.includes(ROUTE_SUPER_ADMIN_INIT) ||
            location.pathname.includes(ROUTE_ADMIN) ||
            location.pathname.includes(ROUTE_KPI) ||
            location.pathname.includes(ROUTE_MY_PATIENTS)
          ) && (!location.pathname.includes(ROUTE_ADMIN_NEW_RX_TEMPLATE_FORM) ) && (
            <MyAppBar
            className="web-appbar"
              position="fixed"
              elevation={0}
              sx={{
                width: `calc(100% - ${drawerWidth}px)`,
                ml: `${drawerWidth}px`,
              }}
            >
              <Toolbar
                sx={{ backgroundColor: theme.palette.primary.background,display:(pathname.includes('admin') || pathname.includes('kpi'))?"none":'block' }}
              >
                <Box>
                  <Tabs value={value} onChange={handleChange}>
                    <Tab
                      sx={{ textTransform: "none",fontWeight:value==="mypatients"?600:undefined }}
                      value="mypatients"
                      disabled={!userFeatures.includes(getFeatureValue('MY_PATIENTS'))}
                      label={
                        <Tooltip title="View Patients">
                          <span>MY PATIENTS</span>
                        </Tooltip>
                      }
                    />
                    <Tab
                      sx={{ textTransform: "none",fontWeight:value==="appointments"?600:undefined}}
                      value="appointments"
                      label={
                        <Tooltip title="My Appointments">
                          <span>APPOINTMENTS</span>
                        </Tooltip>
                      }
                    />
                    <Tab
                      sx={{ textTransform: "none",fontWeight:value==='clinics'?600:undefined}}
                      value="clinics"
                      disabled
                      label={
                        <Tooltip title="Manage Clinics">
                          <span>CLINICS</span>
                        </Tooltip>
                      }
                    />
                  </Tabs>
                </Box>
              </Toolbar>
            </MyAppBar>
          )}
          <MyDrawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            {/* <Toolbar /> */}
            <Box
              height="100vh"
              bgcolor={theme.palette.primary.main}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              sx={{ overflowX: "hidden" }}
            >
              <List sx={{ p: 0 }}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  paddingY={"10px"}
                  marginBottom={3}
                >
                  <Tooltip title={"Home"}>
                    <Box
                      width={{ md: 40 }}
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        (window.location.href = ROUTE_APPOINTMENTS)
                      }
                      component="img"
                      alt="logo"
                      src="/images/dashboardLogo.svg"
                    />
                  </Tooltip>
                </Box>
                {navLinks.map((elem, index) => {
                  return (
                    <Tooltip title={elem.label}>
                      <ListItem
                        key={elem.icon}
                        disablePadding
                        sx={{ padding: "2px 0px 0px 10px", display: "block" }}
                      >
                        <ListItemButton
                          onClick={() => {
                            navigate(elem.href);
                          }}
                          disableRipple
                          disabled={elem?.disabled}
                          sx={{
                            position: "relative",
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 1,
                            borderRadius: "12px 0px 0px 12px",
                            backgroundColor:
                              (elem.value==='/admin' || elem.value===ROUTE_KPI || elem.value==='/invoice' ? location.pathname.includes(elem.value) : 
                              location.pathname === (elem.value))
                                ? theme.palette.white.main
                                : "transparent",
                            "&:hover": {
                              backgroundColor:
                                (elem.value==='/admin' || elem.value===ROUTE_KPI || elem.value==='/invoice' ? location.pathname.includes(elem.value) : 
                                location.pathname === (elem.value))
                                  ? theme.palette.white.main
                                  : "transparent",
                            },
                            "&:before": {
                              position: "absolute",
                              // border:"1px solid black",
                              content: '""',
                              top: "-10px",
                              right: "0px",
                              height: "10px",
                              width: "10px",
                              borderBottomRightRadius: "70%",
                              boxShadow:
                                (elem.value==='/admin' || elem.value===ROUTE_KPI || elem.value==='/invoice' ? location.pathname.includes(elem.value) : 
                                location.pathname === (elem.value))
                                  ? "4px 2px 0 0 white"
                                  : "",
                            },
                            "&:after": {
                              position: "absolute",
                              // border:"1px solid black",
                              content: '""',
                              bottom: "-10px",
                              right: "0px",
                              height: "10px",
                              width: "10px",
                              borderTopRightRadius: "70%",
                              boxShadow:
                                (elem.value==='/admin' || elem.value===ROUTE_KPI || elem.value==='/invoice' ? location.pathname.includes(elem.value) : 
                                location.pathname === (elem.value))
                                  ? "4px -2px 0 0 white"
                                  : "",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              width={{ md: 25 }}
                              component="img"
                              alt={elem.label}
                              src={elem.icon}
                              sx={{
                                filter:
                                  (elem.value==='/admin' || elem.value===ROUTE_KPI || elem.value==='/invoice' ? location.pathname.includes(elem.value) : 
                                  location.pathname === (elem.value))
                                    ? "invert(1)"
                                    : "",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={elem.label}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                  );
                })}
              </List>

              <List sx={{ p: 0 }}>
                {settingsLinks.map((elem, index) => (
                  <Tooltip title={elem.label}>
                    <ListItem
                      key={elem.icon}
                      disablePadding
                      sx={{ padding: "2px 0px", display: "block" }}
                    >
                      <ListItemButton
                        disabled={elem?.disabled}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          borderRadius: "0px 20px 0px 0px",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            width={{ md: 25 }}
                            component="img"
                            alt={elem.label}
                            src={elem.icon}
                            onClick={elem.onClick}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={elem.label}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                ))}
              </List>
            </Box>
          </MyDrawer>
          <Box
            component="main"
            height={"100vh"}
            sx={{
              flexGrow: 1,
              paddingTop:
                location.pathname.includes(ROUTE_APPOINTMENTS) ||
                location.pathname.includes(ROUTE_SUPER_ADMIN_INIT) ||
                location.pathname.includes(ROUTE_ADMIN) ||
                location.pathname.includes(ROUTE_KPI) ||
                location.pathname.includes(ROUTE_ADMIN_RX_TEMPLATES)
                  ? 9
                  : 0,
              backgroundColor: theme.palette.primary.background,
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </>
  );
}
