import { Autocomplete, Box, Button, CircularProgress, IconButton, Modal, Radio, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from '@mui/material';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CallIcon from '@mui/icons-material/Call';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight:'90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius:'14px'
};
const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxHeight:'90vh',
  transform: 'translate(-50%, -50%)',
  width: 280,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius:'14px',
};


export const SearchInput = ({label,value,setValue,isSearchable,searchFunction=null,autoComplete='on',searchOptionMappingFunction=null,searchBy='',setSelectedOption,type,max,disabled,isPhoneInput,isTextInput,helperText,error}) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading,setLoading] = useState(false);

    const [selectedPatient, setSelectedPatient] = useState();

    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    React.useEffect(() => {
      if(isSearchable){
        let active = true;
  
        if (!loading) {
          return undefined;
        }

        if(value.length > 2){
          // fetchResults();
        }
    
        return () => {
          active = false;
        };
      }
    }, [loading,value]);

    
    // React.useEffect(() => {
      
    //   if(isSearchable){
    //     if (!open) {
    //       setOptions([]);
    //     }

    //   }
    //   else{
    //     setOptions([]);
    //     setOpen(false)
    //   }
    // }, [open]);
  

    const handleClickSearch = ()=>{
      
      if(type === "text" && value.length > 2){
        fetchResults().then(()=>{
          setLoading(false)
          setOpen(true)
        });
      }
      
      // if(type === "number" && value.length > 9){
      //   fetchResults().then(()=>{
      //     setLoading(false)
      //     setOpen(true)
      //   });
      // }
    }

    const fetchResults = async ()=>{
      try{
        setLoading(true);
        let results = await searchFunction(value,searchBy);

        if(results?.length){
          setOptions(results.map(searchOptionMappingFunction));
        }
      }
      catch(err){
   
        setOptions([]);
        // setOpen(false)
      }
    }


 

    return (
      <>
        <Autocomplete
          id="asynchronous-search"
          freeSolo
          disabled={disabled}
          sx={{ width:"100%" }}
          open={open}
          onOpen={() => {
            // setOpen(true);
          }}
          onClose={() => {
            // setOpen(false);
          }}
          inputValue={value}
          onInputChange={(event, newInputValue) => {
            
            setValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              return;
            }
            setSelectedOption(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          getOptionLabel={(option) => typeof option === 'string' ? option : option.title}
          options={[]}
          // loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              autoComplete={autoComplete}
              disabled={disabled}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  // Prevents the default action when Enter is pressed
                  event.preventDefault();
                }
              }} 
              helperText={helperText}
              error={error}
              type={type}
              //css inside mui-custom.css file
              className={`mui-textfield-nopadding`}
              InputProps={{
                ...params.InputProps,
                // min: "0", // Minimum value
                max: max, // Maximum value
                // step: "1" ,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {/* {params.InputProps.endAdornment} */}
                    {isSearchable && isPhoneInput && <IconButton disabled={isPhoneInput===true && !disabled? value?.length!==10 : disabled} onClick={handleClickSearch}><SearchIcon /></IconButton>}
                    {isSearchable && isTextInput && <IconButton disabled={isTextInput===true && !disabled? value?.length<3 : disabled} onClick={handleClickSearch}><SearchIcon /></IconButton>}
                  </React.Fragment>
                ),
              }}
              // required={true}
            />
          )}
        />
        <Modal
          open={open}
          onClose={()=>{
            setOpen(false)
            setOptions([])
            setSelectedPatient(undefined)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus={true}
        >
          <Box sx={isMobileScreen ? styleMobile : style} display={'flex'} flexDirection={'column'} gap={1}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <Box
                  width={{ md: 20 }}
                  component="img"
                  alt="add patient"
                  src="/images/ChoosePatientIcon.svg"
                />
                <Typography>Choose Patient</Typography>
              </Box>
              <Box>
                <IconButton onClick={()=>{
                  setOpen(false)
                  setOptions([])
                  setSelectedPatient(undefined)
                }}>
                  <CloseIcon/>
                </IconButton>
              </Box>
            </Box>
            
            <Box>
              <Typography variant='subtitle2'>{`${options.length || 0} Patients found under "${value}"`}</Typography>
            </Box>

            <Box maxHeight={300} overflow={'auto'} display={'flex'} flexDirection={'column'} gap={1} my={2}>
              {options?.map((elem)=>{
                return (
                  <Box display={'flex'} gap={isMobileScreen?1:2} border={`1px solid ${theme.palette.white.dark}`} borderRadius={'10px'} p={1}>
                    <Box>
                      <Radio checked={selectedPatient === elem} value={elem} onChange={()=>{setSelectedPatient(elem)}}/>
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} gap={isMobileScreen? 1:0}>
                      <Typography variant='subtitle2' fontWeight={'600'}>{`${elem?.value?.name} (${elem?.value?.gender.toLowerCase() === 'male' ? 'M' : elem?.value?.gender.toLowerCase() === 'female' ? 'F' :'O'}, ${elem?.value?.age?.no_of_years}y)`}</Typography>
                      <Box display={'flex'} flexDirection={"column"} gap={0.5}>
                        <Box display={'flex'} gap={0.5} alignItems={'center'}>
                          <BadgeOutlinedIcon fontSize="small"/>
                          <Typography variant='caption'>{elem?.value?.patient_hid}</Typography>
                        </Box>
                        <Box display={'flex'} gap={0.5} alignItems={'center'}>
                          <CallIcon fontSize="small"/>
                          <Typography variant='caption'>{`${elem?.value?.country_code} ${elem?.value?.phone}`}</Typography>

                        </Box>
                      </Box>
                      
                    </Box>
                  </Box>
                )
              })}
            </Box>
            
            {options?.length > 0 && (
              <Box display={'flex'} justifyContent={'center'}>
                {/* <Button variant='outlined' onClick={()=>{
                  setOpen(false)
                  setOptions([])
                }}>Cancel</Button> */}
                <Button variant='contained' disabled={!selectedPatient} onClick={()=>{setSelectedOption(selectedPatient); setOpen(false)}}>Select</Button>
              </Box>
            )}
            
          </Box>
        </Modal>
      </>
    );
  };