import { Box, Button, Typography, useTheme } from '@mui/material'
import React from 'react'
import { SUPER_ADMIN_INIT, SUPER_ADMIN_INIT_BUTTON, SUPER_ADMIN_INIT_HEADING, SUPER_ADMIN_INIT_SUBHEADING } from '../../../../assets/translations/admin'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const SuperAdminINIT = () => {

    const theme = useTheme();

  return (
    <Box width={"100%"} height={{xs:'80vh',md:"100%"}} overflowY={'hidden'} mt={{xs:0,md:0}} display={'flex'} justifyContent={"center"} alignItems={"center"} flexDirection={'column'} gap={1} paddingX={2} boxSizing={"border-box"}>
        <Box
          width={{xs:200 ,md: 250 }}
          component="img"
          alt="add appointment"
          src="/images/superadmininitimg.svg"
        />
        <Typography fontWeight={'bold'} mt={3}>{SUPER_ADMIN_INIT_HEADING['eng']}</Typography>
        <Typography maxWidth={'450px'} textAlign={"center"} color={theme.palette.white.greyText}>{SUPER_ADMIN_INIT_SUBHEADING['eng']}</Typography>

        <Button
            variant='contained'
            startIcon={<AddOutlinedIcon/>}
            sx={{
                marginTop:3,
                borderRadius:'8px',
                paddingY:1
            }}
        >
            <Typography textTransform={'none'}>{SUPER_ADMIN_INIT_BUTTON['eng']}</Typography>
        </Button>
    </Box>
  )
}

export default SuperAdminINIT