import {
  ROUTE_LANDING,
  ROUTE_GET_PRESCRIPTION,
  ROUTE_LOGIN,
  ROUTE_PAGE_NOT_FOUND,
  ROUTE_APPOINTMENTS,
  ROUTE_LOGINOTP,
  ROUTE_FORGOTPASSWORD,
  ROUTE_PRESCRIPTION,
  ROUTE_NEW_PATIENT,
  ROUTE_NEW_APPOINTMENT,
  ROUTE_SUPER_ADMIN_INIT,
  ROUTE_ADMIN_USER_MANAGEMENT,
  ROUTE_ADMIN_CLINIC_MANAGEMENT_INIT,
  ROUTE_SUPER_ADMIN_SETUP,
  ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC,
  ROUTE_ADMIN_CLINIC_MANAGEMENT_CLINIC_DETAILS,
  ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC,
  ROUTE_ADMIN_SINGLE_DOCTOR_VIEW,
  ROUTE_ADMIN_SINGLE_DOCTOR_EDIT,
  ROUTE_ADMIN_LIST_RX_TEMPLATES,
  ROUTE_ADMIN_NEW_RX_TEMPLATE_FORM,
  ROUTE_ADMIN_EDIT_RX_TEMPLATE_FORM,
  ROUTE_ADMIN_EDIT_RX_TEMPLATE_SPECIALITY,
  ROUTE_ADMIN_EDIT_RX_TEMPLATE_DOCTOR,
  ROUTE_ADMIN_CLINIC_MANAGEMENT_EDIT_CLINIC,
  ROUTE_ADMIN_SLOT_MANAGEMENT,
  ROUTE_ARTICLES,
  ROUTE_ARTICLES_DETAILS,
  ROUTE_ALL_INVOICES,
  ROUTE_CREATE_INVOICE,
  ROUTE_ADMIN_CLINICAL_MATRIX
} from "../shared/routes/route.constants";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import NotFoundPage from "../pages/not-found";
import PrivateRoutes from "../shared/Utils/private-routes";
import LandingPage from "../pages/landing-page";
import LoginPage from "../pages/components/auth/login.page";
import ForgotPasswordPage from "../pages/components/auth/ForgotPassword.page";
import LoginOtp from "../pages/components/auth/login-otp.page";
import AppointmentsPage from "../pages/appointments-page";
import PrescriptionPage from "../pages/prescription-page";
import { Amplify } from "aws-amplify";
import awsmobile from "../aws-exports";
import DashboardLayout from "../shared/layouts/DashboardLayout";
import { Component, useEffect, useState } from "react";
import NewPatient from "../pages/components/appointment/NewPatient";
import NewAppointment from "../pages/components/appointment/NewAppointment";
import SuperAdminINIT from "../pages/components/admin/superadminregistration/SuperAdminINIT";
import UserManagment from "../pages/components/admin/usermanagement/UserManagment";
import ClinicManagementInit from "../pages/components/admin/clinicmanagement/ClinicManagementInit";
import SuperAdminSetup from "../pages/components/admin/superadmin/SuperAdminSetup";
import NewClinic from "../pages/components/admin/clinicmanagement/NewClinic";
import ClinicDetails from "../pages/components/admin/clinicmanagement/ClinicDetails";
import AllClinic from "../pages/components/admin/clinicmanagement/AllClinics";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/reducers/auth.slice";
import SlotManagement from "../pages/components/admin/slotmanagement/SlotManagement";
import EditClinic from "../pages/components/admin/clinicmanagement/EditClinic";

import RxBuilder from "../pages/components/admin/rxbuilder/RxBuilder";
import RxList from "../pages/components/admin/rxbuilder/RxList";
import RxSpeciality from "../pages/components/admin/rxbuilder/RxSpeciality";
import RxDoctor from "../pages/components/admin/rxbuilder/RxDoctor";
import DoctorProfileView from "../pages/components/admin/usermanagement/doctor/view";
import MyPatient from "../pages/components/my-patient/patient";
import { ROUTE_MY_PATIENTS } from './../shared/routes/route.constants';
import { getFeatureValue, useAuthorization } from "../shared/Utils/useAuthorization";
import NoPermission from "../pages/components/no-permission";
import Articles from "../pages/components/articles/articles";
import ArticlesDetails from "../pages/components/articles/article-details";
import AllInvoice from "../pages/components/Invoice/AllInvoice";
import CreateInvoice from "../pages/components/Invoice/CreateInvoice";
import ClinicalMatrix from "../pages/components/admin/clinicalmatrix/dashboard";
import OpenPrescription from "../pages/components/prescription/utils/OpenPrescription";
Amplify.configure(awsmobile);
Amplify.Logger.LOG_LEVEL = "DEBUG";



const AppRoutes = () => {
  const [auth, setAuth] = useState(false);

  const userFeatures = useAuthorization();

  const ROUTES: any[] = [
    {
      name: "Appointment Page",
      route: ROUTE_APPOINTMENTS,
      component: <AppointmentsPage />,
    },
    {
      name: "Prescription Page",
      route: ROUTE_PRESCRIPTION(":appointment_id"),
      // component: userFeatures.includes(getFeatureValue('PRESCRIPTION')) ? <PrescriptionPage /> : <NoPermission />,
      component:<PrescriptionPage />,
    },
    {
      name:"New Patient",
      route:ROUTE_NEW_PATIENT,
      component: userFeatures.includes(getFeatureValue('REGISTER_USER')) ? <NewPatient/> : <NoPermission />
    },
    {
      name:"New Appointment",
      route:ROUTE_NEW_APPOINTMENT,
      component: userFeatures.includes(getFeatureValue('CREATE_APPOINTMENT')) ? <NewAppointment/> : <NoPermission />
    },
    {
      name:"Super Admin Initial Screen",
      route:ROUTE_SUPER_ADMIN_INIT,
      component:<SuperAdminINIT/>
    },
    {
      name:"Super Admin Setup Screen",
      route:ROUTE_SUPER_ADMIN_SETUP,
      component:<SuperAdminSetup/>
    },
    {
      name:"User Management",
      route:ROUTE_ADMIN_USER_MANAGEMENT,
      component:<UserManagment/>
    },
    {
      name:"Doctor Profile",
      route:ROUTE_ADMIN_SINGLE_DOCTOR_VIEW(":clinic_id",":doctor_id"),
      component:<DoctorProfileView/>
    },
    {
      name:"Doctor Profile Edit",
      route:ROUTE_ADMIN_SINGLE_DOCTOR_EDIT(":clinic_id",":doctor_id"),
      component:<UserManagment/>
    },
    {
      name:"Clinic Management Initial",
      route:ROUTE_ADMIN_CLINIC_MANAGEMENT_INIT,
      component:<ClinicManagementInit/>
    },
    {
      name:"Clinic Management New Clinic",
      route:ROUTE_ADMIN_CLINIC_MANAGEMENT_NEW_CLINIC,
      component: userFeatures.includes(getFeatureValue('CLINIC_CREATION')) ? <NewClinic/> : <NoPermission />
    },
    {
      name:"Clinic Management All Clinics",
      route:ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC,
      component:<AllClinic/>
    },
    {
      name:"Clinic Management Clinic Details",
      route:ROUTE_ADMIN_CLINIC_MANAGEMENT_CLINIC_DETAILS,
      component:<ClinicDetails/>
    },
    {
      name:"Clinic Management Edit Clinic",
      route:ROUTE_ADMIN_CLINIC_MANAGEMENT_EDIT_CLINIC,
      component: userFeatures.includes(getFeatureValue('CLINIC_UPDATION')) ? <EditClinic/> : <NoPermission />
    },
    {
      name:"Slot Management",
      route:ROUTE_ADMIN_SLOT_MANAGEMENT,
      component:<SlotManagement/>
    },
    {
      name:"List RX Templates",
      route:ROUTE_ADMIN_LIST_RX_TEMPLATES,
      component:userFeatures.includes(getFeatureValue('TEMPLATE_BUILDER')) ? <RxList/> : <NoPermission />
    },
    {
      name:"New RX Template",
      route:ROUTE_ADMIN_NEW_RX_TEMPLATE_FORM,
      component:userFeatures.includes(getFeatureValue('TEMPLATE_BUILDER')) ? <RxBuilder/> : <NoPermission />
    },
    {
      name:"Edit RX Template",
      route:ROUTE_ADMIN_EDIT_RX_TEMPLATE_FORM(":template_id"),
      component:userFeatures.includes(getFeatureValue('TEMPLATE_BUILDER')) ? <RxBuilder/> : <NoPermission />
    },
    {
      name:"Edit RX Template Speciality",
      route:ROUTE_ADMIN_EDIT_RX_TEMPLATE_SPECIALITY(":template_id"),
      component:userFeatures.includes(getFeatureValue('TEMPLATE_BUILDER')) ? <RxSpeciality/> : <NoPermission />
    },
    {
      name:"Edit RX Template Doctor",
      route:ROUTE_ADMIN_EDIT_RX_TEMPLATE_DOCTOR(":template_id"),
      component:userFeatures.includes(getFeatureValue('TEMPLATE_BUILDER')) ? <RxDoctor/> : <NoPermission />
    },
    {
      name:"Clinical Matrix",
      route:ROUTE_ADMIN_CLINICAL_MATRIX,
      component:userFeatures.includes(getFeatureValue('KPI_Dashboard')) ? <ClinicalMatrix/> : <NoPermission />
    },
    {
      name:"My Patient",
      route:ROUTE_MY_PATIENTS,
      component: userFeatures.includes(getFeatureValue('MY_PATIENTS')) ? <MyPatient /> : <NoPermission />
    },
    {
      name:"Articles",
      route:ROUTE_ARTICLES,
      component: userFeatures.includes(getFeatureValue('ARTICLES')) ? <Articles /> : <NoPermission />
    },
    {
      name:"Article-Details",
      route:ROUTE_ARTICLES_DETAILS,
      component: userFeatures.includes(getFeatureValue('ARTICLES-DETAILS')) ? <ArticlesDetails /> : <NoPermission />
    },
    {
      name:"All Invoices",
      route:ROUTE_ALL_INVOICES,
      // component: userFeatures.includes(getFeatureValue('ARTICLES-DETAILS')) ? <ArticlesDetails /> : <NoPermission />
      component: <AllInvoice />
    },
    {
      name:"Create Invoice",
      route:ROUTE_CREATE_INVOICE,
      // component: userFeatures.includes(getFeatureValue('ARTICLES-DETAILS')) ? <ArticlesDetails /> : <NoPermission />
      component: <CreateInvoice />
    }
  ];

  // useEffect(() => {
  //   if (auth) {
  //     navigate(ROUTE_APPOINTMENTS, { replace: true });
  //   }
  // }, [auth, navigate]);

 

  useEffect(() => {
    const token = localStorage.getItem("emrToken") || false;
    setAuth(!!token);
  });

  const AppRoutesPermitted = ROUTES;

  return (
    <Routes>
      <Route
        element={
          <DashboardLayout>
            <PrivateRoutes />
          </DashboardLayout>
        }
      >
        {AppRoutesPermitted.map((route: any, i: number) => (
          <Route key={i} path={route.route} element={route.component} />
        ))}
        <Route path={ROUTE_PAGE_NOT_FOUND} element={<NotFoundPage />} />
        <Route path={"*"} element={<NotFoundPage />} />
      </Route>

      {/* Conditional Routes */}

      <Route
        path={ROUTE_GET_PRESCRIPTION}
        element={
          <OpenPrescription />
        }
      />
      <Route
        path={ROUTE_LOGIN}
        element={
          // auth ? <Navigate to={ROUTE_APPOINTMENTS} replace /> : <LoginPage />
          <LoginPage />
        }
      />
      <Route
        path={ROUTE_LOGINOTP}
        element={
          auth ? <Navigate to={ROUTE_APPOINTMENTS} replace /> : <LoginOtp />
        }
      />
      <Route
        path={ROUTE_FORGOTPASSWORD}
        element={
          auth ? (
            <Navigate to={ROUTE_APPOINTMENTS} replace />
          ) : (
            <ForgotPasswordPage />
          )
        }
      />
      <Route
        path={ROUTE_LANDING}
        element={
          auth ? <Navigate to={ROUTE_APPOINTMENTS} replace /> : <LandingPage />
        }
      />
    </Routes>
  );
};
export default AppRoutes;
