const DiagnosticsIcon = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="32"
          height="32"
          rx="16"
          fill="#D89E09"
          fill-opacity="0.1"
        />
        <path d="M9.5 7L19 7.5L23 11.5L22.5 25H9.5V7Z" fill="#D89E09" />
        <path
          d="M23.6451 11.0134L18.8415 6.20982C18.7076 6.07589 18.5268 6 18.3371 6H8.85491C8.45982 6 8.14062 6.3192 8.14062 6.71429V25.2857C8.14062 25.6808 8.45982 26 8.85491 26H23.1406C23.5357 26 23.8549 25.6808 23.8549 25.2857V11.5201C23.8549 11.3304 23.779 11.1473 23.6451 11.0134ZM22.2076 11.8482H18.0067V7.64732L22.2076 11.8482ZM22.2478 24.3929H9.74777V7.60714H16.4888V12.4286C16.4888 12.6772 16.5876 12.9157 16.7634 13.0915C16.9392 13.2673 17.1777 13.3661 17.4263 13.3661H22.2478V24.3929ZM15.8192 18.3661H11.7121C11.6138 18.3661 11.5335 18.4464 11.5335 18.5446V19.6161C11.5335 19.7143 11.6138 19.7946 11.7121 19.7946H15.8192C15.9174 19.7946 15.9978 19.7143 15.9978 19.6161V18.5446C15.9978 18.4464 15.9174 18.3661 15.8192 18.3661ZM11.5335 15.5089V16.5804C11.5335 16.6786 11.6138 16.7589 11.7121 16.7589H20.2835C20.3817 16.7589 20.4621 16.6786 20.4621 16.5804V15.5089C20.4621 15.4107 20.3817 15.3304 20.2835 15.3304H11.7121C11.6138 15.3304 11.5335 15.4107 11.5335 15.5089Z"
          fill="#F3EDDE"
        />
      </svg>
    </>
  );
};
export default DiagnosticsIcon;
