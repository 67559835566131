import MaskedInput from "react-text-mask";
import TextField from "@mui/material/TextField";

function TextMaskCustom(props: any) {
  const { inputRef, ...other } = props;
  // Updated mask: Ten digits without any space or other characters
  const mask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  return <MaskedInput {...other} ref={inputRef} mask={mask} guide={false}/>;
}

export default function FormattedTextField(props: any) {
  return (
    <TextField
      className="custom-text-field"
      {...props}
      InputProps={{
        inputComponent: TextMaskCustom,
        startAdornment: "+91",
        disableUnderline: true,
        classes: {
          underline: "no-underline",
        },
      }}
    />
  );
}
