import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { styled } from "@mui/system";

import RX from "./tabcomponents/RX/RX";
import PatientTimeLine from "./tabcomponents/PatientTimeLine";
import PatientDocuments from "./tabcomponents/Patient-Documents/Patient-documents";
import LastConsultationSection from "./utils/LastConsultSection";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrescriptionDetails, setPastPrescriptions, setToggleShouldShowPastVitals } from "../../../redux/reducers/prescription.slice";
import PastVitalsWrapper from "./tabcomponents/PastVitals/PastVitalsWrapper";
import { sendMessageToNativeApp } from "../../../shared/Utils/utils";
import MobileVideoConsultation from "./utils/MobileVideoConsultation";

export const TabComponentWrapper = styled("div")<{
  height?: string;
}>(({ height }) => ({
  overflowY: "auto",
  height: height || "calc(100vh - 160px)",
}));

interface TabComponentProps {
  children?: ReactNode;
  tabActive: number;
  index: number;
  height?: string;
  isMobileScreen?: boolean;
}

export function TabComponent(props: TabComponentProps) {
  const { children, tabActive, index, height, isMobileScreen, ...other } =
    props;


  const {
    pastVitalsData: {shouldShowPastVitals},
  } = useSelector((state: any) => state.prescription);

  return (
    <TabComponentWrapper
      hidden={tabActive !== index}
      id={`tab-panel-${index}`}
      height={height}
      {...other}
    >
      {tabActive === index && (
        <Box
          sx={{ pr: isMobileScreen ? 0 : 1, pl: isMobileScreen || shouldShowPastVitals ? 0 : 1, pt: shouldShowPastVitals ? 0 : 2, height:shouldShowPastVitals && '100%' }}
        >
          {children}
        </Box>
      )}
    </TabComponentWrapper>
  );
}

export function allyProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    showTimeline: params.get("timeline"),
    showDocument: params.get("document"),
    showLastVist:params.get('lastVisit')
  };
}

export default function RightPanel({ setActiveTab, isMobileScreen, showVideo, setVideo, selectedAppointmentState, activeTab }: any) {
  const dispatch = useDispatch();
  const { showTimeline, showDocument, showLastVist } = getQueryParams();
  const [tabActive, setTabActive] = useState(
    showTimeline ? (isMobileScreen ? 2 : 1) : showDocument ? (isMobileScreen ? 3 : 2) : showLastVist ? (isMobileScreen ? 1 : 0) : 0
  );

  // if any rx section is being edited in the mobile resp. mode hide tabs using below var
  const {
    prescription: { rxData, editRxSecMobile }, pastVitalsData: {shouldShowPastVitals},
  } = useSelector((state: any) => state.prescription);

  const { selectedAppointment } = useSelector(
    (state: any) => state.appointments
  );
  
  const viewTypes = Array.isArray(rxData)
    ? rxData?.map((sec) => sec.view_type)
    : [];


  const handleChange = (_event: any, newValue: number) => {
    setTabActive(newValue);
    setActiveTab(newValue);


    // const tabName = _event?.target?.innerText
    
    if(newValue===2){
      sendMessageToNativeApp('patient_timeline')
      return;
    }
    else if(newValue===3){
      sendMessageToNativeApp('patient_documents')
      return;
    }
  };

  const getPastPrescription = async () => {
    const response = await dispatch(
      fetchPrescriptionDetails({
        params: {
          type: "patient",
          limit: 10,
          id: selectedAppointment?.patient?.patient_id,
        },
      })
    );
    if (response?.payload?.data?.data?.length) {
      dispatch(setPastPrescriptions(response?.payload?.data?.data));
    } else {
      dispatch(setPastPrescriptions([]));
    }
  };

  useEffect(()=>{
   getPastPrescription();
   dispatch(setToggleShouldShowPastVitals(false));
  }, [])

  useEffect(()=>{
    if(activeTab === 1){
      setTabActive(1)
    }
  },[activeTab] )

  return (
    <Box sx={{ width: "100%" }}>
      {!viewTypes.includes(editRxSecMobile) && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: isMobileScreen ? "white" : "#F4F6F9",
            position: "relative",
            zIndex: 1,
          }}
        >
          <Tabs
            value={tabActive}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="RX" {...allyProps(0)} />
            {isMobileScreen && (
              <Tab
                label="Last Consult"
                {...allyProps(isMobileScreen ? 1 : 3)}
              />
            )}
            <Tab
              label="Patient timeline"
              {...allyProps(isMobileScreen ? 2 : 1)}
            />
            <Tab
              label="Patient Documents"
              {...allyProps(isMobileScreen ? 3 : 2)}
            />
          </Tabs>
        </Box>
      )}
      <TabComponent tabActive={tabActive} index={0} height={isMobileScreen ? "100vh" : undefined}>
        {shouldShowPastVitals ? (
          <PastVitalsWrapper selectedAppointment={selectedAppointment} />
        ) : (
          <RX setActiveTab={setActiveTab} />
        )}
      </TabComponent>
      <TabComponent
        tabActive={tabActive}
        index={isMobileScreen ? 2 : 1}
        isMobileScreen={isMobileScreen}
      >
        <PatientTimeLine isMobileScreen={isMobileScreen} />
      </TabComponent>
      <TabComponent tabActive={tabActive} index={isMobileScreen ? 3 : 2}>
        <PatientDocuments isMobileScreen={isMobileScreen} />
      </TabComponent>
      {isMobileScreen && (
        <TabComponent
          tabActive={tabActive}
          index={isMobileScreen ? 1 : 3}
          isMobileScreen={isMobileScreen}
        >
          <MobileVideoConsultation
            showVideo={showVideo}
            setVideo={setVideo}
            selectedAppointmentState={selectedAppointmentState}
          />
          <LastConsultationSection isMobileScreen={isMobileScreen} />
        </TabComponent>
      )}
    </Box>
  );
}
