import { useSelector } from "react-redux"

export const getFeatureValue = (key)=>{

    const features = {
        "REGISTER_USER":'patientregisteration',
        "CREATE_APPOINTMENT":'bookappointment',
        "CONFIRMED_APPOINTMENTS":'confirmedappointment',
        "MY_PATIENTS":'mypatients',
        "TEMPLATE_BUILDER":'tempbuilder',
        "ARTICLES":'articles',
        "ARTICLES-DETAILS":"articles-details",
        "USER_CREATION":'usercreationrights',
        "USER_UPDATION":'userupdaterights',
        "CLINIC_CREATION":'cliniccreationrights',
        "CLINIC_UPDATION":'clinicupdationrights',
        "PRESCRIPTION":'presc',
        "ENABLE_AUTO_APPROVE":'enableautoapprove',
        "KPI_Dashboard":"kpi"
    }

    return features[key]
}

export const useAuthorization = () => {
  const {profile} = useSelector(state=>state.auth)

  const defaultFeatures = {
    doctor:["confirmedappointment","presc","patientregisteration","bookappointment","mypatients", "articles","articles-details"],
    staff:["confirmedappointment","patientregisteration","bookappointment","mypatients","articles", "articles-details"],
    admin:[],
    superadmin:["usercreationrights","userupdaterights","cliniccreationrights","clinicupdationrights"],
  }

  const customFeatures =  profile?.features ? profile?.features?.replaceAll('f:','')?.split(',') || [] : []

  const defaultFeaturesForRole = profile ? profile?.role?.replaceAll('r:','')?.split(',').reduce((acc,curr)=>{
    return acc = defaultFeatures[curr] ? [...acc,...defaultFeatures[curr]] : acc
  },[]) : []

  return [...defaultFeaturesForRole,...customFeatures]
}