import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HTMLAttributes, SyntheticEvent, useEffect, useRef, useState } from "react";
import CardHeader, { renderHeaderButtons } from "../../utils/CardHeader";
import Card from "../../utils/Card";
import { styled } from "@mui/system";
import { CARD_SHADOW } from "../../utils/Constants";
import { DIABETES_ASSESSMENT, REFERRAL } from "../../../../../shared/constants";
import { setEditRxSecMobile } from "../../../../../redux/reducers/prescription.slice";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import DoctorSuggestions from "../../utils/DoctorSuggestions";
import { fetchSuggestions, seperateUrlAndParams } from "../../../../../shared/Utils/utils";
import { useDebounce } from "../../../../../shared/Utils/useDebounce";
import TemplateCard from "../../utils/TemplateCard";

interface SymptomProps {
  id: string;
  name?: string;
  symptom?: string;
  state?: string;
  comment?: string;
  speciality?: string;
  dr_name?: string;
}

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "8px",
    paddingLeft: 0,
    border: "none",
  },
});

const DiabetesAssessment = (props: any) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SymptomProps[]>([]);
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {
      state:'Yes'
    } as SymptomProps,
  });

  const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>(false);
  const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
    useState<boolean>(false);

  const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
  const searchExistingTemplateMobile =
    props?.sectionData?.[0]?.searchExistingTemplateMobile;

  const [searchValue, setSearchValue] = useState("");

  const sectionData = props?.sectionData?.[0]?.rows[0];
  const setTabData = props?.setTabData;
  const isFocused: boolean = props.isFocused || false;
  const symptomArr = sectionData?.master?.symptom?.values || [];
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const autoref = useRef<HTMLDivElement>(null);

  const apiurldata = sectionData?.search?.api;

  const [valUpdateSearch, setValUpdateSearch] = useState("");
  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);
  

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(DIABETES_ASSESSMENT));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === DIABETES_ASSESSMENT
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length !== 0 && searchValue.trim().length >= 3) {
      updateSearchOptions(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (valUpdateSearch.length !== 0) {
      updateSearchOptions(altDebouncedSearch);
    }
  }, [altDebouncedSearch]);

  const handleDialogState = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
      // content :
    });
  };

  const dialogProps = {
    DialogOpen: dialog.open ? dialog.open : false,
    onHandleDialog: handleDialogState,
    headerStyles: { display: "none" },
    PaperPropsStyle: {
      margin: 0,
      width: "100%",
    },
    Layoutstyles: {
      "& .MuiDialog-container": {
        alignItems: "flex-end",
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
      },
    },
    footerStyles: {
      display: "none",
    },
    onHandleSave: () => {
      // not required, custom save option is there
      return;
    },
  };

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === DIABETES_ASSESSMENT) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const removeSymptom = (event: SyntheticEvent) => {
    const elem = event.target as HTMLElement;
    const { id } = elem;
    
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === DIABETES_ASSESSMENT) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (symptom: any) => symptom.id !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([...options, id]);
  };

  const assignAddConditionsMobile = (value: string) => {
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;
    if (!value) return;
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        id,
        name,
        since: "",
        severity: "",
        comment: "",
        state:'',
      },
    });
  };

  const addCondition = async (
    event: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);
    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;
    if (!value) {
      return;
    }

    const newCondition: SymptomProps = {
      id,
      name,
      symptom:"",
      state:"",
      comment: "",
    };
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === DIABETES_ASSESSMENT) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: [...row.value, newCondition],
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
    setSearchValue("");
  };

  const updateCondition = (event: SyntheticEvent, value: string | null) => {
    let newValue: any, newId: any;

    const target =
      (event.target as HTMLInputElement) ||
      (event.target as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName)?.split("-");

    const key = nameArr[nameArr.length - 1];

    const currId = nameArr.reduce((acc, val) => {
      if (val === key) {
        return acc;
      }
      return acc + "-" + val;
    });

    if (key === "name") {
      const optionObj = optionsData.find(
        (optObj: any) => optObj.name === value
      );
      const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
      const name = optionObj?.name ? optionObj.name : value;
      newValue = name;
      newId = id;
    } else {
      (newId = currId), (newValue = value);
    }
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === DIABETES_ASSESSMENT) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.map((condition: any) => {
                    if (condition?.id?.toString() === currId) {
                      return {
                        ...condition,
                        id: newId,
                        [key]: newValue,
                      };
                    }
                    return condition;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });

    setOptions([]);
  };

  const doctorSuggestionProps = {
    sKey: "complaints",
    sHeading: "Suggested conditions",
    headerImg: isMobileScreen
      ? "/images/icon_mobile_resp_suggestion.svg"
      : "/images/icon_dummy.svg",
    handleAdd: (event: any) => {
      const currentTarget = event?.currentTarget;
      const suggestion = currentTarget?.attributes["data-key"]?.nodeValue;
      const clickAction = currentTarget?.attributes["data-action"]?.nodeValue;

      if (!data.some((condition: any) => condition.name === suggestion)) {
        switch (clickAction) {
          case "ADD":
            if (!isMobileScreen) {
              addCondition(null as any, suggestion);
            } else {
              assignAddConditionsMobile(suggestion);
            }
            break;
          case "UPDATE":
            updateCondition(event as any, suggestion);
            break;
          default:
            return;
        }
      }
      if (autoref.current) {
        autoref.current.blur();
      }
    },
  };

  const updateSearchOptions = (value: any) => {
    const trimmedValue = value.trim();
    if (apiurldata && trimmedValue) {
      const { url, query_params } = seperateUrlAndParams(apiurldata);
      fetchSuggestions(
        url,
        {
          ...query_params,
          name: value,
        },
        true // use custom base url
      ).then((response) => {
        const responseArr = response?.data?.data;
        const optionsArr = responseArr?.map((option: any) => option.name);
        if (!optionsArr.some((opt: any) => opt === trimmedValue)) {
          setOptions([trimmedValue, ...optionsArr]);
          setOptionsData([
            {
              id: `custom${Date.now()}`,
              name: trimmedValue,
            },
            ...responseArr,
          ]);
          return;
        }
        setOptions(optionsArr);
        setOptionsData(responseArr);
      });
    }
  };

  const assignAddReferralData = (event: any, value: string) => {
    if (!value) {
      return;
    }
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        speciality: value,
        dr_name: "",
        comment: "",
        state:'',
      },
    });
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        {data.map((datum:any, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
              }}
              key={index}
            >
              {datum?.speciality}
            </Box>
          );
        })}
      </Box>
    );
  };

  const RefferalCardMobile = (Referral: any, index: number) => {
    const { speciality, comment, dr_name,state } = Referral;
    return (
      <Card
        styles={{
          borderRadius: "8px",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
          padding: "0px",
        }}
        key={index}
      >
        <Box
          className="card-header"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px",
          }}
        >
          <Typography
            fontSize="16px"
            fontWeight="700"
            lineHeight="16px"
            color="#494E9D"
          >
            {speciality}
          </Typography>
          <IconButton>
            <Box
              id={speciality}
              aria-label="delete-referral"
              onClick={removeReferral}
              component="img"
              src="/images/DeleteIcon.svg"
            ></Box>
          </IconButton>
        </Box>
        <Divider />
        <Stack sx={{ padding: "12px" }} spacing={2}>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box
              component="img"
              marginRight="4px"
              src="/images/info-icon.svg"
            ></Box>
            <Box>
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="100%"
                marginBottom="8px"
                color="rgba(0, 0, 0, 0.87)"
              >
                Symptom
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="400"
                lineHeight="160%"
                color="#6F6F6F"
              >
                {state}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box
              component="img"
              marginRight="4px"
              src="/images/message-icon.svg"
            ></Box>
            <Box>
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="100%"
                marginBottom="8px"
                color="rgba(0, 0, 0, 0.87)"
              >
                Comments
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="400"
                lineHeight="160%"
                color="#6F6F6F"
              >
                {comment}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Card>
    );
  };

  const removeReferral = (event: SyntheticEvent) => {
    const elem = event.target as HTMLElement;
    const { id } = elem;
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === DIABETES_ASSESSMENT) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (referral: any) => referral.speciality !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([...options, id]);
  };

  const handleButtonClick = async (imageName: string) => {
    if (imageName === "icon_save.svg") {
      if (isMobileScreen) {
        // handleButtonClickMiniTMobile(imageName); // review-code
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === DIABETES_ASSESSMENT) {
              return {
                ...section,
                openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                searchExistingTemplateMobile: false,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenSaveTemplate(!openSaveTemplate);
      setOpenUpdateExistingTemplate(false);
    } else if (imageName === "icon_add_page.svg") {
      if (isMobileScreen) {
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === DIABETES_ASSESSMENT) {
              return {
                ...section,
                openSaveTemplateMobile: false,
                searchExistingTemplateMobile:
                  !section.searchExistingTemplateMobile,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenUpdateExistingTemplate(!serachExistingTemplate);
      setOpenSaveTemplate(false);
    }
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Diabetes Assessment"
          isReadOnly={props?.isReadOnly}
        >
          <CardHeader headerImg="icon_diabetes_assessment.svg" headerTitle="Diabetes Assessment">
            <>
              {renderHeaderButtons(
                ["icon_save.svg", "icon_add_page.svg"],
                handleButtonClick
              )}
            </>
          </CardHeader>
          {data.length !== 0 && (
            <TableContainer sx={{ mb: 2, boxShadow: "none" }} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="all-medical-histories">
                <TableBody>
                  {data.map((referral, index) => {
                    const { id, name, symptom, state, comment } = referral;
                    return (
                      <TableRow key={`complaint-${index}-${symptom}`}>
                        <StyledTableCell sx={{ width: 220 }}>
                          <Typography textAlign={'left'}>{name}</Typography>
                        </StyledTableCell>

                        <StyledTableCell sx={{ width: 220 }}>
                        <FormControl>
                          <RadioGroup
                            row
                            id={`${id}-state`}
                            name={`${id}-state`}
                            value={state || ''}
                          >
                            <FormControlLabel 
                              id={`${id}-state`}
                              name={`${id}-state`}
                              value="Yes"
                              control={<Radio
                                
                                inputProps={
                                  {
                                    onBlur:(event: any) => {
                                      updateCondition(event as any, event.target.value);
                                    },
                                    name:`${id}-state`,
                                    id:`${id}-state`,
                                    onClick:(e:any)=>{
                                      const checked = e.target.checked;
                                      if(checked){
                                        setData((prev: any) => {
                                          return prev.map((elem: any) => {
                                            if (elem.id.toString() === id.toString()) {
                                              return {
                                                ...elem,
                                                state: 'Yes',
                                              };
                                            }
                                            return elem;
                                          });
                                        });
                                      }
                                    }
                                  }
                                }
                                
                              onBlur={(event: any) => {
                                updateCondition(event as any, event.target.value);
                              }}
                            />} label="Yes" />
                            <FormControlLabel 
                              id={`${id}-state`}
                              name={`${id}-state`}
                              value="No" 
                              control={<Radio
                                inputProps={
                                  {
                                    onBlur:(event: any) => {
                                      updateCondition(event as any, event.target.value);
                                    },
                                    name:`${id}-state`,
                                    id:`${id}-state`,
                                    onClick:(e:any)=>{
                                      const checked = e.target.checked;
                                      if(checked){
                                        setData((prev: any) => {
                                          return prev.map((elem: any) => {
                                            if (elem.id.toString() === id.toString()) {
                                              return {
                                                ...elem,
                                                state: 'No',
                                              };
                                            }
                                            return elem;
                                          });
                                        });
                                      }
                                      
                                    }
                                  }
                                }
                                
                            />} label="No" />
                          </RadioGroup>
                        </FormControl>
                        </StyledTableCell>

                        <StyledTableCell>
                          <TextField
                            autoComplete="off"
                            title={comment}
                            value={comment}
                            label="Comments"
                            fullWidth
                            id={`${id}-comment`}
                            name={`${id}-comment`}
                            variant="outlined"
                            onChange={(event: any) => {
                              let value = event.target.value;
                              if (value.length > 250) {
                                value = value.substring(0, 250);
                              }
                              setData((prev: any) => {
                                return prev.map((elem: any) => {
                                  if (elem.id.toString() === id.toString()) {
                                    return {
                                      ...elem,
                                      comment: value,
                                    };
                                  }
                                  return elem;
                                });
                              });
                            }}
                            onBlur={(event: any) => {
                              updateCondition(event as any, event.target.value);
                            }}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{ width: "32px", padding: "0 !important" }}
                          align="right"
                        >
                          <Tooltip title={"Delete"}>
                            <button
                              id={id}
                              onClick={removeSymptom}
                              style={{
                                outline: "none",
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                style={{ pointerEvents: "none" }}
                                src="/images/minus-in-circle.svg"
                                alt="remove-button"
                              />
                            </button>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Autocomplete
            id="search-for-symptoms"
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            // loading={true}
            value={""}
            clearOnBlur
            clearOnEscape
            popupIcon={false}
            clearIcon={false}
            inputValue={searchValue}
            onChange={addCondition}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    value: null,
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for symptoms"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((complaint: any) => complaint.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />

          {openSaveTemplate && (
            <Box position="absolute" top="68px" right="79px" zIndex="1">
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {serachExistingTemplate && (
            <Box position="absolute" top="68px" right="39px" zIndex="1">
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Autocomplete
            id="search-for-symptoms"
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            // loading={true}
            value={""}
            clearOnBlur
            clearOnEscape
            popupIcon={false}
            clearIcon={false}
            inputValue={searchValue}
            onChange={(event, value) => {
              assignAddReferralData(event, value);
            }}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    value: null,
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for symptoms"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((complaint: any) => complaint.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          <Box marginTop="16px">
            {data.length !== 0 &&
              data.map((referral, index) => {
                return (
                  <Box
                    component="div"
                    onClick={(_event: any) => {
                      if (_event.target.ariaLabel === "delete-referral") {
                        return;
                      }
                      setDialog({
                        ...dialog,
                        open: true,
                        mode: "UPDATE",
                        content: referral,
                      });
                    }}
                    key={index}
                  >
                    {RefferalCardMobile(referral, index)}
                  </Box>
                );
              })}
          </Box>
          {openSaveTemplateMobile && (
            <Box sx={SaveTemplateStyle}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {searchExistingTemplateMobile && (
            <Box sx={AddMiniTemplateStyle}>
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
      {isMobileScreen && (
        <CustomizedDialogs {...(dialogProps as any)}>
          <Box>
            <Box className="header" sx={{ display: "flex", padding: "12px" }}>
              <Box
                component="img"
                sx={{ marginRight: 1.5 }}
                src="/images/icon_diabetes_assessment.svg"
              />
              <Box className="text-content" width="80%">
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  Diabetes Assessment
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="100%"
                  color="#494E9D"
                >
                  {dialog.content.speciality}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box className="card-content" padding={2}>
              <Stack spacing={2}>
                <FormControl>
                  <RadioGroup
                    row
                    id={`${dialog.content.speciality}-state`}
                    name={`${dialog.content.speciality}-state`}
                    value={dialog.content?.state || ''}
                  >
                    <FormControlLabel 
                      id={`${dialog.content.speciality}-state`}
                      name={`${dialog.content.speciality}-state`}
                      value="Yes"
                      control={<Radio
                        
                        inputProps={
                          {
                            // onBlur:(event: any) => {
                            //   updateCondition(event as any, event.target.value);
                            // },
                            name:`${dialog.content.speciality}-state`,
                            id:`${dialog.content.speciality}-state`,
                            onClick:(e:any)=>{
                              const checked = e.target.checked;
                              if(checked){
                                // setData((prev: any) => {
                                //   return prev.map((elem: any) => {
                                //     if (elem.id.toString() === dialog.content.speciality.toString()) {
                                //       return {
                                //         ...elem,
                                //         state: 'Yes',
                                //       };
                                //     }
                                //     return elem;
                                //   });
                                // });
                                setDialog({
                                  ...dialog,
                                  content: {
                                    ...dialog.content,
                                    state: 'Yes',
                                  },
                                });
                              }
                            }
                          }
                        }
                        
                      // onBlur={(event: any) => {
                      //   updateCondition(event as any, event.target.value);
                      // }}
                    />} label="Yes" />
                    <FormControlLabel 
                      id={`${dialog.content.speciality}-state`}
                      name={`${dialog.content.speciality}-state`}
                      value="No" 
                      control={<Radio
                        inputProps={
                          {
                            // onBlur:(event: any) => {
                            //   updateCondition(event as any, event.target.value);
                            // },
                            name:`${dialog.content.speciality}-state`,
                            id:`${dialog.content.speciality}-state`,
                            onClick:(e:any)=>{
                              const checked = e.target.checked;
                              if(checked){
                                // setData((prev: any) => {
                                //   return prev.map((elem: any) => {
                                //     if (elem.id.toString() === dialog.content.speciality.toString()) {
                                //       return {
                                //         ...elem,
                                //         state: 'No',
                                //       };
                                //     }
                                //     return elem;
                                //   });
                                // });
                                setDialog({
                                  ...dialog,
                                  content: {
                                    ...dialog.content,
                                    state: 'No',
                                  },
                                });
                              }
                              
                            }
                          }
                        }
                        
                    />} label="No" />
                  </RadioGroup>
                </FormControl>

                <TextField
                  autoComplete="off"
                  title={dialog.content?.comment}
                  value={dialog.content?.comment}
                  label="Comments"
                  fullWidth
                  id={`${dialog.content.speciality}-comment`}
                  name={`${dialog.content.speciality}-comment`}
                  variant="outlined"
                  onChange={(event: any) => {
                    let value = event.target.value;
                    if (value.length > 250) {
                      value = value.substring(0, 250);
                    }
                    // setData((prev: any) => {
                    //   return prev.map((elem: any) => {
                    //     if (elem.id.toString() === dialog.content.speciality.toString()) {
                    //       return {
                    //         ...elem,
                    //         comment: value,
                    //       };
                    //     }
                    //     return elem;
                    //   });
                    // });
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        comment: value,
                      },
                    });
                  }}
                  // onBlur={(event: any) => {
                  //   updateCondition(event as any, event.target.value);
                  // }}
                />
              </Stack>
            </Box>
            <Box padding={2}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none !important",
                  borderRadius: "6px",
                }}
                fullWidth
                onClick={() => {
                  if (dialog.mode === "ADD") {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === DIABETES_ASSESSMENT) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: [...row.value, dialog.content],
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setSearchValue("");
                  }
                  if (dialog.mode === "UPDATE") {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === DIABETES_ASSESSMENT) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: row.value.map((val: any) => {
                                      if (val.id === dialog.content.id) {
                                        return {
                                          ...dialog.content,
                                        };
                                      }
                                      return val;
                                    }),
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                  }
                }}
              >
                {dialog.mode === "ADD" ? `Add` : `Update`} Diabetes Assessment
              </Button>
            </Box>
          </Box>
        </CustomizedDialogs>
      )}
    </>
  )
}

export default DiabetesAssessment