export const SUPER_ADMIN_INIT_HEADING = {
    eng:'Create a Super Admin Account'
}
export const SUPER_ADMIN_INIT_SUBHEADING = {
    eng:`To begin use of the SVAAS EMR Interface begin by creating \n a Super Admin Account`
}
export const SUPER_ADMIN_INIT_BUTTON = {
    eng:`Add Super Admin`
}
export const ADMIN_CANCEL = {
    eng:`Cancel`
}
export const ADMIN_ADD = {
    eng:`Add`
}
export const ADMIN_NEW_USER = {
    eng:`New User`
}
export const ADMIN_FEATURES = {
    eng:`Features`
}
export const ADMIN_TAB_MANAGE_EXISTING_USERS = {
    eng:`Manage Existing Users`
}
export const ADMIN_TAB_ADD_NEW_USER = {
    eng:`Add New User`
}
export const ADMIN_TAB_EDIT_USER = {
    eng:`Edit User`
}
export const ADMIN_ADD_NEW_USERS = {
    eng:`Add New Users`
}
export const ADMIN_ADD_NEW_CLINICS = {
    eng:`Add New Clinics`
}
export const ADMIN_FIRST_NAME = {
    eng:`First Name`
}
export const ADMIN_PHONE_NUMBER = {
    eng:`Phone Number`
}
export const ADMIN_EMAIL = {
    eng:`Email`
}
export const ADMIN_ROLE = {
    eng:`Role`
}
export const ADMIN_SUPER_ADMIN = {
    eng:`Super Admin`
}
export const ADMIN_CREATE_NEW_USERS = {
    eng:`Create New Users`
}
export const ADMIN_LETS_GET_STARTED = {
    eng:`Let’s Get Started`
}
export const ADMIN_LETS_GET_STARTED_SUB = {
    eng:`No Clinic is added yet. You can add all the clinics you visit here.`
}
export const ADMIN_ADD_CLINIC = {
    eng:`Add Clinic`
}
export const ADMIN_ADD_CLINIC_INIT_HEADING = {
    eng:`You can begin by adding Your Clinic followed by Users `
}
export const ADMIN_SUPER_ADMIN_SETUP_SUB = {
    eng:`You can create new users accounts for Doctors, Staff, Nurse etc and assign the system access `
}
export const ADMIN_CREATE_NEW_CLINIC_ACCOUNT = {
    eng:`Create a New Clinic Account`
}
export const ADMIN_CREATE_NEW_CLINIC_ACCOUNT_SUB = {
    eng:`To begin use of the SVAAS EMR start by creating an Clinic Account `
}
export const ADMIN_CREATE_CLINIC = {
    eng:`Create Clinic`
}
export const ADMIN_CREATE_USERS = {
    eng:`Create Users`
}
export const ADMIN_SUBMIT = {
    eng:`Submit`
}
export const ADMIN_CLINIC_DETAILS = {
    eng:`CLINIC DETAILS`
}
export const ADMIN_VIEW_ALL_CLINIC = {
    eng:`View All Clinics`
}
export const ADMIN_ALL_CLINIC = {
    eng:`All Clinics`
}
export const ADMIN_ADD_LOGO = {
    eng:`Add Logo`
}
export const ADMIN_UPLOAD_IMAGE = {
    eng:`Upload from gallery`
}
export const ADMIN_ADD_CLINIC_IMAGES = {
    eng:`Add Clinic Images`
}
export const ADMIN_COUNTRY = {
    eng:`Country`
}
export const ADMIN_STATE = {
    eng:`State`
}
export const ADMIN_CITY= {
    eng:`City`
}
export const ADMIN_PINCODE= {
    eng:`Pincode`
}
export const ADMIN_ADDRESS= {
    eng:`Address`
}
export const ADMIN_ACCOUNT_FOR= {
    eng:`Account For`
}
export const ADMIN_CLINIC_NAME= {
    eng:`Clinic Name`
}
export const ADMIN_CLINIC_CONTACT_NUMBER= {
    eng:`Clinic Contact Number`
}
export const ADMIN_CLINIC_SPECIALIZATION= {
    eng:`Clinic Specialization`
}
export const ADMIN_MAX_USER_LIMIT= {
    eng:`Max User Limit`
}
export const ADMIN_LINK_GOOGLE_BUSINESS= {
    eng:`Link Google Business`
}
export const ADMIN_PERSONAL_WEBSITE_URL= {
    eng:`Personal Website URL`
}
export const ADMIN_SERVICE= {
    eng:`Service`
}
export const ADMIN_HEALTHCARE_FACILITY_REGISTRY= {
    eng:`Healthcare Facility Registry`
}
export const ADMIN_HOW_TO_CREATE= {
    eng:`How to create`
}
export const ADMIN_HEALTH_FACILITY_REGISTRY= {
    eng:`Health Facility Registry?`
}
export const ADMIN_ABDM_HEALTH_FACILITY_REGISTRY= {
    eng:`ABDM Health Facility Registry`
}
export const ADMIN_ADD_TO_INDIAS_DIGITAL_ECOSYSTEM= {
    eng:`Add to India's Digital Ecosystem`
}
export const ADMIN_REGISTER_NOW= {
    eng:`Register Now`
}
export const ADMIN_LINK_NOW= {
    eng:`Link Now`
}
export const ADMIN_WATCH_VIDEO= {
    eng:`Watch Video`
}
export const ADMIN_EDIT= {
    eng:`Edit`
}
export const ADMIN_NEW_CLINIC= {
    eng:`New Clinic`
}
export const ADMIN_SERVICES= {
    eng:`Services`
}
export const ADMIN_TIMINGS= {
    eng:`Timings`
}
export const ADMIN_DIRECTIONS_TO_CLINIC= {
    eng:`Directions to Clinic`
}
export const ADMIN_SEARCH_BY_NAME= {
    eng:`Search by name`
}
export const ADMIN_ALL_USERS= {
    eng:`ALL USERS`
}
export const ADMIN_DOCTORS= {
    eng:`DOCTORS`
}
export const ADMIN_ADMIN= {
    eng:`ADMIN`
}
export const ADMIN_NURSE= {
    eng:`Nurse`
}
export const ADMIN_HOME= {
    eng:`Home`
}
export const ADMIN_DELETE= {
    eng:`Delete`
}
export const ADMIN_SELECT_ROLE= {
    eng:`Please select a role`
}
export const ADMIN_TAG_USER_TO_DOCTOR= {
    eng:`Tag user to doctor`
}
export const ADMIN_SEARCH_DOCTOR= {
    eng:`Search Doctor`
}
export const ADMIN_CONSULTATION_DETAILS= {
    eng:`Consultation Details`
}
export const ADMIN_CONSULTATION_DURATION= {
    eng:`Consultation Duration`
}
export const ADMIN_FREE_FOLLOW_UP_NOS= {
    eng:`Free Follow Up Nos.`
}
export const ADMIN_FREE_FOLLOW_UP_DURATION= {
    eng:`Free Follow Up Duration (Days)`
}
export const ADMIN_ADD_SERVICE= {
    eng:`Add Services`
}
export const ADMIN_CONSULTATION= {
    eng:`Consultation`
}
export const ADMIN_DESCRIPTION= {
    eng:`Description`
}
export const ADMIN_TYPE= {
    eng:`Type`
}
export const ADMIN_PRICE= {
    eng:`Price`
}
export const ADMIN_SELECT_DAY_AND_TIME= {
    eng:`Select Day And Time`
}
export const ADMIN_MORNING= {
    eng:`MORNING`
}
export const ADMIN_AFTERNOON= {
    eng:`AFTERNOON`
}
export const ADMIN_EVENING= {
    eng:`EVENING`
}
export const ADMIN_WEEKOFFS= {
    eng:`WEEKOFFS`
}
export const ADMIN_DAYS= {
    eng:`DAYS`
}
export const ADMIN_MARK_A_LEAVE= {
    eng:`Mark a Leave`
}
export const ADMIN_MARK_A_LEAVE_CAPTION= {
    eng:`Specify days of leaves and reasons, mention alternate doctor reference and emergency contact number`
}
export const ADMIN_MARK_A_HOLIDAY= {
    eng:`Mark a Holiday`
}
export const ADMIN_LEAVES_THIS_WEEK= {
    eng:`Leaves`
}
export const ADMIN_COPY_DATE_CAPTION= {
    eng:`Select Days to copy same timings`
}
export const ADMIN_APPLY= {
    eng:`Apply`
}

