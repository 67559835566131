import { CSSProperties, ReactNode, useState } from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { components } from "react-big-calendar";

export type Props = {
  children: ReactNode;
  title?: string;
  headerStyles?: CSSProperties;
  Layoutstyles?: any;
  PaperPropsStyle?: CSSProperties;
  Buttonstyles?: CSSProperties;
  CloseButtonStyles?: CSSProperties;
  DialogOpen?: boolean;
  onHandleDialog?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  footerStyles?: CSSProperties;
  footerCancel?: string;
  footerSave?: string;
  onHandleSave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  fullWidth?: boolean;
  maxWidth?: DialogProps["maxWidth"];
  Subtitle?: string;
  subTitleStyles?: CSSProperties;
  ContainerStyles?: CSSProperties;
  isMobileScreen?: any;
  buttonDisable?:boolean
  onHandleReset?:any
};
export default function CustomizedDialogs({
  children,
  title,
  Layoutstyles,
  PaperPropsStyle,
  headerStyles,
  DialogOpen = false,
  onHandleDialog,
  footerStyles,
  footerCancel,
  footerSave,
  onHandleSave,
  fullWidth,
  maxWidth = "sm", // xs , sm  ,  md , lg , xl
  Buttonstyles,
  Subtitle,
  CloseButtonStyles,
  subTitleStyles,
  ContainerStyles,
  isMobileScreen,
  buttonDisable,
  onHandleReset
}: Props) {
  return (
    <>
      <Dialog
        sx={{ ...Layoutstyles }}
        open={DialogOpen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={onHandleDialog}
        PaperProps={{
          sx: PaperPropsStyle,
        }}
      >
        <DialogTitle sx={{ ...headerStyles }}>
          {title}
          {!isMobileScreen && (
            <small style={{ ...subTitleStyles }}>{Subtitle}</small>
          )}
        </DialogTitle>
        {isMobileScreen && (
          <small style={{ ...subTitleStyles }}>{Subtitle}</small>
        )}
        <IconButton
          aria-label="close"
          onClick={onHandleDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            ...CloseButtonStyles,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>{children}</Box>
        </DialogContent>
        <DialogActions sx={{ ...footerStyles }}>
          <Button
            variant="outlined"
            disabled={buttonDisable}
            sx={{ textTransform: "none !important", ...Buttonstyles }}
            onClick={onHandleReset ? onHandleReset : onHandleDialog}
          >
            {footerCancel ? footerCancel : "Cancel"}
          </Button>
          <Button
            variant="contained"
            disabled={buttonDisable}
            sx={{ textTransform: "none !important", ...Buttonstyles }}
            onClick={onHandleSave}
          >
            {footerSave ? footerSave : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
