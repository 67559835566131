/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Divider, FormControlLabel, Radio, RadioGroup, Skeleton, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import Card from '../../../utils/Card'
import { CARD_SHADOW } from '../../../utils/Constants'
import CardHeader from '../../../utils/CardHeader'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'


interface GravidaProps {
    type: string;
    weeks: string;
    dc: string;
    comments: string;
  }
  
  const EditorModules = {
    toolbar: [
      ["bold", "italic", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  
  const initState = {
    type: "spontaneous",
    weeks: "",
    dc: "yes",
    comments: "",
  }

const Abortion = (props:any) => {

    const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const isFocused: boolean = props.isFocused || false;
  const sectionData = props?.sectionData?.[0]?.rows[0];

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
        ...state,
        ...action
    }
},initState)

  const { setTabData } = props;
//   const {
//     prescription: { instantRxData },
//   } = useSelector((state: any) => state.prescription);

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };


  useEffect(()=>{
    console.log("state",state)
  },[state])

  useEffect(() => {
    if (sectionData && sectionData.value[0]) {
      // setFindingsValue(findings);
      setLoading(false);
    }
  }, [sectionData]);


  const renderHeaderButtons = ()=>{

    const buttons = [
        {
            name:'Add',
            icon:'icon_add_rx.svg',
            onClick:()=>{return}
        },
        {
            name:'Copy',
            icon:'icon_copy_rx.svg',
            onClick:()=>{return}
        },
        {
            name:'Delete',
            icon:'icon_delete_rx.svg',
            onClick:()=>{return}
        },
    ]

    

    return (
        <>
            {buttons.map((elem,index)=>{
                return (
                    <button
                        key={elem.name}
                        style={{
                            outline: "none",
                            background: "none",
                            backgroundColor:theme.palette.primary.light,
                            padding: 8,
                            // marginRight: index !== imgs.length - 1 ? 16 : 0,
                            border: 0,
                            borderRadius:'50%',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}
                    >
                        <Tooltip
                            title={elem.name}
                        >
                            <img
                                onClick={elem.onClick}
                                src={`/images/${elem.icon}`}
                                alt={elem.icon}
                            />
                        </Tooltip>
                    </button>
                )
            })}
            
        </>
    )
    
  }

  const validateInput = (value:any,type:string)=>{
    switch(type){
        case 'weeks':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'comments':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
  }



  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Abortion"
        >
          <CardHeader
            headerImg=""
            headerTitle="Abortion"
          >
            <Box display={'flex'} gap={1}>
                {renderHeaderButtons()}
            </Box>
            
          </CardHeader>

          <Box display={'flex'} flexDirection={'column'} gap={2}>
            
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <RadioGroup
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="type"
                    row
                    value={state.type}
                    onClick={(e:any)=>{
                        if(e?.target?.value){
                          setState({type:e.target.value})
                        }
                    }}
                >
                    <FormControlLabel value="spontaneous" control={<Radio />} label="Spontaneous" />
                    <FormControlLabel value="mtp" control={<Radio />} label="MTP" />
                    <FormControlLabel value="missed" control={<Radio />} label="Missed" />
                </RadioGroup>
                <TextField
                    label={'Weeks'}
                    value={state.weeks}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'weeks')){
                            setState({
                                weeks:e.target.value
                            })
                        }
                    }}
                    sx={{flex:1}}
                />
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <Box display={'flex'} alignItems={'center'} gap={4} width={'320px'}>
                    <Typography>D&C</Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="dc"
                        row
                        value={state.dc}
                        onClick={(e:any)=>{
                            if(e?.target?.value){
                              setState({dc:e.target.value})
                            }
                        }}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Box>
                <TextField
                    label={'Comments'}
                    value={state.comments}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'comments')){
                            setState({
                                comments:e.target.value
                            })
                        }
                    }}
                    sx={{flex:1}}
                />
            </Box>
            
          </Box>
          

          
        </Card>
      )}
      
    </>
  )
}

export default Abortion