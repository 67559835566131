import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  createFilterOptions,
  Typography,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  HTMLAttributes,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { getDurationOptions } from "../../../../../../shared/Utils/utilities";
import {
  fetchSuggestions,
  seperateUrlAndParams,
} from "../../../../../../shared/Utils/utils";
import { useDebounce } from "../../../../../../shared/Utils/useDebounce";
import { styled } from "@mui/system";
import {
  BASE_API_URL,
  MEDICAL_HISTORY,
  PATIENT_HISTORY,
} from "../../../../../../shared/constants";
import DoctorSuggestions from "../../../utils/DoctorSuggestions";
import { useSelector } from "react-redux";
import CustomizedDialogs from "../../../../../../shared/Dialog/Dialog";
import Card from "../../../utils/Card";
import CloseIcon from "@mui/icons-material/Close";

interface MedicalHistoryProps {
  id: string;
  name: string;
  status: string;
  when?: string;
  since?: string;
}

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "8px",
    paddingLeft: 0,
    border: "none",
  },
});

const CustomDropDownPaper = styled(Paper)({
  minWidth: 900,
});

export default function MedicalHistory(props: any) {
  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [historyDurationOptions, setHistoryDurationOptions] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MedicalHistoryProps[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [valUpdateSearch, setValUpdateSearch] = useState("");
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {} as MedicalHistoryProps,
  });

  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);

  const sectionData = props?.sectionData && props?.sectionData?.[0];
  const setTabData = props?.setTabData;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const statusMaster = sectionData?.master?.status?.values || [];

  const autoref = useRef<HTMLDivElement>(null);

  const apiurldata = sectionData?.search?.api;

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      setLoading(false);
    }
  }, [sectionData]);

  const updateSearchOptions = (value: any) => {
    if (apiurldata && value) {
      const { url, query_params } = seperateUrlAndParams(apiurldata);
      fetchSuggestions(
        url,
        {
          ...query_params,
          name: value,
        },
        true // use custom base url
      ).then((response) => {
        const responseArr = response?.data?.data;
        const optionsArr = responseArr?.map((option: any) => option.name);
        if (!optionsArr.some((opt: any) => opt === value)) {
          setOptions([value, ...optionsArr]);
          setOptionsData([
            {
              id: `custom${Date.now()}`,
              name: value,
            },
            ...responseArr,
          ]);
          return;
        }
        setOptions(optionsArr);
        setOptionsData(responseArr);
      });
    }
  };
  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length !== 0 && searchValue.trim().length >= 3) {
      updateSearchOptions(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (valUpdateSearch.length !== 0) {
      updateSearchOptions(altDebouncedSearch);
    }
  }, [altDebouncedSearch]);

  const handleDialogState = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
    });
  };

  const dialogProps = {
    DialogOpen: dialog.open ? dialog.open : false,
    onHandleDialog: handleDialogState,
    headerStyles: { display: "none" },
    PaperPropsStyle: {
      margin: 0,
      width: "100%",
    },
    Layoutstyles: {
      "& .MuiDialog-container": {
        alignItems: "flex-end",
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
      },
    },
    footerStyles: {
      display: "none",
    },
    CloseButtonStyles:{
      display: "none",
    },
    onHandleSave: () => {
      // not required, custom save option is there
      return;
    },
  };

  const assignAddHistoryMobile = (value: string) => {
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;
    if (!value) return;
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        id,
        name,
        status: "",
        when: "",
        since: "",
      },
    });
  };

  const doctorSuggestionProps = {
    sKey: "medical_history",
    sHeading: "Suggested medical history",
    headerImg: isMobileScreen
      ? "/images/icon_mobile_resp_suggestion.svg"
      : "/images/icon_dummy.svg",
    handleAdd: (event: any) => {
      const currentTarget = event?.currentTarget;
      const suggestion = currentTarget?.attributes["data-key"]?.nodeValue;
      const clickAction = currentTarget?.attributes["data-action"]?.nodeValue;

      if (!data.some((history: any) => history.name === suggestion)) {
        switch (clickAction) {
          case "ADD":
            if (isMobileScreen) {
              assignAddHistoryMobile(suggestion);
            } else {
              addHistory(null as any, suggestion);
            }
            break;
          case "UPDATE":
            updateHistory(event as any, suggestion);
            break;
          default:
            return;
        }
      }
      if (autoref.current) {
        autoref.current.blur();
      }
    },
  };

  const addHistory = (_event: SyntheticEvent, value: string | null) => {
    if (!value) {
      return;
    }
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;

    const newComplaint: MedicalHistoryProps = {
      id,
      name,
      status: "",
      when: "",
      since: "",
    };
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === PATIENT_HISTORY) {
          return {
            ...section,
            rows: section.rows.map((row: any) => {
              if (row.view_type === MEDICAL_HISTORY) {
                return {
                  ...row,
                  value: [...row.value, newComplaint],
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
    setSearchValue("");
  };

  const updateHistory = (event: SyntheticEvent, value: string | null) => {
    let newValue: any, newId: any;

    const target =
      (event.currentTarget as HTMLInputElement) ||
      (event.currentTarget as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName).split("-");

    const key = nameArr[nameArr.length - 1];

    const currId = nameArr.reduce((acc, val) => {
      if (val === key) {
        return acc;
      }
      return acc + "-" + val;
    });

    if (key === "name") {
      const optionObj = optionsData.find(
        (optObj: any) => optObj.name === value
      );
      const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
      const name = optionObj?.name ? optionObj.name : value;
      newValue = name;
      newId = id;
    } else {
      (newId = currId), (newValue = value);
    }
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === PATIENT_HISTORY) {
          return {
            ...section,
            rows: section.rows.map((row: any) => {
              if (row.view_type === MEDICAL_HISTORY) {
                return {
                  ...row,
                  value: row.value.map((val: any) => {
                    if (val?.id?.toString() === currId) {
                      return {
                        ...val,
                        id: newId,
                        [key]: newValue,
                      };
                    }
                    return val;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
    setHistoryDurationOptions([]);
  };

  const removeHistory = (event: SyntheticEvent) => {
    const elem = event.target as HTMLElement;
    const { id } = elem;
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === PATIENT_HISTORY) {
          return {
            ...section,
            rows: section.rows.map((row: any) => {
              if (row.view_type === MEDICAL_HISTORY) {
                return {
                  ...row,
                  value: row.value.filter(
                    (val: any) => val?.id?.toString() !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
  };

  const updateHistoryDurationOptions = (event: SyntheticEvent) => {
    const elem = event.target as HTMLInputElement;
    const { value } = elem;

    const ariaLabel = (event.currentTarget as HTMLInputElement).name as string;
    const nameArr = ariaLabel.split("-");

    const key = nameArr[nameArr.length - 1];

    if (!/^[1-9][0-9]*$/.test(value) || value === "") {
      const newOptionArr = historyDurationOptions.filter((option) =>
        option.includes(value)
      );

      if (newOptionArr) {
        setHistoryDurationOptions(newOptionArr);
        return;
      }

      setHistoryDurationOptions([]);
      return;
    }

    const optionArr = getDurationOptions(value);

    if (optionArr) {
      let suggestionOptions = optionArr;
      if (key === "when") {
        suggestionOptions = optionArr.map((option) => `${option} ago`);
      }
      setHistoryDurationOptions(suggestionOptions);
    }
  };

  const HistoryCardMobile = (History: MedicalHistoryProps, index: number) => {
    const { id, name, status, when, since } = History;
    return (
      <Card
        styles={{
          boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.08)`,
          padding: 0,
          borderRadius: "8px",
        }}
        key={index + id}
      >
        <Box id={id}>
          {/* Card Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <Typography
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              color="#494E9D"
            >
              {name}
            </Typography>
            <IconButton>
              <Box
                id={id}
                aria-label="delete-medical-history"
                onClick={removeHistory}
                component="img"
                src="/images/DeleteIcon.svg"
              ></Box>
            </IconButton>
          </Box>
          <Divider />
          {/* Card Contents */}
          <Box padding="12px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginRight: "25%",
                }}
              >
                <Box
                  component="img"
                  marginRight="4px"
                  src="/images/rollingIcon.svg"
                ></Box>
                <Box>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="100%"
                    marginBottom="8px"
                    color="rgba(0, 0, 0, 0.87)"
                  >
                    Status
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="100%"
                    color="#6F6F6F"
                  >
                    {status}
                  </Typography>
                </Box>
              </Box>
              {status !== "Never Had" && (
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Box
                    component="img"
                    marginRight="4px"
                    src="/images/ClockIcon.svg"
                  ></Box>
                  <Box>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="100%"
                      marginBottom="8px"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {status === "Active" ? "Since When" : "When"}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="100%"
                      color="#6F6F6F"
                    >
                      {status === "Active" ? since : when}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Box sx={{ my: 3 }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "600", color: "#000" }}>
              Medical History
            </span>
            <FormControlLabel
              sx={{
                ".css-1960bxs-MuiTypography-root": {
                  color: "#959595",
                },
              }}
              control={
                <Checkbox
                  onClick={() => {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === PATIENT_HISTORY) {
                          return {
                            ...section,
                            rows: section.rows.map((row: any) => {
                              if (row.view_type === MEDICAL_HISTORY) {
                                return {
                                  ...row,
                                  disable_value: !sectionData?.disable_value,
                                  value: [],
                                };
                              }
                              return row;
                            }),
                          };
                        }
                        return section;
                      });
                    });
                  }}
                  checked={sectionData?.disable_value}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
              }
              label="No known significant medical history"
            />
          </Box>
          {!sectionData?.disable_value && (
            <Box>
              <TableContainer
                sx={{ mb: 2, boxShadow: "none" }}
                component={Paper}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="all-medical-histories"
                >
                  <TableBody>
                    {data.map((mHistory, index) => {
                      const { id, name, status } = mHistory;
                      return (
                        <TableRow key={`complaint-${index}-${name}`}>
                          <StyledTableCell align="left">
                            <Autocomplete
                              id={`${id}-name`}
                              aria-label={`${id}-name`}
                              filterOptions={(options, { inputValue }) => {
                                return options.filter((option) => true);
                              }}
                              options={
                                valUpdateSearch.length === 0 ? [] : options
                              }
                              disableClearable
                              value={name}
                              clearOnBlur
                              clearOnEscape
                              noOptionsText={
                                <DoctorSuggestions
                                  clickSource={`${id}-name`}
                                  clickAction="UPDATE"
                                  {...doctorSuggestionProps}
                                />
                              }
                              popupIcon={false}
                              clearIcon={false}
                              onChange={updateHistory}
                              PaperComponent={({ children }) => (
                                <CustomDropDownPaper>
                                  {children}
                                </CustomDropDownPaper>
                              )}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    inputRef={autoref}
                                    multiline
                                    label="Condition"
                                    onChange={(event: any) => {
                                      if (event.target.value === "") {
                                        setOptions([]);
                                      }
                                      setValUpdateSearch(event.target.value);
                                    }}
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                  />
                                );
                              }}
                              renderOption={(
                                props: HTMLAttributes<HTMLLIElement>,
                                option: string,
                                state
                              ) => {
                                if (
                                  !data.some(
                                    (complaint: any) =>
                                      complaint.name === option
                                  )
                                ) {
                                  return (
                                    <Box
                                      component="li"
                                      aria-label={`${id}-name`}
                                      sx={{
                                        backgroundColor: state.selected
                                          ? "#F2F3FF !important"
                                          : "",
                                        "&:hover, &:focus": {
                                          backgroundColor: "#F2F3FF !important",
                                        },
                                      }}
                                      {...props}
                                    >
                                      {!optionsData
                                        .find((opt: any) => opt.name === option)
                                        ?.id?.toString()
                                        .includes("custom")
                                        ? option
                                        : `Add "${option}"`}
                                    </Box>
                                  );
                                }
                              }}
                              sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" } }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Autocomplete
                              aria-label={`${id}-status`}
                              id={`${id}-status`}
                              filterOptions={(options, { inputValue }) => {
                                return options.filter((option) => true);
                              }}
                              // disableClearable
                              options={statusMaster}
                              onChange={updateHistory}
                              value={status}
                              clearIcon={false}
                              renderOption={(props, option, state) => {
                                return (
                                  <Box
                                    aria-label={`${id}-status`}
                                    component="li"
                                    sx={{
                                      backgroundColor: state.selected
                                        ? "#F2F3FF !important"
                                        : "",
                                      "&:hover, &:focus": {
                                        backgroundColor: "#F2F3FF !important",
                                      },
                                    }}
                                    id={`${id}-status-${option}`}
                                    {...props}
                                  >
                                    {option}
                                  </Box>
                                );
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    name={`${id}-status`}
                                    {...params}
                                    label="Status"
                                    aria-label={`${id}-status`}
                                    fullWidth
                                    variant="outlined"
                                  />
                                );
                              }}
                              sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" } }}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {(status === "Active" || status === "Resolved") && (
                              <Autocomplete
                                aria-label={`${name}-${
                                  status === "Resolved" ? "when" : "since"
                                }`}
                                // disableClearable
                                filterOptions={(options, { inputValue }) => {
                                  return options.filter((option) => true);
                                }}
                                options={historyDurationOptions}
                                clearIcon={false}
                                onChange={updateHistory}
                                noOptionsText=""
                                // open={
                                //   historyDurationOptions.length === 0
                                //     ? false
                                //     : true
                                // }
                                value={
                                  status === "Resolved"
                                    ? mHistory.when
                                    : mHistory.since
                                }
                                renderOption={(props, option, state) => {
                                  return (
                                    <Box
                                      aria-label={`${id}-${
                                        status === "Resolved" ? "when" : "since"
                                      }`}
                                      component="li"
                                      sx={{
                                        backgroundColor: state.selected
                                          ? "#F2F3FF !important"
                                          : "",
                                        "&:hover, &:focus": {
                                          backgroundColor: "#F2F3FF !important",
                                        },
                                      }}
                                      id={`${id}-status-${option}`}
                                      {...props}
                                    >
                                      {option}
                                    </Box>
                                  );
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      onChange={updateHistoryDurationOptions}
                                      label={
                                        status === "Resolved"
                                          ? "When"
                                          : "Since When"
                                      }
                                      name={`${id}-${
                                        status === "Resolved" ? "when" : "since"
                                      }`}
                                      fullWidth
                                      placeholder="Eg: 1 month"
                                      variant="outlined"
                                      InputProps={{ ...params.InputProps, style: { minWidth: '120px' } }} 
                                    />
                                  );
                                }}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ width: "32px", padding: "0 !important" }}
                            align="right"
                          >
                            <Tooltip title={"Delete"}>
                              <button
                                id={id}
                                onClick={removeHistory}
                                style={{
                                  outline: "none",
                                  border: "none",
                                  background: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  style={{ pointerEvents: "none" }}
                                  src="/images/minus-in-circle.svg"
                                  alt="remove-button"
                                />
                              </button>
                            </Tooltip>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Autocomplete
                id="search-for-conditions"
                options={searchValue.length === 0 ? [] : options}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) => true);
                }}
                disableClearable
                value={""}
                clearOnBlur
                clearOnEscape
                noOptionsText={
                  <DoctorSuggestions
                    clickAction="ADD"
                    {...doctorSuggestionProps}
                  />
                }
                inputValue={searchValue}
                popupIcon={false}
                clearIcon={false}
                onChange={addHistory}
                renderInput={(params) => {
                  return (
                    <TextField
                      inputRef={autoref}
                      value={null}
                      onChange={(event: any) => {
                        if (event.target.value === "") {
                          setOptions([]);
                        }
                        setSearchValue(event.target.value);
                      }}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src="/images/icon_search.svg"
                              alt="search_icon"
                            />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search for conditions"
                      fullWidth
                      variant="outlined"
                    />
                  );
                }}
                renderOption={(
                  props: HTMLAttributes<HTMLLIElement>,
                  option: string,
                  state
                ) => {
                  if (
                    !data.some((complaint: any) => complaint.name === option)
                  ) {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {!optionsData
                          .find((opt: any) => opt.name === option)
                          ?.id?.toString()
                          .includes("custom")
                          ? option
                          : `Add "${option}"`}
                      </Box>
                    );
                  }
                }}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                    ],
                  },
                }}
              />
            </Box>
          )}
        </Box>
      )}
      {!loading && isMobileScreen && (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              sx={{
                "& .MuiTypography-root": {
                  color: "#959595",
                },
              }}
              control={
                <Checkbox
                  onClick={() => {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === PATIENT_HISTORY) {
                          return {
                            ...section,
                            rows: section.rows.map((row: any) => {
                              if (row.view_type === MEDICAL_HISTORY) {
                                return {
                                  ...row,
                                  disable_value: !sectionData?.disable_value,
                                  value: [],
                                };
                              }
                              return row;
                            }),
                          };
                        }
                        return section;
                      });
                    });
                  }}
                  checked={sectionData?.disable_value}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
              }
              label="No known significant medical history"
            />
          </Box>
          {!sectionData?.disable_value && (
            <Autocomplete
              id="search-for-conditions"
              options={searchValue.length === 0 ? [] : options}
              disableClearable
              value={""}
              clearOnBlur
              clearOnEscape
              noOptionsText={
                <DoctorSuggestions
                  clickAction="ADD"
                  {...doctorSuggestionProps}
                />
              }
              inputValue={searchValue}
              popupIcon={false}
              clearIcon={false}
              onChange={(_event, value) => {
                assignAddHistoryMobile(value);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    inputRef={autoref}
                    value={null}
                    onChange={(event: any) => {
                      if (event.target.value === "") {
                        setOptions([]);
                      }
                      setSearchValue(event.target.value);
                    }}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src="/images/icon_search.svg"
                            alt="search_icon"
                          />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search for conditions"
                    fullWidth
                    variant="outlined"
                  />
                );
              }}
              renderOption={(
                props: HTMLAttributes<HTMLLIElement>,
                option: string,
                state
              ) => {
                if (!data.some((complaint: any) => complaint.name === option)) {
                  return (
                    <Box
                      component="li"
                      sx={{
                        backgroundColor: state.selected
                          ? "#F2F3FF !important"
                          : "",
                        "&:hover, &:focus": {
                          backgroundColor: "#F2F3FF !important",
                        },
                      }}
                      {...props}
                    >
                      {!optionsData
                        .find((opt: any) => opt.name === option)
                        ?.id?.toString()
                        .includes("custom")
                        ? option
                        : `Add "${option}"`}
                    </Box>
                  );
                }
              }}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: "flip",
                      enabled: false,
                    },
                    {
                      name: "preventOverflow",
                      enabled: false,
                    },
                  ],
                },
              }}
            />
          )}
          <Box marginTop="16px">
            {data.length !== 0 &&
              data.map((history, index) => {
                return (
                  <Box
                    component="div"
                    id={history.id}
                    onClick={(_event: any) => {
                      if (
                        _event.target.ariaLabel === "delete-medical-history"
                      ) {
                        return;
                      }
                      setDialog({
                        ...dialog,
                        open: true,
                        mode: "UPDATE",
                        content: history,
                      });
                    }}
                    key={index}
                  >
                    {HistoryCardMobile(history, index)}
                  </Box>
                );
              })}
          </Box>
        </>
      )}
      {isMobileScreen && (
        <CustomizedDialogs {...(dialogProps as any)}>
          <Box margin="12px">
            <Box className="header" display="flex">
              <Box
                component="img"
                sx={{ marginRight: 1.5 }}
                src="/images/ReportsIcon.svg"
              />
              <Box className="text-content" sx={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <Box>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  Conditions
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="100%"
                  color="#494E9D"
                >
                  {dialog.content.name}
                </Typography>
                </Box>
                <Box onClick={handleDialogState}>
                  <CloseIcon sx={{ color: theme.palette.grey[500] }} />
                </Box>
              </Box>
            </Box>
            <Divider sx={{ margin: "16px 0" }} />
            <Box className="content" marginBottom="24px">
              <Box display="flex" marginBottom="16px" width="100%" gap="8px">
                <Autocomplete
                  fullWidth
                  // disableClearable
                  options={statusMaster}
                  onChange={(_event, value) => {
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        status: value,
                      },
                    });
                  }}
                  value={dialog.content.status}
                  clearIcon={false}
                  renderOption={(props, option, state) => {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {option}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Status"
                        fullWidth
                        variant="outlined"
                      />
                    );
                  }}
                />
                {(dialog.content.status === "Active" ||
                  dialog.content.status === "Resolved") && (
                  <Autocomplete
                    fullWidth
                    disableClearable
                    options={historyDurationOptions}
                    clearIcon={false}
                    onChange={(_event, value) => {
                      setDialog({
                        ...dialog,
                        content: {
                          ...dialog.content,
                          [dialog.content.status === "Resolved"
                            ? "when"
                            : "since"]: value,
                        },
                      });
                    }}
                    noOptionsText=""
                    value={
                      dialog.content.status === "Resolved"
                        ? dialog.content.when
                        : dialog.content.since
                    }
                    renderOption={(props, option, state) => {
                      return (
                        <Box
                          component="li"
                          sx={{
                            backgroundColor: state.selected
                              ? "#F2F3FF !important"
                              : "",
                            "&:hover, &:focus": {
                              backgroundColor: "#F2F3FF !important",
                            },
                          }}
                          {...props}
                        >
                          {option}
                        </Box>
                      );
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          onChange={updateHistoryDurationOptions}
                          label={
                            dialog.content.status === "Resolved"
                              ? "When"
                              : "Since When"
                          }
                          fullWidth
                          variant="outlined"
                        />
                      );
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none !important",
                  borderRadius: "6px",
                }}
                fullWidth
                onClick={() => {
                  if (dialog.mode === "ADD" && dialog.content.id) {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === PATIENT_HISTORY) {
                          return {
                            ...section,
                            rows: section.rows.map((row: any) => {
                              if (row.view_type === MEDICAL_HISTORY) {
                                return {
                                  ...row,
                                  value: [...row.value, dialog.content],
                                };
                              }
                              return row;
                            }),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setSearchValue("");
                  }
                  if (dialog.mode === "UPDATE" && dialog.content.id) {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === PATIENT_HISTORY) {
                          return {
                            ...section,
                            rows: section.rows.map((row: any) => {
                              if (row.view_type === MEDICAL_HISTORY) {
                                return {
                                  ...row,
                                  value: row.value.map((val: any) => {
                                    if (
                                      val?.id === dialog.content.id
                                    ) {
                                      return {
                                        ...dialog.content,
                                      };
                                    }
                                    return val;
                                  }),
                                };
                              }
                              return row;
                            }),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setHistoryDurationOptions([]);
                  }
                }}
              >
                {dialog.mode === "ADD" ? `Add` : `Update`} Medical History
              </Button>
            </Box>
          </Box>
        </CustomizedDialogs>
      )}
    </>
  );
}
