import { Box, Tooltip } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const MobileVideoConsultation = ({
  showVideo,
  setVideo,
  selectedAppointmentState,
}: any) => {
  const { selectedAppointment } = useSelector(
    (state: any) => state.appointments
  );

  const [height, setHeight] = useState("285px");
  const [width, setWidth] = useState("100%");

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    const handleMessage = (event: any) => {
      // Check the origin for security reasons
      if (event.origin !== "https://devvcapp.azurewebsites.net") {
        return;
      }

      // Process the message
      console.log("Received message from iframe:", event.data);
      if (event.data === "VIEW_RX") {
        toggleFullScreen(false);
      }
      if (event.data === "EXIT_CALL") {
        setVideo(false);
      }
      if (event.data === "CHAT_OPEN") {
        setHeight("440px");
      }
      if (event.data === "CHAT_CLOSE") {
        setHeight("285px");
      }
      // TODO: Handle the data from the iframe
    };

    // Add event listener
    window.addEventListener("message", handleMessage);

    // Clean up
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const toggleFullScreen = useCallback((key: any) => {
    setFullScreen(key);
  }, []);

  return (
    <Box>
      {showVideo && selectedAppointment?.appointment_status !== "Done" && (
        <Box
          display="block"
          sx={{
            position: fullScreen ? "absolute" : "relative",

            top: fullScreen ? 0 : null,
            width: fullScreen ? "100%" : width,
            border: "none",
            left: fullScreen ? 0 : null,
            zIndex: fullScreen ? "10000" : "1000",
          }}
        >
          {selectedAppointment?.doctor_video_link && (
            <>
              {fullScreen ? (
                <iframe
                  allow="camera;microphone;display-capture"
                  ref={iframeRef}
                  src={selectedAppointment?.doctor_video_link}
                  style={{
                    width: "100%",
                    height: "100vh",
                    border: "none",
                    overflow: "none",
                    background: "black",
                  }}
                ></iframe>
              ) : (
                <iframe
                  allow="camera;microphone;display-capture"
                  ref={iframeRef}
                  src={selectedAppointment?.doctor_video_link}
                  style={{
                    width: "100%",
                    height: height,
                    border: "none",
                    overflow: "none",
                    background: "black",
                  }}
                ></iframe>
              )}
            </>
          )}
          {!fullScreen && (
            <Tooltip title={"Click to expand"}>
              <svg
                width="39"
                onClick={() => toggleFullScreen(true)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                height="37"
                viewBox="0 0 39 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.3087 17.3053C22.1302 17.479 21.888 17.5766 21.6355 17.5766C21.383 17.5766 21.1409 17.479 20.9623 17.3053C20.7838 17.1316 20.6835 16.896 20.6835 16.6504C20.6835 16.4048 20.7838 16.1692 20.9623 15.9955L28.569 8.59549C28.6574 8.50948 28.7624 8.44126 28.8779 8.39472C28.9934 8.34817 29.1172 8.32422 29.2422 8.32422C29.3673 8.32422 29.4911 8.34817 29.6066 8.39472C29.7221 8.44126 29.827 8.50948 29.9154 8.59549C30.0038 8.68149 30.074 8.78359 30.1218 8.89596C30.1697 9.00833 30.1943 9.12876 30.1943 9.25039C30.1943 9.37201 30.1697 9.49245 30.1218 9.60482C30.074 9.71719 30.0038 9.81929 29.9154 9.90529L22.3087 17.3053ZM10.8987 28.4053C10.8103 28.4913 10.7053 28.5595 10.5898 28.6061C10.4743 28.6526 10.3505 28.6766 10.2255 28.6766C10.1005 28.6766 9.97665 28.6526 9.86115 28.6061C9.74564 28.5595 9.64069 28.4913 9.55228 28.4053C9.46388 28.3193 9.39375 28.2172 9.34591 28.1048C9.29806 27.9925 9.27344 27.872 9.27344 27.7504C9.27344 27.6288 9.29806 27.5083 9.34591 27.396C9.39375 27.2836 9.46388 27.1815 9.55228 27.0955L17.159 19.6955C17.3375 19.5218 17.5797 19.4242 17.8322 19.4242C18.0847 19.4242 18.3268 19.5218 18.5054 19.6955C18.6839 19.8692 18.7842 20.1048 18.7842 20.3504C18.7842 20.596 18.6839 20.8316 18.5054 21.0053L10.8987 28.4053Z"
                  fill="white"
                />
                <path
                  d="M10.2243 28.6742C9.9721 28.6742 9.73025 28.5768 9.55193 28.4033C9.37361 28.2298 9.27344 27.9945 9.27344 27.7492C9.27344 27.5039 9.37361 27.2686 9.55193 27.0951C9.73025 26.9217 9.9721 26.8242 10.2243 26.8242H17.831C18.0832 26.8242 18.325 26.9217 18.5033 27.0951C18.6816 27.2686 18.7818 27.5039 18.7818 27.7492C18.7818 27.9945 18.6816 28.2298 18.5033 28.4033C18.325 28.5768 18.0832 28.6742 17.831 28.6742H10.2243Z"
                  fill="white"
                />
                <path
                  d="M11.1751 27.7492C11.1751 27.9945 11.0749 28.2298 10.8966 28.4033C10.7183 28.5768 10.4765 28.6742 10.2243 28.6742C9.9721 28.6742 9.73025 28.5768 9.55193 28.4033C9.37361 28.2298 9.27344 27.9945 9.27344 27.7492V20.3492C9.27344 20.1039 9.37361 19.8686 9.55193 19.6951C9.73025 19.5217 9.9721 19.4242 10.2243 19.4242C10.4765 19.4242 10.7183 19.5217 10.8966 19.6951C11.0749 19.8686 11.1751 20.1039 11.1751 20.3492V27.7492ZM30.1919 16.6492C30.1919 16.8945 30.0917 17.1298 29.9134 17.3033C29.7351 17.4768 29.4932 17.5742 29.241 17.5742C28.9889 17.5742 28.747 17.4768 28.5687 17.3033C28.3904 17.1298 28.2902 16.8945 28.2902 16.6492V9.24922C28.2902 9.00389 28.3904 8.76862 28.5687 8.59515C28.747 8.42167 28.9889 8.32422 29.241 8.32422C29.4932 8.32422 29.7351 8.42167 29.9134 8.59515C30.0917 8.76862 30.1919 9.00389 30.1919 9.24922V16.6492Z"
                  fill="white"
                />
                <path
                  d="M21.6383 10.1742C21.3862 10.1742 21.1443 10.0768 20.966 9.90329C20.7877 9.72982 20.6875 9.49454 20.6875 9.24922C20.6875 9.00389 20.7877 8.76862 20.966 8.59515C21.1443 8.42167 21.3862 8.32422 21.6383 8.32422H29.245C29.4972 8.32422 29.7391 8.42167 29.9174 8.59515C30.0957 8.76862 30.1959 9.00389 30.1959 9.24922C30.1959 9.49454 30.0957 9.72982 29.9174 9.90329C29.7391 10.0768 29.4972 10.1742 29.245 10.1742H21.6383Z"
                  fill="white"
                />
              </svg>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MobileVideoConsultation;
