interface PhoneIconProps {
  height?: string;
  width?: string;
  color?: string;
  fill?: string;
}
const PhoneIcon = ({
  height = "20",
  width,
  color = "#23BCB5",
  fill,
}: PhoneIconProps) => (
  <>
    <svg
      width={width ? width : "24"}
      height={height ? height : "26"}
      viewBox="0 0 24 26"
      fill={fill ? fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00006 5.40585C3.00006 4.2117 3.89549 3.24365 5.00006 3.24365H8.2793C8.70973 3.24365 9.09187 3.54142 9.22798 3.98288L10.7258 8.84072C10.8832 9.35112 10.6694 9.90895 10.2243 10.1496L7.96707 11.3697C9.06931 14.0127 11.0388 16.142 13.4836 17.3336L14.6122 14.8933C14.8347 14.4121 15.3507 14.181 15.8228 14.3511L20.3163 15.9704C20.7246 16.1176 21.0001 16.5307 21.0001 16.996V20.5412C21.0001 21.7354 20.1046 22.7034 19.0001 22.7034H18.0001C9.71579 22.7034 3.00006 15.443 3.00006 6.48694V5.40585Z"
        stroke="#494E9D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

export default PhoneIcon;
