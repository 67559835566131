import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


import { API_ROUTE_APPOINTMENTS, API_ROUTE_APPOINTMENT_REASONS, API_ROUTE_APPOINTMENT_UPDATE, API_ROUTE_FETCH_APPOINTMENT_BY_ID, API_ROUTE_GET_ALL_INVOICE, API_ROUTE_PATIENT, API_ROUTE_PRESCRIPTION_DOCTOR_TEMPLATES } from "../../shared/routes/route.constants";
import { APIClient } from "../../shared/Utils/api-client";
// import { AlphaClient } from "../../shared/Utils/api-client";

const config = {
  name: "appointments",
};
export const fetchAllApponitments: any = createAsyncThunk(
  `${config.name}/fetchAllApponitments`,
  async (id: any) => {
    const params = id?.params
    return await APIClient.get(API_ROUTE_APPOINTMENTS(id?.id), { params });
  }
);

export const fetchDoctorTemplates: any = createAsyncThunk(
  `${config.name}/fetchDoctorTemplate`,
  async (id: any) => {
    const params = id?.params
    return await APIClient.get(API_ROUTE_PRESCRIPTION_DOCTOR_TEMPLATES(), { params });
  }
)

export const fetchAllConfirmedApponitments: any = createAsyncThunk(
  `${config.name}/fetchAllConfirmedApponitments`,
  async (id: any) => {
    const params = id?.params
    return await APIClient.get(API_ROUTE_APPOINTMENTS(id?.id), { params }, id?.signal);
  }
);

export const updateApponitment: any = createAsyncThunk(
  `${config.name}/updateApponitment`,
  async (id: any) => {
    const body = id?.body
    return await APIClient.put(API_ROUTE_APPOINTMENT_UPDATE(id?.id), body);
  }
);

export const fetchAppointmentType: any = createAsyncThunk(
  `${config.name}/fetchAppointmentType`,
  async (type: any) => {
    return await APIClient.get(API_ROUTE_APPOINTMENT_REASONS(type));
  }
);

export const createNewPatient: any = createAsyncThunk(
  `createNewPatient`,
  async (data: any) => {
    return await APIClient.post(API_ROUTE_PATIENT(), data);
  }
);

export const fetchAppointmentForPrescription: any = createAsyncThunk(
  `fetchAppointmentForPrescription`,
  async (id: any) => {
    return await APIClient.get(API_ROUTE_FETCH_APPOINTMENT_BY_ID(id));
  }
);

export const fetchAllInvoice: any = createAsyncThunk(
  `${config.name}/fetchAllInvoice`,
  async (data:any) => {
    const params = data?.params
    return await APIClient.get(API_ROUTE_GET_ALL_INVOICE(data?.patientId),{ params });
  }
);


//CRUD

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    appoitments: [] as any,
    loader: false as any,
    confirmedAppoitments: [] as any,
    confirmedLoader: true,
    patientSuggestionsList: [] as any,
    appointmentReasons: [] as any,
    updateLoader: false as any,
    error: null,
    doctorTemplates: [] as Array<any>,
    selectedTemplate: {} as any,
    selectedAppointment: null as any,
    showMobileSearch: false as boolean,
    isReschedule: false as boolean,
    rescheduleData: {} as any,
    allinvoice: [] as Array<any>,
  },
  reducers: {
    setIsReschedule: (state, action) => {
      state.isReschedule = action?.payload
    },
    setRescheduleData: (state, action) => {
      state.rescheduleData = action?.payload
    },
    resetReschedule: (state) => {
      state.isReschedule = false;
      state.rescheduleData = {};
    },
    hideError: (state) => {
      state.error = null
    },
    resetAppointments: (state) => {
      state.confirmedAppoitments = [],
        state.appoitments = []
    },
    setSelectedAppointment: (state, payload) => {
      state.selectedAppointment = payload?.payload
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action?.payload
    },
    resetSelectedAppointment: (state) => {
      state.selectedAppointment = null
    },
    resetSelectedTemplate: (state) => {
      state.selectedTemplate = {}
    },
    toggleShowMobileSearch: (state) => {
      state.showMobileSearch = !state.showMobileSearch
    }
  },
  extraReducers(builder: any) {
    builder
      .addCase(fetchAllInvoice.pending, (state: any) => {
        state.loader = true;
      })
      .addCase(fetchAllInvoice.fulfilled, (state: any,action : any) => {
        state.loader = false;
        state.allinvoice = action?.payload?.data?.rows
      })
      .addCase(fetchAllInvoice.rejected, (state: any, action: any) => {
        state.loader = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchAppointmentForPrescription.pending, (state: any) => {
        state.loader = true;
      })
      .addCase(fetchAppointmentForPrescription.fulfilled, (state: any,action : any) => {
        state.loader = false;
        state.selectedAppointment = action?.payload?.data
      })
      .addCase(fetchAppointmentForPrescription.rejected, (state: any, action: any) => {
        state.loader = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(createNewPatient.pending, (state: any) => {
        state.loader = true;
      })
      .addCase(createNewPatient.fulfilled, (state: any) => {
        state.loader = false;
      })
      .addCase(createNewPatient.rejected, (state: any, action: any) => {
        state.loader = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchDoctorTemplates.pending, (state: any) => {
        state.loader = true
      })
      .addCase(fetchDoctorTemplates.fulfilled, (state: any, action: any) => {
        state.doctorTemplates = action?.payload?.data?.data;
        state.loader = false;
      })
      .addCase(fetchDoctorTemplates.rejected, (state: any, action: any) => {
        state.loader = false;
        state.doctorTemplates = [];
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchAllApponitments.pending, (state: any) => {
        state.loader = true;
      })
      .addCase(fetchAllApponitments.fulfilled, (state: any, action: any) => {

        state.appoitments = action.payload?.data;
        state.loader = false;
      })
      .addCase(fetchAllApponitments.rejected, (state: any, action: any) => {
        state.appoitments = [];
        state.loader = false;
        state.error = action.payload || action.error.message;
      })

      .addCase(fetchAllConfirmedApponitments.pending, (state: any) => {
        state.confirmedLoader = true;
      })
      .addCase(fetchAllConfirmedApponitments.fulfilled, (state: any, action: any) => {
        if(action.payload?.data){
          state.confirmedAppoitments = action.payload?.data;
        }
        state.confirmedLoader = false;
      })
      .addCase(fetchAllConfirmedApponitments.rejected, (state: any, action: any) => {
        state.confirmedAppoitments = [];
        // state.confirmedLoader = false; //commented because no appointments flicker for a second on first page load
        state.error = action.payload || action.error.message;
      })

      .addCase(fetchAppointmentType.fulfilled, (state: any, action: any) => {
        state.appointmentReasons = action.payload?.data?.data;
      })
      .addCase(fetchAppointmentType.rejected, (state: any, action: any) => {
        state.appointmentReasons = [];
        state.error = action.payload || action.error.message;
      })


      .addCase(updateApponitment.pending, (state: any) => {
        state.updateLoader = true;
      })
      .addCase(updateApponitment.fulfilled, (state: any) => {
        state.updateLoader = false;
      })
      .addCase(updateApponitment.rejected, (state: any, action: any) => {
        state.updateLoader = false;
        state.error = action.payload || action.error.message;
      })


  },
});

export const {
  hideError, resetAppointments, setSelectedAppointment, setSelectedTemplate, resetSelectedAppointment, resetSelectedTemplate, toggleShowMobileSearch, setIsReschedule, setRescheduleData, resetReschedule
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
