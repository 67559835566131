import { Box } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface CardComponentsProps {
  children: ReactNode;
  title?: string;
  styles?: CSSProperties;
  isReadOnly?: boolean;
}

export default function Card(props: CardComponentsProps) {
  const { children, title, styles, isReadOnly } = props;
  return (
    <Box
      style={{ ...styles }}
      aria-label={`${title as string}`}
      sx={{ py: 3, mb: 2, px: 2, borderRadius: "8px", background: "#ffffff",pointerEvents:isReadOnly?'none': "initial" }}
      
    >
      {children}
    </Box>
  );
}
