import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Skeleton,
  TextField,
  useMediaQuery,
  createFilterOptions,
  useTheme,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import {
    MEAL_PREFERENCE,
  PATIENT_HISTORY,
  PHYSICAL_ACTIVITY,
  SOCIAL_HISTORY,
} from "../../../../../../shared/constants";

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  resize:none;
  outline: none;
  border:1px solid #CACACA;
`
);

export default function PhysicalActivity(props: any) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([] as any);

  const sectionData = props?.sectionData && props?.sectionData?.[0];
  const setTabData = props?.setTabData;

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [radio, setRadio] = useState('');
  const [comment, setComment] = useState('');

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const statusMaster = sectionData?.master?.status?.values || [];

  useEffect(() => {
    if (sectionData) {
      setData(sectionData);
      setComment(sectionData?.value[0]?.comment || '')
      setRadio(sectionData?.value[0]?.state || '')
      setLoading(false);
    }
  }, [sectionData]);

  const updateHistory = () => {
    setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === PATIENT_HISTORY) {
            return {
              ...section,
              rows: section.rows.map((row: any) => {
                if (row.view_type === PHYSICAL_ACTIVITY) {
                  return {
                    ...row,
                    value: [{
                        state:radio,
                        comment:comment
                    }]
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && (
        <Box sx={{ my: isMobileScreen ? 0 : 3 }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              mb: "21px",
              alignItems: "center",
            }}
          >
            {!isMobileScreen && (
              <span style={{ fontWeight: "600", color: "#000" }}>
                Physical Activity
              </span>
              
            )}

            <FormControlLabel
              sx={{
                ".css-1960bxs-MuiTypography-root": {
                  color: "#959595",
                },
              }}
              control={
                <Checkbox
                  onClick={() => {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === PATIENT_HISTORY) {
                          return {
                            ...section,
                            rows: section.rows.map((row: any) => {
                              if (row.view_type === PHYSICAL_ACTIVITY) {
                                return {
                                  ...row,
                                  disable_value: !sectionData?.disable_value,
                                  value: [],
                                };
                              }
                              return row;
                            }),
                          };
                        }
                        return section;
                      });
                    });
                  }}
                  checked={sectionData?.disable_value}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
              }
              label="No known significant physical activity"
            />
          </Box>
          {!sectionData?.disable_value && (
            <Box
                sx={{
                display: "flex",
                gap:2,
                justifyContent:'center',
                alignItems:'center',
                flexDirection: !isMobileScreen ? "row" : "column",
                }}
            >
                <Box>

                    <FormControl>
                            <RadioGroup 
                                row 
                                sx={{gap:3,justifyContent:'space-between'}}
                                value={radio || ''}
                            >
                            {data?.master?.state?.values?.map((elem:any)=>{
                                return (
                                    <FormControlLabel
                                        id={`${elem}-state`}
                                        name={`${elem}-state`}
                                        value={elem}
                                        control={<Radio
                                        
                                            inputProps={
                                                {
                                                onBlur:(event: any) => {
                                                    updateHistory();
                                                },
                                                name:`${elem}-state`,
                                                id:`${elem}-state`,
                                                value:radio,
                                                onClick:(e:any)=>{
                                                    const checked = e.target.checked;
                                                    if(checked){
                                                        setRadio(elem);
                                                    }
                                                }
                                                }
                                            }
                                            
                                            onBlur={(event: any) => {
                                                updateHistory();
                                            }}
                                        />} 
                                        label={elem} 
                                    />
                                )
                            })}
                            
                            </RadioGroup>
                    </FormControl>
                        
                </Box>

                <Box sx={{flex:1,width:isMobileScreen?'100%':'auto'}}>
                    <TextField
                        label="Comments"
                        fullWidth
                        value={comment}
                        onChange={(e)=>{
                            setComment(e.target.value)
                        }}
                        onBlur={()=>{
                            updateHistory();
                        }}
                        sx={{width:'100%'}}
                    />
                </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
