import { useDispatch, useSelector } from "react-redux";
import Card from "../../utils/Card";
import CardHeader from "../../utils/CardHeader";
import { CARD_SHADOW } from "../../utils/Constants";
import { useEffect, useState } from "react";
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ReactQuill from "react-quill";
import { ADVICE_NOTES } from "../../../../../shared/constants";
import DOMPurify from "dompurify";
import { setEditRxSecMobile } from "../../../../../redux/reducers/prescription.slice";
import "../../../../../shared/layouts/style.css"

interface AdditionalNotesProps {
  id: string;
  value: string;
}

const EditorModules = {
  toolbar: [
    ["bold", "italic", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export default function AdditionalNotes(props: any) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const isFocused: boolean = props.isFocused || false;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const sectionData = props?.sectionData?.[0]?.rows[0];
  const notesValue = sectionData
    ? (sectionData.value[0] as AdditionalNotesProps).value
    : "";
  const { setTabData } = props;

  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  useEffect(() => {
    if (sectionData) {
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(ADVICE_NOTES));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === ADVICE_NOTES
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === ADVICE_NOTES) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const RenderReadOnly = () => {
    const examinationData: string = notesValue;
    const tempDiv = document.createElement("div");
    tempDiv.classList.add(isMobileScreen ? "free-text-mobile" : "free-text");
    const removeBreaks = /<p><br><\/p>/g;
    tempDiv.innerHTML = examinationData?.replace(removeBreaks, "");

    return (
      tempDiv.innerHTML?.length > 0 && (
        <Box>
          <Typography
            sx={{
              color: "#6F6F6F",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "160%",
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(tempDiv),
            }}
          ></Typography>
        </Box>
      )
    );
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
          }}
          title="Additional Notes"
        >
          <CardHeader
            headerImg="icon_addnl_notes.svg"
            headerTitle="Additional Notes"
          >
            <></>
          </CardHeader>
          <ReactQuill
            id="rx-additional-notes"
            theme="snow"
            placeholder="Enter some advice for the patient"
            modules={EditorModules}
            value={notesValue}
            onChange={(content, delta, source, editor) => {
              let newValue = content;
              if (editor.getLength() <= 1) {
                newValue = "";
              }
              if (editor.getLength() > 1500) {
                newValue = newValue.substring(0, 1500);
              }
              setTabData((prev: any) => {
                return prev.map((section: any) => {
                  if (section.view_type === ADVICE_NOTES) {
                    return {
                      ...section,
                      rows: section.rows.map((row: any, index: number) => {
                        if (index === 0) {
                          return {
                            ...row,
                            value: row.value.map((val: any, index: number) => {
                              if (index === 0) {
                                return {
                                  ...val,
                                  value: newValue,
                                };
                              }
                              return val;
                            }),
                          };
                        }
                        return row;
                      }),
                    };
                  }
                  return section;
                });
              });
            }}
            style={{ color: "#000000" }}
          />
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <ReactQuill
            id="rx-additional-notes"
            className="additional-notes-quill-mobile"
            theme="snow"
            placeholder="Enter some advice for the patient"
            modules={EditorModules}
            value={notesValue}
            onChange={(content, delta, source, editor) => {
              let newValue = content;
              if (editor.getLength() <= 1) {
                newValue = "";
              }
              if (editor.getLength() > 1500) {
                newValue = newValue.substring(0, 1500);
              }
              setTabData((prev: any) => {
                return prev.map((section: any) => {
                  if (section.view_type === ADVICE_NOTES) {
                    return {
                      ...section,
                      rows: section.rows.map((row: any, index: number) => {
                        if (index === 0) {
                          return {
                            ...row,
                            value: row.value.map((val: any, index: number) => {
                              if (index === 0) {
                                return {
                                  ...val,
                                  value: newValue,
                                };
                              }
                              return val;
                            }),
                          };
                        }
                        return row;
                      }),
                    };
                  }
                  return section;
                });
              });
            }}
            style={{ color: "#000000"}}
          />
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
    </>
  );
}
