import { Typography, Box, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

const PatientCard = ({
  data,
  openMenu,
  handleCheckPatient,
  setAnchorEl,
  setSelectedPatinetData,
}: any) => {
  return (
    <Box
      className="main-section"
      sx={{
        borderRadius: "8px",
        background: "#ffff",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
        margin: "16px",
      }}
    >
      <Box
        className="harder-section"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "0.5px solid #BFBFBF",
        }}
      >
        <Box
          className="patient-details-section"
          sx={{ padding: "12px 0px 0px 12px" }}
        >
          <Typography
            sx={{
              color: "#494E9D",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "16px",
            }}
          >
            {data?.name}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Typography
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "100%",
                padding: "12px 0px",
              }}
            >
              {`(${data?.gender.substring(0, 1).toUpperCase()},   ${
                data?.age?.no_of_years
              } y)`}
            </Typography>
            <Typography
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "100%",
                padding: "12px 0px",
              }}
            >
              {data?.phone}
            </Typography>
          </Box>
        </Box>
        <Box
          className="icon-section"
          sx={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <Box component="img" src="/images/my_patient_calender.svg" />
          <Box component="img" src="/images/my_patient_prescription.svg" />
          <IconButton
            aria-label="more"
            disabled
            id="long-button"
            aria-controls={openMenu ? "long-menu" : undefined}
            aria-expanded={openMenu ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              handleCheckPatient(data?.patient_id);
              setAnchorEl(e.currentTarget);
              setSelectedPatinetData(data);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        className="patient-appointments-details"
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "16px",
          padding: "12px 0px 12px 12px",
        }}
      >
        <DetailsCell heading="Doctor" name={data?.doctor_name} />
        <DetailsCell heading="UHID" name={data?.patient_hid} />
        <DetailsCell
          heading="Last Visited"
          name={
            data?.last_visit
              ? moment(data?.last_visit).format("ddd, DD MMM YYYY")
              : "-----"
          }
        />
        <DetailsCell
          heading="Upcoming Appointment"
          name={
            data?.upcoming_appointment_slot
              ? moment(data?.upcoming_appointment_slot).format(
                  "ddd, DD MMM YYYY"
                )
              : "----"
          }
        />
        <DetailsCell heading="Total Visits" name={data?.total_visits} />
        <DetailsCell heading="Created at" name={data?.created_at} />
      </Box>
    </Box>
  );
};

const DetailsCell = ({ heading, name }: any) => {
  return (
    <Box>
      <Typography
        sx={{
          color: "#00",
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "100%",
        }}
      >
        {heading}
      </Typography>
      <Typography
        sx={{
          color: "#6F6F6F",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "100%",
          padding: "8px 0px 0px 0px",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

export default PatientCard;
