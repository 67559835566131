import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { useTheme } from "@mui/material/styles";

import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import {
  deletePrescriptionDocument,
  fetchPatientDocuments,
} from "../../../../../redux/reducers/prescription.slice";
import moment from "moment";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import NoDocumentsIcon from "../../../../../shared/icons/NodocumentSAvailable";
import PDFModal from "../../../../../shared/Pdf-Modal/Pdf-Modal";
import { PATIENT_DOCUMENTS } from "../../../../../shared/constants";

type Props = {
  type?: any;
  startDate?: any;
  endDate?: any;
  searchTerm?: any;
  addedBy?: any;
  rowsCount?: any;
  setModalOpen?: any;
  setContentType?: any;
  setPreviewURL?: any;
  filterType?: any;
};
interface Data {
  id: number;
  document_type: string;
  document_name: string;
  uploaded_by: string;
  display_url: string;
  document_date: string;
  can_be_deleted: boolean;
  content_type: string;
  empty: string;
}

function createData(
  id: number,
  document_type: string,
  document_name: string,
  uploaded_by: string,
  display_url: string,
  document_date: string,
  can_be_deleted: boolean,
  content_type: string,
  empty: string
): Data {
  return {
    id,
    document_type,
    document_name,
    uploaded_by,
    display_url,
    document_date,
    can_be_deleted,
    content_type,
    empty,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array?.length
    ? array.map((el, index) => [el, index] as [T, number])
    : [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "document_type",
    numeric: true,
    disablePadding: false,
    label: "Document Type",
  },
  {
    id: "document_name",
    numeric: true,
    disablePadding: false,
    label: "Document Name",
  },
  {
    id: "uploaded_by",
    numeric: true,
    disablePadding: false,
    label: "Added By",
  },
  {
    id: "document_date",
    numeric: true,
    disablePadding: false,
    label: "Document Date",
  },

  {
    id: "empty",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              fontSize: "14px",
              fontWeight: "600 !important",
            }}
            // align={headCell.numeric ? "right" : "left"}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    account_id: params.get("account_id"),
  };
}

export default function Alldocuments({
  type,
  startDate,
  endDate,
  searchTerm,
  addedBy,
  rowsCount,
  setPreviewURL,
  setContentType,
  setModalOpen,
  filterType,
}: Props) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("document_type");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewDocumentModal, setViewDocumentModal] = React.useState<boolean>()
  const [documentUrl, setDocumentUrl] = React.useState<string>('')
  const [documentType, setDocumentType] = React.useState<string>()
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const dispatch = useDispatch();
  const { selectedAppointment } = useSelector(
    (state: any) => state.appointments
  );

  const {account_id} = getQueryParams();

  const TableTitles = {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "14px",
    color: "#000000",
  };

  const TableDocmentName = {
    color: "#6F6F6F",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "12px",
  };

  const Ellipse = {
    color: "#6F6F6F",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "12px",
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const Container = {
    width: "95%",
    height: "140px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    background: "#FFF",
    padding: "8px",
    marginTop: "12px",
  };

  const FlexRow = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "8px",
  };

  const FlexSecondrow = {
    width: "100%",
    display: "flex",
    gap: "47%",
    alignItems: "center",
    marginLeft: "8px",
  };

  const FlexColumn = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingLeft: "8px",
  };

  const FlexLeftColumn = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginLeft: "15px",
  };

  const {
    prescription,
    loaders: { fetchingPatientDocuments },
  }: any = useSelector((state: any) => state.prescription);

  const Allrows = (prescription?.patientDocuments?.data || []).map(
    (doc: any, index: number) =>
      createData(
        doc?.id,
        doc?.document_type,
        doc?.file_name,
        doc?.uploaded_by,
        doc?.display_url,
        doc?.Document_date,
        doc?.can_be_deleted,
        doc?.content_type,
        ""
      )
  );
  // Group rows by document_type
  const rowsByDocumentType = Allrows.reduce((groupedRows: any, row: any) => {
    const { document_type } = row;

    if (!groupedRows[document_type]) {
      groupedRows[document_type] = [];
    }
    groupedRows[document_type].push(row);
    return groupedRows;
  }, {});
  const rows = type == "all_documents" ? Allrows : rowsByDocumentType[type];
  const TodaysDate = moment().format("YYYY-MM-DD");
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(today.getMonth() - 6);
  const formattedSixMonthsAgo = sixMonthsAgo.toISOString().split("T")[0];
  React.useEffect(() => {
    getPrescriptionDocuments(startDate, endDate, filterType, addedBy);
  }, [type, startDate, endDate, addedBy, filterType]);

  const getPrescriptionDocuments = async (
    start: any,
    end: any,
    filterType?: any,
    addedBy?: any
  ) => {
    try {
      const response = await dispatch(
        fetchPatientDocuments({
          params: {
            patient_id: selectedAppointment?.patient?.patient_id,
            start_date: start,
            end_date: end,
            ...(filterType &&
              filterType !== "all_documents" && { document_type: filterType }),
            ...(addedBy && { addedBy: addedBy }),
          },
        })
      );
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleMenuClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const handlePrint = async (row: any) => {
    const response = await fetch(row.display_url);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    if (row.content_type?.split("/")[0] === "image") {
      setPreviewURL(row.display_url);
    } else {
      setPreviewURL(blobUrl);
    }
    setContentType(row.content_type);
    setModalOpen(true);
    handleMenuClose();
  };

  const handleMenuItemClick = (row: any, action: any) => {
    if (action === "delete") {
      setSelectedRowId(row.id);
      setOpenConfirmationDialog(true);
    } else if (action === "download") {
      downloadFile(row);
    } else if (action === "print") {
      handlePrint(row);
    }
  };

  const checkPhone = () => {
    const u = navigator.userAgent;
    return !!(u.match(/Android/i) || u.match(/iPhone/i) );
  }

  const downloadFile = async (row: any) => {
    try {

      const androidIosStatus = checkPhone();

      if(androidIosStatus && account_id !== null){
        window.open(row.display_url)
        handleMenuClose();
        return;
      }

      const response = await fetch(row.display_url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = row.document_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    handleMenuClose();
  };

  const handleViewDocument = (row: any) => {
    if (!isMobileScreen) {
      setDocumentType(row?.content_type)
      setDocumentUrl(row?.display_url)
      setViewDocumentModal(true)
    }
    else {
      window.open(row?.display_url)
    }
  }

  const DocumentTypeIcons = (data: string, date: any, row?: any) => {
    const DocumentDate = moment().format("YYYY-MM-DD");
    const alignStylesMobile = {
      display: "flex",
      alignItems: "center",
      gap: "5px",
      width: "28px",
    };
    const alignStyles = {
      display: "flex",
      gap: "8px",
      alignItems: "center",
    };

    const BadgeStylesMobile = {
      left: "16px",
    };
    const BadgeStyle = {
      top: "0px",
      right: "-22px",
    };
    return (
      <Box
        sx={isMobileScreen ? alignStylesMobile : alignStyles}
        height="28px"
      >
        {data === "prescription" ? (
          <img src="/images/prescription.svg" alt="prescription" />
        ) : data === "discharge_summary" ? (
          <img src="/images/discharge_summary.svg" alt="discharge_summary" />
        ) : data === "diagnostics" ? (
          <img src="/images/diagnostics.svg" alt="diagnostics" />
        ) : (
          <img src="/images/others.svg" alt="others" />
        )}
        {!isMobileScreen && (
          <span onClick={()=>{handleViewDocument(row)}} style={{ textTransform: "capitalize", textDecoration:"underline" }}>{data} </span>
        )}
        {isMobileScreen && (
          <Typography sx={TableDocmentName} textTransform={"capitalize"}>
            {data}
          </Typography>
        )}
        {date === DocumentDate && (
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={isMobileScreen ? BadgeStylesMobile : BadgeStyle}
            color="error"
            badgeContent="New"
          ></Badge>
        )}
      </Box>
    );
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const filteredRows =
    rows &&
    rows?.filter(
      (row: any) =>
        row?.document_name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        row?.document_date?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );

  const SearchedRows =
    type === "all_documents"
      ? Allrows &&
        Allrows?.filter(
          (row: any) =>
            row?.document_name
              ?.toLowerCase()
              ?.includes(searchTerm.toLowerCase()) ||
            row?.document_date
              ?.toLowerCase()
              ?.includes(searchTerm.toLowerCase())
        )
      : Allrows &&
        Allrows?.filter((row: any) =>
          row?.document_type?.toLowerCase()?.includes(type?.toLowerCase())
        );
  React.useEffect(() => {
    if (isMobileScreen) {
      rowsCount(SearchedRows.length);
    }
  }, [SearchedRows]);

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredRows, order, orderBy, page, rowsPerPage]
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  const handleConfirmDeletion = async () => {
    try {
      await dispatch(deletePrescriptionDocument(selectedRowId));
      setOpenConfirmationDialog(false);
      setTimeout(() => {
        rows?.pop();
        getPrescriptionDocuments(formattedSixMonthsAgo, TodaysDate);
      }, 500);
      handleMenuClose();
    } catch (error) {
      console.error("Error deleting prescription document:", error);
    }
  };

  const handleCancelDeletion = () => {
    setOpenConfirmationDialog(false);
  };
  const DialogHeaderStyles = () => {
    return {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "28px",
      padding: "16px",
    };
  };
  const DialogFooterStyles = () => {
    return {
      display: "flex",
      justifyContent: "space-between",
      padding: "14px",
    };
  };
  const DialogLayoutStyles = () => {
    if (isMobileScreen) {
      return {
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
        "& .css-ypiqx9-MuiDialogContent-root": {
          padding: 0,
        },
      };
    } else {
      return {};
    }
  };

  const PaperPropsFilterStyle = () => {
    if (isMobileScreen) {
      return {
        margin: 0,
        width: "100%",
      };
    } else {
      return {};
    }
  };

  const Buttonstyles = () => {
    return {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
    };
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleDocumentModalClose = () => {
     setViewDocumentModal(false)
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <CustomizedDialogs
          Buttonstyles={Buttonstyles()}
          maxWidth="md"
          headerStyles={DialogHeaderStyles()}
          DialogOpen={openConfirmationDialog}
          onHandleDialog={handleCancelDeletion}
          Layoutstyles={DialogLayoutStyles()}
          PaperPropsStyle={PaperPropsFilterStyle()}
          onHandleSave={handleConfirmDeletion}
          footerStyles={DialogFooterStyles()}
          footerCancel="No, keep it"
          footerSave="Yes, delete!"
          title="Delete Document"
        >
          <Box sx={{ borderBottom: "0.5px solid #BBB", paddingBottom: "10px" }}>
            <Typography
              sx={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "16px",
                fontWeight: 400,
                paddingLeft: "16px",
              }}
            >
              Are you sure you want to delete the patient document?
            </Typography>
          </Box>
        </CustomizedDialogs>{" "}
        {!isMobileScreen && (
          <>
            {fetchingPatientDocuments ? (
              <>
                <Skeleton sx={{ height: "70px" }} animation="wave" />
                <Skeleton sx={{ height: "70px" }} animation="wave" />
                <Skeleton sx={{ height: "70px" }} animation="wave" />
                <Skeleton sx={{ height: "70px" }} animation="wave" />
                <Skeleton sx={{ height: "70px" }} animation="wave" />
              </>
            ) : (
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows?.length}
                    />

                    <TableBody>
                      {visibleRows.map((row: any, index: number) => {
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            // selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell align="left" title={row.document_type}>
                              <Box>
                                {DocumentTypeIcons(
                                  row.document_type,
                                  row.document_date,
                                  row
                                )}{" "}
                              </Box>
                            </TableCell>
                            <TableCell align="left" title={row.document_name}>
                              <p
                                style={{
                                  whiteSpace: "nowrap",
                                  width: "186px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  paddingLeft: "10px",
                                }}
                              >
                                {row.document_name}
                              </p>
                            </TableCell>
                            <TableCell align="left" title={row.uploaded_by}>
                              {row.uploaded_by}
                            </TableCell>
                            <TableCell align="left" title={row.document_date}>
                              {row.document_date}
                            </TableCell>
                            <TableCell align="right">
                              <MoreVertIcon
                                onClick={(event) =>
                                  handleMenuClick(event, row.id)
                                }
                              />
                              <Menu
                                id={`menu-${row.id}`}
                                anchorEl={anchorEl}
                                open={
                                  Boolean(anchorEl) && selectedRowId === row.id
                                }
                                onClose={handleMenuClose}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "220px",
                                  right: "20px",
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                  }}
                                  onClick={() =>
                                    handleMenuItemClick(row, "print")
                                  }
                                >
                                  <img
                                    src="/images/icon_print.svg"
                                    width="20px"
                                    height="22px"
                                    style={{ color: "rgba(0,0,0,0.56)" }}
                                  />
                                  <span>Print</span>
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                  }}
                                  onClick={() =>
                                    handleMenuItemClick(row, "download")
                                  }
                                >
                                  <Box
                                    width="20px"
                                    height="22px"
                                    style={{ color: "rgba(0,0,0,0.56)" }}
                                  >
                                    <FileDownloadOutlinedIcon />
                                  </Box>

                                  <span>Download</span>
                                </MenuItem>
                                {row.can_be_deleted && (
                                  <MenuItem
                                    sx={{
                                      display: "flex",
                                      gap: "8px",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      handleMenuItemClick(row, "delete")
                                    }
                                  >
                                    <img
                                      src="/images/icon_delete.svg"
                                      width="20px"
                                      height="22px"
                                      style={{ color: "rgba(0,0,0,0.56)" }}
                                    />
                                    <span>Delete</span>
                                  </MenuItem>
                                )}
                              </Menu>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                {!visibleRows.length ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    borderRadius="8px"
                    sx={{
                      background: "#ffffff",
                      height: "calc(100vh - 400px)",
                    }}
                  >
                    <NoDocumentsIcon width="200" height="200" />
                  </Box>
                ) : (
                  ""
                )}

                {rows?.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[6, 12, 30]}
                    component="div"
                    count={rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            )}
          </>
        )}
        {isMobileScreen && (
          <>
            <Box>
              {SearchedRows &&
                SearchedRows.map((data: any) => {
                  return (
                    <Box sx={Container}>
                      <Box sx={FlexRow}>
                        <Box sx={FlexColumn}>
                          <Typography sx={TableTitles}>
                            Document Name
                          </Typography>
                          <Tooltip title={data?.document_name}>
                            <Typography sx={Ellipse}>
                              {data?.document_name}
                            </Typography>
                          </Tooltip>
                        </Box>
                        <Box sx={FlexLeftColumn}>
                          <Typography sx={TableTitles}>Added by</Typography>
                          <Typography sx={TableDocmentName}>
                            {data?.uploaded_by}
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton size="large" edge="start">
                            <MoreVertIcon
                              onClick={(event) =>
                                handleMenuClick(event, data.id)
                              }
                              sx={{ color: "#000" }}
                            />
                          </IconButton>
                          <Menu
                            id={`menu-${data.id}`}
                            anchorEl={anchorEl}
                            open={
                              Boolean(anchorEl) && selectedRowId === data.id
                            }
                            onClose={handleMenuClose}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "220px",
                              right: "20px",
                            }}
                          >
                            <MenuItem
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                              onClick={() => handleMenuItemClick(data, "print")}
                            >
                              <img
                                src="/images/icon_print.svg"
                                width="20px"
                                height="22px"
                                style={{ color: "rgba(0,0,0,0.56)" }}
                              />
                              <span>Print</span>
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                              onClick={() =>
                                handleMenuItemClick(data, "download")
                              }
                            >
                              <Box
                                width="20px"
                                height="22px"
                                style={{ color: "rgba(0,0,0,0.56)" }}
                              >
                                <FileDownloadOutlinedIcon />
                              </Box>

                              <span>Download</span>
                            </MenuItem>
                            {data.can_be_deleted && (
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  gap: "8px",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  handleMenuItemClick(data, "delete")
                                }
                              >
                                <img
                                  src="/images/icon_delete.svg"
                                  width="20px"
                                  height="22px"
                                  style={{ color: "rgba(0,0,0,0.56)" }}
                                />
                                <span>Delete</span>
                              </MenuItem>
                            )}
                          </Menu>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          height: "1px",
                          borderBottom: "1px solid #EEE",
                        }}
                      ></Box>

                      <Box sx={FlexSecondrow}>
                        <Box sx={FlexColumn}>
                          <Typography sx={TableTitles}>Type</Typography>
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            {DocumentTypeIcons(
                              data.document_type,
                              data.document_date
                            )}
                          </Box>
                        </Box>
                        <Box sx={FlexLeftColumn}>
                          <Typography sx={TableTitles}>Date</Typography>
                          <Typography sx={TableDocmentName}>
                            {moment(data?.document_date).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>

            {!SearchedRows.length ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                marginTop="20px"
                borderRadius="8px"
                sx={{
                  background: "#ffffff",
                  height: !isMobileScreen
                    ? "calc(100vh - 400px)"
                    : "calc(100vh - 198px)",
                }}
              >
                <Box component="img" src="/images/icon_no_patient_time.svg" />
                <Typography
                  sx={{
                    color: "#6F6F6F",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "18px",
                    marginTop: "24.37px",
                  }}
                >
                  No documents available
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </>
        )}
      </Box>
      <PDFModal
        // From={PATIENT_DOCUMENTS}
        pdfUrl={documentUrl}
        displayType={documentType}
        handleClose={handleDocumentModalClose}
        modalOpen={viewDocumentModal}
      />
    </>
  );
}
