import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Header from "./components/prescription/Header";
import LeftPanel from "./components/prescription/LeftPanel";
import RightPanel from "./components/prescription/RightPanel";
import {
  fetchAppointmentForPrescription,
  fetchDoctorTemplates,
  resetSelectedTemplate,
  setSelectedAppointment,
  setSelectedTemplate,
} from "../redux/reducers/appointment.slice";
import {
  resetPastPrescriptions,
  resetSignedURL,
  setEditRxSecMobile,
  setInstantRxData,
  setPastPrescriptions,
  setPrevPrescriptionDetails,
} from "../redux/reducers/prescription.slice";
import { fetchAccountDetails, fetchProfile, setToken } from "../redux/reducers/auth.slice";

function getQueryParams() {
  const params = new URLSearchParams(window.location.search);
  return {
    showVideo: params.get("showVideo"),
    showTimeline: params.get("timeline"),
    showDocument: params.get("document"),
    backbutton: params.get("backbutton"),
  };
}

const PrescriptionPage = () => {
  const params: any = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const appointmentID = params["appointment_id"];
  const [showVideoTab, setVideo] = useState(false);
  const dispatch = useDispatch();
  const { showVideo, showTimeline, showDocument, backbutton } =
    getQueryParams();
  const [selectedAppointmentState, setselectedAppointment] =
    useState<any>(null);
  const [activeTab, setActiveTab] = useState(
    showTimeline ? 1 : showDocument ? 2 : 0
  );
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { confirmedAppoitments, doctorTemplates, selectedAppointment, error, loader } =
    useSelector((state: any) => state.appointments);

  const {
    prescription: { rxData, editRxSecMobile },
  } = useSelector((state: any) => state.prescription);

  // if any rx section is being edited in the mobile resp. mode hide header
  const viewTypes = Array.isArray(rxData)
    ? rxData?.map((sec) => sec.view_type)
    : [];

  useEffect(() => {
    if (showVideo) {
      setVideo(showVideo === "true" ? true : false);
    }
  }, [showVideo]);

  useEffect(() => {
    if (selectedAppointmentState) {
      if (selectedAppointmentState.doctor_id) {
        dispatch(
          fetchDoctorTemplates({
            params: {
              doctor_id: selectedAppointmentState?.doctor_id,
              multi: true,
            },
          })
        );
      }
    }
  }, [selectedAppointmentState]);

  useEffect(() => {
    if (searchParams.get("token")) {
      const token: any = searchParams.get("token");
      localStorage.setItem("emrToken", token);
      dispatch(setToken(token));
      // dispatch(fetchProfile());
    }
  }, [searchParams.get("token")]);

  useEffect(()=>{
    if(searchParams.get("account_id") && searchParams.get("doctor_id")){
      dispatch(
        fetchAccountDetails({
          doctor_id: searchParams.get("doctor_id"),
          account_id: searchParams.get("account_id"),
        })
      );
    }
    
  },[searchParams.get("account_id"),searchParams.get("doctor_id")])

  useEffect(() => {
    // below code is set to avoid an unexpected issue with editRxSecMobile being set to "COMPLAINTS" by default
    if (!isMobileScreen) {
      dispatch(setEditRxSecMobile("NoSectionActiveToEdit"));
    }
    //
    return () => {
      dispatch(resetSelectedTemplate());
      dispatch(resetSignedURL());
      dispatch(resetPastPrescriptions());
      dispatch(setInstantRxData([] as any));
      dispatch(setPastPrescriptions([] as any));
      dispatch(setPrevPrescriptionDetails([] as any));
    };
  }, []);

  useEffect(() => {
    if (doctorTemplates && doctorTemplates.length > 0) {
      dispatch(setSelectedTemplate(doctorTemplates[0]));
    }
  }, [doctorTemplates]);

  useEffect(() => {
    if (confirmedAppoitments && confirmedAppoitments.rows) {
      const filteredAppointments = confirmedAppoitments.rows.filter(
        (_data: any) => {
          return _data.id === appointmentID;
        }
      );
      // Assuming you want to select the first matching appointment

      if (filteredAppointments.length > 0) {
        dispatch(setSelectedAppointment(filteredAppointments[0]));
        setselectedAppointment(filteredAppointments[0]);
      }
    } else {
      if (appointmentID) {
        dispatch(fetchAppointmentForPrescription(appointmentID));
      }
    }
  }, [appointmentID, confirmedAppoitments]);

  useEffect(() => {
    if (selectedAppointment) {
      setselectedAppointment(selectedAppointment);
    }
  }, [selectedAppointment]);

  return (
    <>
      {selectedAppointmentState && (
        <>
          {!viewTypes.includes(editRxSecMobile) && (
            <Header
              selectedAppointment={selectedAppointment}
              setVideo={() => setVideo(true)}
              activeTab={activeTab}
              backbutton={backbutton}
              setActiveTab={setActiveTab}
            />
          )}

          <Grid
            display="flex"
            sx={{
              background: isMobileScreen ? "#ffffff" : "#F4F6F9",
              pb: isMobileScreen ? 0 : 0,
              gap: isMobileScreen ? "0px" : "10px",
            }}
          >
            <Grid>
              {!isMobileScreen && (
                <LeftPanel
                  showVideo={showVideoTab}
                  selectedAppointment={selectedAppointmentState}
                  setVideo={(key: any) => setVideo(key)}
                  activeTab={activeTab}
                />
              )}
            </Grid>
            <Grid width="100%">
              <RightPanel
                setActiveTab={setActiveTab}
                isMobileScreen={isMobileScreen}
                showVideo={showVideoTab}
                selectedAppointment={selectedAppointmentState}
                setVideo={(key: any) => setVideo(key)}
                activeTab={activeTab}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default PrescriptionPage;
