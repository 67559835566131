import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";


export const GradientBox = ({ children }) => {
    const theme = useTheme();
    return (
      <Box
        height={"100%"}
        margin={0}
        padding={0}
        sx={{
          background: ``,
        }}
      >
        {children}
      </Box>
    );
  };