import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import TabletIcon from "../../../../shared/icons/TabletIcon";
import LabTestIcon from "../../../../shared/icons/LabTestIcon";
import NoPrescriptionDataIcon from "../../../../shared/icons/NoPrescriptionDataIcon";
import VideoConsultation from "../../../../shared/icons/VideoConsultation";
import {
  ADVICE,
  COMPLAINTS,
  DIAGNOSIS,
  INVESTIGATIONS,
  MEDICATION,
  PATIENT_HISTORY,
} from "../../../../shared/constants";
import PrescriptionHeader from "../utils/PrescriptionHeader";
import {
  fetchPrescriptionDetails,
  setPastPrescriptions,
  setPrevPrescriptionDetails,
} from "../../../../redux/reducers/prescription.slice";
import { useDispatch, useSelector } from "react-redux";
import ReverseIcon from "../../../../shared/icons/ReverseIcon";
import OnwardsIcon from "../../../../shared/icons/OnwardsIcon";
import LeftPanelSkeletonLoader from "../utils/LeftPanelSkeletonLoader";
import moment from "moment";
import { fetchDisplayUrlData } from "../../../../shared/Utils/utils";

interface ValueData {
  name: string;
  since: string;
  meal_pref: string;
  value: string;
  duration: string;
  frequency: string;
}
interface RowsData {
  value: ValueData[];
}
interface PrescriptionData {
  header: string;
  view_type: string;
  rows: RowsData[];
}
const LastConsultationSection = ({
  showVideo,
  appointment_status,
  activeTab,
  isMobileScreen,
}: any) => {
  const dispatch = useDispatch();
  const {
    prescription,
    loaders: { fetchingPrescriptionDetails },
  } = useSelector((state: any) => state.prescription);
  const { selectedAppointment } = useSelector(
    (state: any) => state.appointments
  );
  const [prescriptionLimit, setPrescriptionLimit] = useState(0);
  const [showPrescription, setShowPrescription] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [prescriptionDetails, setPrescriptionDetails] = useState([]);
  const [pastPrescriptionDisplayUrl, setPastPrescriptionDisplayUrl] =
    useState<any>([]);

  const getPrescriptionDetails = async () => {
    if (prescription?.pastPrescriptions?.length) {
      setPastPrescriptionDisplayUrl(prescription?.pastPrescriptions);
      if (prescription?.pastPrescriptions[0]?.display_url) {
        const displayUrlData = await fetchDisplayUrlData(
          prescription?.pastPrescriptions[0]?.display_url
        );
        if (displayUrlData?.sections?.length) {
          setPrescriptionDetails(displayUrlData?.sections);
          // const prevPrescriptionSections = displayUrlData.sections;
          // dispatch(
          //   setPrevPrescriptionDetails([
          //     ...prevPrescriptionSections,
          //     { past_prescriptions: prescription?.pastPrescriptions[0] },
          //   ])
          // );
          setShowPrescription(true);
          setButtonDisable(false);
        } else {
          setPrescriptionDetails([]);
          dispatch(setPrevPrescriptionDetails([]));
          // prescriptionLimit === 0 && dispatch(setPastPrescriptions([]));
          setButtonDisable(true);
        }
      } else {
        setPrescriptionDetails([]);
        // dispatch(setPrevPrescriptionDetails([]));
        // prescriptionLimit === 1 && dispatch(setPastPrescriptions([]));
        setButtonDisable(true);
      }
    }
    if (prescription?.pastPrescriptions?.length === 1) {
      setButtonDisable(true);
    }
  };

  useEffect(() => {
    getPrescriptionDetails();
  }, [prescription?.pastPrescriptions]);

  const handlelastConsult = async (limit: number) => {
    if (prescriptionLimit === pastPrescriptionDisplayUrl?.length) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
    const pastPrescriptionData = await fetchDisplayUrlData(
      pastPrescriptionDisplayUrl[limit]?.display_url
    );
    if (pastPrescriptionData?.sections?.length) {
      setPrescriptionDetails(pastPrescriptionData?.sections);
    }
  };

  useEffect(() => {
    if (pastPrescriptionDisplayUrl?.length > 0) {
      handlelastConsult(prescriptionLimit);
    }
  }, [prescriptionLimit]);

  if (fetchingPrescriptionDetails) {
    return (
      <Box
        sx={{
          background: "#ffffff",
          // width: "385px",
          padding: "10px 10px 20px 10px",
          borderRadius: "8px",
          overflowY: "auto",
          height: "100vh",
          // height: "calc(100vh - 160px)",
          // "&::-webkit-scrollbar": {
          //   display: "none",
          // },
        }}
      >
        <LeftPanelSkeletonLoader />
      </Box>
    );
  } else {
    return (
      <Grid className="last-consult" sx={{ background: "#ffff", borderRadius:"8px" }}>
        {showPrescription && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="10px 10px 0px 10px"
          >
            <Typography fontSize="20px" fontWeight="700" lineHeight="24px">
              Last Consult
            </Typography>
            <Box display="flex" gap="15px">
              <Tooltip title={"View previous Rx"}>
                <Button
                  sx={{ minWidth: "30px", minHeight: "30px" }}
                  onClick={() => setPrescriptionLimit(prescriptionLimit - 1)}
                  disabled={prescriptionLimit === 0}
                >
                  <ReverseIcon
                    fillColor={prescriptionLimit === 0 ? "#ABABAB" : "#545F71"}
                  />
                </Button>
              </Tooltip>
              <Tooltip title={"View newer Rx"}>
                <Button
                  sx={{ minWidth: "30px", minHeight: "30px" }}
                  onClick={() => setPrescriptionLimit(prescriptionLimit + 1)}
                  disabled={buttonDisable}
                >
                  <OnwardsIcon
                    fillColor={buttonDisable ? "#ABABAB" : "#545F71"}
                  />
                </Button>
              </Tooltip>
            </Box>
          </Box>
        )}
        {prescriptionDetails?.length > 0 ? (
          <Box
            className="last-consult"
            sx={{
              padding: "10px",
              overflow: "auto",
              height: showVideo
                ? "calc(100vh - 450px)"
                : "calc(100vh - 200px)",
              // "&::-webkit-scrollbar": {
              //   display: "none",
              // },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                background: "#F2F3FF",
                borderRadius: "8px",
                height: "60px",
              }}
            >
              {prescription?.prescriptionDetails[prescriptionLimit - 1]
                ?.appointment_type === "online" ? (
                <VideoConsultation />
              ) : (
                <Box
                  component="img"
                  src="/images/clinicIcon2.svg"
                  sx={{ width: "25px", height: "25px" }}
                />
              )}
              <Typography fontSize="20px" fontWeight="600" lineHeight="24px">
                Consultation
              </Typography>
              <Typography fontSize="14px" fontWeight="400" lineHeight="19px">
                {prescription?.prescriptionDetails[prescriptionLimit - 1]
                  ?.prescription_date &&
                  moment(
                    prescription?.prescriptionDetails[prescriptionLimit - 1]
                      ?.prescription_date
                  ).format("DD MMM YYYY")}
              </Typography>
            </Box>
            {prescriptionDetails
              .filter(
                (item: PrescriptionData) =>
                  item?.view_type === COMPLAINTS ||
                  item?.view_type === DIAGNOSIS
              )
              .map((data: PrescriptionData) => {
                const namesArray = data?.rows[0].value
                  .map((item) => item?.name)
                  .filter((name) => name !== "");
                const concatedName = namesArray.join(",");
                return (
                  <Box marginTop="20px">
                    <PrescriptionHeader
                      data={data}
                      appointment_status={appointment_status}
                      activeTab={activeTab}
                    />
                    <Box display="flex">
                      {concatedName ? (
                        <Typography
                          fontSize="16px"
                          fontWeight="400"
                          lineHeight="18.75px"
                          textAlign="left"
                          color="rgba(0, 0, 0, 0.6)"
                          width="318px"
                        >
                          {concatedName}
                        </Typography>
                      ) : (
                        <Typography
                          fontSize="16px"
                          fontWeight="400"
                          lineHeight="18.75px"
                          textAlign="left"
                          color="rgba(0, 0, 0, 0.6)"
                        >
                          No Data Available
                        </Typography>
                      )}
                    </Box>
                  </Box>
                );
              })}
            {prescriptionDetails
              .filter(
                (item: PrescriptionData) => item?.view_type === MEDICATION
              )
              .map((data: PrescriptionData) => {
                return (
                  <Box marginTop="20px">
                    <PrescriptionHeader
                      data={data}
                      appointment_status={appointment_status}
                      activeTab={activeTab}
                    />
                    {data?.rows[0]?.value?.length > 0 ? (
                      data?.rows[0]?.value.map((item) => {
                        return (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            marginTop="16px"
                          >
                            <Box display="flex" alignItems="center">
                              <TabletIcon />
                              <Box marginLeft="10px">
                                <Typography
                                  fontSize="16px"
                                  fontWeight="600"
                                  lineHeight="18.75px"
                                  color="rgba(0, 0, 0, 0.6)"
                                >
                                  {item?.name.split("(")[0].trim()}
                                </Typography>
                                <Typography
                                  fontSize="16px"
                                  fontWeight="400"
                                  lineHeight="18.75px"
                                  paddingTop="10px"
                                  variant="h6"
                                  color="#ABABAB"
                                >
                                  {item?.meal_pref}
                                </Typography>
                              </Box>
                            </Box>
                            <Box textAlign="end">
                              <Typography
                                fontSize="16px"
                                fontWeight="600"
                                lineHeight="18.75px"
                                color="rgba(0, 0, 0, 0.6)"
                              >
                                {item?.frequency}
                              </Typography>
                              <Typography
                                fontSize="16px"
                                fontWeight="400"
                                lineHeight="18.75px"
                                paddingTop="10px"
                                color="#ABABAB"
                              >
                                {item?.duration}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })
                    ) : (
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        lineHeight="18.75px"
                        textAlign="left"
                        color="rgba(0, 0, 0, 0.6)"
                      >
                        No Data Available
                      </Typography>
                    )}
                  </Box>
                );
              })}
            {prescriptionDetails
              .filter(
                (item: PrescriptionData) => item?.view_type === INVESTIGATIONS
              )
              .map((data: PrescriptionData) => (
                <Box marginTop="20px">
                  <PrescriptionHeader
                    data={data}
                    appointment_status={appointment_status}
                    activeTab={activeTab}
                  />
                  {data?.rows[0]?.value?.length > 0 ? (
                    data?.rows[0]?.value.map((item: any) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="10px"
                          marginTop="16px"
                        >
                          {item?.diagnostic_type === "PATHOLOGY" ? (
                            <img
                              style={{ pointerEvents: "none" }}
                              src="/images/pathology.svg"
                              alt="pathology"
                            />
                          ) : item?.diagnostic_type === "RADIOLOGY" ? (
                            <img
                              style={{ pointerEvents: "none" }}
                              src="/images/radiology.svg"
                              alt="pathology"
                            />
                          ) : (
                            <img
                              style={{ pointerEvents: "none", width: "22px" }}
                              src="/images/others_diagnostic_type.svg"
                              alt="others"
                            />
                          )}
                          <Typography
                            fontSize="16px"
                            fontWeight="400"
                            lineHeight="18.75px"
                            textAlign="left"
                            color="rgba(0, 0, 0, 0.6)"
                          >
                            {item?.name}
                          </Typography>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight="18.75px"
                      textAlign="left"
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      No Data Available
                    </Typography>
                  )}
                </Box>
              ))}

            {prescriptionDetails
              .filter((item: PrescriptionData) => item?.view_type === ADVICE)
              .map((data: PrescriptionData) => (
                <Box marginTop="20px">
                  <PrescriptionHeader
                    data={data}
                    appointment_status={appointment_status}
                    activeTab={activeTab}
                  />
                  {data?.rows[0]?.value?.length > 0 ? (
                    data?.rows[0]?.value.map((item, index) => {
                      return (
                        <Typography color="rgba(0, 0, 0, 0.6)">
                          {index + 1}.{item.value}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight="18.75px"
                      textAlign="left"
                      color="rgba(0, 0, 0, 0.6)"
                    >
                      No Data Available
                    </Typography>
                  )}
                </Box>
              ))}
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={showVideo ? "20vh" : "65vh"}
            flexDirection="column"
            borderRadius="8px"
            sx={{
              boxShadow: isMobileScreen
                ? "0px 4px 24px 0px rgba(0, 0, 0, 0.08)"
                : null,
              margin: "10px",
            }}
          >
            <NoPrescriptionDataIcon />
            <Typography
              fontSize={isMobileScreen ? "19px" :"24px"}
              fontWeight="700"
              lineHeight="28.13px"
              textAlign="center"
              color="#666BB8"
            >
              No past consultations to show
            </Typography>

            <Typography
              fontSize={isMobileScreen ? "12px" :"14px"}
              fontWeight="400"
              lineHeight="18px"
              color="#6F6F6F"
            >
              When you do, you will see them here
            </Typography>
          </Box>
        )}
      </Grid>
    );
  }
};
export default LastConsultationSection;
