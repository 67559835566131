import {  Box, useMediaQuery, useTheme,Grid, Paper,Typography,TextField,FormControl,InputLabel,OutlinedInput,Button,CircularProgress ,Dialog,InputAdornment , DialogContent,Tooltip,DialogActions } from '@mui/material';
import { useLocation, useNavigate ,useParams,Link  } from 'react-router-dom';
import {  useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { APIClient } from "../../../../shared/Utils/api-client";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { API_ROUTE_PRESCRIPTION_VIEW_URL } from "../../../../shared/routes/route.constants";
import Card from "../../prescription/utils/Card";
import CardHeader from "../../prescription/utils/CardHeader";
import { styled } from "@mui/system";
import { ShowSkeleton,NoData } from './RxCommon';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import{ROUTE_ADMIN_LIST_RX_TEMPLATES} from "../../../../shared/routes/route.constants";
import SystemicExamination from "../../prescription/tabcomponents/RX/SystemicExamination";
import PresentingComplaints from "../../prescription/tabcomponents/RX/PresentingComplaints";
import PhysicalFindings from "../../prescription/tabcomponents/RX/PhysicalFindings";
import KneeExamination from "../../prescription/tabcomponents/RX/KneeExamination";
import InvestigationMonitoring from "../../prescription/tabcomponents/RX/InvestigationMonitoring";
import GeneralPhysicalExamination from "../../prescription/tabcomponents/RX/GeneralPhysicalExamination";
import FootExam from "../../prescription/tabcomponents/RX/FootExam";
import Vitals from "../../prescription/tabcomponents/RX/Vitals";
import OBGHistory from "../../prescription/tabcomponents/RX/obghistory/OBGHistory";
import OBGNotes from '../../prescription/tabcomponents/RX/obgnotes/OBGNotes';
import DiabetesAssessment from '../../prescription/tabcomponents/RX/DiabetesAssessment';
const RxBuilder = () => {
  const navigate = useNavigate();
  const { template_id } = useParams();
  

  const [loading, setLoading] = useState(true);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const[vitalsInputs,setVitalsInput]=useState([]);
  //Hooks
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const handleError = (error) => {
    setErrorOpen(true);
    setErrorMessage(error || 'An error occurred.');
  };
  const handelAlert = (message) => {
    setAlertOpen(true);
    setAlertMessage(message);
  }
  const vitalHeightInput={
    "unit": "cm",
    "key": "height",
    "label": "Height",
    "value":""
  }
  const vitalWeightInput={
    "unit": "kg",
    "key": "weight",
    "label": "Weight",
    "value":""
  }

  const [templateData, setTemplateData] = useState([]);
  const [vitalsData, setVitalsData] = useState([]);
  
  const [vitalsFormKey, setVitalsFormKey] = useState(null);
  const [formTemplateData, setFormTemplateData] = useState([]);
  const getTemplate = async () => {
    const response = await APIClient.get("prescription/get-template", {
      params: {
        type: "section",
        active_account_id : "svaas"
      },
    });
    if (response?.data?.statusCode === 200) {
      setTemplateData(response?.data?.data);
      console.log(response?.data?.data);
      const vitalsData =response?.data?.data? (response.data.data).filter(item => item.formdata.view_type === "VITALS").map(item => item.formdata.rows):[];

      console.log("vitalsData",vitalsData);
      if(vitalsData.length>0){
        setVitalsInput(vitalsData[0]);
      }  
      setLoading(false);
    }
  }

  const getEditTemplate = async (template_id) => {
    const response = await APIClient.get("prescription/get-template", {
      params: {
        type: "all_templates",
        file_type:"template",
        id:template_id,
        active_account_id : "svaas"
      },
    });
    console.log("response---",response);
    if (response?.data?.statusCode === 200) {
      setTemplateLoading(false);
      const rawJSON = await  axios.get(response.data.data[0].display_url);
      console.log("rawJSON",rawJSON,rawJSON?.data);
      console.log("rawJSON.data",rawJSON?.data.sections);

      if(rawJSON?.status===200){
        setFormTemplateData(rawJSON?.data.sections);
      }
      setformNameValue(response?.data?.data[0].filename);
      //setTemplateData(response?.data?.data);
      console.log("response",response?.data?.data);
    }
  }

  
  useEffect(() => {
      if(typeof template_id!=='undefined'){
        setTemplateLoading(true);
        getEditTemplate(template_id);
      }
      getTemplate();
    
  }, []);

  const [formNameValue, setformNameValue] = useState('');
  
  const handleformNameChange = (event) => {
    if((event.target.value).replace(/[^\w\s]/gi, '').trim().length>100){
      handleError("Template name should be less than 100 characters");
      return;
    }
    
    setformNameValue(event.target.value);
  };
  const goToEditPage = (template_id) => {
    console.log("template_id goto",template_id);
    setOpenSaveDialog(false);
    navigate(ROUTE_ADMIN_LIST_RX_TEMPLATES, { replace: true });
  }
  const handleVitalsSaveClick =async () => {
    let nFormTemplateData = formTemplateData;
    nFormTemplateData[vitalsFormKey].rows = vitalsData;
    setFormTemplateData(nFormTemplateData);
    setOpenVitalsSaveDialog(false);
  }
  const handleSaveClick = async () => {
    setDisabledSave(true);
    setSaving(true);
    try{
      //handel already saved template means update
      if(typeof template_id!=='undefined'){
        const templateParam = {
          type: "update_instant_template",
          content_type: 'application/json',
          active_account_id: 'svaas',
          template_id:template_id
        };
        const res = await APIClient.get(API_ROUTE_PRESCRIPTION_VIEW_URL(), {
          params:templateParam,
        });

        /**
         * 
         */
        
        const JSONformData ={
          "sections":formTemplateData
        }
        const displayUrl = res?.data?.data?.url;
        const id = res?.data?.data?.id;
        if (displayUrl) {
        
          
          const response = await axios.put(displayUrl, JSONformData);
          console.log("response",response);
          if (response?.status === 200) {
            APIClient.put("prescription/update-template/"+template_id, {
              
              filename: formNameValue,
              type: 'template'
              
            })
              .then(async (res) => {
                console.log("res==>", res);
                if (res?.data?.statusCode === 200) {
                  // Handle success response
                  goToEditPage(res?.data?.data?.id);
                  
                }
              })
              .catch((err) => {
                // Handle error
                handleError(err);
                setSaving(false);
                setDisabledSave(false);
              });
          }
        
          setOpenSaveDialog(false);
          
        
        }
        /**
         * 
         */
        return;
      }
      // Handle saving the input value, e.g., submit to backend, update state, etc.
    
      const templateParam = {
        type: "template",
        content_type: 'application/json',
        active_account_id: 'svaas',
      };
      //const res = await getTemplateUrl(templateParam);
      const res = await APIClient.get(API_ROUTE_PRESCRIPTION_VIEW_URL(), {
        params:templateParam,
      });
      console.log("res",res)
      const JSONformData ={
        "sections":formTemplateData
      }
      const displayUrl = res?.data?.data?.url;
      const id = res?.data?.data?.id;
      if (displayUrl) {
        const contentType = 'application/json';
        
        const response = await axios.put(displayUrl, JSONformData);
        console.log("response",response);
        if (response?.status === 200) {
          APIClient.post("prescription/save-template?active_account_id=svaas", {
            id: id,
            filename: formNameValue,
            active_account_id: 'svaas',
            type:"template",
          })
          .then(async (res) => {
            console.log("res==>", res);
            if (res?.data?.statusCode === 200) {
              // Handle success response
              console.log("goToEditPage",res?.data?.data?.id);
              goToEditPage(res?.data?.data?.id);
            }
          })
          .catch((err) => {
            // Handle error
            handleError(err);
            setSaving(false);
            setDisabledSave(false);
          });
      }
     

      
     
    }
    }
    catch(error){
      handleError(error);
      setSaving(false);
      setDisabledSave(false);
    }
      
    console.log('Input value:', formNameValue,formTemplateData);
  };
  const addTemplateToForm = async (templateData) => {
    console.log("templateData==>",templateData,formTemplateData);
    if(formTemplateData.some(item => item.view_type == templateData.view_type)){
      handleError("Section Already Added");
      return;

    }
    if(templateData.formdata.view_type=="VITALS"){
      console.log("in vitals");
      setVitalsData(vitalsInputs);
      templateData.formdata.rows = vitalsInputs;
     
    }
    setFormTemplateData([...formTemplateData, templateData.formdata]);
    handelAlert("Section Added Successfully");
    console.log("formdata==>",formTemplateData,templateData);
      
  }

  const addVitalsToForm = async (VitalData) => {
    console.log("VitalData==>",VitalData,vitalsData);
    if(vitalsData.some(item => item.label == VitalData.label)){
      return;
    }
    let nVitalsData = [VitalData];
    if(VitalData.label=="BMI"){
      console.log("IN BMI==>",vitalsData,vitalsData.some(item => item.label == "Height"));
      if(!vitalsData.some(item => item.label == "Height")){
        console.log("in height");
        nVitalsData=[...nVitalsData, vitalHeightInput];
      }
      if(!vitalsData.some(item => item.label == "Weight")){
        nVitalsData=[...nVitalsData, vitalWeightInput];
      }
    }
    console.log("nVitalsData",nVitalsData)
    setVitalsData([...vitalsData, ...nVitalsData]);
  }

  const removeVital = (index) => {
    const newItems = [...vitalsData];
    let flag=false;
    
    if((vitalsData[index].label=="Height" || vitalsData[index].label=="Weight")&&vitalsData.some(item => item.label == "BMI")){
      
      flag=true;
    }
    newItems.splice(index, 1);
    if(flag){
      newItems.splice(newItems.findIndex(item => item.label === "BMI"),1)
    }
    
    console.log("newItems",newItems);
    setVitalsData(newItems);
  }

  // Draggable component using react-beautiful-dnd
const DraggableItem = ({ item, index }) => {
    return (
      <Draggable draggableId={item.view_type} index={index}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div  style={{position:"relative",padding:"0 10px"}}>
              <div style={{cursor:"pointer",position:"absolute",right:20,top:10,zIndex:1}}>
                <Tooltip title="Delete Section">
                  <DeleteIcon color='error' onClick={() => removeItem(index)} />
                </Tooltip>  
                
                {item.view_type == "VITALS" && <Tooltip title="Edit Vitals">
                    <EditIcon color="primary" onClick={() => editVitals(index)} />
                  </Tooltip>}
              </div>
              <Tooltip title="Drag Section to re-arrange">

                {(() => {
                  const viewTypes = [
                    "SYSTEMIC_EXAMINATION ====",
                    "PRESENTING_COMPLAINTS ====",
                    "PHYSICAL_FINDING ===",
                    "KNEE_EXAMINATION ===",
                    "INVESTIGATION_MONITORING ===",
                    "GENERAL_PHYSICAL_EXAMINATION ===",
                    "FOOT_EXAM",
                    "FOLLOW_UP",
                    "EXAMINATION",
                    "DIAGNOSIS",
                    "DIABETES_ASSESSMENT",
                    "COMPLAINTS",
                    "ADVICE_NOTES",
                    "ADVICE",
                    "OBG_NOTES",
                    "OBG_HISTORY"
                  ];
                  console.log("item.view_type",item);
                  switch (item.view_type) {
                    
                    case "VITALS":
                        
                        return (
                            <><Vitals sectionData={[item]} isReadOnly={true}/></>
                        )
                    case "REFERRAL":
                        return (
                            <><RenderReferralInputGrid/></>
                        )    
                    case "PATIENT_HISTORY":
                        return (
                            <><RenderPatientHistoryInputGrid/></>
                        )    
                    case "MEDICATION":
                        return (
                            <><RenderMedicationInputGrid/></>
                        )   
                    case "INVESTIGATIONS":
                        return (
                            <><RenderInvestigationsInputGrid/></>
                        )     
                    case "FOLLOW_UP":
                        return (
                            <><RenderFollowUpInputGrid/></>
                        )
                    case "EXAMINATION":
                        return (
                            <><RenderExaminationInputGrid/></>
                        )  
                    case "DIAGNOSIS":
                          return (
                              <><RenderDiagnosisInputGrid/></>
                          )   
                    case "COMPLAINTS":
                      return (
                          <><RenderChiefComplaintInputGrid/></>
                      )   
                    case "ADVICE_NOTES":
                      return (
                          <><RenderAdditionalNotesInputGrid/></>
                      )   
                    case "ADVICE":
                      return (
                          <><RenderAdviceInputGrid/></>
                      )   
                    case "SYSTEMIC_EXAMINATION":
                      return (
                          <><SystemicExamination sectionData={[item]} isReadOnly={true}/></>
                      )    
                    case "PRESENTING_COMPLAINTS":
                      return (
                          <><PresentingComplaints sectionData={[item]} isReadOnly={true}/></>
                      )   
                    case "PHYSICAL_FINDING":
                        return (
                            <><PhysicalFindings sectionData={[item]} isReadOnly={true}/></>
                        )  
                    case "KNEE_EXAMINATION":
                        return (
                            <><KneeExamination sectionData={[item]} isReadOnly={true}/></>
                        )    
                    case "INVESTIGATION_MONITORING":
                        return (
                            <><InvestigationMonitoring sectionData={[item]} isReadOnly={true}/></>
                        )     
                    case "GENERAL_PHYSICAL_EXAMINATION":
                      return(
                          <><GeneralPhysicalExamination sectionData={[item]} isReadOnly={true}/></>
                      )
                    case "FOOT_EXAM":
                      return(
                          <><FootExam sectionData={[item]} isReadOnly={true}/></>
                      )  
                    case  "DIABETES_ASSESSMENT":
                      return (
                        <><DiabetesAssessment sectionData={[item]} isReadOnly={true}/></>
                      )
                    case "OBG_HISTORY":
                      return(
                          <><OBGHistory sectionData={[item]} isReadOnly={true}/></>
                      )  
                    case "OBG_NOTES":
                      return(
                          <><OBGNotes sectionData={[item]} isReadOnly={true}/></>
                      )            
                      
                  }
                })()}
              </Tooltip>
               
                
            </div>
          </div>
          
        )}
      </Draggable>
    );
  };
  
  
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from( formTemplateData);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    
    setFormTemplateData(newItems);
  };

  const handleVitalDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(vitalsData);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    console.log("result",result);
    console.log("newItems",newItems);
    setVitalsData(newItems);
  };
  const removeItem = (index) => {
    const newItems = [...formTemplateData];
    newItems.splice(index, 1);
    setFormTemplateData(newItems);
  }

  const editVitals = (index) => {
    setVitalsFormKey(index);
    console.log("vitalsInputs",vitalsInputs);
    console.log("index",index);
    console.log("formTemplateData",formTemplateData);
    setVitalsData(formTemplateData[index].rows);
    setOpenVitalsSaveDialog(true);
    //setFormTemplateData(newItems);
  }

  const BPInnerTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      maxWidth: 50,
      maxHeight: 24,
      padding: 0,
      "& .MuiInputBase-input": {
        padding: 0,
      },
      "& fieldset": {
        padding: 0,
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
      
      
      [theme.breakpoints.down("sm")]: {
        "& .MuiOutlinedInput-root": {
          width: 77,
        },
      },
    },
  }));
  
  const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      maxWidth: 204,
      height: 56,
      padding: "16px 20px 16px 0",
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        maxWidth: "100%",
        width: "100%",
      },
    },
  }));
  const BPCustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      maxWidth: 204,
      height: 56,
    },
    "& .MuiFormHelperText-root": {
      margin: 0,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        maxWidth: "100%",
        width: "100%",
      },
    },
  }));
  
  const RenderVitalsInputGrid = (props) => {
    return (
        console.log("props",props),
        <Box id="VITALS" style={{ opacity: 1,width:"100%" }}>
          <Card
              styles={{
                boxShadow: "none",
              }}
              title="Vitals"
            >
            <CardHeader headerImg="icon_vitals.svg" headerTitle="Vitals">
              
            </CardHeader>
            <Grid
              container
              wrap="wrap"
              sx={{ width: "100%" }}
              spacing={ 2}
              gap={2}
            >
              {(props.item.rows).map((row, index) => (
                <Grid item key={row?.key} md={2.78} sm={4} xs={5.7}>
                {row?.label !== "BP" ? (
                  <CustomTextField
                    
                    id={row.key}
                    aria-label={row.label}
                    sx={{
                      backgroundColor:
                      row?.label !== "BMI" ? "unset" : "#f0f0f0",
                    }}
                    disabled={true}
                    
                    autoComplete="off"
                    
                    InputProps={{
                      // readOnly: elem?.label !== "BMI" ? false : true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {row?.unit}
                        </InputAdornment>
                      ),
                    }}
                    label={row?.label}
                    placeholder={row?.label}
                    variant="outlined"
                  />
                ) : (
                  // ...
                  <BPCustomTextField
                    // onChange={handleInputChange}
                    id="bp"
                    autoComplete="off"
                    disabled={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BPInnerTextField
                            
                             disabled={true}
                            autoComplete="off"
                            placeholder="SBP"
                            id="Systolic"
                            inputProps = {
                              {
                                sx: {
                                  '&::placeholder': {
                                    color: '#000',
                                    opacity: 1, // otherwise firefox shows a lighter color
                                  },
                                },
                              }
                            }
                          />
                          <span
                            style={{
                              fontWeight: 400,
                              color: "#000000",
                              fontSize: "20px",
                            }}
                          >
                            /
                          </span>
                          <BPInnerTextField
                            sx={{ marginLeft: "10px" }}
                            disabled={true}
                            autoComplete="off"
                            id="Diastolic"
                            placeholder="DBP"
                            inputProps = {
                              {
                                sx: {
                                  '&::placeholder': {
                                    color: '#000',
                                    opacity: 1, // otherwise firefox shows a lighter color
                                  },
                                },
                              }
                            }  
                            
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {row?.unit}
                        </InputAdornment>
                      ),
                    }}
                    aria-label={row.label}
                    label={row?.label}
                    placeholder={row?.label}
                    sx={{
                      "& #bp": {
                        visibility: "hidden",
                        width: "0px",
                      },
                    }}
                    variant="outlined"
                  />

                  // ...
                )}
                
              </Grid>
              ))}
            </Grid>    
        </Card>
          
          
        </Box>
    );
  };


  const RenderReferralInputGrid  = () => {
    return (
      
        <Box id="REFERRAL" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/referral.svg" style={{ opacity: 1,width:"100%" }} alt="icon_referral" />
        </Box>
      
    );
  };

  const RenderPatientHistoryInputGrid  = () => {
    return (
      <Box id="PATIENT_HISTORY" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/patient_history.svg" style={{ opacity: 1,width:"100%" }} alt="icon_patient_history" />
      </Box>
    );
  };
  
  const RenderMedicationInputGrid  = () => {
    return (
        <Box id="MEDICATION" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/medication.svg" style={{ opacity: 1,width:"100%" }} alt="icon_medication" />
        </Box>
    );
  };


  const RenderInvestigationsInputGrid  = () => {
    return (
      <Box id="LAB_INVESTIGATION" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/lab_investigation.svg" style={{ opacity: 1,width:"100%" }} alt="icon_lab_investigation" />
        </Box>
    );
  };

  const RenderFollowUpInputGrid  = () => {
    return (
      <Box id="FOLLOW_UP" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/follow_up.svg" style={{ opacity: 1,width:"100%" }} alt="icon_follow_up" />
        </Box>
    );
  };
  
  const  RenderExaminationInputGrid= () => {
    return (
      <Box id="EXAMINATION" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/examination_findings.svg" style={{ opacity: 1,width:"100%" }} alt="icon_examination_findings" />
        </Box>
    );
  };


  const RenderDiagnosisInputGrid  = () => {
    return (
      <Box id="DIAGNOSIS" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/diagnosis.svg" style={{ opacity: 1,width:"100%" }} alt="icon_diagnosis" />
        </Box>	

    );
  };

  const RenderChiefComplaintInputGrid  = () => {
    return (
      <Box id="COMPLAINTS" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/chief_complaints.svg" style={{ opacity: 1,width:"100%" }} alt="icon_cheif_complaints" />
        </Box>	

    );
  };

  const RenderAdditionalNotesInputGrid  = () => {
    return (
      <Box id="ADVICE_NOTES" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/additional_notes.svg" style={{ opacity: 1,width:"100%" }} alt="icon_additional_notes" />
        </Box>	

    );
  };

  
  const RenderAdviceInputGrid  = () => {
    return (
      <Box id="ADVICE" style={{ opacity: 1,width:"100%" }}>
          <img src="/images/rxbuilder/advice.svg" style={{ opacity: 1,width:"100%" }} alt="icon_advice" />
        </Box>	

    );
  };
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const [openVitalsSaveDialog, setOpenVitalsSaveDialog] = useState(false);

  const handleSaveButtonClick = async () => {
    
    setOpenSaveDialog(true);
  };

  const handleCloseSaveDialog = (event,reason) => {
    if (reason && reason == "backdropClick") {
      console.log('backdropClicked. Not closing dialog.')
      //return;
    }
    setOpenSaveDialog(false);
  };

  const handleCloseVitalsDialog = (event,reason) => {
    if (reason && reason == "backdropClick") {
      console.log('backdropClicked. Not closing dialog.')
      //return;
    }
    setOpenVitalsSaveDialog(false);
  };
  

  return (
    <>
      {isMobileScreen ? (
        <>
          
        </>
      ) : (
        <Box >
          
          <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography 
                        variant='h4' 
                        color={'black'}
                        fontWeight={"bold"}
                        paddingLeft={"15px"}
                  >
                    <IconButton component={Link} to={ROUTE_ADMIN_LIST_RX_TEMPLATES}>
                      <Tooltip title="Back To Templates">
                        <ArrowBackIcon/>
                      </Tooltip>  
                    </IconButton>
                        {template_id?" Edit ":" Add "} Rx Generation Form 
                  </Typography>
                  <Typography 
                        variant='subtitle2' 
                        
                        
                        paddingLeft={"15px"}
                  >
                        Click on add button to add section. Sections will appear in right partition. Drag sections to reorder.
                  </Typography>
                    
                </Grid>
                {loading?<>
                    <Grid item xs={12} sm={12}>
                      <ShowSkeleton/>
                    </Grid>  
                  </>:<>
                   {/* Left partition with 30% width */}
                    <Grid item xs={12} sm={4} style={{overflowX:'hidden',height:'calc(100vh - 140px)'}}>
                        {templateData&&templateData.map((template) => {
                            console.log(template.view_type)
                            return (
                                <Paper style={{ cursor:'pointer', padding: '5px',width:'95%',margin:'auto',  marginBottom: '5px' }}>
                                    <Grid container alignItems="center" spacing={2} style={{margin:0}}>
                                        <Grid item xs={12} sm={10} style={{padding:0,paddingLeft:'5px'}}>
                                            {template.formdata.header}
                                        </Grid>
                                        <Grid item xs={12} sm={2}  style={{padding:0}}>
                                            <Tooltip title="Add Section To Form">
                                              <IconButton aria-label="add" onClick={()=>addTemplateToForm(template)}>
                                                  <AddIcon />
                                              </IconButton>
                                            </Tooltip>
                                            
                                        </Grid>
                                    </Grid>
                                  
                                </Paper>    
                            )
                        })}
                      
                    </Grid>
                    {/* Right partition with 70% width */}
                    <Grid item xs={12} sm={8} style={{overflowX:'hidden',height:'calc(100vh - 140px)',padding:0,background:'transparent'}}>
                        {templateLoading?<>
                            <ShowSkeleton/>
                          </>:<>
                            {formTemplateData.length === 0 && (
                                <NoData>
                                  Add Section To Form
                                </NoData>
                            )}
                            {/* dragabble starts here */}
                            <DragDropContext onDragEnd={handleDragEnd} style={{padding:0,background:'transparent',boxShadow:"none"}}>
                                <Droppable droppableId="formitems">
                                    {(provided) => (
                                    <Paper {...provided.droppableProps} ref={provided.innerRef} sx={{ paddingBottom: '55px' }} style={{background:'transparent',boxShadow:"none"}}>
                                        
                                      
                                        
                                          {formTemplateData.map((item, index) => (
                                              
                                              <DraggableItem key={item.view_type} index={index} item={item} />
                                          ))}
                                          
                                        {provided.placeholder}
                                    </Paper>
                                    )}
                                </Droppable>
                              
                                </DragDropContext>
                            {/* dragabble ends here */}
                        </>}    
                    </Grid>
                </>}
               
            </Grid>
            <div style={{position:'fixed', bottom: 0, right: '15px', zIndex: 999,textAlign:'right',paddingRight:20,paddingBottom:20}}>
                <Button  variant="contained" color="primary" disabled={!formTemplateData.length} onClick={handleSaveButtonClick}>{template_id?" Update ":" Save "}</Button>
            </div>
            <Dialog open={openVitalsSaveDialog} fullWidth maxWidth="md" onClose={handleCloseVitalsDialog} disableBackdropClick >
              <DialogActions  sx={{position:"absolute",right: 0}}>
                <IconButton aria-label="close" onClick={handleCloseVitalsDialog}>
                  <CloseIcon />
                </IconButton>
                {/* Other action buttons */}
              </DialogActions>
              {/* Add your vitals save popup content here */} 
              <DialogContent width={"100%"} sx={{padding:"20px 40px"}}>
                <Grid container spacing={3} sx={{marginTop:"0",width:"100%",marginLeft:"0"}} >
                    
                    {/* Left partition with 30% width */}
                    <Grid item xs={12} sm={6} style={{overflowX:'hidden',height:'calc(90vh - 100px)',padding:"5px",background:'transparent'}}>
                        {vitalsInputs&&vitalsInputs.map((vitalsInput) => {
                            return (
                                <Paper style={{   marginBottom: '5px',cursor:'pointer', padding: '5px' }}>
                                    <Grid container alignItems="center" spacing={2} style={{margin:0}}>
                                        <Grid item xs={12} sm={10} style={{padding:0,paddingLeft:'5px'}}>
                                            {vitalsInput.label}
                                        </Grid>
                                        <Grid item xs={12} sm={2}  style={{padding:0}}>
                                          <Tooltip title="Add Vital To Section">
                                            <IconButton aria-label="add"  onClick={()=>addVitalsToForm(vitalsInput)}>
                                                <AddIcon />
                                            </IconButton>
                                          </Tooltip>
                                            
                                        </Grid>
                                    </Grid>
                                  
                                </Paper>    
                            )
                        })}
                      
                    </Grid>
                    {/* Right partition with 70% width */}
                    <Grid item xs={12} sm={6} style={{overflowX:'hidden',height:'calc(90vh - 100px)',padding:"5px",background:'transparent'}}>
                        
                        {/* dragabble starts here */}
                        <DragDropContext onDragEnd={handleVitalDragEnd} style={{padding:0,background:'transparent'}}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                <Paper {...provided.droppableProps} ref={provided.innerRef} sx={{ padding: 2 }} style={{padding:0,background:'transparent'}}>
                                    
                                    {vitalsData.map((item, index) => (
                                        <>
                                        
                                        {/* draggable starts here */}
                                        <Draggable draggableId={item.label+index} index={index}>
                                            {(provided) => (
                                              <Paper style={{   marginBottom: '5px',cursor:'pointer', padding: '5px' }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                  <div style={{position:"relative",padding:"13px 10px",marginBottom:5}}>
                                                    <div style={{cursor:"pointer",position:"absolute",right:10}}>
                                                      <Tooltip title="Remove Vital">
                                                        <DeleteIcon color='error' onClick={() => removeVital(index)} />
                                                      </Tooltip>
                                                    </div>
                                                    <div>
                                                      {item.label}
                                                    </div>  
                                                  </div>
                                                  
                                              </Paper>    
                                             
                                            )}
                                          </Draggable>
                                        {/* draggable ends here */}
                                        </>
                                    ))}
                                    
                                    {provided.placeholder}
                                </Paper>
                                )}
                            </Droppable>
                          
                        </DragDropContext>
                        {/* dragabble ends here */}
                    </Grid>
                </Grid>
                <div style={{textAlign:"right",marginTop:"10px"}}>
                  <Button variant="contained" disabled={!vitalsData.length} onClick={handleVitalsSaveClick}>Save</Button>
                </div>
              </DialogContent>
              
              {/* vitals save popup content ends here */}                     
            </Dialog>  
            <Dialog open={openSaveDialog} onClose={handleCloseSaveDialog} disableBackdropClick >
                <DialogActions sx={{position:"absolute",right: 0}}>
                  <IconButton aria-label="close" onClick={handleCloseSaveDialog}>
                    <CloseIcon />
                  </IconButton>
                  {/* Other action buttons */}
                </DialogActions>                
                <DialogContent>
                  <div style={{padding: 20,textAlign:"center"}}>
                    <h4>{template_id?" Update ":" Save "} Template</h4>
                    {/* Add your save popup content here */}
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-age">
                          Enter Template Name
                        </InputLabel>
                        <OutlinedInput
                        label="Enter Template Name"
                        
                        value={formNameValue}
                        onChange={handleformNameChange}
                        sx={{ mr: 1 }}
                        />
                    </FormControl>
                    <div style={{marginTop:"10px"}}>
                      
                        <Button 
                          variant="contained" 
                          disabled={!formTemplateData||(formNameValue.replace(/[^\w\s]/gi, '').trim().length<3||formNameValue.replace(/[^\w\s]/gi, '').trim().length>100)||disabledSave}
                          startIcon={saving&&<CircularProgress size={20} color="inherit" />} 
                          onClick={handleSaveClick}>{template_id?" Update ":" Save "} </Button>
                      
                    </div>
                    
                    
                  </div>
                </DialogContent>
                
            </Dialog>
            <Snackbar
              open={errorOpen}
              autoHideDuration={6000} // Optional: Auto-close after 6 seconds
              onClose={() => setErrorOpen(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Optional: Adjust position
            >
              <MuiAlert onClose={() => setErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
              </MuiAlert>
            </Snackbar> 
            <Snackbar
              open={alertOpen}
              autoHideDuration={6000} // Optional: Auto-close after 6 seconds
              onClose={() => setAlertOpen(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Optional: Adjust position
            >
              <MuiAlert onClose={() => setAlertOpen(false)} severity="success" sx={{ width: '100%' }}>
                {alertMessage}
              </MuiAlert>
            </Snackbar> 
        </Box>
      )}
    </>
  )
}


export default RxBuilder