import { store } from "./redux/store";
import { Amplify, Auth } from "aws-amplify";
import {
  AWS_COGNITO_CONFIG,
  MAX_COGNITO_REFRESH_TIMES,
} from "./shared/constants";
import { setToken } from "./redux/reducers/auth.slice";
import moment from "moment";

// Moved currentSession to program root
function currentSession() {
  setTimeout(() => {
    if (localStorage.getItem("emrToken") && localStorage.getItem("auth-type")) {
      localStorage.getItem("auth-type") === "cognito" &&
        refreshCognitoSession();
    }
    currentSession();
  }, 50 * 60 * 60);
}

const data = localStorage.getItem("config");
const allData = AWS_COGNITO_CONFIG
  ? { AWS_COGNITO_CONFIG: AWS_COGNITO_CONFIG }
  : JSON.parse(data || "{}");

const AWS_Cognito_Config = allData.AWS_COGNITO_CONFIG || null;
if (AWS_Cognito_Config?.aws_cognito_identity_pool_id) {
  Amplify.configure(AWS_Cognito_Config);
  Amplify.Logger.LOG_LEVEL = "DEBUG";
  currentSession(); // Call the function instead of defining it

  if (localStorage.getItem("auth-type") === "cognito") {
    const func = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        refreshCognitoSession();
      } catch {
        localStorage.clear();
        window.location.reload();
      }
    };
    func();
  }
}

export const refreshCognitoSession = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentValidity = localStorage.getItem("cognito-validity");
  const cognitoRefreshTimes = localStorage.getItem("cognito-refresh-times");
  if (currentValidity && cognitoRefreshTimes < MAX_COGNITO_REFRESH_TIMES) {
    const timeLeft = moment(parseInt(currentValidity) * 1000).diff(moment());
    if (timeLeft < 10 * 60 * 1000) {
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          const { idToken } = session;
          const exp = idToken?.payload?.exp;
          const iat = idToken?.payload?.iat;
          localStorage.setItem("cognito-validity", exp);
          localStorage.setItem("cognito-timestamp", iat);
          const oldTime = localStorage.getItem("cognito-refresh-times");
          let newTime = 0;
          if (oldTime) {
            newTime = parseInt(oldTime) + 1;
          }
          localStorage.setItem("cognito-refresh-times", newTime);
          localStorage.setItem("emrToken", idToken.jwtToken);
          store.dispatch(setToken(idToken.jwtToken));
        }
      );
    }
  }
};
