const OnlineConsultationIcon = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2214_62163)">
          <rect
            width="32"
            height="32"
            rx="16"
            fill="#2188FF"
            fill-opacity="0.1"
          />
          <path
            d="M11.1123 9.75372C10.7748 9.75323 10.4405 9.82474 10.1285 9.96416C9.81661 10.1036 9.5332 10.3082 9.29452 10.5662C9.05584 10.8242 8.8666 11.1306 8.73764 11.4679C8.60868 11.8051 8.54252 12.1665 8.54297 12.5314V19.4758C8.54388 20.2122 8.81488 20.9181 9.29653 21.4388C9.77818 21.9595 10.4312 22.2525 11.1123 22.2535H17.5357C17.8733 22.254 18.2076 22.1824 18.5195 22.043C18.8314 21.9036 19.1149 21.699 19.3535 21.441C19.5922 21.1829 19.7815 20.8765 19.9104 20.5393C20.0394 20.2021 20.1055 19.8407 20.1051 19.4758V18.7795L22.4175 20.6572C22.561 20.7734 22.7316 20.844 22.9101 20.861C23.0887 20.878 23.2681 20.8408 23.4281 20.7535C23.5871 20.6723 23.7214 20.5439 23.8152 20.3831C23.9102 20.2202 23.9601 20.0313 23.9591 19.8387V12.1833C23.9596 11.9896 23.9098 11.7997 23.8152 11.6352C23.7221 11.4701 23.5879 11.3367 23.428 11.2502C23.2681 11.1638 23.0888 11.1277 22.9108 11.1463C22.7324 11.1648 22.5619 11.2352 22.4175 11.35L20.1051 13.224V12.5277C20.106 12.1627 20.0401 11.801 19.9113 11.4636C19.7825 11.1261 19.5933 10.8195 19.3546 10.5614C19.1158 10.3033 18.8322 10.0987 18.52 9.95946C18.2079 9.82021 17.8734 9.74903 17.5357 9.75001L11.1123 9.75372Z"
            fill="#2188FF"
          />
          <path
            d="M20.1051 18.7795V19.4758C20.1055 19.8407 20.0394 20.2021 19.9104 20.5393C19.7815 20.8765 19.5922 21.1829 19.3535 21.441C19.1149 21.699 18.8314 21.9036 18.5195 22.043C18.2076 22.1824 17.8733 22.254 17.5357 22.2535H11.1123C10.4312 22.2525 9.77818 21.9595 9.29653 21.4388C8.81488 20.9181 8.54388 20.2122 8.54297 19.4758V12.5314C8.54252 12.1665 8.60868 11.8051 8.73764 11.4679C8.8666 11.1306 9.05584 10.8242 9.29452 10.5662C9.5332 10.3082 9.81661 10.1036 10.1285 9.96416C10.4405 9.82474 10.7748 9.75323 11.1123 9.75372L17.5357 9.75001C17.8734 9.74903 18.2079 9.82021 18.52 9.95946C18.8322 10.0987 19.1158 10.3033 19.3546 10.5614C19.5933 10.8195 19.7825 11.1261 19.9113 11.4636C20.0401 11.801 20.106 12.1627 20.1051 12.5277V13.224M20.1051 18.7795L22.4175 20.6572C22.561 20.7734 22.7316 20.844 22.9101 20.861C23.0887 20.878 23.2681 20.8408 23.4281 20.7535C23.5871 20.6723 23.7214 20.5439 23.8152 20.3831C23.9102 20.2202 23.9601 20.0313 23.9591 19.8387V12.1833C23.9596 11.9896 23.9098 11.7997 23.8152 11.6352C23.7221 11.4701 23.5879 11.3367 23.428 11.2502C23.2681 11.1638 23.0888 11.1277 22.9108 11.1463C22.7324 11.1648 22.5619 11.2352 22.4175 11.35L20.1051 13.224M20.1051 18.7795V13.224"
            stroke="#E0EBF6"
            stroke-width="1.54162"
            stroke-miterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_2214_62163">
            <rect width="32" height="32" rx="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default OnlineConsultationIcon;
