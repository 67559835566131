import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HTMLAttributes, SyntheticEvent, useEffect, useRef, useState } from "react";
import CardHeader, { renderHeaderButtons } from "../../utils/CardHeader";
import Card from "../../utils/Card";
import { styled } from "@mui/system";
import { CARD_SHADOW } from "../../utils/Constants";
import { REFERRAL, INVESTIGATION_MONITORING } from "../../../../../shared/constants";
import { setEditRxSecMobile } from "../../../../../redux/reducers/prescription.slice";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import DoctorSuggestions from "../../utils/DoctorSuggestions";
import { fetchSuggestions, seperateUrlAndParams } from "../../../../../shared/Utils/utils";
import { useDebounce } from "../../../../../shared/Utils/useDebounce";
import TemplateCard from "../../utils/TemplateCard";

interface SymptomProps {
  id: string;
  name: string;
  symptom: string;
  state: string;
  comment: string;
  value: string;
}
interface ReferralProps {
  speciality: string;
  dr_name: string;
  comment: string;
}

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "8px",
    paddingLeft: 0,
    border: "none",
  },
});

const InvestigationMonitoring = (props: any) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SymptomProps[]>([]);
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {} as SymptomProps,
  });

  const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>(false);
  const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
    useState<boolean>(false);

  const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
  const searchExistingTemplateMobile =
    props?.sectionData?.[0]?.searchExistingTemplateMobile;

  const [searchValue, setSearchValue] = useState("");

  const sectionData = props?.sectionData?.[0]?.rows[0];
  const setTabData = props?.setTabData;
  const isFocused: boolean = props.isFocused || false;
  const symptomArr = sectionData?.master?.symptom?.values || [];
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const autoref = useRef<HTMLDivElement>(null);

  const apiurldata = sectionData?.search?.api;

  const [valUpdateSearch, setValUpdateSearch] = useState("");
  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);

  const [values, setValues] = useState<any>({});
  

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(INVESTIGATION_MONITORING));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      const valuesObj:any = {};
      sectionData.value?.forEach((elem:any)=>{
        valuesObj[elem.id] = elem.state;
      })
      setValues(valuesObj);
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === INVESTIGATION_MONITORING
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length !== 0 && searchValue.trim().length >= 3) {
      updateSearchOptions(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (valUpdateSearch.length !== 0) {
      updateSearchOptions(altDebouncedSearch);
    }
  }, [altDebouncedSearch]);


  useEffect(()=>{
    console.log("data",data)
  },[data])

  const handleDialogState = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
      // content :
    });
  };

  const dialogProps = {
    DialogOpen: dialog.open ? dialog.open : false,
    onHandleDialog: handleDialogState,
    headerStyles: { display: "none" },
    PaperPropsStyle: {
      margin: 0,
      width: "100%",
    },
    Layoutstyles: {
      "& .MuiDialog-container": {
        alignItems: "flex-end",
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
      },
    },
    footerStyles: {
      display: "none",
    },
    onHandleSave: () => {
      // not required, custom save option is there
      return;
    },
  };

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === INVESTIGATION_MONITORING) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const removeSymptom = (event: SyntheticEvent) => {
    const elem = event.target as HTMLElement;
    const { id } = elem;
    
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === INVESTIGATION_MONITORING) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (symptom: any) => symptom.id !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([...options, id]);
  };

  const assignAddLabInvestigationMobile = (value: string) => {
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;
    if (!value) return;
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        id,
        name,
        since: "",
        severity: "",
        comment: "",
      },
    });
  };

  const addInvestigation = async (
    event: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    const optionObj = optionsData.find((optObj: any) => optObj.name === value);
    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    const name = optionObj?.name ? optionObj.name : value;
    if (!value) {
      return;
    }

    const newCondition: SymptomProps = {
      id,
      name,
      symptom:"",
      state:"",
      comment: "",
      value:'',
    };
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === INVESTIGATION_MONITORING) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: [...row.value, newCondition],
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
    setSearchValue("");
  };

  const updateInvestigation = (event: SyntheticEvent, value: string | null) => {
    let newValue: any, newId: any;

    const target =
      (event.target as HTMLInputElement) ||
      (event.target as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName)?.split("-");

    const key = nameArr[nameArr.length - 1];

    const currId = nameArr.reduce((acc, val) => {
      if (val === key) {
        return acc;
      }
      return acc + "-" + val;
    });

    if (key === "name") {
      const optionObj = optionsData.find(
        (optObj: any) => optObj.name === value
      );
      const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
      const name = optionObj?.name ? optionObj.name : value;
      newValue = name;
      newId = id;
    } else {
      (newId = currId), (newValue = value);
    }
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === INVESTIGATION_MONITORING) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.map((condition: any) => {
                    if (condition?.id?.toString() === currId) {
                      return {
                        ...condition,
                        id: newId,
                        [key]: newValue,
                      };
                    }
                    return condition;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });

    setOptions([]);
  };

  const doctorSuggestionProps = {
    sKey: "investigations",
    sHeading: "Suggested investigation",
    headerImg: isMobileScreen
      ? "/images/icon_mobile_resp_suggestion.svg"
      : "/images/icon_dummy.svg",
    handleAdd: (event: any) => {
      const currentTarget = event?.currentTarget;
      const suggestion = currentTarget?.attributes["data-key"]?.nodeValue;
      const clickAction = currentTarget?.attributes["data-action"]?.nodeValue;

      if (
        !data.some((investigation: any) => investigation.name === suggestion)
      ) {
        switch (clickAction) {
          case "ADD":
            if (!isMobileScreen) {
              addInvestigation(null as any, suggestion);
            } else {
              assignAddLabInvestigationMobile(suggestion);
            }
            break;
          case "UPDATE":
            updateInvestigation(event as any, suggestion);
            break;
          default:
            break;
        }
      }
      if (autoref.current) {
        autoref.current.blur();
      }
    },
  };

  const updateSearchOptions = (value: any) => {
    const trimmedValue = value.trim();
    if (apiurldata && trimmedValue) {
      const { url, query_params } = seperateUrlAndParams(apiurldata);
      fetchSuggestions(
        url,
        {
          ...query_params,
          name: value,
        },
        true // use custom base url
      ).then((response) => {
        const responseArr = response?.data?.data;
        const optionsArr = responseArr?.map((option: any) => option.name);
        if (!optionsArr.some((opt: any) => opt === trimmedValue)) {
          setOptions([trimmedValue, ...optionsArr]);
          setOptionsData([
            {
              id: `custom${Date.now()}`,
              name: trimmedValue,
            },
            ...responseArr,
          ]);
          return;
        }
        setOptions(optionsArr);
        setOptionsData(responseArr);
      });
    }
  };

  const assignAddReferralData = (event: any, value: string) => {
    if (!value) {
      return;
    }
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        speciality: value,
        dr_name: "",
        comment: "",
      },
    });
  };

  const RefferalCardMobile = (Referral: SymptomProps, index: number) => {
    const {  comment } = Referral;
    return (
      <Card
        styles={{
          borderRadius: "8px",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
          padding: "0px",
        }}
        key={index}
      >
        <Box
          className="card-header"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px",
          }}
        >
          <Typography
            fontSize="16px"
            fontWeight="700"
            lineHeight="16px"
            color="#494E9D"
          >
            {}
          </Typography>
          <IconButton>
            <Box
              id={Referral?.id}
              aria-label="delete-referral"
              onClick={removeReferral}
              component="img"
              src="/images/DeleteIcon.svg"
            ></Box>
          </IconButton>
        </Box>
        <Divider />
        <Stack sx={{ padding: "12px" }} spacing={2}>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box
              component="img"
              marginRight="4px"
              src="/images/icon_dr.svg"
            ></Box>
            <Box>
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="100%"
                marginBottom="8px"
                color="rgba(0, 0, 0, 0.87)"
              >
                Doctor Name
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="400"
                lineHeight="160%"
                color="#6F6F6F"
              >
                {}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box
              component="img"
              marginRight="4px"
              src="/images/SpecInstrIcon.svg"
            ></Box>
            <Box>
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="100%"
                marginBottom="8px"
                color="rgba(0, 0, 0, 0.87)"
              >
                Notes
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="400"
                lineHeight="160%"
                color="#6F6F6F"
              >
                {}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Card>
    );
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        {data.map((datum:any, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
              }}
              key={index}
            >
              {`${datum.name} - ${datum.state}`}
            </Box>
          );
        })}
      </Box>
    );
  };

  const removeReferral = (event: SyntheticEvent) => {
    const elem = event.target as HTMLElement;
    const { id } = elem;
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === REFERRAL) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (referral: any) => referral.speciality !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([...options, id]);
  };

  const handleButtonClick = async (imageName: string) => {
    if (imageName === "icon_save.svg") {
      if (isMobileScreen) {
        // handleButtonClickMiniTMobile(imageName); // review-code
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === INVESTIGATION_MONITORING) {
              return {
                ...section,
                openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                searchExistingTemplateMobile: false,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenSaveTemplate(!openSaveTemplate);
      setOpenUpdateExistingTemplate(false);
    } else if (imageName === "icon_add_page.svg") {
      if (isMobileScreen) {
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === INVESTIGATION_MONITORING) {
              return {
                ...section,
                openSaveTemplateMobile: false,
                searchExistingTemplateMobile:
                  !section.searchExistingTemplateMobile,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenUpdateExistingTemplate(!serachExistingTemplate);
      setOpenSaveTemplate(false);
    }
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position:'relative'
          }}
          title="Investigation Monitoring"
          isReadOnly={props?.isReadOnly}
        >
          <CardHeader headerImg="icon_investigation_monitoring.svg" headerTitle="Investigation Monitoring">
            <>
              {renderHeaderButtons(
                ["icon_save.svg", "icon_add_page.svg"],
                handleButtonClick
              )}
            </>
          </CardHeader>
          {data.length !== 0 && (
            <Box display={'flex'} flexWrap={'wrap'} gap={4} mb={2}>
              {data.map((elem)=>{
                return (
                  <TextField
                    label={elem.name}
                    placeholder={elem.name}
                    value={values[elem.id] || ''}
                    id={`${elem.id}-state`}
                    aria-label={`${elem.id}-state`}
                    name={`${elem.id}-state`}
                    onChange={(e)=>{
                      setValues({
                        ...values,
                        [elem.id]:e.target.value
                      })
                    }}
                    onBlur={(e)=>{
                      updateInvestigation(e,e.target.value)
                    }}
                  />
                )
              })}
            </Box>
          )}

          <Autocomplete
            id="search-for-symptoms"
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            // loading={true}
            value={""}
            clearOnBlur
            clearOnEscape
            popupIcon={false}
            clearIcon={false}
            inputValue={searchValue}
            onChange={addInvestigation}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    value: null,
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for investigation"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((complaint: any) => complaint.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplate && (
            <Box position="absolute" top="68px" right="79px" zIndex="1">
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {serachExistingTemplate && (
            <Box position="absolute" top="68px" right="39px" zIndex="1">
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Box marginTop="16px">
            {data.length !== 0 && (
              <Box display={'flex'} flexWrap={'wrap'} gap={2} mb={2}>
                {data.map((elem)=>{
                  return (
                    <TextField
                      label={elem.name}
                      placeholder={elem.name}
                      value={values[elem.id] || ''}
                      id={`${elem.id}-state`}
                      aria-label={`${elem.id}-state`}
                      name={`${elem.id}-state`}
                      onChange={(e)=>{
                        setValues({
                          ...values,
                          [elem.id]:e.target.value
                        })
                      }}
                      onBlur={(e)=>{
                        updateInvestigation(e,e.target.value)
                      }}
                      sx={{
                        width:'46%'
                      }}
                    />
                  )
                })}
              </Box>
            )}
          </Box>
          <Autocomplete
            id="search-for-symptoms"
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            // loading={true}
            value={""}
            clearOnBlur
            clearOnEscape
            popupIcon={false}
            clearIcon={false}
            inputValue={searchValue}
            onChange={addInvestigation}
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autoref}
                  value={null}
                  onChange={(event: any) => {
                    if (event.target.value === "") {
                      setOptions([]);
                    }
                    setSearchValue(event.target.value);
                  }}
                  InputProps={{
                    value: null,
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for investigation"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((complaint: any) => complaint.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplateMobile && (
            <Box sx={SaveTemplateStyle}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {searchExistingTemplateMobile && (
            <Box sx={AddMiniTemplateStyle}>
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
    </>
  )
}

export default InvestigationMonitoring