import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'

const NoPermission = () => {
  const [loader, setLoader] = useState(true);

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setLoader(false);
  //   },2000)
  // },[])

  return (
    <div style={{height:'100%',width:'100%', display:'flex',justifyContent:'center',alignItems:'center'}}>
        {/* {loader ? (
          <CircularProgress/>
        ) : (
          <p>You do not have permission to view this page</p>
        )} */}
        <p>You do not have permission to view this page</p>
    </div>
  )
}

export default NoPermission;