import {
  Autocomplete,
  Box,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  tableCellClasses,
  InputLabel,
  Tooltip,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Divider,
  Button,
  createFilterOptions,
} from "@mui/material";
import {
  HTMLAttributes,
  SyntheticEvent,
  createElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { getDurationOptions } from "../../../../../shared/Utils/utilities";
import CardHeader, { renderHeaderButtons } from "../../utils/CardHeader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Card from "../../utils/Card";
import styled from "@emotion/styled";
import { useDebounce } from "../../../../../shared/Utils/useDebounce";
import {
  fetchSuggestions,
  seperateUrlAndParams,
} from "../../../../../shared/Utils/utils";
import { CARD_SHADOW } from "../../utils/Constants";
import { convertLetterCase } from "./../../../../../shared/Utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { MEDICATION } from "../../../../../shared/constants";
import {
  setCopyData,
  setEditRxSecMobile,
} from "../../../../../redux/reducers/prescription.slice";
import dayjs from "dayjs";
import DoctorSuggestions from "../../utils/DoctorSuggestions";
import TemplateCard from "../../utils/TemplateCard";
import { getQtyPerDoseOptions } from "../../utils/Utilities";
import TabletIcon from "../../../../../shared/icons/TabletIcon";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CloseIcon from "@mui/icons-material/Close";

interface MedicationProps {
  id: string;
  name: string;
  dosage: string;
  frequency: string;
  duration: string;
  meal_pref: string;
  start_from: string;
  comment: string;
  salt?: string;
}

const TableHeaders = [
  "Medicine Name",
  "Qty/Dose",
  "Frequency",
  "Duration",
  "Meal Pref",
  "Start From",
  "Special Instructions"
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: 400,
    opacity: "87%",
    letterSpacing: "0.17px",
  },
}));

const tableCellStyle = {
  padding: "0px 5px 10px",
  borderBottom:'none'
};

const fieldStyle = {
  "& .MuiOutlinedInput-root": {
    paddingRight: "0 !important",
    paddingLeft: "0 !important",
  },
  width: "48%",
};

const CustomDropDownPaper = styled(Paper)({
  minWidth: 160,
});

export default function Medication(props: any) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);

  const [durationOptions, setDurationOptions] = useState<string[]>([]);
  const [qtyOptions, setQtyOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MedicationProps[]>([]);

  const [searchValue, setSearchValue] = useState("");
  const [valUpdateSearch, setValUpdateSearch] = useState("");

  const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>();
  const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
    useState<boolean>();

  const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
  const searchExistingTemplateMobile =
    props?.sectionData?.[0]?.searchExistingTemplateMobile;
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {} as MedicationProps,
  });
  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);

  const autoref = useRef<HTMLDivElement>(null);
  const currentDate = new Date();

  const isFocused: boolean = props.isFocused || false;

  const sectionData =
    props.sectionData[0]?.rows && props?.sectionData?.[0]?.rows[0];
  const { setTabData } = props;

  const frequencyMaster = sectionData?.master?.frequency?.values || [];
  const mealPrefMaster = sectionData?.master?.meal_pref?.values || [];
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const {
    copyData,
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const apiurldata = sectionData?.search?.api;

  const MiniTemplateStyle = {
    position: !isMobileScreen ? "absolute" : "none",
    top: "68px",
    right: "79px",
    zIndex: 1,
    height: "0px",
  };

  const AddMiniTemplateStyle = {
    position: !isMobileScreen ? "absolute" : "none",
    top: "68px",
    right: "39px",
    zIndex: 1,
    height: "0px",
  };

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(MEDICATION));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (sectionData) {
      setData(sectionData.value);
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === MEDICATION
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === MEDICATION) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  const newValue = item?.rows[0]?.value.map((val: any) => {
                    const { start_from, ...rest } = val;
                    return rest;
                  });
                  return { ...row, value: newValue };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
    // handleButtonClick("icon_add_page.svg");
  };

  useEffect(() => {
    if (copyData?.view_type === MEDICATION) {
      handleSelectedTemplateData(copyData);
      dispatch(setCopyData([] as any));
    }
  }, [copyData]);

  const assignMedicationMobile = (value: string) => {
    let name: any;

    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    if (value?.includes("(") && value?.includes(")")) {
      name = value;
    } else {
      if (!optionObj) {
        name = `${value} ()`;
      } else {
        name = optionObj?.molecule
          ? `${value} (${optionObj?.molecule})`
          : `${value} ()`;
      }
    }

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;

    if (!value) return;
    setDialog({
      ...dialog,
      open: true,
      mode: "ADD",
      content: {
        id,
        name,
        dosage: "",
        meal_pref: "",
        duration: "",
        start_from: "",
        comment: "",
        frequency: "",
        salt: "",
      },
    });
  };

  const doctorSuggestionProps = {
    sKey: "medication",
    sHeading: "Suggested medication",
    headerImg: isMobileScreen
      ? "/images/icon_mobile_resp_suggestion.svg"
      : "/images/icon_dummy.svg",
    handleAdd: (event: any) => {
      const currentTarget = event?.currentTarget;
      const suggestion = currentTarget?.attributes["data-key"]?.nodeValue;
      const clickAction = currentTarget?.attributes["data-action"]?.nodeValue;

      if (!data.some((history: any) => history.name === suggestion)) {
        switch (clickAction) {
          case "ADD":
            if (!isMobileScreen) {
              addMedication(null as any, suggestion);
            } else {
              assignMedicationMobile(suggestion);
            }
            break;
          case "UPDATE":
            updateMedication(event as any, suggestion);
            break;
          default:
            addMedication(null as any, suggestion);
        }
      }
      if (autoref.current) {
        autoref.current.blur();
      }
    },
  };

  const addMedication = (event: SyntheticEvent, value: string | null) => {
    if (!value) {
      return;
    }

    let name: any;

    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    if (value?.includes("(") && value?.includes(")")) {
      name = value;
    } else {
      if (!optionObj) {
        name = `${value} ()`;
      } else {
        name = optionObj?.molecule
          ? `${value} (${optionObj?.molecule})`
          : `${value} ()`;
      }
    }

    const id = optionObj?.id ? optionObj.id : `custom${Date.now()}`;

    const newMedication: MedicationProps = {
      id,
      name,
      dosage: "",
      meal_pref: "",
      duration: "",
      start_from: "",
      comment: "",
      frequency: "",
    };
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === MEDICATION) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: [...row.value, newMedication],
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
    setOptions([]);
    setSearchValue("");
  };

  const updateMedication = (event: SyntheticEvent, value: string | null) => {
    let newValue: any, newId: any;

    const target =
      (event.currentTarget as HTMLInputElement) ||
      (event.currentTarget as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName).split("-");

    const key = nameArr[nameArr.length - 1];

    const currId = nameArr.reduce((acc, val) => {
      if (val === key) {
        return acc;
      }
      return acc + "-" + val;
    });

    const optionObj = optionsData.find((optObj: any) => optObj.name === value);

    const elemId = target.id;

    if (key === "name" && !elemId.includes("update-molecule-name")) {
      if (value?.includes("(") && value?.includes(")")) {
        newValue = value;
      } else {
        if (!optionObj) {
          newValue = `${value} ()`;
        } else {
          newValue = optionObj?.molecule
            ? `${value} (${optionObj?.molecule})`
            : `${value} ()`;
        }
      }
      newId = optionObj?.id ? optionObj.id : `custom${Date.now()}`;
    } else if (key === "name" && elemId.includes("update-molecule-name")) {
      const idArr = elemId.split("-");
      const medName = idArr[idArr.length - 1]?.split("(")[0]?.trim();
      if (!medName) {
        return;
      }
      newValue = value ? `${medName} (${value})` : `${medName} ()`;

      newId = currId;
    } else {
      (newId = currId), (newValue = value);
    }

    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === MEDICATION) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.map((medication: any) => {
                    if (medication?.id?.toString() === currId) {
                      return {
                        ...medication,
                        id: newId,
                        [key]: newValue,
                      };
                    }
                    return medication;
                  }),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });

    setDurationOptions([]);
  };

  const updateDurationOptions = (event: SyntheticEvent) => {
    const elem = event.target as HTMLInputElement;
    const { value } = elem;

    if (!/^[1-9][0-9]*$/.test(value) || value === "") {
      const newOptionArr = durationOptions.filter((option) =>
        option.includes(value)
      );

      if (newOptionArr) {
        setDurationOptions(newOptionArr);
        return;
      }

      setDurationOptions([]);
      return;
    }

    const optionArr = getDurationOptions(value, true);

    if (optionArr) {
      setDurationOptions(optionArr);
    }
  };

  const updateQtyOptions = (event: SyntheticEvent) => {
    const elem = event.target as HTMLInputElement;
    const { value } = elem;

    if (value === "0") {
      setQtyOptions([]);
      return;
    }

    if (!/^\d+(\.\d)?$/.test(value) || value === "") {
      const newOptionArr = qtyOptions.filter((option) =>
        option.includes(value)
      );

      if (newOptionArr) {
        setQtyOptions(newOptionArr);
        return;
      }

      setQtyOptions([]);
      return;
    }

    const optionArr = getQtyPerDoseOptions(value);

    if (optionArr) {
      setQtyOptions(optionArr);
    }
  };

  const removeMedication = (event: SyntheticEvent) => {
    const elem = event.target as HTMLElement;
    const { id } = elem;
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === MEDICATION) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (index === 0) {
                return {
                  ...row,
                  value: row.value.filter(
                    (medication: any) => medication?.id?.toString() !== id
                  ),
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });
  };

  const updateSearchOptions = (value: any) => {
    if (apiurldata && apiurldata[0] && value) {
      const { url, query_params } = seperateUrlAndParams(apiurldata);
      fetchSuggestions(
        url,
        {
          ...query_params,
          name: value,
        },
        true // use custom base url
      ).then((response) => {
        const responseArr = response?.data?.data;
        const optionsArr = responseArr?.map((option: any) => option.name);
        if (!optionsArr.some((opt: any) => opt === value)) {
          setOptions([value, ...optionsArr]);
          setOptionsData([
            {
              id: `custom${Date.now()}`,
              name: value,
            },
            ...responseArr,
          ]);
          return;
        }
        setOptions(optionsArr);
        setOptionsData(responseArr);
      });
    }
  };

  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue.length !== 0 && searchValue.trim().length >= 3) {
      updateSearchOptions(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (valUpdateSearch.length !== 0) {
      updateSearchOptions(altDebouncedSearch);
    }
  }, [altDebouncedSearch]);

  const handleButtonClick = async (imageName: string) => {
    if (imageName === "icon_save.svg") {
      if (isMobileScreen) {
        // handleButtonClickMiniTMobile(imageName); // review-code
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === MEDICATION) {
              return {
                ...section,
                openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                searchExistingTemplateMobile: false,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenSaveTemplate(!openSaveTemplate);
      setOpenUpdateExistingTemplate(false);
    } else if (imageName === "icon_add_page.svg") {
      if (isMobileScreen) {
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === MEDICATION) {
              return {
                ...section,
                openSaveTemplateMobile: false,
                searchExistingTemplateMobile:
                  !section.searchExistingTemplateMobile,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenUpdateExistingTemplate(!serachExistingTemplate);
      setOpenSaveTemplate(false);
    }
  };

  const handleFocus = () => {
    setQtyOptions([]);
  };

  const handleDialogState = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
      // content :
    });
  };

  const dialogProps = {
    DialogOpen: dialog.open ? dialog.open : false,
    onHandleDialog: handleDialogState,
    headerStyles: { display: "none" },
    PaperPropsStyle: {
      margin: 0,
      width: "100%",
    },
    Layoutstyles: {
      "& .MuiDialog-container": {
        alignItems: "flex-end",
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
      },
    },
    footerStyles: {
      display: "none",
    },
    CloseButtonStyles:{
      display: "none",
    },
    onHandleSave: () => {
      // not required, custom save option is there
      return;
    },
  };
  const MedicationCardMobile = (Medication: MedicationProps, index: number) => {
    const {
      id,
      name,
      duration,
      dosage,
      frequency,
      start_from,
      meal_pref,
      comment,
    } = Medication;

    return (
      <Card
        styles={{
          borderRadius: "8px",
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
          padding: "0px",
        }}
        key={index + id}
      >
        {/* Card header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px",
          }}
        >
          <Typography
            fontSize="16px"
            fontWeight="700"
            lineHeight="16px"
            color="#494E9D"
          >
            {name !== ""
              ? convertLetterCase("sentence-case", name?.split("(")[0]?.trim())
              : name}
          </Typography>
          <IconButton>
            <Box
              id={id}
              aria-label="delete-medication"
              onClick={removeMedication}
              component="img"
              src="/images/DeleteIcon.svg"
            ></Box>
          </IconButton>
        </Box>
        <Divider />
        {/* Card Contents */}
        <Box padding="12px">
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            {[
              {
                title: "Salt",
                icon: "saltIcon.svg",
                value:
                  name !== ""
                    ? convertLetterCase(
                      "sentence-case",
                      name?.split("(")[1]?.split(")")[0]
                    )
                    : name,
              },
              {
                title: "Quantity per dose",
                icon: "glassIcon.svg",
                value: dosage,
              },
            ].map((dtCol, ColIndex) => {
              const ColItem = (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    component="img"
                    marginRight="4px"
                    src={`/images/${dtCol.icon}`}
                  ></Box>
                  <Box>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="100%"
                      marginBottom="8px"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {dtCol.title}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="100%"
                      color="#6F6F6F"
                    >
                      {dtCol.value}
                    </Typography>
                  </Box>
                </Box>
              );
              if (ColIndex === 0) {
                return <Box sx={{ width: "48%" }}>{ColItem}</Box>;
              }
              return (
                <Box
                  sx={{
                    display: "flex",
                    width: "48%",
                    alignItems: "center",
                  }}
                >
                  {ColItem}
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "16px",
              justifyContent: "space-between",
            }}
          >
            {[
              {
                title: "Frequency",
                icon: "mobile_tick_icon.svg",
                value: frequency,
              },
              {
                title: "Duration Unit",
                icon: "ClockIcon.svg",
                value: duration,
              },
            ].map((dtCol, ColIndex) => {
              const ColItem = (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    component="img"
                    marginRight="4px"
                    src={`/images/${dtCol.icon}`}
                  ></Box>
                  <Box>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="100%"
                      marginBottom="8px"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {dtCol.title}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="100%"
                      color="#6F6F6F"
                    >
                      {dtCol.value}
                    </Typography>
                  </Box>
                </Box>
              );
              if (ColIndex === 0) {
                return <Box sx={{ width: "48%" }}>{ColItem}</Box>;
              }
              return (
                <Box
                  sx={{
                    width: "48%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {ColItem}
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            {[
              {
                title: "Meal Pref",
                icon: "mealPrefIcon.svg",
                value: meal_pref,
              },
              {
                title: "Date",
                icon: "calenderIcon3.svg",
                value: dayjs(start_from).format("DD/MM/YYYY"),
              },
            ].map((dtCol, ColIndex) => {
              const ColItem = (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    component="img"
                    marginRight="4px"
                    src={`/images/${dtCol.icon}`}
                  ></Box>
                  <Box>
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="100%"
                      marginBottom="8px"
                      color="rgba(0, 0, 0, 0.87)"
                    >
                      {dtCol.title}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="100%"
                      color="#6F6F6F"
                    >
                      {dtCol.value}
                    </Typography>
                  </Box>
                </Box>
              );
              if (ColIndex === 0) {
                return <Box sx={{ width: "48%" }}>{ColItem}</Box>;
              }
              return (
                <Box
                  sx={{
                    width: "48%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {ColItem}
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box
              component="img"
              marginRight="4px"
              src="/images/SpecInstrIcon.svg"
            ></Box>
            <Box>
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="100%"
                marginBottom="8px"
                color="rgba(0, 0, 0, 0.87)"
              >
                Special Instructions
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight="400"
                lineHeight="160%"
                color="#6F6F6F"
              >
                {comment}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    );
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        {data.map((datum, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
              }}
              key={index}
            >
              {datum.name}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Medication"
        >
          <CardHeader headerImg="icon_medication.svg" headerTitle="Medication">
            {renderHeaderButtons(
              ["icon_save.svg", "icon_add_page.svg"],
              handleButtonClick
            )}
          </CardHeader>
          {data.length !== 0 && (
            <TableContainer sx={{ mb: 2, boxShadow: "none" }} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="all-family-histories">
                <TableHead>
                  <TableRow
                    sx={{
                      background: "#f2f3ff",
                      color: "#00000",
                    }}
                  >
                    {TableHeaders.map((header, index) => {
                      return (
                        <TableCell
                          sx={{
                            fontWeight: 700,
                            opacity: 1,
                            lineHeight: "24px",
                          }}
                          key={index}
                        >
                          {header}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((medication, index) => {
                    const {
                      id,
                      name,
                      dosage,
                      frequency,
                      duration,
                      meal_pref,
                      start_from,
                      comment,
                    }: any = medication;

                    return (
                      <>
                        <TableRow>
                          <StyledTableCell colSpan={4} sx={{borderBottom:'none', padding:"16px 0px 0px 0px"}}>
                            <Autocomplete
                              disableClearable
                              autoComplete={false}
                              filterOptions={(options, { inputValue }) => {
                                return options.filter((option) => true);
                              }}
                              id={`${id}-name`}
                              aria-label={`${id}-name`}
                              popupIcon={false}
                              clearIcon={false}
                              options={
                                valUpdateSearch.length === 0 ? [] : options
                              }
                              onChange={updateMedication}
                              clearOnBlur
                              clearOnEscape
                              noOptionsText={
                                <DoctorSuggestions
                                  clickSource={`${id}-name`}
                                  clickAction="UPDATE"
                                  {...doctorSuggestionProps}
                                />
                              }
                              PaperComponent={({ children }) => (
                                <Paper sx={{ minWidth: 900 }}>
                                  {children}
                                </Paper>
                              )}
                              value={
                                name !== ""
                                  ? convertLetterCase(
                                    "sentence-case",
                                    name?.split("(")[0]?.trim()
                                  )
                                  : name
                              }
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    multiline
                                    onChange={(event: any) => {
                                      if (event.target.value === "") {
                                        setOptions([]);
                                      }
                                      setValUpdateSearch(event.target.value);
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input": {
                                        fontWeight: 600,
                                        padding: 0
                                      },
                                      "& .MuiInputBase-root": {
                                        padding: 0,
                                        background:"rgba(244, 147, 154, 0.15)"
                                      },
                                      mb: 1,
                                    }}
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                  />
                                );
                              }}
                              renderOption={(
                                props: HTMLAttributes<HTMLLIElement>,
                                option: string,
                                state
                              ) => {
                                if (
                                  !data.some(
                                    (medication: any) =>
                                      medication.name === option
                                  )
                                ) {
                                  return (
                                    <Box
                                      component="li"
                                      aria-label={`${id}-name`}
                                      sx={{
                                        backgroundColor: state.selected
                                          ? "#F2F3FF !important"
                                          : "",
                                        "&:hover, &:focus": {
                                          backgroundColor:
                                            "#F2F3FF !important",
                                        },
                                      }}
                                      {...props}
                                    >
                                      {!optionsData
                                        .find(
                                          (opt: any) => opt.name === option
                                        )
                                        ?.id?.toString()
                                        .includes("custom")
                                        ? option
                                        : `Add "${option}"`}
                                    </Box>
                                  );
                                }
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  paddingRight: "0 !important",
                                  paddingLeft: "0 !important",
                                },
                              }}
                            />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow sx={{verticalAlign:'top', borderBottom:"1px solid #EEE"}}>
                          <StyledTableCell sx={{padding:"0px 5px 10px 0px", borderBottom:'none'}}>
                            <TextField
                              multiline
                              id={`update-molecule-name-${name}`}
                              name={`${id}-name`}
                              data-med-name={name}
                              onChange={(event: any) => {
                                updateMedication(event, event.target.value);
                              }}
                              value={
                                name !== ""
                                  ? convertLetterCase(
                                    "sentence-case",
                                    name?.split("(")[1]?.split(")")[0]
                                  )
                                  : name
                              }
                              sx={{
                                "& .MuiInputBase-input": {
                                  fontWeight: 300,
                                  fontSize: "14px",
                                },
                                "& .MuiInputBase-root": {
                                  padding: "8px !important",
                                  
                                },
                                mb: 1,
                              }}
                              fullWidth
                              variant="outlined"
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={{ maxWidth: 90, padding: "0px 5px 10px", borderBottom:'none' }}>
                            <Autocomplete
                              aria-label={`${id}-dosage`}
                              id={`${id}-dosage`}
                              autoComplete={false}
                              filterOptions={(options, { inputValue }) => {
                                return options.filter((option) => true);
                              }}
                              // disableClearable
                              options={qtyOptions}
                              value={dosage}
                              noOptionsText={""}
                              popupIcon={false}
                              clearIcon={false}
                              PaperComponent={({ children }) => (
                                <CustomDropDownPaper>
                                  {children}
                                </CustomDropDownPaper>
                              )}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding:"0px 0px 0px 0px !important",
                                  fontSize:"14px",
                                },
                              }}
                              onChange={updateMedication}
                              renderOption={(props, option, state) => {
                                return (
                                  <Box
                                    aria-label={`${id}-dosage`}
                                    component="li"
                                    sx={{
                                      backgroundColor: state.selected
                                        ? "#F2F3FF !important"
                                        : "",
                                      "&:hover, &:focus": {
                                        backgroundColor: "#F2F3FF !important",
                                      },
                                    }}
                                    id={`${id}-dosage-${option}`}
                                    {...props}
                                  >
                                    {option}
                                  </Box>
                                );
                              }}
                              renderInput={(params) => {
                                return (
                                  <Tooltip title="Eg: 1 Tablet">
                                    <TextField
                                      name={`${id}-dosage`}
                                      {...params}
                                      placeholder="Eg: 1 Tablet"
                                      onChange={updateQtyOptions}
                                      onFocus={handleFocus}
                                      fullWidth
                                    />
                                  </Tooltip>
                                );
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ maxWidth: 125, padding: "0px 5px", borderBottom:'none' }}
                          >
                            <Autocomplete
                              aria-label={`${id}-frequency`}
                              id={`${id}-frequency`}
                              filterOptions={(options, { inputValue }) => {
                                return options.filter((option) => true);
                              }}
                              autoComplete={false}
                              // disableClearable
                              noOptionsText=""
                              options={frequencyMaster ? frequencyMaster : []}
                              value={frequency}
                              popupIcon={false}
                              clearIcon={false}
                              PaperComponent={({ children }) => (
                                <CustomDropDownPaper>
                                  {children}
                                </CustomDropDownPaper>
                              )}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding:"0px 0px 0px 0px !important",
                                  fontSize:"14px",
                                },
                              }}
                              onChange={updateMedication}
                              renderOption={(props, option, state) => {
                                return (
                                  <Box
                                    aria-label={`${id}-frequency`}
                                    component="li"
                                    sx={{
                                      backgroundColor: state.selected
                                        ? "#F2F3FF !important"
                                        : "",
                                      "&:hover, &:focus": {
                                        backgroundColor: "#F2F3FF !important",
                                      },
                                    }}
                                    id={`${id}-frequency-${option}`}
                                    {...props}
                                  >
                                    {option}
                                  </Box>
                                );
                              }}
                              renderInput={(params) => {
                                return <TextField name={`${id}-frequency`} {...params} fullWidth />;
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={tableCellStyle}>
                            <Autocomplete
                              autoComplete={false}
                              filterOptions={(options, { inputValue }) => {
                                return options.filter((option) => true);
                              }}
                              aria-label={`${id}-duration`}
                              id={`${id}-duration`}
                              // disableClearable
                              options={durationOptions}
                              value={duration}
                              noOptionsText={""}
                              popupIcon={false}
                              clearIcon={false}
                              PaperComponent={({ children }) => (
                                <CustomDropDownPaper>
                                  {children}
                                </CustomDropDownPaper>
                              )}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding:"0px 0px 0px 0px !important",
                                  fontSize:"14px",
                                },
                              }}
                              // open={durationOptions.length === 0 ? false : true}
                              onChange={updateMedication}
                              renderOption={(props, option, state) => {
                                return (
                                  <Box
                                    aria-label={`${id}-duration`}
                                    component="li"
                                    sx={{
                                      backgroundColor: state.selected
                                        ? "#F2F3FF !important"
                                        : "",
                                      "&:hover, &:focus": {
                                        backgroundColor: "#F2F3FF !important",
                                      },
                                    }}
                                    id={`${id}-duration-${option}`}
                                    {...props}
                                  >
                                    {option}
                                  </Box>
                                );
                              }}
                              renderInput={(params) => {
                                return (
                                  <Tooltip title="Eg: 1 day">
                                    <TextField
                                      name={`${id}-duration`}
                                      {...params}
                                      placeholder="Eg: 1 day"
                                      onChange={updateDurationOptions}
                                      fullWidth
                                    />
                                  </Tooltip>
                                );
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={tableCellStyle}>
                            <Autocomplete
                              aria-label={`${id}-meal_pref`}
                              id={`${id}-meal_pref`}
                              filterOptions={(options, { inputValue }) => {
                                return options.filter((option) => true);
                              }}
                              autoComplete={false}
                              // disableClearable
                              noOptionsText=""
                              options={mealPrefMaster}
                              value={meal_pref}
                              popupIcon={false}
                              clearIcon={false}
                              PaperComponent={({ children }) => (
                                <CustomDropDownPaper>
                                  {children}
                                </CustomDropDownPaper>
                              )}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  padding:"0px 0px 0px 0px !important",
                                  fontSize:"14px",
                                },
                              }}
                              onChange={updateMedication}
                              renderOption={(props, option, state) => {
                                return (
                                  <Box
                                    aria-label={`${id}-meal_pref`}
                                    component="li"
                                    sx={{
                                      backgroundColor: state.selected
                                        ? "#F2F3FF !important"
                                        : "",
                                      "&:hover, &:focus": {
                                        backgroundColor: "#F2F3FF !important",
                                      },
                                    }}
                                    id={`${id}-meal_pref-${option}`}
                                    {...props}
                                  >
                                    {option}
                                  </Box>
                                );
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField name={`${id}-meal_pref`} multiline {...params} fullWidth />
                                );
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={tableCellStyle}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                className="dark"
                                format="DD/MM/YYYY"
                                slotProps={{
                                  textField: {
                                    error: false,
                                    disabled: true,
                                    placeholder: "Select Date",
                                  },
                                  popper: {
                                    style: {
                                      zIndex: 0
                                    }
                                  }

                                }}
                                minDate={dayjs()}
                                value={
                                  start_from?.length !== 0
                                    ? dayjs(start_from)
                                    : dayjs(currentDate)
                                }
                                onChange={(newValue: any) => {
                                  setTabData((prev: any) => {
                                    return prev.map((section: any) => {
                                      if (section.view_type === MEDICATION) {
                                        return {
                                          ...section,
                                          rows: section.rows.map(
                                            (row: any, index: number) => {
                                              if (index === 0) {
                                                return {
                                                  ...row,
                                                  value: row.value.map(
                                                    (medication: any) => {
                                                      if (
                                                        medication.name === name
                                                      ) {
                                                        return {
                                                          ...medication,
                                                          start_from: newValue,
                                                        };
                                                      }
                                                      return medication;
                                                    }
                                                  ),
                                                };
                                              }
                                              return row;
                                            }
                                          ),
                                        };
                                      }
                                      return section;
                                    });
                                  });
                                }}
                                sx={{
                                  "& .MuiInputBase-root":{
                                    padding:"0px"
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    width: "112px !important",
                                    fontSize: "14px",
                                  },
                                  "& .MuiInputBase-input":{
                                    padding: "5px 0px 5px 5px !important",
                                  },
                                  "& .MuiButtonBase-root":{
                                    padding:"0px",
                                    marginRight:"2px"
                                  },
                                  "& .MuiSvgIcon-root":{
                                    width:"20px",
                                  } 
                                }}
                              />
                            </LocalizationProvider>
                          </StyledTableCell>
                          <StyledTableCell sx={tableCellStyle}>
                            {/* <Tooltip title={comment}> */}
                            <TextField
                              autoComplete="off"
                              multiline
                              // multiline={comment.length <= 100}
                              name={`${id}-comment`}
                              aria-label={`${id}-comment`}
                              onChange={(event: any) => {
                                let value = event.target.value;
                                if (value.length > 400) {
                                  value = value.substring(0, 400);
                                }
                                setData((prev: any) => {
                                  return prev.map((elem: any) => {
                                    if (elem.id.toString() === id.toString()) {
                                      return {
                                        ...elem,
                                        comment: value,
                                      };
                                    }
                                    return elem;
                                  });
                                });
                              }}
                              onBlur={(event: any) => {
                                updateMedication(
                                  event as any,
                                  event.target.value
                                );
                              }}
                              sx={{
                                color: "#545F71 !important",
                                "& .MuiInputBase-root": {
                                  padding: "5px",
                                  fontSize: "14px",
                                },
                              }}
                              value={comment}
                              fullWidth
                            />
                            {/* </Tooltip> */}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ width: "32px", padding: "0 !important", borderBottom:'none' }}
                            align="right"
                          >
                            <Tooltip title={"Delete"}>
                              <button
                                id={id}
                                onClick={removeMedication}
                                style={{
                                  outline: "none",
                                  border: "none",
                                  background: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  style={{ pointerEvents: "none" }}
                                  src="/images/minus-in-circle.svg"
                                  alt="remove-button"
                                />
                              </button>
                            </Tooltip>
                          </StyledTableCell>
                        </TableRow>
                      </>

                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Autocomplete
            id="search-for-medicines"
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            value={""}
            clearOnBlur
            clearOnEscape
            popupIcon={false}
            clearIcon={false}
            inputValue={searchValue}
            onChange={addMedication}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autoref}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    if (value === "") {
                      setOptions([]);
                    }
                    if (!(value?.includes("(") && value?.includes(")"))) {
                      setSearchValue(event.target.value);
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for medicines"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((medication: any) => medication.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplate && (
            <Box position="absolute" top="68px" right="79px" zIndex="1">
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {serachExistingTemplate && (
            <Box position="absolute" top="68px" right="39px" zIndex="1">
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <Autocomplete
            id="search-for-medicines"
            filterOptions={(options, { inputValue }) => {
              return options.filter((option) => true);
            }}
            options={searchValue.length === 0 ? [] : options}
            disableClearable
            value={""}
            clearOnBlur
            clearOnEscape
            popupIcon={false}
            clearIcon={false}
            inputValue={searchValue}
            onChange={(event, value) => {
              assignMedicationMobile(value);
            }}
            noOptionsText={
              <DoctorSuggestions clickAction="ADD" {...doctorSuggestionProps} />
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={autoref}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    if (value === "") {
                      setOptions([]);
                    }
                    if (!(value?.includes("(") && value?.includes(")"))) {
                      setSearchValue(event.target.value);
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/icon_search.svg" alt="search_icon" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search for medicines"
                  fullWidth
                  variant="outlined"
                />
              );
            }}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              option: string,
              state
            ) => {
              if (!data.some((medication: any) => medication.name === option)) {
                return (
                  <Box
                    component="li"
                    sx={{
                      backgroundColor: state.selected
                        ? "#F2F3FF !important"
                        : "",
                      "&:hover, &:focus": {
                        backgroundColor: "#F2F3FF !important",
                      },
                    }}
                    {...props}
                  >
                    {!optionsData
                      .find((opt: any) => opt.name === option)
                      ?.id?.toString()
                      .includes("custom")
                      ? option
                      : `Add "${option}"`}
                  </Box>
                );
              }
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "flip",
                    enabled: false,
                  },
                  {
                    name: "preventOverflow",
                    enabled: false,
                  },
                ],
              },
            }}
          />
          {openSaveTemplateMobile && (
            <Box sx={MiniTemplateStyle}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {searchExistingTemplateMobile && (
            <Box sx={AddMiniTemplateStyle}>
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
          <Box marginTop="16px">
            {data.length !== 0 &&
              data.map((medication, index) => {
                return (
                  <Box
                    component="div"
                    id={medication.id}
                    onClick={(_event: any) => {
                      if (_event.target.ariaLabel === "delete-medication") {
                        return;
                      }
                      setDialog({
                        ...dialog,
                        open: true,
                        mode: "UPDATE",
                        content: medication,
                      });
                    }}
                    key={index}
                  >
                    {MedicationCardMobile(medication, index)}
                  </Box>
                );
              })}
          </Box>
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
      {isMobileScreen && (
        <CustomizedDialogs {...(dialogProps as any)}>
          <Box>
            <Box className="header" sx={{ display: "flex", padding: "12px" }}>
              <Box
                component="img"
                sx={{ marginRight: 1.5 }}
                src="/images/icon_medication.svg"
              />
              <Box className="text-content" sx={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <Box>
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="100%"
                  marginBottom="8px"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  Medication
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="100%"
                  color="#494E9D"
                >
                  {dialog.content.name && dialog.content.name !== ""
                    ? convertLetterCase(
                      "sentence-case",
                      dialog.content.name?.split("(")[0]?.trim()
                    )
                    : dialog.content.name}
                </Typography>
              </Box>
              </Box>
              <Box onClick={handleDialogState}>
                  <CloseIcon sx={{ color: theme.palette.grey[500] }} />
                </Box>
            </Box>
            <Divider />
            <Box className="medication-card-content" sx={{ padding: "12px" }}>
              <TextField
                multiline
                id={`update-molecule-name-${name}`}
                name={`${dialog.content.id}-name`}
                label="Medicine"
                // onChange={(event, value) => {
                //   setDialog({
                //     ...dialog,
                //     content: {
                //       ...dialog.content,

                //     },
                //   });
                // }}
                value={
                  dialog.content.name && dialog.content.name !== ""
                    ? convertLetterCase(
                      "sentence-case",
                      dialog.content.name?.split("(")[1]?.split(")")[0]
                    )
                    : dialog.content.name
                }
                sx={{
                  "& .MuiInputBase-input": {
                    fontWeight: 400,
                    color: "rgba(38, 38, 38, 1)",
                  },
                  mb: 2,
                }}
                fullWidth
                variant="outlined"
              />
              <Box
                className="medicine-details-section"
                sx={{ display: "flex", flexWrap: "wrap", gap: "14px" }}
              >
                <Autocomplete
                  autoComplete={false}
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => true);
                  }}
                  // disableClearable
                  options={qtyOptions}
                  value={dialog.content.dosage}
                  noOptionsText={""}
                  popupIcon={false}
                  clearIcon={false}
                  PaperComponent={({ children }) => (
                    <CustomDropDownPaper>{children}</CustomDropDownPaper>
                  )}
                  sx={{
                    width: "48%",
                  }}
                  onChange={(event, value) => {
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        dosage: value,
                      },
                    });
                  }}
                  renderOption={(props, option, state) => {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {option}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        onChange={updateQtyOptions}
                        onFocus={handleFocus}
                        fullWidth
                        label="Quantity per dose"
                        placeholder="Eg: 1 Tablet"
                      />
                    );
                  }}
                />
                <Autocomplete
                  autoComplete={false}
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => true);
                  }}
                  // disableClearable
                  noOptionsText=""
                  options={frequencyMaster ? frequencyMaster : []}
                  value={dialog.content.frequency}
                  popupIcon={false}
                  clearIcon={false}
                  PaperComponent={({ children }) => (
                    <CustomDropDownPaper>{children}</CustomDropDownPaper>
                  )}
                  sx={{ width: "48%" }}
                  onChange={(event, value) => {
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        frequency: value,
                      },
                    });
                  }}
                  renderOption={(props, option, state) => {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {option}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField {...params} label="Frequency" fullWidth />
                    );
                  }}
                />
                <Autocomplete
                  autoComplete={false}
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => true);
                  }}
                  // disableClearable
                  options={durationOptions}
                  value={dialog.content.duration}
                  noOptionsText={""}
                  popupIcon={false}
                  clearIcon={false}
                  PaperComponent={({ children }) => (
                    <CustomDropDownPaper>{children}</CustomDropDownPaper>
                  )}
                  sx={{ width: "48%" }}
                  onChange={(event, value) => {
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        duration: value,
                      },
                    });
                  }}
                  renderOption={(props, option, state) => {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {option}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        onChange={updateDurationOptions}
                        fullWidth
                        label="Duration Unit"
                        placeholder="Eg: 1 day"
                      />
                    );
                  }}
                />
                <Autocomplete
                  autoComplete={false}
                  disableClearable
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => true);
                  }}
                  noOptionsText=""
                  options={mealPrefMaster}
                  value={dialog.content.meal_pref}
                  popupIcon={false}
                  clearIcon={false}
                  PaperComponent={({ children }) => (
                    <CustomDropDownPaper>{children}</CustomDropDownPaper>
                  )}
                  sx={{ width: "48%" }}
                  onChange={(event, value) => {
                    setDialog({
                      ...dialog,
                      content: {
                        ...dialog.content,
                        meal_pref: value,
                      },
                    });
                  }}
                  renderOption={(props, option, state) => {
                    return (
                      <Box
                        component="li"
                        sx={{
                          backgroundColor: state.selected
                            ? "#F2F3FF !important"
                            : "",
                          "&:hover, &:focus": {
                            backgroundColor: "#F2F3FF !important",
                          },
                        }}
                        {...props}
                      >
                        {option}
                      </Box>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        multiline
                        {...params}
                        fullWidth
                        label="Meal Pref"
                      />
                    );
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="dark"
                    format="DD/MM/YYYY"
                    label="Date"
                    slotProps={{
                      textField: {
                        InputProps: {
                          endAdornment: (
                            <Box
                              width={{
                                md: 40,
                                marginRight: 4,
                              }}
                              component="img"
                              src={"/images/icon_mobile_calender.svg"}
                            />
                          ),
                        },
                        error: false,
                        // disabled: true,
                        placeholder: "Select Date",
                      },
                    }}
                    sx={{ width: "48%" }}
                    minDate={dayjs()}
                    value={
                      dialog.content.start_from?.length !== 0
                        ? dayjs(dialog.content.start_from)
                        : dayjs(currentDate)
                    }
                    onChange={(value) => {
                      setDialog({
                        ...dialog,
                        content: {
                          ...dialog.content,
                          start_from: value,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <TextField
                autoComplete="off"
                onChange={(event: any) => {
                  let value = event.target.value;
                  if (value.length > 400) {
                    value = value.substring(0, 400);
                  }
                  setDialog({
                    ...dialog,
                    content: {
                      ...dialog.content,
                      comment: value,
                    },
                  });
                }}
                sx={{
                  color: "#545F71 !important",
                  marginTop: "16px",
                }}
                value={dialog.content.comment}
                fullWidth
                label="Special Instructions"
              />
            </Box>
            <Box sx={{ padding: "12px" }}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none !important",
                  borderRadius: "6px",
                }}
                fullWidth
                onClick={() => {
                  if (dialog.mode === "ADD" && dialog.content.id) {
                    const newMedication = {
                      ...dialog.content,
                      start_from:
                        dialog.content.start_from !== ""
                          ? dialog.content.start_from
                          : dayjs(currentDate),
                    };
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === MEDICATION) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: [...row.value, newMedication],
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setSearchValue("");
                  }
                  if (dialog.mode === "UPDATE" && dialog.content.id) {
                    setTabData((prev: any) => {
                      return prev.map((section: any) => {
                        if (section.view_type === MEDICATION) {
                          return {
                            ...section,
                            rows: section.rows.map(
                              (row: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...row,
                                    value: row.value.map((val: any) => {
                                      if (val.id === dialog.content.id) {
                                        return {
                                          ...dialog.content,
                                        };
                                      }
                                      return val;
                                    }),
                                  };
                                }
                                return row;
                              }
                            ),
                          };
                        }
                        return section;
                      });
                    });
                    setDialog({
                      ...dialog,
                      open: false,
                      content: {},
                    });
                    setOptions([]);
                    setDurationOptions([]);
                  }
                }}
              >
                {dialog.mode === "ADD" ? `Add` : `Update`} Medication
              </Button>
            </Box>
          </Box>
        </CustomizedDialogs>
      )}
    </>
  );
}
