
import { Box, Button, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { API_ROUTE_CREATE_INVOICE, ROUTE_ALL_INVOICES, ROUTE_APPOINTMENTS } from '../../../shared/routes/route.constants';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import { APIClient } from '../../../shared/Utils/api-client';
import { useSelector } from 'react-redux';

const initState = { 
    invoice_amount: 0,
    payment_option: "offline",
    invoice_details: [],
    discount: "",
    billed_amount: 0,
    payment_mode: "cash",
    appointment_id: "",
    doctor_id: "",
    patient_id: "",
    account_id: "",
    amount_received: ""
}

let initTableState = {
    category:'consultation',
    type:'',
    quantity:'',
    id:'',
    cost:0,
}
const initOptionsState = {
    category:['consultation'],
    type:[
        '1st Visit',
        'Follow up'
    ],
}

const initModalState = {
    isSuccess:'',
    headerText:'',
    subText:'',
    actionButtonOnClick:()=>{return;},
    actionButtonText:'',
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:"14px",
    p: 2,
    
  };
  const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 280,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:"14px",
    p: 2,
  };

const CreateInvoice = () => {

    //hooks
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const {accountInfo}  = useSelector((state)=>state.auth);

    //state

    const [state,setState] = useReducer((state,action)=>{
        return {
            ...state,
            ...action
        }
    },initState)

    const [tableState, setTableState] = useReducer((state,action)=>{
        return{
            ...state,
            ...action
        }
    },initTableState)

    const [submitLoader,setSubmitLoader] = useState(false);
    const [openSubmitModal,setOpenSubmitModal] = useState(false);
    
    const [modalData, setModalData] = useReducer((state,action)=>{
        return{
            ...state,
            ...action
        }
    },initModalState)

    const [optionsState, setOptionsState] = useReducer((state,action)=>{
        return{
            ...state,
            ...action
        }
    },initOptionsState)


    //useEffect

    useEffect(()=>{
        let total = state.invoice_details?.reduce((acc,curr)=>{
            return acc = acc+(curr.total||0);
        },0)

        let totalAfterDiscount = (total*(1-(state.discount/100))).toFixed(2);

        setState({
            billed_amount:total || 0,
            invoice_amount:totalAfterDiscount || 0,
        })
    },[state.invoice_details,state.discount])

    useEffect(()=>{
        setState({
            appointment_id: searchParams.get('appointment_id'),
            doctor_id: searchParams.get('doctor_id'),
            patient_id: searchParams.get('patient_id'),
            account_id: searchParams.get('account_id')
        })
    },[
        searchParams.get('appointment_id'),
        searchParams.get('doctor_id'),
        searchParams.get('patient_id'),
        searchParams.get('account_id')
    ])

    useEffect(()=>{
        if(accountInfo.invoice_settings?.header?.length){
            let typeIndex = accountInfo.invoice_settings?.header.indexOf('type');
            let nameIndex = accountInfo.invoice_settings?.header.indexOf('name');
            let category = accountInfo.invoice_settings?.body?.map(elem=>elem[typeIndex]) || [];
            let type = accountInfo.invoice_settings?.body?.map(elem=>elem[nameIndex]) || [];

            // let uniqueCategory = [...new Set(category)];
            // setOptionsState({
            //     category:uniqueCategory,
            //     type:type
            // })
            let uniqueCategory = ['consultation'];
            // setOptionsState({
            //     category:uniqueCategory,
            //     type:type
            // })
            if(uniqueCategory?.length===1){
                setTableState({
                    // category:uniqueCategory[0]
                    category:optionsState.category[0]
                })
                initTableState = {
                    ...initTableState,
                    // category: uniqueCategory[0]
                    category:optionsState.category[0]
                }
            }
        }
        
    },[accountInfo.invoice_settings])


    //functions

    const handleClickViewAllInvoice = ()=>{
        navigate({
            pathname: '/'+ROUTE_ALL_INVOICES,
            search: createSearchParams({
                patient_id:searchParams.get('patient_id'),
            }).toString()
        });
        return;
    }
    const handleClickCancel = ()=>{
        navigate(ROUTE_APPOINTMENTS)
        return;
    }
    const handleCreateInvoice = async ()=>{
        try {
            setSubmitLoader(true);
            await APIClient.post(API_ROUTE_CREATE_INVOICE(),state).then(res=>{
                setSubmitLoader(false);
                setModalData({
                    isSuccess:true,
                    headerText:'Invoice Created',
                    subText:'',
                    actionButtonOnClick:()=>{navigate('/'+ROUTE_ALL_INVOICES)},
                    actionButtonText:'Done',
                })
                setOpenSubmitModal(true);
            }).catch(err=>{
                setSubmitLoader(false);
                setModalData({
                    isSuccess:false,
                    headerText:'Unable to create invoice',
                    subText:err?.message || '',
                    actionButtonOnClick:()=>{setOpenSubmitModal(false)},
                    actionButtonText:'Close',
                })
                setOpenSubmitModal(true);
            })
        } catch (error) {
            console.log("error",error)
        }
        return;
    }

    const handleClickAdd = ()=>{
        setState({
            invoice_details:[...state.invoice_details,{...tableState,id:Date.now()}]
        })
        setTableState(initTableState);
        return;
    }

    const handleDeleteTableEntry = (index)=>{
        setState({
            invoice_details:state.invoice_details.filter((elem,i)=>i!=index)
        })
        return;
    }

  return (
    <>
        <Box height={'100%'} p={2} display={'flex'} flexDirection={'column'} sx={{overflow:'auto'}}>
            {/* header */}
            <Box display={'flex'} justifyContent={'end'}>
                <Button variant='outlined' onClick={handleClickViewAllInvoice}>
                    View All Invoices
                </Button>
            </Box>

            {/* details */}
            <Box height={'100%'} flex={1} display={'flex'} gap={2} py={2}>
                <Box flex={1} display={'flex'} flexDirection={'column'} gap={2} height={'100%'} p={2} sx={{backgroundColor:theme.palette.white.main, borderRadius:'8px',overflow:'auto'}}>
                    {/* invoice details */}
                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                        <Box display={'flex'} gap={1}>
                            <Box
                                component={'img'}
                                src='/images/icon_invoice_details.svg'
                            />
                            <Typography color={theme.palette.primary.main}>INVOICE DETAILS</Typography>
                        </Box>
                        <Box display={'flex'} gap={1}>
                            <FormControl sx={{flex:1}} size='small'>
                                <InputLabel id="demo-simple-select-label2">Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    id="demo-simple-select2"
                                    value={tableState.category}
                                    label="Category"
                                    onChange={(e)=>{setTableState({category:e.target.value})}}
                                    disabled={optionsState?.category?.length<=1}
                                    
                                >
                                    {optionsState.category?.map(elem=>{
                                        return (
                                            <MenuItem value={elem}>{elem}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl sx={{flex:1}} size='small'>
                                <InputLabel id="demo-simple-select-label">Visit Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tableState.type}
                                    label="Visit Type"
                                    onChange={(e)=>{setTableState({type:e.target.value})}}
                                    
                                >
                                    {optionsState.type?.map(elem=>{
                                        return (
                                            <MenuItem value={elem}>{elem}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <TextField
                                label={'Quantity'}
                                value={tableState.quantity}
                                onChange={(e)=>{
                                    const onlyNums = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    );
                                    if(+onlyNums>0 || onlyNums===''){
                                        setTableState({
                                            quantity:onlyNums
                                        })
                                    }
                                }}
                                sx={{flex:1}}
                                size="small"
                            />

                            <Button variant='contained' startIcon={<AddIcon fontSize='small'/>} sx={{flex:1}} onClick={handleClickAdd} disabled={!tableState.category || !tableState.type || !tableState.quantity}>
                                <Typography>Add</Typography>
                            </Button>
                        </Box>
                        <Box>

                        <TableContainer component={Paper} style={{ boxShadow:'none', maxHeight: 180, overflow:'auto' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">#</TableCell>
                                        <TableCell align="left">Category</TableCell>
                                        <TableCell align="left">Item</TableCell>
                                        <TableCell align="left">Quantity</TableCell>
                                        <TableCell align="left">Unit Cost</TableCell>
                                        <TableCell align="left">Total</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state?.invoice_details?.map((row,index) => (
                                        <TableRow
                                            key={row?.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {index+1}
                                            </TableCell>
                                            <TableCell align="left">{row.category}</TableCell>
                                            <TableCell align="left">{row.type}</TableCell>
                                            <TableCell align="left">{row.quantity}</TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    key={row?.id}
                                                    size='small'
                                                    value={row.cost}
                                                    onChange={(e)=>{
                                                        const onlyNums = e.target.value.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                        );
                                                        if(+onlyNums>=0){
                                                            setState({
                                                                invoice_details:state.invoice_details.map((elem,i)=>{
                                                                    if(index===i){
                                                                        return {
                                                                            ...elem,
                                                                            cost:onlyNums,
                                                                            total:onlyNums*row.quantity
                                                                        }
                                                                    }
                                                                    return elem;
                                                                })
                                                            })
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{row.total}</TableCell>
                                            <TableCell align="left">
                                               <IconButton onClick={(e)=>{handleDeleteTableEntry(index)}}>
                                                    <DeleteIcon/>
                                               </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        </Box>
                    </Box>

                    {/* payment details */}
                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                        <Box display={'flex'} gap={1}>
                            <Box
                                component={'img'}
                                src='/images/icon_payment_details.svg'
                            />
                            <Typography color={theme.palette.primary.main}>PAYMENT DETAILS</Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography sx={{width:'200px'}} color={theme.palette.white.darkerText}>Payment Mode</Typography>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={state.payment_option}
                                onChange={(e) => {
                                    setState({
                                        payment_option:e.target.value
                                    })
                                    return;
                                }}
                                sx={{gap:2}}
                              >
                                <FormControlLabel
                                  value="offline"
                                  control={<Radio />}
                                  label="Offline"
                                />
                                <FormControlLabel
                                  value="online"
                                  control={<Radio />}
                                  label="Online"
                                  disabled={true}
                                />
                              </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography sx={{width:'200px'}} color={theme.palette.white.darkerText}>Payment Method</Typography>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={state.payment_mode}
                                onChange={(e) => {
                                    setState({
                                        payment_mode:e.target.value
                                    })
                                    return;
                                }}
                                sx={{gap:2}}
                              >
                                <FormControlLabel
                                  value="cash"
                                  control={<Radio />}
                                  label="Cash"
                                />
                                <FormControlLabel
                                  value="card"
                                  control={<Radio />}
                                  label="Card"
                                />
                                <FormControlLabel
                                  value="upi"
                                  control={<Radio />}
                                  label="UPI"
                                />
                                <FormControlLabel
                                  value="wallet"
                                  control={<Radio />}
                                  label="Wallet"
                                />
                              </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography sx={{width:'200px'}} color={theme.palette.white.darkerText}>Amount Received</Typography>
                            <TextField
                                label={'Received Amount'}
                                value={state.amount_received}
                                onChange={(e)=>{
                                    const onlyNums = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    );
                                    if(+onlyNums>=0){
                                        setState({
                                            amount_received:onlyNums
                                        })
                                    }
                                }}
                                size='small'
                            />
                        </Box>
                    </Box>
                </Box>

                <Box width={'400px'} display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{backgroundColor:theme.palette.white.main, borderRadius:'8px',overflow:'auto'}}>
                    <Box
                        component={'img'}
                        src='/images/img_invoice.svg'
                        sx={{mt:4}}
                    />
                    <Box width={'100%'} p={2} display={'flex'} flexDirection={'column'} gap={1} alignItems={'start'}>
                        <Typography fontWeight={'bold'}>Summary</Typography>
                        <Divider sx={{width:'100%'}}/>
                        <Box display={'flex'}>
                                <Typography width={'150px'}>{`Sub Total (Rs)`}</Typography>
                                <Typography>{state.billed_amount}</Typography>
                        </Box>
                        <Box display={'flex'}  alignItems={'center'}>
                                <Typography width={'150px'}>Discount</Typography>
                                <TextField
                                    size='small'
                                    value={state.discount}
                                    onChange={(e)=>{
                                        const onlyNums = e.target.value.replace(
                                            /[^0-9]/g,
                                            ""
                                        );
                                        if(+onlyNums>=0 && +onlyNums<=100){
                                            setState({
                                                discount:onlyNums
                                            })
                                        }
                                    }}
                                    label={'Enter value (%)'}
                                />
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                                <Typography variant='h6' fontWeight={'bold'} width={'150px'}>{`Total (Rs)`}</Typography>
                                <Typography variant='h6' fontWeight={'bold'}>{state.invoice_amount}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* footer */}
            <Box display={'flex'} justifyContent={'end'} gap={2} sx={{backgroundColor:theme.palette.white.main, borderRadius:'8px',p:1}}>
                <Button variant='outlined' onClick={handleClickCancel}>
                    Cancel
                </Button>

                <LoadingButton loading={submitLoader} disabled={!state.amount_received || !state.invoice_details?.length} variant='contained' onClick={handleCreateInvoice}>
                    Create Invoice
                </LoadingButton>
            </Box>
        </Box>
        <Modal
            open={openSubmitModal}
            onClose={() => {
                setOpenSubmitModal(false);
                if(modalData.isSuccess){
                    handleClickViewAllInvoice();
                }
            }}
        >
            <Box
                sx={isMobileScreen ? styleMobile : style}
                display={"flex"}
                flexDirection={"column"}
                gap={1}
            >
                <Box display={"flex"} justifyContent={"flex-end"}>
                <Box>
                    <IconButton
                    onClick={() => {
                        setOpenSubmitModal(false);
                        if(modalData.isSuccess){
                            handleClickViewAllInvoice();
                        }
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                </Box>
                </Box>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={1}
                >
                <Box
                    width={{ md: 80 }}
                    component="img"
                    alt="add patient"
                    src={
                    !modalData.isSuccess
                        ? "/images/appointmentFailed.svg"
                        : "/images/appointmentConfirmed.svg"
                    }
                />
                </Box>

                <Box
                    maxHeight={300}
                    overflow={"auto"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    my={2}
                >
                <Typography variant="h6" fontWeight={600} textAlign={"center"}>
                    {modalData.headerText}
                </Typography>
                <Typography variant="subtitle1" textAlign={"center"}>
                    {modalData.subText}
                </Typography>
                {!modalData.isSuccess && (
                    <Button variant="outlined" onClick={modalData.actionButtonOnClick}>
                    {modalData.actionButtonText}
                    </Button>
                )}
                </Box>
            </Box>
        </Modal>
    </>
  )
}

export default CreateInvoice

// {
//     "invoice_amount": 0,
//     "payment_option": "offline",
//     "invoice_details": [
//         {
//             "category": "test2",
//             "type": "1000",
//             "quantity": "1",
//             "cost": 0,
//             "total": 0
//         }
//     ],
//     "discount": "",
//     "billed_amount": 0,
//     "payment_mode": "cash",
//     "appointment_id": "svaas:2024-05-28$appt:5f025716-58a8-433f-93d3-61865f0af850:2024-05-27T13:40:54:336a194",
//     "doctor_id": "5f025716-58a8-433f-93d3-61865f0af850",
//     "patient_id": "1716460676983cc8fa",
//     "account_id": "svaas",
//     "amount_received": "1000"
// }