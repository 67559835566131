import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchPatientDocuments,
  fetchSignedURL,
  savePrescriptionDocuments,
} from "../../../../../redux/reducers/prescription.slice";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosResponse } from "axios";
import { fileSavePatientDocuments } from "../../../../../shared/Utils/utils";
import moment from "moment";
import PdfReport from "../../utils/PdfReview";
import PdfIcon from "../../../../../shared/icons/PdfIcon";

interface FilesProps {
  index: number;
  path: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}
interface DocumentTypes {
  label: string;
  value: string;
}
type Props = {
  setFiles: React.Dispatch<React.SetStateAction<Array<FilesProps>>>;
  files: Array<FilesProps>;
  HandleDialog: () => void;
  isMobileScreen: any;
};

interface DocumentDetails {
  name: string;
  type: string;
  id: string;
  file_type: string;
}

interface FormDataItem {
  originalFilename: string;
  name: string;
  file_type: string;
  date: any;
  type: string;
  id: string;
  DocUrl: any;
}

const DocumentTypes: DocumentTypes[] = [
  { label: "Diagnostics", value: "diagnostics" },
  { label: "Discharge Summary", value: "discharge_summary" },
  { label: "Prescription", value: "prescription" },
  { label: "Others", value: "other" },
];

const FileUploader = ({
  isMobileScreen,
  HandleDialog,
  setFiles,
  files = [],
}: Props) => {
  const [nameErrors, setNameErrors] = useState<string[]>([]);
  const [typeErrors, setTypeErrors] = useState<string[]>([]);
  const [dateErrors, setDateErrors] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const { selectedAppointment } = useSelector(
    (state: any) => state.appointments
  );
  const {
    prescription: { signedURL },
  } = useSelector((state: any) => state.prescription);
  const initializeFormData = (
    numFiles: number,
    type: string
  ): FormDataItem[] => {
    return Array.from({ length: numFiles }, () => ({
      originalFilename: "",
      name: "",
      file_type: "",
      date: "",
      type: "",
      id: "",
      DocUrl: "",
    }));
  };
  const [formData, setFormData] = useState(() => {
    return initializeFormData(files.length, "");
  });
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [LoadedData, setLoadedData] = useState<any>({});
  const [largeSizeData, setLargeSizeData] = useState<any>([]);

  const inputFileRef: any = useRef(null);
  const {
    selectedAppointment: { patient },
  } = useSelector((state: any) => state.appointments);

  useEffect(() => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, []);
  const TodaysDate = moment().format("YYYY-MM-DD");
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(today.getMonth() - 6);
  const formattedSixMonthsAgo = sixMonthsAgo.toISOString().split("T")[0];

  const {
    prescription,
    loaders: { fetchingPatientDocuments },
  }: any = useSelector((state: any) => state.prescription);

  const getPrescriptionDocuments = async (start: any, end: any) => {
    try {
      const response = await dispatch(
        fetchPatientDocuments({
          params: {
            patient_id: patient?.patient_id,
            start_date: start,
            end_date: end,
          },
        })
      );
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const formatDate = (dateObject: any): string => {
    return dateObject ? dateObject.format("YYYY-MM-DD") : "";
  };

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        if (acceptedFiles.length === 0) {
          return;
        }
        setLoading(true);
        const startTime = performance.now();
        const file = acceptedFiles[0];

        const maxSize = 3145728; // 3MB
        if (file.size > maxSize) {
          RadFileDataURL(file, (dataUrl) => {
            setLargeSizeData([
              {
                name: file.name,
                size: file.size,
                type: file.type,
                Docurl: dataUrl,
              },
            ]);
          });
          // setTimeout(() => {
          //   setLoading(false);
          //   setLargeSizeData([]);
          // }, 100000);
          // setLoading(false);
          return;
        }
        RadFileDataURL(file, (dataUrl) => {
          setLoadedData({
            name: file.name,
            size: file.size,
            type: file.type,
            Docurl: dataUrl,
          });
        });
        const res = await dispatch(
          fetchSignedURL({
            params: {
              type: "report",
              content_type: file.type,
              appointment_id: selectedAppointment?.appointment_id,
              doctor_id: selectedAppointment?.doctor_id,
              patient_id: selectedAppointment?.patient?.patient_id,
              thb_appt_id: selectedAppointment?.id,
            },
          })
        );
        const displayUrl = res?.payload?.data?.data?.url;
        const id: string = res?.payload?.data?.data?.id;
        if (displayUrl) {
          const binaryStr = await readFileAsArrayBuffer(file);

          const contentType = file.type;
          const response = await fileSavePatientDocuments({
            displayUrl,
            binaryStr,
            contentType,
            onUploadProgress: (progressEvent: any) => {
              if (progress === 100) {
                return 0;
              }
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
          });
          const endTime = performance.now();
          const timeTaken = endTime - startTime;

          setFiles((files) => [...files, file]);
          setProgress(timeTaken);
          setLoading(false);

          RadFileDataURL(file, (dataUrl) => {
            setFormData((prevData) => [
              ...prevData,
              {
                originalFilename: file.name,
                name: "",
                file_type: file.type,
                date: new Date(),
                type: "",
                id: id,
                DocUrl: dataUrl,
              },
            ]);
          });
          setLoadedData({});
          setLargeSizeData([]);
        }
      } catch (error) {
        console.error("Error in onDrop:", error);
        setLoading(false);
      }
    },
    [dispatch, selectedAppointment, setFiles, files]
  );

  const readFileAsArrayBuffer = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onabort = () => reject(new Error("File reading was aborted"));
      reader.onerror = () => reject(new Error("File reading has failed"));
      reader.onload = () => resolve(reader.result);
      reader.readAsArrayBuffer(file);
    });
  };

  const RadFileDataURL = (file: any, callback: (dataUrl: string) => void) => {
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const dataUrl = event.target.result;
      callback(dataUrl);
    };

    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
  });

  function BytesToSize(bytes: number, decimals = 2) {
    if (!Number(bytes)) {
      return "0 Bytes";
    }
    const kbToBytes = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "kb", "mb", "gb", "tb"];
    const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes));
    return `${parseFloat((bytes / Math.pow(kbToBytes, index)).toFixed(dm))} ${
      sizes[index]
    }`;
  }

  const removefile = (data: FilesProps, index: number) => {
    setFiles((prev) => {
      return prev.filter((files) => files !== data);
    });
    setFormData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    const newErrors: string[] = [];

    formData.forEach((data, index) => {
      const nameError =
        data.name.trim() === "" ? "Document name is required" : "";
      const typeError = !data.type ? "Document type is required" : "";
      const dateError = !data.date ? "Document date is required" : "";

      setNameErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = nameError;
        return updatedErrors;
      });

      setTypeErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = typeError;
        return updatedErrors;
      });

      setDateErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = dateError;
        return updatedErrors;
      });

      if (nameError || typeError || dateError) {
        newErrors.push("Validation errors. Please check the form.");
      }
    });

    return newErrors;
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    setFormData((prevData) => {
      if (!prevData[index]) {
        return prevData;
      }

      const updatedData: any = [...prevData];
      updatedData[index][field] = value;
      validateForm();
      setNameErrors([]);
      setTypeErrors([]);
      setDateErrors([]);
      return updatedData;
    });
  };

  const handleDateChange = (date: any, index: number) => {
    setFormData((prevData) => {
      if (!prevData[index]) {
        return prevData;
      }
      const updatedData = [...prevData];
      updatedData[index].date = formatDate(date);
      validateForm();
      return updatedData;
    });
  };

  const handleDateError = (error: string, index: number) => {
    const updatedDateErrors = [...dateErrors];
    updatedDateErrors[index] = error;
    setDateErrors(updatedDateErrors);
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    if (isSaving) {
      return;
    }
    event.preventDefault();
    const newErrors = validateForm();

    if (newErrors.length > 0) {
      console.error(newErrors.join("\n"));
      return;
    }

    const fileList: any = formData.map(
      ({ originalFilename, DocUrl, ...rest }) => rest
    );
    const isValidDate = (dateString: any) => {
      const pattern = /^\d{4}-\d{2}-\d{2}$/;
      if (
        !pattern.test(dateString) ||
        !moment(dateString, "YYYY-MM-DD", true).isValid()
      ) {
        return false;
      }

      const date = moment(dateString, "YYYY-MM-DD");
      const oneYearAgo = moment().subtract(2, "year");
      return date.isAfter(oneYearAgo);
    };

    const formattedArray: any = fileList.map((item: any) => {
      return {
        ...item,
        date: moment(item.date).format("YYYY-MM-DD"),
      };
    });
    const ValidDate = fileList.map((item: any) => {
      return isValidDate(moment(item.date).format("YYYY-MM-DD"));
    });

    if (!ValidDate[0]) {
      return;
    }

    const params = {
      appointment_id: selectedAppointment?.appointment_id,
      doctor_id: selectedAppointment?.doctor_id,
      patient_id: selectedAppointment?.patient?.patient_id,
      file_source: "doctor_web",
      documents: formattedArray,
    };
    try {
      setIsSaving(true);
      await dispatch(savePrescriptionDocuments(params));

      setFormData(initializeFormData(files.length, ""));
      setFiles([]);
      setTimeout(() => {
        getPrescriptionDocuments(formattedSixMonthsAgo, TodaysDate);
      }, 100);

      HandleDialog();

      console.log("Prescription documents saved successfully!");
    } catch (error) {
      console.error("Error saving prescription documents:", error);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column">
        {!loading && formData.length === 0 ? (
          <Box
            {...getRootProps()}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{
              border: "1px dashed #cccccc",
              borderRadius: "4px",
              padding: "24px 7px",
              cursor: "pointer",
              width: isMobileScreen ? "358px" : "550px",
            }}
          >
            <input {...getInputProps()} />
            <Box>
              <Avatar
                alt="Avatar"
                variant="square"
                sx={{
                  background: "none",
                }}
              >
                <UploadFileOutlinedIcon sx={{ fill: "#2196F3" }} />
              </Avatar>
            </Box>
            {isDragActive ? (
              <Typography sx={{}}>
                <Link> Click to upload </Link>
                or drag and drop
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontWeight: "400px",
                  fontSize: "16px",
                  lineHeight: "28px",
                  letterSpacing: "0.15px",
                }}
              >
                {" "}
                <Link> Click to upload </Link> or drag and drop
              </Typography>
            )}
            <Typography
              sx={{
                fontWeight: "400px",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "0.17px",
                color: "rgba(0,0,0,0.6)",
              }}
            >
              PNG, JPG or PDF (max. 3MB)
            </Typography>
          </Box>
        ) : (
          ""
        )}

        {formData.map((data: any, index: number) => {
          return (
            <>
              <Box
                sx={{
                  width: isMobileScreen ? "358px" : "800px",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "40px",
                  borderRadius: "4px",
                  backgroundColor: "rgba(242, 243, 255, 0.39)",
                  border: "1px solid #eee",
                }}
              >
                <Box
                 className="title-class"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems:"center",
                    paddingLeft:isMobileScreen?"16px" : "16px",
                    paddingTop:isMobileScreen ? "16px" : "0px",
                    paddingBottom:isMobileScreen ? "0px" : "10px"
                  }}
                >
                  <Typography
                    title={data.originalFilename}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      whiteSpace: "nowrap",
                      maxWidth: "330px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.originalFilename}
                  </Typography>
                  <Box>
                    <Avatar
                      alt="Avatar"
                      variant="square"
                      sx={{
                        background: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => removefile(data, index)}
                    >
                      <IconButton
                        aria-label="close"
                        sx={{
                          color: (theme: any) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Avatar>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: isMobileScreen ? "column" : "row",
                    padding:isMobileScreen ? "0px" : "0px 0px 21px 24px"
                  }}
                >
                  <Box
                    sx={{
                      width: "150px",
                      height: "128px",
                      borderRadius: "4px",
                      display: isMobileScreen ? "flex" : "block",
                      justifyContent: "center",
                      marginTop: isMobileScreen ? "12px" : 0,
                      padding: isMobileScreen ? "0px 16px" : "0px"
                    }}
                  >
                    {/* application/msword */}
                    {data.file_type === "application/pdf" ? (
                      <Box
                        sx={{
                          borderRadius: "4px",
                          width: "90%",
                          height: "100%",
                        }}
                      >
                        {data.DocUrl ? (
                          // <PdfReport pdfLink={} />
                          <Box
                            sx={{
                              width: isMobileScreen ? "306px" : "135px",
                              height: "128px",
                              border: "none",
                              overflow: "hidden",
                              borderRadius: "4px",
                              
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                top: "-5px",
                                left: "-4px",
                              }}
                            >
                              <iframe
                                className="pdf-iframe"
                                src={data.DocUrl}
                              ></iframe>
                              <Box
                                sx={{
                                  position: "relative",
                                  right: "-4px",
                                  bottom: "166px",
                                  height: "139px",
                                  display: "flex",
                                  alignItems: "flex-end",
                                  background: "rgba(0,0,0,0.4)",
                                  paddingBottom: "5px",
                                  paddingLeft: "5px",
                                }}
                              >
                                <PdfIcon />
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          ""
                        )}
                      </Box>
                    ) : (
                      <img
                        style={{
                          borderRadius: "4px",
                          width: "90%",
                          height: "100%",
                        }}
                        src={data.DocUrl}
                        alt={data.originalFilename}
                      />
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                      padding:isMobileScreen? "0px 16px 16px 16px" : "0px"
                    }}
                  >
                    <Box
                     className="upload-input-section"
                      display="flex"
                      gap={2}
                      fontSize="16px"
                      fontWeight="400"
                      flexDirection={isMobileScreen ? "column" : "row"}
                      marginTop={isMobileScreen ? "12px" : 0}
                    >
                      <TextField
                        autoComplete="off"
                        sx={{
                          minWidth: "260px",
                        }}
                        value={data.name}
                        onChange={(e: any) =>
                          handleInputChange(index, "name", e.target.value)
                        }
                        id={`Document name*${index}`}
                        label={`Document name*`}
                        placeholder={`Document name`}
                        variant="outlined"
                        error={!!nameErrors[index]}
                        helperText={nameErrors[index]}
                      />
                      <FormControl fullWidth>
                        <InputLabel id={`Document Type*${index}`}>
                          Document Type*
                        </InputLabel>
                        <Select
                          label={`Document Type*`}
                          sx={{
                            width: isMobileScreen ? "100%" : "260px",
                          }}
                          labelId={`Document Type*${index}`}
                          id={`Document Type*${index}`}
                          value={data.type}
                          placeholder={`Document Type*`}
                          MenuProps={{
                            sx: {
                              "&.Mui-selected": {
                                background: "#F2F3FF",
                              },
                            },
                          }}
                          onChange={(e) =>
                            handleInputChange(index, "type", e.target.value)
                          }
                          error={!!typeErrors[index]}
                        >
                          {DocumentTypes?.map((data: DocumentTypes) => {
                            return (
                              <MenuItem value={data.value} key={data.value}>
                                {data.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {typeErrors[index] && (
                          <FormHelperText error>
                            {typeErrors[index]}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        sx={{
                          minWidth: "260px",
                          paddingTop: "16px",
                        }}
                        components={["DatePicker"]}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          maxDate={dayjs().add(0, "day")}
                          label={`Document Date*`}
                          value={dayjs(data.date)}
                          onChange={(date: any) =>
                            handleDateChange(date, index)
                          }
                          defaultValue={dayjs(new Date())}
                          onError={(error: any) =>
                            handleDateError(error, index)
                          }
                        />
                        {dateErrors[index] && (
                          <FormHelperText error>
                            {dateErrors[index] === "invalidDate" ? "Invalid Date" : dateErrors[index]}
                          </FormHelperText>
                        )}
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
            </>
          );
        })}
        {loading && Object?.keys(LoadedData).length ? (
          <>
            {
              <Box
                sx={{
                  width: isMobileScreen ? "358px" : "800px",
                  pointerEvents: "none",
                  height: "100px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0",
                  backgroundColor: "rgba(242, 243, 255, 0.39)",
                  border: "1px solid #eee",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    height: "82px",
                    alignItems: "center",
                    padding: "6px",
                  }}
                  gap={2}
                >
                  <Box
                    sx={{
                      width: "112px",
                      height: "86px",
                      borderRadius: "4px",
                    }}
                  >
                    {/* application/msword */}
                    {LoadedData?.type === "application/pdf" ? (
                      <Box
                        sx={{
                          borderRadius: "4px",
                          width: "90%",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            border: "none",
                            overflow: "hidden",
                            borderRadius: "4px",
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              top: "-5px",
                              left: "-4px",
                            }}
                          >
                            <iframe
                              className="pdf-iframe"
                              style={{ borderWidth: "0px" }}
                              src={LoadedData.DocUrl}
                            ></iframe>
                            <Box
                              sx={{
                                position: "relative",
                                right: "-4px",
                                bottom: "166px",
                                height: "95px",
                                display: "flex",
                                alignItems: "flex-end",
                                background: "rgba(0,0,0,0.4)",
                                paddingBottom: "5px",
                                paddingLeft: "5px",
                                borderRadius: "4px",
                              }}
                            >
                              <PdfIcon />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <img
                        style={{
                          borderRadius: "4px",
                          width: "90%",
                          height: "100%",
                        }}
                        src={LoadedData?.Docurl}
                        alt={LoadedData?.name}
                      />
                    )}
                  </Box>
                  <Box>
                    <Typography
                      title={LoadedData?.name}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        whiteSpace: "nowrap",
                        maxWidth: isMobileScreen ? "180px" : "330px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {LoadedData?.name}
                    </Typography>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "rgba(0, 0, 0, 0.6)",
                        }}
                      >
                        {BytesToSize(LoadedData?.size)} {". "} {"Loading"}
                      </Typography>
                    </Box>{" "}
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    aria-label="close"
                    sx={{
                      color: (theme: any) => theme.palette.grey[500],
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            }
          </>
        ) : (
          ""
        )}
      </Box>

      {largeSizeData.length ? (
        <>
          {
            <Box
              sx={{
                width: isMobileScreen ? "358px" : "800px",
                height: "100px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 0",
                backgroundColor: "rgba(242, 243, 255, 0.39)",
                border: "1px solid #eee",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  height: "82px",
                  alignItems: "center",
                  padding: "6px",
                }}
                gap={2}
              >
                <Box
                  sx={{
                    width: "112px",
                    height: "86px",
                    borderRadius: "4px",
                  }}
                >
                  {/* application/msword */}
                  {largeSizeData[0]?.type === "application/pdf" ? (
                    <Box
                      sx={{
                        borderRadius: "4px",
                        width: "90%",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          border: "none",
                          overflow: "hidden",
                          borderRadius: "4px",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            top: "-5px",
                            left: "-4px",
                          }}
                        >
                          <iframe
                            className="pdf-iframe"
                            style={{ borderWidth: "0px" }}
                            src={largeSizeData[0].DocUrl}
                          ></iframe>
                          <Box
                            sx={{
                              position: "relative",
                              right: "-4px",
                              bottom: "166px",
                              height: "95px",
                              display: "flex",
                              alignItems: "flex-end",
                              background: "rgba(0,0,0,0.4)",
                              paddingBottom: "5px",
                              paddingLeft: "5px",
                              borderRadius: "4px",
                            }}
                          >
                            <PdfIcon />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <img
                      style={{
                        borderRadius: "4px",
                        width: "90%",
                        height: "100%",
                      }}
                      src={largeSizeData[0]?.DocUrl}
                      alt={largeSizeData[0]?.name}
                    />
                  )}
                </Box>
                <Box>
                  <Typography
                    title={largeSizeData[0]?.name}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "28px",
                      whiteSpace: "nowrap",
                      maxWidth: "330px",
                      color: "#D32F2F",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Upload failed.
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#D32F2F",
                      }}
                    >
                      File too large . Failed
                    </Typography>
                  </Box>{" "}
                  <LinearProgress
                    color="error"
                    variant="determinate"
                    value={progress}
                  />
                </Box>
              </Box>
              <Box
                onClick={() => {
                  setLoading(false);
                  setLargeSizeData([]);
                }}
              >
                <IconButton
                  aria-label="close"
                  sx={{
                    color: (theme: any) => theme.palette.grey[500],
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          }
        </>
      ) : (
        ""
      )}

      <Box>
        {formData.length > 0 && largeSizeData.length === 0 ? (
          <Box
            display="flex"
            justifyContent="flex-end"
            mt={2}
            sx={{ marginBottom: "-33px" }}
            gap={2}
          >
            <Button
              disabled={formData.length > 2}
              {...getRootProps()}
              variant="outlined"
              sx={{
                textTransform: "none !important",
              }}
            >
              <input {...getInputProps()} />
              Upload another document
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none !important",
              }}
              onClick={(evt) => handleSubmit(evt)}
            >
              {isSaving ? "Saving..." : "Save"}
            </Button>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default FileUploader;
