import { Box, Skeleton } from "@mui/material";

export const LeftPanelSkeletonLoader = () => {
  return (
    <>
      <Skeleton variant="rounded" height="60px" sx={{ marginTop: "20px" }} />
      <HeaderSkeletonLoader />
      <HeaderSkeletonLoader />
      <Box marginTop="20px">
        <MedicationsSkeletonLoader />
      </Box>
      <Box marginTop="20px">
        <LabInvestigationSkeletonLoader />
      </Box>
    </>
  );
};
export default LeftPanelSkeletonLoader;

const HeaderSkeletonLoader = () => {
  return (
    <Box marginTop="20px">
      <Skeleton variant="rounded" height="20px" />
      <Skeleton width="50%" height="10px" />
      <Skeleton width="50%" height="10px" />
    </Box>
  );
};

const LabInvestigationSkeletonLoader = () => {
  return (
    <>
      <Skeleton variant="rounded" height="20px" />
      <Box display="flex" width="100%" gap="20px" marginTop="10px">
        <Skeleton variant="rounded" width="20px" height="15px" />
        <Skeleton variant="rounded" width="80%" height="15px" />
      </Box>
      <Box display="flex" width="100%" gap="20px" marginTop="10px">
        <Skeleton variant="rounded" width="20px" height="15px" />
        <Skeleton variant="rounded" width="80%" height="15px" />
      </Box>
    </>
  );
};

const MedicationsSkeletonLoader = () => {
  return (
    <>
      <Skeleton variant="rounded" height="20px" />
      <Box
        display="flex"
        width="100%"
        justifyContent="space-around"
        gap="20px"
        alignItems="center"
      >
        <Skeleton width="60px" height="30px" />
        <Box width="100%">
          <Skeleton width="60px" height="12px" />
          <Skeleton width="60px" height="12px" />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          sx={{ paddingLeft: "136px" }}
        >
          <Skeleton width="60px" height="12px" />
          <Skeleton width="60px" height="12px" />
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-around"
        gap="20px"
        alignItems="center"
      >
        <Skeleton width="60px" height="30px" />
        <Box width="100%">
          <Skeleton width="60px" height="12px" />
          <Skeleton width="60px" height="12px" />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          sx={{ paddingLeft: "136px" }}
        >
          <Skeleton width="60px" height="12px" />
          <Skeleton width="60px" height="12px" />
        </Box>
      </Box>
    </>
  );
};
