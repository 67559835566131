import AdditionalNotes from "../pages/components/prescription/tabcomponents/RX/AdditionalNotes";
import Advice from "../pages/components/prescription/tabcomponents/RX/Advice";
import ChiefComplaints from "../pages/components/prescription/tabcomponents/RX/ChiefComplaints";
import Diagnosis from "../pages/components/prescription/tabcomponents/RX/Diagnosis";
import ExaminationFindings from "../pages/components/prescription/tabcomponents/RX/ExaminationFindings";
import FollowUp from "../pages/components/prescription/tabcomponents/RX/FollowUp";
import LabInvestigation from "../pages/components/prescription/tabcomponents/RX/LabInvestigation";
import Medication from "../pages/components/prescription/tabcomponents/RX/Medication";
import Referral from "../pages/components/prescription/tabcomponents/RX/Referral";
import Vitals from "../pages/components/prescription/tabcomponents/RX/Vitals";
import PatientHistory from "../pages/components/prescription/tabcomponents/RX/patienthistory/PatientHistory";

export type RootState = {
  auth: any;
  appointments: any
};
// export const BASE_API_URL = "https://o1apqc0fj1.execute-api.ap-south-1.amazonaws.com/dev/";
// export const BASE_API_URL = "https://50nfmmhuab.execute-api.ap-south-1.amazonaws.com/dev/";
export const BASE_API_URL = "https://api.drclinic.ai/";
export const TOKEN_SECRET = "test";
// export const BASE_API_URL = "https://betaemrapi.hlthclub.in/";
export const VITALS = "VITALS";
export const COMPLAINTS = "COMPLAINTS";
export const PATIENT_HISTORY = "PATIENT_HISTORY";
export const OBG_NOTES = "OBG_NOTES";
export const OBG_HISTORY = "OBG_HISTORY";

export const KNEE_EXAMINATION = "KNEE_EXAMINATION";
export const PHYSICAL_FINDING = "PHYSICAL_FINDING";
export const PRESENTING_COMPLAINTS = "PRESENTING_COMPLAINTS";

export const MEDICAL_HISTORY = "MEDICAL_HISTORY";
export const FAMILY_HISTORY = "FAMILY_HISTORY";
export const SURGICAL_HISTORY = "SURGICAL_HISTORY";
export const SOCIAL_HISTORY = "SOCIAL_HISTORY";

export const MENSTRUAL_HISTORY = "MENSTRUAL_HISTORY";
export const MARITAL_HISTORY = "MARITAL_HISTORY";
export const PREGNANCY_HISTORY = "PREGNANCY_HISTORY";

export const PHYSICAL_EXAMINATION = "PHYSICAL_EXAMINATION";
export const TRIMESTER_NOTES = "TRIMESTER_NOTES";
export const PER_ABDOMEN = "PER_ABDOMEN";
export const BREAST = "BREAST";
export const PER_VAGINUM = "PER_VAGINUM";
export const PER_SPECULUM = "PER_SPECULUM";
export const OTHER_FINDINGS = "Other Findings";

export const MEAL_PREFERENCE = "MEAL_PREFERENCE";
export const PHYSICAL_ACTIVITY = "PHYSICAL_ACTIVITY";
export const MEDICAL_HISTORY_DIABETES = "DIABETES_MEDICATION_HISTORY";
export const MEDICATION_HISTORY = "MEDICATION_HISTORY";

export const ALLERGIES = "ALLERGIES";
export const DIAGNOSIS = "DIAGNOSIS";
export const EXAMINATION = "EXAMINATION"
export const MEDICATION = "MEDICATION";
export const INVESTIGATIONS = "INVESTIGATIONS";
export const REFERRAL = "REFERRAL";

export const DIABETES_ASSESSMENT = "DIABETES_ASSESSMENT";

export const GENERAL_PHYSICAL_EXAMINATION = "GENERAL_PHYSICAL_EXAMINATION";
export const SYSTEMIC_EXAMINATION = "SYSTEMIC_EXAMINATION";
export const FOOT_EXAM = "FOOT_EXAM";
export const INVESTIGATION_MONITORING = "INVESTIGATION_MONITORING";

export const OBSTETRIC_HISTORY = "OBSTETRIC_HISTORY";
export const EXAMINATIONS = "EXAMINATIONS";


export const ADVICE = "ADVICE";
export const FOLLOW_UP = "FOLLOW_UP";
export const ADVICE_NOTES = "ADVICE_NOTES";
export const MAX_COGNITO_REFRESH_TIMES = 24;
export const SVAAS_DOCUMENTS = "SvaasDocument"
export const INVOICE_DOCUMENTS = "InvoiceDocument"
export const PATIENT_TIMELINE = "Timeline"
export const PATIENT_DOCUMENTS= "PATIENT_DOCUMENTS"
export const AWS_COGNITO_CONFIG = {
  'aws_app_analytics': 'enable',
  'aws_cognito_identity_pool_id': 'ap-south-1:d67a44b0-f143-4b62-8ead-7082502ed4c9',
  'aws_cognito_region': 'ap-south-1',
  'aws_mandatory_sign_in': 'disable',
  // 'aws_mobile_analytics_app_id': '5ac33a4afc7b4c5080bdd174139afd69',
  // 'aws_mobile_analytics_app_region': 'us-east-1',
  //'aws_project_id': '79c948db-d1ec-4284-9373-589f57221b78',
  //'aws_project_name': 'thb-emr-live',
  'aws_project_region': 'ap-south-1',
  'aws_resource_name_prefix': 'emr_pool',
  'aws_sign_in_enabled': 'disable',
  'aws_user_files': 'enable',
  'aws_user_files_s3_bucket': 'emr-userfiles',
  'aws_user_files_s3_bucket_region': 'ap-south-1',
  'aws_user_pools': 'enable',
  'aws_user_pools_id': 'ap-south-1_UOP26uPYa',
  'aws_user_pools_mfa_type': 'OPTIONAL',
  'aws_user_pools_web_client_id': '6q4haiq0eqbod6ebs9ea53fvab'
}

type RXTabComponentType = {
  [key: string]: React.ComponentType<JSX.Element>;
}
export const RxSectionComponentConstants: RXTabComponentType = {
  VITALS: Vitals,
  COMPLAINTS: ChiefComplaints,
  PATIENT_HISTORY: PatientHistory,
  DIAGNOSIS: Diagnosis,
  EXAMINATION: ExaminationFindings,
  MEDICATION: Medication,
  INVESTIGATIONS: LabInvestigation,
  REFERRAL: Referral,
  ADVICE: Advice,
  FOLLOW_UP: FollowUp,
  ADVICE_NOTES: AdditionalNotes,
};