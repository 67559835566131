
import { Button, Tooltip, useTheme } from "@mui/material";


const useGetAppoitmentActions = ({
  actions,
  subject,
  key,
  setAppoitmentStatus,
  onClick,
}) => {
  const theme = useTheme();

  const getBorderColor = (type)=>{
    switch(type){
      case 'resume': 
        return theme.palette.green.main;
      case 'start':
        return theme.palette.primary.main;
      case 'viewrx':
        return theme.palette.negative.main;
      default:
        return theme.palette.primary.main;
    }
  }
  const getButtonColor = (type)=>{
    switch(type){
      case 'resume': 
        return "green";
      case 'start':
        return 'primary';
      case 'viewrx':
        return 'negative';
      default:
        return 'primary';
    }
  }

  const getTooltipText = (key)=>{
    switch(key){
      case "resume":
        return "Resume Consultation"
      case "start":
        return "Start Consultation"
      case "viewrx":
        return ""
      default:
        return ""
    }
  }

  // const [loading,setLoading] = useState(false);

  return (
    <Tooltip title={
      getTooltipText(actions?.value)
    }>
      <Button
        variant="outlined"
        key={subject}
      
        sx={{
          border: `1px solid ${getBorderColor(actions?.value)}`,
          minWidth:"100px"
        }}
        color={getButtonColor(actions?.value)}
        onClick={() => {setAppoitmentStatus(actions?.value, key)}}
        disabled={!actions?.active}
      >
        {actions?.label}
      </Button>
    </Tooltip>
  )

  // if (key === "Booked") {
  //   return (
  //     <LoadingButton
  //       variant="outlined"
  //       key={subject}
  //       loading={loader}
  //       sx={{
  //         border: `1px solid  ${theme.palette.primary.main}`,
  //       }}
  //       onClick={() => setAppoitmentStatus("Consulting", key)}
  //     >
  //       {"Start "}
  //     </LoadingButton>
  //   );
  // }
  // if (key === "Checked-In") {
  //   return (
  //     <LoadingButton
  //       loading={loader}
  //       key={subject}
  //       variant="outlined"
  //       sx={{
  //         border: `1px solid  ${theme.palette.primary.main}`,
  //       }}
  //       onClick={() => setAppoitmentStatus("Checked-In", key)}
  //     >
  //       {"Start"}
  //     </LoadingButton>
  //   );
  // }

  // if (key === "Consulting") {
  //   return (
  //     <LoadingButton
  //       loading={loader}
  //       key={subject}
  //       variant="outlined"
  //       sx={{
  //         border: `1px solid  ${theme.palette.success.main}`,
  //       }}
  //       onClick={() => setAppoitmentStatus("Resume", key)}
  //     >
  //       {"Resume"}
  //     </LoadingButton>
  //   );
  // }

  // if (key === "Done") {
  //   return (
  //     <LoadingButton
  //       loading={loader}
  //       key={subject}
  //       variant="outlined"
  //       sx={{
  //         border: `1px solid  ${theme.palette.warning.main}`,
  //       }}
  //       // onClick={() => setAppoitmentStatus("done", key)}
  //     >
  //       {"View Rx"}
  //     </LoadingButton>
  //   );
  // }

  // if (key === "Cancelled") {
  //   return (
  //     <LoadingButton
  //       loading={loader}
  //       key={subject}
  //       variant="outlined"
  //       sx={{
  //         border: `1px solid  ${theme.palette.primary.main}`,
  //       }}
  //       disabled
  //       onClick={() => setAppoitmentStatus("Consulting", key)}
  //     >
  //       {"Start"}
  //     </LoadingButton>
  //   );
  // }

  // if (key === "Rescheduled") {
  //   return (
  //     <LoadingButton
  //       loading={loader}
  //       key={subject}
  //       variant="outlined"
  //       sx={{
  //         border: `1px solid  ${theme.palette.primary.main}`,
  //       }}
  //       disabled
  //       onClick={() => setAppoitmentStatus("Consulting", key)}
  //     >
  //       {"Start"}
  //     </LoadingButton>
  //   );
  // }
};

export default useGetAppoitmentActions;
