import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC, ROUTE_APPOINTMENTS } from "../../../shared/routes/route.constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius:"14px",
  p: 2,
  
};
const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius:"14px",
  p: 2,
};

const ModalComponent = ({
  open,
  setOpen,
  isSuccess,
  headerModal=false,
  headerText,
  subText,
  actionButtonText,
  actionButtonOnClick,
  isForCreateClinic=false

}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        if(isSuccess){
          if(headerModal){
            return;
          }
          if(isForCreateClinic){
            navigate(0);
            return;
          }
          else{
            navigate(ROUTE_APPOINTMENTS)
          }
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={isMobileScreen ? styleMobile : style}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
      >
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Box>
            <IconButton
              onClick={() => {
                setOpen(false);
                if(isSuccess){
                  if(headerModal){
                    return;
                  }
                  if(isForCreateClinic){
                    navigate(ROUTE_ADMIN_CLINIC_MANAGEMENT_ALL_CLINIC);
                    return;
                  }
                  else{
                    navigate(ROUTE_APPOINTMENTS)
                  }
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
        >
          <Box
            width={{ md: 80 }}
            component="img"
            alt="add patient"
            src={
              !isSuccess
                ? "/images/appointmentFailed.svg"
                : "/images/appointmentConfirmed.svg"
            }
          />
        </Box>

        <Box
          maxHeight={300}
          overflow={"auto"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          my={2}
        >
          <Typography variant="h6" fontWeight={600} textAlign={"center"}>
            {headerText}
          </Typography>
          <Typography variant="subtitle1" textAlign={"center"}>
            {subText}
          </Typography>
          {!isSuccess && (
            <Button variant="outlined" onClick={actionButtonOnClick}>
              {actionButtonText}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
