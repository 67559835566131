/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HTMLAttributes, SyntheticEvent, useEffect, useRef, useState } from "react";
import CardHeader from "../../utils/CardHeader";
import Card from "../../utils/Card";
import { styled } from "@mui/system";
import { CARD_SHADOW } from "../../utils/Constants";
import { DIABETES_ASSESSMENT, FOOT_EXAM, GENERAL_PHYSICAL_EXAMINATION, REFERRAL, SYSTEMIC_EXAMINATION } from "../../../../../shared/constants";
import { setEditRxSecMobile } from "../../../../../redux/reducers/prescription.slice";
import CustomizedDialogs from "../../../../../shared/Dialog/Dialog";
import DoctorSuggestions from "../../utils/DoctorSuggestions";
import { fetchSuggestions, seperateUrlAndParams } from "../../../../../shared/Utils/utils";
import { useDebounce } from "../../../../../shared/Utils/useDebounce";

interface SymptomProps {
  group: number;
  group_title: string;
  key: string;
  label: string;
  options: any;
  type: string;
  value: string;
}

const EditorModules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    ["bold", "italic", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};


const SystemicExamination = (props: any) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [options, setOptions] = useState<string[]>([]);
  const [optionsData, setOptionsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SymptomProps[]>([]);
  const [dialog, setDialog] = useState<any>({
    open: false,
    content: {} as SymptomProps,
  });

  const [searchValue, setSearchValue] = useState("");

  const sectionData = props?.sectionData?.[0]?.rows;
  const setTabData = props?.setTabData;
  const isFocused: boolean = props.isFocused || false;
  const symptomArr = sectionData?.master?.symptom?.values || [];
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const autoref = useRef<HTMLDivElement>(null);

  const apiurldata = sectionData?.search?.api;

  const [valUpdateSearch, setValUpdateSearch] = useState("");
  const debouncedSearch = useDebounce(searchValue);
  const altDebouncedSearch = useDebounce(valUpdateSearch);

  const [comments,setComments] = useState<any>({});

  

  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(SYSTEMIC_EXAMINATION));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (sectionData) {
      setData(sectionData);
      const commentsObj:any = {}
      sectionData?.filter((elem:any)=>elem.type==='text')?.forEach((comm:any)=>{
        commentsObj[comm?.key] = comm.value;
      })
      setComments(commentsObj)
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === SYSTEMIC_EXAMINATION
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  useEffect(() => {
    if (searchValue.length === 0) {
      setOptions([]);
    }
  }, [searchValue]);


  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === SYSTEMIC_EXAMINATION) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const updateCondition = (event: SyntheticEvent, value: string | null) => {
    let newValue: any, newId: any;

    const target =
      (event.target as HTMLInputElement) ||
      (event.target as HTMLElement);

    const ariaLabel = target.ariaLabel as string;
    const inputName = target.name as string;

    const nameArr = (ariaLabel || inputName)?.split("-");
    
    const fieldName = nameArr[0];
    
    setTabData((prev: any) => {
      return prev.map((section: any) => {
        if (section.view_type === SYSTEMIC_EXAMINATION) {
          return {
            ...section,
            rows: section.rows.map((row: any, index: number) => {
              if (row.key === fieldName) {
                return {
                  ...row,
                  value: data.find(elem=>elem.key===fieldName)?.value
                };
              }
              return row;
            }),
          };
        }
        return section;
      });
    });

    setOptions([]);
  };

  const RenderReadOnly = () => {
    return (
      <Box component="ul" sx={{ paddingInlineStart: "16px", margin: 0 }}>
        
        {data.map((datum:any, index) => {
          return (
            <Box
              component="li"
              sx={{
                color: "#6F6F6F",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                marginBottom: "12px",
              }}
              key={index}
            >
              {`${datum?.label || ''} ${datum?.label ? `(${datum?.value})` : datum?.value}`}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
          }}
          title="Systemic Examination"
          isReadOnly={props?.isReadOnly}
        >
          <CardHeader headerImg="icon_systemic_examination.svg" headerTitle="Systemic Examination">
            <></>
          </CardHeader>
          <Box>
            <Box display={'flex'} flexDirection={'column'} gap={8} justifyContent={'space-between'}>
              <Box display={'flex'} flexDirection={'column'}>
                {data.length !== 0 && data?.filter(elem=>elem.type==='text')?.map(elem=>{
                  return (
                    <Box display={'flex'} gap={8} mb={3} alignItems={'center'}>
                      <Typography sx={{width:'80px'}}>{elem.label}</Typography>
                      <TextField
                        placeholder={'Enter Comments'}
                        value={comments[elem.key] || ''}
                        onChange={(e)=>{
                          setComments({
                            ...comments,
                            [elem.key]:e.target.value
                          })
                        }}
                        sx={{flex:1}}
                        onBlur={()=>{
                          setTabData((prev: any) => {
                            return prev.map((section: any) => {
                              if (section.view_type === SYSTEMIC_EXAMINATION) {
                                return {
                                  ...section,
                                  rows: section.rows.map((row: any, index: number) => {
                                    
                                    if (row.key == elem.key) {
                                      return {
                                        ...row,
                                        value: comments[elem.key] || ''
                                      };
                                    }
                                    return row;
                                  }),
                                };
                              }
                              return section;
                            });
                          });
                        }}
                      />
                    </Box>
                  )
                })}
              </Box>
            </Box>


            <Box display={'flex'} gap={4}>
              <Box display={'flex'} flexDirection={'column'} pt={2}>
                <Typography fontWeight={'bold'} mb={3}>{data?.find(elem=>elem.group===1)?.group_title}</Typography>
                {data.length !== 0 && data?.filter(elem=>elem.group===1 && elem.type==='radio')?.map(elem=>{
                  return (
                    <Box display={'flex'} gap={8} mb={3} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography>{elem.label}</Typography>
                      <FormControl>
                          <RadioGroup
                            row
                            id={`${elem.key}-state`}
                            name={`${elem.key}-state`}
                            value={elem.value || ''}
                          >
                            {elem.options.map((opt:any)=>{
                              return (
                                <FormControlLabel 
                                  id={`${elem.key}-state`}
                                  name={`${elem.key}-state`}
                                  value={opt}
                                  control={<Radio
                                    
                                    inputProps={
                                      {
                                        onBlur:(event: any) => {
                                          updateCondition(event as any, event.target.value);
                                        },
                                        name:`${elem.key}-state`,
                                        id:`${elem.key}-state`,
                                        onClick:(e:any)=>{
                                          const checked = e.target.checked;
                                          if(checked){
                                            setData((prev: any) => {
                                              return prev.map((elem: any) => {
                                                if (elem.key.toString() === e.target.id?.split('-')[0]) {
                                                  return {
                                                    ...elem,
                                                    value: opt,
                                                  };
                                                }
                                                return elem;
                                              });
                                            });
                                          }
                                        }
                                      }
                                    }
                                    
                                  onBlur={(event: any) => {
                                    updateCondition(event as any, event.target.value);
                                  }}
                                />} label={opt} />
                              )
                            })}
                            
                          </RadioGroup>
                      </FormControl>
                    </Box>
                  )
                })}
              </Box>

              <Box sx={{height:'300px',width:'2px',pt:2}}>
                <Divider light={true} orientation="vertical"/>
              </Box>

              <Box display={'flex'} flexDirection={'column'} pt={2}>
                <Typography fontWeight={'bold'} mb={3}>{data?.find(elem=>elem.group===2)?.group_title}</Typography>
                {data.length !== 0 && data?.filter(elem=>elem.group==2 && elem.type==='radio')?.map(elem=>{
                  return (
                    <Box display={'flex'} gap={8} mb={3} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography>{elem.label}</Typography>
                      <FormControl>
                          <RadioGroup
                            row
                            id={`${elem.key}-state`}
                            name={`${elem.key}-state`}
                            value={elem?.value || ""}
                          >
                            {elem.options.map((opt:any)=>{
                              return (
                                <FormControlLabel 
                                  id={`${elem.key}-state`}
                                  name={`${elem.key}-state`}
                                  value={opt}
                                  control={<Radio
                                    
                                    inputProps={
                                      {
                                        onBlur:(event: any) => {
                                          updateCondition(event as any, event.target.value);
                                        },
                                        name:`${elem.key}-state`,
                                        id:`${elem.key}-state`,
                                        onClick:(e:any)=>{
                                          const checked = e.target.checked;
                                          if(checked){
                                            setData((prev: any) => {
                                              return prev.map((elem: any) => {
                                                if (elem.key.toString() === e.target.id?.split('-')[0]) {
                                                  return {
                                                    ...elem,
                                                    value: opt,
                                                  };
                                                }
                                                return elem;
                                              });
                                            });
                                          }
                                        }
                                      }
                                    }
                                    
                                  onBlur={(event: any) => {
                                    updateCondition(event as any, event.target.value);
                                  }}
                                />} label={opt} />
                              )
                            })}
                            
                          </RadioGroup>
                      </FormControl>
                    </Box>
                  )
                })}
              </Box>
            </Box>

          </Box>

          
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box display={'flex'} flexDirection={'column'}>
            {data.length !== 0 && data?.filter(elem=>elem.type==='text')?.map(elem=>{
              return (
                <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={1} mb={2} alignItems={'start'}>
                  <Typography sx={{width:'80px'}}>{elem.label}</Typography>
                  <TextField
                    placeholder={'Comments'}
                    value={comments[elem.key] || ''}
                    onChange={(e)=>{
                      setComments({
                        ...comments,
                        [elem.key]:e.target.value
                      })
                    }}
                    sx={{width:'100%'}}
                    onBlur={()=>{
                      setTabData((prev: any) => {
                        return prev.map((section: any) => {
                          if (section.view_type === SYSTEMIC_EXAMINATION) {
                            return {
                              ...section,
                              rows: section.rows.map((row: any, index: number) => {
                                
                                if (row.key == elem.key) {
                                  return {
                                    ...row,
                                    value: comments[elem.key] || ''
                                  };
                                }
                                return row;
                              }),
                            };
                          }
                          return section;
                        });
                      });
                    }}
                  />
                </Box>
              )
            })}
          </Box>

          <Divider/>

          <Box display={'flex'} flexDirection={'column'} pt={2}>
            <Typography fontWeight={'bold'} color={theme.palette.primary.main} mb={3}>{data?.find(elem=>elem.group===1)?.group_title}</Typography>
            {data.length !== 0 && data?.filter(elem=>elem.group===1 && elem.type==='radio')?.map(elem=>{
              return (
                <Box display={'flex'} gap={2} mb={3} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography>{elem.label}</Typography>
                  <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
                    <FormControl>
                        <RadioGroup
                          row
                          id={`${elem.key}-state`}
                          name={`${elem.key}-state`}
                          value={elem.value || ''}
                        >
                          {elem.options.map((opt:any)=>{
                            return (
                              <FormControlLabel 
                                id={`${elem.key}-state`}
                                name={`${elem.key}-state`}
                                value={opt}
                                control={<Radio
                                  
                                  inputProps={
                                    {
                                      onBlur:(event: any) => {
                                        updateCondition(event as any, event.target.value);
                                      },
                                      name:`${elem.key}-state`,
                                      id:`${elem.key}-state`,
                                      onClick:(e:any)=>{
                                        const checked = e.target.checked;
                                        if(checked){
                                          setData((prev: any) => {
                                            return prev.map((elem: any) => {
                                              if (elem.key.toString() === e.target.name?.split('-')[0]) {
                                                return {
                                                  ...elem,
                                                  value: opt,
                                                };
                                              }
                                              return elem;
                                            });
                                          });
                                        }
                                      }
                                    }
                                  }
                                  
                                onBlur={(event: any) => {
                                  updateCondition(event as any, event.target.value);
                                }}
                              />} label={opt} />
                            )
                          })}
                          
                        </RadioGroup>
                    </FormControl>
                  </Box>
                  
                </Box>
              )
            })}
          </Box>

          <Divider/>

          <Box display={'flex'} flexDirection={'column'} pt={2}>
            <Typography fontWeight={'bold'} color={theme.palette.primary.main} mb={3}>{data?.find(elem=>elem.group===2)?.group_title}</Typography>
            {data.length !== 0 && data?.filter(elem=>elem.group==2 && elem.type==='radio')?.map(elem=>{
              return (
                <Box display={'flex'} gap={2} mb={3} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography>{elem.label}</Typography>
                  <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
                    <FormControl>
                        <RadioGroup
                          row
                          id={`${elem.key}-state`}
                          name={`${elem.key}-state`}
                          value={elem?.value || ""}
                        >
                          {elem.options.map((opt:any)=>{
                            return (
                              <FormControlLabel 
                                id={`${elem.key}-state`}
                                name={`${elem.key}-state`}
                                value={opt}
                                control={<Radio
                                  
                                  inputProps={
                                    {
                                      onBlur:(event: any) => {
                                        updateCondition(event as any, event.target.value);
                                      },
                                      name:`${elem.key}-state`,
                                      id:`${elem.key}-state`,
                                      onClick:(e:any)=>{
                                        const checked = e.target.checked;
                                        if(checked){
                                          setData((prev: any) => {
                                            return prev.map((elem: any) => {
                                              if (elem.key.toString() === e.target.name?.split('-')[0]) {
                                                return {
                                                  ...elem,
                                                  value: opt,
                                                };
                                              }
                                              return elem;
                                            });
                                          });
                                        }
                                      }
                                    }
                                  }
                                  
                                onBlur={(event: any) => {
                                  updateCondition(event as any, event.target.value);
                                }}
                              />} label={opt} />
                            )
                          })}
                          
                        </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
    </>
  )
}

export default SystemicExamination;