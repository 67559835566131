import { Box } from "@mui/system";
const PlusIcon = ({ onClick }: { onClick?: (event: any) => void }) => {
  return (
    <Box onClick={onClick} sx={{ cursor: "pointer" }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon_Plus_24px">
          <path
            id="Vector 450"
            d="M12 2V12M12 22V12M12 12H22H2"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </Box>
  );
};
export default PlusIcon;
