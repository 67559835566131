import { Box, Tooltip } from "@mui/material";
import { ReactNode } from "react";

interface CardHeaderProps {
  children: ReactNode;
  headerImg: string;
  headerTitle: string;
  styles?: object; // inline style overrides
}

export default function CardHeader(props: CardHeaderProps) {
  const { children, headerImg, headerTitle, styles } = props;
  return (
    <Box
      style={{ ...styles }}
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        mb: 2,
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {headerImg && (
          <img
            src={`/images/${headerImg}`}
            alt={headerImg.substring(0, headerImg.lastIndexOf("."))}
          />
        )}
        <span style={{ marginLeft: headerImg?"12px":'0px', fontWeight: "600", color: "#000" }}>
          {headerTitle}
        </span>
      </Box>
      {children}
    </Box>
  );
}

export function renderHeaderButtons(
  imgs: string[],
  handleButtonClick?: (imageName: string, viewType?: string) => void
) {
  const handleClickImage = (imgName: string) => {
    if (handleButtonClick) {
      handleButtonClick(imgName);
    }
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {imgs.map((imgName, index) => {
        return (
          <button
            key={index}
            style={{
              outline: "none",
              background: "none",
              padding: 0,
              marginRight: index !== imgs.length - 1 ? 16 : 0,
              border: 0,
            }}
          >
            <Tooltip
              title={
                (imgName === "icon_save.svg" && "Save template") ||
                (imgName === "icon_add_page.svg" &&
                  "Add from existing template")
              }
            >
              <img
                onClick={() => handleClickImage(imgName)}
                src={`/images/${imgName}`}
                alt={imgName.substring(0, imgName.lastIndexOf("."))}
              />
            </Tooltip>
          </button>
        );
      })}
    </Box>
  );
}
