import { Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { ADMIN_ABDM_HEALTH_FACILITY_REGISTRY, ADMIN_ACCOUNT_FOR, ADMIN_ADDRESS, ADMIN_ADD_CLINIC_IMAGES, ADMIN_ADD_LOGO, ADMIN_ADD_NEW_CLINICS, ADMIN_ADD_TO_INDIAS_DIGITAL_ECOSYSTEM, ADMIN_CANCEL, ADMIN_CLINIC_CONTACT_NUMBER, ADMIN_CLINIC_DETAILS, ADMIN_CLINIC_NAME, ADMIN_CLINIC_SPECIALIZATION, ADMIN_CREATE_CLINIC, ADMIN_HEALTHCARE_FACILITY_REGISTRY, ADMIN_HEALTH_FACILITY_REGISTRY, ADMIN_HOW_TO_CREATE, ADMIN_LINK_GOOGLE_BUSINESS, ADMIN_LINK_NOW, ADMIN_MAX_USER_LIMIT, ADMIN_PERSONAL_WEBSITE_URL, ADMIN_REGISTER_NOW, ADMIN_SERVICE, ADMIN_SUBMIT, ADMIN_UPLOAD_IMAGE, ADMIN_VIEW_ALL_CLINIC, ADMIN_WATCH_VIDEO } from '../../../../assets/translations/admin';
import { LoadingButton } from '@mui/lab';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { APIClient } from '../../../../shared/Utils/api-client';
import { API_ROUTE_CREATE_CLINIC, API_ROUTE_EDIT_CLINIC, API_ROUTE_FETCH_CITY, API_ROUTE_FETCH_COUNTRIES, API_ROUTE_FETCH_STATE, API_ROUTE_GET_SIGNED_URL } from '../../../../shared/routes/route.constants';
import { useDispatch, useSelector } from 'react-redux';
import { createClinic, fetchClinicDetails, resetClinicDetails } from '../../../../redux/reducers/admin.slice';
import ModalComponent from '../../appointment/Modal';
import { fileSavePatientDocuments } from '../../../../shared/Utils/utils';
import { getFeatureValue, useAuthorization } from '../../../../shared/Utils/useAuthorization';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const sections = [
    {
        id:'clinicmanagement',
        label:'Clinic Management',
        value:'/admin/clinicmanagement/allclinic'
    },
    {
        id:'usermanagement',
        label:'User Management',
        value:'/admin/usermanagement'
    },
    {
        id:'slotmanagement',
        label:'Slot Management',
        value:'/admin/slotmanagement'
    },
]

const accountForOptions = [
    {
        label:'Clinic',
        value:'clinic'
    },
    // {
    //     label:'Hospital',
    //     value:'hosptial'
    // },
    // {
    //     label:'Lab',
    //     value:'lab'
    // },
]

const initState = {
    clinicId:'',
    clinicName:'',
    accountFor:'',
    address:'',
    pincode:'',
    country:'India',
    state:'',
    city:'',
    clinicContactNumber:'',
    clinicSpecialization:'',
    maxUserLimit:'',
    logo:'',
    images:[],
    linkGoogleBusiness:'',
    personalWebsiteURL:'',
    service:[]
}

const servicesOptions = [
    "Consultation",
]

const locationOptions = {
    cityOptions:[],
    stateOptions:[],
    countryOptions:[],
}

const initErrorState = {
    errorField:'',
    errorMsg:''
}

const EditClinic = () => {

  //Hook
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //State
  const {clinicDetails,loader} = useSelector((state)=>state.admin)
  const [pathname,setPathname] = useState('');
  const [searchParam,setSearchParams] = useSearchParams();
  const [submitLoading,setSubmitLoading] = useState(false);
  const [state,setState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },initState)
  const [locationState,setLocationState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },locationOptions)
  const [errorState,setErrorState] = useReducer((state,action)=>{
    return {
        ...state,
        ...action
    }
  },initErrorState)

  const [logoFile, setLogoFile] = useState();

  const logoInputRef = useRef(null);
  const clinicImageInputRef = useRef(null);

  const [selectedClinicImage,setSelectedClinicImage] = useState(0);
  const [clinicImageFile1, setClinicImageFile1] = useState();
  const [clinicImageFile2, setClinicImageFile2] = useState();
  const [clinicImageFile3, setClinicImageFile3] = useState();

  const [logoURL,setLogoURL] = useState();
  const [clinicImg1URL,setClinicImg1URL] = useState();
  const [clinicImg2URL,setClinicImg2URL] = useState();
  const [clinicImg3URL,setClinicImg3URL] = useState();
  const [logoDisplayURL,setLogoDisplayURL] = useState();
  const [clinicImg1DisplayURL,setClinicImg1DisplayURL] = useState();
  const [clinicImg2DisplayURL,setClinicImg2DisplayURL] = useState();
  const [clinicImg3DisplayURL,setClinicImg3DisplayURL] = useState();

  const [uploadingLogo,setUploadingLogo] = useState(false);
  const [uploadingClinicImage,setUploadingClinicImage] = useState(false);

  const [loadingLocationOptions, setLoadingLocationOptions] = useState(false); 

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({
    isSuccess: true,
    headerText: "",
    subText: "",
    actionButtonText: "",
    actionButtonOnClick: () => {
      return;
    },
  });
  
  const [imageUploadError,setImageUploadError] = useState('');
  const [imageUploadError2,setImageUploadError2] = useState('');

  const userFeatures = useAuthorization();

  //Effects
  useEffect(()=>{
    if(location.pathname){
        setPathname(location.pathname);
    }
  },[location])

  useEffect(()=>{
    if(searchParam.get('account_id')){
        dispatch(fetchClinicDetails({account_id:searchParam.get('account_id')}))
    }
  },[searchParam])

  useEffect(()=>{
    if(logoFile){
        setState({logo:logoFile})
        console.log("logo", logoFile)
    }
  },[logoFile])

  useEffect(()=>{
    let list = [];
    if(clinicImageFile1){
        list.push(clinicImageFile1)
    }
    if(clinicImageFile2){
        list.push(clinicImageFile2)
    }
    if(clinicImageFile3){
        list.push(clinicImageFile3)
    }
    setState({images:list})
  },[clinicImageFile1,clinicImageFile2,clinicImageFile3])

  useEffect(() => {
    setLoadingLocationOptions(true);
    fetchCountryOptions().then((res) => {
      setState({ country: "India" });
      setLoadingLocationOptions(false);
    }).catch(()=>{
        setLoadingLocationOptions(false);
    });

    return ()=>{
        dispatch(resetClinicDetails());
    }
  }, []);

  useEffect(() => {
    setLoadingLocationOptions(true);
    if (state.country) {
      fetchStateOptions().then(()=>{
        setLoadingLocationOptions(false);
      }).catch(()=>{
        setLoadingLocationOptions(false);
      });
    }
  }, [state.country]);

  useEffect(() => {
    setLoadingLocationOptions(true);
    if (state.state) {
      fetchCityOptions().then(()=>{
        setLoadingLocationOptions(false);
      }).catch(()=>{
        setLoadingLocationOptions(false);
      });
    }
  }, [state.state]);

  useEffect(()=>{
    if(searchParam.get('account_id')){
        dispatch(fetchClinicDetails({account_id:searchParam.get('account_id')}))
    }
  },[searchParam])

  useEffect(()=>{
    if(Object.keys(clinicDetails).length){
        setState({
            clinicId:clinicDetails?.account_id || '',
            clinicName:clinicDetails?.account_name || '',
            accountFor:clinicDetails?.account_type || '',
            address:clinicDetails?.address?.line || '',
            pincode:clinicDetails?.address?.pincode || '',
            country:clinicDetails?.address?.country || '',
            state:clinicDetails?.address?.state || '',
            city:clinicDetails?.address?.city || '',
            clinicContactNumber:clinicDetails?.emergency_info || '',
            clinicSpecialization:clinicDetails?.specialities[0] || '',
            maxUserLimit:clinicDetails?.max_user_allowed || '',
            logo:clinicDetails?.clinic_logo || '',
            images:clinicDetails?.clinic_images || [],
            linkGoogleBusiness:clinicDetails?.web_url || '',
            personalWebsiteURL:clinicDetails?.clinic_website || '',
            service:clinicDetails?.services || []
        })
        setLogoURL(clinicDetails?.clinic_logo || '')
        if(clinicDetails.clinic_images){
            if(clinicDetails?.clinic_images[0]){
                setClinicImg1URL(clinicDetails?.clinic_images[0])
            }
            if(clinicDetails?.clinic_images[1]){
                setClinicImg2URL(clinicDetails?.clinic_images[1])
            }
            if(clinicDetails?.clinic_images[2]){
                setClinicImg3URL(clinicDetails?.clinic_images[2])
            }
        }

        setLogoDisplayURL(clinicDetails?.clinic_logo_url || '')
        if(clinicDetails.clinic_images_url){
            if(clinicDetails?.clinic_images_url[0]){
                setClinicImg1DisplayURL(clinicDetails?.clinic_images_url[0])
            }
            if(clinicDetails?.clinic_images[1]){
                setClinicImg2DisplayURL(clinicDetails?.clinic_images_url[1])
            }
            if(clinicDetails?.clinic_images[2]){
                setClinicImg3DisplayURL(clinicDetails?.clinic_images_url[2])
            }
        }
    }
    
  },[clinicDetails])

  //Functions

  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onabort = () => reject(new Error("File reading was aborted"));
      reader.onerror = () => reject(new Error("File reading has failed"));
      reader.onload = () => resolve(reader.result);
      reader.readAsArrayBuffer(file);
    });
  };

  const getSignedURLAndUploadFile = async (field,file,type,content_type,account_id)=>{
    const res =  await APIClient.get(API_ROUTE_GET_SIGNED_URL(type,content_type))
    const binaryStr = await readFileAsArrayBuffer(file);
    const uploadResponse = await fileSavePatientDocuments({
        displayUrl:res?.data?.data?.url,
        binaryStr,
        contentType:content_type,
    });

    switch(field){
        case 'logo':
            setLogoURL(res?.data?.path);
            setUploadingLogo(false);
            return;
        case 'clinicImg1':
            setClinicImg1URL(res?.data?.path);
            setUploadingClinicImage(false);
            return;
        case 'clinicImg2':
            setClinicImg2URL(res?.data?.path);
            setUploadingClinicImage(false);
            return;
        case 'clinicImg3':
            setClinicImg3URL(res?.data?.path);
            setUploadingClinicImage(false);
            return;
        default:
            setUploadingClinicImage(false);
            setUploadingLogo(false);
            return;
    }

  }

  const handleCancel = ()=>{
    navigate('/admin/clinicmanagement/allclinic')
    return;
  }
  const handleSubmit = async ()=>{

    //validation
    if(!state.clinicId){
        setErrorState({
            errorField:'clinic id',
            errorMsg:'Please enter clinic id'
        })
        return;
    }else if(!state.clinicName){
        setErrorState({
            errorField:'clinic name',
            errorMsg:'Please enter clinic name'
        })
        return;
    }else if(!state.address){
        setErrorState({
            errorField:'address',
            errorMsg:'Please enter address'
        })
        return;
    }else if(!state.clinicContactNumber || state.clinicContactNumber?.length !== 10){
        setErrorState({
            errorField:'clinic contact number',
            errorMsg:'Please enter contact number'
        })
        return;
    }else if(!state.clinicSpecialization){
        setErrorState({
            errorField:'clinic specialization',
            errorMsg:'Please enter clinic specialization'
        })
        return;
    }else if(!state.maxUserLimit){
        setErrorState({
            errorField:'max user limit',
            errorMsg:'Please enter max user limit'
        })
        return;
    }else if(!state.linkGoogleBusiness){
        setErrorState({
            errorField:'google business link',
            errorMsg:'Please enter google business link'
        })
        return;
    }else if(!state.personalWebsiteURL){
        setErrorState({
            errorField:'personal website url',
            errorMsg:'Please enter personal website url'
        })
        return;
    }

    setSubmitLoading(true);

    const body = {
        account_id: state.clinicId,
        account_name: state.clinicName,
        account_type: state.accountFor,
        enable_tele_call: true,
        max_user_allowed: state.maxUserLimit,
        address:{
            line:state.address,
            city:state.city,
            state:state.state,
            country:state.country,
            pincode:state.pincode,
        },
        emergency_info:state.clinicContactNumber,
        specialities:[state.clinicSpecialization],
        clinic_logo:logoURL,
        clinic_images:[],
        web_url:state.linkGoogleBusiness,
        clinic_website:state.personalWebsiteURL,
        services:state.service
    }
    
    if(clinicImg1URL){
        body.clinic_images.push(clinicImg1URL);
    }
    if(clinicImg2URL){
        body.clinic_images.push(clinicImg2URL);
    }
    if(clinicImg3URL){
        body.clinic_images.push(clinicImg3URL);
    }
    
    
    await APIClient.put(API_ROUTE_EDIT_CLINIC(searchParam.get('account_id')),body)
    .then(res=>{
        setModalData({
            isSuccess: true,
            headerText: "Clinic details updated",
            subText: "",
            actionButtonText: "",
            actionButtonOnClick: () => {
                return;
            },
        })
        setOpenModal(true);
        setSubmitLoading(false);
    }).catch(err=>{
        console.log("Err",err)
        setModalData({
            isSuccess: false,
            headerText: "Unable To Update Clinic",
            subText: (
              <Typography>
                {err}
              </Typography>
            ),
            actionButtonText: "Try Again",
            actionButtonOnClick: () => {
                setOpenModal(false);
              handleSubmit();
              return;
            },
          });
        setOpenModal(true);
        setSubmitLoading(false);
    })
    
    return;
  }
  const handleClickViewAllClinic = ()=>{
    navigate('/admin/clinicmanagement/allclinic')
    return;
  }
  const handleClickLinkNow = ()=>{
    return;
  }
  const handleClickRegisterNow = ()=>{
    return;
  }
  const handleClickWatchVideo = ()=>{
    return;
  }
  const handleCLickLogoInput = async (e)=>{
    setImageUploadError('');
    if(e.target.files.length){

        if(e.target.files[0].size > 2e+6){
            console.log("file size too big")
            setImageUploadError('File size too big')
            return;
        }
        setLogoFile(URL.createObjectURL(e.target.files[0]));
        setUploadingLogo(true);
        await getSignedURLAndUploadFile("logo",e.target.files[0],'logo',e.target.files[0].type)
    }
    return;
  }
  const handleClickAddClinicImages = async (e)=>{
    setImageUploadError2('')
    if(e.target.files.length){
        if(e.target.files[0].size > 2e+6){
            setImageUploadError2('File size too big')
            return;
        }
        setUploadingClinicImage(true);
        switch(selectedClinicImage){
            case 1:
                setClinicImageFile1(URL.createObjectURL(e.target.files[0]))
                await getSignedURLAndUploadFile("clinicImg1",e.target.files[0],'clinic_image',e.target.files[0].type)
                return;
            case 2:
                setClinicImageFile2(URL.createObjectURL(e.target.files[0]))
                await getSignedURLAndUploadFile("clinicImg2",e.target.files[0],'clinic_image',e.target.files[0].type)
                return;
            case 3:
                setClinicImageFile3(URL.createObjectURL(e.target.files[0]))
                await getSignedURLAndUploadFile("clinicImg3",e.target.files[0],'clinic_image',e.target.files[0].type)
                return;
            default:
                return;
        }
        
    }
    return;
  }

  const fetchCountryOptions = async () => {
    let results = await APIClient.get(
    //   API_ROUTE_FETCH_COUNTRIES(localStorage.getItem("s3AccountId"), "country")
      API_ROUTE_FETCH_COUNTRIES('svaas', "country")
    );

    if (results?.data?.length > 0) {
      setLocationState({ countryOptions: results?.data });
    } else {
        setLocationState({ countryOptions: [] });
    }
  };
  const fetchStateOptions = async () => {
    let results = await APIClient.get(
    //   API_ROUTE_FETCH_STATE(
    //     localStorage.getItem("s3AccountId"),
    //     "state",
    //     state.country
    //   )
      API_ROUTE_FETCH_STATE(
        'svaas',
        "state",
        state.country
      )
    );

    if (results?.data?.length > 0) {
        setLocationState({ stateOptions: results?.data });
    } else {
        setLocationState({ stateOptions: [] });
    }
  };
  const fetchCityOptions = async () => {
    let results = await APIClient.get(
    //   API_ROUTE_FETCH_CITY(
    //     localStorage.getItem("s3AccountId"),
    //     "city",
    //     state.country,
    //     state.state
    //   )
      API_ROUTE_FETCH_CITY(
        'svaas',
        "city",
        state.country,
        state.state
      )
    );

    if (results?.data?.length > 0) {
        setLocationState({ cityOptions: results?.data });
    } else {
        setLocationState({ cityOptions: [] });
    }
  };

  const handleClickAddNewClinic = ()=>{
    navigate('/admin/clinicmanagement/newclinic')
    return;
  }

  const isSubmitDisabled = ()=>{
    return (
        uploadingLogo || uploadingClinicImage
    )
  }

  return (
    <>
      {isMobileScreen ? (
        <>
          
        </>
      ) : (
        <Box height={"100%"} width={"100%"} zIndex={100} position={"absolute"}>
          
          <Box >
            <Grid container height={{xs:"100%",md:`calc(100vh - 42px)`}} width={{xs:"100%",md:`calc(100vw - 60px)`}} spacing={4}>
              
              {/* Left Panel */}
              <Grid
                item
                height="100%"
                xs={3.3}
                
              >
                    <Box 
                        width={'100%'} 
                        height={'100%'}
                        sx={{
                            // boxShadow: "1px 1px 4px 2px rgba(0,0,0,0.2)",
                            backgroundColor: "#ffffff",
                            borderRadius:'0px 8px 0px 0px'
                        }}
                    >
                        {
                            sections.map((elem)=>{
                                return (
                                    <Box 
                                        key={elem.label}
                                        width={'100%'} 
                                        boxSizing={'border-box'}
                                        paddingX={4} 
                                        paddingY={2} 
                                        display={'flex'} 
                                        justifyContent={'space-between'} 
                                        alignItems={'center'}
                                        onClick={()=>{
                                            // navigate(elem.value)
                                        }}
                                        sx={{
                                            cursor:'pointer'
                                        }}
                                    >
                                        <Typography 
                                            variant='subtitle2' 
                                            color={pathname.includes(elem.id) ? theme.palette.primary.main : 'black'}
                                            fontWeight={pathname.includes(elem.id) ? "bold" : 'regular'}
                                        >
                                            {elem.label}
                                        </Typography>
                                        {
                                            pathname.includes(elem.id) && (
                                                <ArrowForwardIosOutlinedIcon fontSize='small' sx={{color:theme.palette.primary.main}}/>
                                            )
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
              </Grid>


              {/* Right Panel */}
              <Grid
                item
                height="100%"
                xs
                spacing={10}
              >
                <Grid
                    container
                    height={'100%'}
                    spacing={2}
                    direction={'column'}
                >
                    <Grid
                        item
                        xs
                        height={'10%'}
                    >
                        <Box 
                            width={'100%'} 
                            height={'100%'}
                            sx={{
                                backgroundColor: "#ffffff",
                                boxSizing:'border-box',
                                flex:1,
                                borderRadius:'8px',
                                overflow:'hidden',
                                position:'relative'
                            }}
                        >
                            {/* Heading */}
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} padding={2}>
                                
                                {/* Title */}
                                <Box display={'flex'} gap={1}>
                                    <Box
                                        width={{ xs: 20 }}
                                        component="img"
                                        alt="clinic"
                                        src={"/images/clinicicon2primary.svg"}
                                        sx={{color:'primary'}}
                                    />
                                    <Typography variant='subtitle2' fontWeight={'bold'} color={theme.palette.primary.main}>{ADMIN_CLINIC_DETAILS['eng']}</Typography>
                                </Box>

                                {/* Header Buttons */}
                                <Box display={'flex'} gap={2}>
                                    <Button
                                        variant='outlined'
                                        startIcon={<RemoveRedEyeOutlinedIcon/>}
                                        onClick={handleClickViewAllClinic}
                                    >
                                        <Typography variant='subtitle2' textTransform={'none'}>{ADMIN_VIEW_ALL_CLINIC['eng']}</Typography>
                                    </Button>
                                    <Button
                                        variant='contained'
                                        startIcon={<AddOutlinedIcon/>}
                                        onClick={handleClickAddNewClinic}
                                        sx={{display:userFeatures.includes(getFeatureValue('CLINIC_CREATION')) ? 'flex' : 'none'}}
                                    >
                                        <Typography variant='subtitle2' textTransform={'none'}>{ADMIN_ADD_NEW_CLINICS['eng']}</Typography>
                                    </Button>
                                </Box>

                            </Box>  

                            {/* Form */}
                            <Box height={'100%'} display={'flex'} flexDirection={'column'} sx={{boxSizing:'border-box'}}  overflow={'auto'} paddingX={2} paddingTop={1} paddingBottom={10}>
                                {loader ? (
                                    <>
                                        <Skeleton animation='wave'/>
                                        <Skeleton animation='wave'/>
                                        <Skeleton animation='wave'/>
                                        <Skeleton animation='wave'/>
                                    </>
                                ) : (
                                    <>
                                        <Box display={'flex'} gap={3}>
                                            <Box flex={1} display={'flex'} flexDirection={'column'} gap={2}>
                                                <TextField
                                                    label={`Clinic ID`}
                                                    sx={{display:'flex'}}
                                                    required={true}
                                                    value={state.clinicId}
                                                    disabled={true}
                                                    onChange={(e)=>{
                                                        setState({clinicId:e.target.value.trim()});
                                                    }}
                                                    helperText={
                                                        errorState.errorField === "clinic id" && errorState.errorMsg
                                                    }
                                                    error={errorState.errorField === "clinic id"}
                                                />
                                                <TextField
                                                    label={`${ADMIN_CLINIC_NAME['eng']}`}
                                                    sx={{display:'flex'}}
                                                    required={true}
                                                    value={state.clinicName}
                                                    onChange={(e)=>{
                                                        let val = e.target.value;
                                                        if(val.length > 30) return;
                                                        setState({clinicName:val});
                                                    }}
                                                    helperText={
                                                        errorState.errorField === "clinic name" && errorState.errorMsg
                                                    }
                                                    error={errorState.errorField === "clinic name"}
                                                />
                                                
                                                {/* <TextField
                                                    label={ADMIN_ACCOUNT_FOR['eng']}
                                                    sx={{display:'flex'}}
                                                    value={state.accountFor}
                                                    onChange={(e)=>{
                                                        setState({accountFor:e.target.value});
                                                    }}
                                                /> */}
                                                <FormControl fullWidth>
                                                    <InputLabel id="accountfor-label">
                                                        {ADMIN_ACCOUNT_FOR['eng']}
                                                    </InputLabel>
                                                    <Select 
                                                    id="accountfor-label"
                                                        label={ADMIN_ACCOUNT_FOR['eng']}
                                                        value={state.accountFor}
                                                        onChange={(e)=>{
                                                            if(e.target.value){
                                                                setState({accountFor:e.target.value});
                                                            }
                                                        }}
                                                        sx={{display:'flex'}}
                                                    >
                                                        {accountForOptions.map(opt=>{
                                                            return (
                                                                <MenuItem value={opt.value}>{opt.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                                
                                                <TextField
                                                    label={`${ADMIN_ADDRESS['eng']}*`}
                                                    sx={{display:'flex'}}
                                                    value={state.address}
                                                    onChange={(e)=>{
                                                        setState({address:e.target.value})
                                                    }}
                                                    helperText={
                                                        errorState.errorField === "address" && errorState.errorMsg
                                                    }
                                                    error={errorState.errorField === "address"}
                                                />

                                                <Box display={"flex"} gap={1}>
                                                    <TextField
                                                        label="Pincode"
                                                        autoComplete={"off"}
                                                        sx={{ width: "100%" }}
                                                        value={state.pincode}
                                                        onChange={(e) => {
                                                        const onlyNums = e.target.value.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                        );
                                                        if (onlyNums.length > 6) {
                                                            return;
                                                        }
                                                        if (onlyNums || e.target.value === "") {
                                                            setState({ pincode: e.target.value });
                                                            if (errorState.errorField === "pincode") {
                                                            setErrorState({errorField:''});
                                                            }
                                                        }
                                                        }}
                                                        helperText={
                                                            errorState.errorField === "pincode" && errorState.errorMsg
                                                        }
                                                        error={errorState.errorField === "pincode"}
                                                    />

                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Country
                                                        </InputLabel>
                                                        <Select
                                                        label="Country"
                                                        value={state.country}
                                                        onChange={(e) => {
                                                            setState({ country: e.target.value });
                                                        }}
                                                        disabled={loadingLocationOptions}
                                                        >
                                                        {locationState.countryOptions?.map((country) => {
                                                            return (
                                                            <MenuItem value={country}>
                                                                {country}
                                                            </MenuItem>
                                                            );
                                                        })}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <Box display={"flex"} gap={1}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            State
                                                        </InputLabel>
                                                        <Select
                                                            label="State"
                                                            value={state.state}
                                                            onChange={(e) => {
                                                                setState({ state: e.target.value });
                                                            }}
                                                            disabled={state.country === "" || loadingLocationOptions}
                                                        >
                                                        {locationState.stateOptions?.map((state) => {
                                                            return (
                                                            <MenuItem value={state}>{state}</MenuItem>
                                                            );
                                                        })}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            City
                                                        </InputLabel>
                                                        <Select
                                                            label="City"
                                                            value={state.city}
                                                            onChange={(e) => {
                                                                setState({ city: e.target.value });
                                                            }}
                                                            disabled={state.state === "" || loadingLocationOptions}
                                                        >
                                                        {locationState.cityOptions?.map((city) => {
                                                            return (
                                                            <MenuItem value={city}>{city}</MenuItem>
                                                            );
                                                        })}
                                                        </Select>
                                                    </FormControl>
                                                </Box>

                                                <TextField
                                                    label={`${ADMIN_CLINIC_CONTACT_NUMBER['eng']}`}
                                                    sx={{display:'flex'}}
                                                    required={true}
                                                    value={state.clinicContactNumber}
                                                    onChange={(e) => {
                                                        const onlyNums = e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                        );
                                                        if (onlyNums.length > 10) {
                                                        return;
                                                        } else {
                                                        setState({
                                                            clinicContactNumber: onlyNums,
                                                        });
                                                        if (errorState.errorField === "clinic contact number") {
                                                            setErrorState({errorField:""})
                                                        }
                                                        }
                                                    }}
                                                    helperText={
                                                    errorState.errorField === "clinic contact number" &&
                                                    errorState.errorMsg
                                                    }
                                                    error={errorState.errorField === "clinic contact number"}
                                                />

                                                <TextField
                                                    label={`${ADMIN_CLINIC_SPECIALIZATION['eng']}`}
                                                    sx={{display:'flex'}}
                                                    required={true}
                                                    value={state.clinicSpecialization}
                                                    onChange={(e)=>{
                                                        setState({clinicSpecialization:e.target.value})
                                                    }}
                                                    helperText={
                                                        errorState.errorField === "clinic specialization" &&
                                                        errorState.errorMsg
                                                    }
                                                    error={errorState.errorField === "clinic specialization"}
                                                />

                                                <TextField
                                                    label={`${ADMIN_MAX_USER_LIMIT['eng']}`}
                                                    sx={{display:'flex'}}
                                                    value={state.maxUserLimit}
                                                    onChange={(e)=>{
                                                        const onlyNums = e.target.value.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                        );
                                                        if(onlyNums<0 || onlyNums>150) return;
                                                        setState({maxUserLimit:onlyNums})
                                                    }}
                                                    helperText={
                                                        errorState.errorField === "max user limit" &&
                                                        errorState.errorMsg
                                                    }
                                                    error={errorState.errorField === "max user limit"}
                                                    InputProps={{
                                                        endAdornment:<Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                            <Box sx={{cursor:'pointer',display:'flex'}} onClick={()=>{setState({maxUserLimit:state.maxUserLimit && state.maxUserLimit < 150 ? +state.maxUserLimit + 1:state.maxUserLimit})}}><KeyboardArrowUpIcon color='primary'/></Box>
                                                            <Box sx={{cursor:'pointer',display:'flex'}} onClick={()=>{setState({maxUserLimit:state.maxUserLimit && state.maxUserLimit > 0 ? +state.maxUserLimit - 1:0})}}><KeyboardArrowDownIcon color='primary'/></Box>
                                                        </Box>
                                                    }}
                                                />
                                            </Box>
                                            <Box flex={1} display={'flex'} flexDirection={'column'} gap={2}>
                                                <Box display={'flex'} gap={2} flexDirection={'column'} justifyContent={'start'}>
                                                    
                                                    {/* add logo */}
                                                    <Box display={'flex'} gap={1} flexDirection={'column'} justifyContent={'start'}>
                                                        <Box  display={'flex'} gap={2}>
                                                            <Typography variant='caption' color={theme.palette.white.greyText}>{ADMIN_ADD_LOGO['eng']}</Typography>
                                                            <Typography variant='caption' color={theme.palette.white.greyText}>{uploadingLogo?"uploading...":""}</Typography>
                                                            <Typography variant='caption' color={theme.palette.red.main}>{imageUploadError?imageUploadError:""}</Typography>
                                                        </Box>
                                                        

                                                        <input ref={logoInputRef} type="file" onChange={handleCLickLogoInput} accept="image/png" style={{display:'none'}}/>
                                                        <Box 
                                                            display={'flex'} 
                                                            width={'100px'}
                                                            height={'100px'}
                                                            flexDirection={'column'} 
                                                            justifyContent={'center'} 
                                                            alignItems={'center'}
                                                            gap={0.5}
                                                            overflow={'hidden'}
                                                            sx={{borderRadius:"14px",border:`1px dashed ${theme.palette.white.outline}`,cursor:'pointer'}}
                                                            onClick={()=>{setLogoFile(undefined) ;logoInputRef.current.click()}}
                                                        >
                                                            {logoFile ? (
                                                                <>
                                                                    <Box
                                                                        component={'img'}
                                                                        src={logoFile}
                                                                        height={'100%'}
                                                                        sx={{
                                                                            backgroundPosition:'center',
                                                                            backgroundSize:'cover',
                                                                            
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                logoDisplayURL ? (
                                                                    <Box
                                                                        component={'img'}
                                                                        src={logoDisplayURL}
                                                                        height={'100%'}
                                                                        sx={{
                                                                            backgroundPosition:'center',
                                                                            backgroundSize:'cover',
                                                                            
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Box 
                                                                        display={'flex'} 
                                                                        width={'100%'}
                                                                        height={'100%'}
                                                                        flexDirection={'column'} 
                                                                        justifyContent={'center'} 
                                                                        alignItems={'center'}
                                                                        padding={2}
                                                                        gap={0.5}
                                                                    >
                                                                        <AddPhotoAlternateOutlinedIcon color='primary'/>
                                                                        <Typography variant='caption' textAlign={'center'} color={theme.palette.white.greyText}>{ADMIN_UPLOAD_IMAGE['eng']}</Typography>
                                                                    </Box>
                                                                )
                                                            )}
                                                            
                                                        </Box>
                                                        
                                                    </Box>

                                                    {/* add images */}
                                                    <Box display={'flex'} gap={1} flexDirection={'column'} justifyContent={'start'}>
                                                        <input ref={clinicImageInputRef} type="file" onChange={handleClickAddClinicImages} accept="image/png, image/jpeg" style={{display:'none'}}/>
                                                        <Box display={'flex'} gap={2}>
                                                            <Typography variant='caption' color={theme.palette.white.greyText}>{ADMIN_ADD_CLINIC_IMAGES['eng']}</Typography>
                                                            <Typography variant='caption' color={theme.palette.white.greyText}>{uploadingClinicImage?"uploading...":""}</Typography>
                                                            <Typography variant='caption' color={theme.palette.red.main}>{imageUploadError2?imageUploadError2:""}</Typography>
                                                        </Box>

                                                        <Box display={'flex'} gap={1}>
                                                            <Box 
                                                                display={'flex'} 
                                                                width={'100px'}
                                                                height={'100px'}
                                                                flexDirection={'column'} 
                                                                justifyContent={'center'} 
                                                                alignItems={'center'}
                                                                gap={0.5}
                                                                sx={{
                                                                    cursor:'pointer',
                                                                    borderRadius:"14px",
                                                                    backgroundColor:theme.palette.primary.lighter,
                                                                }}
                                                                overflow={'hidden'}
                                                                onClick={()=>{setSelectedClinicImage(1) ;setClinicImageFile1(undefined); clinicImageInputRef.current.click()}}
                                                            >
                                                                {clinicImageFile1 ? (
                                                                    <Box
                                                                        component={'img'}
                                                                        src={clinicImageFile1}
                                                                        height={'100%'}
                                                                        sx={{
                                                                            backgroundPosition:'center',
                                                                            backgroundSize:'cover',
                                                                            
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    clinicImg1DisplayURL?(
                                                                        <Box
                                                                            component={'img'}
                                                                            src={clinicImg1DisplayURL}
                                                                            height={'100%'}
                                                                            sx={{
                                                                                backgroundPosition:'center',
                                                                                backgroundSize:'cover',
                                                                                
                                                                            }}
                                                                        />
                                                                    ):(
                                                                        <Box
                                                                            width={{ xs: 20 }}
                                                                            component="img"
                                                                            alt="clinic"
                                                                            src={"/images/plusicon1.svg"}
                                                                            sx={{color:'primary'}}
                                                                        />
                                                                    )
                                                                    
                                                                )}
                                                                
                                                            </Box>
                                                            <Box 
                                                                display={'flex'} 
                                                                width={'100px'}
                                                                height={'100px'}
                                                                flexDirection={'column'} 
                                                                justifyContent={'center'} 
                                                                alignItems={'center'}
                                                                gap={0.5}
                                                                sx={{
                                                                    cursor:'pointer',
                                                                    borderRadius:"14px",
                                                                    backgroundColor:theme.palette.primary.lighter,
                                                                }}
                                                                overflow={'hidden'}
                                                                onClick={()=>{setSelectedClinicImage(2) ;setClinicImageFile2(undefined); clinicImageInputRef.current.click()}}
                                                            >
                                                                {clinicImageFile2 ? (
                                                                    <Box
                                                                        component={'img'}
                                                                        src={clinicImageFile2}
                                                                        height={'100%'}
                                                                        sx={{
                                                                            backgroundPosition:'center',
                                                                            backgroundSize:'cover',
                                                                            
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    clinicImg2DisplayURL ? (
                                                                        <Box
                                                                            component={'img'}
                                                                            src={clinicImg2DisplayURL}
                                                                            height={'100%'}
                                                                            sx={{
                                                                                backgroundPosition:'center',
                                                                                backgroundSize:'cover',
                                                                                
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Box
                                                                            width={{ xs: 20 }}
                                                                            component="img"
                                                                            alt="clinic"
                                                                            src={"/images/plusicon1.svg"}
                                                                            sx={{color:'primary'}}
                                                                        />
                                                                    )
                                                                    
                                                                )}
                                                            </Box>
                                                            <Box 
                                                                display={'flex'} 
                                                                width={'100px'}
                                                                height={'100px'}
                                                                flexDirection={'column'} 
                                                                justifyContent={'center'} 
                                                                alignItems={'center'}
                                                                gap={0.5}
                                                                sx={{
                                                                    cursor:'pointer',
                                                                    borderRadius:"14px",
                                                                    backgroundColor:theme.palette.primary.lighter,
                                                                }}
                                                                overflow={'hidden'}
                                                                onClick={()=>{setSelectedClinicImage(3) ;setClinicImageFile3(undefined); clinicImageInputRef.current.click()}}
                                                            >
                                                                {clinicImageFile3 ? (
                                                                    <Box
                                                                        component={'img'}
                                                                        src={clinicImageFile3}
                                                                        height={'100%'}
                                                                        sx={{
                                                                            backgroundPosition:'center',
                                                                            backgroundSize:'cover',
                                                                            
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    clinicImg3DisplayURL ? (
                                                                        <Box
                                                                            component={'img'}
                                                                            src={clinicImg3DisplayURL}
                                                                            height={'100%'}
                                                                            sx={{
                                                                                backgroundPosition:'center',
                                                                                backgroundSize:'cover',
                                                                                
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Box
                                                                            width={{ xs: 20 }}
                                                                            component="img"
                                                                            alt="clinic"
                                                                            src={"/images/plusicon1.svg"}
                                                                            sx={{color:'primary'}}
                                                                        />
                                                                    )
                                                                    
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                    <TextField
                                                        label={`${ADMIN_LINK_GOOGLE_BUSINESS['eng']}`}
                                                        required={true}
                                                        sx={{display:'flex'}}
                                                        value={state.linkGoogleBusiness}
                                                        onChange={(e)=>{
                                                            setState({linkGoogleBusiness:e.target.value})
                                                        }}
                                                        helperText={
                                                            errorState.errorField === "google business link" &&
                                                            errorState.errorMsg
                                                        }
                                                        error={errorState.errorField === "google business link"}
                                                    />
                                                    <TextField
                                                        label={`${ADMIN_PERSONAL_WEBSITE_URL['eng']}`}
                                                        required={true}
                                                        sx={{display:'flex'}}
                                                        value={state.personalWebsiteURL}
                                                        onChange={(e)=>{
                                                            setState({personalWebsiteURL:e.target.value})
                                                        }}
                                                        helperText={
                                                            errorState.errorField === "personal website url" &&
                                                            errorState.errorMsg
                                                        }
                                                        error={errorState.errorField === "personal website url"}
                                                    />
                                                    {/* <TextField
                                                        label={`${ADMIN_SERVICE['eng']}`}
                                                        sx={{display:'flex'}}
                                                        value={state.service}
                                                        onChange={(e)=>{
                                                            setState({service:e.target.value})
                                                        }}
                                                    /> */}
                                                    <FormControl sx={{display:'flex',width:460}}>
                                                        <InputLabel id="demo-multiple-service-label">{ADMIN_SERVICE['eng']}</InputLabel>
                                                        <Select
                                                        labelId="demo-multiple-service-label"
                                                        id="demo-multiple-service"
                                                        multiple
                                                        value={state.service}
                                                        sx={{
                                                            width:460,
                                                        }}
                                                        onChange={(e)=>{

                                                            let value = e.target.value;

                                                            setState({
                                                                // On autofill we get a stringified value.
                                                                service:typeof value === 'string' ? value.split(',') : value,
                                                            });
                                                        }}
                                                        input={<OutlinedInput label="Service" />}
                                                        // MenuProps={MenuProps}
                                                        >
                                                        {servicesOptions.map((name) => (
                                                            <MenuItem
                                                            key={name}
                                                            value={name}
                                                            >
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                        </Select>
                                                    </FormControl>

                                                </Box>
                                            </Box>
                                        </Box>
                                        
                                        <Box mt={3} display={'flex'} flexDirection={'column'} gap={2}>
                                            <Box display={'flex'} gap={1}>
                                                <Box
                                                    width={{ xs: 20 }}
                                                    component="img"
                                                    alt="clinic"
                                                    src={"/images/healthcarefacilityregistryicon.svg"}
                                                    sx={{color:'primary'}}
                                                />
                                                <Typography variant='subtitle2' fontWeight={'bold'} textTransform={'uppercase'} color={theme.palette.primary.main}>{ADMIN_HEALTHCARE_FACILITY_REGISTRY['eng']}</Typography>
                                            </Box>

                                            <Box display={'flex'} width={'100%'} height={'180px'} borderRadius={'8px'} backgroundColor={theme.palette.primary.lighter}>
                                                <Box position={'relative'} display={'flex'} justifyContent={'end'} alignItems={'center'} paddingRight={6} width={'50%'} height={'100%'} borderRadius={'8px'} backgroundColor={theme.palette.primary.main}>
                                                    <Box zIndex={10}>
                                                        <Typography variant='subtitle2' fontWeight={'bold'} color={theme.palette.white.main}>{ADMIN_HOW_TO_CREATE['eng']}</Typography>
                                                        <Typography variant='subtitle2' fontWeight={'bold'} color={theme.palette.white.main}>{ADMIN_HEALTH_FACILITY_REGISTRY['eng']}</Typography>
                                                        <Box width={'fit-content'} sx={{cursor:'pointer'}}  padding={1} display={'flex'} alignItems={'center'} gap={1} paddingX={2} mt={1} borderRadius={'8px'} backgroundColor={theme.palette.white.main}>
                                                            <Box onClick={handleClickWatchVideo} width={'20px'} height={'20px'} borderRadius={'50%'} display={'flex'} justifyContent={'center'} alignItems={'center'} backgroundColor={theme.palette.primary.main}>
                                                                <PlayArrowIcon fontSize='8px' color={"white"}/>
                                                            </Box>
                                                            <Typography variant='subtitle2' fontWeight={'bold'} color={theme.palette.primary.main}>{ADMIN_WATCH_VIDEO['eng']}</Typography>
                                                            
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        width={{ xs: 220 }}
                                                        component="img"
                                                        alt="clinic"
                                                        src={"/images/healthcarefacilitybackground.svg"}
                                                        zIndex={0}
                                                        sx={{color:'primary',position:'absolute',bottom:0,left:30,zIndex:0}}
                                                    />
                                                    
                                                </Box>

                                                <Box position={'relative'} width={'50%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                    <Typography variant='subtitle1' fontWeight={'bold'} textTransform={'none'}>{ADMIN_ABDM_HEALTH_FACILITY_REGISTRY['eng']}</Typography>
                                                    <Typography variant='subtitle2' textTransform={'none'}>{ADMIN_ADD_TO_INDIAS_DIGITAL_ECOSYSTEM['eng']}</Typography>
                                                    <Box display={'flex'} gap={2} mt={1}>
                                                            <Button
                                                                variant='outlined'
                                                                sx={{backgroundColor:theme.palette.white.main}}
                                                                onClick={handleClickRegisterNow}
                                                            >
                                                                <Typography variant='caption' py={0.5} px={1} textTransform={'none'}>{ADMIN_REGISTER_NOW['eng']}</Typography>
                                                            </Button>
                                                            <Button
                                                                variant='contained'
                                                                onClick={handleClickLinkNow}
                                                            >
                                                                <Typography variant='caption' py={0.5} px={3} textTransform={'none'}>{ADMIN_LINK_NOW['eng']}</Typography>
                                                            </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                
                                
                                
                                
                            </Box>
                        </Box>
                    </Grid>


                    {/* Bottom Buttons */}
                    <Grid
                        item
                        xs={1.5}
                    >
                        <Box 
                            width={'100%'} 
                            height={'100%'}
                            sx={{
                                backgroundColor: "#ffffff",
                                boxSizing:'border-box',
                                flex:1,
                                borderRadius:'8px'
                            }}
                        >

                            <Box height={'100%'} display={"flex"} justifyContent={"space-between"} alignItems={"center"} px={3}>
                                <Button 
                                    variant='outlined'
                                    
                                    onClick={handleCancel}
                                >
                                    <Typography textTransform={'none'}>{ADMIN_CANCEL['eng']}</Typography>
                                </Button>

                                <LoadingButton 
                                    variant='contained'
                                    loading={submitLoading}
                                    sx={{paddingX:3}}
                                    onClick={handleSubmit}
                                    disabled={isSubmitDisabled()}
                                >
                                    <Typography textTransform={'none'}>{'Update'}</Typography>
                                </LoadingButton>
                            </Box>
                            
                        </Box>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <ModalComponent
        open={openModal}
        setOpen={setOpenModal}
        isSuccess={modalData.isSuccess}
        headerText={modalData.headerText}
        subText={modalData.subText}
        actionButtonText={modalData.actionButtonText}
        actionButtonOnClick={modalData.actionButtonOnClick}
        isForCreateClinic={true}
      />
    </>
  )
}

export default EditClinic