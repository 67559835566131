import React, { useState, useEffect } from 'react';
import { Button, Container, Dialog, Grid , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Select, MenuItem, FormControl,InputLabel,CircularProgress,Typography,DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios'; // Assuming you're using axios for API requests
import { APIClient } from "../../../../shared/Utils/api-client";
import { BrowserRouter as Router, Route, Link,useParams } from 'react-router-dom';
import{ROUTE_ADMIN_NEW_RX_TEMPLATE_FORM} from "../../../../shared/routes/route.constants";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { width } from '@mui/system';
import { ShowSkeleton,NoData,HeaderTitleButtonFlex,HeaderTitleButtonItem } from './RxCommon';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import{ROUTE_ADMIN_LIST_RX_TEMPLATES} from "../../../../shared/routes/route.constants";
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles({
  addButton: {
    marginBottom: '20px',
  },
  listItem: {
    marginTop: '20px',
  },
});

function RxSpeciality() {
  const { template_id } = useParams();
  console.log("template_id",template_id);
  const classes = useStyles();
  const [specialities, setSpecialities] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleError = (error) => {
    setErrorOpen(true);
    setErrorMessage(error || 'An error occurred.');
  };

  const handleSaveClick = async () => {
    setSaving(true);
    setDisabledSave(true);
    try{
      const response = await APIClient.post("prescription/assign-template?active_account_id=svaas", {
            "account_id": "svaas",
                "speciality_id":selectedOption,
                "type": "speciality",
                "template_id": template_id
        })
        if (response?.data?.statusCode === 200) {
          setOpenSaveDialog(false);
          setLoading(true);
          setSaving(false);
          setSelectedOption('');
          setDisabledSave(false);
          getTemplate(template_id);
        }
    }
    catch(error){
      console.log("error---",error);
      handleError(error);
      setSaving(false);
      setDisabledSave(false);
    }
   
    
    
    
  }  

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    if(event.target.value === ''){
      setDisabledSave(true);
    }
    else{
      setDisabledSave(false);
    }
  };
  const handleSaveButtonClick = async () => {
    
    setOpenSaveDialog(true);
  };

  const handleCloseSaveDialog = () => {
    setOpenSaveDialog(false);
  };
  const getTemplate = async (template_id) => {
    const response = await APIClient.get("prescription/get-template", {
      params: {
        type: "all_templates",
        file_type:"template",
        id:template_id,
        active_account_id : "svaas"
      },
    });
    console.log("response---",response);
    if (response?.data?.statusCode === 200) {
      setTemplateData(response?.data.data[0]);
      console.log("response",response?.data);
      setLoading(false);
     
    }
   
  }
  const getSpecialities = async () => {
    const response = await APIClient.get("prescription/suggestions", {
      params: {
        type: "speciality",
        active_account_id : "svaas"
      },
    });
    if (response?.data?.statusCode === 200) {
        
        const newObj = (response.data.data).reduce((acc, curr) => {
          acc[curr.id] = curr.name;
          return acc;
      }, {});
      console.log("specialities",newObj);
        setSpecialities(newObj);
      console.log(response?.data?.data);
    }
    
  }
  useEffect(() => {

    // Fetch data from API
    getSpecialities();
    getTemplate(template_id);
   
  }, []);



  const handleDelete = async (id) => {
    setLoading(true)
    // Logic for deleting template with id
    try{
      const response = await APIClient.delete("prescription/delete-assignment/"+id+"?active_account_id=svaas", {
      
      });
      console.log("response---",response);
      if (response?.data?.statusCode === 200) {
        getTemplate(template_id);
      
      
      }
    
    }
    catch(error){
      handleError(error);
      getTemplate(template_id);
      console.log("error---",error);
      console.log('Delete template with ID:', id);
    }
    
  };


  return (
    <Container>
        
        <div>
          <HeaderTitleButtonFlex>
                <HeaderTitleButtonItem>
                  <Typography variant='subtitle1' fontWeight={'bold'}>
                      <IconButton component={Link} to={ROUTE_ADMIN_LIST_RX_TEMPLATES}>
                        <Tooltip title="Back To Templates">
                          <ArrowBackIcon/>
                        </Tooltip>  
                      </IconButton>
                      Specialities Linked To Template
                  </Typography>
                </HeaderTitleButtonItem>
                <HeaderTitleButtonItem>
                  <div style={{textAlign: 'right'}}>
                    <Button  variant="contained" color="primary" className={classes.addButton} onClick={handleSaveButtonClick}> Link New speciality</Button>
                  </div>  
                </HeaderTitleButtonItem>  
            </HeaderTitleButtonFlex>
            
           
            
            <Dialog open={openSaveDialog} onClose={handleCloseSaveDialog} >
                <DialogActions sx={{position:"absolute",right: 0}}>
                  <IconButton aria-label="close" onClick={handleCloseSaveDialog}>
                    <CloseIcon />
                  </IconButton>
                  {/* Other action buttons */}
                </DialogActions>     
                <div style={{padding: 20,width: 400,textAlign: 'center'}}>
                  {/* Add your save popup content here */}
                  <h4>Select Speciality To Link</h4>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="select-label">Select Speciality</InputLabel>
                      <Select
                        labelId="select-label"
                        id="select"
                        value={selectedOption}
                        onChange={handleChange}
                        label="Select Speciality"
                      >
                        {Object.keys(specialities).map((key) => (
                          <MenuItem key={key} value={key}>
                            {specialities[key]}
                          </MenuItem>
                        ))}
                      
                      </Select>
                    </FormControl>
                  
                  </div>
                  <Button 
                    variant="contained" 
                    style={{marginTop: 20}} 
                    onClick={handleSaveClick}
                    startIcon={saving&&<CircularProgress size={20} color="inherit" />}
                    disabled={disabledSave}
                  >
                      Link
                  </Button>
                </div>
            </Dialog>
            
        </div>
      
      <Grid container spacing={3} style={{height:"calc(100vh - 120px)",overflowY:"auto",marginTop:"0px"}}>
      {loading?<>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <ShowSkeleton count={3}/>
          </Grid> 
          
      </>:
      <Grid item xs={12} style={{textAlign: 'center'}}>
        <TableContainer component={Paper} sx={{width:"100%"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                
                <TableCell style={{textAlign:"right"}}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log("specialities===>",templateData.speciality_map,11)}
              {templateData.speciality_map&&(templateData.speciality_map).map((speciality) => (
                <TableRow key={speciality.speciality_id}>
                  <TableCell>{specialities[speciality.speciality_id]}</TableCell>
                  
                  <TableCell style={{textAlign:"right"}}>
                
                    <Tooltip title="Unlink Speciality">
                      <IconButton aria-label="Unlink Speciality" onClick={() => handleDelete(speciality.id)}>
                        <LinkOffIcon color='error'/>
                      </IconButton>
                    </Tooltip>
              
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {templateData && templateData.speciality_map&&templateData.speciality_map.length === 0 && (
            <NoData>
              No Speciality Linked 
            </NoData>
        )} 
      </Grid>}
      {console.log("specialities===>",22)}
     
      </Grid>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000} // Optional: Auto-close after 6 seconds
        onClose={() => setErrorOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Optional: Adjust position
      >
        <MuiAlert onClose={() => setErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
}

export default RxSpeciality;
