import {
    Box,
    Button,
    IconButton,
    Modal,
    Typography,
    useMediaQuery,
  } from "@mui/material";
  
  import CloseIcon from "@mui/icons-material/Close";
  import { useTheme } from "@mui/material";
  import { useNavigate } from "react-router-dom";
import { ADMIN_ADD_NEW_CLINICS, ADMIN_ADD_NEW_USERS, ADMIN_SUPER_ADMIN } from "../../../../assets/translations/admin";
import { useEffect } from "react";
import { getFeatureValue, useAuthorization } from '../../../../shared/Utils/useAuthorization';

  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:"14px",
    p: 2,
    
  };
  const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 280,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius:"14px",
    p: 2,
  };
  
  const UserAddedModal = ({
    open,
    setOpen,
    isSuccess,
    headerModal=false,
    headerText,
    subText,
    actionButtonText,
    actionButtonOnClick,
    role,
    actionButtonOnClickNewUsers,
    actionButtonOnClickNewClinics
  }) => {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const userFeatures = useAuthorization();

    return (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          navigate(0);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={isMobileScreen ? styleMobile : style}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
        >
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Box>
              <IconButton
                onClick={() => {
                  setOpen(false);
                  navigate(0);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
          >
            <Box
              width={{ md: 80 }}
              component="img"
              alt="add patient"
              src={
                !isSuccess
                  ? "/images/userAddedModal.svg"
                  : "/images/appointmentConfirmed.svg"
              }
            />
          </Box>
  
          <Box
            maxHeight={300}
            overflow={"auto"}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
            gap={0}
          >
            <Typography variant="h5" fontWeight={600} textAlign={"center"}>
              {headerText}
            </Typography>

            <Typography variant="caption" color={theme.palette.primary.main} sx={{backgroundColor:theme.palette.primary.light,borderRadius:'20px',paddingX:2, paddingY:0.5,mt:0.5}}>{role?.filter(elem=>{return !elem?.includes('f:') && !elem?.includes('e:')})?.join(',')?.replaceAll('r:',"")?.toUpperCase()}</Typography>

            <Typography variant="caption" textAlign={"center"} color={theme.palette.white.lightText} my={2}>
              {subText}
            </Typography>
            {!isSuccess && (
              <Box display={'flex'} gap={2}>
                <Button variant="outlined" onClick={actionButtonOnClickNewUsers} sx={{borderRadius:'8px',display:userFeatures.includes(getFeatureValue('USER_CREATION')) ? 'block' : 'none'}}>
                    {ADMIN_ADD_NEW_USERS['eng']}
                </Button>
                <Button variant="outlined" onClick={actionButtonOnClickNewClinics} sx={{borderRadius:'8px',display:userFeatures.includes(getFeatureValue('CLINIC_CREATION')) ? 'block' : 'none'}}>
                    {ADMIN_ADD_NEW_CLINICS['eng']}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };
  
  export default UserAddedModal;
  