import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import axios from "axios";
import { useNavigate } from "react-router";
import { ROUTE_NEW_PATIENT } from "../../../shared/routes/route.constants";
import { fetchAllPatientDetails } from "../../../redux/reducers/my-patient.slice";
import { CircularProgress } from "@mui/material";
import { APIClient } from "../../../shared/Utils/api-client";
import PatientCard from "./my-patient-card";

const MyPatient = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const columns = [
    { id: "serialNo", label: "Serial No", width: 100 },
    { id: "patientInfo", label: "Patient Info", width: 120 },
    {
      id: "mobileNumber",
      label: "Mobile Number",
      width: 150,
    },
    { id: "uhid", label: "UID", width: 120 },
    { id: "doctorName", label: "Doctor Name", width: 150 },
    { id: "lastVisit", label: "Last Visited", width: 280 },
    {
      id: "upcomingAppointment",
      label: "Upcoming Appointments",
      width: 250,
    },
    { id: "totalVisits", label: "Total Visits", width: 150 },
    { id: "createdAt", label: "Created At", width: 100 },
    { id: "action", label: "Action", width: 100, align: "center" },
  ];
  const MenuItemList = [
    "Merge",
    "Edit",
    "Delete",
    "Fetch via ABHA",
    "Bill Patient",
    "Create ABHA",
  ];
  const [patientData, setPatientData] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [limit, setLimit] = useState<any>(10);
  const [openMenu, setMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null as any);
  const [loading, setLoading] = useState(true);
  const [infiniteScrollLoader, setInfiniteScrollLoader] = useState(false);
  const [selectPatientData, setSelectedPatinetData] = useState<any>();
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>();
  const [patientSearch, setPatientSearch] = useState("");
  const [showCreateAbha, setshowCreateAbha] = useState<boolean>();
  const [serialNo, setSerialNo] = useState(1);
  const [keyHistory, setKeyHistory] = useState<any>([lastEvaluatedKey]);
  const dispatch = useDispatch();
  const { clinicName } = useSelector((state: any) => state.auth);
  const [selectedClinicName, setSelectedClinicName] = useState<any>(
    clinicName?.[0]?.value
  );

  const getPatientData = async (patientSearch?: any) => {
    // setLoading(true);
    const reg = new RegExp("^[0-9]+$");
    let isPhone = false;
    if (reg.test(patientSearch)) {
      isPhone = true;
    }
    setSerialNo(page * limit + 1);
    const response = await dispatch(
      fetchAllPatientDetails({
        params: {
          limit: limit,
          account_id: localStorage.getItem("cAccount") || selectedClinicName,
          ...(lastEvaluatedKey && { last_evaluatedKey: lastEvaluatedKey }),
          ...(patientSearch && !isPhone && { name: patientSearch }),
          ...(patientSearch && isPhone && { phone: patientSearch }),
        },
      })
    );
    if (response?.payload?.data?.rows?.length === 0) {
      setPatientData([]);
      setLoading(false);
    } else {
      if(isMobileScreen){
        setPatientData((prev : any) => [...prev, ...(response?.payload?.data?.rows || [])]);
        setLastEvaluatedKey(response?.payload?.data?.last_evaluatedKey);
        setLoading(false);
        setInfiniteScrollLoader(false)
      }
      else{
      setPatientData(response?.payload?.data?.rows);
      setLastEvaluatedKey(response?.payload?.data?.last_evaluatedKey);
      setLoading(false);
    }
  }
};

  const patientDataCount = (patientSearch?: any) => {
    const reg = new RegExp("^[0-9]+$");
    let isPhone = false;
    if (reg.test(patientSearch)) {
      isPhone = true;
    }
    APIClient.get(`patient/patient/count`, {
      params: {
        account_id: localStorage.getItem("cAccount") || selectedClinicName,
        ...(patientSearch && !isPhone && { name: patientSearch }),
        ...(patientSearch && isPhone && { phone: patientSearch }),
      },
    }).then((res) => {
      setTotalCount(res?.data);
    });
  };

  const handleAuth = () => {
    axios(
      "https://ndhm-qa.azurewebsites.net/api/user-auth?code=gw-nnH7eTz4slHjuHhdiGfK3xHVxztRvs4TcIfTBxFoxAzFuq2mq7A%3D%3D",
      {
        method: "POST",
        data: {
          clientId: "EMR",
          clientSecret: "Ndhm@EMR1",
        },
      }
    ).then((res: any) => {
      localStorage.setItem("ndhm_token", res?.data?.accessToken);
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page) {
      setPage(newPage);
      setKeyHistory((prev: any) => [...prev, lastEvaluatedKey]);
    } else {
      setPage(newPage);
      setKeyHistory((prev: any) => prev.slice(0, prev.length - 1));
      setLastEvaluatedKey(keyHistory?.[newPage]);
    }
  };

  useEffect(() => {
    if(isMobileScreen){
      setPatientData([]);
    }
    setLoading(true);
    getPatientData(patientSearch);
    handleAuth();
  }, [page, selectedClinicName]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLimit(event.target.value);
    setLastEvaluatedKey(null);
  };

  const handleMenu = (item: string) => {
    if (item === "Create ABHA") {
      setAnchorEl(null);
      setMenu(false);
      window.location.href = `https://ndhm-webapp-qa.azurewebsites.net?token=${localStorage.getItem(
        "ndhm_token"
      )}&id=${selectPatientData?.patient_id}&name=${selectPatientData?.name}`;
    }
  };

  const handleClick = () => {
    if(isMobileScreen){
      setPatientData([])
    }
    setLoading(true);
    getPatientData(patientSearch);
    patientDataCount(patientSearch);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenu(false);
  };

  const handleNewPatient = () => {
    navigate(ROUTE_NEW_PATIENT);
  };

  const handleCheckPatient = (patientId: string) => {
    axios
      .get(
        `https://ndhm-qa.azurewebsites.net/api/get-abha-user?id=${patientId}&code=gw-nnH7eTz4slHjuHhdiGfK3xHVxztRvs4TcIfTBxFoxAzFuq2mq7A==`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ndhm_token")}`,
            authType: "jwt",
          },
        }
      )
      .then((res) => {
        setshowCreateAbha(true);
        setMenu(true);
      })
      .catch((err: any) => {
        setMenu(true);
        if (err?.response?.status === 404) {
          setMenu(true);
          setshowCreateAbha(false);
        }
      });
  };

  const handleClinicChange = (event: any) => {
    setSelectedClinicName(event.target.value);
    localStorage.setItem("cAccount", event.target.value);
    setLastEvaluatedKey(null);
    setPage(0);
  };

  const handleSearchPatient = (e: any) => {
    setLastEvaluatedKey(null);
    setPatientSearch(e.target.value);
    setPage(0);
    if (e.target.value === "" && !isMobileScreen) {
      getPatientData();
      patientDataCount();
    }
    else if(e.target.value === "" && isMobileScreen){
      setPatientData([]);
      getPatientData();
    }
  };

  const showContent = (item: string) => {
    if (item === "Create ABHA" && !showCreateAbha) {
      return <Typography>Create ABHA</Typography>;
    } else if (item === "Create ABHA" && showCreateAbha) {
      return <Typography>View ABHA</Typography>;
    } else {
      return <Typography>{item}</Typography>;
    }
  };
  useEffect(() => {
    patientDataCount();
  }, [selectedClinicName]);

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } =
      scrollContainerRef.current;
      if(scrollTop !== 0){
    if (scrollTop + clientHeight > scrollHeight -1) {
        setInfiniteScrollLoader(true);
        getPatientData(patientSearch);
    }
  }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer && isMobileScreen && lastEvaluatedKey !== null) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer && isMobileScreen) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastEvaluatedKey]);

  return (
    <Box
      className="main-container-my-patient"
      sx={{ marginTop: isMobileScreen ? "10px" : "65px" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: isMobileScreen ? "0px" : "0px 24px 16px",
          flexDirection: isMobileScreen ? "column-reverse" : "row",
          margin: isMobileScreen ? "16px" : "0px",
          gap: isMobileScreen ? "16px" : "0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: isMobileScreen ? "space-between" : undefined,
            width: "100%",
          }}
        >
          <FormControl>
            <Select
              defaultValue={
                localStorage.getItem("cAccount") || selectedClinicName
              }
              onChange={handleClinicChange}
              displayEmpty
              sx={{
                width: 200,
                height: 40,
                background: "#ffff",
              }}
            >
              {clinicName?.map((clinic: any) => {
                return (
                  <MenuItem value={clinic?.value}>{clinic?.label}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* <ToggleButtonGroup
            color="primary"
            exclusive
            value={toggleValue}
            onChange={handleToggleChange}
            sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
          >
            <ToggleButton value="Svaas">Svaas</ToggleButton>
            <ToggleButton value="Non-Svaas">Non-Svaas</ToggleButton>
          </ToggleButtonGroup> */}
          {isMobileScreen && (
            <Tooltip title="Register patient">
              <Button
                variant="outlined"
                onClick={handleNewPatient}
                sx={{
                  maxWidth: "40px",
                  minWidth: "40px",
                  padding: "4px",
                  borderRadius: "8px",
                }}
              >
                <Box
                  width={{ md: 25 }}
                  component="img"
                  alt="add patient"
                  src="/images/addpatient.svg"
                />
              </Button>
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
            width: "100%",
          }}
        >
          <TextField
            onChange={(e) => handleSearchPatient(e)}
            placeholder="Search Patient by Name/Mobile No."
            value={patientSearch}
            // disabled={disabled}
            className={`mui-textfield-nopadding-small`}
            sx={{
              backgroundColor: "#ffff",
              width: isMobileScreen ? "100%" : 330,
              borderRadius: isMobileScreen ? "4px" : "8px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ margin: 0, cursor: "pointer" }}
                  onClick={() => {
                    if (patientSearch?.length > 2) {
                      handleClick();
                    }
                  }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {!isMobileScreen && (
            <Tooltip title="Register patient">
              <Button variant="contained" onClick={handleNewPatient}>
                <Box component="img" src="/images/add_new_patient.svg" />
                <Typography>New Patient</Typography>
              </Button>
            </Tooltip>
          )}
        </Box>
      </Box>
      {!isMobileScreen && (
        <Box className="table-main-container">
          <Box className="table-container" sx={{ overflow: "auto" }}>
            <TableContainer sx={{ height: "calc(100vh - 175px)" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead sx={{ background: "#EEEEF7" }}>
                  <TableRow>
                    {columns.map((column: any) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ width: column.width, background: "#EEEEF7" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={10} sx={{ textAlign: "center" }}>
                        {<CircularProgress />}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {patientData?.length > 0 && !loading ? (
                  <TableBody sx={{ background: "#ffff" }}>
                    {patientData?.map((data: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{serialNo + index}</TableCell>
                          <TableCell>{data?.name}</TableCell>
                          <TableCell>{data?.phone}</TableCell>
                          <TableCell>{data?.patient_hid}</TableCell>
                          <TableCell>{data?.doctor_name}</TableCell>
                          <TableCell>{data?.last_visit}</TableCell>
                          <TableCell>
                            {data?.upcoming_appointment_slot}
                          </TableCell>
                          <TableCell>{data?.total_visits}</TableCell>
                          <TableCell>{data?.created_at}</TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "12px",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Box
                                component="img"
                                src="/images/my_patient_calender.svg"
                              />
                              <Box
                                component="img"
                                src="/images/my_patient_prescription.svg"
                              />
                              <IconButton
                                aria-label="more"
                                disabled
                                id="long-button"
                                aria-controls={
                                  openMenu ? "long-menu" : undefined
                                }
                                aria-expanded={openMenu ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={(e) => {
                                  handleCheckPatient(data?.patient_id);
                                  setAnchorEl(e.currentTarget);
                                  setSelectedPatinetData(data);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={10} sx={{ textAlign: "center" }}>
                        {loading ? "Loading Data" : "No Data Found"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
          <TablePagination
            sx={{ background: "#ffff" }}
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={10}
            page={page}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
      <Box
        className="patient-mobile-container"
        ref={scrollContainerRef}
        sx={{ height: "calc(100vh - 250px)", overflow: "scroll" }}
      >
        {isMobileScreen && !loading ? (
          patientData?.length > 0 ? (
            <Box>
              {patientData?.map((data: any) => (
                <PatientCard
                  data={data}
                  openMenu={openMenu}
                  handleCheckPatient={handleCheckPatient}
                  setAnchorEl={setAnchorEl}
                  setSelectedPatinetData={setSelectedPatinetData}
                />
              ))}{" "}
            </Box>
          ) : (
            <Typography sx={{ textAlign: "center" }}>No Data found</Typography>
          )
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <Typography>Loading Data</Typography>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
      >
        {MenuItemList.map((item, index) => {
          return (
            <MenuItem key={index} onClick={() => handleMenu(item)}>
              {showContent(item)}
            </MenuItem>
          );
        })}
      </Menu>
      {infiniteScrollLoader &&<Box sx={{textAlign:"center"}}>{<CircularProgress />}</Box>}
    </Box>
  );
};

export default MyPatient;
