import { useDispatch, useSelector } from "react-redux";
import Card from "../../utils/Card";
import CardHeader, { renderHeaderButtons } from "../../utils/CardHeader";
import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CARD_SHADOW } from "../../utils/Constants";
import TemplateCard from "../../utils/TemplateCard";
import { EXAMINATION } from "../../../../../shared/constants";

import ReactQuill from "react-quill";
import { debounce } from "lodash";
import { setEditRxSecMobile } from "../../../../../redux/reducers/prescription.slice";
import DOMPurify from "dompurify";
import "../../../../../shared/layouts/style.css";

interface ExaminationFindingsProps {
  name: string;
  comment: string;
}

const EditorModules = {
  toolbar: [
    ["bold", "italic", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export default function ExaminationFindings(props: any) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openSaveTemplate, setOpenSaveTemplate] = useState<boolean>(false);
  const [serachExistingTemplate, setOpenUpdateExistingTemplate] =
    useState<boolean>(false);

  const openSaveTemplateMobile = props?.sectionData?.[0]?.openSaveTemplateMobile;
  const searchExistingTemplateMobile =
    props?.sectionData?.[0]?.searchExistingTemplateMobile;
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const isFocused: boolean = props.isFocused || false;
  const sectionData = props?.sectionData?.[0]?.rows[0];
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  // const [findingsValue, setFindingsValue] = useState("");

  const { setTabData } = props;
  const {
    prescription: { instantRxData },
  } = useSelector((state: any) => state.prescription);

  const SaveTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    left: "0px",
    zIndex: 1,
    height: "0px",
    width:"100%"
  };

  const AddMiniTemplateStyle = {
    position: isMobileScreen ? "absolute" : "none",
    top: "144px",
    right: "16px",
    zIndex: 1,
    height: "0px",
  };

  const findings = sectionData
    ? (sectionData.value[0] as ExaminationFindingsProps).comment
    : "";

  useEffect(() => {
    if (sectionData && sectionData.value[0]) {
      // setFindingsValue(findings);
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(EXAMINATION));
    }
  }, [isAccExpanded]);

  useEffect(() => {
    if (instantRxData?.section?.length > 0) {
      const instantTemplateData = instantRxData?.section?.filter(
        (section: any) => section?.view_type === EXAMINATION
      );
      handleSelectedTemplateData(instantTemplateData[0]);
    }
  }, [instantRxData]);

  const handleSelectedTemplateData = (item: any) => {
    if (item?.rows && item?.rows[0] && item?.rows[0]?.value) {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === EXAMINATION) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return { ...row, value: item?.rows[0]?.value };
                } else {
                  return row;
                }
              }),
            };
          } else {
            return section;
          }
        });
      });
    }
  };

  const handleButtonClick = async (imageName: string) => {
    if (imageName === "icon_save.svg") {
      if (isMobileScreen) {
        // handleButtonClickMiniTMobile(imageName); // review-code
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === EXAMINATION) {
              return {
                ...section,
                openSaveTemplateMobile: !section?.openSaveTemplateMobile,
                searchExistingTemplateMobile: false,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenSaveTemplate(!openSaveTemplate);
      setOpenUpdateExistingTemplate(false);
    } else if (imageName === "icon_add_page.svg") {
      if (isMobileScreen) {
        setTabData((prev: any) => {
          return prev.map((section: any) => {
            if (section.view_type === EXAMINATION) {
              return {
                ...section,
                openSaveTemplateMobile: false,
                searchExistingTemplateMobile:
                  !section.searchExistingTemplateMobile,
              };
            } else {
              return section;
            }
          });
        });
        return;
      }
      setOpenUpdateExistingTemplate(!serachExistingTemplate);
      setOpenSaveTemplate(false);
    }
  };

  const debouncedOnChange = useCallback(
    debounce((content: any) => {
      setTabData((prev: any) => {
        return prev.map((section: any) => {
          if (section.view_type === EXAMINATION) {
            return {
              ...section,
              rows: section.rows.map((row: any, index: number) => {
                if (index === 0) {
                  return {
                    ...row,
                    value: row.value.map((val: any, index: number) => {
                      if (index === 0) {
                        return {
                          ...val,
                          comment: content,
                        };
                      }
                      return val;
                    }),
                  };
                }
                return row;
              }),
            };
          }
          return section;
        });
      });
    }, 300), // Debounce time in milliseconds
    [] // No dependencies, only create the function once
  );

  const RenderReadOnly = () => {
    const examinationData: string = findings;
    const tempDiv = document.createElement("div");
    tempDiv.classList.add(isMobileScreen ? "free-text-mobile" : "free-text");
    const removeBreaks = /<p><br><\/p>/g;
    tempDiv.innerHTML = examinationData?.replace(removeBreaks, "");

    return (
      tempDiv.innerHTML?.length > 0 && (
        <Box>
          <Typography
            sx={{
              color: "#6F6F6F",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "160%",
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(tempDiv),
            }}
          ></Typography>
        </Box>
      )
    );
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
            position: "relative",
          }}
          title="Examination Findings"
        >
          <CardHeader
            headerImg="icon_ex_findings.svg"
            headerTitle="Examination Findings"
          >
            {renderHeaderButtons(
              ["icon_save.svg", "icon_add_page.svg"],
              handleButtonClick
            )}
          </CardHeader>
          <ReactQuill
            id="rx-examination-findings"
            theme="snow"
            placeholder="Enter clinical findings"
            modules={EditorModules}
            value={findings}
            onChange={(content, _delta, _source, editor) => {
              let newComment = content;
              if (editor.getLength() <= 1) {
                newComment = "";
              }
              if (editor.getLength() > 1500) {
                newComment = newComment.substring(0, 1500);
              }
              // setFindingsValue(newComment);
              setTabData((prev: any) => {
                return prev.map((section: any) => {
                  if (section.view_type === EXAMINATION) {
                    return {
                      ...section,
                      rows: section.rows.map((row: any, index: number) => {
                        if (index === 0) {
                          return {
                            ...row,
                            value: row.value.map((val: any, index: number) => {
                              if (index === 0) {
                                return {
                                  ...val,
                                  comment: newComment ? newComment : "",
                                };
                              }
                              return val;
                            }),
                          };
                        }
                        return row;
                      }),
                    };
                  }
                  return section;
                });
              });
            }}
            style={{ color: "#000000" }}
          />

          {openSaveTemplate && (
            <Box position="absolute" top="68px" right="79px" zIndex="1">
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {serachExistingTemplate && (
            <Box position="absolute" top="68px" right="39px" zIndex="1">
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </Card>
      )}
      {!loading && isMobileScreen && editSection && (
        <>
          <ReactQuill
            className="examination-quill-mobile"
            id="rx-examination-findings"
            theme="snow"
            placeholder="Enter clinical findings"
            modules={EditorModules}
            value={findings}
            onChange={(content) => {
              debouncedOnChange(content);
            }}
            style={{ color: "#000000", width: "100%", height: "100%" }}
          />
          {openSaveTemplateMobile && (
            <Box sx={SaveTemplateStyle}>
              <TemplateCard
                templateName="save-template"
                handleCloseButton={handleButtonClick}
                data={props.sectionData[0]}
              />
            </Box>
          )}
          {searchExistingTemplateMobile && (
            <Box sx={AddMiniTemplateStyle}>
              <TemplateCard
                handleCloseButton={handleButtonClick}
                templateName="search-template"
                data={props.sectionData[0]}
                handleSelectedTemplateData={handleSelectedTemplateData}
              />
            </Box>
          )}
        </>
      )}
      {!loading && isMobileScreen && !editSection && RenderReadOnly()}
    </>
  );
}
