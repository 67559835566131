/* eslint-disable no-var */
/* eslint-disable prefer-const */
import {  useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  Button,
  Snackbar,
  useTheme,
} from "@mui/material";


import LoginLayout from "../../../shared/layouts/LoginLayout";

import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import LoadingButton from "@mui/lab/LoadingButton";
import OtpInput from 'react-otp-input';
import FormattedTextField from "../../../shared/Utils/indianMobileFormatInput";



const ForgotPasswordPage = () => {

    const navigate = useNavigate();

    const [activeStep,setActiveStep] = useState(0);
    const [otp, setOtp] = useState('');
    const [phone,setPhone] = useState('');
    const [newPassword,setNewPassword] = useState('')
    const [confirmNewPassword,setConfirmNewPassword] = useState('')
    const [loadingSendOTP,setLoadingSendOTP] = useState(false);
    const [loadingVerifyOTP,setLoadingVerifyOTP] = useState(false);
    const [loadingSETPassword,setLoadingSETPassword] = useState(false);
    const [disableResend,setDisableResend] = useState(false);
    const [showSnackbar,setShowSnackbar] = useState(false);
    const [snackbarMessage,setSnackbarMessage] = useState('');

    const theme = useTheme();


    // const checkUserExistence = async (username) => {
    //     try {
    //         await Auth.signIn(username, 'anyPassword'); // Use a dummy password
    //         return true; // If no error, user exists (but password may be wrong)
    //     } catch (error) {
    //         console.log(error,"error")
    //         if (error.code === 'UserNotFoundException') {
    //             return false; // User does not exist
    //         }
    //         if (error.code === 'NotAuthorizedException') {
    //             return true; // User exists but password is wrong
    //         }
    //         throw error; // Other errors
    //     }
    // };
    
    // const handleSend = async () => {
    //     setLoadingSendOTP(true);
    
    //     try {
    //         const userExists = await checkUserExistence(phone);
    //         if (userExists) {
    //             // User exists, proceed with forgot password flow
    //             await Auth.forgotPassword(phone)
    //                 .then(res => {
    //                     console.log(res, "Response");
    //                     setLoadingSendOTP(false);
    //                     setActiveStep(1);
    //                 });
    //         } else {
    //             // Handle user not found scenario
    //             console.log("User not found");
    //             setLoadingSendOTP(false);
    //             // Additional error handling
    //         }
    //     } catch (error) {
    //         setLoadingSendOTP(false);
    //         setShowSnackbar(true);
    //         setSnackbarMessage(error.message || 'An error occurred');
    //     }
    // };
    
    const handleSend = async () => {
        setLoadingSendOTP(true);
    
        try {
            // Attempt to send a password reset request
            await Auth.forgotPassword(`+91${phone.replace(/\s/g, "")}`)
                .then(res => {
                   
                    setActiveStep(1); // Proceed to next step in UI if necessary
                })
                .catch(err => {
                 
            if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
                setLoadingSendOTP(false);
                        setShowSnackbar(true);
                        setSnackbarMessage("User Doesn't Exists!");
                return  // User does not exist
            }

            if (err.code === 'LimitExceededException') {
                setLoadingSendOTP(false);
                        setShowSnackbar(true);
                        setSnackbarMessage("Limit Exceed Try after sometime!");
                return  // User does not exist
            }
            
          
            else{
                setLoadingSendOTP(false);
                        setShowSnackbar(true);
                        setSnackbarMessage(err);
                return  // User does not exist
            }
          
                });
        } catch (error) {
            console.error("An error occurred:", error);
        } 
    };
    
    
    
       
    

    const handleOTPSubmit = async ()=>{
        setActiveStep(2)
    }

    const handleResendOTP = ()=>{
        setDisableResend(true);
        setTimeout(()=>{
            setDisableResend(false);
        },5000);

        handleSend();
    }

    const handleSetNewPassword = async ()=>{
        setLoadingSETPassword(true);
        let test  = /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
        if(test.test(newPassword)){
             await Auth.forgotPasswordSubmit(`+91${phone.replace(/\s/g, "")}`,otp,newPassword).then(res=>{
                setLoadingSETPassword(false)
                navigate('/login')
            }).catch(err=>{
                // console.log(err)
                setSnackbarMessage("Invalid OTP/Password");
                setShowSnackbar(true);
                setLoadingSETPassword(false);
                setTimeout(()=>{
                  
                    setActiveStep(1);
                  
                    setOtp('');
                    setNewPassword('');
                    setConfirmNewPassword('');
                },3000)
    
                
            })
        }
else{
    setShowSnackbar(true);
    setSnackbarMessage("Enter Correct Password Format");
    setLoadingSETPassword(false);
}
        
    }
    const formatIndianMobileNumber = (num) => {
        // Remove all non-digit characters
        const digits = num.replace(/\D/g, '');
        // Format the number
        return digits.replace(/(\d{5})(\d{1,5})?/, '$1 $2').trim();
    };
    function changeUsername(e) {
        e.preventDefault();
       setPhone(formatIndianMobileNumber(e.target.value))
      }

    return (
        <>
            <Snackbar  ContentProps={{ sx: { background: "red" , marginTop: "40px" } }}
                open={showSnackbar}  
                onClose={()=>setShowSnackbar(false)} 
                autoHideDuration={6000} 
                message={snackbarMessage}  
                anchorOrigin={{ vertical:'top', horizontal:'right' }}
                
            />
          <LoginLayout>
            {activeStep===0 &&
                <>
                    <Typography
                        variant="h2"
                        fontSize={2}
                        gutterBottom
                        sx={{ marginBottom: 1 }}
                    >
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", display: "inline" }}
                        >
                            Forget Password
                        </Typography>
                    
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{marginBottom: 6}}
                    >
                        You will receive an OTP on registered mobile number to reset password
                    </Typography>
                    <Stack spacing={2} mb={2}>
                       
                       
                    <FormattedTextField
            label="Mobile Number"
            // type="number"
            variant="outlined"
            value={phone}
            onChange={changeUsername}
          />
                       
                       
                    </Stack>
                    
                    <Button
                    variant="text"
                    sx={{width:'fit-content',my:1}}
                      onClick={()=>{navigate("/login")}}
                    >
                    <Typography textTransform={'none'}>Back to Sign in </Typography>
                    </Button>
            
                    <LoadingButton
                        variant="contained"
                        loading={loadingSendOTP}
                        disabled={phone.length === 11 ? false : true}
                        sx={{ marginBottom: 3, marginTop: 1 }}
                        onClick={() => {
                          handleSend();
                        }}
                    >
                        SEND
                    </LoadingButton>
            
                    <Typography fontSize={12} 
                        // color={theme.palette.white.dark}
                    >
                    By continuing, you agree that we create an account for you (unless
                    already created), and accept our{" "}
                    <Typography
                        fontSize={12}
                        color={theme.palette.blue.lightHighlight}
                        sx={{
                        textDecoration: "underline",
                        display: "inline",
                        cursor: "pointer",
                        }}
                        onClick={() => {
                            window.open("https://svaaswellness.com/terms-conditions/")
                          }}
                    >
                        Terms and Conditions
                    </Typography>{" "}
                    and{" "}
                    <Typography
                        fontSize={12}
                        color={theme.palette.blue.lightHighlight}
                        sx={{
                        textDecoration: "underline",
                        display: "inline",
                        cursor: "pointer",
                        }}
                        onClick={() => {
                            window.open("https://svaaswellness.com/privacy-policy/")
                          }}
                    >
                        Privacy Policy
                    </Typography>
                    .
                    </Typography>
                </>
            }
            {activeStep===1 && 
                <>
                        <Typography
                            variant="h2"
                            fontSize={2}
                            gutterBottom
                            sx={{ marginBottom: 1 }}
                        >
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", display: "inline" }}
                        >
                            Forget Password
                        </Typography>
                        
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{marginBottom: 6}}
                        >
                            You will receive an OTP on registered mobile number to reset password
                        </Typography>
                        <Stack spacing={2} mb={2}>
                        <OtpInput
                       
                            value={otp}
                            onChange={(e)=>{
                                const onlyNums = e.replace(/[^0-9]/g, '');
                                if(onlyNums.length > 10){
                                    return
                                }else{
                                    setOtp(onlyNums)
                                }
                            }}
                            numInputs={6}
                          
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{fontSize:'20px',padding:'11px',marginRight:'6px'}}
                        />
                        </Stack>
                        
                        <Button
                        variant="text"
                        sx={{width:'fit-content',my:1}}
                        onClick={handleResendOTP}
                        disabled={disableResend}
                        >
                            <Typography textTransform={'none'}>Resend Code </Typography>
                        </Button>
                
                        <LoadingButton
                            variant="contained"
                            loading={loadingVerifyOTP}
                            disabled={otp.length<6}
                            sx={{ marginBottom: 3, marginTop: 1 }}
                            onClick={() => {
                              handleOTPSubmit();
                            }}
                        >
                            VERIFY
                        </LoadingButton>
                
                        <Typography fontSize={12} 
                            // color={theme.palette.white.dark}
                        >
                        By continuing, you agree that we create an account for you (unless
                        already created), and accept our{" "}
                        <Typography
                            fontSize={12}
                            color={theme.palette.blue.lightHighlight}
                            sx={{
                            textDecoration: "underline",
                            display: "inline",
                            cursor: "pointer",
                            }}
                            onClick={() => {
                                window.open("https://svaaswellness.com/terms-conditions/")
                              }}
                        >
                            Terms and Conditions
                        </Typography>{" "}
                        and{" "}
                        <Typography
                            fontSize={12}
                            color={theme.palette.blue.lightHighlight}
                            sx={{
                            textDecoration: "underline",
                            display: "inline",
                            cursor: "pointer",
                            }}
                            onClick={() => {
                                window.open("https://svaaswellness.com/privacy-policy/")
                              }}
                        >
                            Privacy Policy
                        </Typography>
                        .
                        </Typography>
                </>
            }
            {activeStep===2 && 
                <>
                        <Typography
                            variant="h2"
                            fontSize={2}
                            gutterBottom
                            sx={{ marginBottom: 1 }}
                        >
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", display: "inline" }}
                        >
                            Reset Password
                        </Typography>
                        
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{marginBottom: 6}}
                        >
                            Set a new password 
                        </Typography>
                        <Stack spacing={2} mb={2}>
                            <TextField label={'New Password'} type="password" value={newPassword} onChange={(e)=>{setNewPassword(e.target.value)}}/>
                           
                            <TextField label={'Re-type new password'} type="password" value={confirmNewPassword} onChange={(e)=>{setConfirmNewPassword(e.target.value)}}/>
                        </Stack>
                        
                        <Typography
                           
                           fontSize={12}
                           
                           sx={{ marginTop:"-10px" }}
                       > <small>Password must be min 8 character and contains atleast 1 number, 1 special character, 1 uppercase letter, 1 lowercase letter</small>
                       </Typography>
                        <LoadingButton
                            variant="contained"
                            loading={loadingSETPassword}
                            disabled={newPassword.length===0 || confirmNewPassword.length===0 || newPassword!==confirmNewPassword}
                            sx={{ marginBottom: 3, marginTop: 1 }}
                            onClick={() => {
                              handleSetNewPassword();
                            }}
                        >
                            SET
                        </LoadingButton>
                
                        <Typography fontSize={12} 
                            // color={theme.palette.white.dark}
                        >
                        By continuing, you agree that we create an account for you (unless
                        already created), and accept our{" "}
                        <Typography
                            fontSize={12}
                            color={theme.palette.blue.lightHighlight}
                            sx={{
                            textDecoration: "underline",
                            display: "inline",
                            cursor: "pointer",
                            }}
                            onClick={() => {
                                window.open("https://svaaswellness.com/terms-conditions/")
                              }}
                        >
                            Terms and Conditions
                        </Typography>{" "}
                        and{" "}
                        <Typography
                            fontSize={12}
                            color={theme.palette.blue.lightHighlight}
                            sx={{
                            textDecoration: "underline",
                            display: "inline",
                            cursor: "pointer",
                            }}
                            onClick={() => {
                                window.open("https://svaaswellness.com/privacy-policy/")
                              }}
                        >
                            Privacy Policy
                        </Typography>
                        .
                        </Typography>
                </>
            }
                    
            </LoginLayout>
        </>
      );
}

export default ForgotPasswordPage;