import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import utc from 'dayjs/plugin/utc'
import { convertUTCTimeToISTTime } from '../../../utils/Utilities';

const BMITable = () => {

    const columns = [
        { 
            id: 'date', 
            label: 'Date', 
            minWidth: 170,
            align:'left'
        },
        { 
            id: 'time', 
            label: 'Time', 
            minWidth: 100,
            align:'left'
        },
        {
            id: 'height',
            label: 'Height(cm)',
            minWidth: 170,
            align:'left'
        },
        {
            id: 'weight',
            label: 'Weight(kg)',
            minWidth: 170,
            align:'left'
        },
        {
            id: 'bmi',
            label: 'BMI',
            minWidth: 170,
            align:'left'
        },
        {
            id: 'type',
            label: 'Type',
            minWidth: 170,
            align:'center'
        },
    ];

    // const rows = [
    //     createData('12/10/2023', '12:01:32', "173", "45.4","15.2","underweight"),
    //     createData('12/10/2023', '12:01:32', "173", "45.4","15.2","normal"),
    //     createData('12/10/2023', '12:01:32', "173", "45.4","15.2","overweight"),
    //     createData('12/10/2023', '12:01:32', "173", "45.4","15.2","underweight"),
    //     createData('12/10/2023', '12:01:32', "173", "45.4","15.2","overweight"),
    //     createData('12/10/2023', '12:01:32', "173", "45.4","15.2","normal"),
    //     createData('12/10/2023', '12:01:32', "173", "45.4","15.2","underweight"),
        
    // ];

    function createData(date, time, height, weight,bmi,type) {
        return { date, time, height, weight, bmi,type };
    }

    const theme = useTheme();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows,setRows] = useState([]);
    dayjs.extend(utc)

    const {
        pastVitalsData : {data,error,pastVitalsLoader}
    } = useSelector((state) => state.prescription);

    useEffect(()=>{
        if(data?.VITALS?.length){
            let dataArray = data?.VITALS?.find(elem=>elem.name=='bmi');
            if(dataArray?.values){
                let results = [];
                dataArray?.values?.forEach(row=>{
                    let value = row?.value
                    if(value){
                        results.push(
                            createData(
                                dayjs(row?.date,'YYYY-MM-DD').format('DD/MM/YYYY'),
                                convertUTCTimeToISTTime(row?.time),
                                row?.height,
                                row?.weight,
                                value,
                                row?.type
                            )
                        )
                    }
                })
                setRows(results);
            }
        }
    },[data])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    
    const getTag = (type) => {
        switch(type){
            case 'underweight':
                return (
                    <Box px={2} py={0.2} sx={{display:'flex',gap:1,justifyContent:'center',alignItems:'center',backgroundColor:'#F2F3FF',borderRadius:'10px'}}>
                        <Box
                            width={'6px'}
                            height={'6px'}
                            sx={{
                                borderRadius:'50%',
                                backgroundColor:'#494F9D'
                            }}
                        />
                        <Typography variant='caption'>{type[0]?.toUpperCase() + type?.slice(1)}</Typography>
                    </Box>
                )
            case 'normal':
                return (
                    <Box px={2} py={0.2} sx={{display:'flex',gap:1,justifyContent:'center',alignItems:'center',backgroundColor:'#E8FDF3',borderRadius:'10px'}}>
                        <Box
                            width={'6px'}
                            height={'6px'}
                            sx={{
                                borderRadius:'50%',
                                backgroundColor:'#12B76A'
                            }}
                        />
                        <Typography variant='caption'>{type[0]?.toUpperCase() + type?.slice(1)}</Typography>
                    </Box>
                )
            case 'overweight':
                return (
                    <Box px={2} py={0.2} sx={{display:'flex',gap:1,justifyContent:'center',alignItems:'center',backgroundColor:'#FFF0E9',borderRadius:'10px'}}>
                        <Box
                            width={'6px'}
                            height={'6px'}
                            sx={{
                                borderRadius:'50%',
                                backgroundColor:'#FF7A3C'
                            }}
                        />
                        <Typography variant='caption'>{type[0]?.toUpperCase() + type?.slice(1)}</Typography>
                    </Box>
                )
            case 'obese':
                return (
                    <Box px={2} py={0.2} sx={{display:'flex',gap:1,justifyContent:'center',alignItems:'center',backgroundColor:'#FFE7E7',borderRadius:'10px'}}>
                        <Box
                            width={'6px'}
                            height={'6px'}
                            sx={{
                                borderRadius:'50%',
                                backgroundColor:'#FB1D20'
                            }}
                        />
                        <Typography variant='caption'>{type[0]?.toUpperCase() + type?.slice(1)}</Typography>
                    </Box>
                )
        }
    }


    return (
        <Paper sx={{ width: '100%',height:'100%'}}>
            <TableContainer sx={{height:'100%'}}>
                <Table stickyHeader aria-label="sticky table" sx={{height:'fit-content' ,overflowY:'auto'}}>
                    <TableHead >
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            // style={{ minWidth: column.minWidth }}
                            sx={{backgroundColor:theme.palette.blue.light}}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                    const value = row[column.id];

                                    if(column.id ==='type'){
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {getTag(value)}
                                            </TableCell>
                                        )
                                    }
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        
                    }}
                /> */}
            </TableContainer>
            
            
        </Paper>
    )
}

export default BMITable