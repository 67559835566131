import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Document, Page, pdfjs } from "react-pdf";
import "../../../../shared/layouts/style.css";

import PdfIcon from "../../../../shared/icons/PdfIcon";

type Props = {
  pdfLink: string;
  isMobileScreen : boolean;
};

export default function PdfReport({ pdfLink, isMobileScreen }: Props): JSX.Element {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    // Update worker path on component mount
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  }, []);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <>
      {!isMobileScreen && <Document
        className="pdf-container"
        file={pdfLink}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page scale={5.0} pageNumber={pageNumber} />
      </Document>}
      <Box position="relative" top={isMobileScreen ? "67px" : "-40px"} left="10px">
        <PdfIcon />
      </Box>
    </>
  );
}
