import { ComponentType } from "react";
import AdditionalNotes from "../tabcomponents/RX/AdditionalNotes";
import Advice from "../tabcomponents/RX/Advice";
import ChiefComplaints from "../tabcomponents/RX/ChiefComplaints";
import Diagnosis from "../tabcomponents/RX/Diagnosis";
import ExaminationFindings from "../tabcomponents/RX/ExaminationFindings";
import FollowUp from "../tabcomponents/RX/FollowUp";
import LabInvestigation from "../tabcomponents/RX/LabInvestigation";
import Medication from "../tabcomponents/RX/Medication";
import Referral from "../tabcomponents/RX/Referral";
import Vitals from "../tabcomponents/RX/Vitals";
import PatientHistory from "../tabcomponents/RX/patienthistory/PatientHistory";
import DiabetesAssessment from "../tabcomponents/RX/DiabetesAssessment";
import GeneralPhysicalExamination from "../tabcomponents/RX/GeneralPhysicalExamination";
import SystemicExamination from "../tabcomponents/RX/SystemicExamination";
import FootExam from "../tabcomponents/RX/FootExam";
import InvestigationMonitoring from "../tabcomponents/RX/InvestigationMonitoring";
import KneeExamination from "../tabcomponents/RX/KneeExamination";
import PhysicalFindings from "../tabcomponents/RX/PhysicalFindings";
import PresentingComplaints from "../tabcomponents/RX/PresentingComplaints";
import ObstetricHistory from "../tabcomponents/RX/obstetrichistory/ObstetricHistory";
import OBGHistory from "../tabcomponents/RX/obghistory/OBGHistory";
import OBGNotes from "../tabcomponents/RX/obgnotes/OBGNotes";

const CARD_SHADOW = "rgba(0.35, 0.35, 0.35, 0.35) 3px 3px 3px";
const VITALS_INPUT_VALIDATE_WHOLE_NUMBER = /^(?:[1-9][0-9]{0,1}|100|[1-4][0-9]{2}|500|)$/;
const VITALS_INPUT_VALIDATE_DECIMAL_VALUE = /^(?:500(?:\.0+)?|(?:(?:[1-9]|[1-9]\d|500)(?:\.\d+)?|\.\d*[1-9]\d*))?$/;

export interface SectionComponentStruct {
    key: string;
    name: string,
    disableView?: boolean,
    header_icon: string,
    component: ComponentType<JSX.Element>;
}

const VITALS_VALIDATIONS = [
    {
        "key": "height",
        "min": 15,
        "max": 300,
        "regex": /^[0-9\b]+$/
    },
    {
        "key": "weight",
        "min": 0.2,
        "max": 500,
        "isFloat": true,
        "rounded": 2,
        "regex": /^[0-9\b]+$/

    },
    {
        "key": "bmi",
        "min": 8,
        "max": 150,
        "isFloat": true,
        "rounded": 2,
        "regex": /^[0-9\b]+$/

    },
    {
        "key": "temperature",
        "min": 50,
        "max": 115,
        "isFloat": true,
        "rounded": 1,
        "regex": /^[0-9\b]+$/

    },
    {
        "key": "spo2",
        "min": 20,
        "max": 100,
        "regex": /^[0-9\b]+$/

    },
    {
        "key": "rr",
        "min": 1,
        "max": 100,
        "regex": /^[0-9\b]+$/
    },
    {
        "key": "prhr",
        "min": 25,
        "max": 600,
        "regex": /^[0-9\b]+$/

    },
    {
        "key": "Systolic",
        "min": 40,
        "max": 400,
        "regex": /^[0-9\b]+$/

    }
    , {
        "key": "Diastolic",
        "min": 20,
        "max": 400,
        "regex": /^[0-9\b]+$/
    }
    , {
        "key": "fbs",
        "min": 20,
        "max": 400,
        "regex": /^[0-9\b]+$/
    }
    , {
        "key": "rbs",
        "min": 20,
        "max": 400,
        "regex": /^[0-9\b]+$/
    }
    , {
        "key": "bspp",
        "min": 20,
        "max": 400,
        "regex": /^[0-9\b]+$/
    }
    , {
        "key": "waist",
        "min": 20,
        "max": 400,
        "regex": /^[0-9\b]+$/
    }
    , {
        "key": "hip",
        "min": 20,
        "max": 400,
        "regex": /^[0-9\b]+$/
    }
    , {
        "key": "whr",
        "min": 20,
        "max": 400,
        "regex": /^[0-9\b]+$/
    }
];

const dropDownBgOverride = (state: any) => {
    return {
        backgroundColor: state.selected
            ? "#F2F3FF !important"
            : "",
        "&:hover, &:focus": {
            backgroundColor: "#F2F3FF !important",
        }
    }
}

const SectionComponents: SectionComponentStruct[] = [
    {
        key: "VITALS",
        name: "Vitals",
        header_icon: 'icon_vitals.svg',
        component: Vitals,
    },
    {
        key: "COMPLAINTS",
        name: "Chief Complaints",
        header_icon: "icon_chief_complaints.svg",
        component: ChiefComplaints,
    },
    {
        key: "PATIENT_HISTORY",
        name: "Patient History",
        header_icon: "icon_patient_history.svg",
        component: PatientHistory,
    },
    {
        key: "DIAGNOSIS",
        name: "Diagnosis",
        header_icon: "icon_diagnosis.svg",
        component: Diagnosis,
    },
    {
        key: "EXAMINATION",
        name: "Examination Findings",
        header_icon: "icon_ex_findings.svg",
        component: ExaminationFindings,
    },
    {
        key: "MEDICATION",
        name: "Medication",
        header_icon: "icon_medication.svg",
        component: Medication,
    },
    {
        key: "INVESTIGATIONS",
        name: "Lab Investigation",
        header_icon: "icon_lab_inv.svg",
        component: LabInvestigation,
    },
    {
        key: "ADVICE",
        name: "Advice",
        header_icon: "icon_advice.svg",
        component: Advice,
    },
    {
        key: "REFERRAL",
        name: "Referral",
        header_icon: "icon_referral.svg",
        component: Referral,
    },
    {
        key: 'FOLLOW_UP',
        name: "Follow Up",
        header_icon: "icon_follow_up.svg",
        component: FollowUp,
    },
    {
        key: "ADVICE_NOTES",
        name: "Additional Notes",
        header_icon: "icon_addnl_notes.svg",
        component: AdditionalNotes
    },
    {
        key: "DIABETES_ASSESSMENT",
        name: "Diabetes Assessment",
        header_icon: "icon_diabetes_assessment.svg",
        component: DiabetesAssessment
    },
    {
        key: "GENERAL_PHYSICAL_EXAMINATION",
        name: "General Physical Examination",
        header_icon: "icon_general_physical_examination.svg",
        component: GeneralPhysicalExamination
    },
    {
        key: "SYSTEMIC_EXAMINATION",
        name: "Systemic Examination",
        header_icon: "icon_systemic_examination.svg",
        component: SystemicExamination
    },
    {
        key: "FOOT_EXAM",
        name: "Foot Exam",
        header_icon: "icon_foot_exam.svg",
        component: FootExam
    },
    {
        key: "INVESTIGATION_MONITORING",
        name: "Investigation Monitoring",
        header_icon: "icon_investigation_monitoring.svg",
        component: InvestigationMonitoring
    },
    {
        key: "KNEE_EXAMINATION",
        name: "Knee Examination",
        header_icon: "icon_knee_examination.svg",
        component: KneeExamination
    },
    {
        key: "PHYSICAL_FINDING",
        name: "Physical Finding",
        header_icon: "icon_physical_findings.svg",
        component: PhysicalFindings
    },
    {
        key: "PRESENTING_COMPLAINTS",
        name: "Presenting Complaints",
        header_icon: "icon_chief_complaints.svg",
        component: PresentingComplaints
    },
    {
        key: "OBSTETRIC_HISTORY",
        name: "Obstetric History",
        header_icon: "icon_addnl_notes.svg",
        component: ObstetricHistory
    },
    {
        key: "OBG_HISTORY",
        name: "OBG History",
        header_icon: "icon_obg_history.svg",
        component: OBGHistory,
    },
    {
        key: "OBG_NOTES",
        name: "OBG Notes",
        header_icon: "icon_obg_notes.svg",
        component: OBGNotes,
    },
]

export {
    CARD_SHADOW,
    VITALS_VALIDATIONS,
    VITALS_INPUT_VALIDATE_DECIMAL_VALUE,
    VITALS_INPUT_VALIDATE_WHOLE_NUMBER,
    dropDownBgOverride,
    SectionComponents
}   