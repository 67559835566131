/* eslint-disable prefer-const */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
  Tooltip,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import Card from "../../../utils/Card";
import CardHeader from "../../../utils/CardHeader";
import { useEffect, useReducer, useState } from "react";

import { CARD_SHADOW } from "../../../utils/Constants";
import { styled } from "@mui/system";
import Slider from "react-slick";
import {
  ALLERGIES,
  FAMILY_HISTORY,
  MEAL_PREFERENCE,
  MEDICAL_HISTORY,
  MEDICAL_HISTORY_DIABETES,
  PATIENT_HISTORY,
  PHYSICAL_ACTIVITY,
  SOCIAL_HISTORY,
  SURGICAL_HISTORY,
} from "../../../../../../shared/constants";
import { Next, Prev } from "../patienthistory/utils/arrows";
import { isPatientHistoryEmpty } from "../patienthistory/utils/shared";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditRxSecMobile,
  setPrevPrescriptionDetails,
  setToggleRepopulateTable,
} from "../../../../../../redux/reducers/prescription.slice";
import { TabComponent, allyProps } from "../../../RightPanel";
import Gravida from "./Gravida";
import Abortion from "./Abortion";
import Ectopic from "./Ectopic";

const patientHistoryProps = {
  ["Gravida"]: false,
  ["Abortion"]: false,
  ["Ectopic"]: false,
};

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.body}`]: {
    padding: "16px",
    paddingLeft: 0,
    border: "none",
  },
});

const StyledChip = styled(Chip)({
  color: "#494E9D",
  backgroundColor: "#F2F3FF",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "18px",
  borderRadius: "100px",
  letterSpacing: "0.16px",
  marginRight: "13px",
  cursor: "pointer",
  userSelect: "none",
});

const EditButton = styled(Button)({
  width: 93,
  height: 32,
  border: "1px solid #494E9D",
  borderRadius: 4,
  color: "#494E9D",
  textTransform: "none",
});

const EmptyState: any = {
  MEDICAL_HISTORY: "No known significant medical history",
  FAMILY_HISTORY: "No known significant family history",
  SURGICAL_HISTORY: "No known significant surgical history",
  ALLERGIES: "No known allergies",
  SOCIAL_HISTORY: "No known significant social history",
  MEAL_PREFERENCE: "No known significant meal preference",
  PHYSICAL_ACTIVITY: "No known significant physical activity",
  DIABETES_MEDICATION_HISTORY: "No known significant medical history diabetes",

};

const initState = {
  marriage:'unmarried',
  marriedSince:'',
  marriageComments:'',
  sexualStatus:'not active',
  gravida:0,
  para:0,
  live:0,
  abortion:0,
  ectopic:'yes',
  ectopicComments:''
}

export default function PatientHistory(props: any) {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [showHistory, setShowHistory] = useState(patientHistoryProps);
  const [toEdit, setEdit] = useState<boolean>();
  const [tabActive, setTabActive] = useState<number>(0);

  const sectionData = props.sectionData[0];
  const setTabData = props.setTabData;

  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isFocused: boolean = props.isFocused || false;
  const editSection = props?.editSection || false;
  const isSectionEmpty = props?.isSectionEmpty;
  const isAccExpanded = props?.sectionData?.[0]?.isExpanded;

  const {
    prescription: { prevPrescriptionDetails, rePopulateTable },
  } = useSelector((state: any) => state.prescription);

  const prevPatientHistory = prevPrescriptionDetails?.find(
    (section: any) => section.view_type === PATIENT_HISTORY
  );

  const [state,setState] = useReducer((state:any,action:any)=>{
    return {
      ...state,
      ...action
    }
  },initState)

  useEffect(() => {
    if (sectionData) {
      if (isSectionEmpty && prevPatientHistory && rePopulateTable) {
        if (!isPatientHistoryEmpty(prevPatientHistory)) {
          setTabData((prev: any) => {
            return prev.map((section: any) => {
              if (section.view_type === PATIENT_HISTORY) {
                return { ...prevPatientHistory };
              }
              return { ...section };
            });
          });
          dispatch(setToggleRepopulateTable(false));
        }
      } else {
        dispatch(setToggleRepopulateTable(false));
        setData(sectionData);
      }

      rePopulateTable && dispatch(setToggleRepopulateTable(false));

      if (isSectionEmpty && !isFocused) {
        setShowHistory(() => {
          return {
            ["Gravida"]: false,
            ["Abortion"]: false,
            ["Ectopic"]: false,
          };
        });
      }
      setLoading(false);
    }
  }, [sectionData]);

  useEffect(() => {
    if (!isFocused) {
      setEdit(false);
    }
  }, [isFocused]);

  useEffect(() => {
    if (
      isSectionEmpty &&
      !data?.rows?.some((row: any) => row.disable_value === true) &&
      !rePopulateTable
    ) {
      setEdit(true);
    }
  }, [isSectionEmpty]);

  useEffect(() => {
    if (
      data &&
      isPatientHistoryEmpty(data) &&
      !data?.rows?.some((row: any) => row.disable_value === true) &&
      !rePopulateTable
    ) {
      setEdit(true);
    }
  }, [toEdit]);

  useEffect(() => {
    if (isAccExpanded && isSectionEmpty && isMobileScreen) {
      dispatch(setEditRxSecMobile(PATIENT_HISTORY));
    }
  }, [isAccExpanded]);

  const sliderSetting = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: (
      <Prev
        customStyles={{
          zIndex: "20",
          width: 24,
          height: 24,
          position: "absolute",
          left: "101%",
        }}
      />
    ),
    nextArrow: (
      <Next
        customStyles={{
          width: 24,
          height: 24,
          position: "absolute",
          right: "-55px",
        }}
      />
    ),
  };

  const sliderMobileSetting = {
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: (
      <Prev
        customStyles={{
          zIndex: "20",
          width: 20,
          height: 20,
          position: "absolute",
          left: "88%",
          top: "-10px",
        }}
      />
    ),
    nextArrow: (
      <Next
        customStyles={{
          width: 20,
          height: 20,
          position: "absolute",
          right: "-12px",
          top: "-10px",
        }}
      />
    ),
  };

  const isFieldDisabled = () => {
    const disabledFields = data?.rows?.filter(
      (row: any) => row.disable_value === true
    );

    if (disabledFields?.length === 0) return ShowEditableContent();

    const viewTypes = disabledFields?.map((row: any) => row.view_type);

    return (
      <Box
        sx={{
          color: "#959595",
          fontWeight: 400,
          lineHeight: "132%",
        }}
        component="span"
      >
        {viewTypes?.map((view: any) => EmptyState[view]).join(", ")}
      </Box>
    );
  };

  const renderComplaints = (view_type: string, value: Array<any>) => {
    const EmptyValueLabel = () => {
      return (
        <StyledTableCell
          sx={{
            display: "flex",
            flexDirection: "column",
            borderBottom: isMobileScreen ? "none" : "1px solid #eee",
            padding: isMobileScreen ? "8px 8px 8px 0px" : "16px",
          }}
          key={view_type}
        >
          <Box
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "132%",
              marginBottom: "5px",
            }}
            component="span"
          >
            {EmptyState[view_type]}
          </Box>
        </StyledTableCell>
      );
    };

    if (
      view_type === SOCIAL_HISTORY &&
      value?.every((val) => val?.status?.length === 0)
    ) {
      return <EmptyValueLabel />;
    }

    if (value?.length > 0) {
      return value?.map((item: any) => {
        let { name, status, since, when, category } = item;
        let label, icons;

        switch (view_type) {
          case MEDICAL_HISTORY:
            label = `${status}${
              status !== "Never Had"
                ? `${status.length !== 0 ? ` | ` : ""} ${
                    status === "Resolved" ? when : since
                  }`
                : ""
            }`;
            break;
          case FAMILY_HISTORY:
            label = (
              <Tooltip arrow title={item.relation.join(", ")}>
                {item.relation.length <= 2
                  ? item.relation.join(", ")
                  : `${item.relation.slice(0, 2).join(", ")}...`}
              </Tooltip>
            );
            break;
          case SURGICAL_HISTORY:
            label = when;
            break;
          case ALLERGIES:
            label = category;
            break;
          case SOCIAL_HISTORY:
            if (status?.length !== 0) {
              label = status;
            }
            icons = [...value.map((elem) => elem.name)];
            break;
          case MEAL_PREFERENCE:
            name = item.state
            break;
          case PHYSICAL_ACTIVITY:
            name = item.state
            break;
        }

        const content = (
          <>
            <Box
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "132%",
                marginBottom: "5px",
              }}
              component="span"
            >
              {name}
            </Box>
            {label && (
              <Box
                sx={{
                  color: "#878787",
                  fontWeight: 400,
                  lineHeight: "132%",
                }}
                component="span"
              >
                {label}
              </Box>
            )}
          </>
        );

        return (
          <>
            <StyledTableCell
              className="histories"
              sx={{
                display: "flex",
                flexDirection: "column",
                borderBottom: isMobileScreen ? "none" : "1px solid #eee",
                padding: isMobileScreen ? "8px 8px 8px 0px" : "16px",
                maxWidth:  !isMobileScreen ? "200px" : undefined
              }}
              key={name}
            >
              {Array.isArray(icons) && icons.length !== 0 ? (
                <Box
                  className="SociakHistory"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <span style={{ marginRight: "9px" }}>
                    <img
                      src={`/images/${
                        icons.filter((iName: any) => iName === name)[0]
                      }.svg`}
                    />
                  </span>
                  <Box
                    className="SociakHistory"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: isMobileScreen
                        ? "space-between"
                        : "center",
                    }}
                  >
                    {content}
                  </Box>
                </Box>
              ) : (
                content
              )}
            </StyledTableCell>
          </>
        );
      });
    }

    return <EmptyValueLabel />;
  };

  const getValueContainerWidth = () => {
    const tableRow: any = document.querySelector(
      "#preview-medical-histories tr"
    );
    const labelCell: any = tableRow?.querySelector(`td`);
    const width: any = tableRow?.clientWidth - labelCell?.clientWidth;

    return width;
  };

  const ShowTableView = () => {
    return (
      <Box>
        <TableContainer
          sx={{
            boxShadow: "none",
            overflow: "hidden",
          }}
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-label="preview-medical-histories"
            id="preview-medical-histories"
          >
            <TableBody>
              {sectionData?.rows?.map((pHistory: any) => {
                const { header, view_type, value } = pHistory;
                return (
                  <>
                    <TableRow
                      key={view_type}
                      sx={{ borderBottom: "1px solid #E6E6E6" }}
                    >
                      <StyledTableCell id={header} align="left">
                        <StyledChip
                          clickable={false}
                          sx={{
                            width: 157,
                            height: 35,
                            borderRadius: 24,
                            fontWeight: 600,
                            lineHeight: "19px",
                            letterSpacing: "-0.28px",
                            cursor: "auto",
                          }}
                          label={header}
                        />
                      </StyledTableCell>
                      {Array.isArray(value) && value.length > 4 ? (
                        <Box
                          key={view_type + " values"}
                          sx={{
                            width: !isNaN(getValueContainerWidth())
                              ? getValueContainerWidth() - 50
                              : 650,
                            maxWidth: getValueContainerWidth(),
                          }}
                        >
                          <Slider {...sliderSetting}>
                            {renderComplaints(view_type, value)}
                          </Slider>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          {renderComplaints(view_type, value)}
                        </Box>
                      )}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const validateInput = (value:any,type:string)=>{
    switch(type){
        case 'marriedSince':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'marriageComments':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'ectopicComments':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'gravida':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'para':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'live':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        case 'abortion':
            // eslint-disable-next-line no-constant-condition
            if(true){
                return true;
            }else{
                return false;
            }
        default:
            return false;
    }
  }

  const ShowEditableContent = () => {
    const GravidaRef = (
      <Gravida
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === MEDICAL_HISTORY
        )}
      />
    );
    const AbortionRef = (
      <Abortion
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === FAMILY_HISTORY
        )}
      />
    );
    const EctopicRef = (
      <Ectopic
        setTabData={setTabData}
        sectionData={data?.rows.filter(
          (section: any) => section.view_type === SURGICAL_HISTORY
        )}
      />
    );
    
    if (!isMobileScreen) {
      return (
        <>

          <Box display={'flex'} flexDirection={'column'} gap={3} mb={2}>
            <Box display={"flex"} gap={2}>
              <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="marriage"
                  row
                  value={state.marriage}
                  onClick={(e:any)=>{
                    if(e?.target?.value){
                      setState({marriage:e.target.value})
                    }
                  }}
              >
                  <FormControlLabel value="married" control={<Radio />} label="Married" />
                  <FormControlLabel value="unmarried" control={<Radio />} label="Unmarried" />
                  <FormControlLabel value="divorced" control={<Radio />} label="Divorced" />
              </RadioGroup>
              <TextField
                  label={'Married Since'}
                  value={state.marriedSince}
                  onChange={(e)=>{
                      if(validateInput(e.target.value,'marriedSince')){
                          setState({
                            marriedSince:e.target.value
                          })
                      }
                  }}
                  disabled={state.marriage!=='married'}
              />
              <TextField
                  label={'Comments'}
                  value={state.marriageComments}
                  onChange={(e)=>{
                      if(validateInput(e.target.value,'marriageComments')){
                          setState({
                            marriageComments:e.target.value
                          })
                      }
                  }}
              />
            </Box>

            <Box display={"flex"} gap={2}>
              <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="sexualStatus"
                  row
                  value={state.sexualStatus}
                  onClick={(e:any)=>{
                    if(e?.target?.value){
                      setState({sexualStatus:e.target.value})
                    }
                  }}
              >
                  <FormControlLabel value="active" control={<Radio />} label="Active" />
                  <FormControlLabel value="not active" control={<Radio />} label="Not Active" />
                  <FormControlLabel value="planning for pregnancy" control={<Radio />} label="Planning for pregnancy" />
                  <FormControlLabel value="not planning for pregnancy" control={<Radio />} label="Not planning for pregnancy" />
              </RadioGroup>
            </Box>

            <Divider/>

            <Box display={"flex"} gap={2}>
              {
                [
                  {
                    label:'Gravida',
                    key:'gravida',
                  },
                  {
                    label:'Para',
                    key:'para',
                  },
                  {
                    label:'Live',
                    key:'live',
                  },
                  {
                    label:'Abortion',
                    key:'abortion',
                  },
                ].map(elem=>{
                  return (
                    <TextField
                        label={elem.label}
                        value={state[elem.key]}
                        onChange={(e)=>{
                            if(validateInput(e.target.value,elem.key)){
                                setState({
                                  [elem.key]:e.target.value
                                })
                            }
                        }}
                        sx={{flex:1}}
                    />
                  )
                })
              }
              
            </Box>

            <Box display={'flex'} justifyContent={'space-between'} gap={2}>
                <Box display={'flex'} alignItems={'center'} gap={4} width={'320px'}>
                    <Typography>Ectopic</Typography>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        row
                        value={state.ectopic}
                        onClick={(e:any)=>{
                          if(e?.target?.value){
                            setState({ectopic:e.target.value})
                          }
                        }}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Box>
                <TextField
                    label={'Comments'}
                    value={state.ectopicComments}
                    onChange={(e)=>{
                        if(validateInput(e.target.value,'ectopicComments')){
                            setState({
                              ectopicComments:e.target.value
                            })
                        }
                    }}
                    sx={{flex:1}}
                />
            </Box>

          </Box>


          {showHistory["Gravida"] && GravidaRef}
          {showHistory["Abortion"] && AbortionRef}
          {showHistory["Ectopic"] && EctopicRef}

          <>
            {Object.values(showHistory).includes(false) && (
              <>
                <Divider sx={{ mx: 0, mb: 2 }} variant="middle" />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      marginRight: "16px",
                      color: "#000000",
                      opacity: "87%",
                    }}
                  >
                    Add
                  </span>
                  <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                    {Object.entries(showHistory).map((arr) => {
                      const [key, value] = arr;
                      if (!value) {
                        return (
                          <Box
                            sx={{ mr: 2, cursor: "pointer" }}
                            onClick={(e) => {
                              const target = e.currentTarget as HTMLElement;
                              const ariaLabel = target.ariaLabel as string;
                              setShowHistory({
                                ...showHistory,
                                [ariaLabel]: true,
                              });
                            }}
                            aria-label={key}
                            key={key}
                          >
                            <StyledChip
                              sx={{ pointerEvents: "none" }}
                              avatar={
                                <Avatar alt="" src="/images/icon_add.svg" />
                              }
                              label={key}
                            />
                          </Box>
                        );
                      }
                    })}
                  </Box>
                  
                </Box>
              </>
            )}
          </>
        </>
      );
    }
    return (
      <>
        <TabComponent tabActive={tabActive} index={0}>
          {GravidaRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={1}>
          {AbortionRef}
        </TabComponent>
        <TabComponent tabActive={tabActive} index={2}>
          {EctopicRef}
        </TabComponent>
      </>
    );
  };

  const RenderReadOnly = () => {
    return (
      <Box>
        {data?.rows?.map((item: any) => {
          return (
            <Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "rgba(73, 78, 157, 1)",
                  }}
                >
                  {item?.header}
                </Typography>
              </Box>
              {item?.value?.length > 3 ? (
                <Box className="slider-container">
                  <Slider {...sliderMobileSetting}>
                    {renderComplaints(item?.view_type, item?.value)}
                  </Slider>
                </Box>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {renderComplaints(item?.view_type, item?.value)}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  const RenderData = () => {
    if (!isPatientHistoryEmpty(sectionData)) {
      return ShowTableView();
    }
    return isFieldDisabled();
  };

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </>
      )}
      {!loading && !isMobileScreen && (
        <Card
          styles={{
            boxShadow: `${isFocused ? CARD_SHADOW : "none"}`,
          }}
          title="Obstetric History"
        >
          <CardHeader
            headerImg="icon_patient_history.svg"
            headerTitle="Obstetric History"
          >
            {/* {!toEdit &&
              (!isPatientHistoryEmpty(sectionData) ||
                sectionData.rows.some(
                  (row: any) => row.disable_value === true
                )) && (
                <EditButton onClick={() => setEdit(true)}>Edit</EditButton>
              )} */}
              <></>
          </CardHeader>
          {ShowEditableContent()}
        </Card>
      )}
      {/* {!loading && isMobileScreen && !editSection && RenderReadOnly()}
      {!loading && isMobileScreen && editSection && (
        <>
          <Divider sx={{ mx: 0 }} variant="middle" />
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                background: "transparent",
              }}
            >
              <Tabs
                value={tabActive}
                onChange={(_e, newValue) => {
                  setTabActive(newValue);
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                {Object.entries(showHistory).map((arr, index) => {
                  const [key] = arr;
                  return <Tab label={key} {...allyProps(index)} />;
                })}
              </Tabs>
            </Box>
            {ShowEditableContent()}
          </Box>
        </>
      )} */}
    </>
  );
}
