import { useState, useEffect } from 'react';
import { Button, Container, Dialog, Grid , Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,CircularProgress, FormControl,Typography,DialogActions} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { APIClient } from "../../../../shared/Utils/api-client";
import { useParams,Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ShowSkeleton,NoData,HeaderTitleButtonFlex,HeaderTitleButtonItem } from './RxCommon';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import{ROUTE_ADMIN_LIST_RX_TEMPLATES} from "../../../../shared/routes/route.constants";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  addButton: {
    marginBottom: '20px',
  },
  listItem: {
    marginTop: '20px',
  },
});

function RxDoctor() {
  const { template_id } = useParams();
  console.log("template_id",template_id);
  const classes = useStyles();
  const [doctors, setDoctors] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const [selectedClinic, setSelectedClinic] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleError = (error) => {
    setErrorOpen(true);

    setErrorMessage(error || 'An error occurred.');
  };

  useEffect(() => {
    getDoctors(); // Logs the updated count after state change
  }, [selectedClinic]);
  const handleSaveClick = async () => {
    setSaving(true);
    setDisabledSave(true);
    
    try{
      const response = await APIClient.post("prescription/assign-template?active_account_id=svaas", {
          "account_id": "svaas",
              "doctor_id":selectedDoctor.doctor_id,
              "type": "doctor",
              "template_id": template_id
      })
      if (response?.data?.statusCode === 200) {
        setOpenSaveDialog(false);
        getTemplate(template_id);
        setSaving(false);
        setDisabledSave(false);
        setSelectedDoctor('');
      }
    }
    catch(error){
      handleError(error);
      setSaving(false);
      setDisabledSave(false);
    }
  }  

  const handleClinicChange = (event) => {
    setSelectedClinic(event.target.value);

  };
  const handleSaveButtonClick = async () => {
    
    setOpenSaveDialog(true);
  };

  const handleCloseSaveDialog = () => {
    setOpenSaveDialog(false);
  };
  const getTemplate = async (template_id) => {
    const response = await APIClient.get("prescription/get-template", {
      params: {
        type: "all_templates",
        file_type:"template",
        id:template_id,
        active_account_id : "svaas"
      },
    });
    console.log("response---",response);
    if (response?.data?.statusCode === 200) {
      setTemplateData(response?.data.data[0]);
      setLoading(false);
      console.log("response",response?.data);
     
     
    }
  }
  const getClinics = async () => {
    const response = await APIClient.get("admin/admin/account/all", {
      params: {
        limit:1000
      },
    });
    console.log("response---",response);
    if (response.status === 200) {
        
       
      console.log("clinics",response.data.rows);
        setClinics(response.data.rows);
      console.log(response?.data?.data);
    }
  }
  const getDoctors = async () => {
    const response = await APIClient.get("user/onlinedoctor", {
      params: {
        fetch_all_type: true,
        account_id : selectedClinic.account_id
      },
    });
    console.log("doctors",response);
    if (response?.status === 200) {
        
       
      console.log("doctors",response.data.rows);
        setDoctors(response.data.rows);
      console.log(response?.data?.rows);
    }
  }
  useEffect(() => {

    // Fetch data from API
    getClinics();
    getTemplate(template_id);
   
  }, []);



  const handleDelete = async (id) => {
    setLoading(true);
    // Logic for deleting template with id
    try{
      const response = await APIClient.delete("prescription/delete-assignment/"+id+"?active_account_id=svaas", {
      
      });
      console.log("response---",response);
      if (response?.data?.statusCode === 200) {
        getTemplate(template_id);
      
      
      }

      console.log('Delete template with ID:', id);
    }
    catch(error){
      handleError(error);
      getTemplate(template_id);
    }
  };


  return (
    <Container>
        
        <div>
            <HeaderTitleButtonFlex>
                <HeaderTitleButtonItem>
                  <Typography variant='subtitle1' fontWeight={'bold'}>                    
                        <IconButton component={Link} to={ROUTE_ADMIN_LIST_RX_TEMPLATES}>
                          <Tooltip title="Back To Templates">
                            <ArrowBackIcon/>
                          </Tooltip>  
                        </IconButton>                      
                    Doctors Linked To Template
                  </Typography>
                </HeaderTitleButtonItem>
                <HeaderTitleButtonItem>
                  <div style={{textAlign: 'right'}}>
                      <Button  variant="contained" color="primary" className={classes.addButton} onClick={handleSaveButtonClick}> Link New doctor</Button>
                  </div>
                </HeaderTitleButtonItem>  
            </HeaderTitleButtonFlex>
            
           
            <Dialog open={openSaveDialog} onClose={handleCloseSaveDialog} >
                <DialogActions sx={{position:"absolute",right: 0}}>
                  <IconButton aria-label="close" onClick={handleCloseSaveDialog}>
                    <CloseIcon />
                  </IconButton>
                  {/* Other action buttons */}
                </DialogActions>     
                <div style={{padding: 20,width: 400,textAlign: 'center'}}>
                  {/* Add your save popup content here */}
                  {console.log("selectedClinic",selectedClinic)}
                  <h4>Select  Doctor To Link</h4>
                  <div style={{marginBottom: 10}}>
                  <FormControl fullWidth variant="outlined">
                      
                      <Autocomplete
                        options={clinics}
                        labelId="clinic-label"
                        label="Select Clinic"
                        getOptionLabel={(clinic) => clinic.account_name}
                        getOptionSelected={(clinic, value) => value!=''&&clinic.account_id === value.account_id}
                        renderInput={(params) => <TextField {...params} label="Select Clinic" />}
                        onChange={(event, value) => {console.log("clinic value",value);value&&setSelectedClinic(value);setDisabledSave(true);}}
                        value={selectedClinic&&typeof selectedClinic.account_id==="string"?selectedClinic:null}
                        />
                  </FormControl>
                    
                    
                  
                  </div>
                  {doctors&&<>
                    <div>
                      <FormControl fullWidth variant="outlined">
                        
                        <Autocomplete
                            labelId="doctor-label"
                            label="Select Doctor"
                            options={doctors}
                            getOptionLabel={(doctor) => doctor.doctor_name}
                            getOptionSelected={(doctor, value) => value&&doctor.doctor_id === value.doctor_id}
                            renderInput={(params) => <TextField {...params} label="Select Doctor" />}
                            onChange={(event, value) => {console.log("doctor value",value);value&&setSelectedDoctor(value);setDisabledSave(false);}}
                            value={selectedDoctor&&typeof selectedDoctor.doctor_id==="string"?selectedDoctor:null}
                            />
                      </FormControl>  
                    
                    </div>
                  </>}
                  <Button 
                    variant="contained" 
                    style={{marginTop: 20}} 
                    onClick={handleSaveClick}
                    startIcon={saving&&<CircularProgress size={20} color="inherit" />}
                    disabled={disabledSave}
                    >Link</Button>
                </div>
            </Dialog>
            
        </div>
      
      <Grid container spacing={3} style={{height:"calc(100vh - 120px)",overflowY:"auto",marginTop:"0px"}}>
      {loading?<>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <ShowSkeleton count={3}/>
          </Grid> 
          
      </>:
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{width:"100%"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Clinic</TableCell>
                <TableCell>Speciality</TableCell>
                <TableCell style={{textAlign:"right"}}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log("templateData",templateData)}
              {templateData.doctor_map&&(templateData.doctor_map).map((doctor) => (
                console.log("doctor", typeof (doctor.speciality_name), doctor.speciality_name ),
                <TableRow key={doctor.id}>
                  <TableCell>{doctor.doctor_name}</TableCell>
                  <TableCell>{doctor.clinic_name}</TableCell>
                  <TableCell>{doctor.speciality_name}</TableCell>
                  <TableCell style={{textAlign:"right"}}>
                
                    <Tooltip title="Unlink Doctor">
                      <IconButton aria-label="unlink Doctor" onClick={() => handleDelete(doctor.id)}>
                        <PersonAddDisabledOutlinedIcon color='error'/>
                      </IconButton>
                    </Tooltip>
              
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {templateData && templateData.doctor_map&&templateData.doctor_map.length === 0 && (
            <NoData>
              No Doctor Linked 
            </NoData>
        )} 
      </Grid>
      }
      
      </Grid>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000} // Optional: Auto-close after 6 seconds
        onClose={() => setErrorOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Optional: Adjust position
      >
        <MuiAlert onClose={() => setErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </MuiAlert>
      </Snackbar> 
    </Container>
  );
}

export default RxDoctor;
